import { FC, memo } from 'react';

import Avatar from 'core/containers/Avatar';
import useAppSelector from 'core/hooks/useAppSelector';

import { AvatarContainer } from '../../styled';

type Props = { usersId?: number; storageKey?: string | null; name: string | null };

const UserAvatar: FC<Props> = ({ usersId, storageKey, name }) => {
  const currentUser = useAppSelector((s) => s.core.user!);
  const isOwnProfile = currentUser.id === usersId;

  return (
    <AvatarContainer>
      <Avatar
        edit={false}
        size="normal"
        endpoint={isOwnProfile ? 'profile/image' : `users/${usersId}/image`}
        title={name || undefined}
        storageKey={storageKey}
      />
    </AvatarContainer>
  );
};

export default memo(UserAvatar);
