import styled from 'styled-components/macro';

import theme from 'app/theme';

export const Wrapper = styled.div<{ open: boolean }>`
  justify-content: flex-end; /* disable float magnifier icon to left and animate back to right */
  align-items: center;
  min-width: 3rem;
  display: flex;
  height: 100%;
  width: 100%;
  z-index: 2;
`;

export const Content = styled.div`
  align-items: center;
  overflow: hidden;
  display: flex;
  height: 100%;
  flex: 1;

  & > .material-icons {
    margin-left: 1rem;
  }
`;

export const Form = styled.form`
  flex: 1;

  & > button {
    display: none;
  }
`;

export const Input = styled.input`
  margin-left: 0.5rem;
  font-size: 1rem;
  line-height: 1;
  border: none;
  height: 100%;
  width: 100%;
  ::placeholder {
    color: ${theme.color.gray200};
  }
`;
