import { useMemo } from 'react';
import { useTranslation } from 'react-i18next';

import Button from 'core/components/Button';
import { FlexRow } from 'core/components/FlexUtils';
import IconButton from 'core/components/IconButton';
import Location from 'lists/models/Location';

interface Props {
  onClick: () => void;
  disabled: boolean;
  location?: Location;
}

const SelectLocationButton = ({ onClick, disabled, location }: Props) => {
  const { t } = useTranslation();

  const title = useMemo(
    () =>
      (location &&
        t('Select Location ({{locationName}})', {
          locationName: location.name,
        })) ||
      (t('Select Location') as string),
    [location, t]
  );

  const { tooltip, icon } = useMemo(() => {
    const comment =
      location && location.internalComments
        ? location.internalComments
        : t('No Internal Comments provided');

    const icon = location && location.internalComments ? 'speaker_notes' : 'chat_bubble_outline';

    return {
      tooltip: comment,
      icon,
    };
  }, [location, t]);

  if (disabled) {
    return <></>;
  }

  return (
    <FlexRow horizontalAlign="end" fullWidth>
      {location && <IconButton icon={icon} tooltip={tooltip} />}
      <Button onClick={onClick} text={title} type="button" />
    </FlexRow>
  );
};

export default SelectLocationButton;
