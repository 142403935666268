import { FC, useEffect, useState, useMemo } from 'react';
import { useTranslation } from 'react-i18next';

import Button from 'core/components/Button';
import DropDown, { SimpleOption } from 'core/components/DropDown';
import Modal from 'core/components/Modal';
import useDropdownResource from 'core/hooks/useDropdownResource';
import useDropDownSelectionGrouping from 'core/hooks/useDropDownSelectionGrouping';
import useAthleteOptionsMapping from 'personnel/hooks/useAthleteOptionsMapping';
import Athlete, { ATHLETES_RESOURCE } from 'personnel/models/Athlete';

export type OwnProps = {
  onRequestCreate: (athletesId: number[]) => void;
  onRequestClose: () => void;
  hasFederation?: boolean;
  open: boolean;
  saveInProgress: boolean;
};

type Props = OwnProps;

const TestBulkCreateDropdown: FC<Props> = ({
  onRequestCreate,
  onRequestClose,
  hasFederation,
  open,
  saveInProgress,
}) => {
  const [selected, setSelected] = useState([]);
  const { t } = useTranslation();

  useEffect(() => {
    if (!open && selected.length > 0) {
      setSelected([]);
    }
  }, [selected, open, setSelected]);

  const getAthleteOption = useAthleteOptionsMapping();

  const [athletes] = useDropdownResource<Athlete>(
    ATHLETES_RESOURCE,
    useMemo(
      () => ({
        reducer: (athletes: Athlete[]) =>
          athletes.reduce((list, athlete) => {
            if (athlete.active) {
              list.push(getAthleteOption(athlete));
            }
            return list;
          }, [] as SimpleOption[]),
        autoload: !!open,
      }),
      [getAthleteOption, open]
    )
  );

  const dropdownOptions = useDropDownSelectionGrouping(athletes || [], selected);

  return (
    <Modal
      onClear={hasFederation ? () => setSelected([]) : undefined}
      ariaLabel={t('Bulk test creation from athletes dialog')}
      title={t('Bulk test create')}
      onCancel={onRequestClose}
      onClose={onRequestClose}
      open={open}
      confirmButton={
        hasFederation ? (
          <Button
            disabled={!selected || selected.length === 0 || saveInProgress}
            onClick={() => onRequestCreate(selected.map((id) => Number(id)))}
            text={t('Create ({{count}})', { count: selected.length })}
          />
        ) : null
      }
    >
      {hasFederation ? (
        <DropDown
          onChange={(val: any) => {
            setSelected(val || []);
          }}
          options={dropdownOptions}
          label={t('Athletes')}
          id="active-athletes"
          value={selected}
          mode="inline"
        />
      ) : (
        t('In order to use the bulk test creation feature, please define a federation.')
      )}
    </Modal>
  );
};

export default TestBulkCreateDropdown;
