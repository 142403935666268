import { put, take, race } from 'redux-saga/effects';

import { apiCall } from 'core/actions';
import { stringify } from 'core/functions/qs';
import showUpdatedRecordsMessage from 'core/functions/showUpdatedRecordsMessage';
import { BulkEditOrderItemAction, TYPE_BULK_EDIT_ORDER_ITEM } from 'planning/actions';

// TODO: refactor with using one bulkEdit saga for all entities
export function* bulkEditOrderItemsSaga(action: BulkEditOrderItemAction) {
  const {
    selection: params,
    outputMapping,
    formData,
    initials,
    ordersId,
    resetFormOnSuccess,
  } = action.payload;
  yield put(
    apiCall(
      TYPE_BULK_EDIT_ORDER_ITEM,
      'PATCH',
      `/orders/${ordersId}/items/bulk`,
      outputMapping(formData),
      {
        params,
        paramsSerializer: (params) =>
          stringify(params, {
            encodeValuesOnly: true,
          }),
      },
      true,
      outputMapping({
        ...initials,
      })
    )
  );
  const { success } = yield race({
    success: take(`${TYPE_BULK_EDIT_ORDER_ITEM}_SUCCESS`),
    error: take(`${TYPE_BULK_EDIT_ORDER_ITEM}_ERROR`),
  });

  if (success) {
    showUpdatedRecordsMessage(success.payload.response);
    yield put({
      type: `${TYPE_BULK_EDIT_ORDER_ITEM}_SUCCESS`,
      payload: success.payload,
    });
    resetFormOnSuccess && resetFormOnSuccess();
  }
}
