import { toast } from 'react-toastify';
import { eventChannel } from 'redux-saga';
import { call, delay, fork, put, race, take, takeEvery } from 'redux-saga/effects';
import io, { Socket } from 'socket.io-client';

import {
  setNotificationsCount,
  socketMessage,
  socketOff,
  socketOn,
  TYPE_SOCKET_RESTART,
} from 'core/actions';
import getEnv from 'core/functions/getEnv';
import { t } from 'core/i18n';

let socket: Socket | null = null;

const socketServerURL = '';
const options = {
  path: `${getEnv('API_URL')}/socket.io`,
  transports: ['websocket'],
};

const connect = () => {
  socket = io(socketServerURL, options);
  return new Promise((resolve) => {
    /* this event "Connected" its specific for app api (and called right after connect)
     * its too fast and general event channel in startSocketSaga handler its not able to catch it */
    socket && socket.on('Connected', resolve);
  });
};

const onDisconnect = () => {
  return new Promise<void>((resolve) => {
    socket &&
      socket.on('disconnect', () => {
        socket = null;
        resolve();
      });
  });
};

const onReconnect = () => {
  return new Promise((resolve) => {
    socket && socket.on('reconnect', resolve);
  });
};

export const listenDisconnectSaga = function* () {
  while (true) {
    yield call(onDisconnect);
    yield put(socketOff());
  }
};

export const listenConnectSaga = function* () {
  while (true) {
    yield call(onReconnect);
    yield put(socketOn());
  }
};

export const startSocketSaga: () => void = function* () {
  try {
    const { timeout, connected } = yield race({
      connected: (yield call(connect)) as { unread: number } | undefined,
      timeout: (yield delay(3000)) as true | undefined,
    });

    if (!timeout) {
      if (connected && connected.unread) {
        yield put(setNotificationsCount(connected.unread));
      }

      // @ts-ignore
      const eventsChannel = yield eventChannel((emit) => {
        // Watch more events here -> put custom type
        socket &&
          socket.on('Notification', (message: any) => emit({ type: 'notification', message }));
        socket &&
          socket.on('VersionUpdate', (message: any) => emit({ type: 'versionUpdate', message }));
        socket &&
          socket.on('SessionExpired', (message: any) => emit({ type: 'sessionExpired', message }));
        socket &&
          socket.on('SessionDeleted', (message: any) => emit({ type: 'sessionDeleted', message }));

        return () => {
          socket && socket.close();
        };
      });

      yield takeEvery(
        eventsChannel,
        function* ({ type, message }: { type: 'notification' | 'versionUpdate'; message: any }) {
          yield put(socketMessage(type, message));
        }
      );

      yield take(TYPE_SOCKET_RESTART);
      eventsChannel.close();
      yield put(setNotificationsCount(0));
      yield fork(startSocketSaga);
      return;
    } else {
      yield fork(listenDisconnectSaga);
      yield fork(listenConnectSaga);
    }

    yield put(timeout ? socketOff() : socketOn());
  } catch (e) {
    toast.warn(t('Error enabling real-time notifications.'));
  }
};
