import { FC, memo } from 'react';
import { useTranslation } from 'react-i18next';

import Field from 'core/components/Form/Field';
import { FullRowCell } from 'core/components/GridCell';
import ResourceFormDropdown from 'core/components/ResourceFormDropdown';
import getResourcesUrl from 'core/functions/getResourcesUrl';
import { useResources } from 'core/hooks/useResource';
import useAthleteOptionsMapping from 'personnel/hooks/useAthleteOptionsMapping';
import Athlete, { ATHLETES_RESOURCE_PARAMS } from 'personnel/models/Athlete';
import BlacklistAthletes from 'personnel/models/BlacklistAthletes';

type Props = {
  isEdit: boolean;
  usersId: number;
};

const Athletes: FC<Props> = memo(({ isEdit, usersId }) => {
  const { t } = useTranslation();
  const getAthleteOption = useAthleteOptionsMapping();

  const { data: athletesBlacklist } = useResources<BlacklistAthletes>(
    `blacklist/athletes/user/${usersId}`
  );

  const ATHLETES_RESOURCE = getResourcesUrl('athletes', {
    ...ATHLETES_RESOURCE_PARAMS,
    ...(!isEdit // for create, show only not already blacklisted entities
      ? {
          id: {
            $nin: [...(athletesBlacklist || []).map((it: BlacklistAthletes) => it.athletesId)],
          },
        }
      : {}),
  });

  return (
    <FullRowCell>
      <Field
        component={ResourceFormDropdown}
        label={t('Athlete')}
        resource={ATHLETES_RESOURCE}
        name="athletesId"
        single
        fast={false}
        viewMode={isEdit}
        resourceReducer={(list: Athlete[]) => list.map(getAthleteOption)}
      />
    </FullRowCell>
  );
});

export default Athletes;
