import { DateTime } from 'luxon';
import { FC, memo, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';

import Button from 'core/components/Button';
import Modal from 'core/components/Modal';
import Select from 'core/components/Select';
import { useFormatDateTime } from 'core/i18n/useFormatDateTime';

import Sliders from './Sliders';
import { PickerWrapper } from './styled';

export interface Props {
  id: string;
  value: string | Date | null;
  label: string;
  required?: boolean;
  disabled?: boolean;
  error?: boolean;
  onChange: (time: Date | null, timeString: string | null) => void;
  onBlur?: () => void;
  min?: Date;
  max?: Date;
}

const processInputValue = (value: Props['value']) => {
  if (value) {
    if (value instanceof Date) {
      return value;
    }
    const parsed = DateTime.fromISO(value);
    return parsed.isValid ? parsed.toJSDate() : undefined;
  } else {
    return undefined;
  }
};

const TimePickerInput: FC<Props> = ({
  id,
  value,
  label,
  required = false,
  disabled = false,
  error,
  onChange,
  onBlur,
  min,
  max,
}) => {
  const [opened, setOpened] = useState(false);
  const { t } = useTranslation();
  const formatDateTime = useFormatDateTime();

  const [newValue, setNewValue] = useState<Date | undefined>(() => processInputValue(value));
  useEffect(() => setNewValue(processInputValue(value)), [value]);

  const valueHr = newValue ? formatDateTime(newValue, 'TIME_SHORT') : '';

  const handleClear = () => {
    onChange(null, null);
    setOpened(false);
  };

  const handleCancel = () => {
    setOpened(false);

    const oldValue = processInputValue(value);

    setNewValue(oldValue);
  };

  const handleOk = () => {
    onChange(newValue || DateTime.fromISO('2000-01-01T00:00').toJSDate(), valueHr || '00:00');
    opened && setOpened(false);
  };

  const handleClose = () => {
    handleOk();
    setTimeout(() => onBlur && onBlur());
  };

  return (
    <>
      <Select
        onCLick={() => !disabled && setOpened(true)}
        options={[{ id: '1', name: valueHr }]}
        value={newValue ? '1' : null}
        error={!disabled && error}
        includeEmptyOption={false}
        onChange={() => undefined}
        readOnly={disabled}
        disableOptions
        onBlur={onBlur}
        label={label}
        outlined
        id={id}
      />

      {!disabled && (
        <Modal
          ariaLabel={t('Time Picker Dialog')}
          onClear={(!required && handleClear) || undefined}
          cancelButton={<Button type="button" text={t('Cancel')} onClick={handleCancel} />}
          onConfirm={() => handleOk}
          onClose={handleClose}
          open={opened}
        >
          <PickerWrapper>
            <Sliders onChange={setNewValue} value={newValue} min={min} max={max} />
          </PickerWrapper>
        </Modal>
      )}
    </>
  );
};

export default memo(TimePickerInput);
