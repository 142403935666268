import { DateTime } from 'luxon';
import { useCallback, useMemo } from 'react';
import { useTranslation } from 'react-i18next';

const useRelativeRanges = () => {
  const { t } = useTranslation();

  const relativeOptions = useMemo(
    () =>
      [
        {
          id: 'yesterday',
          name: t('Yesterday'),
        },
        {
          id: 'lastWeek',
          name: t('Last Week'),
        },
        {
          id: 'lastMonth',
          name: t('Last Month'),
        },
        {
          id: 'last30Days',
          name: t('Last 30 Days'),
        },
        {
          id: 'last60Days',
          name: t('Last 60 Days'),
        },
        {
          id: 'lastQuarter',
          name: t('Last Quarter'),
        },
        {
          id: 'lastYear',
          name: t('Last Year'),
        },
        {
          id: 'last365Days',
          name: t('Last 365 Days'),
        },
        'SEPARATOR',
        {
          id: 'today',
          name: t('Today'),
        },
        {
          id: 'thisWeek',
          name: t('This Week'),
        },
        {
          id: 'thisMonth',
          name: t('This Month'),
        },
        {
          id: 'thisQuarter',
          name: t('This Quarter'),
        },
        {
          id: 'thisYear',
          name: t('This Year'),
        },
        {
          id: 'yearToDate',
          name: t('Year to Date'),
        },
        'SEPARATOR',
        {
          id: 'tomorrow',
          name: t('Tomorrow'),
        },
        {
          id: 'nextWeek',
          name: t('Next Week'),
        },
        {
          id: 'nextMonth',
          name: t('Next Month'),
        },
        {
          id: 'next30Days',
          name: t('Next 30 Days'),
        },
        {
          id: 'next60Days',
          name: t('Next 60 Days'),
        },
        {
          id: 'nextQuarter',
          name: t('Next Quarter'),
        },
        {
          id: 'nextYear',
          name: t('Next Year'),
        },
        {
          id: 'next365Days',
          name: t('Next 365 Days'),
        },
      ] as const,
    [t]
  );

  type RelativeOptions = typeof relativeOptions;
  type OptionIds = Exclude<RelativeOptions[number], string>['id'];

  const getRangeForOption = useCallback((option: OptionIds) => {
    switch (option) {
      case 'yesterday':
        return [
          DateTime.local().minus({ days: 1 }).startOf('day'),
          DateTime.local().minus({ days: 1 }).endOf('day'),
        ] as const;

      case 'lastWeek':
        return [
          DateTime.local().minus({ weeks: 1 }).startOf('week'),
          DateTime.local().minus({ weeks: 1 }).endOf('week'),
        ] as const;

      case 'lastMonth':
        return [
          DateTime.local().minus({ months: 1 }).startOf('month'),
          DateTime.local().minus({ months: 1 }).endOf('month'),
        ] as const;

      case 'last30Days':
        return [
          DateTime.local().minus({ days: 29 }).startOf('day'),
          DateTime.local().endOf('day'),
        ] as const;

      case 'last60Days':
        return [
          DateTime.local().minus({ days: 59 }).startOf('day'),
          DateTime.local().endOf('day'),
        ] as const;

      case 'lastQuarter':
        return [
          DateTime.local().minus({ quarters: 1 }).startOf('quarter'),
          DateTime.local().minus({ quarters: 1 }).endOf('quarter'),
        ] as const;

      case 'lastYear':
        return [
          DateTime.local().minus({ years: 1 }).startOf('year'),
          DateTime.local().minus({ years: 1 }).endOf('year'),
        ] as const;

      case 'last365Days':
        return [
          DateTime.local().minus({ days: 365 }).startOf('day'),
          DateTime.local().endOf('day'),
        ] as const;

      case 'today':
        return [DateTime.local().startOf('day'), DateTime.local().endOf('day')] as const;

      case 'thisWeek':
        return [DateTime.local().startOf('week'), DateTime.local().endOf('week')] as const;

      case 'thisMonth':
        return [DateTime.local().startOf('month'), DateTime.local().endOf('month')] as const;

      case 'thisQuarter':
        return [DateTime.local().startOf('quarter'), DateTime.local().endOf('quarter')] as const;

      case 'thisYear':
        return [DateTime.local().startOf('year'), DateTime.local().endOf('year')] as const;

      case 'yearToDate':
        return [DateTime.local().startOf('year'), DateTime.local().endOf('day')] as const;

      case 'tomorrow':
        return [
          DateTime.local().plus({ days: 1 }).startOf('day'),
          DateTime.local().plus({ days: 1 }).endOf('day'),
        ] as const;

      case 'nextWeek':
        return [
          DateTime.local().plus({ weeks: 1 }).startOf('week'),
          DateTime.local().plus({ weeks: 1 }).endOf('week'),
        ] as const;

      case 'nextMonth':
        return [
          DateTime.local().plus({ months: 1 }).startOf('month'),
          DateTime.local().plus({ months: 1 }).endOf('month'),
        ] as const;

      case 'next30Days':
        return [
          DateTime.local().startOf('day'),
          DateTime.local().plus({ days: 30 }).endOf('day'),
        ] as const;

      case 'next60Days':
        return [
          DateTime.local().startOf('day'),
          DateTime.local().plus({ days: 60 }).endOf('day'),
        ] as const;

      case 'nextQuarter':
        return [
          DateTime.local().plus({ quarters: 1 }).startOf('quarter'),
          DateTime.local().plus({ quarters: 1 }).endOf('quarter'),
        ] as const;

      case 'nextYear':
        return [
          DateTime.local().plus({ years: 1 }).startOf('year'),
          DateTime.local().plus({ years: 1 }).endOf('year'),
        ] as const;

      case 'next365Days':
        return [
          DateTime.local().startOf('day'),
          DateTime.local().plus({ days: 365 }).endOf('day'),
        ] as const;
    }
  }, []);

  return [relativeOptions, getRangeForOption] as const;
};

export default useRelativeRanges;
