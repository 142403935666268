import { FC } from 'react';
import { useTranslation } from 'react-i18next';

import Field from 'core/components/Form/Field';
import { ExplanationWrapper } from 'core/components/FormStyles';
import FormSwitch from 'core/components/FormSwitch';
import FormTextfield from 'core/components/FormTextfield';
import { FullRowCell, HalfRowCell } from 'core/components/GridCell';
import useAppSelector from 'core/hooks/useAppSelector';
import { UserView } from 'core/models/UserView';

import { StyledRow } from './styled';

interface Props {
  activeView: UserView | null;
}

const Details: FC<Props> = ({ activeView }) => {
  const { t } = useTranslation();

  const { userId } = useAppSelector(({ core }) => ({
    userId: core.user?.id,
    savingInProgress: core.viewSavingInProgress,
    deletingInProgress: core.viewDeletingInProgress,
  }));
  const isOwn = userId === activeView?.createdBy;

  return (
    <HalfRowCell>
      <StyledRow>
        <FullRowCell>
          <Field
            name="name"
            label={t('Name')}
            viewMode={!!activeView && !isOwn}
            component={FormTextfield}
          />
        </FullRowCell>

        <FullRowCell>
          <Field
            name="shared"
            label={t('Shared')}
            viewMode={!!activeView && !isOwn}
            component={FormSwitch}
            inline
            color={'secondary'}
          />

          <ExplanationWrapper>
            {t('Any other user of your Client will be able to see and use this Saved View.')}
          </ExplanationWrapper>
        </FullRowCell>

        <FullRowCell>
          <Field name="default" label={t('Default')} component={FormSwitch} inline />

          <ExplanationWrapper>
            {t('Makes this saved View automatically preload when the module is opened.')}
          </ExplanationWrapper>
        </FullRowCell>
      </StyledRow>
    </HalfRowCell>
  );
};

export default Details;
