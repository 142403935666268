import ExcludedListModel from 'lists/models/ExcludedListModel';

export const GENE_ANALYSE_CODE = 'GENE';

export default interface Analysis extends ExcludedListModel {
  analyseTypesId: 'blu' | 'ser' | 'uri';
  otherAnalysisRemarks: string | null;
  twoBloodEdtaTubes: boolean;
  oneBloodEdtaTube: boolean;
  twoBloodGelTubes: boolean;
  oneBloodGelTube: boolean;
  oneBloodEdtaTwoGelTubes: boolean;
  driedBloodSpots: boolean;
  isAgeRequired: boolean;
  oneUrineTube: boolean;
  isSelectable: boolean;
  shortName: string;
  fullName: string;
  code: string;
  isAbpQuestionnaireRequired: boolean;
}
