import { useMemo } from 'react';

import useFormFieldValue from 'core/components/Form/useFormFieldValue';
import { useIncludeOptions } from 'core/components/ResourceFormDropdown/IncludeResourcesProvider';
import { useDetail } from 'core/containers/FormDetailPage/DetailContext';
import { useResources } from 'core/hooks/useResource';
import Event from 'planning/models/Event';
import Mission from 'planning/models/Mission';

/**
 * Provides the currently selected event - include or an option
 */
const useSelectedEvent = () => {
  const { entityData: missionData } = useDetail();
  const include = useIncludeOptions('eventsId') as Event[];
  const eventsId = useFormFieldValue('eventsId');

  // Used just to retrieve the list once it is loaded
  const { data: events } = useResources<Event>('events', {
    autoload: false,
  });

  const eventInclude = Array.isArray(include) && include[0];
  const eventChanged = eventsId !== (missionData as Mission)?.eventsId;

  const event = useMemo(() => {
    // If we have no event selected, well ... there is no event :)
    if (!eventsId) return undefined;

    // If we have include and event hasn't changed. we should use it
    if (!eventChanged && eventInclude) return eventInclude;

    // Otherwise we can find it in options
    return (events || []).find((i) => i.id === eventsId);
  }, [eventsId, eventChanged, eventInclude, events]);

  return event;
};

export default useSelectedEvent;
