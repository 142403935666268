import { createContext, useContext } from 'react';

import { ChildrenProps } from '.';

const DetailContext = createContext<ChildrenProps<any, any, any>>(
  {} as ChildrenProps<any, any, any>
);

/**
 * Use to get formDetail context instead of prop drilling.
 *
 * Will only work when called from child components wrapped by `FormDetailPage`.
 */
export function useDetail<
  Model,
  FormData extends Record<string, any> = Record<string, any>,
  Request = Record<string, any>,
  ValidFormData extends Record<keyof FormData, any> = FormData,
>(): ChildrenProps<Model, FormData, Request, ValidFormData> {
  const value = useContext(DetailContext);
  return value as ChildrenProps<Model, FormData, Request, ValidFormData>;
}

export default DetailContext;
