import * as Sentry from '@sentry/browser';
import { toast } from 'react-toastify';
import { spawn, all, call, delay, SimpleEffect, ForkEffectDescriptor } from 'redux-saga/effects';

import { t } from 'core/i18n';

export default function* safeSagaSpawn(
  ...sagas: (() => IterableIterator<SimpleEffect<'FORK', ForkEffectDescriptor<any>>>)[]
) {
  yield all(
    sagas.map((saga) =>
      spawn(function* () {
        let attempt = 1;
        while (true) {
          try {
            yield call(saga);
            break;
          } catch (e) {
            Sentry.withScope((scope) => {
              scope.setLevel(Sentry.Severity.Critical);
              Sentry.captureException(e);
            });
            toast.error(t('Fatal error: {{error}}', { error: e }));
            // eslint-disable-next-line no-console
            console.error(e);
          }
          // Don't start the saga again right away
          // or we are risking never-ending loop.
          // With this slow gradual backoff
          // we don't spam the Sentry and block the thread.
          yield delay(attempt * 500);
          // eslint-disable-next-line no-console
          console.warn('Restarting saga for ' + attempt + ' time.');
          attempt++;
        }
      })
    )
  );
}
