import { FC, memo } from 'react';

import FilesTable from 'core/containers/FilesTable';
import { useDetail } from 'core/containers/FormDetailPage/DetailContext';
import { useSetModal } from 'core/hooks/useModal';
import { useGetPermission } from 'core/hooks/usePermission';

import { MissionModal, useMissionModal } from '../hooks/useMissionModal';

const FilesTab: FC = () => {
  const isOpen = useMissionModal(MissionModal.ADD_FILE);
  const setModal = useSetModal();
  const { id } = useDetail();

  const hasPermission = useGetPermission();
  const canCreateFiles = hasPermission('missions:files[actions:create]');

  const filesProps = {
    closeFileDialog: () => setModal(undefined),
    addFileOpen: !!isOpen,
    readonly: !canCreateFiles,
  };

  if (!id) {
    return null;
  }
  return <FilesTable {...filesProps} entityId={id} entityKey="mission" addFileOpen={!!isOpen} />;
};

export default memo(FilesTab);
