import { push } from 'connected-react-router';
import { toast } from 'react-toastify';
import { put, race, take, select } from 'redux-saga/effects';

import Profile from 'app/models/Profile';
import { apiCall, LoginAsUserAction, TYPE_LOGIN_AS_USER } from 'core/actions';
import { t } from 'core/i18n';
import { CoreState } from 'core/reducer';

function* loginAsUser(action: LoginAsUserAction) {
  const current: Profile | undefined = yield select(({ core }: { core: CoreState }) => core.user);
  const origin = current?.originUser;

  if (current && current.id === action.payload) {
    toast.info(t('You are already logged as {{fullName}}', { fullName: current.fullName }));
    return;
  }

  if (origin?.id === action.payload) {
    yield put(apiCall(TYPE_LOGIN_AS_USER, 'POST', `/auth/login/back`));
  } else {
    yield put(apiCall(TYPE_LOGIN_AS_USER, 'POST', `/users/${action.payload}/login`));
  }

  const { logged } = yield race({
    logged: take(`${TYPE_LOGIN_AS_USER}_SUCCESS`),
    error: take(`${TYPE_LOGIN_AS_USER}_ERROR`),
  });

  if (!logged) return;

  yield put(push('/'));
  window.location.reload();
}

export default loginAsUser;
