import { FC } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch } from 'react-redux';
import * as Yup from 'yup';

import { EntityKey, entities } from 'app/entity';
import { saveWatchers } from 'core/actions';
import Form from 'core/components/Form';
import Modal from 'core/components/Modal';
import { useResources } from 'core/hooks/useResource';
import Watcher from 'core/models/Watcher';

import useWatchersUrls from './useWatchersUrls';
import WatchersFields from './WatchersFields';

export interface Props {
  entityKey: EntityKey;
  entityId?: number;
  entityIds?: number[];
  open: boolean;
  setOpen: (open: boolean) => void;
  successCallback?: () => void;
  reloadOnSave?: boolean;
}

const Watchers: FC<Props> = ({
  entityKey,
  entityId,
  open,
  setOpen,
  entityIds,
  successCallback,
  reloadOnSave = true,
}) => {
  const dispatch = useDispatch();
  const { t } = useTranslation();
  const isBulkAdding = Boolean(entityIds && entityIds.length);

  const watcherUrls = useWatchersUrls(entities[entityKey].api('').list);
  const endpoint = isBulkAdding
    ? watcherUrls.watchersBulkAdd
    : watcherUrls.watchersList(entityId || 0);

  const { data: watchers, reload } = useResources<Watcher>(endpoint, {
    autoload: !!entityId && !isBulkAdding,
  });

  const schema = Yup.object({
    watchers: Yup.array().nullable().ensure().default([]),
  });

  const initialValues = schema.cast({
    watchers: (watchers || [])?.map((it) => it.usersId),
  });

  return (
    <Modal
      ariaLabel={t('Watchers dialog')}
      title={t('Watchers')}
      open={open}
      cancelButton={null}
      confirmButton={null}
      onClose={() => {
        setOpen(false);
      }}
    >
      <Form
        initialValues={initialValues}
        validationSchema={schema}
        id="watchersForm"
        onSubmit={(values) => {
          dispatch(
            saveWatchers(
              endpoint,
              values.watchers || [],
              true,
              () => {
                if (reloadOnSave && reload) {
                  reload && reload();
                }
                setOpen(false);
                successCallback && successCallback();
              },
              entityIds
            )
          );
        }}
      >
        <WatchersFields
          onClose={() => setOpen(false)}
          entityId={entityId || 0}
          entityKey={entityKey}
          isBulkAdding={!!isBulkAdding}
        />
      </Form>
    </Modal>
  );
};

export default Watchers;
