import { RowClickedEvent } from 'ag-grid-community';

const isTextSelected = (): boolean => !!window.getSelection()?.toString();

/**
 * Callback to be used with the AgGridReact when allowing copying text from the cells
 * (using `enableCellTextSelection` and `ensureDomOrder`).
 */
export const onCopyableRowClicked = (
  e: RowClickedEvent,
  onRowClicked?: (e: RowClickedEvent) => void
) => {
  if (!isTextSelected()) {
    e?.data && onRowClicked?.(e);
  }
};

/**
 * Callback for `onContextMenu` for an element.
 * Allow right-click context menu only for `a` tags.
 * @param e Right-click mouse event.
 * @param stopId Stop searching for an `a` tag in parent elements if we find an element with this id.
 */
export const showContextMenuOnlyIfLink = (
  e: React.MouseEvent<HTMLElement, MouseEvent>,
  stopId: string
) => {
  let target: HTMLElement | null = e.target as HTMLElement;
  while (target && target.id !== stopId) {
    if (target.tagName === 'A') return;
    target = target.parentElement;
  }
  e.preventDefault();
};
