import { toast } from 'react-toastify';
import { put, take, race } from 'redux-saga/effects';

import { apiCall, ImportDataAction, PREFIX_IMPORT_DATA_ENDPOINT, apiCallError } from 'core/actions';
import { t } from 'core/i18n';

function* importData(action: ImportDataAction) {
  const { file, format, endpoint, supportedExtensions, sc } = action.payload;

  const extension = file.name.split('.').pop();
  if (!extension) {
    t('Upload failed: unknown file extension.');
    yield put(apiCallError(PREFIX_IMPORT_DATA_ENDPOINT, new Error('Unknown file extension.')));
    return;
  }
  if (supportedExtensions && !supportedExtensions.includes(extension)) {
    toast.error(
      t('Upload failed: unsupported file extension. Supported file extensions are: {{formats}}.', {
        formats: supportedExtensions.join(', '),
      })
    );
    yield put(apiCallError(PREFIX_IMPORT_DATA_ENDPOINT, new Error('Unsupported file extension.')));
    return;
  }

  const formData = new FormData();
  formData.append('importFile', file);

  yield put(
    apiCall(PREFIX_IMPORT_DATA_ENDPOINT, 'POST', `${endpoint}/import`, formData, {
      params: {
        type: format,
        format: extension,
      },
      headers: {
        Accept: 'application/json',
        'Content-Type': `multipart/form-data`,
      },
    })
  );

  const { success, error } = yield race({
    success: take(`${PREFIX_IMPORT_DATA_ENDPOINT}_SUCCESS`),
    error: take(`${PREFIX_IMPORT_DATA_ENDPOINT}_ERROR`),
  });

  if (error) {
    return;
  }

  const done = success.payload.response.status === 204;

  if (!done) {
    toast.error(t('Import failed: Unexpected response from server.'));
    yield put(
      apiCallError(PREFIX_IMPORT_DATA_ENDPOINT, new Error('Unexpected response from server.'))
    );
    return;
  } else {
    sc && sc();

    toast.info(
      t(
        'Upload succeeded. Your file is being processed, and you will be notified about the result.'
      )
    );
  }
}

export default importData;
