import { useField } from 'formik';
import { FC, useCallback, useContext, useMemo } from 'react';
import { useTranslation } from 'react-i18next';

import Button from 'core/components/Button';
import DataCard from 'core/components/DataCard';
import { DataCardItem } from 'core/components/DataCard/props';
import { FlexCell } from 'core/components/FlexUtils';
import { FormContext } from 'core/components/Form';
import Field from 'core/components/Form/Field';
import FormTextfield from 'core/components/FormTextfield';
import MaterialIcon from 'core/components/MaterialIcon';
import getFakeId from 'core/functions/getFakeId';
import { TueItem } from 'planning/models/TestResult';

interface Props {
  /**
   * Path to the field, e.g., medications.advancedTue
   */
  fieldName?: string;

  /**
   * Override value
   */
  value?: TueItem[];

  /**
   * Override viewMode
   */
  viewMode?: boolean;
}

const AdvancedTueCard: FC<Props> = ({ fieldName = '', value, viewMode }) => {
  const [{ value: formValue }, , { setValue }] = useField<TueItem[] | null>(fieldName);
  const { t } = useTranslation();

  const items = useMemo(() => value || formValue || [], [formValue, value]);

  const { viewMode: formViewMode, defaultViewMode } = useContext(FormContext);

  const isInViewMode =
    viewMode !== undefined
      ? viewMode
      : formViewMode && formViewMode.hasOwnProperty(fieldName)
        ? formViewMode[fieldName]
        : formViewMode && defaultViewMode;

  const onRemove = useCallback(
    (selection: DataCardItem | DataCardItem[]) => {
      const deletedIds = new Set(
        Array.isArray(selection) ? selection.map(({ id }) => id) : [selection.id]
      );
      setValue(items.filter(({ id }) => !deletedIds.has(id)));
    },
    [items, setValue]
  );

  return (
    <DataCard
      title={t('TUE Products')}
      items={items}
      onRemove={!isInViewMode && items.length > 1 ? onRemove : undefined}
      footer={
        !isInViewMode && (
          <Button
            onClick={() =>
              setValue([...items, { id: getFakeId().toString(), name: '', authority: '' }])
            }
            text={t('Add new item')}
            icon={<MaterialIcon icon="add" />}
            type="button"
          />
        )
      }
    >
      {(item, index) => (
        <>
          <FlexCell flex={1} block>
            <Field
              component={FormTextfield}
              label={t('Name')}
              type="text"
              name={`${fieldName}[${index}].name`}
              dummyValue={item.name}
              viewMode={isInViewMode}
            />
          </FlexCell>
          <FlexCell flex={1} block>
            <Field
              component={FormTextfield}
              label={t('Authority')}
              type="text"
              name={`${fieldName}[${index}].authority`}
              dummyValue={item.authority}
              viewMode={isInViewMode}
            />
          </FlexCell>
        </>
      )}
    </DataCard>
  );
};

export default AdvancedTueCard;
