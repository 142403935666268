import { FC, ReactNode, useId } from 'react';

import theme from 'app/theme';
import Text from 'core/components/Text';

import { StyledCheckbox } from './styled';

type Props = {
  /** Label of the checkbox */
  label: ReactNode;
  /** Name of the checkbox */
  name: string;
  /** Function that will be called when the checkbox is clicked */
  onChange?: (event: any) => void;
  /** Description of the checkbox */
  description?: string | number | null;
  /** Value of the checkbox */
  value?: string;
  /** If the checkbox is checked */
  checked?: boolean;
  /** If the checkbox is disabled */
  disabled?: boolean;
  /** Id of the checkbox */
  id?: string;
  /** If the checkbox should render only the label and input, without the description */
  onlyLabelAndInput?: boolean;
};

const Checkbox: FC<Props> = ({
  onChange,
  label,
  name,
  description,
  checked,
  disabled,
  id,
  onlyLabelAndInput,
}) => {
  const htmlId = useId();

  return (
    <StyledCheckbox
      $checked={checked}
      $disabled={disabled}
      key={name}
      $onlyLabelAndInput={onlyLabelAndInput}
    >
      <input
        disabled={disabled}
        type="checkbox"
        id={id || htmlId}
        name={name}
        checked={checked}
        onChange={(event) => {
          onChange && onChange(event);
        }}
      />
      <label htmlFor={id || htmlId}>
        {typeof label === 'string' ? (
          <Text $fontSize="0.75rem" $color={disabled ? theme.color.darkGray : theme.color.black}>
            {label}
          </Text>
        ) : (
          label
        )}
        {onlyLabelAndInput ? null : (
          <Text $fontSize="1rem" $color={disabled ? theme.color.darkGray : theme.color.black}>
            {description ? description : '-'}
          </Text>
        )}
      </label>
    </StyledCheckbox>
  );
};

export default Checkbox;
