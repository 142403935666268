import { ColumnApi, GridApi } from 'ag-grid-community';
import { MutableRefObject, useEffect } from 'react';

import { BulkAction } from '../props';

const useComponentUpdates = (
  reloadData: () => void,
  agGridApiRef: MutableRefObject<GridApi<any> | undefined>,
  agColumnApiRef: MutableRefObject<ColumnApi | undefined>,
  bulkActions?: BulkAction[],
  highlightDuplicates?: boolean,
  defaultFilters?: {
    [prop: string]: any;
  }
) => {
  useEffect(() => {
    if (!agColumnApiRef.current) return;

    // Hide or show checkboxes when bulk actions get added or removed
    if (bulkActions) {
      agColumnApiRef.current.setColumnVisible('prefix-check', true);
    } else {
      agColumnApiRef.current.setColumnVisible('prefix-check', false);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [bulkActions]);

  useEffect(() => {
    if (!agGridApiRef.current) return;

    // Redraw rows if additional functionality is toggled
    agGridApiRef.current.redrawRows();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [highlightDuplicates]);

  useEffect(() => {
    if (!agGridApiRef.current) return;

    // Reload if default filters have changed
    reloadData();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [defaultFilters]);
};

export default useComponentUpdates;
