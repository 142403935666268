import { DateTime } from 'luxon';
import { useCallback } from 'react';

import MaterialIcon from 'core/components/MaterialIcon';

export const ADULT_AGE = 18;

export const isAdult = (age: DateTime, when: DateTime) => {
  return (when.diff(age, 'years').toObject().years || 0) > 18;
};

export const useNotAdultIcon = () => {
  return useCallback((dateOfBirth?: DateTime | null, when: DateTime = DateTime.now()) => {
    if (dateOfBirth && !isAdult(dateOfBirth, when)) {
      return <MaterialIcon icon="escalator_warning" size={1} key="escalator_warning" />;
    }

    return null;
  }, []);
};
