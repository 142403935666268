import { useFormikContext } from 'formik';
import { FC, useCallback, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';

import Modal from 'core/components/Modal';
import Textfield from 'core/components/Textfield';

import { TeamMemberType } from '../../enums';
import { TeamFormData, TeamMemberData } from '../../useTeamInputMapping';

interface Props {
  type: TeamMemberType;
  member?: TeamMemberData;
  index: number;
  onClose: () => void;
}

const CommentsModal: FC<Props> = ({ type, member, index, onClose }) => {
  const { t } = useTranslation();
  const [value, setValue] = useState<string | null>(null);
  useEffect(() => {
    member && setValue(member?.internalComment);
  }, [member]);

  const formik = useFormikContext<TeamFormData>();
  const setFieldValue = useCallback(
    (value: string | null) => {
      formik.setFieldValue(`${type}Members[${index}].internalComment`, value);
    },
    [type, formik, index]
  );

  return (
    <Modal
      ariaLabel={t('Team Member internal comments editing dialog')}
      open={!!member}
      onClose={() => onClose()}
      onConfirm={() => setFieldValue(value)}
      onClear={() => setValue(null)}
    >
      <Textfield
        name={`${type}Members[${index}].internalComment`}
        label={t('Internal Comment')}
        value={value}
        onChange={setValue}
      />
    </Modal>
  );
};

export default CommentsModal;
