import { FC, useCallback, useState } from 'react';
import { useTranslation } from 'react-i18next';

import theme from 'app/theme';
import ContextMenu from 'core/components/ContextMenu';
import IconButton from 'core/components/IconButton';
import { Item as MenuItem } from 'core/components/Menu';
import Modal from 'core/components/Modal';
import Can from 'core/containers/Can';
import { useGetPermission } from 'core/hooks/usePermission';

import { Wrap } from './styled';

export type MainControlItem = 'SEPARATOR' | (MenuItem & { hidden?: boolean });
export interface MainControl {
  confirmation?: string;
  items?: Array<MainControlItem>;
  disabled?: boolean;
  permission: string;
  onClick?: () => void;
  hidden?: boolean;
  type?: HTMLButtonElement['type'];
  form?: string;
  text: string;
  icon: string;
  key: string;
  to?: string;
  active?: boolean;
}

export interface SecondaryControl extends MenuItem {
  permission?: string;
  hidden?: boolean;
  underOriginUser?: boolean;
}

interface Props {
  secondaryControls?: Array<'SEPARATOR' | SecondaryControl>;
  mainControls?: Array<MainControl | 'SEPARATOR'>;
}

const Navigation: FC<Props> = ({ mainControls, secondaryControls }) => {
  const [confirmation, setConfirmation] = useState<SecondaryControl | MainControl | undefined>();
  const getPermission = useGetPermission();
  const { t } = useTranslation();

  const handleConfirmAction = useCallback(() => {
    if (confirmation && 'onClick' in confirmation && confirmation.onClick) {
      confirmation.onClick(confirmation);
    }

    confirmation && setConfirmation(undefined);
  }, [confirmation]);

  const secondary = (secondaryControls || []).filter(
    (it) =>
      it === 'SEPARATOR' ||
      (!it.hidden && (!it.permission || getPermission(it.permission, it.underOriginUser)))
  );

  return (
    <>
      <Wrap>
        <hr />
        {mainControls &&
          mainControls
            .filter((i) => i === 'SEPARATOR' || !i.hidden)
            .map((action, index) => {
              if (action === 'SEPARATOR') {
                return <hr key={`SEPARATOR_${index}`} />;
              }
              return (
                <Can permission={action.permission} key={action.key}>
                  {action.items && action.items.length > 0 ? (
                    <ContextMenu
                      menuItems={(action.items || []).reduce((items, item) => {
                        if (item === 'SEPARATOR') {
                          return items.concat([item]);
                        } else if (!item.hidden) {
                          const onClick = item.confirmation ? setConfirmation : item.onClick;
                          return items.concat([
                            { ...item, onClick: () => onClick && onClick(item) },
                          ]);
                        }

                        return items;
                      }, [] as MainControlItem[])}
                      menuId="mainControls"
                    >
                      <IconButton
                        color={action.active ? theme.color.primary : undefined}
                        icon={action.icon || 'more_horiz'}
                        tooltip={action.text}
                      />
                    </ContextMenu>
                  ) : (
                    <IconButton
                      onClick={() => {
                        action.confirmation
                          ? setConfirmation(action)
                          : action.onClick && action.onClick();
                      }}
                      icon={action.icon}
                      type={action.type}
                      form={action.form}
                      to={action.to}
                      tooltip={action.text}
                      disabled={action.disabled}
                      color={action.active ? theme.color.primary : undefined}
                    />
                  )}
                </Can>
              );
            })}
        {secondary && secondary.length > 0 ? (
          <ContextMenu
            menuItems={secondary.map((i) => {
              if (i === 'SEPARATOR') {
                return i;
              }
              const onClick = i.confirmation ? setConfirmation : i.onClick;
              return { ...i, onClick: () => onClick && onClick(i) };
            })}
            menuId="secondaryControls"
          >
            <IconButton icon="more_horiz" tooltip={t('More')} />
          </ContextMenu>
        ) : null}
      </Wrap>
      <Modal
        ariaLabel={t('Confirmation Dialog')}
        onClose={() => setConfirmation(undefined)}
        onCancel={() => setConfirmation(undefined)}
        onConfirm={handleConfirmAction}
        title={t(`Confirm Action`)}
        open={!!confirmation}
      >
        {confirmation && confirmation.confirmation}
      </Modal>
    </>
  );
};

export default Navigation;
