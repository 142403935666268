import BaseModel from 'core/models/Base';

export enum CovidSrfStatus {
  DISABLED = 0,
  ENABLED = 1,
  ENABLED_OPENED = 2,
  ENABLED_CLOSED = 3,
}

export enum AthleteDocumentNumber {
  MANDATORY = 'mandatory',
  OPTIONAL = 'optional',
  HIDDEN = 'hidden',
}

export type SampleCodeClientOption = {
  min: number;
  max: number;
  alphanumeric: boolean;
};

export default interface Setting extends BaseModel {
  value: string | number | Array<string | number> | null | SampleCodeClientOption;
  key: string;
  type: string;
}

export const DEFAULT_NOTIFICATION_ARRIVAL_DELAY = 5;
