import { DateTime } from 'luxon';
import { useCallback } from 'react';

import { AccreditationsId } from 'app/models/Accreditation';
import { urls } from 'app/urls';
import { stringify } from 'core/functions/qs';

interface AgendaOptions {
  accreditation?: AccreditationsId[];
  dateFrom?: Date;
  dateTo?: Date;
}

export const getDCOAgendaConditions = (options: AgendaOptions) =>
  stringify({
    filters: { accreditation: options.accreditation },
    range:
      options.dateFrom && options.dateTo
        ? {
            from: DateTime.fromJSDate(options.dateFrom).toISODate(),
            to: DateTime.fromJSDate(options.dateTo).toISODate(),
          }
        : undefined,
  });

const useRedirectToAgenda = () => {
  return useCallback((options: AgendaOptions) => {
    window.open(`${urls.agenda}?${getDCOAgendaConditions(options)}`, '_blank');
  }, []);
};

export default useRedirectToAgenda;
