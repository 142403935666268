import Radio, { NativeRadioControl } from '@material/react-radio';
import { ErrorMessage, FieldProps, getIn } from 'formik';
import { FC } from 'react';

import { FeedbackMessage } from 'core/components/FeedbackMessage';
import FieldGroup from 'core/components/FieldGroup';
import { Option } from 'core/components/Select';

import { StyledRadio, RadioInput } from './styled';

interface Props extends FieldProps {
  readOnly?: boolean;
  options: Option[];
  label?: string;
  error?: boolean;
  onChange?: (id: string) => void;
}

const FormRadio: FC<Props> = ({
  form: { setFieldValue, touched, errors },
  readOnly,
  options,
  label,
  field,
  error,
  onChange,
}) => {
  const { name, value } = field;
  const hasError =
    error !== undefined ? error : !!getIn(touched, field.name) && !!getIn(errors, field.name);

  return (
    <FieldGroup hasError={hasError}>
      <RadioInput>
        {label && <label>{label}</label>}
        {options.map((option: Option) => (
          <StyledRadio key={option.id}>
            <Radio label={option.name}>
              <NativeRadioControl
                onChange={() => {
                  if (onChange) {
                    onChange(option.id);
                  } else {
                    setFieldValue(name, option.id);
                  }
                }}
                checked={value === option.id}
                readOnly={readOnly}
                value={option.name}
                id={option.id}
                name={name}
              />
            </Radio>
          </StyledRadio>
        ))}
      </RadioInput>
      {hasError && <ErrorMessage name={field.name} component={FeedbackMessage} />}
    </FieldGroup>
  );
};

export default FormRadio;
