import { FormData } from 'planning/containers/OrderItemBulkEditPage/useBulkOrderItemSchema';

export const TYPE_BULK_EDIT_ORDER_ITEM = 'BULK_EDIT_ORDER_ITEM';
export const bulkEditOrderItem = (
  selection: { [key: string]: any },
  ordersId: number,
  formData: FormData,
  initials: FormData,
  resetFormOnSuccess: () => void,
  outputMapping: (data: FormData) => any
) =>
  ({
    type: TYPE_BULK_EDIT_ORDER_ITEM,
    payload: {
      outputMapping,
      resetFormOnSuccess,
      selection,
      formData,
      initials,
      ordersId,
    },
  }) as const;
export type BulkEditOrderItemAction = ReturnType<typeof bulkEditOrderItem>;

export type OrderActions = BulkEditOrderItemAction;
