import { useFormikContext } from 'formik';
import { useEffect, useMemo } from 'react';

import { AlertMessage, useAlert } from 'core/components/Alert';
import useAppSelector from 'core/hooks/useAppSelector';
import { BLACKLISTS_TYPE } from 'personnel/containers/UsersDetailPage/tabs/BlackListsTab/BlacklistsModal';
import { TeamFormData } from 'planning/components/TeamTab/useTeamInputMapping';
import { TestFormData } from 'planning/containers/TestDetailPage/hooks/useTestSchema';

import useBlacklists from './useBlacklists';

const useTeamBlacklists = (
  type: BLACKLISTS_TYPE,
  id: number | number[] | null | undefined,
  /**
   * Alert message to be set when a currently logged-in user occurs for some record in the
   * fetched blacklists.
   */
  alert: { id: string | number; message: AlertMessage }
) => {
  const user = useAppSelector((state) => state.core.user);
  const { setMessage: setAlertMessage, clearMessage: clearAlertMessage } = useAlert();
  const { values } = useFormikContext<TestFormData>();
  const { assignedMembers, assigneesId } = values;

  const userIds: number[] = useMemo(
    () =>
      Array.from(
        new Set([
          ...((assignedMembers || []) as TeamFormData['assignedMembers']).map(
            (member) => member.usersId
          ),
          ...(assigneesId ? [assigneesId] : []),
          ...(user ? [user.id] : []),
        ])
      ),
    [assignedMembers, assigneesId, user]
  );

  const blacklists = useBlacklists(userIds, type, id);

  useEffect(() => {
    if (blacklists?.some((b) => b.usersId === user?.id)) {
      setAlertMessage(alert.id, alert.message);
    } else {
      clearAlertMessage(alert.id);
    }
  }, [clearAlertMessage, setAlertMessage, blacklists, user?.id, alert.id, alert.message]);

  return blacklists;
};

export default useTeamBlacklists;
