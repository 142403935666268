import { getIn, useFormikContext } from 'formik';
import { FC, useEffect, useMemo } from 'react';
import { useDispatch } from 'react-redux';
import * as Yup from 'yup';
import { SchemaDescription } from 'yup/lib/schema';

import { setTabErrors } from 'core/actions';

interface Props {
  validationSchema: Yup.ObjectSchema<any>;
}

type SchemaWithMeta = {
  [name: string]: SchemaDescription & {
    meta: { tab?: string[] };
  };
};

const TabErrorCollector: FC<Props> = ({ validationSchema }) => {
  const { errors, touched } = useFormikContext<{ [key: string]: unknown }>();
  const dispatch = useDispatch();
  const flatErrors = useMemo(() => {
    const flatErrors = Object.keys(flatten(errors));
    const touchedSet = new Set(Object.keys(flatten(touched)));

    // accept only touched ones
    return flatErrors.filter((e) => touchedSet.has(e));
  }, [errors, touched]);

  const tabErrors = useMemo<string[]>(() => {
    if (!validationSchema) {
      return [];
    }

    const schemaDesc = validationSchema.describe();
    const fields = schemaDesc.fields as SchemaWithMeta;
    const metaTabErrors = new Set<string>();

    flatErrors.forEach((key) => {
      const chain = key.split('.');

      // discover all nested metadata =
      // if key "detail.address.city" has error then check "detail" "detail.address" "detail.address.city" schema fields if there is defined some meta tab
      chain.forEach((i, k, a) => {
        const f = a.slice(0, 1 + k).join('.');
        const tabs: string[] | undefined =
          getIn(fields, `${f}.meta`) && getIn(fields, `${f}.meta.tab`);
        tabs && Array.isArray(tabs) && tabs.forEach((t) => metaTabErrors.add(t));
      });
    });

    return Array.from(metaTabErrors);
  }, [flatErrors, validationSchema]);

  useEffect(() => {
    const errors = tabErrors.concat(flatErrors);
    // concating nested field errors + error tabs, we have list of all items which should be marked with error label
    dispatch(setTabErrors(errors));

    // Other values are referenced indirectly
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [tabErrors.join(), flatErrors.join(), dispatch]);

  return null;
};

/**
 * Higher order function that produces tab meta
 */
export const getTabMeta =
  <T extends string>() =>
  (...tab: T[]) => ({ tab });

export default TabErrorCollector;

function flatten(input: Record<string, any>, reference?: string, output?: Record<string, any>) {
  output = output || {};
  for (let key in input) {
    const value = input[key];
    key = reference ? reference + '.' + key : key;
    if (typeof value === 'object' && value !== null) {
      flatten(value, key, output);
    } else {
      output[key] = value;
    }
  }
  return output;
}
