import styled from 'styled-components/macro';

import theme from 'app/theme';

export const StyledMenu = styled.div`
  padding: 0.3125rem 0;
  min-width: 9.375rem;

  & hr {
    display: block;
    height: 1px;
    margin: 0.25rem auto;
    border: none;
    background-color: ${theme.color.gray200};
  }
`;
