import { ICellRendererParams } from 'ag-grid-community';

import theme, { StatusColor } from 'app/theme';

type Props = ICellRendererParams & {
  color: (data: any) => StatusColor;
  icon?: (data: any) => string;
};

export default class StatusCellRenderer {
  private element?: HTMLDivElement;

  public init({ color, icon, data }: Props) {
    this.element = document.createElement('div');
    const iconName = icon ? icon(data) : theme.color.statusIcons[color(data)];
    this.element.className = 'material-icons icon-' + iconName;
    this.element.style.color = theme.color.status[color(data)];
  }

  public getGui() {
    // Ag-grid should call init before getGui, so we should be fairly sure
    return this.element!;
  }

  public refresh({ color, data, icon }: Props) {
    // Tell grid the whole component needs to be rendered
    if (!this.element) return false;
    const iconName = icon ? icon(data) : theme.color.statusIcons[color(data)];
    this.element.className = 'material-icons icon-' + iconName;
    this.element.style.color = theme.color.status[color(data)];

    // Tell grid we rerendered successfully
    return true;
  }
}
