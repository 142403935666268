import { ColumnApi, GridApi } from 'ag-grid-community';
import { MutableRefObject, useCallback, useEffect, useRef } from 'react';
import { useDebouncedCallback } from 'use-debounce';

import ResizeObserver from 'core/ponyfills/resizeObserver';

import { DataGridApi, Filter, FilterValue, SelectedRows } from '../props';

import { getParamsFromFilters } from './useDataSource';

const useGridChanges = (
  agGridApiRef: MutableRefObject<GridApi | undefined>,
  agColumnApiRef: MutableRefObject<ColumnApi | undefined>,
  filterValues: {
    [key: string]: FilterValue;
  },
  defaultValues: {
    [key: string]: FilterValue;
  },
  reloadData: (force?: boolean) => void,
  disableViews: boolean,
  selectedRows: SelectedRows,
  filters?: {
    [key: string]: Filter;
  },
  defaultFilters?: { [prop: string]: any },
  apiRef?: MutableRefObject<DataGridApi | undefined>
) => {
  const onResize = useDebouncedCallback(() => {
    agGridApiRef.current?.sizeColumnsToFit();
  }, 100);

  const resizeObserverRef = useRef<ResizeObserver | undefined>();

  const onContainerRefChange = useCallback(
    (containerRef: HTMLDivElement) => {
      if (!resizeObserverRef.current) {
        resizeObserverRef.current = new ResizeObserver(onResize);
        resizeObserverRef.current.observe(containerRef);
      }
    },
    [onResize]
  );

  useEffect(() => {
    return () => {
      resizeObserverRef.current?.disconnect();
      resizeObserverRef.current = undefined;
      agGridApiRef.current = undefined;
      agColumnApiRef.current = undefined;
      if (apiRef) {
        apiRef.current = undefined;
      }
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const disableSorting =
    filters &&
    Object.keys(filterValues).some((key) => {
      const filter = filters[key];

      if (!filter?.type || filter.type === 'delimiter') {
        return false;
      }

      const valueChanged = filterValues[key] !== defaultValues[key];
      return 'disablesSorting' in filter && filter.disablesSorting === true && valueChanged;
    });

  const onGridReady = ({ api: agApi, columnApi }: { api: GridApi; columnApi: ColumnApi }) => {
    agGridApiRef.current = agApi;
    agColumnApiRef.current = columnApi;
    if (disableViews || !!Object.keys(filterValues).length) {
      reloadData(false);
    }
  };

  const filtersRef = useRef<{
    selectedRows: SelectedRows;
    filterParams: ReturnType<typeof getParamsFromFilters>;
  }>({
    selectedRows,
    filterParams: getParamsFromFilters(defaultFilters, filters, filterValues, defaultValues),
  });

  filtersRef.current = {
    selectedRows,
    filterParams: getParamsFromFilters(defaultFilters, filters, filterValues, defaultValues),
  };

  const onFirstDataRendered = ({ api }: { api: GridApi }) => {
    api.sizeColumnsToFit();
    if (apiRef) {
      apiRef.current = {
        agApi: api,
        refresh: reloadData,
        getSelectedRows: () => filtersRef.current.selectedRows,
        getFilterParams: () => filtersRef.current.filterParams,
      };
    }
  };

  return {
    onGridReady,
    onFirstDataRendered,
    disableSorting,
    onContainerRefChange,
  };
};

export default useGridChanges;
