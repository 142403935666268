import { toast } from 'react-toastify';
import { put, take, race } from 'redux-saga/effects';

import {
  apiCall,
  DatagridBulkDeleteAction,
  PREFIX_DATAGRID_BULK_DELETE_ENDPOINT,
} from 'core/actions';
import { stringify } from 'core/functions/qs';
import { t } from 'core/i18n';

function* datagridBulkDelete(action: DatagridBulkDeleteAction) {
  const { selection } = action.payload;

  yield put(
    apiCall(
      PREFIX_DATAGRID_BULK_DELETE_ENDPOINT,
      'DELETE',
      `${action.payload.endpoint}/bulk`,
      {},
      {
        params: Array.isArray(selection)
          ? {
              id: selection,
            }
          : selection,
        paramsSerializer: (params) =>
          stringify(params, {
            encodeValuesOnly: true,
          }),
      }
    )
  );

  const { error } = yield race({
    success: take(`${PREFIX_DATAGRID_BULK_DELETE_ENDPOINT}_SUCCESS`),
    error: take(`${PREFIX_DATAGRID_BULK_DELETE_ENDPOINT}_ERROR`),
  });

  if (error) {
    return;
  }

  toast.success(t('Successfully deleted'));

  // We have to do this because of the ag-grid infinite scrolling architecture
  action.payload.successCallback();
}

export default datagridBulkDelete;
