import { DateTime } from 'luxon';
import { useMemo } from 'react';

import { stringify } from 'core/functions/qs';
import { useResources } from 'core/hooks/useResource';
import { UserView } from 'core/models/UserView';

export default function useDatagridUserViews(
  entity: string,
  configuredFilters?: Set<string>,
  disableViews?: boolean
): [UserView[], (force?: boolean) => void, boolean] {
  // TODO: use getResourceUrl
  const {
    data: views,
    reload,
    isLoading,
  } = useResources<UserView>('users/views?' + stringify({ entity }), {
    autoload: !disableViews && configuredFilters && configuredFilters.size > 0,
  });

  const list = useMemo(() => {
    const shared: UserView[] = [];
    const defaults: UserView[] = [];
    const rest: UserView[] = [];

    (views || [])
      // Sort so default is first and shared last
      .forEach((it) => {
        // If the property is DateTime, make it string
        const listItem = {
          ...it,
          data: {
            ...(it?.data || {}),
            filters: Object.fromEntries(
              Object.keys(it?.data?.filters || {}).map((filterName) => {
                let filterValue = it?.data?.filters[filterName];

                if (
                  filterValue &&
                  Array.isArray(filterValue) &&
                  (filterValue[0] as unknown) instanceof Date
                ) {
                  // We need to make range:[DateTime, DateTime] to be string, for proper comparing
                  filterValue = filterValue.map(
                    (it) => DateTime.fromJSDate(it as unknown as Date).toISODate()!
                  );
                }

                return [filterName, filterValue];
              })
            ),
          },
        };

        if (listItem.shared) {
          shared.push(listItem);
        } else if (listItem.default) {
          defaults.push(listItem);
        } else {
          rest.push(listItem);
        }
      });

    const res = [
      ...defaults.sort((a, b) => a.name.localeCompare(b.name)),
      ...rest.sort((a, b) => a.name.localeCompare(b.name)),
      ...shared.sort((a, b) => a.name.localeCompare(b.name)),
    ];

    return configuredFilters
      ? res.filter((f) => Object.keys(f.data.filters).every((k) => configuredFilters.has(k)))
      : res;
  }, [configuredFilters, views]);

  return [list, reload, isLoading];
}
