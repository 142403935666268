import { put, race, take } from '@redux-saga/core/effects';
import { toast } from 'react-toastify';

import { apiCall, loadEntitySuccess } from 'core/actions';
import { t } from 'core/i18n';
import {
  AddMemberStatement,
  TYPE_ADD_MEMBER_STATEMENT,
  TYPE_UNDO_MEMBER_STATEMENT,
  UndoMemberStatement,
} from 'planning/actions/teamMembers';

export function* addMemberStatement(action: AddMemberStatement) {
  const { id, entity, data } = action.payload;

  yield put(apiCall(TYPE_ADD_MEMBER_STATEMENT, 'POST', `/${entity}/${id}/statement`, data));

  const { success, error } = yield race({
    success: take(`${TYPE_ADD_MEMBER_STATEMENT}_SUCCESS`),
    error: take(`${TYPE_ADD_MEMBER_STATEMENT}_ERROR`),
  });

  if (success) {
    if (success.payload.response.data) {
      yield put(loadEntitySuccess(success.payload.response, `/${entity}/${id}`));
    }

    toast.success(t('Invitation response successfully saved'));
    yield put({
      type: `${TYPE_ADD_MEMBER_STATEMENT}_SUCCESS`,
      payload: success.payload,
    });
  }

  if (error) {
    yield put({
      type: `${TYPE_ADD_MEMBER_STATEMENT}_ERROR`,
      payload: error.payload,
    });
    toast.error(t('Invitation response failed'));
  }
}

export function* undoMemberStatement(action: UndoMemberStatement) {
  const { id, entity } = action.payload;

  yield put(apiCall(TYPE_UNDO_MEMBER_STATEMENT, 'POST', `/${entity}/${id}/statement/undo`));

  const { success, error } = yield race({
    success: take(`${TYPE_UNDO_MEMBER_STATEMENT}_SUCCESS`),
    error: take(`${TYPE_UNDO_MEMBER_STATEMENT}_ERROR`),
  });

  if (success) {
    if (success.payload.response.data) {
      yield put(loadEntitySuccess(success.payload.response, `/${entity}/${id}`));
    }

    toast.success(t('Undo statement successful'));
    yield put({
      type: `${TYPE_UNDO_MEMBER_STATEMENT}_SUCCESS`,
      payload: success.payload,
    });
  }

  if (error) {
    yield put({
      type: `${TYPE_UNDO_MEMBER_STATEMENT}_ERROR`,
      payload: error.payload,
    });
    toast.error(t('Undo statement failed'));
  }
}
