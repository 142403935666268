import styled from 'styled-components/macro';

import theme from 'app/theme';

export const FieldGroup = styled.div<{ hasError: boolean }>`
  > span {
    color: ${(p) => (p.hasError ? theme.color.error : theme.color.gray200)};
  }

  input {
    border: 1px solid ${(p) => (p.hasError ? theme.color.error : theme.color.gray200)};
    &:not(:placeholder-shown) + span {
      color: ${(p) => (p.hasError ? theme.color.error : theme.color.secondary800)};
    }
  }
`;
