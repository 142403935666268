import { useCallback, useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch } from 'react-redux';

import { EntityKey, entities, getEntityPermission } from 'app/entity';
import { saveWatchers } from 'core/actions';
import { MainControl, MainControlItem } from 'core/components/PageHeader/Navigation';
import useAppSelector from 'core/hooks/useAppSelector';
import { useSetModal } from 'core/hooks/useModal';
import usePermission from 'core/hooks/usePermission';
import { useResources } from 'core/hooks/useResource';
import Watcher from 'core/models/Watcher';

import useWatchersUrls from './useWatchersUrls';

const useWatchersAction: (entityKey: EntityKey) => MainControl = (entityKey) => {
  const { t } = useTranslation();
  const entityPermissionName = getEntityPermission(entityKey);
  const setModal = useSetModal();
  const watcherUrls = useWatchersUrls(entities[entityKey].api('').list);
  const dispatch = useDispatch();
  const canSeeWatchers = usePermission(`${entityPermissionName}:watchers[actions:find]`);
  const canEditWatchers = usePermission(`${entityPermissionName}:watchers[actions:patch]`);
  const canEditAllWatchers = usePermission(`${entityPermissionName}:watchers[scope:all]`);
  const canDeleteWatchers = !!canEditWatchers && !!canEditAllWatchers;

  const entityId = useAppSelector(({ core: { entityData } }) => entityData && entityData.id);
  const currentUsersId: number = useAppSelector(({ core }) => core.user!.id);

  const endpoint = watcherUrls.watchersList(entityId || 0);

  const { data: watchers, reload } = useResources<Watcher>(endpoint || '', {
    autoload: !!entityId && canSeeWatchers,
  });

  const currentWatcherIds = useMemo(() => (watchers || []).map((it) => it.usersId), [watchers]);

  const isUserWatcher = useMemo(
    () => (watchers || []).some((it) => it.usersId === currentUsersId),
    [currentUsersId, watchers]
  );

  const handleSaveWatchers = useCallback(
    (newIds: number[], showSuccess: boolean) => {
      dispatch(saveWatchers(endpoint, newIds, showSuccess, reload));
    },
    [dispatch, endpoint, reload]
  );

  const subItems: (MainControlItem | 'SEPARATOR')[] = useMemo(() => {
    return [
      ...(canEditWatchers
        ? ([
            {
              key: `startStopWatching`,
              text: isUserWatcher ? t('Stop Watching') : t('Start Watching'),
              icon: isUserWatcher ? 'visibility_off' : 'visibility',
              onClick: () => {
                const newWatcherIds = isUserWatcher
                  ? [...currentWatcherIds.filter((it) => it !== currentUsersId)]
                  : [...currentWatcherIds, currentUsersId];
                handleSaveWatchers(newWatcherIds, false);
              },
            },
            'SEPARATOR',
          ] as (MainControlItem | 'SEPARATOR')[])
        : []),
      ...(watchers || []).map((watcher) => ({
        key: `watcher-${watcher.usersId}`,
        text: watcher.user?.fullName || '-',
        icon: 'visibility',
        hoverIcon: 'visibility_off',
        disabled: !canDeleteWatchers,
        confirmation: t('Are you sure you want to remove this watcher?'),
        onClick: () => {
          const newWatcherIds = [...currentWatcherIds.filter((it) => it !== watcher.usersId)];
          handleSaveWatchers(newWatcherIds, true);
        },
      })),
      ...(!watchers?.length
        ? [
            {
              key: `emptyWatchers`,
              text: t('No watchers yet'),
              icon: 'people_alt',
              disabled: true,
            },
          ]
        : []),
      ...(canEditWatchers
        ? ([
            'SEPARATOR',
            {
              key: `manageWatchers`,
              text: canDeleteWatchers ? t('Manage Watchers') : t('Add Watchers'),
              icon: canDeleteWatchers ? 'edit' : 'add',
              onClick: () => {
                setModal('WATCHERS');
              },
            },
          ] as (MainControlItem | 'SEPARATOR')[])
        : []),
    ];
  }, [
    t,
    isUserWatcher,
    setModal,
    currentUsersId,
    currentWatcherIds,
    handleSaveWatchers,
    watchers,
    canDeleteWatchers,
    canEditWatchers,
  ]);

  return {
    permission: `${entityPermissionName}:watchers[actions:find]`,
    icon: 'visibility',
    text: t('Watchers'),
    key: 'watchers',
    active: isUserWatcher,
    hidden: !entityId || !canSeeWatchers,
    items: subItems,
  };
};

export default useWatchersAction;
