import { FC, useRef, useEffect } from 'react';
import { useTranslation } from 'react-i18next';

import MaterialIcon from 'core/components/MaterialIcon';
import Tooltip from 'core/components/Tooltip';
import useAppSelector from 'core/hooks/useAppSelector';

import { Bell, Notifications, NotificationsBadge, bellAnimationDuration } from './styled';

interface Props {
  onOpen?: () => void;
  expand: boolean;
}

const NotificationsIcon: FC<Props> = ({ onOpen, expand }) => {
  const { t } = useTranslation();
  const count = useAppSelector(({ core }) => core.notifications.unread);
  const bellRef = useRef<HTMLElement | null>(null);

  useEffect(() => {
    if (count > 0 && bellRef.current) {
      if (bellRef.current && bellRef.current.classList.contains('ring')) {
        return;
      }
      bellRef.current && bellRef.current.classList.add('ring');
      setTimeout(() => {
        bellRef.current && bellRef.current.classList.remove('ring');
      }, bellAnimationDuration + 50);
    }
  }, [count]);

  return (
    <Tooltip content={t('Open Notifications') as string} placement="right">
      <div>
        <Notifications $expand={expand} onClick={onOpen}>
          <Bell ref={bellRef}>
            <MaterialIcon icon="notifications" />
          </Bell>
          {count > 0 && (
            <NotificationsBadge data-cy="notifications-badge">
              {count > 99 ? '99+' : count}
            </NotificationsBadge>
          )}
        </Notifications>
      </div>
    </Tooltip>
  );
};

export default NotificationsIcon;
