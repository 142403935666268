import { FC, useCallback, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch } from 'react-redux';

import IconLoader from 'app/components/IconLoader';
import { deleteFile, downloadData, saveFileDocument } from 'core/actions';
import Button from 'core/components/Button';
import Form from 'core/components/Form';
import { Row } from 'core/components/Grid';
import { FullRowCell } from 'core/components/GridCell';
import Modal from 'core/components/Modal';
import StyledButtonWrapper from 'core/components/StyledButtonWrapper';
import useAppSelector from 'core/hooks/useAppSelector';
import useResource from 'core/hooks/useResource';
import FileAttachment from 'core/models/FileAttachment';

import useSchema, { FormData } from '../AddFilesDialog/useSchema';
import { DetailModeEnum } from '../FormDetailPage';

import Fieldset from './Fieldset';
import useUserCanModify from './useUserCanModify';

type Props = {
  onClose: () => void;
  open: boolean;
  fileId: number;
  onSuccessDelete?: () => void;
  onSuccessSave?: () => void;
  hideAssignTo?: boolean;
  detailUrl?: (id: number) => string;
  listUrl?: string;
  downloadUrl?: (id: number) => string;
  permissionController: string;
  unrestrictedRoleIds?: number[];
};

const EditFilesDialog: FC<Props> = ({
  open,
  onClose,
  fileId,
  onSuccessDelete,
  onSuccessSave,
  hideAssignTo,
  detailUrl,
  listUrl,
  downloadUrl,
  permissionController,
  unrestrictedRoleIds,
}) => {
  const { t } = useTranslation();

  const dispatch = useDispatch();
  const processedDetailUrl = detailUrl ? detailUrl(fileId) : `files/${fileId}`;

  const dispatchSave = useCallback(
    (fd: FormData, prevFile: FormData, id: number, sc: () => void) =>
      dispatch(saveFileDocument(fd, prevFile, id, sc, listUrl, processedDetailUrl)),
    [listUrl, dispatch, processedDetailUrl]
  );

  const dispatchDeleteFile = useCallback(
    (fileId: number, sc: () => void) => dispatch(deleteFile(fileId, sc, processedDetailUrl)),
    [dispatch, processedDetailUrl]
  );

  const dispatchDownloadFile = useCallback(
    (fileId: number, fileName: string) =>
      dispatch(
        downloadData(
          downloadUrl ? downloadUrl(fileId) : `/files/${fileId}/download`,
          '*/*',
          fileName
        )
      ),
    [dispatch, downloadUrl]
  );

  const saveInProgress = useAppSelector((state) => state.core.entitySaveInProgress);

  const { data: fileDetail } = useResource<FileAttachment>(processedDetailUrl, {
    cache: false,
    stale: false,
  });

  const { canEdit, canDelete } = useUserCanModify(permissionController, fileDetail?.createdBy);

  const schema = useSchema(DetailModeEnum.EDIT);
  const initialValues = schema.cast({
    ...(fileDetail || {}),
  });
  const [confirmDeleteOpen, setConfirmDelete] = useState(false);

  const closeDialogIfCan = useCallback(() => {
    !saveInProgress && onClose();
  }, [saveInProgress, onClose]);

  return (
    <>
      <Modal
        ariaLabel={t('Detail dialog')}
        title={t('File attachment detail')}
        open={open}
        onClose={closeDialogIfCan}
        cancelButton={null}
        confirmButton={null}
      >
        {!fileDetail && <IconLoader />}
        {fileDetail && (
          <Form
            initialValues={initialValues}
            validationSchema={schema}
            id="fileAttachmentForm"
            viewMode={!canEdit}
            onSubmit={(values, formik) => {
              const prevData = schema.cast(fileDetail, { stripUnknown: true });
              const requestData = schema.cast(values, { stripUnknown: true });
              dispatchSave(requestData, prevData, fileId, () => {
                onSuccessSave && onSuccessSave();
                formik.resetForm();
                onClose();
              });
            }}
          >
            <Row>
              <Fieldset
                downloadFile={dispatchDownloadFile}
                fileDetail={fileDetail}
                hideAssignTo={hideAssignTo}
                unrestrictedRoleIds={unrestrictedRoleIds || []}
              />

              <FullRowCell>
                <StyledButtonWrapper>
                  {canDelete ? (
                    <Button
                      text={t('Delete File')}
                      onClick={() => setConfirmDelete(true)}
                      type="button"
                      disabled={saveInProgress}
                    />
                  ) : (
                    <div></div>
                  )}
                  <div>
                    <Button
                      disabled={saveInProgress}
                      text={t('Cancel')}
                      onClick={closeDialogIfCan}
                      type="button"
                    />
                    {canEdit && <Button disabled={saveInProgress} type="submit" text={t('Save')} />}
                  </div>
                </StyledButtonWrapper>
              </FullRowCell>
            </Row>
          </Form>
        )}
      </Modal>

      <Modal
        ariaLabel={t('Deletion confirmation dialog')}
        title={t('Delete File')}
        open={confirmDeleteOpen}
        onClose={() => setConfirmDelete(false)}
        onConfirm={() => {
          dispatchDeleteFile(fileDetail!.id, () => {
            onSuccessDelete && onSuccessDelete();
            onClose();
          });
        }}
        onCancel={() => setConfirmDelete(false)}
      >
        {t('Are you sure you want to permanently delete this File?')}
      </Modal>
    </>
  );
};

export default EditFilesDialog;
