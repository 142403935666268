import { GridApi } from 'ag-grid-community';
import { MutableRefObject, useCallback, useRef } from 'react';

const useDuplicateRowsHighlight = (
  agGridApiRef: MutableRefObject<GridApi<any> | undefined>,
  fieldToCheck?: string
) => {
  // Duplicate row ids precalculated once when loaded data change
  // Should not influence rendering (reason for not being in state) as they are used in procedural code
  const duplicateRows = useRef(new Set<unknown>());

  // Combines existing and new data and updates duplicate row ids in duplicateRows
  const updateDuplicateRows = useCallback(
    (data: Array<{ [key: string]: unknown }>) => {
      // Reset duplicate rows - they can't be reused - we have to do linear search anyway
      duplicateRows.current = new Set<unknown>();

      // Skip if it is not configured
      if (!fieldToCheck) return;

      // Already seen value: id pairs
      const seenValues: { [value in string | number]: string } = {};

      const processValue = (value: string | number, id: string) => {
        if (seenValues[value]) {
          // Add currently checked row and the row it duplicates
          duplicateRows.current.add(id);
          duplicateRows.current.add(seenValues[value]);
          return;
        }
        seenValues[value] = id;
      };

      // Existing values
      agGridApiRef.current?.forEachNode((node) => {
        if (!node?.data?.[fieldToCheck] || !node?.id) return;
        processValue(node.data[fieldToCheck], node.id);
      });

      // New values values
      data.forEach((row) => {
        const id = row?.id as number | string;
        id &&
          row?.[fieldToCheck] &&
          processValue(row?.[fieldToCheck] as string | number, id.toString());
      });
    },
    [agGridApiRef, fieldToCheck]
  );

  const hasDuplicate = useCallback((id: string) => duplicateRows.current.has(id), []);

  return {
    updateDuplicateRows,
    hasDuplicate,
  };
};

export default useDuplicateRowsHighlight;
