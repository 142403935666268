import { useCallback } from 'react';

import { SportDisciplinesBlacklistsFormData, AthletesBlacklistsFormData } from './useSchema';

import { BLACKLISTS_TYPE } from '.';

const useOutputMapping = () => {
  return useCallback(
    (
      formData: SportDisciplinesBlacklistsFormData | AthletesBlacklistsFormData,
      type?: BLACKLISTS_TYPE
    ) => {
      if (type === BLACKLISTS_TYPE.SPORT_DISCIPLINES) {
        const sportDisciplinesFd = formData as SportDisciplinesBlacklistsFormData;

        return {
          sportDisciplinesId: sportDisciplinesFd.sportDisciplinesId,
          comments: sportDisciplinesFd.comments,
          usersId: sportDisciplinesFd.usersId,
        };
      }

      return {
        ...formData,
      } as const;
    },
    []
  );
};

export default useOutputMapping;
