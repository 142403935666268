import { useCallback } from 'react';

import { Option, SimpleOption } from 'core/components/DropDown';
import Laboratory from 'lists/models/Laboratory';

export const useLaboratoryDropdownReducer = () => {
  return useCallback((list: Laboratory[], selectedVal: number): Option[] => {
    const options: SimpleOption[] = list.reduce((acc, it) => {
      if (it.active === undefined || it.active || it.id === selectedVal) {
        const countryName = it.countryName ? ` - ${it.countryName}` : '';
        acc.push({
          id: it.id,
          name: it.fullName,
          secondary: `${it.shortName}${countryName}`,
        });
      }
      return acc;
    }, [] as SimpleOption[]);

    return options;
  }, []);
};
