/**
 * The purpose of this class is to provide a fallback mechanism for the
 * ResizeObserver API by using the window.resize event when ResizeObserver is not supported.
 *
 * If we will have any new use cases in the future it is worth pausing and deciding whether
 * extending this class makes sense compared to using a complete polyfill
 * https://github.com/juggle/resize-observer.
 */
class ResizeObserverController {
  private supported: boolean;
  private resizeObserverRef?: ResizeObserver;

  constructor(private onResize: () => void) {
    this.supported = typeof ResizeObserver !== 'undefined';
    if (this.supported) {
      this.resizeObserverRef = new ResizeObserver(onResize);
    }
  }

  observe(target: Element, options?: ResizeObserverOptions) {
    if (this.supported) {
      this.resizeObserverRef?.observe(target, options);
    } else {
      window.addEventListener('resize', this.onResize);
    }
  }

  disconnect() {
    if (this.supported) {
      this.resizeObserverRef?.disconnect();
    } else {
      window.removeEventListener('resize', this.onResize);
    }
  }
}

export default ResizeObserverController;
