import { useMemo } from 'react';

import { Option } from 'core/components/DropDown';
import { ResourceDropDownFilter, FilterValue } from 'core/containers/DataGrid';

const useMultiDropdownResourceFilter = (
  label: string,
  resource: string,
  property: string,
  canBeEmpty = true,
  customReducer?: ((resource: any[], value: FilterValue) => Option[]) | undefined
): ResourceDropDownFilter => {
  return useMemo(
    () => ({
      type: 'resourceDropdown',
      label: label,
      defaultValue: ['any'],
      applyToParams: (value) => defaultApplyToParams(value, property),
      resource: resource,
      single: false,
      canBeEmpty,
      reducer: customReducer,
    }),
    [canBeEmpty, label, property, resource, customReducer]
  );
};

export const defaultApplyToParams = (value: FilterValue, property: string) => {
  if (!Array.isArray(value) || value.includes('any')) return {};

  const hasEmpty = value.includes('noValue');

  const condition = hasEmpty
    ? { [property]: { $or: ['\\x00', ...value.filter((v) => v !== 'noValue')] } }
    : { [property]: value };

  return condition;
};

export default useMultiDropdownResourceFilter;
