import { push } from 'connected-react-router';
import { toast } from 'react-toastify';
import { put, race, take } from 'redux-saga/effects';

import { urls } from 'app/urls';
import {
  ActivateAccountAction,
  TYPE_ACTIVATE_ACCOUNT,
  TYPE_ACTIVATE_ACCOUNT_SUCCESS,
  TYPE_ACTIVATE_ACCOUNT_ERROR,
  apiCall,
} from 'core/actions';
import { t } from 'core/i18n';

function* activateAccountSaga(action: ActivateAccountAction) {
  yield put(
    apiCall(
      TYPE_ACTIVATE_ACCOUNT,
      'POST',
      `/auth/activate-account/${action.payload.code}`,
      action.payload.data
    )
  );

  const { success } = yield race({
    success: take(TYPE_ACTIVATE_ACCOUNT_SUCCESS),
    error: take(TYPE_ACTIVATE_ACCOUNT_ERROR),
  });

  if (success) {
    yield put(push(urls.login));
    toast.success(t('Account activated'));
  }
}

export default activateAccountSaga;
