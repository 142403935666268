import { FC, memo } from 'react';
import { useTranslation } from 'react-i18next';

import { FullRowCell } from 'core/components/GridCell';
import PaperContainer from 'core/components/PaperContainer';

import BloodFields from './BloodFields';
import UrineFields from './UrineFields';

interface Props {
  onRequestEditAnalyses?: () => void;
  /** enable dried blood spots = this param filters blood analysis if its not enabled   */
  enableDBS: boolean;
  hideNonAnalysesFields?: boolean;
}

const AnalysesFields: FC<Props> = ({
  hideNonAnalysesFields = false,
  enableDBS,
  onRequestEditAnalyses,
}) => {
  const { t } = useTranslation();

  return (
    <>
      <FullRowCell>
        <PaperContainer title={t('Blood')}>
          <BloodFields
            hideNonAnalysesFields={hideNonAnalysesFields}
            enableDBS={enableDBS}
            onRequestEditAnalyses={onRequestEditAnalyses}
          />
        </PaperContainer>
      </FullRowCell>

      <FullRowCell>
        <PaperContainer title={t('Urine')}>
          <UrineFields
            hideNonAnalysesFields={hideNonAnalysesFields}
            onRequestEditAnalyses={onRequestEditAnalyses}
          />
        </PaperContainer>
      </FullRowCell>
    </>
  );
};

export default memo(AnalysesFields);

export * from './useAnalysesFieldsSchema';
