import { push } from 'connected-react-router';
import { Location } from 'history';
import { ReactElement, ReactNode, useCallback, useEffect, useMemo, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { shallowEqual, useDispatch } from 'react-redux';
import { Action } from 'redux';
import * as Yup from 'yup';

import { StatusColor } from 'app/theme';
import {
  EntityRequest,
  createEntity,
  deleteEntity,
  editEntity,
  loadEntity,
  unloadEntity,
} from 'core/actions';
import DetailSubheader from 'core/components/DetailSubheader';
import { TabOption } from 'core/components/DetailSubheader/Tabs';
import LoadingPage from 'core/components/LoadingPage';
import { Item as MenuItem } from 'core/components/Menu';
import Modal from 'core/components/Modal';
import Page from 'core/components/Page';
import { MainControl, SecondaryControl } from 'core/components/PageHeader/Navigation';
import IncludeResourcesProvider from 'core/components/ResourceFormDropdown/IncludeResourcesProvider';
import ApiCache from 'core/hooks/useApiCall/ApiCache';
import useAppSelector from 'core/hooks/useAppSelector';
import { useGetPermission } from 'core/hooks/usePermission';
import Archivable from 'core/models/Archivable';
import BaseModel from 'core/models/Base';
import { StandardApi, StandardUrls } from 'core/module';

import DetailContext from './DetailContext';
import EntityNotFoundPage from './EntityNotFoundPage';
import usePreviousDatagridParams from './usePreviousDatagridParams';

export enum DetailModeEnum {
  EDIT = 'edit',
  CREATE = 'create',
  CLONE = 'clone',
}
export const FORM_ID = 'detailForm';

type renderFn<Model, FormData, RequestData, ValidData> = (
  props: ChildrenProps<Model, FormData, RequestData, ValidData>
) => ReactElement<Props<Model, FormData, RequestData, ValidData>>;

export type Props<Model, FormData, WriteRequestData, ValidFormData> = {
  /**
   * Custom Entity API services
   */
  api: StandardApi;

  /**
   * Common Entity frontend urls
   */
  urls: StandardUrls;

  /**
   * Entity permission name
   */
  permission: string;

  /**
   * Human readable Entity name
   */
  entityName: string;

  /**
   * Mapping from the fetched entity to form data
   */
  inputMapping: (entity: undefined | Model, mode: DetailModeEnum) => FormData;
  /**
   * Mapping from the fetched entity to form data
   */
  outputMapping: (
    formData: ValidFormData,
    mode: DetailModeEnum,
    entityData?: Model
  ) => WriteRequestData;
  /**
   * Child should be a lambda taking the props and rendering the form
   */
  children: ReactNode | renderFn<Model, FormData, WriteRequestData, ValidFormData>;
  /**
   * Custom onEdit action creator overriding the default one
   */
  onCreateAction?: <WriteRequestData>(
    req: EntityRequest<WriteRequestData>,
    listUrl: string,
    detailUrl: (id: number) => string
  ) => Action;
  /**
   * Custom onEdit action creator overriding the default one
   */
  onEditAction?: (req: EntityRequest<WriteRequestData>, prevEntity: WriteRequestData) => Action;
  /**
   * Custom onDelete action creator overriding the default one
   */
  onDeleteAction?: (id: number, endpoint: string, successCallback: () => void) => Action;

  /**
   * Optionally specify custom main controls
   *
   * If not used, contains "Save" button
   */
  mainControls?: (
    props: ChildrenProps<Model, FormData, WriteRequestData, ValidFormData>
  ) => Array<MainControl | 'SEPARATOR'>;
  /**
   * Optionally specify custom secondary controls
   *
   * If not used, contains "Clone" and "Delete"
   */
  secondaryControls?: (
    props: ChildrenProps<Model, FormData, WriteRequestData, ValidFormData>
  ) => SecondaryControl[];

  /**
   * Use different back control
   *
   * Defaults to list key from URLs
   */
  customBackControl?: { to: string; text: string } | null;

  /**
   * Should be subheader displayed
   *
   * Defaults to true
   */
  displaySubheader?: boolean;

  /**
   * Subheader options
   */
  tabs?: (entity?: Model) => TabOption[];

  /** Custom page title and document title, if the document title is not defined */
  title: (entity?: Model) => ReactNode;

  /** Document title */
  documentTitle?: (entity?: Model) => string;

  /** Custom status color */
  statusColor?: (entity?: Model) => StatusColor;
  /** Custom status text */
  statusText?: (entity?: Model) => string;
  /** Form mode */
  mode: DetailModeEnum;
  /** target entity id */
  id?: number;
  /** Resource dropdown mapping (automatic context provided by EntityData)  */
  includeResourceMapping?: { [key: string]: string };
  /** Resource dropdown source override - source defaults to entityData */
  includeResourceOverride?: { [key: string]: unknown };
  /**
   * Disable <ApiCache> wrapper (aggregate apiClean action - trigger on FormDetailPage unmount)
   */
  disableCache?: boolean;
};

export type ChildrenProps<
  Model,
  FormData = Record<string, any>,
  WriteRequestData = Record<string, any>,
  ValidFormData = FormData,
> = Props<Model, FormData, WriteRequestData, ValidFormData> & {
  id: undefined | number;
  mode: DetailModeEnum;
  hasCreatePermission: boolean;
  hasUpdatePermission: boolean;
  hasDeletePermission: boolean;
  entityData?: Model;
  formData: FormData;
  entitySaveInProgress: boolean;
  onEdit: (formData: ValidFormData) => void;
  onDelete: () => void;
  onCreate: (formData: ValidFormData) => void;
  reload: () => void;
  shouldBlockNextLocation: (location: Location) => boolean;
  viewMode: boolean;
};

function FormDetailPage<
  Model extends BaseModel,
  FormData,
  WriteRequestData,
  ValidFormData extends FormData = FormData,
>(
  props: Props<Model, FormData, WriteRequestData, ValidFormData>
): ReactElement<Props<Model, FormData, WriteRequestData, ValidFormData>> {
  const {
    id,
    api,
    entityName,
    urls,
    permission,
    mode,
    outputMapping,
    displaySubheader = true,
    includeResourceMapping,
    includeResourceOverride,
    children,
    customBackControl,
    disableCache,
  } = props;
  const [isDeleteOpen, setIsDeleteOpen] = useState(false);
  const getPermission = useGetPermission();
  const { t } = useTranslation();
  const endpoint = id ? api.detail(id) : api.list;

  const { entitySaveInProgress, entityData, entityDataLoading } = useAppSelector(
    ({ core: { entitySaveInProgress, entityData, entityDataSource, entityDataLoading } }) => {
      const ourData = entityDataSource === endpoint;

      return {
        entitySaveInProgress,
        entityData: (ourData && (entityData as Model)) || undefined,
        entityDataLoading,
      };
    },
    shallowEqual
  );

  const formData = props.inputMapping(entityData || undefined, mode) as ValidFormData;

  const dispatch = useDispatch();
  const createEntityAction = (props.onCreateAction ||
    createEntity) as unknown as typeof createEntity;

  const leaveFormDialogEnabled = useRef(true);

  const shouldBlockNextLocation = useCallback(
    (newLocation: Location) => {
      // ignore prompt when deleted
      if (!leaveFormDialogEnabled.current) {
        return false;
      }

      const isClone =
        // is clone
        mode === DetailModeEnum.CLONE &&
        // continue with clone
        new RegExp(`^${urls.clone('[0-9]+')}`).test(newLocation.pathname);

      const isCreate =
        // is create
        mode === DetailModeEnum.CREATE &&
        // continue with create
        new RegExp(`^${urls.create}`).test(newLocation.pathname);

      // suppress leave prompt between tabs
      if (isCreate || isClone) {
        return false;
      }

      // suppress leave prompt for:
      // 1. stay on edit (just tabs)
      // 2. move from clone/create -> edit
      return !new RegExp(`^${urls.detail('[0-9]+')}`).test(newLocation.pathname);
    },
    [mode, urls]
  );

  const onCreate = useCallback(
    (formData: ValidFormData) =>
      !entitySaveInProgress &&
      dispatch(
        createEntityAction(
          {
            data: outputMapping(formData, mode, entityData),
            endpoint: api.list,
          },
          urls.list,
          urls.detail
        )
      ),
    [
      entitySaveInProgress,
      dispatch,
      createEntityAction,
      outputMapping,
      entityData,
      api.list,
      urls.list,
      urls.detail,
      mode,
    ]
  );
  const editEntityAction = (props.onEditAction || editEntity) as unknown as typeof editEntity;
  const onEdit = useCallback(
    (newFormData: ValidFormData) => {
      if (id && !entitySaveInProgress) {
        const action = editEntityAction(
          {
            id,
            data: outputMapping(newFormData, mode, entityData) as {},
            endpoint: api.detail(id),
          },
          outputMapping(formData, mode, entityData) as {} // We are sending entity data mainly for cloning
        );

        dispatch(action);
      }
    },
    [
      entitySaveInProgress,
      editEntityAction,
      id,
      outputMapping,
      entityData,
      api,
      formData,
      dispatch,
      mode,
    ]
  );
  const deleteAction = props.onDeleteAction || deleteEntity;
  const onDelete = useCallback(
    () =>
      dispatch(
        deleteAction(id!, endpoint, () => {
          leaveFormDialogEnabled.current = false;
          dispatch(push(urls.list));
        })
      ),
    [deleteAction, dispatch, endpoint, id, urls.list]
  );
  const previousUrlWithParams = usePreviousDatagridParams(urls.list);

  const handleLoadEntity = useCallback(() => {
    mode !== 'create' && dispatch(loadEntity(endpoint));
  }, [dispatch, endpoint, mode]);

  useEffect(() => {
    handleLoadEntity();
    return () => {
      dispatch(unloadEntity());
    };
  }, [id, dispatch, handleLoadEntity]);

  const isEdit = props.mode === 'edit';
  const hasCreatePermission = getPermission(`${permission}:create`);
  const hasUpdatePermission =
    getPermission(`${permission}:patch`) || getPermission(`${permission}:put`);
  const hasDeletePermission = getPermission(`${permission}:delete`);

  const defaultBack = !!urls.list && {
    text: t('Back to List'),
    to: previousUrlWithParams || urls.list,
  };

  const backControl = customBackControl || (customBackControl !== null && defaultBack) || undefined;
  const defaultSaveButton = useMemo(
    () => [
      {
        permission: isEdit ? `${permission}:patch` : `${permission}:create`,
        key: 'save',
        icon: 'save',
        text: entitySaveInProgress ? t('Saving...') : t('Save'),
        type: 'submit' as const,
        disabled: entitySaveInProgress,
        form: FORM_ID,
      },
    ],
    [entitySaveInProgress, isEdit, permission, t]
  );

  const includeResourcesSource = useMemo(
    () =>
      entityData || includeResourceOverride
        ? ({ ...entityData, ...includeResourceOverride } as BaseModel | undefined)
        : undefined,
    [entityData, includeResourceOverride]
  );

  const childrenProps: ChildrenProps<Model, FormData, WriteRequestData, ValidFormData> = useMemo(
    () => ({
      ...props,
      id: Number(props.id) || undefined,
      hasCreatePermission,
      hasUpdatePermission,
      hasDeletePermission,
      viewMode:
        (mode === 'create' && !hasCreatePermission) ||
        (['edit', 'clone'].includes(mode) && !hasUpdatePermission),
      entityData,
      entitySaveInProgress,
      formData,
      onEdit,
      onCreate,
      onDelete,
      shouldBlockNextLocation,
      reload: handleLoadEntity,
    }),
    [
      entityData,
      entitySaveInProgress,
      formData,
      handleLoadEntity,
      hasCreatePermission,
      hasDeletePermission,
      hasUpdatePermission,
      mode,
      onCreate,
      onDelete,
      onEdit,
      props,
      shouldBlockNextLocation,
    ]
  );

  const mainControls: Array<MainControl | 'SEPARATOR'> = useMemo(
    () => (props.mainControls ? props.mainControls(childrenProps) : defaultSaveButton),
    [childrenProps, defaultSaveButton, props]
  );

  if (entityDataLoading) {
    return <LoadingPage />;
  }

  if (!entityDataLoading && isEdit && !entityData) {
    return <EntityNotFoundPage backControl={backControl} entityName={entityName} id={id} />;
  }

  const secondaryControls: MenuItem[] = props.secondaryControls
    ? props.secondaryControls(childrenProps)
    : [];
  if (!props.secondaryControls) {
    if (isEdit && entityData) {
      if (hasCreatePermission && 'clone' in urls) {
        secondaryControls.push({
          key: 'clone',
          icon: 'file_copy',
          text: t('Clone'),
          to: urls.clone!(entityData.id),
        });
      }

      if (hasDeletePermission) {
        secondaryControls.push({
          key: 'delete',
          text: t('Delete'),
          icon: 'delete',
          onClick: () => setIsDeleteOpen(true),
        });
      }
    }
  }

  const isArchivable = (entity?: BaseModel | Archivable): entity is Archivable =>
    !!entity && 'archived' in entity;

  return (
    <ApiCache disable={disableCache}>
      <Page
        backControl={backControl}
        secondaryControls={secondaryControls}
        mainControls={mainControls}
        title={props.title(entityData)}
        documentTitle={props.documentTitle && props.documentTitle(entityData)}
      >
        <IncludeResourcesProvider source={includeResourcesSource} mapping={includeResourceMapping}>
          {displaySubheader && (
            <DetailSubheader
              archived={isArchivable(entityData) && entityData.archived}
              modified={entityData?.modifiedAt || undefined}
              created={entityData?.createdAt || undefined}
              statusColor={
                mode === 'edit' && props.statusColor ? props.statusColor(entityData) : undefined
              }
              statusText={
                mode === 'edit' && props.statusText ? props.statusText(entityData) : undefined
              }
              options={props.tabs && props.tabs(entityData)}
            />
          )}
          <DetailContext.Provider value={childrenProps}>
            {typeof children === 'function' ? children(childrenProps) : children}
          </DetailContext.Provider>
          <Modal
            ariaLabel={t('Deletion confirmation dialog')}
            title={t('Delete {{entity}}', { entity: entityName })}
            onConfirm={() => onDelete()}
            onClose={() => setIsDeleteOpen(false)}
            onCancel={() => setIsDeleteOpen(false)}
            open={isDeleteOpen}
          >
            {t('Are you sure you want to permanently delete this {{entity}}?', {
              entity: entityName,
            })}
          </Modal>
        </IncludeResourcesProvider>
      </Page>
    </ApiCache>
  );
}

/**
 * Helper for input mapping using the schema
 *
 * Filters out any properties that are not part of the schema
 *
 * @param schema Yup schema used in the form
 * @param data Data received from the outside
 */
export const filterUsingSchema = <Model extends BaseModel, Schema extends Yup.ObjectSchema<any>>(
  schema: Schema,
  data?: Model
) => schema.cast(data || {}, { stripUnknown: true }) as Yup.TypeOf<Schema>;

export default FormDetailPage;
