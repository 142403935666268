import { takeLatest } from 'redux-saga/effects';

import {
  TYPE_ABORT_TEST,
  TYPE_ADAMS_CHANGE,
  TYPE_ADD_MEMBER_STATEMENT,
  TYPE_APPLY_MISSION_TO_TESTS,
  TYPE_BULK_EDIT_MISSION,
  TYPE_BULK_EDIT_ORDER_ITEM,
  TYPE_BULK_EDIT_TEST,
  TYPE_EDIT_MISSION,
  TYPE_EDIT_SAMPLE,
  TYPE_EDIT_TEST,
  TYPE_INDIVIDUAL_BULK_EDIT_TEST,
  TYPE_LOAD_MISSION,
  TYPE_LOAD_SAMPLE,
  TYPE_SAVE_TEST_ASSIGNMENT_DIFF,
  TYPE_TEST_BULK_CREATE,
  TYPE_UNDO_MEMBER_STATEMENT,
} from '../actions';

import * as adamsSaga from './adams';
import * as missionsSaga from './missions';
import * as ordersSaga from './orders';
import * as samplesSaga from './samples';
import * as teamMembersSaga from './teamMembers';
import * as testsSaga from './tests';

export default function* root() {
  yield takeLatest(TYPE_LOAD_MISSION, missionsSaga.loadMissionSaga);
  yield takeLatest(TYPE_EDIT_MISSION, missionsSaga.editMissionSaga);
  yield takeLatest(TYPE_APPLY_MISSION_TO_TESTS, missionsSaga.applyMissionToTestsSaga);
  yield takeLatest(TYPE_BULK_EDIT_MISSION, missionsSaga.bulkEditMission);
  yield takeLatest(TYPE_TEST_BULK_CREATE, testsSaga.testBulkCreateSaga);
  yield takeLatest(TYPE_BULK_EDIT_TEST, testsSaga.bulkEditTestSaga);
  yield takeLatest(TYPE_INDIVIDUAL_BULK_EDIT_TEST, testsSaga.individualBulkEditTestSaga);
  yield takeLatest(TYPE_SAVE_TEST_ASSIGNMENT_DIFF, testsSaga.saveTestAssignmentDiffSaga);
  yield takeLatest(TYPE_ABORT_TEST, testsSaga.abortTestSaga);
  yield takeLatest(TYPE_EDIT_TEST, testsSaga.editTestSaga);
  yield takeLatest(TYPE_LOAD_SAMPLE, samplesSaga.loadSampleSaga);
  yield takeLatest(TYPE_EDIT_SAMPLE, samplesSaga.editSampleSaga);
  yield takeLatest(TYPE_ADAMS_CHANGE, adamsSaga.adamsChangeSaga);
  yield takeLatest(TYPE_BULK_EDIT_ORDER_ITEM, ordersSaga.bulkEditOrderItemsSaga);
  yield takeLatest(TYPE_ADD_MEMBER_STATEMENT, teamMembersSaga.addMemberStatement);
  yield takeLatest(TYPE_UNDO_MEMBER_STATEMENT, teamMembersSaga.undoMemberStatement);
}
