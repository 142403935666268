import { FC, useState } from 'react';
import { useTranslation } from 'react-i18next';

import Button from 'core/components/Button';
import DetailTimeZoneModal from 'core/components/DetailTimeZoneModal';
import Tooltip from 'core/components/Tooltip';
import { useFormatDateTime } from 'core/i18n/useFormatDateTime';

import { ModifiedWrap } from './styled';

interface Props {
  modified?: Date;
  created?: Date;
  /**
   * Used if neither modified or created is present
   */
  chosenTimeZone?: string;
  /**
   * If passed does not open the default details model but calls the callback
   */
  onOpenDetails?: () => void;
}

const Time: FC<Props> = ({ modified, created, chosenTimeZone, onOpenDetails }) => {
  const { t } = useTranslation();
  const formatDateTime = useFormatDateTime();
  const [detailOpen, setDetailOpen] = useState(false);

  let date: string | null = null;
  if (modified) {
    date = formatDateTime(modified, 'DATETIME_SHORT');
  } else if (created) {
    date = formatDateTime(created, 'DATETIME_SHORT');
  }

  return (
    <ModifiedWrap>
      <Tooltip content={t('See details and set time zone')} wrapInSpan>
        <Button
          type="button"
          text={date || chosenTimeZone || t('Time Zone')}
          onClick={onOpenDetails ? onOpenDetails : () => setDetailOpen(true)}
        />
      </Tooltip>

      {!onOpenDetails && (
        <DetailTimeZoneModal
          open={detailOpen}
          onClose={() => setDetailOpen(false)}
          created={created}
          modified={modified}
        />
      )}
    </ModifiedWrap>
  );
};

export default Time;
