import { useCallback, useMemo } from 'react';
import { useTranslation } from 'react-i18next';

export type AutoArchiveOption =
  | {
      type: 'AGE';
      entity: string;
      description: string;
      source: string;
      key: string;
      clientOption?: string;
    }
  | {
      type: 'BOOLEAN';
      entity: string;
      description: string;
      key: string;
      clientOption?: string;
    };

export const autoArchiveOptions = [
  {
    key: 'autoArchiveCocAge',
    type: 'AGE',
  },
  {
    key: 'autoArchiveTestDcoReportAge',
    type: 'AGE',
    clientOption: 'enableDcoReport',
  },
  {
    key: 'autoArchiveTestReportAge',
    type: 'AGE',
    clientOption: 'enableDcr',
  },
  {
    key: 'autoArchiveTestAge',
    type: 'AGE',
  },
  {
    key: 'autoArchiveTestShipped',
    type: 'BOOLEAN',
  },
  {
    key: 'autoArchiveTestUaReportAge',
    type: 'AGE',
  },
] as const;

const useAutoArchiveItems = () => {
  const { t } = useTranslation();

  const getItemForOption = useCallback<
    (option: (typeof autoArchiveOptions)[number]) => AutoArchiveOption
  >(
    (option) => {
      switch (option.key) {
        case 'autoArchiveCocAge':
          return {
            ...option,
            entity: t('Chain of Custody'),
            description: t(
              'Chain of Custody that is not a draft will be automatically archived if no modification is done for the specified number of days.'
            ),
            source: t('Last Modification'),
          };

        case 'autoArchiveTestDcoReportAge':
          return {
            ...option,
            entity: t('Control Reports'),
            description: t(
              'Control Report that is completed will be automatically archived if no modification is done for the specified number of days.'
            ),
            source: t('Last Modification'),
          };

        case 'autoArchiveTestReportAge':
          return {
            ...option,
            entity: t('Control Reports'),
            description: t(
              'Control Report that is completed will be automatically archived if no modification is done for the specified number of days.'
            ),
            source: t('Last Modification'),
          };

        case 'autoArchiveTestUaReportAge':
          return {
            ...option,
            entity: t('UA Reports'),
            description: t(
              'UA Report that is completed will be automatically archived if no modification is done for the specified number of days.'
            ),
            source: t('Last Modification'),
          };

        case 'autoArchiveTestAge':
          return {
            ...option,
            entity: t('Tests - Age'),
            description: t(
              'Test will be automatically archived after the assignment date range end is past for the specified number of days. Tests will be archived irrespective of status - including those In Progress!'
            ),
            source: t('Past Date Range'),
          };

        case 'autoArchiveTestShipped':
          return {
            ...option,
            entity: t('Tests - Shipped'),
            description: t('Test will be automatically archived once all samples are shipped.'),
          };
      }
    },
    [t]
  );

  return useMemo(() => autoArchiveOptions.map(getItemForOption), [getItemForOption]);
};

export default useAutoArchiveItems;
