import useClientOption, { useBoolClientOption } from 'core/hooks/useClientOption';
import { AthleteDocumentNumber } from 'settings/models/Setting';

export default function useHideAthleteDocumentNumber() {
  const athleteDocumentNumber = useClientOption('athleteDocumentNumber');
  // TODO: remove once we ditch the angular app
  const hideAthleteDocumentNumber = useBoolClientOption('hideAthleteDocumentNumber');

  return athleteDocumentNumber
    ? athleteDocumentNumber === AthleteDocumentNumber.HIDDEN
    : hideAthleteDocumentNumber;
}
