import styled, { css } from 'styled-components/macro';

export const StyledIcon = styled.div<{ isOpen?: boolean; right?: boolean }>`
  transition: all 0.4s ease-in-out;
  display: flex;
  ${(p) =>
    p.isOpen &&
    css`
      transform: rotate(180deg);
    `}
  ${(p) =>
    p.right &&
    css`
      margin-left: auto;
    `}
`;
