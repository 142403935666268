import MuiTab, { TabProps } from '@material/react-tab';
import { forwardRef } from 'react';
import styled, { css } from 'styled-components/macro';

import TabBar from 'core/components/StyledTabBar';

type P = TabProps & { hasError?: boolean };

const MT = forwardRef<HTMLAnchorElement | HTMLButtonElement, P>(
  ({ hasError: _hasError, ...props }, ref: any) => {
    return <MuiTab ref={ref} {...props} />;
  }
);

export const Tab = styled(MT)`
  ${(p: any) =>
    p.hasError &&
    css`
      & .mdc-tab-indicator > .mdc-tab-indicator__content--underline {
        background-color: var(--mdc-theme-error) !important;
      }
    `}
`;

export const StyledTabBar = styled(TabBar)`
  margin-bottom: 1.35rem;

  & .mdc-tab {
    text-transform: uppercase;
    font-size: 1rem !important;
    letter-spacing: 0;
    font-weight: 600;
  }
`;
