import { ErrorMessage, FieldProps, getIn } from 'formik';
import { FC } from 'react';

import theme from 'app/theme';
import { FeedbackMessage } from 'core/components/FeedbackMessage';
import Switch from 'core/components/Switch';

interface Props extends FieldProps {
  label: string;
  disabled?: boolean;
  onChange?: (v: boolean) => void;
  tooltip?: string;
  color?: keyof typeof theme.color;
  error?: boolean;
  mode?: 'inline' | 'buttons' | 'not-inline';
  margin?: string;
}

const FormSwitch: FC<Props> = ({
  field: { name, value },
  form: { touched, errors, setFieldValue },
  label,
  disabled,
  color = 'success',
  onChange,
  tooltip,
  error,
  mode,
  margin,
}) => {
  const hasError: boolean =
    error !== undefined ? error : !!getIn(touched, name) && !!getIn(errors, name);

  const handleOnChange = (v: boolean) => {
    if (onChange) {
      onChange(v);
    } else {
      setFieldValue(name, v);
    }
  };

  return (
    <>
      <Switch
        value={value}
        label={label}
        disabled={disabled}
        id={`form-${name}`}
        tooltip={tooltip}
        color={color}
        onChange={(v) => {
          handleOnChange(v);
        }}
        name={name}
        mode={mode}
        margin={margin}
      />
      {hasError && <ErrorMessage name={name} component={FeedbackMessage} />}
    </>
  );
};

export default FormSwitch;
