import cloneDeep from 'lodash/cloneDeep';
import { FC, KeyboardEvent, memo, useEffect, useMemo, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { VariableSizeList as LazyList, VariableSizeList } from 'react-window';
import { useDebouncedCallback } from 'use-debounce';

import { FlexCell, FlexRow } from 'core/components/FlexUtils';
import Textfield from 'core/components/Textfield';

import { GroupOption, Option, SimpleOption } from '..';
import ItemRenderer, { getItemSize } from '../ItemRenderer';
import { SelectAllButton, StyledList } from '../styled';

export interface Props {
  /** display loading indicator after options */
  loading?: boolean;
  /** select options */
  options: Option[];
  /** selected value (id) */
  value: null | string | number | Array<string | number>;
  /**
   * Enable filtering using a search box
   *
   * defaults = true
   */
  searching?: boolean;
  /**
   * Should the secondary text by included in the search
   *
   * Requires .secondary on items to be a string
   * default = false
   */
  searchingSecondary?: boolean;
  /**
   * Select one item (native select)
   * default = false (multi-select)
   */
  single?: boolean;
  /**
   * Content height in px
   */
  height?: number;
  /**
   * Handle on-change event
   */
  onChange: (
    ids: null | string | number | Array<string | number>,
    option: SimpleOption | { [id: string]: SimpleOption }
  ) => void;
}

// We have to reset the lazy list index after options change
const useResetLazyCache = (data: Option[]) => {
  const ref = useRef<VariableSizeList>(null);
  useEffect(() => {
    if (ref.current) {
      ref.current.resetAfterIndex(0, true);
    }
  }, [data]); // eslint-disable-line
  return ref;
};

const Content: FC<Props> = ({
  value: originalValue,
  searching = true,
  searchingSecondary = true,
  single = false,
  height = 400,
  onChange,
  loading,
  options,
}) => {
  const value: Array<string | number> = useMemo(
    () =>
      single
        ? originalValue
          ? [originalValue as string | number]
          : []
        : (originalValue as Array<string | number>) || [],
    [single, originalValue]
  );
  const [search, setSearch] = useState<string | null>(null);
  const [filteredOptions, setFilteredOptions] = useState(options);
  const selected = useMemo(() => new Set(value), [value]);
  const selectedOption = useRef<{ [id: string]: SimpleOption }>({});

  useEffect(() => {
    // This makes sure we are in sync with the value that can be changed
    // since this is a controlled component.
    // Nothing will be done if the value is just mirrored from the onChange.
    // But it'll react to clearing or setting of additional items to value.
    value.forEach((v) => {
      // Find and add added values
      if (!selectedOption.current[v]) {
        selectedOption.current[v] = options.find(
          (o) => o !== 'SEPARATOR' && !('options' in o) && o.id === v
        ) as SimpleOption;
      }
    });
    Object.keys(selectedOption.current).forEach((v) => {
      // Remove removed values
      if (!selected.has(v) && !selected.has(+v)) {
        delete selectedOption.current[v];
      }
    });
    // We react only to changing value
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [value]);

  const { t } = useTranslation();
  const ref = useResetLazyCache(filteredOptions);
  const debouncedSetFilteredOptions = useDebouncedCallback((s: string | null) => {
    if (!s) {
      setFilteredOptions(options);
    } else {
      const phrase = s.toLowerCase();

      setFilteredOptions(
        options.reduce((acc, o) => {
          // Filter out separator when searching
          if (o === 'SEPARATOR') return acc;

          // Include option right away if name matches
          // If the group name matches, all of its options are shown
          const nameMatches = o.name?.toLowerCase().includes(phrase);
          const secondaryMatches =
            searchingSecondary &&
            'secondary' in o &&
            typeof o.secondary === 'string' &&
            o.secondary.toLowerCase().includes(phrase);
          if (nameMatches || secondaryMatches) {
            acc.push(o);
            return acc;
          }

          // Otherwise if it is a group, try to include matching suboptions
          if ('options' in o) {
            const subOptions = o.options.filter((subo) => {
              const nameMatches = subo.name?.toLowerCase().includes(phrase);
              const secondaryMatches =
                searchingSecondary &&
                typeof subo.secondary === 'string' &&
                subo.secondary.toLowerCase().includes(phrase);
              return nameMatches || secondaryMatches;
            });

            // ...unless it's empty when we collapse it
            if (subOptions.length > 0) {
              acc.push({ ...o, options: subOptions });
            }
          }

          return acc;
        }, [] as Option[])
      );
    }
  }, 500);
  useEffect(
    () => {
      debouncedSetFilteredOptions(search);
    },
    // We don't want to depend on search and debouncedSetFilteredOptions
    // As those will change and we'll be in endless loop
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [options]
  );

  const handleSelect = (id: string | number, group?: string) => {
    const usedOptions = group
      ? (options.find((o) => o !== 'SEPARATOR' && 'options' in o && o.id === group) as GroupOption)
          .options
      : options;

    const option = usedOptions.find((o) => o !== 'SEPARATOR' && o.id === id) as SimpleOption;
    const isSelected = selected.has(option.id);
    if (single) {
      onChange(option.id, option);
    } else {
      const withoutOption = value.filter((v) => v !== option.id);
      const withOption = [...value, option.id];

      const newSelectedOptions = cloneDeep(selectedOption.current);
      if (isSelected) {
        delete newSelectedOptions[option.id];
      } else {
        newSelectedOptions[option.id] = option;
      }

      onChange(isSelected ? withoutOption : withOption, newSelectedOptions);
    }
  };

  const { selectableOptions, selectableSelected } = useMemo(
    () =>
      single
        ? { selectableOptions: undefined, selectableSelected: undefined }
        : filteredOptions.reduce(
            (acc, option) => {
              if (option === 'SEPARATOR') return acc;
              const addOption = (option: SimpleOption) => {
                acc.selectableOptions.set(option.id, option);
                if (selected.has(option.id)) acc.selectableSelected.add(option.id);
              };
              if (!('options' in option)) addOption(option);
              if ('options' in option) option.options.forEach(addOption);
              return acc;
            },
            {
              selectableOptions: new Map<string | number, SimpleOption>(),
              selectableSelected: new Set<string | number>(),
            }
          ),
    [filteredOptions, selected, single]
  );
  const selectedAll =
    !!selectableOptions?.size && selectableSelected?.size === selectableOptions.size;
  const selectAll = () =>
    onChange(
      Array.from(
        new Set([...Array.from(selected.values()), ...Array.from(selectableOptions?.keys() || [])])
      ),
      Object.fromEntries(selectableOptions?.entries() || [])
    );
  const unselectAll = () =>
    onChange(
      Array.from(selected.values()).filter((id) => !selectableOptions?.has(id)),
      {}
    );

  const fontSize = parseInt(getComputedStyle(document.documentElement).fontSize || '16');

  const hasTwoLine = options.some((o) => o !== 'SEPARATOR' && 'secondary' in o);

  let finalOptions: Array<Option | 'LOADING'> = filteredOptions;
  if (loading) {
    finalOptions = finalOptions.concat(['LOADING']);
  }

  return (
    <>
      {searching && (
        <FlexRow fullWidth>
          <FlexCell flex={1}>
            <Textfield
              onChange={(v) => {
                setSearch(v);
                debouncedSetFilteredOptions(v);
              }}
              onKeyDown={(e: KeyboardEvent<HTMLInputElement>) => {
                // Prevent default behavior of form submit on Enter
                e.key === 'Enter' && e.preventDefault();
              }}
              id="dropdown-search"
              label={t('Search')}
              value={search}
              icon="search"
            />
          </FlexCell>
          {selectableOptions && selectableSelected && (
            <FlexCell>
              <SelectAllButton
                icon={
                  selectedAll
                    ? 'check_box'
                    : selectableSelected?.size > 0
                      ? 'indeterminate_check_box'
                      : 'check_box_outline_blank'
                }
                disabled={selectableOptions.size === 0}
                onClick={() => (selectableSelected.size > 0 ? unselectAll() : selectAll())}
                role="checkbox"
                aria-label={t('Toggle selection of all items')}
                aria-checked={selectableSelected.size > 0 && !selectedAll ? 'mixed' : selectedAll}
              />
            </FlexCell>
          )}
        </FlexRow>
      )}

      <StyledList
        className={`mdc-list mdc-list--dense ${hasTwoLine && 'mdc-list--two-line'}`}
        role="group"
        onKeyDown={(e) => {
          const currentlyFocused = document.activeElement as HTMLElement | null;
          if (!currentlyFocused || !currentlyFocused.classList.contains('mdc-list-item')) {
            return;
          }

          // Move up focus to the nearest focusable list item
          if (e.key === 'ArrowUp') {
            let prevElement = currentlyFocused.previousElementSibling as HTMLElement | null;
            while (prevElement !== null) {
              if (prevElement.getAttribute('role') === 'checkbox') {
                prevElement.focus();
                e.preventDefault();
                e.stopPropagation();
                return;
              }

              prevElement = prevElement.previousElementSibling as HTMLElement | null;
            }
          }

          // Move down or focus first on arrow down
          if (e.key === 'ArrowDown') {
            let nextElement = currentlyFocused.nextElementSibling as HTMLElement | null;
            while (nextElement !== null) {
              if (nextElement.getAttribute('role') === 'checkbox') {
                nextElement.focus();
                e.preventDefault();
                e.stopPropagation();
                return;
              }

              nextElement = nextElement.nextElementSibling as HTMLElement | null;
            }
          }

          // Select on Enter and Space
          if (e.key === 'Enter' || e.key === ' ') {
            e.preventDefault();
            e.stopPropagation();

            const disabled = currentlyFocused.getAttribute('aria-disabled');
            if (disabled === 'true') return;

            const focusedIdIsNumber = currentlyFocused.dataset['numberid'] === 'true';
            const focusedId = currentlyFocused.dataset['id'];
            focusedId && handleSelect(focusedIdIsNumber ? Number(focusedId) : focusedId);
          }
        }}
      >
        <LazyList
          itemCount={finalOptions.length}
          itemData={finalOptions}
          estimatedItemSize={40}
          useIsScrolling={true}
          overscanCount={5}
          height={height}
          width="100%"
          ref={ref}
          itemKey={(i, data) => (typeof data[i] === 'string' ? `${data[i]}-${i}` : data[i].id)}
          itemSize={(i) => getItemSize(finalOptions[i], fontSize)}
        >
          {({ ...props }) => ItemRenderer({ ...props, single, handleSelect, selected })}
        </LazyList>
      </StyledList>
    </>
  );
};

export default memo(Content);
