import { FC, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch } from 'react-redux';

import { favoriteView } from 'core/actions';
import ContextMenu from 'core/components/ContextMenu';
import { FlexCell } from 'core/components/FlexUtils';
import IconButton from 'core/components/IconButton';
import Modal from 'core/components/Modal';
import { Tab } from 'core/components/Tabs/styled';
import { COLUMN_KEYS } from 'core/containers/DataGrid/hooks/useColumnConfiguration';
import useAppSelector from 'core/hooks/useAppSelector';
import { UserView } from 'core/models/UserView';

import { StyledTabBar } from '../styled';
import useViewTabs, { ViewTabs } from '../useViewTabs';

import SaveButton from './SaveButton';
import { ActionsWrapper, HeaderWrapper, StickySettingsWrapper, Title } from './styled';

export const VIEW_URL_KEYS = [...COLUMN_KEYS, 'activeView', 'duplicity', 'sort'];

interface Props {
  activeView: UserView | null;
  activeTab: ViewTabs;
  setActiveTab: (newActiveTab: ViewTabs) => void;
  disableSettings: boolean;
  onDelete: (id: number) => void;
  onClone: () => void;
  reloadUserViews: () => void;
  onOpenAllViews: () => void;
}

const Header: FC<Props> = ({
  activeView,
  setActiveTab,
  activeTab,
  disableSettings,
  onDelete,
  onClone,
  reloadUserViews,
  onOpenAllViews,
}) => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const tabs = useViewTabs(disableSettings);
  const [confirmDelete, setConfirmDelete] = useState<boolean>(false);

  const { savingInProgress, deletingInProgress, usersId } = useAppSelector(({ core }) => ({
    savingInProgress: core.viewSavingInProgress,
    deletingInProgress: core.viewDeletingInProgress,
    usersId: core.user!.id,
  }));

  const moreActions = useMemo(() => {
    return [
      {
        key: 'clone',
        text: t('Clone'),
        onClick: () => onClone(),
        disabled: savingInProgress || deletingInProgress,
      },
      {
        key: 'delete',
        text: t('Delete'),
        onClick: () => setConfirmDelete(true),
        disabled: savingInProgress || usersId !== activeView?.createdBy,
      },
    ];
  }, [t, savingInProgress, deletingInProgress, onClone, usersId, activeView]);

  return (
    <>
      <StickySettingsWrapper>
        <HeaderWrapper>
          <Title>
            {activeView
              ? t('Customize: {{viewName}}', { viewName: activeView.name })
              : t('Create View')}
          </Title>

          <ActionsWrapper>
            <SaveButton
              activeTab={activeTab}
              activeView={activeView}
              onClone={onClone}
              setActiveTab={setActiveTab}
            />

            <>
              {activeView && !activeView.default && (
                <IconButton
                  icon={activeView.favorite ? 'star' : 'star_outline'}
                  tooltip={t('Favorites')}
                  onClick={() => {
                    dispatch(
                      favoriteView(activeView.id, !Boolean(activeView.favorite), () =>
                        reloadUserViews()
                      )
                    );
                  }}
                />
              )}

              <FlexCell>
                <IconButton icon="storage" tooltip={t('All Views')} onClick={onOpenAllViews} />
              </FlexCell>

              {activeView && (
                <ContextMenu menuItems={moreActions} menuId="viewOptions">
                  <IconButton icon="more_horiz" tooltip={t('More')} />
                </ContextMenu>
              )}
            </>
          </ActionsWrapper>
        </HeaderWrapper>

        <StyledTabBar>
          {tabs.map((it) => (
            <Tab
              active={activeTab === it.type}
              onClick={() => setActiveTab(it.type)}
              key={it.key}
              type="button"
              disabled={it.disabled}
            >
              <span>{it.title}</span>
            </Tab>
          ))}
        </StyledTabBar>

        <Modal
          ariaLabel={t('Deletion Confirmation Dialog')}
          onClose={() => setConfirmDelete(false)}
          title={t('Delete Selected View')}
          onConfirm={() => confirmDelete && activeView && onDelete(activeView.id)}
          open={confirmDelete}
        >
          {t('Are you sure you want to permanently delete this View?')}
        </Modal>
      </StickySettingsWrapper>
    </>
  );
};

export default Header;
