import { Input } from '@material/react-text-field';
import { ErrorMessage, FieldInputProps, useField, useFormikContext } from 'formik';
import {
  ComponentType,
  forwardRef,
  ForwardRefRenderFunction,
  KeyboardEvent,
  memo,
  SyntheticEvent,
  useCallback,
  useEffect,
  useRef,
  useState,
} from 'react';
import { useDebouncedCallback } from 'use-debounce';

import { FeedbackMessage } from 'core/components/FeedbackMessage';
import FieldGroup from 'core/components/FieldGroup';
import Textfield, { Props as TextfieldProps } from 'core/components/Textfield';

export type SetFieldValueOnChange = (value: string | null) => void;

export interface Props {
  onChange?: (value: string | null, setFieldValue?: SetFieldValueOnChange) => void;
  textarea?: boolean;
  field: FieldInputProps<string | null>;
  label: string;
  icon?: string;
  type?: string;
  preventAutoComplete?: boolean;
  form: any;
  error?: boolean;
  component?: ComponentType<TextfieldProps>;
  fullHeight: boolean;
}

const FormTextfield: ForwardRefRenderFunction<HTMLInputElement | HTMLTextAreaElement, Props> = (
  {
    form,
    onChange,
    textarea,
    field,
    label,
    icon,
    type,
    preventAutoComplete,
    component,
    fullHeight,
    ...rest
  },
  forwardedRef
) => {
  const formValue = field.value;

  const [value, setValue] = useState(formValue);
  const inputRef = useRef<Input<HTMLInputElement> | Input<HTMLTextAreaElement>>(null);

  useEffect(() => {
    if (inputRef.current?.inputElement !== document.activeElement) {
      setValue(formValue);
    }
  }, [formValue]);

  const skipDebounce = useRef(false);

  const [, { error, touched }] = useField(field.name);

  // if we would use setValue from useField hook it won't trigger our overriden setFieldValue
  const { setFieldValue } = useFormikContext();

  const debouncedSetFormValue = useDebouncedCallback((v: string | null) => {
    setFieldValue(field.name, v);
  }, 500);

  // submitCount bcs in some cases nested field its not marked as touched when user hit submit button
  const hasError = !!(error !== undefined && (touched || form.submitCount > 0));

  // @ts-ignore
  delete rest.viewMode;

  const Input = component || Textfield;

  useEffect(() => {
    if (forwardedRef) {
      if (typeof forwardedRef === 'function') {
        forwardedRef(inputRef.current?.inputElement || null);
      } else {
        forwardedRef.current = inputRef.current?.inputElement || null;
      }
    }
  });

  const setFieldValueOnChange = useCallback(
    (value: string | null) => {
      setValue(value);
      debouncedSetFormValue(value);
    },
    [debouncedSetFormValue]
  );

  return (
    <FieldGroup fullHeight={fullHeight} hasError={!!hasError}>
      <Input
        {...rest}
        id={`form-${field.name}`}
        textarea={textarea}
        error={hasError}
        label={label}
        icon={icon}
        type={type}
        preventAutoComplete={preventAutoComplete}
        {...field}
        value={value}
        onFocus={() => {
          skipDebounce.current = false;
        }}
        onChange={(v, e) => {
          if (onChange) {
            onChange(v, setFieldValueOnChange);
            return;
          }
          setValue(v);
          if (skipDebounce.current) {
            setFieldValue(field.name, v);
            field.onBlur(e);
          } else {
            debouncedSetFormValue(v);
          }
          skipDebounce.current = false;
        }}
        onKeyDown={(e: KeyboardEvent<HTMLElement | HTMLTextAreaElement>) => {
          skipDebounce.current = false;
          if (e.keyCode === undefined) {
            // if user selects prefilled value (e.g. chrome autofill) -> onChange its called with new value
            skipDebounce.current = true;
          } else if (!textarea && e.key === 'Enter') {
            // when user hits enter in text input -> onChange its not called
            debouncedSetFormValue.cancel();
            setFieldValue(field.name, value);
            field.onBlur(e);
          }
        }}
        ref={inputRef as any}
        onBlur={(e: SyntheticEvent<HTMLElement | HTMLTextAreaElement>) => {
          debouncedSetFormValue.cancel();
          field.onBlur(e);
          setFieldValue(field.name, value);
        }}
      />
      {hasError && <ErrorMessage name={field.name} component={FeedbackMessage} />}
    </FieldGroup>
  );
};

export default memo(forwardRef<HTMLInputElement | HTMLTextAreaElement, Props>(FormTextfield));
