import TextField from '@material/react-text-field';
import styled, { css } from 'styled-components/macro';

import theme from 'app/theme';

export const StyledTextfield = styled(TextField)`
  width: 100%;

  label {
    line-height: 1rem;
    font-size: 1rem;
  }

  input {
    background: ${theme.color.white};
  }

  ${({ textarea }) =>
    textarea &&
    css`
      height: auto !important;
    `}

  .mdc-floating-label {
    padding-right: 0.5rem;
  }

  .mdc-floating-label:not(.mdc-floating-label--float-above) {
    margin-bottom: 0;
  }
`;
