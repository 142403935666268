import { useFormikContext } from 'formik';
import { FC, useContext, useMemo } from 'react';
import { useTranslation } from 'react-i18next';

import { SimpleOption } from 'core/components/DropDown';
import { FormContext } from 'core/components/Form';
import Field from 'core/components/Form/Field';
import sortOptionsHelper from 'core/components/FormDropDown/sortOptionsHelper';
import FormTextfield from 'core/components/FormTextfield';
import { useIncludeOptions } from 'core/components/ResourceFormDropdown/IncludeResourcesProvider';
import SelectOtherField, { OTHER_NUMBER } from 'core/components/SelectOtherField';
import { useDetail } from 'core/containers/FormDetailPage/DetailContext';
import { defaultDropdownReducer } from 'core/hooks/useDropdownResource';
import { useResources } from 'core/hooks/useResource';
import Federation from 'lists/models/Federation';

const FederationsField: FC = () => {
  const { t } = useTranslation();

  const { isFieldInViewMode } = useContext(FormContext);
  const viewMode = isFieldInViewMode('federationsId');
  const { mode } = useDetail();
  const { values, setValues } = useFormikContext<{
    federationsName: string | null;
    federationsId: number | null;
  }>();

  const include = useIncludeOptions('federationsId') as Federation[];

  const { data: federations, reload: loadFederations } = useResources<Federation>('federations', {
    autoload: !!values.federationsId && mode === 'create',
  });

  const federationOptions: SimpleOption[] = useMemo(() => {
    const addInclude = include.length && !federations?.some((f) => f.id === include[0].id);

    const completeFederations = addInclude
      ? [...include, ...(federations || [])]
      : federations || [];

    return sortOptionsHelper(
      defaultDropdownReducer(
        completeFederations.map((f) => ({
          id: f.id,
          name: f.shortName,
          secondary: f.fullName,
          active: f.active,
        })) || [],
        values.federationsId
      )
    ) as SimpleOption[];
  }, [include, federations, values.federationsId]);

  const handleFederationsChange = (federationsId: number | null) => {
    setValues({
      ...values,
      federationsId: federationsId as number | null,
      federationsName: null,
    });
  };

  return (
    <SelectOtherField
      label={t('Federation')}
      options={federationOptions}
      name="federationsId"
      viewMode={viewMode}
      otherComponent={(autoFocus) => (
        <Field
          name="federationsName"
          label={t('Federation')}
          component={FormTextfield}
          autoFocus={autoFocus}
          dummyValue={
            viewMode
              ? values.federationsName || (include.length > 0 && include[0].fullName)
              : undefined
          }
        />
      )}
      otherOption={{
        value: OTHER_NUMBER,
      }}
      onClickOther={() => setValues({ ...values, federationsId: null, federationsName: null })}
      defaultMode={values.federationsName ? 'other' : 'dropdown'}
      onDropdownChange={(newValue) => handleFederationsChange(newValue as number | null)}
      onOpen={() => {
        if (!federations?.length) {
          loadFederations();
        }
      }}
    />
  );
};

export default FederationsField;
