import { isValidPhoneNumber } from 'libphonenumber-js';
import * as Yup from 'yup';
import { BaseSchema, ValidationError } from 'yup';
import { AnyObject, Maybe } from 'yup/lib/types';

import { t } from 'core/i18n';

/**
 * Additional global methods
 */
declare module 'yup' {
  interface StringSchema<
    TType extends Maybe<string> = string | undefined,
    TContext extends AnyObject = AnyObject,
    TOut extends TType = TType,
  > extends BaseSchema<TType, TContext, TOut> {
    phone(): StringSchema<TType, TContext, TOut>;
  }

  interface ArraySchema<T> {
    unique: (message: string, mapper?: (it: T) => any) => ArraySchema<T>;
  }
}

// eslint-disable-next-line
const EMAIL_ADDRESS_PATTERN =
  /^(?=[a-zA-Z0-9@.!#$%&'*+/=?^_`{|}~-]{6,254}$)(?=[a-zA-Z0-9.!#$%&'*+/=?^_`{|}~-]{1,64}@)[a-zA-Z0-9!#$%&'*+/=?^_`{|}~-]+(?:\.[a-zA-Z0-9!#$%&'*+/=?^_`{|}~-]+)*@(?:(?=[a-zA-Z0-9-]{1,63}\.)[a-zA-Z0-9](?:[a-zA-Z0-9-]*[a-zA-Z0-9])?\.)+(?=[a-zA-Z0-9-]{1,63}$)[a-zA-Z0-9](?:[a-zA-Z0-9-]*[a-zA-Z0-9])?$/;

const yupEmail = Yup.string().email;

function init() {
  Yup.addMethod(Yup.string, 'email', function () {
    return yupEmail
      .apply(this)
      .test(
        'email',
        t('Email cannot contain any special characters like accents'),
        function (value) {
          if (!value) return true;

          return Boolean(value.match(EMAIL_ADDRESS_PATTERN));
        }
      );
  });

  Yup.addMethod(Yup.string, 'phone', function () {
    return this.test('phone', t('Must be valid Phone number'), function (value) {
      if (!value) return true;

      return isValidPhoneNumber(value);
    });
  });

  Yup.addMethod(Yup.array, 'unique', function (message: string) {
    return this.test('unique', message, function (items): boolean | ValidationError {
      const checklist = (items || []).map(
        (val, _i, list) => list.filter((v) => val === v).length === 1
      );

      if (checklist.every(Boolean)) {
        return true;
      }

      const inner = checklist.reduce(
        (errors, isValid, index) =>
          isValid ? errors : [...errors, { path: `${this.path}[${index}]`, message }],
        [] as any[]
      );

      return {
        name: 'ValidationError',
        errors: [],
        inner,
      } as unknown as ValidationError;
    });
  });
}

export default init;
