import { FC, useCallback } from 'react';
import { useDispatch } from 'react-redux';

import { notificationMarkRead } from 'core/actions';
import Drawer from 'core/components/Drawer';
import NotificationsList from 'core/components/Notifications';
import useAppSelector from 'core/hooks/useAppSelector';

interface Props {
  open: boolean;
  onClose: () => void;
}

const Notifications: FC<Props> = ({ open, onClose }) => {
  const dispatch = useDispatch();

  const events = useAppSelector(({ core }) => core.notifications.events);
  const handleMarkAsRead = useCallback(
    (id?: number) => {
      dispatch(notificationMarkRead(id));
    },
    [dispatch]
  );

  return (
    <Drawer onRequestClose={onClose} open={open} opacity={25}>
      <NotificationsList
        onRequestClose={onClose}
        handleMarkAsRead={handleMarkAsRead}
        data={events}
      />
    </Drawer>
  );
};

export default Notifications;
