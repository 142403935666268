import { useFormikContext } from 'formik';
import { FC, useCallback, useEffect } from 'react';
import { useTranslation } from 'react-i18next';

import Button from 'core/components/Button';
import { SimpleOption } from 'core/components/DropDown';
import DummyField from 'core/components/DummyField';
import Field from 'core/components/Form/Field';
import Modal from 'core/components/Modal';
import ResourceFormDropdown from 'core/components/ResourceFormDropdown';
import { useIncludeOptions } from 'core/components/ResourceFormDropdown/IncludeResourcesProvider';
import useAppSelector from 'core/hooks/useAppSelector';
import { useFormatDateTime } from 'core/i18n/useFormatDateTime';
import TimeZone from 'core/models/TimeZone';

import { VerticallySpaced } from './styled';

export interface Props {
  modified?: Date | null;
  created?: Date | null;
  open: boolean;
  onClose: () => void;
  afterTimeZoneChange?: (name: string) => void;
}

const DetailTimeZoneModal: FC<Props> = ({
  open,
  modified,
  created,
  onClose,
  afterTimeZoneChange,
}) => {
  const { t } = useTranslation();
  const formatDateTime = useFormatDateTime();

  // useFormikContext can't be used as it logs warning if it isn't found
  const formik = useFormikContext<Record<string, any>>();
  // Having access to formik context means this is used inside form and we expect to set timeZonesId
  const explicitTimeZone = !!formik && formik.values.timeZonesId !== undefined;
  // Trigger initial time zone change with the include/profile time zone
  const timeZoneInclude = useIncludeOptions('timeZonesId')?.[0] as TimeZone;

  const profileTimeZone = useAppSelector(({ core }) => core.user?.timeZone);

  useEffect(() => {
    if (!afterTimeZoneChange) return;

    if (timeZoneInclude) {
      afterTimeZoneChange(timeZoneInclude.name);
    } else if (profileTimeZone) {
      afterTimeZoneChange(profileTimeZone.name);
    }
  }, [timeZoneInclude, afterTimeZoneChange, profileTimeZone]);

  const onTimeZoneChange = useCallback(
    (id: number, option: SimpleOption) => {
      formik.setFieldValue('timeZonesId', id);
      afterTimeZoneChange && afterTimeZoneChange(option.name);
    },
    [formik, afterTimeZoneChange]
  );

  const localTimeZoneDummy = (
    <DummyField
      label={t('Data Time Zone')}
      value={t('Does not contain time zone sensitive data') as string}
    />
  );

  return (
    <Modal
      ariaLabel={t('Creation time, modification time, time zone dialog')}
      onClose={onClose}
      open={open}
      cancelButton={null}
      confirmButton={<Button type="button" text={t('Close')} onClick={onClose} />}
    >
      <VerticallySpaced>
        {created && (
          <DummyField
            label={t('Created')}
            value={
              created
                ? formatDateTime(created, 'DATETIME_SHORT_WITH_SECONDS')
                : (t('Not created yet.') as string)
            }
          />
        )}

        {modified && (
          <DummyField
            label={t('Modified')}
            value={
              modified
                ? formatDateTime(modified, 'DATETIME_SHORT_WITH_SECONDS')
                : (t('Not modified yet.') as string)
            }
          />
        )}

        {explicitTimeZone ? (
          <Field
            component={ResourceFormDropdown}
            label={t('Data Time Zone')}
            name="timeZonesId"
            id="timeZonesId"
            resource="time-zones"
            onChange={onTimeZoneChange}
            fast={false}
            single
            required
          />
        ) : (
          localTimeZoneDummy
        )}
      </VerticallySpaced>
    </Modal>
  );
};

export default DetailTimeZoneModal;
