import { DialogContent } from '@reach/dialog';
import styled from 'styled-components/macro';

import theme from 'app/theme';

export const Container = styled(DialogContent)`
  display: flex;
  flex-direction: column;
  border-radius: 0.25rem;
  /* Their selector is too specific, hence important, sorry! */
  padding-top: 0.5rem !important;
  max-height: calc(100vh - 2rem);
  box-shadow:
    0 11px 15px -7px rgba(0, 0, 0, 0.2),
    0 24px 38px 3px rgba(0, 0, 0, 0.14),
    0 9px 46px 8px rgba(0, 0, 0, 0.12);
`;

export const Title = styled.h2`
  font-size: 1.25rem;
  line-height: 2rem;
  font-weight: 500;
  letter-spacing: 0.0125em;
  padding: 1rem;
  margin-bottom: 0;
`;

export const Content = styled.div`
  padding: ${(p: { padding?: string }) => p.padding || '0.5rem 1rem'};
  overflow-y: auto;
  color: ${theme.color.textSecondary};
`;

export const Footer = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: flex-end;
  padding: 0.5rem;
  margin-top: auto;
`;

export const PullLeft = styled.span`
  flex: 1;
`;

export const TopSpacedWrapper = styled.div`
  padding-top: 0.375rem;
`;
