import { DateTime } from 'luxon';
import { ReactNode, useCallback } from 'react';
import { useTranslation } from 'react-i18next';

import theme from 'app/theme';
import { useIcons } from 'common/columns/useAthleteColumn';
import { SimpleOption } from 'core/components/DropDown';
import { Spacer } from 'core/components/Spacer/styled';
import { BadgeCellRenderer } from 'core/containers/DataGrid/FastCellRenderers';
import { useFormatDateTime } from 'core/i18n/useFormatDateTime';
import Athlete, { AthleteDatagrid } from 'personnel/models/Athlete';

const useAthleteOptionsMapping: () => (athlete: Athlete | AthleteDatagrid) => SimpleOption = () => {
  const { t } = useTranslation();
  const formatDateTime = useFormatDateTime();
  const icons = useIcons();

  return useCallback(
    (athlete) => {
      const { id, fullName, dateOfBirth, adamsId, poolsId, gender, mastersId } = athlete;
      const indications: ReactNode[] = [];
      const revisionBadge =
        mastersId !== null ? (
          <Spacer $marginLeft=".3rem" $marginRight=".3rem" $display="inline">
            <BadgeCellRenderer color={theme.color['gray300']} title={t('Revision')} />
          </Spacer>
        ) : null;

      if (adamsId) {
        indications.push(t('ADAMS ID: {{adamsId}}', { adamsId }));
      }

      if (dateOfBirth) {
        indications.push(
          t('DoB: {{dateOfBirth}}', {
            dateOfBirth: formatDateTime(new Date(dateOfBirth), 'DATE_SHORT'),
          })
        );
      }

      return {
        id: id,
        name: fullName || t('Unknown'),
        icons: dateOfBirth
          ? [
              ...icons(gender, DateTime.fromJSDate(dateOfBirth) || DateTime.now(), DateTime.now()),
              revisionBadge,
            ]
          : null,
        secondary: indications.join(', '),
        startIcons: revisionBadge,
        extra: { poolsId },
      };
    },
    [formatDateTime, t, icons]
  );
};

export default useAthleteOptionsMapping;
