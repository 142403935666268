import {
  FC,
  ReactElement,
  Ref,
  useCallback,
  useEffect,
  useImperativeHandle,
  useMemo,
  useState,
} from 'react';
import { useTranslation } from 'react-i18next';

import { Option, SimpleOption } from 'core/components/DropDown';
import { FlexCell, FlexRow } from 'core/components/FlexUtils';
import Field from 'core/components/Form/Field';
import FormDropDown from 'core/components/FormDropDown';
import FormTextfield from 'core/components/FormTextfield';
import IconButton from 'core/components/IconButton';

import MaterialIcon from '../MaterialIcon';

import StyledIcon from './styled';

export const OTHER_NUMBER = 0;
export const OTHER_STRING = 'Other';
interface Props {
  label: string;
  options: Option[];
  name: string;
  viewMode?: boolean;
  dummyValue?: string;
  otherComponent?: (autoFocus: boolean) => ReactElement;
  defaultMode?: 'dropdown' | 'other';
  onOpen?: () => void;
  onDropdownChange?: (newValue: SimpleOption['id'] | null, option?: SimpleOption) => void;
  otherOption?: {
    label?: string;
    value: number | string;
    prependOptions?: boolean;
  };
  loading?: boolean;
  onClickOther?: () => void;
  customRef?: Ref<{ isOther: boolean }>;
}

/**
 * Dropdown field that includes a feature to add a custom option.
 * If the user selects this option, the dropdown field will convert into a text field,
 * allowing the user to type in their own input.
 * The user can then switch the field back to a dropdown by selecting an icon next to this field.
 */
const SelectOtherField: FC<Props> = ({
  label,
  options,
  name,
  otherComponent,
  defaultMode = 'dropdown',
  viewMode,
  dummyValue,
  otherOption = { value: 'Other' },
  onOpen,
  onDropdownChange,
  loading,
  onClickOther,
  customRef,
}) => {
  const { t } = useTranslation();
  const [isOther, setIsOther] = useState(defaultMode === 'other');

  useImperativeHandle(customRef, () => ({
    isOther,
  }));

  useEffect(() => {
    setIsOther(defaultMode === 'other');
  }, [defaultMode]);

  const [autoFocus, setAutoFocus] = useState(false);

  const decoratedOptions = useMemo(() => {
    const x = [
      {
        id: otherOption.value,
        name: otherOption?.label || t('Other'),

        graphic: (
          <StyledIcon>
            <MaterialIcon icon="low_priority" size={1.25} />
          </StyledIcon>
        ),
      },
      'SEPARATOR',
    ];

    return [
      ...(otherOption?.prependOptions !== false ? x : []),
      ...options.map((it) => (typeof it === 'string' ? it : { ...it })),
    ];
  }, [options, otherOption, t]);

  const defaultOtherComponent = (
    <Field
      component={FormTextfield}
      label={label}
      name={name}
      viewMode={viewMode}
      value={dummyValue}
      autoFocus={autoFocus}
    />
  );

  const other = otherComponent ? otherComponent(autoFocus) : defaultOtherComponent;
  const onDropdownValueChange = useCallback(
    (value: SimpleOption['id'] | null, option: SimpleOption) => {
      if (!autoFocus) {
        setAutoFocus(true);
      }

      if (value === otherOption.value) {
        setIsOther(true);
        onClickOther?.();
        return;
      }

      if (onDropdownChange) {
        onDropdownChange(value, option);
      }
    },
    [autoFocus, onDropdownChange, otherOption.value, onClickOther]
  );

  return isOther ? (
    <FlexRow>
      <FlexCell block flex={1}>
        {other}
      </FlexCell>
      {!viewMode && (
        <FlexCell>
          <IconButton
            icon="low_priority"
            tooltip={t('Switch to Options')}
            onClick={() => {
              setIsOther(false);
              onClickOther?.();
            }}
          />
        </FlexCell>
      )}
    </FlexRow>
  ) : (
    <Field
      component={FormDropDown}
      label={label}
      options={decoratedOptions}
      name={name}
      single
      sortOptions={false}
      fast={false}
      viewMode={viewMode}
      dummyValue={dummyValue}
      onOpen={onOpen}
      onChange={(newValue: SimpleOption['id'] | null, option: SimpleOption) =>
        onDropdownValueChange(newValue, option)
      }
      loading={loading}
    />
  );
};

export default SelectOtherField;
