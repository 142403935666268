import { useFormikContext } from 'formik';
import { FC, useEffect, useRef } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch } from 'react-redux';
import { toast } from 'react-toastify';

import { entities, EntityKey } from 'app/entity';
import { clearApiQuery } from 'core/actions';
import Button from 'core/components/Button';
import Field from 'core/components/Form/Field';
import ResourceFormDropdown from 'core/components/ResourceFormDropdown';
import useAppSelector from 'core/hooks/useAppSelector';
import usePermission from 'core/hooks/usePermission';

import { ButtonsWrapper } from './styled';
import useUsersUrl from './useUsersUrl';
import useWatchersUrls from './useWatchersUrls';

interface Props {
  entityKey: EntityKey;
  entityId: number;
  onClose: () => void;
  isBulkAdding: boolean;
}

const WatchersFields: FC<Props> = ({ entityKey, entityId, onClose, isBulkAdding }) => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const saveInProgress = useAppSelector(({ core }) => core.dialogSaveInProgress);
  const { setFieldValue, values } = useFormikContext<{ watchers: number[] }>();
  const clientsId = useAppSelector(({ core }) => core.user && core.user.clientsId);
  const canEditWatchers = usePermission(
    `${entities[entityKey].permission}:watchers[actions:patch]`
  );
  const originalWatchers = useRef(values.watchers);

  const canEditAllWatchers = usePermission(`${entities[entityKey].permission}:watchers[scope:all]`);
  const canDeleteWatchers = !!canEditWatchers && !!canEditAllWatchers;
  const usersUrl = useUsersUrl();
  const urls = useWatchersUrls(entities[entityKey].api('').list);
  const availableWatchersUrl = urls.availableWatchersList(entityId);
  const availableWatchersBulkUrl = clientsId ? usersUrl.list({ clientsId }) : 'users';
  const watchersUrl = isBulkAdding ? availableWatchersBulkUrl : availableWatchersUrl;

  // We must clear this from the cache, so we get latest available watchers e.g. DCO can be watcher only when he is added to a test or mission
  useEffect(() => {
    return () => {
      dispatch(clearApiQuery(watchersUrl));
    };
  }, [watchersUrl, dispatch]);

  return (
    <>
      <Field
        component={ResourceFormDropdown}
        label={t('Watchers')}
        name="watchers"
        resource={watchersUrl}
        fast={false}
        mode="inline"
        onChange={(ids: number[]) => {
          if (!canDeleteWatchers) {
            const didRemoved = originalWatchers.current.some((it) => !ids.includes(it));

            if (didRemoved) {
              toast.error(t('Already saved watchers can not be removed!') as string);
              return;
            }
          }

          setFieldValue('watchers', ids);
        }}
      />

      <ButtonsWrapper>
        <>
          <div>
            <Button
              onChange={() => {
                setFieldValue('watchers', []);
              }}
              disabled={saveInProgress}
              text={t('Clear')}
              type="button"
              onClick={() => {
                setFieldValue('watchers', []);
              }}
            />
          </div>
          <div>
            <Button disabled={saveInProgress} type="button" text={t('Cancel')} onClick={onClose} />
            <Button disabled={saveInProgress} text={t('Save')} type="submit" />
          </div>
        </>
      </ButtonsWrapper>
    </>
  );
};

export default WatchersFields;
