import User from 'app/models/User';
import getResourcesUrl from 'core/functions/getResourcesUrl';
import { stringify } from 'core/functions/qs';
import Base from 'core/models/Base';
import { definition, getStandardApi, getStandardUrls } from 'core/module';
import Event from 'planning/models/Event';

import Coc from './models/Coc';
import ControlReport from './models/ControlReport';
import Holder from './models/Holder';
import Mission from './models/Mission';
import { Order } from './models/Order';
import Sample from './models/Sample';
import Tdp from './models/Tdp';
import Test from './models/Test';
import { TestResult } from './models/TestResult';
import TestResultSample from './models/TestResultSample';
import { TestReturn } from './models/TestReturn';
import UAReport from './models/UAReport';

type T = (m: string) => string;

export const planningEntities = {
  accreditation: definition<User>('accreditations', {
    name: (t: T) => t('Accreditation'),
    listName: (t: T) => t('Accreditations'),
  }),
  coc: {
    ...definition<Coc>('cocs', {
      name: (t: T) => t('CoC'),
      listName: (t: T) => t('CoCs'),
    }),
    urls: () => ({
      ...getStandardUrls(`cocs`),
      list: '/cocs',
      bulkCreate: (filter?: any) =>
        `/cocs/create/detail` +
        (filter
          ? '?' +
            stringify(
              Array.isArray(filter)
                ? {
                    testId: filter,
                  }
                : filter
            )
          : ''),
    }),
  },
  // TODO: check -> url is /controlReports
  controlReport: {
    ...definition<ControlReport>('controlReports', {
      name: (t: T) => t('Control Report'),
      listName: (t: T) => t('Control Reports'),
    }),
    urls: (testsId: number | string) => ({
      ...getStandardUrls(`test-reports/${testsId}`),
      list: '/test-reports',
    }),
    api: () => ({
      ...getStandardApi('tests/reports'),
      generatePdf: (id: number | string) => `/tests/reports/${id}/generate-pdf`,
      dcr: (id: number | string) => `/tests/reports/${id}/dcr`,
    }),
  },
  // TODO: check -> url is /dcoReports
  testDcoReport: {
    ...definition<any>('dcoReports', {
      name: (t: T) => t('Test Report'),
      listName: (t: T) => t('Test Reports'),
    }),
    urls: (testsId: number | string) => ({
      ...getStandardUrls(`test-reports/${testsId}`),
      list: '/test-reports',
    }),
    api: () => ({ ...getStandardApi('tests/dco'), tests: 'tests/dco/tests' }),
    permission: 'testDcoReports',
  },

  event: {
    ...definition<Event>('events', {
      name: (t: T) => t('Event'),
      listName: (t: T) => t('Events'),
    }),
    urls: () => ({
      ...getStandardUrls('events'),
      detailContents: (id: number | string) => getStandardUrls('events').detail(id) + '/contents',
      detailDashboard: (id: number | string) => getStandardUrls('events').detail(id) + '/dashboard',
    }),
    api: () => ({
      ...getStandardApi('events'),
      detailDashboardStaff: (id: number) => `/events/${id}/staff`,
      detailDashboardRecentlyFinalized: (id: number) => `/events/${id}/recently-finalized`,
      detailDashboardStats: (id: number) => `/events/${id}/stats`,
      createCoordinates: () => '/events/coordinates',
    }),
  },
  holder: definition<Holder>('holders', {
    name: (t: T) => t('Holder'),
    listName: (t: T) => t('Holders'),
  }),
  mission: {
    ...definition<Mission>('missions', {
      name: (t: T) => t('Mission'),
      listName: (t: T) => t('Missions'),
    }),
    urls: () => ({
      ...getStandardUrls('missions'),
      bulkEdit: '/missions/edit/bulk',
      detailContents: (id: number | string) => getStandardUrls('missions').detail(id) + '/contents',
    }),
    api: () => ({
      ...getStandardApi('missions'),
      tests: (missionsId: number | string) => `/missions/tests?${stringify({ missionsId })}`,
    }),
  },
  order: {
    ...definition<Order>('orders', {
      name: (t: T) => t('Order'),
      listName: (t: T) => t('Orders'),
    }),
    api: () => ({
      ...getStandardApi('orders'),
      import: (id: number | string) => `/orders/import/${id}`,
    }),
  },
  orderItem: {
    ...definition<Order>('orderItems', {
      name: (t: T) => t('Order Item'),
      listName: (t: T) => t('Order Items'),
    }),
    urls: (ordersId: number | string) => ({
      ...getStandardUrls(`orders-items/${ordersId}`),
      bulkEdit: (filter?: any) =>
        `/orders-items/${ordersId}/edit/bulk` +
        (filter
          ? '?' +
            stringify(
              Array.isArray(filter)
                ? {
                    id: filter,
                  }
                : filter
            )
          : ''),
    }),
    api: (orderId?: number | string) => ({
      ...getStandardApi(`orders${orderId ? '/' + orderId : ''}/items`),
      bulkEdit: (filter?: undefined | number[] | Record<string, unknown>) =>
        getResourcesUrl(
          `orders/items/edit/bulk`,
          filter ? (Array.isArray(filter) ? { id: filter } : filter) : undefined
        ),
    }),
  },

  sample: definition<Sample>('samples', {
    name: (t: T) => t('Sample'),
    listName: (t: T) => t('Samples'),
  }),
  tdp: {
    ...definition<Tdp>('tdps', {
      name: (t: T) => t('Test Distribution Plan'),
      listName: (t: T) => t('Test Distribution Plans'),
    }),
    api: () => ({
      ...getStandardApi('tdps'),
      download: (tdpsId: number | string, id: number | string) => `/tdps/${tdpsId}/download/${id}`,
      statistics: (tdpsId: number | string) => `/tdps/${tdpsId}/stats`,
      athletesList: (tdpsId: number | string) => `/tdps/${tdpsId}/athletes`,
      athletesBulk: (tdpsId: number | string) => `/tdps/${tdpsId}/athletes/bulk`,
      athletesDetail: (tdpsId: number | string, id: number | string) =>
        `/tdps/${tdpsId}/athletes/${id}`,
      classesList: `/tdps/classes`,
      testsList: (tdpsId: number | string) => `/tdps/${tdpsId}/tests`,
    }),
  },
  test: {
    ...definition<Test>('tests', { name: (t: T) => t('Test'), listName: (t: T) => t('Tests') }),
    urls: () => ({
      ...getStandardUrls('tests'),
      results: (id: number | string) => `${getStandardUrls('tests').detail(id)}/results`,
      bulkEdit: (filter?: undefined | number[] | Record<string, any>) =>
        '/tests/edit/bulk' +
        (filter
          ? '?' +
            stringify(
              Array.isArray(filter)
                ? {
                    id: filter,
                  }
                : filter
            )
          : ''),
      individualBulk: (filter?: undefined | number[] | Record<string, any>) =>
        '/tests/edit/individual-bulk' +
        (filter
          ? '?' +
            stringify(
              Array.isArray(filter)
                ? {
                    id: filter,
                  }
                : filter
            )
          : ''),
      createFromMission: (missionsId: number) => `/tests/create/detail?missionsId=${missionsId}`,
    }),
    api: () => ({
      ...getStandardApi('tests'),
      results: (id: number | string) => `${getStandardApi('tests').detail(id)}/results`,
      sendResults: (id: number | string) => `/tests/send-result/${id}`,

      bulkEdit: (filter?: undefined | number[] | Record<string, any>) =>
        getResourcesUrl(
          '/tests/edit/bulk',
          filter
            ? Array.isArray(filter)
              ? {
                  id: filter,
                }
              : filter
            : undefined
        ),
      testIndividualBulkEdit: (filter: undefined | number[] | Record<string, any>) =>
        getResourcesUrl(
          '/tests/edit/individual-bulk',
          filter
            ? Array.isArray(filter)
              ? {
                  id: filter,
                }
              : filter
            : undefined
        ),
      bulkDownload: '/tests/files/bulkDownload',
    }),
  },
  report: {
    ...definition<Base>('reports', {
      name: (t: T) => t('Report'),
      listName: (t: T) => t('Reports'),
    }),
    api: () => ({
      ...getStandardApi('reports'),
      download: (id: string | number) => `/reports/${id}`,
    }),
  },
  // Team member ??
  testResult: {
    ...definition<TestResult>('tests/results', {
      name: (t: T) => t('Test Result'),
      listName: (t: T) => t('Test Results'),
    }),
    urls: (testsId: string | number) => ({
      ...getStandardUrls(`test-results/${testsId}`),
    }),
    api: (testsId: string | number) => ({
      ...getStandardApi(`tests/${testsId}/results`),
    }),
  },

  testResultSample: {
    ...definition<TestResultSample>('tests/result/samples', {
      name: (t: T) => t('Test Result Sample'),
      listName: (t: T) => t('Test Result Samples'),
    }),
    urls: () => getStandardUrls('tests-result-samples'),
  },
  testReturn: {
    ...definition<TestReturn>('testReturns', {
      name: (t: T) => t('Test Return'),
      listName: (t: T) => t('Test Returns'),
    }),
    urls: () => getStandardUrls('tests-returns'),
    api: () => ({
      ...getStandardApi('tests/returns'),
      sendEmail: (id: number | string) => `tests/returns/${id}/sendEmail`,
    }),
  },
  testUaReport: {
    ...definition<UAReport>('ua', {
      name: (t: T) => t('UA Report'),
      listName: (t: T) => t('UA Reports'),
    }),
    permission: 'testUaReports',
    urls: (testsId: number | string) => ({ ...getStandardUrls(`ua/${testsId}`), list: '/ua' }),
    api: () => ({ ...getStandardApi('ua'), tests: 'ua/tests' }),
  },
  testAaReport: {
    ...definition<UAReport>('testAaReports', {
      name: (t: T) => t('After Action Report'),
      listName: (t: T) => t('After Action Reports'),
    }),
    urls: () => getStandardUrls('aars'),
    api: () => getStandardApi('tests/aar'),
  },
} as const;

export type K = keyof typeof planningEntities;
