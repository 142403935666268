import { Link } from 'react-router-dom';
import styled from 'styled-components/macro';

import theme from 'app/theme';

export const StyledHeader = styled.div`
  display: flex;
  align-items: center;
  padding: 0.625rem 1rem;
  flex-shrink: 0;
  justify-content: space-between;
  .mdc-icon-button {
    margin: -1rem;
  }
`;

export const LogoWrap = styled.div`
  align-items: flex-end;
  display: flex;
  svg {
    fill: ${theme.color.white};
    height: auto;
    height: ${theme.sidebarLogoHeight || '1.25rem'};
    width: auto;
  }
`;

export const StyledHamburger = styled.div`
  height: 2.75rem;
  min-width: 2.75rem;
  color: ${theme.color.white};
  display: flex;
  align-items: center;
  position: relative;
  padding: 0.3125rem;
`;

export const Wrap = styled.div<{ collapse?: boolean }>`
  justify-content: ${({ collapse }) => (collapse ? 'center' : 'space-between')};
  display: flex;
  width: 100%;
`;

export const StyledLink = styled(Link)`
  text-transform: uppercase;
  text-decoration: none !important;
  color: ${theme.color.white};
`;

export const StyledHeading = styled.div`
  text-transform: uppercase;
  font-size: 0.6875rem;
  font-style: normal;
`;
