import { FC } from 'react';
import { useTranslation } from 'react-i18next';

import { entities } from 'app/entity';
import DummyField from 'core/components/DummyField';
import Field from 'core/components/Form/Field';
import FormTextfield from 'core/components/FormTextfield';
import { Cell, Row } from 'core/components/Grid';
import { FullRowCell } from 'core/components/GridCell';
import PaperContainer from 'core/components/PaperContainer';
import { useDetail } from 'core/containers/FormDetailPage/DetailContext';
import Event from 'planning/models/Event';

import EventField from './EventField';
import ScheduleDateTimeFields from './ScheduleDateTimeFields';

interface Props {
  originEntity: string;
  originRange?: [Date, Date];
  originEvent?: Event | null;
  disablePublishAt?: boolean;
  disableRangeExpanding: boolean;
}

const ScheduleFieldset: FC<Props> = ({
  originEntity,
  originRange,
  originEvent,
  disablePublishAt,
  disableRangeExpanding,
}) => {
  const { t } = useTranslation();

  const { formData } = useDetail();
  const hasEventsId = formData && 'eventsId' in formData;

  return (
    <PaperContainer title={t('Schedule')}>
      <Row>
        <FullRowCell>
          {hasEventsId ? (
            // It is used in the mission's flows
            <EventField />
          ) : originEvent?.name ? (
            // When mission is connected to the test and the event have the name -> it is used in the test's flows
            <DummyField
              label={t('Event Name')}
              link={entities.event.urls().detail(originEvent.id)}
              value={originEvent?.name}
            />
          ) : (
            // In test is saved only the event name ->  is used in the test's flows
            <Field name="eventName" label={t('Event Name')} component={FormTextfield} />
          )}
        </FullRowCell>

        <Cell desktopColumns={6} tabletColumns={4} phoneColumns={4}>
          <Field name="race" label={t('Race')} component={FormTextfield} />
        </Cell>
        <Cell desktopColumns={6} tabletColumns={4} phoneColumns={4}>
          <Field component={FormTextfield} label={t('Place')} name="place" />
        </Cell>

        <ScheduleDateTimeFields
          originEntity={originEntity}
          originRange={originRange}
          disablePublishAt={disablePublishAt}
          disableExpanding={disableRangeExpanding}
        />
      </Row>
    </PaperContainer>
  );
};

export default ScheduleFieldset;
