import 'core/containers/DataGrid/styles.scss';
import { RowClickedEvent } from 'ag-grid-community';
import { AgGridReact } from 'ag-grid-react';
import { FC } from 'react';

import IconLoader from 'app/components/IconLoader';
import AgGridPaperWrapper from 'core/components/AgGridPaperWrapper';
import PaperContainer from 'core/components/PaperContainer';
import { Icon } from 'core/components/PaperContainer';
import { BadgeCellRenderer, IconCellRenderer } from 'core/containers/DataGrid/FastCellRenderers';
import { onCopyableRowClicked } from 'core/containers/DataGrid/helpers';

import useTableDefinitions from './useTableDefinitions';

interface Props {
  type: keyof ReturnType<typeof useTableDefinitions>;
  onSelect?: (event: RowClickedEvent) => void;
  data?: any[];
  loading: boolean;
  icons?: Icon[];
}

const PaperTable: FC<Props> = ({ data, type, onSelect, loading, icons }) => {
  const tables = useTableDefinitions();
  const tableDef = tables[type]();

  return (
    <PaperContainer
      icons={icons}
      badgePrimary={data && data.length.toString()}
      title={tableDef.title}
      fullHeight
    >
      {loading ? (
        <IconLoader />
      ) : (
        <AgGridPaperWrapper fullHeight>
          <AgGridReact<any>
            onGridReady={({ api }) => api.sizeColumnsToFit()}
            defaultColDef={{ sortable: true }}
            columnDefs={tableDef.columns}
            suppressHorizontalScroll={false}
            components={{
              badgeCellRenderer: BadgeCellRenderer,
              iconCellRenderer: IconCellRenderer,
            }}
            suppressCellFocus
            tooltipShowDelay={500}
            onRowClicked={(e) => onCopyableRowClicked(e, onSelect)}
            enableCellTextSelection
            ensureDomOrder
            rowData={data || []}
          />
        </AgGridPaperWrapper>
      )}
    </PaperContainer>
  );
};

export default PaperTable;
