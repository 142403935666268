import { FC, useMemo, memo } from 'react';

import { Option } from 'core/components/DropDown';
import { Cell as GridCell } from 'core/components/Grid';

import { OtherAnalyseInput } from './OtherBloodAnalyses';
import OtherRow from './OtherRow';

/**
 * Render other blood analyses rows
 * = Dropdown + remarks text + remove button
 */
const OtherRows: FC<{
  selectedValues: OtherAnalyseInput[];
  options: Option[];
  name: string;
}> = ({ options, selectedValues, name }) => {
  const items = useMemo(
    () =>
      selectedValues.map((value, index) => {
        const itOptions = options.filter(
          (i) =>
            i === 'SEPARATOR' || i.id === value.id || !selectedValues.find((j) => j.id === i.id)
        );
        return (
          <OtherRow
            key={`other-blood-analysis-${value.id}`}
            name={`${name}[${index}]`}
            options={itOptions}
          />
        );
      }),
    [name, options, selectedValues]
  );

  return (
    <GridCell desktopColumns={12} tabletColumns={8} phoneColumns={4}>
      {items}
    </GridCell>
  );
};

export default memo(OtherRows);
