import { ColDef } from 'ag-grid-community';
import { FC, useMemo } from 'react';
import { useTranslation } from 'react-i18next';

import theme from 'app/theme';
import ContextMenu from 'core/components/ContextMenu';
import IconButton from 'core/components/IconButton';
import MaterialIcon from 'core/components/MaterialIcon';
import { Item } from 'core/components/Menu';
import useAppSelector from 'core/hooks/useAppSelector';
import useUrlMultiState from 'core/hooks/useUrlMultiState';
import { UserView } from 'core/models/UserView';

import { COLUMNS_DEFAULT_VALUE, UrlColumnConfiguration } from '../../hooks/useColumnConfiguration';
import useDefaultGridConfiguration from '../../hooks/useDefaultGridConfiguration';
import { getUrlConfiguration } from '../../hooks/useDefaultViewConfiguration';
import { objectPropsNotEqual } from '../../hooks/useUrlState';
import { Filter, FilterValue } from '../../props';
import { VIEW_URL_KEYS } from '../CustomizeViews/Header';
import useSaveView from '../CustomizeViews/useSaveView';
import useSchema from '../CustomizeViews/useSchema';
import { ViewTabs } from '../CustomizeViews/useViewTabs';
import { ToolbarButton } from '../styled';

import { ButtonsWrapper, FilterButton } from './styled';

const hasGridSettingsChanged = (s1: UrlColumnConfiguration, s2: UrlColumnConfiguration) => {
  return ['hide', 'width', 'pinned', 'position'].some((it) =>
    objectPropsNotEqual(
      (s1[it as keyof UrlColumnConfiguration] || {}) as { [key: string]: any },
      (s2[it as keyof UrlColumnConfiguration] || {}) as { [key: string]: any }
    )
  );
};
interface Props {
  entity: string;
  userViews: UserView[];
  reloadUserViews: () => void;
  defaultFilterValues: { [key: string]: FilterValue };
  onApplyView: (
    filters: { [key: string]: FilterValue } | undefined,
    columnConfiguration: UrlColumnConfiguration
  ) => void;
  onOpen: (openTab?: ViewTabs) => void;
  filters?: { [id: string]: Filter };
  originalColumnDefs: ColDef<any>[];
}

const URL_KEYS = [...VIEW_URL_KEYS, 'filters', 'sort'];
export const URL_DEFAULT_VALUES = {
  ...COLUMNS_DEFAULT_VALUE,
  filters: {},
  sort: '',
};

const ViewChanges: FC<Props> = ({
  entity,
  userViews,
  reloadUserViews,
  onApplyView,
  onOpen,
  filters,
  defaultFilterValues,
  originalColumnDefs,
}) => {
  const { t } = useTranslation();
  const schema = useSchema();
  const usersId = useAppSelector((s) => s.core.user!.id);
  const defaultGridConfiguration: UrlColumnConfiguration =
    useDefaultGridConfiguration(originalColumnDefs);

  const {
    urlStatesValues: viewConfigurationAndFilters,
  }: {
    urlStatesValues: UrlColumnConfiguration & {
      filters: { [key: string]: FilterValue };
      sort: string;
    };
  } = useUrlMultiState(URL_KEYS, URL_DEFAULT_VALUES);

  const defaultView = useMemo(() => userViews.find((view) => view.default), [userViews]);

  const activeView = useMemo(() => {
    return userViews.find(
      (view) =>
        (view.default && !viewConfigurationAndFilters?.activeView) ||
        (viewConfigurationAndFilters?.activeView &&
          Number(viewConfigurationAndFilters.activeView) === Number(view.id))
    );
  }, [userViews, viewConfigurationAndFilters.activeView]);

  // We take filters from URL and not from parent, bcs from the parent it's faster and leads to wrong first display
  const filterValues = useMemo(
    () => viewConfigurationAndFilters?.filters || {},
    [viewConfigurationAndFilters?.filters]
  );

  const viewChanged = useMemo(() => {
    if (activeView) {
      // If we have active view, lets compare to its values
      const hasChangeOnFilters = objectPropsNotEqual(
        viewConfigurationAndFilters?.filters || {},
        activeView!.data?.filters || {}
      );
      const hasChangeOnGridSettings = hasGridSettingsChanged(
        viewConfigurationAndFilters,
        activeView!.data
      );
      const hasChangeOnDuplicity =
        'duplicity' in activeView!.data &&
        !!activeView!.data.duplicity !== !!viewConfigurationAndFilters?.duplicity;
      const hasChangeOnSort = activeView!.data?.sort !== viewConfigurationAndFilters?.sort;

      return (
        hasChangeOnFilters || hasChangeOnGridSettings || hasChangeOnDuplicity || hasChangeOnSort
      );
    }

    // No active view, lets compare to default grid column values
    const hasChangeOnFilters = objectPropsNotEqual(
      viewConfigurationAndFilters?.filters || {},
      defaultFilterValues || {}
    );
    const hasChangeOnSort = defaultGridConfiguration?.sort !== viewConfigurationAndFilters?.sort;
    const hasChangeOnGridSettings = hasGridSettingsChanged(
      viewConfigurationAndFilters,
      defaultGridConfiguration
    );

    return hasChangeOnFilters || hasChangeOnSort || hasChangeOnGridSettings;
  }, [activeView, viewConfigurationAndFilters, defaultFilterValues, defaultGridConfiguration]);

  const onSave = useSaveView(entity, onApplyView, userViews, reloadUserViews);

  const actions: Array<Omit<Item, 'onClick'> & { onClick: () => void }> = useMemo(() => {
    return [
      {
        key: 'saveChanges',
        text: t('Save changes'),
        onClick: () => {
          if (activeView) {
            const viewValues = schema.cast({ ...activeView }, { stripUnknown: true });
            onSave(
              viewValues,
              viewConfigurationAndFilters.hide as { [key: string]: boolean },
              filterValues
            );
          } else if (!defaultView) {
            // When we don't yet have default view saved, we save it as new default view
            onSave(
              schema.cast({ name: t('Default View'), default: true }),
              viewConfigurationAndFilters.hide as { [key: string]: boolean },
              filterValues
            );
          } else {
            onOpen(ViewTabs.DETAILS);
          }
        },
        disabled: (activeView && usersId !== activeView?.createdBy) || false,
      },
      ...(activeView || !defaultView
        ? [
            {
              key: 'createNew',
              text: t('Save as new View'),
              onClick: () => {
                onApplyView({ ...filterValues }, { activeView: undefined });
                onOpen(ViewTabs.DETAILS);
              },
            },
          ]
        : []),
    ];
  }, [
    t,
    activeView,
    filterValues,
    onApplyView,
    onOpen,
    onSave,
    schema,
    viewConfigurationAndFilters?.hide,
    usersId,
    defaultView,
  ]);

  const saveButton = (
    <IconButton
      onClick={actions.length < 2 ? actions[0].onClick : undefined}
      color={theme.color.primary}
      type="button"
      tooltip={t('Save')}
      icon="save"
      dense
    />
  );

  return (
    <ButtonsWrapper>
      <FilterButton>
        <ToolbarButton
          type="button"
          text={viewChanged ? t('Customized') : t('Customize')}
          icon={<MaterialIcon icon="filter_list" />}
          disabled={Object.keys(filters || {}).length === 0}
          onClick={() => onOpen()}
          $highlighted={viewChanged}
        />
      </FilterButton>

      {viewChanged && (
        <>
          {actions.length > 1 ? (
            <ContextMenu menuItems={actions} menuId="saveChangesControls">
              {saveButton}
            </ContextMenu>
          ) : (
            saveButton
          )}

          <IconButton
            color={theme.color.primary}
            onClick={() => {
              if (activeView) {
                const { filters, ...rest } = activeView.data || {};
                onApplyView({ ...filters }, getUrlConfiguration(rest, activeView?.id));
              } else {
                onApplyView({}, { hide: {}, activeView: undefined });
              }
            }}
            type="button"
            tooltip={t('Clear')}
            icon="cancel"
            dense
          />
        </>
      )}
    </ButtonsWrapper>
  );
};

export default ViewChanges;
