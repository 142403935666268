import { ReactElement, useCallback, useMemo, useRef } from 'react';

import useDropdownResource, {
  defaultDropdownReducer,
  Entity,
} from 'core/hooks/useDropdownResource';

import { Option } from '../DropDown';
import DummyDropdownField from '../DummyDropdownField';
import FormDropDown, { Props as FormDropDownProps } from '../FormDropDown';

import { useIncludeOptions } from './IncludeResourcesProvider';

/**
 * Connected dropdown component reads resources from API
 * based on resource url passed into "resource" property
 * YOU MUST ADD FILTER VALUE here too
 */
interface Props<O extends Entity> extends FormDropDownProps {
  resourceReducer?: (list: O[], value?: any) => Option[];
  viewMode?: boolean;
  resource: string;
}

const ResourceFormDropdown: <O extends Entity = Entity>(
  props: Props<O>
) => ReactElement<Props<O>> = <O extends Entity>({
  resourceReducer,
  resource,
  viewMode,
  field,
  mode,
  onOpen,
  ...props
}: Props<O>) => {
  const includes = useIncludeOptions(field.name) as O[];

  const hasValue =
    field.value !== undefined &&
    field.value !== null &&
    (!Array.isArray(field.value) || field.value.length > 0);

  const arrValue = hasValue
    ? Array.isArray(field.value)
      ? field.value
      : [field.value]
    : undefined;
  const hasInclude =
    arrValue && Array.isArray(includes) && arrValue.every((v) => includes.some((i) => v === i.id));

  const autoload = props.initOpen || mode === 'inline' || (hasValue && !hasInclude);

  const [resourceOptions, reload, isLoading] = useDropdownResource<O, Option>(resource, {
    reducer: (list: O[]) => list,
    selectedId: field.value,
    autoload,
  });

  const valueRef = useRef(field.value);
  valueRef.current = field.value; // suppress hook = don't re-render options on each change

  const options = useMemo(() => {
    const reducer = resourceReducer || defaultDropdownReducer;
    const resourcesList = (resourceOptions || []) as unknown as O[];

    const includesList = (includes || []).filter((i) => {
      return !Boolean(
        resourcesList.find(
          (j: any) => (typeof i === 'string' || typeof j !== 'string') && i !== j && j.id === i.id
        )
      );
    }) as unknown as O[];

    const list = includesList.concat(resourcesList);

    return reducer(list, valueRef.current);
  }, [resourceOptions, resourceReducer, includes]);

  const initFetch = useCallback(() => {
    !resourceOptions && reload(false);
    onOpen && onOpen();
  }, [resourceOptions, reload, onOpen]);

  const Component = viewMode ? DummyDropdownField : FormDropDown;

  return (
    <Component
      {...props}
      loading={isLoading}
      options={options || []}
      onOpen={initFetch}
      // @ts-ignore TODO: Figure out the typing - they come from the same place, but are not compatible?
      field={field}
      mode={mode}
    />
  );
};

export default ResourceFormDropdown;
