import { LOCATION_CHANGE } from 'connected-react-router';
import { takeEvery, takeLatest, takeLeading } from 'redux-saga/effects';

import {
  TYPE_ACTIVATE_ACCOUNT,
  TYPE_API_CALL,
  TYPE_API_QUERY,
  TYPE_BULK_DOWNLOAD,
  TYPE_BULK_UPLOAD_FILES,
  TYPE_COPY_TO_CLIPBOARD,
  TYPE_CREATE_ENTITY,
  TYPE_DATAGRID_BULK_DELETE,
  TYPE_DATAGRID_BULK_PATCH,
  TYPE_DELETE_DIALOG_ENTITY,
  TYPE_DELETE_ENTITY,
  TYPE_DELETE_FILE,
  TYPE_DELETE_VIEW,
  TYPE_DOWNLOAD_DATA,
  TYPE_EDIT_ENTITY,
  TYPE_EXPORT_DATA,
  TYPE_FAVORITE_VIEW,
  TYPE_FETCH_DATAGRID_DATA,
  TYPE_FIRE_API_QUERY,
  TYPE_GENERATE_FILE,
  TYPE_GENERATE_PDF_MANUALLY,
  TYPE_GET_NOTIFICATIONS,
  TYPE_GET_WORKSPACE,
  TYPE_IMPORT_DATA,
  TYPE_INSTALL_APPLICATION,
  TYPE_LOAD_ENTITY,
  TYPE_LOAD_PROFILE,
  TYPE_LOGIN,
  TYPE_LOGIN_AS_USER,
  TYPE_LOGOUT,
  TYPE_NOTIFICATION_MARK_READ,
  TYPE_RELOAD_API_QUERY,
  TYPE_REPORT_SEND,
  TYPE_RESET_PASSWORD,
  TYPE_RESTORE_PASSWORD,
  TYPE_SAVE_DIALOG_ENTITY,
  TYPE_SAVE_FILE_DOCUMENT,
  TYPE_SAVE_VIEW,
  TYPE_SAVE_WATCHERS,
  TYPE_SEND_EMAIL,
  TYPE_SOCKET_MESSAGE,
  TYPE_SOCKET_START,
  TYPE_SUBSCRIBE_2FA,
  TYPE_UNSUBSCRIBE_2FA,
  TYPE_UPLOAD_IMAGE,
  TYPE_VALIDATE_CODE,
  TYPE_VERIFY_2FA,
} from '../actions';

import activateAccountSaga from './activateAccount';
import apiCall from './apiCall';
import { apiQuerySaga, fireApiQuerySaga, reloadApiQuerySaga } from './apiQuery';
import copyToClipboard from './copyToClipboard';
import datagridBulkDelete from './datagridBulkDelete';
import datagridBulkPatch from './datagridBulkPatch';
import { deleteDialogEntitySaga, saveDialogEntitySaga } from './dialogEntity';
import * as downloadDataSaga from './downloadData';
import { createEntitySaga, deleteEntitySaga, editEntitySaga, loadEntitySaga } from './entity';
import { getNotifications, notificationMarkRead, watchSocketMessages } from './events';
import exportData from './exportData';
import fetchDataGridData from './fetchDataGridData';
import * as filesSaga from './files';
import generateFileSaga from './generateFile';
import generatePdfManuallySaga from './generatePdfManually';
import getWorkspace from './getWorkspace';
import importData from './importData';
import installApplicationSaga from './installApplication';
import loadProfileSaga from './loadProfile';
import locationChange from './locationChange';
import login from './login';
import loginAsUser from './loginAsUser';
import logout from './logout';
import persistRehydrate from './persistRehydrate';
import reportSendSaga from './reportSend';
import resetPasswordSaga from './resetPassword';
import restorePasswordSaga from './restorePassword';
import sendEmailSaga from './sendEmail';
import { startSocketSaga } from './socket';
import subscribe2FASaga from './subscribe2FA';
import unsubscribe2FASaga from './unsubscribe2FA';
import uploadAvatarSaga from './uploadImage';
import validateCodeSaga from './validateCode';
import verify2FA from './verify2FA';
import * as filtersSaga from './views';
import { saveWatchersSaga } from './watchers';

export default function* root() {
  yield takeLeading('persist/REHYDRATE', persistRehydrate);

  yield takeEvery(LOCATION_CHANGE, locationChange);

  yield takeLatest(TYPE_LOGIN, login);
  yield takeLatest(TYPE_GET_WORKSPACE, getWorkspace);
  yield takeLatest(TYPE_LOGIN_AS_USER, loginAsUser);
  yield takeLatest(TYPE_LOGOUT, logout);
  yield takeLatest(TYPE_VALIDATE_CODE, validateCodeSaga);
  yield takeLatest(TYPE_RESTORE_PASSWORD, restorePasswordSaga);
  yield takeLatest(TYPE_RESET_PASSWORD, resetPasswordSaga);
  yield takeLatest(TYPE_ACTIVATE_ACCOUNT, activateAccountSaga);

  yield takeEvery(TYPE_API_CALL, apiCall);
  yield takeEvery(TYPE_FETCH_DATAGRID_DATA, fetchDataGridData);
  yield takeEvery(TYPE_DATAGRID_BULK_DELETE, datagridBulkDelete);
  yield takeEvery(TYPE_DATAGRID_BULK_PATCH, datagridBulkPatch);
  yield takeEvery(TYPE_EXPORT_DATA, exportData);
  yield takeEvery(TYPE_IMPORT_DATA, importData);

  yield takeLatest(TYPE_DOWNLOAD_DATA, downloadDataSaga.downloadData);
  yield takeLatest(TYPE_BULK_DOWNLOAD, downloadDataSaga.bulkDownload);

  yield takeLatest(TYPE_LOAD_PROFILE, loadProfileSaga);
  yield takeLatest(TYPE_UPLOAD_IMAGE, uploadAvatarSaga);

  yield takeLatest(TYPE_CREATE_ENTITY, createEntitySaga);
  yield takeLatest(TYPE_EDIT_ENTITY, editEntitySaga);
  yield takeLatest(TYPE_DELETE_ENTITY, deleteEntitySaga);
  yield takeLatest(TYPE_LOAD_ENTITY, loadEntitySaga);

  yield takeLatest(TYPE_SAVE_DIALOG_ENTITY, saveDialogEntitySaga);
  yield takeLatest(TYPE_DELETE_DIALOG_ENTITY, deleteDialogEntitySaga);

  yield takeLatest(TYPE_SUBSCRIBE_2FA, subscribe2FASaga);
  yield takeLatest(TYPE_UNSUBSCRIBE_2FA, unsubscribe2FASaga);
  yield takeLatest(TYPE_VERIFY_2FA, verify2FA);

  yield takeLatest(TYPE_SAVE_FILE_DOCUMENT, filesSaga.saveFileDocument);
  yield takeLatest(TYPE_BULK_UPLOAD_FILES, filesSaga.bulkUploadFiles);
  yield takeLatest(TYPE_DELETE_FILE, filesSaga.deleteFile);

  yield takeLatest(TYPE_SOCKET_START, startSocketSaga);
  yield takeEvery(TYPE_SOCKET_MESSAGE, watchSocketMessages);
  yield takeLatest(TYPE_GET_NOTIFICATIONS, getNotifications);
  yield takeEvery(TYPE_NOTIFICATION_MARK_READ, notificationMarkRead);

  yield takeEvery(TYPE_REPORT_SEND, reportSendSaga);

  yield takeLatest(TYPE_INSTALL_APPLICATION, installApplicationSaga);

  yield takeLatest(TYPE_GENERATE_PDF_MANUALLY, generatePdfManuallySaga);
  yield takeLatest(TYPE_GENERATE_FILE, generateFileSaga);
  yield takeLatest(TYPE_SEND_EMAIL, sendEmailSaga);

  yield takeLatest(TYPE_SAVE_VIEW, filtersSaga.saveView);
  yield takeLatest(TYPE_FAVORITE_VIEW, filtersSaga.favoriteView);
  yield takeLatest(TYPE_DELETE_VIEW, filtersSaga.deleteView);

  yield takeLatest(TYPE_COPY_TO_CLIPBOARD, copyToClipboard);

  yield takeEvery(TYPE_API_QUERY, apiQuerySaga);
  yield takeEvery(TYPE_FIRE_API_QUERY, fireApiQuerySaga);
  yield takeEvery(TYPE_RELOAD_API_QUERY, reloadApiQuerySaga);

  yield takeLatest(TYPE_SAVE_WATCHERS, saveWatchersSaga);
}
