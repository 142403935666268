import { FormData as SettingsFormData } from 'settings/containers/AdamsSettingsPage/useSchema';

export const TYPE_LOAD_ADAMS_SETTINGS = 'LOAD_ADAMS_SETTINGS';
export const loadAdamsSettings = () =>
  ({
    type: TYPE_LOAD_ADAMS_SETTINGS,
    payload: {},
  }) as const;
export type LoadAdamsSettingsAction = ReturnType<typeof loadAdamsSettings>;

export const TYPE_UNLOAD_ADAMS_SETTINGS = 'UNLOAD_ADAMS_SETTINGS';
export const unloadAdamsSettings = () =>
  ({
    type: TYPE_UNLOAD_ADAMS_SETTINGS,
    payload: {},
  }) as const;
export type UnloadAdamsSettingsAction = ReturnType<typeof unloadAdamsSettings>;

export const TYPE_EDIT_ADAMS_SETTINGS = 'EDIT_ADAMS_SETTINGS';
export const editAdamsSettings = (fd: SettingsFormData) =>
  ({
    type: TYPE_EDIT_ADAMS_SETTINGS,
    payload: { fd },
  }) as const;
export type EditAdamsSettingsAction = ReturnType<typeof editAdamsSettings>;

export type AdamsSettingsActions =
  | LoadAdamsSettingsAction
  | UnloadAdamsSettingsAction
  | EditAdamsSettingsAction;
