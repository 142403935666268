import { toast } from 'react-toastify';
import { put, take, race } from 'redux-saga/effects';

import { apiCall, TYPE_SEND_EMAIL, SendEmailAction } from 'core/actions';
import { t } from 'core/i18n';

export default function* sendEmail(action: SendEmailAction) {
  const { endpoint, successCallback, values } = action.payload;

  yield put(apiCall(TYPE_SEND_EMAIL, 'POST', endpoint, values, {}));

  const { success } = yield race({
    success: take(`${TYPE_SEND_EMAIL}_SUCCESS`),
  });

  if (success) {
    toast.info(t('The process for sending the email has begun'));
    successCallback();
  }
}
