import { FC } from 'react';
import { useTranslation } from 'react-i18next';

import theme from 'app/theme';
import Tooltip from 'core/components/Tooltip';

import {
  SwitchButtonsWrapper,
  TooltipContainer,
  Wrapper,
  StyledMaterialSwitch,
  Label,
  SwitchButton,
} from './styled';

interface Props {
  color?: keyof typeof theme.color;
  onChange: (b: boolean) => void;
  disabled?: boolean;
  tooltip?: string;
  value?: boolean;
  label: string;
  name: string;
  id: string;
  mode?: 'inline' | 'buttons' | 'not-inline';
  margin?: string;
}

const Switch: FC<Props> = ({
  onChange,
  disabled,
  tooltip,
  value,
  label,
  color,
  name,
  id,
  mode = 'inline',
  margin,
}) => {
  const { t } = useTranslation();

  const materialSwitch = (
    <StyledMaterialSwitch
      nativeControlId={id}
      disabled={disabled}
      inline={mode === 'inline'}
      checked={value}
      color={color}
      name={name}
    />
  );

  const switchWithOptionalTooltip = (
    <>
      {tooltip ? (
        <TooltipContainer>
          <Tooltip content={tooltip} wrapInSpan>
            {materialSwitch}
          </Tooltip>
        </TooltipContainer>
      ) : (
        materialSwitch
      )}
    </>
  );

  const buttons = (
    <>
      <SwitchButtonsWrapper role="radiogroup" aria-labelledby={id}>
        <SwitchButton
          outlined={value === true} // for having it unfilled with null value
          text={t('Yes')}
          onClick={() => onChange(true)}
          type="button"
          role="radio"
          aria-checked={value === true}
          isLeftButton
          disabled={disabled}
        />
        <SwitchButton
          outlined={value === false} // for having it unfilled with null value
          text={t('No')}
          onClick={() => onChange(false)}
          type="button"
          role="radio"
          aria-checked={value === false}
          disabled={disabled}
        />
      </SwitchButtonsWrapper>
    </>
  );

  return (
    <Wrapper
      inline={mode === 'inline'}
      margin={margin}
      onClick={() => {
        !disabled && mode !== 'buttons' && onChange(!value);
      }}
    >
      <Label
        htmlFor={mode === 'inline' ? id : undefined}
        id={mode === 'buttons' ? id : undefined}
        onClick={(e) => e.preventDefault()}
      >
        {label}
      </Label>
      {mode === 'buttons' ? buttons : switchWithOptionalTooltip}
    </Wrapper>
  );
};

export default Switch;
