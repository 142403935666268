import { FC, forwardRef, ReactNode, useMemo, useRef } from 'react';
import { useTranslation } from 'react-i18next';

import Tooltip from 'core/components/Tooltip';

import MaterialIcon from '../MaterialIcon';

import LinkElement from './LinkElement';
import { End, Start, StyledField, StyledLabel, StyledValue, Wrapper } from './styled';

export type ValueStyle = 'new' | 'old' | undefined;
export interface DummyFieldProps {
  /* Field value */
  value?: string | number | null | undefined | boolean | string[];
  /* simple link end adornment displayed as "chain" icon button */
  link?: ((value: any) => string) | string;
  /* link could be disabled with reason displayed in tooltip */
  disabledLink?: ReactNode | boolean | string;
  /* Custom field end adornment element - like edit button or remove... */
  endAdornment?: ReactNode;
  /* Custom field start adornment element */
  startAdornment?: ReactNode;
  /* formik field prop (we could use this as formik field component) */
  field?: {
    value: string | number | null | undefined | boolean | string[];
    name?: string;
  };
  /* Boolean dummy field will print "Yes" or "No" string based on value */
  boolean?: boolean;
  /* multiline mode */
  textarea?: boolean;
  /* Custom tooltip / reason why is field in view mode only */
  tooltip?: string;
  /* Support smaller margins */
  dense?: boolean;
  /* Field label */
  label: string;
  /*Custom icon displayed before label */
  startIcon?: string;
  /*Custom icon displayed after label */
  endIcon?: string;
  /*First display Value, than label */
  reverseTitleOrder?: boolean;
  /*Number of rows for textarea*/
  rows?: number;
  /*In case HTML type is used like password*/
  type?: string;
  /** Force minimum width */
  minWidth?: string | number;
  /** Styling for old/new value */
  valueStyle?: ValueStyle;
  /** Add vertical scroll bar to display the overflowed text  */
  enableVerticalScroll?: boolean;
}
const DummyField: FC<DummyFieldProps> = forwardRef<HTMLDivElement, DummyFieldProps>(
  (props, ref) => {
    const {
      endAdornment = undefined,
      valueStyle = undefined,
      boolean = false,
      disabledLink,
      textarea,
      tooltip,
      label,
      value,
      field,
      dense,
      link,
      reverseTitleOrder,
      startIcon,
      endIcon,
      minWidth,
      startAdornment,
      type,
      rows,
      enableVerticalScroll = false,
    } = props;
    const { t } = useTranslation();
    const val = value ?? field?.value;
    const accessibilityId = useRef(
      field?.name ? `form-${field.name}` : Math.random().toString(36).slice(-6)
    );

    const renderVal = useMemo(() => {
      if (
        val === undefined ||
        val === null ||
        val === '' ||
        (Array.isArray(val) && val.length === 0)
      ) {
        return '-';
      } else if (boolean || typeof val === 'boolean') {
        return val ? t('Yes') : t('No');
      } else if (val && typeof val === 'string' && type === 'password') {
        return '*****';
      } else if (typeof val === 'number') {
        return (val || 0).toString();
      } else if (Array.isArray(val)) {
        return val.join(', ');
      }

      return val;
    }, [boolean, val, type, t]);

    const hasValue = !!renderVal || (textarea && !!rows);

    const styledLabel = label && (
      <StyledLabel valueStyle={valueStyle} id={accessibilityId.current}>
        {label}
      </StyledLabel>
    );

    const styledValue = (
      <StyledValue
        aria-labelledby={accessibilityId.current}
        multiline={!!textarea}
        multilineRows={rows}
        hasValue={hasValue}
        valueStyle={valueStyle}
        enableVerticalScroll={enableVerticalScroll}
        // @ts-ignore
        disabled
      >
        {renderVal}
      </StyledValue>
    );

    const titles = reverseTitleOrder ? (
      <>
        {styledValue}
        {styledLabel}
      </>
    ) : (
      <>
        {styledLabel}
        {styledValue}
      </>
    );

    const elem = (
      <Wrapper
        $valueStyle={valueStyle}
        $dense={dense}
        ref={ref}
        className="dummy-field"
        $minWidth={minWidth}
        enableVerticalScroll={enableVerticalScroll}
      >
        {Boolean(startAdornment || startIcon) && (
          <Start>
            {startAdornment}
            {startIcon && <MaterialIcon icon={startIcon} />}
          </Start>
        )}

        <StyledField
          hasValue={hasValue}
          hasLabel={Boolean(label)}
          hasStartIcon={Boolean(startAdornment || startIcon)}
          hasEndIcon={Boolean(endIcon || disabledLink || link || endAdornment)}
          enableVerticalScroll={enableVerticalScroll}
        >
          {titles}
        </StyledField>

        {Boolean(endIcon || disabledLink || link || endAdornment) && (
          <End>
            {endIcon && <MaterialIcon icon={endIcon} />}
            {Boolean(disabledLink || link) && (
              <LinkElement link={link && getLink(link, value)} disabledLink={disabledLink} />
            )}
            {endAdornment}
          </End>
        )}
      </Wrapper>
    );

    return tooltip ? <Tooltip content={tooltip}>{elem}</Tooltip> : elem;
  }
);

export default DummyField;

export function getLink(link: ((value: any) => string) | string, value: any): string {
  if (link && typeof link === 'string') {
    return (link as string).replace(':value', value as string) || '';
  } else if (link && typeof link === 'function') {
    return (link as (id: string) => string)(value as string) || '';
  }
  return '';
}
