import { MouseEventHandler } from 'react';
import styled from 'styled-components/macro';

import theme from 'app/theme';

type ThemeColor = keyof Omit<typeof theme.color, 'status'>;
type ThemeStatusColor = keyof typeof theme.color.status;

interface Props {
  variant: ThemeStatusColor | ThemeColor;
  color?: string;
  strong?: boolean;
  onClick?: (e: MouseEventHandler<HTMLDivElement>) => void;
}

const Badge = styled(({ children, className, onClick }) => (
  <div className={className} onClick={onClick}>
    {children}
  </div>
)).attrs<Props>(({ variant, color, strong }: Props) => ({
  bg: theme.color[variant as ThemeColor] || theme.color.status[variant as ThemeStatusColor],
  color: color || 'white',
  strong,
}))<Props>`
  background-color: ${({ bg }) => bg};
  color: ${({ color }) => color};
  padding: 0.25rem 0.5rem;
  display: inline-block;
  border-radius: 1rem;
  font-weight: ${({ strong }) => (strong ? 'bold' : 'normal')};
  font-size: 0.75rem;
  line-height: 1;
  white-space: nowrap;
`;

export const BadgesWrapper = styled.div`
  & > *:not(:last-child) {
    margin-right: 0.313rem;
  }
`;

export default Badge;
