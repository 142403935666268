import { takeLatest } from 'redux-saga/effects';

import {
  TYPE_LOAD_CONTROL_SETTINGS,
  TYPE_EDIT_CONTROL_SETTINGS,
  TYPE_LOAD_ADAMS_SETTINGS,
  TYPE_EDIT_ADAMS_SETTINGS,
} from '../actions';

import * as adamsSettingsSaga from './adamsSettings';
import * as controlSettingsSaga from './controlSettings';

export default function* root() {
  yield takeLatest(TYPE_LOAD_CONTROL_SETTINGS, controlSettingsSaga.loadControlSettingsSaga);
  yield takeLatest(TYPE_EDIT_CONTROL_SETTINGS, controlSettingsSaga.editControlSettingsSaga);
  yield takeLatest(TYPE_LOAD_ADAMS_SETTINGS, adamsSettingsSaga.loadAdamsSettingsSaga);
  yield takeLatest(TYPE_EDIT_ADAMS_SETTINGS, adamsSettingsSaga.editAdamsSettingsSaga);
}
