import TabBar from '@material/react-tab-bar';
import styled from 'styled-components/macro';

export const StyledTabBar = styled(TabBar)`
  margin: 0;

  &.mdc-tab-bar {
    width: auto;

    .mdc-tab {
      border-bottom-width: 3px;
      flex: 0;
      padding: 0 1rem;
    }
  }
`;
