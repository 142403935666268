import styled from 'styled-components/macro';

import theme from 'app/theme';

export const PopoverWrapper = styled.div`
  background: ${theme.color.white};
  z-index: 1;
  /* 4px value follows material design surface component we are using */
  border-radius: 4px;

  box-shadow: ${theme.popoverShadow};

  visibility: ${(p: { visible: boolean }) => (p.visible ? 'visible' : 'hidden')};
`;
