import { toast } from 'react-toastify';
import { call, put, race, take } from 'redux-saga/effects';
// @ts-ignore They are missing typings and are not important

import { parse } from 'core/functions/qs';
import apiRedirect from 'core/functions/redirectWithMethod';
import { t } from 'core/i18n';

import {
  LoginAction,
  TYPE_LOGIN,
  TYPE_LOGIN_ERROR,
  TYPE_LOGIN_SUCCESS,
  apiCall,
  savePrevPathname,
  switchLogin,
} from '../actions';

import bootstrap from './bootstrap';

/**
 * After submit email and password in the login form, this function will try to login the user.
 */
function* login(action: LoginAction) {
  const {
    code_challenge,
    code_challenge_method,
    redirect_uri,
    client_id,
    response_type,
    state,
    scope,
    allowed_role,
  } = parse(window.location.search);

  yield put(
    apiCall(
      TYPE_LOGIN,
      'POST',
      `/auth/login/${action.payload.passcode ? 'mfa' : ''}`,
      action.payload.passcode ? action.payload : { ...action.payload, passcode: undefined },
      { baseURL: process.env.REACT_APP_API_URL },
      false
    )
  );

  // get prevPathname from localStorage
  const prevPathname = localStorage.getItem('prevPathname');
  if (prevPathname) {
    yield put(savePrevPathname(prevPathname));
    localStorage.removeItem('prevPathname');
  }

  const { success, error } = yield race({
    success: take(TYPE_LOGIN_SUCCESS),
    error: take(TYPE_LOGIN_ERROR),
  });

  if (error) {
    const e = error.payload.error;
    if (e.response && e.response.status === 401) {
      if (action.payload.passcode) {
        toast.error(t('Login failed: Incorrect email, password or passcode.'));
      } else {
        toast.error(t('Login failed: Incorrect email or password.'));
      }
    } else {
      const message =
        e.response && e.response.data && e.response.data.message
          ? e.response.data.message
          : e.message;
      toast.error(t('Login failed: {{message}}', { message }));
    }
    return;
  }

  if (!success) {
    return;
  }

  if (success.payload.response.status === 202) {
    yield put(switchLogin(true));
    return;
  }

  const rolesId = success.payload.response?.data?.rolesId;
  if (allowed_role && rolesId !== +allowed_role) {
    toast.error(t('Login failed: Role is not allowed.'));
    return;
  }

  if (redirect_uri) {
    const isAbsolutePattern = /^https?:\/\//i;
    const api_url = process.env.REACT_APP_API_URL
      ? isAbsolutePattern.test(process.env.REACT_APP_API_URL)
        ? process.env.REACT_APP_API_URL
        : `${window.location.protocol}//${window.location.host}${process.env.REACT_APP_API_URL}`
      : undefined;
    apiRedirect('POST', `${process.env.REACT_APP_API_URL}/oauth/authorize`, {
      code_challenge,
      code_challenge_method,
      redirect_uri,
      client_id,
      response_type,
      state,
      scope,
      api_url,
    });
  } else {
    yield call(bootstrap, false);
  }
}

export default login;
