import {
  Dispatch,
  FC,
  ReactNode,
  SetStateAction,
  createContext,
  useContext,
  useState,
} from 'react';

type IncludeOverridesType = [
  Record<string, unknown>,
  Dispatch<SetStateAction<Record<string, unknown>>>,
];

const IncludeOverrides = createContext<IncludeOverridesType>([{}, () => {}]);

export const IncludeOverrideProvider: FC<{
  children: ReactNode | ((args: IncludeOverridesType) => ReactNode);
}> = ({ children }) => {
  const state = useState({});

  return (
    <IncludeOverrides.Provider value={state}>
      {typeof children === 'function' ? children(state) : children}
    </IncludeOverrides.Provider>
  );
};

const useIncludeOverride = () => {
  return useContext(IncludeOverrides);
};

export default useIncludeOverride;
