import { FC } from 'react';
import { useTranslation } from 'react-i18next';

import NoData from 'app/assets/icons/NoData';

import { Item, Img, Text } from './styled';

const RenderNoData: FC = () => {
  const { t } = useTranslation();
  return (
    <Item>
      <Img>
        <NoData />
      </Img>
      <Text>{t('You have no unread notifications!')}</Text>
    </Item>
  );
};

export default RenderNoData;
