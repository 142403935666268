import { FC } from 'react';

import DummyField from 'core/components/DummyField';
import formatPhone from 'core/functions/formatPhone';

const DummyPhoneField: FC<any> = (props) => (
  <DummyField {...props} field={{ ...props.field, value: formatPhone(props.field.value) }} />
);

export default DummyPhoneField;
