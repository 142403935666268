import { ICellRendererParams } from 'ag-grid-community';
import tippy, { Instance, createSingleton } from 'tippy.js';

import { defaultTippyOptions } from 'core/components/Tooltip';

import styles from './index.module.css';

export interface IconCellParams {
  /**
   * Name of the icon if it should be material icon
   *
   * Only one of "name", "path", or "code" should be provided
   */
  name?: string | ((data: any) => string);
  /**
   * Path to the icon if it is a custom icon
   *
   * Only one of "name", "path", or "code" should be provided
   */
  path?: string | ((data: any) => string);
  /**
   * String that will be displayed as icon
   *
   * Only one of "name", "path", or "code" should be provided
   */
  code?: string | ((data: any) => string);
  visible?(data: any): boolean | undefined;
  tooltip?: string | ((data: any) => string);
  color?: string | ((data: any) => string);
  size?: string;
  spacing?: string;
}

type Props = ICellRendererParams & {
  icons: IconCellParams[];
  tooltip?: string;
  alignRight: boolean;
  titleLeft?(data: any): string | null;
  titleRight?(data: any): string | null;
  hasError?: (data: any) => boolean;
};

export default class IconCellRenderer {
  private element?: HTMLDivElement;
  private tippyInstances: Instance[] = [];

  public init({ tooltip, icons, hasError, titleLeft, titleRight, alignRight, data }: Props) {
    this.element = document.createElement('div');
    this.element.className = styles.icons;
    if (alignRight) this.element.className += ` ${styles['align-right']}`;

    if (hasError && hasError(data)) {
      this.element.style.border = `1px solid var(--mdc-theme-error)`;
      this.element.style.borderRadius = '3px';
    }

    const leftTitle = data && titleLeft && titleLeft(data);
    if (leftTitle) {
      const titleElement = document.createElement('div');
      titleElement.textContent = leftTitle;
      this.element.appendChild(titleElement);
    }

    const iconsWrapperElement = document.createElement('div');
    iconsWrapperElement.className = styles.wrapper;
    const tippySingletonInstances: Instance[] = [];
    icons.forEach(({ visible, name, path, code, color, size, tooltip, spacing = '0.25rem' }) => {
      if (!data || (visible && !visible(data))) {
        return null;
      }

      let iconElement: HTMLDivElement | HTMLImageElement;
      if (name) {
        const icon = typeof name === 'function' ? name(data) : name;
        iconElement = document.createElement('div');
        iconElement.className = `material-icons icon-${icon} ${styles['icon']}`;
        if (size) iconElement.style.fontSize = size;
      } else if (path) {
        iconElement = document.createElement('img');
        if (size) {
          iconElement.style.width = size;
          iconElement.style.height = size;
        }
        (iconElement as HTMLImageElement).src = typeof path === 'function' ? path(data) : path;
      } else if (code) {
        iconElement = document.createElement('div');
        iconElement.className = styles.code;
        iconElement.textContent = typeof code === 'function' ? code(data) : code;
        if (size) iconElement.style.fontSize = size;
      } else {
        // eslint-disable-next-line no-console
        console.error('Either path, name, or code must be supplied for an icon, skipping!');
        return;
      }

      if (color) iconElement.style.color = typeof color === 'function' ? color(data) : color;
      iconElement.style.marginLeft = spacing;

      if (tooltip) {
        if (iconElement.tagName === 'img') {
          (iconElement as HTMLImageElement).alt =
            typeof tooltip === 'function' ? tooltip(data) : tooltip;
        }

        const tippyInstance = tippy(iconElement, {
          content: typeof tooltip === 'function' ? tooltip(data) : tooltip,
        });
        this.tippyInstances.push(tippyInstance);
        tippySingletonInstances.push(tippyInstance);
      }

      iconsWrapperElement.appendChild(iconElement);
    });
    createSingleton(tippySingletonInstances, defaultTippyOptions);
    this.element.appendChild(iconsWrapperElement);

    const rightTitle = data && titleRight && titleRight(data);
    if (rightTitle) {
      const titleElement = document.createElement('div');
      titleElement.textContent = rightTitle;
      titleElement.style.marginLeft = '0.3rem';
      this.element.appendChild(titleElement);
    }

    if (tooltip) {
      this.tippyInstances.push(
        tippy(this.element, {
          ...defaultTippyOptions,
          content: tooltip,
        })
      );
    }
  }

  public getGui() {
    // Ag-grid should call init before getGui, so we should be fairly sure
    return this.element!;
  }

  public destroy() {
    this.tippyInstances.forEach((instance) => instance.destroy());
  }

  public refresh() {
    // Refreshing would be complex, so we swallow telling grid we could not rerender
    return false;
  }
}
