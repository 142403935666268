import { getIn, useFormikContext } from 'formik';
import { FC } from 'react';
import { useTranslation } from 'react-i18next';

import DummyField from 'core/components/DummyField';
import { FeedbackMessage } from 'core/components/FeedbackMessage';
import Field from 'core/components/Form/Field';
import { ExplanationWrapper } from 'core/components/FormStyles/styled';
import FormSwitch from 'core/components/FormSwitch';
import { Cell, Row } from 'core/components/Grid';
import { FullRowCell, HalfRowCell } from 'core/components/GridCell';
import Slider from 'core/components/Slider';

import { StyledSliderWrapper } from '../../Features/styled';
import { FormData, SampleCodeType, useSampleCodeSchema } from '../../useSchema';

type Props = {
  label: string;
  value: SampleCodeType;
  fieldName: string;
};

const SampleCode: FC<Props> = ({ label, value, fieldName }) => {
  const { errors, setFieldValue, touched } = useFormikContext<FormData>();

  const { t } = useTranslation();
  const sampleCodeSchema = useSampleCodeSchema();
  const hasError = getIn(touched, '') && getIn(errors, `${fieldName}.min`);

  return (
    <>
      <FullRowCell>
        <Field
          name={`${fieldName}.enabled`}
          label={label}
          inline
          component={FormSwitch}
          fast={false}
          onChange={(newValue: boolean) => {
            // So we will reset subfields on each toggle
            setFieldValue(fieldName, sampleCodeSchema.cast({ enabled: newValue }));
          }}
        />

        <ExplanationWrapper>
          {t('Enables you to specify the validation required for the Sample Code')}
        </ExplanationWrapper>
      </FullRowCell>

      {!!value?.enabled && (
        <>
          <FullRowCell>
            <Field
              name={`${fieldName}.alphanumeric`}
              label={t('Alphanumeric')}
              inline
              component={FormSwitch}
              fast={false}
            />
            <ExplanationWrapper>
              {t('If enabled, the Sample Code can also contain letters')}
            </ExplanationWrapper>
          </FullRowCell>

          <FullRowCell>
            <Row>
              <HalfRowCell>
                <Cell desktopColumns={3} tabletColumns={3} phoneColumns={4}>
                  <DummyField label={t('Min Length')} value={value?.min} />
                  {hasError && <FeedbackMessage>{hasError}</FeedbackMessage>}
                </Cell>

                <Cell desktopColumns={9} tabletColumns={5} phoneColumns={4}>
                  <StyledSliderWrapper>
                    <Slider
                      step={1}
                      min={0}
                      max={50}
                      onChange={(newValue: number) =>
                        setFieldValue(fieldName, sampleCodeSchema.cast({ ...value, min: newValue }))
                      }
                      leftIcon="remove"
                      rightIcon="add"
                      value={value?.min ? value.min : undefined}
                    />
                  </StyledSliderWrapper>
                </Cell>
              </HalfRowCell>
              <HalfRowCell>
                <Cell desktopColumns={3} tabletColumns={3} phoneColumns={4}>
                  <DummyField label={t('Max Length')} value={value?.max} />
                </Cell>

                <Cell desktopColumns={9} tabletColumns={5} phoneColumns={4}>
                  <StyledSliderWrapper>
                    <Slider
                      step={1}
                      min={0}
                      max={50}
                      onChange={(newValue: number) =>
                        setFieldValue(fieldName, sampleCodeSchema.cast({ ...value, max: newValue }))
                      }
                      leftIcon="remove"
                      rightIcon="add"
                      value={value?.max ? value.max : undefined}
                    />
                  </StyledSliderWrapper>
                </Cell>
              </HalfRowCell>
            </Row>

            <ExplanationWrapper>
              {t(
                'The Sample Code length will be limited to the selected range of characters (minimum/maximum).'
              )}
            </ExplanationWrapper>
          </FullRowCell>
        </>
      )}
    </>
  );
};

export default SampleCode;
