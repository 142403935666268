import { useCallback } from 'react';

import { transformDateToString } from 'core/effects/apiCall/dateTimeTransformations';
import { useBoolClientOption } from 'core/hooks/useClientOption';
import { AnalysesFieldsData } from 'planning/components/AnalysesFields';
import analysesFieldsOutputMapping from 'planning/components/AnalysesFields/analysesFieldsOutputMapping';
import useOfficersOutputMapping from 'planning/components/OfficersFieldset/useOfficersOutputMapping';
import useSettingsOutputMapping from 'planning/components/SettingsTab/useSettingsOutputMapping';
import { TeamMemberData } from 'planning/components/TeamTab/useTeamInputMapping';
import useTeamOutputMapping from 'planning/components/TeamTab/useTeamOutputMapping';

import { MissionFormData } from './useMissionSchema';

const useMissionOutputMapping = () => {
  const advancedDcoAssignmentEnabled = useBoolClientOption('enableAdvancedDcoAssignment');
  const teamOutputMapping = useTeamOutputMapping();
  const officersOutputMapping = useOfficersOutputMapping();
  const settingsOutputMapping = useSettingsOutputMapping();

  return useCallback(
    (formData: MissionFormData) => {
      const { dateRange, ...data } = formData;

      const fd = data;

      // TODO: remove this fd overhead
      const req = {
        ...settingsOutputMapping(fd),
        assigneesId: fd.assigneesId,
        code: fd.generateCode ? null : fd.code,
        timeZonesId: fd.timeZonesId,
        sampleAuthoritiesId: fd.sampleAuthoritiesId,
        initiatorAuthoritiesId: fd.initiatorAuthoritiesId,
        externalId: fd.externalId,

        federationsName: formData.federationsId ? null : formData.federationsName,
        federationsId: formData.federationsId || null,
        debtorsId: formData.debtorsId || null,
        debtorsName: formData.debtorsId ? null : formData.debtorsName,
        customersId: formData.customersId || null,
        customersName: formData.customersId ? null : formData.customersName,
        generateCode: undefined,
        testSituationsId: fd.testSituationsId,
        poolsId: fd.poolsId,
        sportsId: fd.sportsId,
        sportDisciplinesId: fd.sportDisciplinesId,
        testAuthority: fd.testAuthority,
        resultAuthority: fd.resultAuthority,
        generalComments: fd.generalComments,
        internalComments: fd.internalComments,
        drawComments: fd.drawComments,
        invoiceComments: fd.invoiceComments,
        accommodationComments: fd.accommodationComments,
        ...analysesFieldsOutputMapping(formData as unknown as AnalysesFieldsData),
        bloodLabsId: fd.bloodLabsId,
        bloodComments: fd.bloodComments,
        bloodExpedited: fd.bloodExpedited,
        bloodLts: fd.bloodLts,
        urineLabsId: fd.urineLabsId,
        urineComments: fd.urineComments,
        urineExpedited: fd.urineExpedited,
        urineLts: fd.urineLts,
        race: fd.race,
        place: fd.place,
        dateFrom: dateRange ? transformDateToString(dateRange.from as Date, 'DATE') : null,
        dateTo: dateRange ? transformDateToString(dateRange.to as Date, 'DATE') : null,
        timeFrom: fd.timeFrom ? transformDateToString(fd.timeFrom, 'TIME') : null,
        timeTo: fd.timeTo ? transformDateToString(fd.timeTo, 'TIME') : null,
        dateFromBuffer: fd.dateFromBuffer !== null ? Number(fd.dateFromBuffer) : null,
        // TODO: Find out why this does not get cast to number as formik/use new prop parse when it lands in version we can update to
        dateToBuffer: fd.dateToBuffer !== null ? Number(fd.dateToBuffer) : null,
        plannedAt: fd.plannedAt ? transformDateToString(fd.plannedAt) : null,
        ...(advancedDcoAssignmentEnabled
          ? teamOutputMapping({
              // We are sure those are defined when assignment is enabled as input/schema has them
              invitedMembers: (formData.invitedMembers || []) as TeamMemberData[],
              assignedMembers: (formData.assignedMembers || []) as TeamMemberData[],
              notifyOfTeamUpdates: formData.notifyOfTeamUpdates!,
              showAthleteToDco: formData.showAthleteToDco!,
            })
          : officersOutputMapping({
              invitedMembers: (formData.invitedMembers || []) as TeamMemberData[],
              assignedMembers: (formData.assignedMembers || []) as TeamMemberData[],
              dcos: [],
              bcos: [],
              chaperones: formData.chaperones || [],
              leadDcosId: formData.leadDcosId || null,
            })),
        street: fd.street,
        address: fd.address,
        zip: fd.zip,
        city: fd.city,
        state: fd.state,
        countriesId: fd.countriesId,
        createLocation: !!fd.createLocation,
        locationName: fd.createLocation ? fd.locationName : undefined,
        locationsId: fd.createLocation ? null : fd.locationsId,
        costsId: fd.costsId,
        invoicingCodesId: fd.invoicingCodesId,
        eventName: formData.eventsId ? null : formData.eventName,
        eventsId: formData.eventsId || null,
      };

      return req;
    },
    [advancedDcoAssignmentEnabled, teamOutputMapping, settingsOutputMapping, officersOutputMapping]
  );
};

export type MissionRequestModel = ReturnType<ReturnType<typeof useMissionOutputMapping>>;

export default useMissionOutputMapping;
