import { FC } from 'react';
import { useTranslation } from 'react-i18next';

import { entities } from 'app/entity';
import Form from 'core/components/Form';
import Field from 'core/components/Form/Field';
import Modal from 'core/components/Modal';
import { TopSpacedWrapper } from 'core/components/Modal/styled';
import ResourceFormDropdown from 'core/components/ResourceFormDropdown';
import { getResourceUrl } from 'core/functions/getResourcesUrl';
import { useUserOptionsReducer } from 'finance/containers/PaymentPlanDetailPage/tabs/Users/AddUsersDialog/useUserOptionsMapping';

import useRedirectUser from './useRedirectUser';

export interface Props {
  onClose: () => void;
  open: boolean;
  missionsId?: number;
  allowedUserIds?: number[];
}

const InvoiceUserDialog: FC<Props> = ({ onClose, open, missionsId, allowedUserIds }) => {
  const { t } = useTranslation();
  const userOptionsReducer = useUserOptionsReducer(undefined, allowedUserIds);
  const redirectUser = useRedirectUser(open, onClose, missionsId);

  if (!redirectUser) {
    return null;
  }

  return (
    <Modal
      ariaLabel={t('Choose User for Invoice creation dialog')}
      title={t('Select User for Invoice')}
      open={open}
      onClose={onClose}
      confirmButton={null}
      cancelButton={null}
      clearButton={null}
    >
      <Form initialValues={{ usersId: null }} id="selectTest">
        <TopSpacedWrapper>
          <Field
            onChange={(usersId: number) => {
              onClose();
              redirectUser(usersId);
            }}
            component={ResourceFormDropdown}
            resource={getResourceUrl(entities.paymentPlan.api().usersList(undefined))}
            resourceReducer={userOptionsReducer}
            sortOptions={false}
            name="usersId"
            mode="inline"
            single
            fast={false}
          />
        </TopSpacedWrapper>
      </Form>
    </Modal>
  );
};

export default InvoiceUserDialog;
