import { FC } from 'react';

import FormTextfield, { Props as FormTextfieldProps } from 'core/components/FormTextfield';
import Phonefield from 'core/components/Phonefield';
import { Props as TextfieldProps } from 'core/components/Textfield';

interface Props extends FormTextfieldProps {
  enablePickCountry?: boolean;
}

const FormPhonefield: FC<Props> = (props) => {
  return <FormTextfield component={Phonefield as FC<TextfieldProps>} {...props} />;
};

export default FormPhonefield;
