const Glyph = () => (
  <svg x="0px" y="0px" width="265px" height="262.628px" viewBox="0 0 265 262.628">
    <g>
      <path
        fill="var(--logo-color)"
        d="M125.972,109.095l-62.986,36.364L0,109.095v-72.73L62.986,0l62.986,36.364V109.095z"
      />
      <path
        fill="var(--logo-color)"
        d="M263.819,109.095l-62.986,36.364l-62.985-36.364v-72.73L200.833,0l62.986,36.364V109.095z"
      />
      <path
        fill="var(--logo-color)"
        d="M194.896,226.265l-62.986,36.363l-62.986-36.363v-72.73l62.986-36.364l62.986,36.364 V226.265z"
      />
    </g>
  </svg>
);

export default Glyph;
