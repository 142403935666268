import { push } from 'connected-react-router';
import { put, race, take } from 'redux-saga/effects';

import { urls } from 'app/urls';

import {
  validateCodeSuccess,
  ValidateCodeAction,
  apiCall,
  TYPE_VALIDATE_CODE,
  validateCodeError,
} from '../actions';

function* validateCodeSaga(action: ValidateCodeAction) {
  // We don't use straight type since we want to pass the code we don't receive

  yield put(
    apiCall(
      TYPE_VALIDATE_CODE + '_REQUEST',
      action.payload.type === 'unlock-account' ? 'POST' : 'GET',
      `/auth/${action.payload.type}/${action.payload.code}`
    )
  );

  const { success } = yield race({
    success: take(`${TYPE_VALIDATE_CODE}_REQUEST_SUCCESS`),
    error: take(`${TYPE_VALIDATE_CODE}_REQUEST_ERROR`),
  });

  if (success) {
    yield put(validateCodeSuccess(action.payload.code));
  } else {
    yield put(validateCodeError());
    yield put(push(urls.login));
  }
}

export default validateCodeSaga;
