import { FC, useCallback, useState } from 'react';
import { useTranslation } from 'react-i18next';

import CalendarInput from 'core/components/CalendarInput';
import DropDown, { Option } from 'core/components/DropDown';
import { FlexCell, FlexRow } from 'core/components/FlexUtils';
import IconButton from 'core/components/IconButton';

interface Props {
  id?: string;
  customLabel?: string;
  relativeOptions: readonly Option[];
  value: [string, string] | string | null;
  onChange: (value: [string, string] | string | null) => void;
}

const RangeFilter: FC<Props> = ({ id, relativeOptions, customLabel, onChange, value }) => {
  const { t } = useTranslation();
  const [isRelative, setIsRelative] = useState(typeof value === 'string');
  const switchMode = useCallback(() => {
    onChange(null);
    setIsRelative((r) => !r);
  }, [onChange]);

  return (
    <FlexRow>
      <FlexCell block flex={1}>
        {isRelative ? (
          <DropDown
            id={`filter-${id || 'range'}`}
            label={customLabel || t('Relative Date Range')}
            options={relativeOptions as Option[]}
            value={value as string}
            onChange={(v) => onChange(v as string | null)}
            single
          />
        ) : (
          <CalendarInput
            id={`filter-${id || 'range'}`}
            label={customLabel || t('Date Range')}
            onChange={(val) => {
              onChange(
                val && Array.isArray(val)
                  ? (val.map((i) => i.toISOString().substring(0, 10)) as [string, string])
                  : null
              );
            }}
            value={Array.isArray(value) ? value.map((i) => new Date(i)) : null}
            range
          />
        )}
      </FlexCell>
      <FlexCell>
        <IconButton
          icon={isRelative ? 'date_range' : 'lock_clock'}
          onClick={switchMode}
          tooltip={isRelative ? t('Switch to Absolute') : t('Switch to Relative')}
        />
      </FlexCell>
    </FlexRow>
  );
};

export default RangeFilter;
