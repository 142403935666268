import { FC } from 'react';
import { useTranslation } from 'react-i18next';

import theme from 'app/theme';
import DummyField from 'core/components/DummyField';
import Field from 'core/components/Form/Field';
import FormTextfield from 'core/components/FormTextfield';
import { Row } from 'core/components/Grid';
import { FullRowCell } from 'core/components/GridCell';
import IconButton from 'core/components/IconButton';
import MaterialIcon from 'core/components/MaterialIcon';
import SecondaryPage from 'core/components/SecondaryPage';
import useMDCAutofillHackEffect from 'core/hooks/useMDCAutofillHackEffect';

import useFormFieldValue from '../Form/useFormFieldValue';

interface Props {
  switchBackLogin: () => void;
  inProgress: boolean;
  twoFactor: boolean;
  backTo: string;
}

const Fieldset: FC<Props> = ({ switchBackLogin, inProgress, twoFactor, backTo }) => {
  const { t } = useTranslation();
  const email = useFormFieldValue('email');
  useMDCAutofillHackEffect();

  const getLink = () => {
    const params = new URLSearchParams(window.location.search);
    params.delete('key');

    const encodedEmail = window.btoa(`email=${encodeURIComponent(email)}`);
    const paramWithoutKey = params.toString() ? `&${params.toString()}` : params.toString();

    const origin = new URL(backTo).host.includes('pwc-modoc.com')
      ? backTo
      : window.location.origin + window.location.pathname;

    return origin + '?key=' + encodedEmail + paramWithoutKey;
  };

  const loginFields = (
    <SecondaryPage.Grid>
      <Row>
        <FullRowCell>
          <DummyField
            label={t('E-mail')}
            type="email"
            value={email}
            startAdornment={<MaterialIcon icon="email" />}
            endAdornment={
              <IconButton icon="edit" color={theme.color.black} href={getLink()} isLink />
            }
          />
        </FullRowCell>
        <FullRowCell>
          <Field
            component={FormTextfield}
            label={t('Password')}
            name="password"
            type="password"
            id="password"
            fast={false}
            icon="vpn_key"
            autoComplete="current-password"
          />
        </FullRowCell>
      </Row>
    </SecondaryPage.Grid>
  );

  const twoFactorFields = (
    <SecondaryPage.Grid>
      <Row>
        <FullRowCell>
          <Field
            label={t('2-Factor Code')}
            component={FormTextfield}
            type="passcode"
            name="passcode"
            id="passcode"
          />
        </FullRowCell>
      </Row>
    </SecondaryPage.Grid>
  );

  return (
    <>
      {twoFactor ? twoFactorFields : loginFields}

      <SecondaryPage.Actions>
        <SecondaryPage.Button
          text={inProgress ? t('Logging in...') : t('Log in')}
          disabled={inProgress}
          id="loginButton"
          type="submit"
          raised
        />
        {twoFactor ? (
          <SecondaryPage.Link onClick={switchBackLogin}>
            <MaterialIcon icon="arrow_back" />
            {t('Back to credentials')}
          </SecondaryPage.Link>
        ) : (
          <SecondaryPage.Link to="/forgot-password">{t("Can't log in?")}</SecondaryPage.Link>
        )}
      </SecondaryPage.Actions>
    </>
  );
};

export default Fieldset;
