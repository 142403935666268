import { FC } from 'react';
import { useTranslation } from 'react-i18next';

import IconButton from 'core/components/IconButton';
import PopoverCellRenderer from 'core/containers/DataGrid/CellRenderers/PopoverCellRenderer';
import { getCustomUserAvatarUrl } from 'core/models/CoreProfile';
import { TeamMemberType } from 'planning/components/TeamTab/enums';

import { Avatar, AvatarLetters } from '../../../styled';
import { TeamMemberData } from '../../../useTeamInputMapping';
import useActions from '../../useActions';

import NotificationsPopover from './NotificationsPopover';
import { ColoredIconButton, NameCell, PersonalInfo } from './styled';

interface Props {
  type: TeamMemberType;
  user: TeamMemberData & { id: number };
  availabilities: { [usersId: number]: string };
  triggers: ReturnType<typeof useActions>['1'];
  viewMode: boolean;
  actionsDisabled: boolean;
}

enum SendingStatus {
  NONE = 0,
  SENT = 1,
  SENDING = 2,
}

const BasicInfoCell: FC<Props> = ({
  type,
  user,
  availabilities,
  triggers,
  viewMode,
  actionsDisabled,
}) => {
  const { t } = useTranslation();

  const profileUrl =
    user.avatarStorageKey && getCustomUserAvatarUrl(user.id, user.avatarStorageKey);

  const initials =
    user.firstName && user.lastName
      ? user.firstName.charAt(0) + user.lastName.charAt(0)
      : user.fullName?.substring(0, 2) || '-';

  const typeNotificationKey =
    type === TeamMemberType.INVITED ? 'invitations' : 'assignmentNotifications';

  const sendingStatus = user[typeNotificationKey].reduce((acc, i) => {
    return Math.max(acc, i.createdAt ? SendingStatus.SENT : SendingStatus.SENDING);
  }, SendingStatus.NONE);

  const info = availabilities[user.usersId] ? [availabilities[user.usersId]] : [];
  info.push(...user.tags);

  const doesHaveInvitationsOrNotifications =
    user.invitations.length > 0 || user.assignmentNotifications.length > 0;

  return (
    <NameCell flex={6} block minWidth="18rem">
      {profileUrl ? (
        <Avatar src={profileUrl} alt={user.fullName || t('Avatar')} />
      ) : (
        <AvatarLetters>{initials}</AvatarLetters>
      )}
      <PersonalInfo>
        <h3>{user.fullName}</h3>
        <span>{info.join(', ') || '-'}</span>
      </PersonalInfo>

      <PopoverCellRenderer
        interactive
        content={
          doesHaveInvitationsOrNotifications
            ? () => <NotificationsPopover type={type} user={user} />
            : undefined
        }
        params={undefined}
      >
        <ColoredIconButton
          icon="send"
          disabled={viewMode}
          tooltip={
            doesHaveInvitationsOrNotifications
              ? undefined
              : t('Plan {{notification}} via email or SMS', {
                  notification: type === TeamMemberType.INVITED ? 'invitation' : 'notification',
                })
          }
          onClick={() => triggers.onToggleInvitations(user)}
          color={
            sendingStatus === SendingStatus.SENDING
              ? 'warning'
              : sendingStatus === SendingStatus.SENT
                ? 'success'
                : 'gray300'
          }
        />
      </PopoverCellRenderer>

      <IconButton
        icon={user.internalComment ? 'speaker_notes' : 'chat_bubble_outline'}
        tooltip={user.internalComment ? user.internalComment : t('Add internal comment')}
        disabled={actionsDisabled}
        onClick={() => triggers.onEditComments(user)}
      />
    </NameCell>
  );
};

export default BasicInfoCell;
