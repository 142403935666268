import { getIn, useFormikContext } from 'formik';
import { FC, useCallback, useContext, useMemo } from 'react';
import { useTranslation } from 'react-i18next';

import DropDown, { SimpleOption } from 'core/components/DropDown';
import Modal from 'core/components/Modal';

import { FormContext } from '..';

export interface OperationType {
  id: string;
  name: string;
}
interface Props {
  operationOptions?: OperationType[];
}

const BulkEditCustomActionDialog: FC<Props> = ({ operationOptions }) => {
  const { t } = useTranslation();

  const { openedOperation, setOpenedOperation, saveOperation, customOperations } =
    useContext(FormContext);
  const { setFieldValue, initialValues } = useFormikContext();

  const currentValue = useMemo(() => {
    if (openedOperation) {
      const alreadyChoosedOp = customOperations.find(
        (it) => it.fieldName === openedOperation.fieldName
      );
      return alreadyChoosedOp && alreadyChoosedOp.operation
        ? alreadyChoosedOp.operation
        : 'replace';
    }
    return 'replace';
  }, [customOperations, openedOperation]);

  const customOperationOptions = useMemo(() => {
    if (!openedOperation) {
      return [];
    }

    if (operationOptions?.length && openedOperation.type === 'EXTENDED') {
      return operationOptions;
    }

    const options = [
      {
        id: 'replace',
        name: t('Replace'),
      },
      {
        id: 'remove',
        name: t('Remove'),
        description: t('All previous values will be removed.'),
      },
    ];
    if (openedOperation.type === 'EXTENDED') {
      options.unshift({
        id: 'add',
        name: t('Add (keep previous items)'),
        description: t(
          'Items will be added, previous items assigned to this field will stay unchanged.'
        ),
      });
    }
    return options;
  }, [t, openedOperation, operationOptions]);

  const handleOptionChosen = useCallback(
    (id: any, item?: SimpleOption | { [id: string]: SimpleOption }) => {
      if (id && openedOperation) {
        saveOperation({
          ...openedOperation,
          operation: id as 'add' | 'remove' | 'replace',
          description: (item && 'description' in item ? item.description : undefined) as
            | string
            | undefined,
        });

        if (id === 'remove') {
          setFieldValue(openedOperation.fieldName, getIn(initialValues, openedOperation.fieldName));
        }
      }
      setOpenedOperation(undefined);
    },
    [openedOperation, saveOperation, setOpenedOperation, initialValues, setFieldValue]
  );

  return (
    <>
      {openedOperation && (
        <Modal
          ariaLabel={t('Custom Operation Picker Dialog')}
          onClose={() => setOpenedOperation(undefined)}
          title={t('Choose Custom Operation')}
          open
          confirmButton={null}
        >
          <DropDown
            onChange={handleOptionChosen}
            options={customOperationOptions}
            value={currentValue}
            mode="inline"
            single
          />
        </Modal>
      )}
    </>
  );
};

export default BulkEditCustomActionDialog;
