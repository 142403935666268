import useAppSelector from 'core/hooks/useAppSelector';

import { DEFAULT_LANGUAGE, DEFAULT_LOCALE } from './languages';

export const useLocale = () => {
  const locale = useAppSelector(({ core }) => core.locale || navigator.language || DEFAULT_LOCALE);
  const language = useAppSelector(({ core }) => core.language || DEFAULT_LANGUAGE);

  const [languageFromLocale, region] = locale.split('-');

  if (languageFromLocale === language) {
    return locale;
  }

  return `${language}-${region}`;
};
