import { FC, ReactNode } from 'react';

import usePermission from 'core/hooks/usePermission';

type Props = {
  permission: string;
  no?: ReactNode;
  children?: ReactNode;
};

const Can: FC<Props> = ({ permission, children, no = null }) => {
  const can = usePermission(permission);

  return <>{can ? children : no}</>;
};

export default Can;
