import Base from 'core/models/Base';
import { definition, EntityMeta, getStandardUrls } from 'core/module';

import Setting from './models/Setting';

type T = (m: string) => string;

export const settingsEntities = {
  settings: {
    ...definition<Setting>('settings', {
      name: (t: T) => t('Setting'),
      listName: (t: T) => t('Settings'),
    }),
    urls: () => ({
      ...getStandardUrls('settings'),
      controlSettings: (tab?: 'defaults' | 'features') =>
        `/control-settings${tab ? `/${tab}` : ''}`,
      outbound: '/outbound-communication',
      adams: '/adams-settings',
      appContent: '/application-contents',
    }),
  },
  permissions: definition<Base>('permissions', {
    name: (t: T) => t('Permissions'),
    listName: (t: T) => t('Permissions'),
  }),
} as const;

// REQUIRED !!!
// eslint-disable-next-line @typescript-eslint/no-unused-vars
const verifyEntities: Record<keyof typeof settingsEntities, EntityMeta> = settingsEntities;

export type K = keyof typeof settingsEntities;
