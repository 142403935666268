import List from '@material/react-list';
import styled from 'styled-components/macro';

export const Wrapper = styled.div`
  display: flex;
  flex-direction: row;
  width: 100%;
  height: 16.5rem;
  padding: 1rem 1rem 0 1rem;
`;

export const Slider = styled(List)`
  height: 15rem;
  padding: 0;
  overflow-y: hidden;
  flex: 1;

  .mdc-list-item {
    justify-content: center;
  }

  &:hover {
    overflow: overlay;
  }
`;

export const Divider = styled.div`
  height: 16rem;
  line-height: 16rem;
  color: var(--mdc-theme-secondary);
`;
