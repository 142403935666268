import { toast } from 'react-toastify';
import { put, race, take } from 'redux-saga/effects';

import { apiCall, UploadImageAction, TYPE_UPLOAD_IMAGE } from 'core/actions';
import { t } from 'core/i18n';

function* uploadImageSaga(action: UploadImageAction) {
  const { url, imageFile, successCallback } = action.payload;
  const formData = new FormData();
  formData.append('imageFile', imageFile);

  yield put(
    apiCall(TYPE_UPLOAD_IMAGE, 'POST', url, formData, {
      headers: {
        'content-type': 'multipart/form-data',
      },
    })
  );

  const { success } = yield race({
    success: take(`${TYPE_UPLOAD_IMAGE}_SUCCESS`),
    error: take(`${TYPE_UPLOAD_IMAGE}_ERROR`),
  });

  if (success) {
    toast.success(t('Image successfully uploaded'));
    successCallback();
  }
}

export default uploadImageSaga;
