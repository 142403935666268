import { useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import * as Yup from 'yup';

/**
 * Usable on yup schema object field
 *
 * Example:
 * ```
 * {
 *  ...
 *  timeFrom: timeRangeSchema[0].required().meta(tabMeta('detail'))
 *  timeTo: timeRangeSchema[1].required().meta(tabMeta('detail'))
 *  ...
 * }
 * ```
 */
const useTimeRangeSchema = () => {
  const { t } = useTranslation();

  return useMemo(
    () => [
      Yup.date()
        .nullable()
        .default(null)
        .test('timeRange', t('You need to pick a valid time range'), function (value: Date | null) {
          const timeTo = this.parent.timeTo;
          if (!value || !timeTo) return true;

          return value <= timeTo;
        }),
      Yup.date()
        .nullable()
        .default(null)
        .test('timeRange', t('You need to pick a valid time range'), function (value: Date | null) {
          const timeFrom = this.parent.timeFrom;
          if (!value || !timeFrom) return true;

          return timeFrom <= value;
        }),
    ],
    [t]
  );
};

export default useTimeRangeSchema;
