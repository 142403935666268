import { ReactElement, useCallback, useEffect, useMemo, useRef } from 'react';

import useDropdownResource, {
  defaultDropdownReducer,
  Entity,
} from 'core/hooks/useDropdownResource';

import DropDown, { Option, Props as DropDownProps } from '../DropDown';
import DummyDropdownField from '../DummyDropdownField';
import { ValueStyle } from '../DummyField';

/**
 * Connected dropdown component reads resources from API
 * based on resource url passed into "resource" property
 * YOU MUST ADD FILTER VALUE here too
 */
interface Props<O extends Entity> extends Omit<DropDownProps, 'options'> {
  resourceReducer?: (list: O[], value?: any) => Option[];
  viewMode?: boolean;
  resource: string;
  autoload?: boolean;
  onConfirm?: () => void;
  /**
   * If true, fetch the resource data only after opening the dropdown menu
   */
  suppressFetchIfNotOpened?: boolean;
  /** Styling for old/new value */
  valueStyle?: ValueStyle;
  /* simple link as end adornment displayed as "chain" icon button */
  link?: ((value: any) => string) | string;
}

const ResourceDropdown: <O extends Entity = Entity>(props: Props<O>) => ReactElement<Props<O>> = <
  O extends Entity,
>({
  resourceReducer,
  resource,
  viewMode,
  value,
  mode,
  onOpen,
  autoload,
  suppressFetchIfNotOpened = false,
  ...props
}: Props<O>) => {
  const hasValue = props.single
    ? !!value && value !== 'any'
    : !!value && ((value as string[]) || []).filter((i) => i !== 'any').length > 0;

  const initialValue = useRef(value);

  const autoloadRef = useRef(
    !suppressFetchIfNotOpened && (autoload || props.initOpen || mode === 'inline' || hasValue)
  );

  const reqOptions = useMemo(
    () => ({
      reducer: (list: O[]) => list,
      selectedId: initialValue.current,
      autoload: autoloadRef.current,
    }),
    []
  );

  const [resourceOptions, reload, isLoading] = useDropdownResource<O, Option>(resource, reqOptions);

  const options = useMemo(() => {
    const reducer = resourceReducer || defaultDropdownReducer;
    const resourcesList = (resourceOptions || []) as unknown as O[];

    const list = resourcesList;

    return reducer(list, value);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [resourceOptions, resourceReducer]);

  const initFetch = useCallback(() => {
    reload(false);
    onOpen && onOpen();
  }, [reload, onOpen]);

  useEffect(() => {
    if (!isLoading && !resourceOptions && !suppressFetchIfNotOpened && hasValue) {
      reload(false);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isLoading, resourceOptions, hasValue, suppressFetchIfNotOpened]);

  const Component = viewMode ? DummyDropdownField : DropDown;

  return (
    <Component
      {...props}
      loading={isLoading}
      options={options || []}
      onOpen={initFetch}
      label={props.label || ''}
      value={value}
      mode={mode}
    />
  );
};

export default ResourceDropdown;
