import { put, take, race, select } from 'redux-saga/effects';

import { State as GlobalState } from 'app/store/prepareStore';
import { TYPE_EDIT_ENTITY, editEntity, loadProfile } from 'core/actions';
import { EditClientAction } from 'personnel/actions';

export function* editClientSaga(action: EditClientAction) {
  const { id, data, prevEntity, endpoint } = action.payload;

  yield put(editEntity({ id, data, endpoint }, prevEntity));

  const { success } = yield race({
    success: take(`${TYPE_EDIT_ENTITY}_SUCCESS`),
    error: take(`${TYPE_EDIT_ENTITY}_ERROR`),
  });

  if (success) {
    // if it's current client call re-fetch client options, fetching full profile in case of magic with client options in reducer
    const currentClientsId: number | undefined = yield select(
      (state: GlobalState) => state.core.user?.client.id
    );

    // If we have no id = we are modifying our client profile
    if (id === undefined || currentClientsId === id) {
      yield put(loadProfile());
    }
  }
}
