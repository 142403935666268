import { useCallback, useMemo } from 'react';
import { useTranslation } from 'react-i18next';

import { entities } from 'app/entity';
import { ClientOptions } from 'app/models/Profile';
import { useFormViewMode } from 'core/components/Form';
import { useDetail } from 'core/containers/FormDetailPage/DetailContext';
import useAppSelector from 'core/hooks/useAppSelector';
import { useClientOptions } from 'core/hooks/useClientOption';
import Test, { TestStatus } from 'planning/models/Test';

type SwitchSetting = {
  name: string;
  title: string;
  description: string;
  disabled?: boolean;
};

type SwitchSettingsDependenciesElement = {
  setting: string;
  clientOption: Readonly<string[]>;
};

type SwitchSettingsDependencies = {
  control: Readonly<SwitchSettingsDependenciesElement[]>;
  dcf: Readonly<SwitchSettingsDependenciesElement[]>;
};

const switchSettingsDependencies = {
  control: [
    { setting: 'isPersonallyKnownAllowed', clientOption: [] },
    { setting: 'requireNotificationSignature', clientOption: [] },
    { setting: 'allowDelayDeadline', clientOption: [] },
    { setting: 'prefillSportDiscipline', clientOption: [] },
    { setting: 'simplifiedSigning', clientOption: [] },
    { setting: 'additionalAssignments', clientOption: [] },
    { setting: 'notifyOfTeamUpdates', clientOption: ['enableAdvancedDcoAssignment'] },
    { setting: 'showAthleteToDco', clientOption: ['enableAdvancedDcoAssignment'] },
    { setting: 'showInternalCommentToDco', clientOption: [] },
    { setting: 'use3genApp', clientOption: ['enable3genApp'] },
    { setting: 'allowIcPoolCollection', clientOption: [] },
  ] as const,
  dcf: [
    { setting: 'useAuthorityLogo', clientOption: [] },
    { setting: 'useAuthorityEmailTemplate', clientOption: [] },
    { setting: 'useAuthorityDcfTemplate', clientOption: [] },
  ] as const,
} satisfies SwitchSettingsDependencies;

export const switchSettings = {
  control: switchSettingsDependencies.control.map(({ setting }) => setting),
  dcf: switchSettingsDependencies.dcf.map(({ setting }) => setting),
};

export type SwitchSettingName =
  | (typeof switchSettingsDependencies.control)[number]['setting']
  | (typeof switchSettingsDependencies.dcf)[number]['setting'];

const useSwitchSettings = (options?: { exclude?: Set<SwitchSettingName> }) => {
  const { t } = useTranslation();
  const clientOptions = useClientOptions();

  const entityDataSource = useAppSelector((s) => s.core.entityDataSource);
  const { entityData, mode } = useDetail();
  const viewMode = !!useFormViewMode();

  const disabled3genAppSwitch = useMemo(() => {
    const isTest = entityDataSource?.includes(entities.test.api().list) || false;

    if (isTest) {
      const notUnassigned = (entityData as Test).status !== TestStatus.UNASSIGNED;
      const clone = mode === 'clone';

      // Returns viewMode state
      return notUnassigned && !clone;
    }

    return viewMode;
  }, [entityData, entityDataSource, mode, viewMode]);

  const getFromClientOption = (
    clientOptions: undefined | ClientOptions,
    clientOptionName: SwitchSettingName,
    type: 'number' | 'string' = 'number'
  ): null | string | number => {
    const value = clientOptions?.[clientOptionName]?.value;
    if (!value) return null;
    return type === 'number' ? Number(value) : value;
  };

  const getItemByName = useCallback(
    (name: SwitchSettingName): SwitchSetting => {
      switch (name) {
        case 'isPersonallyKnownAllowed':
          return {
            name,
            title: t('Personally Known Allowed'),
            description: t(
              'When identifying an athlete, it is possible to choose that the DCO knows the athlete personally. In this case, no identification document is required.'
            ),
          };

        case 'requireNotificationSignature':
          return {
            name,
            title: t('Notification Signature'),
            description: t(
              `The athlete's signature will be required in the "Notification" step even if the notification was done via paper.`
            ),
          };

        case 'allowDelayDeadline':
          return {
            name,
            title: t('Allow Delay Deadline'),
            description: t(
              'If enabled, the athlete may request a delay in the "Notification" step.'
            ),
          };

        case 'prefillSportDiscipline':
          return {
            name,
            title: t('Prefill Sport Discipline'),
            description: t('If enabled, entered sport discipline will be pre-filled in the app.'),
          };

        case 'simplifiedSigning':
          return {
            name,
            title: t('Simplified Signing'),
            description: t(
              'If enabled, only notification and confirmation signatures may be required.'
            ),
          };

        case 'additionalAssignments':
          return {
            name,
            title: t('Additional Assignments'),
            description: t('If enabled, Lead DCO can assign additional DCOs during the control.'),
          };
        case 'useAuthorityLogo':
          return {
            name,
            title: t('Testing Authority Logo'),
            description: t(
              'If the selected testing authority has a logo, it will be used on the DCF document. If "No" is selected, the client logo will be used.'
            ),
          };

        case 'useAuthorityEmailTemplate':
          return {
            name,
            title: t('Testing Authority Email Template'),
            description: t(
              'If the selected testing authority has an email template, it will be used to send the DCF document. If "No" is selected, the client email template will be used.'
            ),
          };

        case 'useAuthorityDcfTemplate':
          return {
            name,
            title: t('Testing Authority DCF Template'),
            description: t(
              'If the selected testing authority has a DCF template, it will be used to generate the DCF document. If "No" is selected, the default DCF template will be used.'
            ),
          };

        case 'notifyOfTeamUpdates':
          return {
            name,
            title: t('Notify me of status updates'),
            description: t(
              'The assignee will be notified by e-mail when invited team members react to the invitation.'
            ),
          };

        case 'showAthleteToDco':
          return {
            name,
            title: t('Show athlete and gender to invited DCO'),
            description: t(
              'The invited DCO will be able to see the athlete and gender in the Backoffice.'
            ),
          };

        case 'showInternalCommentToDco':
          return {
            name,
            title: t('Show athlete internal comment to DCO'),
            description: t(
              'The DCO will be able to see internal comment from the athlete detail in the Backoffice.'
            ),
          };

        case 'use3genApp':
          return {
            name,
            title: t('Use 3rd Gen App'),
            description: t('If enabled, the entity will be shown in the 3rd gen app.'),
            disabled: disabled3genAppSwitch,
          };

        case 'allowIcPoolCollection':
          return {
            name,
            title: t('Allow IC Pool Collection'),
            description: t(
              'If enabled, the Pool field will be available for In Competition (IC) Tests.'
            ),
          };
      }
    },
    [disabled3genAppSwitch, t]
  );

  return useMemo(() => {
    const getItemFilter = (it: SwitchSettingsDependenciesElement) => {
      if (options?.exclude && options.exclude.has(it.setting as SwitchSettingName)) return false;

      const { clientOption } = it;
      const dependenciesValues = clientOption.map((it) =>
        getFromClientOption(clientOptions, it as SwitchSettingName)
      );
      const isEnabled = dependenciesValues.every((it) => it === 1);

      return isEnabled;
    };

    const getItemMap = (it: SwitchSettingsDependenciesElement) => {
      const { setting } = it;
      const item = getItemByName(setting as SwitchSettingName);
      return item;
    };

    return {
      control: switchSettingsDependencies.control.filter(getItemFilter).map(getItemMap),
      dcf: switchSettingsDependencies.dcf.filter(getItemFilter).map(getItemMap),
    };
  }, [options?.exclude, clientOptions, getItemByName]);
};

export default useSwitchSettings;
