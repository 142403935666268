import { DateTime } from 'luxon';
import { FC, useEffect, useRef, useState } from 'react';
import DayPicker, { AfterModifier, BeforeAfterModifier, BeforeModifier } from 'react-day-picker';

import 'react-day-picker/lib/style.css';
import CalendarHeader from '../CalendarHeader';
import { PickerWrapper } from '../styled';

export interface Props {
  onChange: (date: Date[]) => void;
  value?: Date[];
  fromDay?: Date;
  toDay?: Date;
}

const DateRangePicker: FC<Props> = ({ onChange, value, fromDay, toDay }) => {
  // Don't display selected value while it was not picked - changing just year and month should not display default date
  const selectedVal = useRef(!!value);
  const [rangeFrom, setRangeFrom] = useState<Date | undefined>();
  const [rangeTo, setRangeTo] = useState<Date | undefined>();
  const [rangeClick, setRangeClick] = useState(1);
  const [monthYear, setMonthYear] = useState((value && value[0]) || new Date());
  const valueMonthYear =
    value && value[0] ? value[0].getMonth().toString() + value[0].getFullYear() : undefined;
  useEffect(() => {
    setMonthYear((value && value[0]) || new Date());
    // We use indirect dependency
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [valueMonthYear]);

  useEffect(() => {
    setRangeClick(1);
    setRangeFrom(value && value[0] ? value[0] : undefined);
    setRangeTo(value && value[1] ? value[1] : undefined);

    selectedVal.current = !!value && value.filter((i) => i).length === 2;
  }, [value]);

  const handleDayClick = (d: Date) => {
    const picked = DateTime.fromJSDate(d);

    if (
      (fromDay && picked.startOf('day') < DateTime.fromJSDate(fromDay).startOf('day')) ||
      (toDay && picked.startOf('day') > DateTime.fromJSDate(toDay).startOf('day'))
    ) {
      return;
    }
    selectedVal.current = true;

    if (rangeClick === 1) {
      setRangeFrom(d);
      setRangeTo(undefined);
    } else if (rangeClick === 2) {
      if (picked.startOf('day') < DateTime.fromJSDate(rangeFrom as Date).startOf('day')) {
        setRangeFrom(d);
        setRangeTo(rangeFrom);
        onChange([d, rangeFrom!]);
      } else {
        setRangeTo(d);
        onChange([rangeFrom!, d]);
      }
    }

    setRangeClick(rangeClick === 2 ? 1 : 2);
  };

  let disabledDays: BeforeAfterModifier | BeforeModifier | AfterModifier | undefined;
  if (toDay && fromDay) {
    disabledDays = { after: toDay, before: fromDay };
  } else if (toDay) {
    disabledDays = { after: toDay };
  } else if (fromDay) {
    disabledDays = { before: fromDay };
  }

  return (
    <PickerWrapper>
      <DayPicker
        selectedDays={
          selectedVal.current ? [rangeFrom, { from: rangeFrom, to: rangeTo }] : undefined
        }
        modifiers={{ start: rangeFrom, end: rangeTo }}
        className="DayPicker--Range"
        disabledDays={disabledDays}
        onDayClick={handleDayClick}
        navbarElement={() => null}
        fromMonth={fromDay}
        numberOfMonths={2}
        fixedWeeks={true}
        toMonth={toDay}
        month={monthYear}
        captionElement={({ date }: { date: Date }) => (
          <CalendarHeader
            onChange={setMonthYear}
            selection={(selectedVal.current && value) || undefined}
            date={date || new Date()}
            fromMonth={fromDay}
            toMonth={toDay}
            range
          />
        )}
      />
    </PickerWrapper>
  );
};

export default DateRangePicker;
