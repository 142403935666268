import {
  FC,
  ReactNode,
  createContext,
  memo,
  useCallback,
  useContext,
  useMemo,
  useState,
} from 'react';

import MaterialIcon from 'core/components/MaterialIcon';

import { AlertType, StyledAlert } from './styled';

export interface AlertMessage {
  value: string;
  type?: AlertType;
}

interface ContextProps {
  /**
   * Latest message
   */
  message: AlertMessage;

  /**
   * Add or update a message
   */
  setMessage: (id: string | number, message: AlertMessage) => void;

  clearMessage: (id: string | number) => void;
}

export const AlertContext = createContext<ContextProps>({
  message: {
    value: '',
  },
  setMessage: () => {},
  clearMessage: () => {},
});

/**
 * Context Provider, meant to be used with one or multiple `Alert` components.
 * Provider holds messages that can be in the future retrieved by specified ids.
 * A possible use case to store multiple messages with different identifiers with only one `Alert`
 * is showing the previous 'active' message when the latest message gets cleared (removed).
 * Achieving this with only one message can become complex, cause race condition problems, and so on.
 */
export const AlertProvider: FC<{ children: ReactNode; defaultType?: AlertType }> = ({
  children,
  defaultType,
}) => {
  const [messages, setMessages] = useState<{ id: string | number; message: AlertMessage }[]>([]);

  const message: AlertMessage = useMemo(() => {
    const m = messages.length === 0 ? { value: '' } : messages[messages.length - 1]!.message;
    m.type ||= defaultType;
    return m;
  }, [defaultType, messages]);

  const setMessage = useCallback((id: string | number, message: AlertMessage) => {
    setMessages((prevMessages) => {
      const nextMessages = [...prevMessages];
      const msg = nextMessages.find((m) => m.id === id);
      if (msg) msg.message = message;
      else {
        nextMessages.push({ id, message });
      }
      return nextMessages;
    });
  }, []);

  const clearMessage = useCallback((id: string | number) => {
    setMessages((prevMessages) => prevMessages.filter((m) => m.id !== id));
  }, []);

  return (
    <AlertContext.Provider value={{ message, setMessage, clearMessage }}>
      {children}
    </AlertContext.Provider>
  );
};

export const useAlert = () => {
  return useContext(AlertContext);
};

const iconMapping = {
  success: 'check_circle',
  info: 'info',
  warning: 'warning',
  error: 'error',
};

const Alert: FC = () => {
  const { message } = useAlert();

  const type = useMemo(() => message.type || 'info', [message.type]);

  if (!message.value) return <></>;

  return (
    <StyledAlert type={type}>
      <div>
        <MaterialIcon icon={iconMapping[type]} size={1.75} />
      </div>
      <div>
        <span className="alert-text">{message.value}</span>
      </div>
    </StyledAlert>
  );
};

export default memo(Alert);
