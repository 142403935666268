import { FC } from 'react';
import ContentLoader from 'react-content-loader';
import { useTranslation } from 'react-i18next';

import theme from 'app/theme';

import { Container } from './styled';

const LoadingCellRenderer: FC<{}> = () => {
  const { t } = useTranslation();

  return (
    <Container>
      <ContentLoader
        foregroundColor={theme.color.gray200}
        backgroundColor={theme.color.gray100}
        title={t('Loading...')}
        arial-label={t('Loading indicator')}
        width={160}
        height={16}
      >
        <rect x="0" y="0" rx="5" ry="5" width={160} height={16} />
      </ContentLoader>
    </Container>
  );
};

export default LoadingCellRenderer;
