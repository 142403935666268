import { FieldProps, getIn } from 'formik';
import { FC } from 'react';
import { useTranslation } from 'react-i18next';

import IconButton from 'core/components/IconButton';

import { AnalysesFieldsData } from '../useAnalysesFieldsSchema';

const RemoveButton: FC<FieldProps<AnalysesFieldsData['otherBloodAnalyses'][0]>> = ({
  field,
  form,
}) => {
  const { t } = useTranslation();
  const filledOther = (getIn(form.values, 'otherBloodAnalyses') ||
    []) as AnalysesFieldsData['otherBloodAnalyses'];
  const id = field.value?.id;

  return (
    <IconButton
      type="button"
      icon="close"
      tooltip={t('Remove')}
      onClick={() => {
        form.setFieldValue(
          'otherBloodAnalyses',
          (filledOther || []).filter((analysis) => analysis.id !== id)
        );
      }}
    />
  );
};

export default RemoveButton;
