import { FormikHelpers } from 'formik';
import { FC, memo, useCallback, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch } from 'react-redux';

import IconLoader from 'app/components/IconLoader';
import { deleteEntity } from 'core/actions';
import Form from 'core/components/Form';
import Modal from 'core/components/Modal';
import useAppSelector from 'core/hooks/useAppSelector';
import useResource, { useResources } from 'core/hooks/useResource';
import { saveBlacklistRecord } from 'personnel/actions';
import { ATHLETES_RESOURCE_LIMIT } from 'personnel/models/Athlete';
import BlacklistAthletes from 'personnel/models/BlacklistAthletes';
import BlacklistSportDisciplines from 'personnel/models/BlacklistSportDisciplines';

import { useBlacklistsTypeEndpoint } from '../useBlacklistsEndpoints';

import Fieldset from './fieldset';
import { StyledWrapper } from './styled';
import useBlacklistsTypeTitle from './useBlacklistsTypeTitle';
import useOutputMapping from './useOutputMapping';
import useSchema, {
  AthletesBlacklistsFormData,
  SportDisciplinesBlacklistsFormData,
} from './useSchema';

interface Props {
  type: BLACKLISTS_TYPE;
  onClose: () => void;
  usersId: number;
  id?: number;
}

export enum BLACKLISTS_TYPE {
  ATHLETES,
  SPORT_DISCIPLINES,
}

const BlacklistsModal: FC<Props> = memo(({ onClose, type, usersId, id }) => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const [confirmDeleteOpen, setConfirmDelete] = useState(false);

  const typeTitle = useBlacklistsTypeTitle(type);
  const typeEndpoint = useBlacklistsTypeEndpoint(type);

  const { data: blacklistsRecord, reload: refetchBlacklistRecord } = useResource<
    BlacklistAthletes | BlacklistSportDisciplines
  >(id && type !== undefined ? `${typeEndpoint}/${id}` : undefined, { autoload: false });

  const { reload: refetchBlacklistsTable } = useResources(`${typeEndpoint}/user/${usersId}`, {
    autoload: false,
    params: { limit: ATHLETES_RESOURCE_LIMIT },
  });

  const saveInProgress = useAppSelector(({ personnel }) => Boolean(personnel.dialogSaveInProgress));
  const handleCloseIfCan = useCallback(() => {
    if (!saveInProgress) {
      onClose();
    }
  }, [saveInProgress, onClose]);

  const schema = useSchema(type);
  const outputMapping = useOutputMapping();
  const initialValues = schema.cast({
    usersId,
    ...blacklistsRecord,
  });

  useEffect(() => {
    if (!blacklistsRecord) refetchBlacklistRecord();
  }, [blacklistsRecord, refetchBlacklistRecord]);

  const dispatchDelete = useCallback(() => {
    dispatch(
      deleteEntity(id!, `${typeEndpoint}/${id}`, () => {
        onClose();
        refetchBlacklistsTable();
      })
    );
  }, [dispatch, id, typeEndpoint, onClose, refetchBlacklistsTable]);

  const handleSubmit = useCallback(
    (
      values: AthletesBlacklistsFormData | SportDisciplinesBlacklistsFormData,
      formik: FormikHelpers<AthletesBlacklistsFormData | SportDisciplinesBlacklistsFormData>
    ) => {
      dispatch(
        saveBlacklistRecord(
          {
            endpoint: typeEndpoint,
            data: outputMapping(values, type),
            id,
          },
          id ? { ...outputMapping(schema.cast({ ...blacklistsRecord }), type) } : undefined,
          () => {
            refetchBlacklistsTable();
            refetchBlacklistRecord();
            formik.resetForm();
            onClose();
          }
        )
      );
    },
    [
      blacklistsRecord,
      dispatch,
      id,
      onClose,
      outputMapping,
      refetchBlacklistsTable,
      refetchBlacklistRecord,
      schema,
      type,
      typeEndpoint,
    ]
  );

  return (
    <>
      <Modal
        title={id ? t('Blacklist item details') : t('Add to the blacklist')}
        ariaLabel={`${typeTitle} ${t('Blacklists Dialog')}`}
        onClose={handleCloseIfCan}
        open={type !== undefined}
        confirmButton={null}
        cancelButton={null}
      >
        <Form
          initialValues={initialValues}
          validationSchema={schema}
          id="blacklistsDialogForm"
          onSubmit={handleSubmit}
        >
          {(id && !blacklistsRecord) || type === undefined || !usersId ? (
            <IconLoader />
          ) : (
            <StyledWrapper>
              <Fieldset
                saveInProgress={saveInProgress}
                usersId={usersId}
                isEdit={!!id}
                onCancel={handleCloseIfCan}
                type={type}
                onDeleted={() => setConfirmDelete(true)}
                creatorsName={blacklistsRecord?.creatorsFullName}
              />
            </StyledWrapper>
          )}
        </Form>
      </Modal>

      <Modal
        ariaLabel={t('Deletion confirmation dialog')}
        title={t('Delete Blacklist Record')}
        open={confirmDeleteOpen}
        onClose={() => setConfirmDelete(false)}
        onConfirm={() => dispatchDelete()}
        onCancel={() => setConfirmDelete(false)}
      >
        {t('Are you sure you want to permanently delete this Blacklist Record?')}
      </Modal>
    </>
  );
});

export default BlacklistsModal;
