import { ErrorMessage, FieldProps, getIn } from 'formik';
import { FC, useCallback } from 'react';

import CalendarInput from 'core/components/CalendarInput';
import { Props as CalendarProps } from 'core/components/CalendarInput';
import { FeedbackMessage } from 'core/components/FeedbackMessage';

import { FieldGroup } from './styled';

interface OwnProps {
  label: string;
  icon?: string;
  error?: boolean;
  minWidth?: number | string;
}

export type Props = FieldProps & OwnProps & CalendarProps;

const FormCalendarInput: FC<Props> = ({
  form: { touched, errors, setFieldValue },
  field: { name, value, onBlur },
  onChange,
  label,
  error,
  id,
  ...props
}) => {
  const onChangeCallback = useCallback(
    (v: Date | Date[] | null) => {
      if (onChange) {
        onChange(v);
      } else {
        setFieldValue(name, v, true);
      }
    },
    [setFieldValue, name, onChange]
  );

  const hasError = error !== undefined ? error : !!getIn(touched, name) && !!getIn(errors, name);

  return (
    <FieldGroup hasError={hasError}>
      <CalendarInput
        onChange={onChangeCallback}
        id={id || `form-${name}`}
        error={hasError}
        label={label}
        onBlur={onBlur}
        {...props}
        value={props.value || value}
      />
      {hasError && <ErrorMessage component={FeedbackMessage} name={name} />}
    </FieldGroup>
  );
};

export default FormCalendarInput;
