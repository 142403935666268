import styled from 'styled-components/macro';

export const VerticallySpaced = styled.div`
  display: flex;
  flex-direction: column;

  > :not(:last-child) {
    margin-bottom: 1rem;
  }
`;
