import { FC } from 'react';
import { useTranslation } from 'react-i18next';

import Button from 'core/components/Button';

import { MaxScreenWidthModal, MinWidthTable } from './styled';

interface Props {
  open: boolean;
  onClose: () => void;
}

const HelpModal: FC<Props> = ({ open, onClose }) => {
  const { t } = useTranslation();

  const advancedSyntax = [
    {
      syntax: t('+ signifies AND'),
      example: 'John + M-123456',
      explanation: t('Records containing words John and M-123456.'),
    },
    {
      syntax: t('| signifies OR'),
      example: 'John | M-123456',
      explanation: t('Records containing either word John or M-123456.'),
    },
    {
      syntax: t('" wraps words to signify a phrase'),
      example: '"John Doe"',
      explanation: t('Records containing the phrase "John Doe".'),
    },
    {
      syntax: t('* signifies a prefix or postfix search'),
      example: '2020_* | *02*',
      explanation: t(
        'Records starting with 2020_ or with 02 in the middle. Cannot be combined with other syntax.'
      ),
    },
    {
      syntax: t('( and ) signify precedence'),
      example: 'M-123456 + (John | Joe)',
      explanation: t('Records containing word M-123456 and either John or Joe.'),
    },
    {
      syntax: t('- signifies negation'),
      example: 'John + -Joe',
      explanation: t('Records containing word John and not word Joe.'),
    },
    {
      syntax: t('~N after a word is the number of possible errors'),
      example: 'John~1',
      explanation: t('Records containing word John with up to one wrong character (Johm, Tohn).'),
    },
    {
      syntax: t('~N after a phrase is the number of optional words'),
      example: '"John Doe Third" ~1',
      explanation: t(
        'Records containing words John Doe Third with up to one of them missing (John Doe Second).'
      ),
    },
  ];

  return (
    <MaxScreenWidthModal
      ariaLabel={t('Search Help Dialog')}
      onClose={onClose}
      open={open}
      title={t('Using Search')}
      cancelButton={null}
      confirmButton={<Button text={t('Close')} onClick={onClose} />}
      autoResponsiveWidth={false}
    >
      <p>{t('For basic loose search, simply put in words you would like to search for.')}</p>

      <p>{t('Search also offers advanced functionality:')}</p>

      <MinWidthTable>
        <thead>
          <tr>
            <th>{t('Syntax')}</th>
            <th>{t('Example')}</th>
            <th>{t('Explanation')}</th>
          </tr>
        </thead>
        <tbody>
          {advancedSyntax.map((row, index) => (
            <tr key={index}>
              <td>
                <strong>{row.syntax}</strong>
              </td>
              <td>
                <code>{row.example}</code>
              </td>
              <td>{row.explanation}</td>
            </tr>
          ))}
        </tbody>
      </MinWidthTable>

      <br />

      <p>
        {t('If none of the advanced functionality is used, default query is {{query}}.', {
          query: '(*each* | *word*)',
        })}
      </p>
    </MaxScreenWidthModal>
  );
};

export default HelpModal;
