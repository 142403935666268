import { FC, useContext } from 'react';

import { FlexRow, FlexCell } from 'core/components/FlexUtils';

import { FormContext } from '..';

import { StyledIconButton } from './styled';

type Props = {
  children: any;
  name: string;
  isMultipleDropdown: boolean;
};

const DiffModeIcon: FC<Props> = ({ children, name, isMultipleDropdown }) => {
  const { setOpenedOperation } = useContext(FormContext);

  return (
    <>
      <FlexRow>
        <FlexCell flex={1} block>
          {children}
        </FlexCell>
        <FlexCell>
          <StyledIconButton
            onClick={() =>
              setOpenedOperation({
                fieldName: name,
                operation: 'replace',
                type: isMultipleDropdown ? 'EXTENDED' : 'COMMON',
              })
            }
            icon="edit"
          />
        </FlexCell>
      </FlexRow>
    </>
  );
};

export default DiffModeIcon;
