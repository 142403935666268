import { push } from 'connected-react-router';
import { useCallback } from 'react';
import { useDispatch } from 'react-redux';

import { NoContextEntityKey, entities } from 'app/entity';
import { useGetPermission } from 'core/hooks/usePermission';
import BaseModel from 'core/models/Base';

interface Props<T> {
  entity?: NoContextEntityKey;
  getDetailUrl?: (data?: T) => string | undefined;
  onRowClicked?: (data?: T) => void;
}

const useRedirectActions = <T extends BaseModel>(props: Props<T>) => {
  const dispatch = useDispatch();
  const getPermission = useGetPermission();

  const getDetailUrl: (data: T) => string | undefined = useCallback(
    (data: T) => {
      if (props.getDetailUrl) return props.getDetailUrl(data);
      else if (props.entity && getPermission(`${entities[props.entity].permission}:get`)) {
        return entities[props.entity].urls().detail(data.id);
      }
      return undefined;
    },
    [getPermission, props]
  );

  const onRowClicked = useCallback(
    (data: T) => {
      if (props.onRowClicked) props.onRowClicked(data);
      else if (getDetailUrl) {
        const detailUrl = getDetailUrl(data);
        detailUrl && dispatch(push(detailUrl));
      }
    },
    [dispatch, getDetailUrl, props]
  );

  return { getDetailUrl, onRowClicked };
};

export default useRedirectActions;
