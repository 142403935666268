import className from 'classnames';
import {
  AriaRole,
  ForwardRefRenderFunction,
  ReactElement,
  ReactNode,
  SyntheticEvent,
  forwardRef,
  memo,
  useEffect,
  useMemo,
  useRef,
  useState,
} from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch } from 'react-redux';

import theme from 'app/theme';
import { setOpenDropdownId } from 'core/actions';
import useAppSelector from 'core/hooks/useAppSelector';

import Button from '../Button';
import Divider from '../Divider';
import { FlexColumn, FlexRow } from '../FlexUtils';
import Spacer from '../Spacer';

import Content, { Props as ContentProps } from './Content';
import { StyledModal, StyledSelectedText } from './styled';

export interface SimpleOption {
  secondary?: ReactNode;
  graphic?: ReactElement;
  id: string | number;
  disabled?: boolean;
  name: string;
  meta?: ReactNode;
  extra?: any;
  icons?: ReactNode;
  startIcons?: ReactNode;
}

export interface GroupOption {
  options: SimpleOption[];
  name: string;
  secondary?: string;
  id: string;
  icons?: ReactNode;
  startIcons?: ReactNode;
}

export type Option = SimpleOption | GroupOption | 'SEPARATOR';

export type RenderTargetType = {
  onClick: () => void;
  role: AriaRole;
};

export interface Props extends ContentProps {
  /** handle on-focus event */
  onFocus?: (e: SyntheticEvent) => void;
  /** render mode */
  mode?: 'inline' | 'modal';
  /** Watch open modal event */
  onOpen?: () => void;
  /** Disabled Input state */
  disabled?: boolean;
  /** Require value = disable clear */
  required?: boolean;
  /** Input error */
  error?: boolean;
  /** Decrease input spacing and padding */
  dense?: boolean;
  /** content height */
  height?: number;
  /** Initial open state (only for "modal" mode) */
  initOpen?: boolean;
  /** Input label */
  label?: string;
  /** Input element name */
  name?: string;
  /** Input element dom ID */
  id?: string;
  /** Indicate loading options  */
  loading?: boolean;
  /** Input min width override */
  minWidth?: number | string;
  /** Callback triggered on the 'SET' button click  */
  onConfirm?: () => void;
  /** Display secondary value in input */
  displaySelectedSecondary?: boolean;
  /** Content on the left side of dropdown */
  rightContent?: ReactNode;
  /** Custom height and width of modal */
  modalSize?: {
    height: string;
    width: string;
  };
  /** Override the automatic close in the single dropdown */
  noAutomaticCloseSingleMode?: boolean;
  /** Override the target in the dropdown */
  renderTarget?: (props: RenderTargetType) => ReactElement;
}

const DropDown: ForwardRefRenderFunction<HTMLDivElement, Props> = (
  {
    required = false,
    initOpen = false,
    mode = 'modal',
    dense = false,
    minWidth,
    disabled,
    onOpen,
    height,
    error,
    label,
    id,
    onConfirm,
    displaySelectedSecondary = true,
    rightContent,
    modalSize,
    noAutomaticCloseSingleMode = false,
    renderTarget,
    ...props
  },
  forwardedRef
) => {
  const [modalOpen, setModalOpen] = useState(initOpen);
  const [hasFocus, setHasFocus] = useState(false);
  const ref = useRef<HTMLDivElement>(null);
  const onOpenRef = useRef(onOpen);
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const openDropdownId = useAppSelector(({ core }) => core.openDropdownId);

  useEffect(() => {
    setModalOpen(id === openDropdownId);
  }, [id, openDropdownId]);

  // enable non cached callbacks
  useEffect(() => {
    onOpenRef.current = onOpen;
    return () => {
      onOpenRef.current = undefined;
    };
  }, [onOpen]);

  useEffect(() => {
    const onOpen = onOpenRef.current; // ref bcs of prevent triggering open on ref change

    onOpen && modalOpen && onOpen();
  }, [modalOpen]);

  if (
    props.single &&
    !(typeof props.value === 'string' || typeof props.value === 'number' || props.value === null)
  ) {
    throw new Error(
      `Value ${
        props.name ? ` of ${props.name} ` : ''
      }has to be string or number or null for single value select but was ${typeof props.value}`
    );
  }

  if (!props.single && props.value && !Array.isArray(props.value)) {
    throw new Error(
      `Value ${
        props.name ? ` of ${props.name} ` : ''
      }has to be an array for multiselect but was ${typeof props.value}`
    );
  }

  const value = props.value || props.value === 0 ? props.value : null;

  const valueHr = useMemo(() => {
    if (typeof value === 'string' || typeof value === 'number') {
      // find in options and suboptions and return representation value
      return props.options.reduce((acc: string | ReactElement, o: Option) => {
        if (typeof o === 'string') {
          return acc;
        }

        if ('options' in o) {
          const suboption = o.options.find((s) => s.id === value);
          if (suboption) {
            return `${suboption.name}${
              displaySelectedSecondary && suboption.secondary ? ' - ' + suboption.secondary : ''
            }`;
          }
        }

        if (o.id === value) {
          return (
            <>
              {o.startIcons ? <Spacer $display="inline">{o.startIcons}</Spacer> : null}
              {`${o.name}${displaySelectedSecondary && o.secondary ? ' - ' + o.secondary : ''}`}
            </>
          );
        }
        return acc;
      }, '');
    } else if (Array.isArray(value)) {
      const itemsToDisplay: SimpleOption[] = [];

      if (value.length > 0) {
        // Display limited number of items
        const DISPLAY_LIMIT = 10;
        value.slice(0, DISPLAY_LIMIT).forEach((singleValueId) => {
          props.options.forEach((o) => {
            if (typeof o === 'string') return;

            if ('options' in o) {
              const suboption = o.options.find((subo) => subo.id === singleValueId);
              if (suboption) {
                itemsToDisplay.push(suboption);
                return;
              }
            }

            if (o.id === singleValueId) itemsToDisplay.push(o);
          });
        });

        return (
          (itemsToDisplay.length > 1 ? `(${value.length}) ` : '') +
          itemsToDisplay.map(({ name }) => name).join(', ')
        );
      }
    }
    return '';
  }, [value, props.options, displaySelectedSecondary]);

  if (mode === 'inline') {
    return <Content height={height} {...props} />;
  }

  if (!id || !label) {
    throw new Error(`ID and label are required in the modal mode (${props.name})`);
  }

  const handleBlur = () => {
    if (!modalOpen) {
      setHasFocus(false);
    }
  };

  const handleClose = () => {
    setModalOpen(false);
    if (id === openDropdownId) {
      dispatch(setOpenDropdownId(undefined));
    }
    setTimeout(() => ref.current && ref.current.blur());
  };

  const handleChange = (
    ids: null | string | number | Array<string | number>,
    option: SimpleOption | { [id: string]: SimpleOption }
  ) => {
    if (props.single && !noAutomaticCloseSingleMode) {
      handleClose();
    }
    props.onChange(ids, option);
  };

  const handleClear = () => {
    props.onChange(props.single ? null : [], {});
  };

  const textClasses = className('mdc-select', 'mdc-select--outlined', {
    'mdc-select--focused': !!hasFocus,
    'mdc-select--invalid': !!error,
  });

  return (
    <>
      {renderTarget ? (
        renderTarget({
          onClick: () => setModalOpen(true),
          role: 'button',
        })
      ) : (
        <StyledSelectedText
          minWidth={minWidth}
          ref={forwardedRef}
          dense={!!dense}
          className={textClasses}
        >
          <i className="mdc-select__dropdown-icon" />
          <div
            onKeyDown={(e) => !disabled && (e.keyCode || e.which) === 13 && setModalOpen(true)}
            onFocus={() => !disabled && setHasFocus(true)}
            className="mdc-select__selected-text"
            onClick={() => setModalOpen(true)}
            aria-labelledby={`${id}-label`}
            tabIndex={disabled ? -1 : 0}
            aria-expanded={modalOpen}
            aria-disabled={disabled}
            onBlur={handleBlur}
            role="button"
            ref={ref}
            id={id}
          >
            {valueHr}
          </div>
          <div
            className={className('mdc-notched-outline mdc-notched-outline--upgraded', {
              'mdc-notched-outline--notched': valueHr,
            })}
          >
            <div className="mdc-notched-outline__leading" />
            <div className="mdc-notched-outline__notch">
              <label
                className={className('mdc-floating-label', {
                  'mdc-floating-label--float-above': valueHr,
                })}
                id={`${id}-label`}
                htmlFor={id}
              >
                {label}
              </label>
            </div>
            <div className="mdc-notched-outline__trailing" />
          </div>
        </StyledSelectedText>
      )}

      {!disabled && (
        <StyledModal
          confirmButton={
            <Button
              type="button"
              text={t('SET')}
              onClick={() => {
                onConfirm?.();
                handleClose();
              }}
            />
          }
          onClear={required ? undefined : handleClear}
          ariaLabel={t('Dropdown Dialog')}
          onCancel={handleClose}
          onClose={handleClose}
          open={modalOpen}
          $height={modalSize?.height}
          $width={modalSize?.width}
        >
          {rightContent ? (
            <FlexRow fullWidth verticalAlign="start" height="100%">
              <FlexColumn padding="1.5rem" height="100%">
                <Content {...props} height={565} onChange={handleChange} />
              </FlexColumn>
              <Divider orientation="vertical" color={theme.color.gray100} />
              <FlexColumn height="100%">{rightContent}</FlexColumn>
            </FlexRow>
          ) : (
            <Content height={height} {...props} onChange={handleChange} />
          )}
        </StyledModal>
      )}
    </>
  );
};

export default memo<Props>(forwardRef(DropDown));
