import { CSSProperties, FC } from 'react';
import ContentLoader from 'react-content-loader';
import { useTranslation } from 'react-i18next';

import theme from 'app/theme';

import { LoadingRow, HiddenInput } from './styled';

const LoadingItem: FC<{ style?: CSSProperties; single?: boolean }> = ({ style, single }) => {
  const { t } = useTranslation();

  return (
    <LoadingRow style={style} className="mdc-list-item">
      <ContentLoader
        backgroundColor={theme.color.gray100}
        foregroundColor={theme.color.gray200}
        title={t('Loading...')}
        width={style?.width}
        height={13}
        viewBox={`0 0 ${style?.width} 13`}
      >
        <rect x="0" y="0" rx="5" ry="5" width={style?.width} height={13} />
      </ContentLoader>
      {!single && <HiddenInput type="checkbox" />}
    </LoadingRow>
  );
};

export default LoadingItem;
