export const TYPE_LOAD_ATHLETE_REVISIONS = 'LOAD_ATHLETE_REVISIONS';
export const loadAthleteRevisions = (mastersId: number) =>
  ({
    type: TYPE_LOAD_ATHLETE_REVISIONS,
    payload: {
      mastersId,
    },
  }) as const;
export type LoadAthleteRevisionAction = ReturnType<typeof loadAthleteRevisions>;

export const TYPE_SET_ATHLETE_MASTER = 'SET_ATHLETE_MASTER';
export const setAthleteMaster = (athleteId: number, mastersId: null | number) =>
  ({
    type: TYPE_SET_ATHLETE_MASTER,
    payload: {
      mastersId,
      athleteId,
    },
  }) as const;
export type SetAthleteMasterAction = ReturnType<typeof setAthleteMaster>;

export const TYPE_LOAD_ATHLETE_MASTERS = 'LOAD_ATHLETE_MASTERS';
export const loadAthleteMasters = () =>
  ({
    type: TYPE_LOAD_ATHLETE_MASTERS,
  }) as const;
export type LoadAthleteMastersAction = ReturnType<typeof loadAthleteMasters>;

export type AthleteActions =
  | LoadAthleteRevisionAction
  | SetAthleteMasterAction
  | LoadAthleteMastersAction;
