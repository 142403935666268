import { ErrorMessage, FieldProps, getIn } from 'formik';
import { FC, ReactNode, useState } from 'react';

import { FeedbackMessage } from 'core/components/FeedbackMessage';

import ChipsField from '../ChipsField';
import FieldGroup from '../FieldGroup';

import { StyledHelperText } from './styled';

type Props = FieldProps & {
  helperText: ReactNode;
  disabled?: boolean;
  label: string;
  onChange?: (value: string[] | null) => void;
};

const FormChipsField: FC<Props> = ({
  form: { setFieldValue },
  field: { name, value, onBlur },
  helperText,
  disabled,
  label,
  form,
  onChange,
}) => {
  const [text, setText] = useState('');
  const error = getIn(form.touched, name) && getIn(form.errors, name);
  const chipErrors: any = error && Array.isArray(form.errors[name]) ? form.errors[name] || [] : [];

  return (
    <FieldGroup hasError={!!error}>
      <ChipsField
        error={!!error}
        onChange={(value) => {
          if (onChange) {
            onChange(value);
          } else {
            setFieldValue(name, value);
          }
        }}
        onTextChange={setText}
        onBlur={(e) => onBlur(e)}
        chipErrors={chipErrors}
        disabled={disabled}
        value={value}
        textValue={text}
        label={label}
        name={name}
      />
      <ErrorMessage
        component={FeedbackMessage}
        name={name}
        render={(error) => {
          if (error && Array.isArray(error)) {
            return error
              .filter((it: string, key: number, arr: string[]) => arr.indexOf(it) === key)
              .map((error: string, key: number) => (
                <FeedbackMessage key={key}>{error}</FeedbackMessage>
              ));
          }
          return <FeedbackMessage>{error}</FeedbackMessage>;
        }}
      />
      {helperText && !error && <StyledHelperText isValid={true}>{helperText}</StyledHelperText>}
    </FieldGroup>
  );
};

export default FormChipsField;
