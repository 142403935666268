import { useCallback } from 'react';

export const WADA_URL = `https://adams.wada-ama.org/ng/en/to/view`;
const WADA_PREFIX_TO = 'TO-';
const WADA_PREFIX_M = 'M-';

const useGetWADAMissionUrl = () => {
  return useCallback((code: string) => {
    return `${WADA_URL}/${code.replace(
      new RegExp(`^(${WADA_PREFIX_TO}|${WADA_PREFIX_M})`, 'g'),
      ''
    )}`;
  }, []);
};

export const isWADAMission = (code?: string): boolean =>
  code?.startsWith(WADA_PREFIX_TO) || code?.startsWith(WADA_PREFIX_M) || false;

export default useGetWADAMissionUrl;
