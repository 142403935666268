import { push } from 'connected-react-router';
import { toast } from 'react-toastify';
import { put, race, take } from 'redux-saga/effects';

import { urls } from 'app/urls';
import { t } from 'core/i18n';

import {
  ResetPasswordAction,
  apiCall,
  TYPE_RESET_PASSWORD,
  TYPE_RESET_PASSWORD_SUCCESS,
  TYPE_RESET_PASSWORD_ERROR,
} from '../actions';

function* resetPasswordSaga(action: ResetPasswordAction) {
  yield put(
    apiCall(
      TYPE_RESET_PASSWORD,
      'POST',
      `/auth/reset-password/${action.payload.code}`,
      action.payload.data
    )
  );

  const { success } = yield race({
    success: take(TYPE_RESET_PASSWORD_SUCCESS),
    error: take(TYPE_RESET_PASSWORD_ERROR),
  });

  if (success) {
    yield put(push(urls.login));
    toast.success(t('Password successfully saved'));
  }
}

export default resetPasswordSaga;
