import { FC, memo } from 'react';
import { useDispatch } from 'react-redux';

import { useDetail } from 'core/containers/FormDetailPage/DetailContext';
import { useSetModal } from 'core/hooks/useModal';
import { testBulkCreate } from 'planning/actions';
import useMissionTests from 'planning/hooks/useMissionTests';
import Mission from 'planning/models/Mission';

import TestBulkCreateDropdown from '../components/TestBulkCreateDropdown';
import { MissionModal, useMissionModal } from '../hooks/useMissionModal';

const BulkCreateTestsModal: FC = () => {
  const { entityData: missionData, entitySaveInProgress, id: missionsId } = useDetail<Mission>();
  const isOpen = useMissionModal(MissionModal.BULK_CREATE);
  const { dispatch: refetchTests } = useMissionTests(missionsId, false);
  const setModal = useSetModal();
  const dispatch = useDispatch();

  if (!missionData) {
    return null;
  }

  return (
    // @ts-ignore
    <TestBulkCreateDropdown
      // TODO: there is uncovered situation https://cannypack.atlassian.net/browse/MODFE-802
      hasFederation={Boolean(missionData.federationsId || missionData.federationsName)}
      onRequestClose={() => !entitySaveInProgress && setModal()}
      onRequestCreate={(athletesId: number[]) => {
        dispatch(
          testBulkCreate(missionsId!, { athletesId }, () => {
            setModal();
            refetchTests({ cache: false }); // TODO: use new resources hook with dependencies
          })
        );
      }}
      saveInProgress={entitySaveInProgress}
      open={isOpen}
    />
  );
};

export default memo(BulkCreateTestsModal);
