import { DateTime } from 'luxon';
import { useMemo } from 'react';

import 'react-day-picker/lib/style.css';

import { FilterValue, RenderedCustomFilter } from 'core/containers/DataGrid';
import { transformDateToString } from 'core/effects/apiCall/dateTimeTransformations';

import RangeFilter from './RangeFilter';
import useRelativeRanges from './useRelativeRanges';

const DEFAULT_VAL: FilterValue = null;

function useRangeDatagridFilter(
  customFilter?: ((from: DateTime, to: DateTime) => {}) | undefined,
  customLabel?: string,
  id?: string
) {
  const [relativeOptions, getRangeForRelativeOption] = useRelativeRanges();

  return useMemo(
    () =>
      ({
        type: 'customRendered',
        defaultValue: DEFAULT_VAL,
        applyToParams: (value: FilterValue) => {
          let range: readonly [DateTime, DateTime] | undefined = undefined;

          // Relative range
          if (typeof value === 'string') {
            range = getRangeForRelativeOption(
              value as Parameters<typeof getRangeForRelativeOption>[0]
            );
          }

          // Absolute range
          if (Array.isArray(value) && value.length === 2) {
            range = [DateTime.fromISO(value[0] as string), DateTime.fromISO(value[1] as string)];
          }

          if (!range) return {};

          let filter;
          if (customFilter) {
            filter = customFilter(range[0], range[1]);
          } else {
            filter = {
              dateTo: {
                $gte: transformDateToString(range[0]),
              },
              dateFrom: {
                $lte: transformDateToString(range[1]),
              },
            };
          }

          return filter;
        },
        component: (onChange, range) => (
          <RangeFilter
            id={id}
            relativeOptions={relativeOptions}
            customLabel={customLabel}
            value={range as [string, string] | null}
            onChange={onChange}
          />
        ),
      }) as RenderedCustomFilter,
    [customFilter, customLabel, getRangeForRelativeOption, relativeOptions, id]
  );
}

export const getRangeFilterValues = (
  from?: DateTime | Date | null,
  to?: DateTime | Date | null
): string[] | undefined => {
  if (from && to) return [transformDateToString(from, 'DATE'), transformDateToString(to, 'DATE')];
  return undefined;
};

export default useRangeDatagridFilter;
