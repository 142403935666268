import { useMemo } from 'react';
import { useTranslation } from 'react-i18next';

const useFrequencyOptions = () => {
  const { t } = useTranslation();

  return useMemo(
    () => [
      { id: 'One time use', name: t('One time use') },
      { id: 'Once daily', name: t('Once daily') },
      { id: 'Twice daily', name: t('Twice daily') },
      { id: 'Every 4 hours', name: t('Every 4 hours') },
      { id: 'Every 6 hours', name: t('Every 6 hours') },
      { id: 'Every 12 hours', name: t('Every 12 hours') },
      { id: 'As needed', name: t('As needed') },
      { id: 'Other', name: t('Other') },
    ],
    [t]
  );
};

export default useFrequencyOptions;
