import { DateTime } from 'luxon';
import { FC, useRef, useEffect, useState } from 'react';
import DayPicker from 'react-day-picker';

import 'react-day-picker/lib/style.css';
import CalendarHeader from '../CalendarHeader';
import { PickerWrapper } from '../styled';

export interface Props {
  onChange: (date: Date) => void;
  value?: Date;
  fromDay?: Date;
  toDay?: Date;
}

const SingleDayPicker: FC<Props> = ({ onChange, value, fromDay, toDay }) => {
  // Don't display selected value while it was not picked - changing just year and month should not display default date
  const selectedVal = useRef(!!value);
  const [monthYear, setMonthYear] = useState(value || new Date());
  const valMonthYear = value ? value.getMonth().toString() + value?.getFullYear() : undefined;
  useEffect(() => {
    setMonthYear(value || new Date());
    // We use indirect dependency
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [valMonthYear]);

  useEffect(() => {
    if (!value) {
      selectedVal.current = false; // reset on reset
    }
  }, [value]);

  const handleDayClick = (d: Date) => {
    const picked = DateTime.fromJSDate(d);

    if (
      (fromDay && picked.startOf('day') < DateTime.fromJSDate(fromDay).startOf('day')) ||
      (toDay && picked.startOf('day') > DateTime.fromJSDate(toDay).startOf('day'))
    ) {
      return;
    }

    selectedVal.current = true;
    onChange(d);
  };

  return (
    <PickerWrapper>
      <DayPicker
        selectedDays={(selectedVal.current && value) || undefined}
        onDayClick={handleDayClick}
        navbarElement={() => null}
        month={monthYear}
        fromMonth={fromDay}
        toMonth={toDay}
        captionElement={({ date }: { date: Date | null }) => (
          <CalendarHeader
            selection={
              // Display "Date selection" if no date was picked
              (selectedVal.current && value) || undefined
            }
            date={date || new Date()}
            onChange={setMonthYear}
            fromMonth={fromDay}
            toMonth={toDay}
            range={false}
          />
        )}
      />
    </PickerWrapper>
  );
};

export default SingleDayPicker;
