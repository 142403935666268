import { FC } from 'react';
import { useTranslation } from 'react-i18next';

import theme from 'app/theme';
import Divider from 'core/components/Divider';
import { Spacer } from 'core/components/Spacer/styled';
import {
  PopoverBlock,
  PopoverHeader,
} from 'core/containers/DataGrid/CellRenderers/PopoverCellRenderer';
import { PopoverTitle } from 'core/containers/DataGrid/CellRenderers/PopoverCellRenderer/styled';
import { useFormatRelativeDateTime } from 'core/i18n/useFormatDateTime';
import { TeamMemberData } from 'planning/components/TeamTab/useTeamInputMapping';

import { parseNotificationData } from './utils';

const AssignmentNotificationsContent: FC<{ user: TeamMemberData & { id: number } }> = ({
  user,
}) => {
  const { t } = useTranslation();
  const formatRelativeDateTime = useFormatRelativeDateTime();

  const { sent: sentInvitations } = parseNotificationData(user.invitations, formatRelativeDateTime);

  const { plannedMeans, sent: sentNotifications } = parseNotificationData(
    user.assignmentNotifications,
    formatRelativeDateTime
  );

  const hasPlannedNotifications = plannedMeans.length > 0;
  const hasSentNotifications = sentNotifications.length > 0;
  const hasSentInvitations = sentInvitations.length > 0;

  return (
    <>
      <PopoverHeader title={t('Notifications')} />

      {hasPlannedNotifications && (
        <PopoverBlock label={t('On save, will be sent via')} value={plannedMeans.join(', ')} />
      )}

      {hasSentNotifications && hasPlannedNotifications && (
        <Divider color={theme.color.gray200} marginVertical="0.5rem" />
      )}

      {hasSentNotifications &&
        sentNotifications.map(({ formattedDate, means }) => (
          <PopoverBlock
            key={formattedDate}
            label={t('Sent {{when}} via', { when: formattedDate })}
            value={means.join(', ')}
          />
        ))}

      {!hasPlannedNotifications && !hasSentNotifications && (
        <PopoverTitle $color={theme.color.textSecondary}>
          <Spacer $marginTop=".3rem" />
          {t('No notifications sent.')}
        </PopoverTitle>
      )}

      {hasSentInvitations && (
        <>
          <Spacer $marginTop="1rem" />
          <PopoverHeader title={t('Previous invitations')} />
          {sentInvitations.map(({ formattedDate, means }) => (
            <PopoverBlock
              key={formattedDate}
              label={t('Sent {{when}} via', { when: formattedDate })}
              value={means.join(', ')}
            />
          ))}
        </>
      )}
    </>
  );
};

export default AssignmentNotificationsContent;
