import { RowClickedEvent } from 'ag-grid-community';
import React from 'react';
import { useTranslation } from 'react-i18next';

import { entities } from 'app/entity';
import { FullRowCell } from 'core/components/GridCell';
import { useDetail } from 'core/containers/FormDetailPage/DetailContext';
import { getResourceUrl } from 'core/functions/getResourcesUrl';
import history from 'core/history';
import usePermission from 'core/hooks/usePermission';
import { useResources } from 'core/hooks/useResource';
import Sample from 'planning/models/Sample';

import PaperTable from '../PaperTable';

import useEntityOverviewUrl from './useEntityOverviewUrl';

const SamplesTable: React.FC = () => {
  const { id: missionsId, mode } = useDetail();
  const isEdit = mode === 'edit';
  const canFindSamples = usePermission('samples:find');
  const { t } = useTranslation();

  const { data: samples, isLoading } = useResources<Sample>('missionSamples', {
    url: getResourceUrl(`missions/${missionsId}/samples`, { order: 'createdAt', sort: 'desc' }),
    autoload: canFindSamples,
  });

  const entityOverviewUrl = useEntityOverviewUrl('sample', {
    mission: missionsId?.toString(),
    volume: 'any',
  });

  const iconActions = [
    {
      icon: 'link',
      to: entityOverviewUrl,
      disabled: !samples?.length,
      title: t('Link to Samples'),
    },
  ];

  if (!canFindSamples) {
    return null;
  }

  return (
    <FullRowCell>
      <PaperTable
        data={samples || []}
        type="samples"
        onSelect={(event: RowClickedEvent) => {
          if (isEdit) {
            const sample: Sample = event.data;
            history.push(entities.sample.urls().detail(sample.id));
          }
        }}
        loading={isLoading}
        icons={iconActions}
      />
    </FullRowCell>
  );
};

export default SamplesTable;
