import { FC, ReactNode } from 'react';

import MaterialIcon from 'core/components/MaterialIcon';

import IconButton from '../IconButton';

interface LinkProps {
  disabledLink: ReactNode;
  link: string | undefined;
}

const LinkElement: FC<LinkProps> = ({ disabledLink, link }) => {
  return (
    <IconButton
      to={link}
      tooltip={typeof disabledLink === 'string' ? disabledLink : undefined}
      disabled={!!disabledLink}
      type="button"
    >
      <MaterialIcon icon="link" />
    </IconButton>
  );
};

export default LinkElement;
