import React, { FC, memo } from 'react';

import AuditLogs from 'core/containers/AuditLogs';
import { useDetail } from 'core/containers/FormDetailPage/DetailContext';

const AuditLogsTab: FC = () => {
  const { id } = useDetail();

  if (!id) {
    return null;
  }
  return <AuditLogs entityId={id} entity="mission" />;
};

export default memo(AuditLogsTab);
