import { EntityKey, getEntityPermission } from 'app/entity';
import useAppSelector from 'core/hooks/useAppSelector';
import usePermission from 'core/hooks/usePermission';

const useCanSeeRestrictedVisibility = (entityKey: EntityKey) => {
  const entityPermission = getEntityPermission(entityKey);
  const canSeeRoles = usePermission('roles:find');
  const canSeeRestrictedVisibility = usePermission(
    `${entityPermission}:comments[actions:restrictVisibility]`
  );

  return canSeeRoles && canSeeRestrictedVisibility;
};

export const useCanDeleteAllComments = (entityKey: EntityKey) => {
  const entityPermission = getEntityPermission(entityKey);
  const permissions = useAppSelector(({ core: { user } }) => (user && user.permissions) || {});

  const commentsDeletePermissions = permissions[`${entityPermission}:comments`];

  const scope = commentsDeletePermissions.params?.scope || [];

  return scope.includes('unrestrictedRemoval');
};

export default useCanSeeRestrictedVisibility;
