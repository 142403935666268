import { push } from 'connected-react-router';
import 'core/containers/DataGrid/styles.scss';
import { FC } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch } from 'react-redux';

import { entities, NoContextEntityKey } from 'app/entity';
import AgGridPaperWrapper from 'core/components/AgGridPaperWrapper';
import PaperContainer from 'core/components/PaperContainer';
import usePermission from 'core/hooks/usePermission';

import DataGrid from '../DataGrid';

import useAuditsUrls, { AuditsUrls } from './useAuditsUrls';
import useColumnDefs from './useColumnDefs';
import useFilters from './useFilters';

export interface Props {
  entityId: number;
  auditsUrls?: AuditsUrls;
  entity: NoContextEntityKey;
}

const AuditLogs: FC<Props> = ({ auditsUrls, entityId, entity }) => {
  const { t } = useTranslation();
  const canSeeDetail = usePermission('auditLogs:get');
  const columnDefs = useColumnDefs();

  const currentEntity = entities[entity];
  const defaultUrls = useAuditsUrls(currentEntity.api().list);
  const urls = auditsUrls || defaultUrls;

  const dispatch = useDispatch();
  const openAuditLogDetail = (id: number) => dispatch(push(entities.auditLog.urls().detail(id)));

  const filters = useFilters({
    withEntity: false,
    withSearch: false,
  });

  return (
    <PaperContainer fullHeight title={t('History')}>
      <AgGridPaperWrapper fullHeight>
        <DataGrid
          columnDefs={columnDefs}
          endpoint={urls.auditsList(entityId)}
          onRowClicked={(data) => {
            // id can be negative
            if (canSeeDetail && !!data?.id) {
              openAuditLogDetail(data.id);
            }
          }}
          disableTotalCount
          filters={filters}
          disableViews
        />
      </AgGridPaperWrapper>
    </PaperContainer>
  );
};

export default AuditLogs;
