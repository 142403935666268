import { useTranslation } from 'react-i18next';

import { entities } from 'app/entity';
import Event, { EventStatus } from 'app/models/Event';
import {
  Notification,
  NotificationTypeEnum,
} from 'core/components/Notifications/RenderNotification';
import getEnv from 'core/functions/getEnv';

export default function useCreateTdpFiles() {
  const { t } = useTranslation();

  return (e: Event): Notification => {
    const tdpsId = e.payload.tdpsId || t('Unknown ID');

    const typeTexts = {
      tdp: {
        inProgressPrimary: t('TDP: #{{tdpsId}} report creation submitted', {
          tdpsId,
        }),
        inProgressSecondary: t('The report is being created.'),
        finishedPrimary: t('TDP: #{{tdpsId}} report creation completed', {
          tdpsId,
        }),
        finishedSecondary: t('The report have been successfully created. You can download it now.'),
        failedPrimary: t('TDP: #{{tdpsId}} report creation failed', {
          tdpsId,
        }),
        failedSecondary: t('An error occurred while creating the report.'),
      },
      tdpStats: {
        inProgressPrimary: t('TDP: #{{tdpsId}} statistics creation submitted', {
          tdpsId,
        }),
        inProgressSecondary: t('The statistics are being created.'),
        finishedPrimary: t('TDP: #{{tdpsId}} statistics creation completed', {
          tdpsId,
        }),
        finishedSecondary: t(
          'The statistics have been successfully created. You can download them now.'
        ),
        failedPrimary: t('TDP: #{{tdpsId}} statistics creation failed', {
          tdpsId,
        }),
        failedSecondary: t('An error occurred while creating the statistics.'),
      },
    };
    const type = e.payload.type as keyof typeof typeTexts;

    const map = {
      [EventStatus.IN_PROGRESS]: {
        primary: typeTexts[type].inProgressPrimary || t('Unknown'),
        secondary: typeTexts[type].inProgressSecondary || t('Unknown'),
        updatedAt: e.modifiedAt || e.createdAt,
        icon: 'cloud_download',
        inProgress: true,
        read: e.isRead,
        type: NotificationTypeEnum.INFO,
        id: e.id,
      },

      [EventStatus.FINISHED]: {
        primary: typeTexts[type].finishedPrimary || t('Unknown'),
        secondary: typeTexts[type].finishedSecondary || t('Unknown'),
        updatedAt: e.modifiedAt || e.createdAt,
        actions: [
          {
            key: 'download',
            text: t('Download'),
            action: () =>
              window.open(
                `${getEnv('API_URL')}${entities.tdp.api().download(tdpsId, e.id)}`,
                '_blank'
              ),
          },
        ],
        icon: 'cloud_download',
        inProgress: false,
        read: e.isRead,
        type: NotificationTypeEnum.SUCCESS,
        id: e.id,
      },

      [EventStatus.FAILED]: {
        primary: typeTexts[type].failedPrimary || t('Unknown'),
        secondary: typeTexts[type].failedSecondary || t('Unknown'),
        updatedAt: e.modifiedAt || e.createdAt,
        icon: 'cloud_download',
        inProgress: false,
        read: e.isRead,
        type: NotificationTypeEnum.ERROR,
        id: e.id,
      },

      [EventStatus.CREATED]: null,
      [EventStatus.WARNING]: null,
    };

    return map[e.status] || map[EventStatus.IN_PROGRESS];
  };
}
