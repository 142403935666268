import { ErrorMessage, FieldProps, getIn } from 'formik';
import { ComponentProps, FC, ReactNode, useCallback, useMemo } from 'react';

import DropDown, { Option, SimpleOption } from 'core/components/DropDown';
import { FeedbackMessage } from 'core/components/FeedbackMessage';
import FieldGroup from 'core/components/FieldGroup';
import { groupSelection } from 'core/hooks/useDropDownSelectionGrouping';

import sortOptionsHelper from './sortOptionsHelper';

export type Props = FieldProps<null | string | number | Array<string | number>> &
  ComponentProps<typeof DropDown> & {
    sortOptions?: boolean;
    sortComparator?: (a: SimpleOption, b: SimpleOption) => number;
    /**
     * Group selected options at the beginning - does not support groups within options
     */
    groupSelected?: boolean;
    error?: boolean;
    minWidth?: string | number;
    displaySelectedSecondary?: boolean;
    rightContent?: ReactNode;
    modalSize?: {
      height: string;
      width: string;
      overflow?: 'auto' | 'hidden';
    };
  };

const FormDropDown: FC<Props> = ({
  form: { touched, errors, setFieldValue },
  options: rawOptions,
  field: { name, value },
  sortOptions = true,
  sortComparator,
  groupSelected = true,
  onChange,
  dense,
  error,
  disabled,
  rightContent,
  ...props
}) => {
  const onChangeCallback = useCallback(
    (
      value: string | number | (string | number)[] | null,
      option:
        | SimpleOption
        | {
            [id: string]: SimpleOption;
          }
    ) => {
      if (onChange) {
        onChange(value, option);
      } else {
        setFieldValue(name, value, true);
      }
    },
    [setFieldValue, name, onChange]
  );

  const hasError = error !== undefined ? error : getIn(touched, name) && getIn(errors, name);
  // Options get sorted and selected are grouped
  const options: Option[] = useMemo(() => {
    let processedOptions = rawOptions || [];

    if (sortOptions) {
      processedOptions = sortOptionsHelper(processedOptions, sortComparator);
    }

    if (groupSelected) {
      processedOptions = groupSelection(processedOptions, value);
    }

    return processedOptions;
  }, [sortOptions, sortComparator, groupSelected, rawOptions, value]);

  const dropdownEl = (
    <DropDown
      disabled={disabled}
      id={`form-${name}`}
      label={props.label}
      options={options}
      dense={dense}
      {...props}
      value={value}
      onChange={onChangeCallback}
      error={hasError}
      name={name}
      rightContent={rightContent}
    />
  );

  const msg = useMemo(
    () => hasError && <ErrorMessage component={FeedbackMessage} name={name} />,
    [hasError, name]
  );

  return (
    <FieldGroup hasError={hasError}>
      {dropdownEl}
      {msg}
    </FieldGroup>
  );
};

export default FormDropDown;
