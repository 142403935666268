import { FC, useLayoutEffect } from 'react';

import SearchInput, { Props } from '../';

const SearchInputWrapper: FC<Props> = (props) => {
  useLayoutEffect(() => {
    document.getElementById('page-header')?.classList.add('has-search');
  });

  return <SearchInput {...props} />;
};

export default SearchInputWrapper;
