import { DateTime, SystemZone } from 'luxon';

/**
 * TLDR for datetime transformations:
 * 1. Values received from API are automatically transformed to Date or Luxon DateTime if has offset and transformStringsToDateTime should usually not be used manually.
 * 2. Forms expecting Luxon DateTime on input should use transformDateTimeToDate in input mapping and transformDateToString on output.
 * 3. Occasionally it might be useful to use transformDateTimeToStrings which is opposite to transformStringsToDateTime.
 */

/**
 * Transforms ISO date/datetime strings on input (object, array...) to Date or Luxon DateTime
 *
 * Recursively finds and transforms any ISO date or datetime string:
 * - Date or datetime strings without offset like 2020-01-01 or 2020-12-10T18:25:21.000Z are transformed to Date
 * - Date or datetime strings with offset like 2021-01-09T23:00:00.000+01:00 are transformed to Luxon DateTime so they retain the timezone
 *
 * Changes are made in-place (on the passed reference).
 *
 * @todo Is there any way to type this better?
 * @param {*} input Any value coming from the API - nested objects, array ...
 * @return Reference to the input or tranformed value for pass-by-value (primitive) values
 */
export function transformStringsToDateTime<Input>(input: Input) {
  // Transform every supported value type separately and let the rest fall through

  // Valid date/datetime is 10 chars 2020-01-01 or longer
  if (typeof input === 'string' && input.length >= 10) {
    // Check if the string is known ISO date/datetime https://regexr.com/5lrp5
    if (
      input.match(/^\d{4}-\d{2}-\d{2}(T\d{2}:\d{2}(:\d{2})?(.\d{3})?(([+-]\d{2}:\d{2})|Z)?)?$/) ===
      null
    ) {
      return input;
    }

    if (input.length === 10) {
      // Strings of date length are Date
      // and they represent midnight local time
      return DateTime.fromISO(input, { zone: 'local' }).toJSDate();
    } else if (input.match(/.*[+-]\d{2}:\d{2}$/) === null) {
      // Strings that don't contain explicit offset are Date only
      // They are expected to be UTC and will be shifted to local time
      return new Date(input);
    } else {
      // the rest has to be date and time ending with explicit offset
      return DateTime.fromISO(input, { setZone: true });
    }
  }

  if (Array.isArray(input)) {
    input.forEach((one) => transformStringsToDateTime(one));
  }

  if (typeof input === 'object' && input !== null) {
    Object.entries(input).forEach(
      // @ts-ignore We are sure about the type here as we just got key from the object
      ([key, value]) => (input[key] = transformStringsToDateTime(value))
    );
  }

  return input;
}

/**
 * All Date and DateTime instances in the object typed as strings
 */
export type ObjectDatesAsStrings<T extends object> = {
  [K in keyof T]: T[K] extends Date | null
    ? string | null
    : T[K] extends Date | undefined
      ? string | undefined
      : T[K] extends Date
        ? string
        : T[K] extends DateTime | null
          ? string | null
          : T[K] extends DateTime | undefined
            ? string | undefined
            : T[K] extends DateTime
              ? string
              : T[K];
};

/**
 * All Date and DateTime instances in the object typed as strings
 */
export type ObjectDateTimesAsDates<T extends object> = {
  [K in keyof T]: T[K] extends DateTime | null
    ? Date | null
    : T[K] extends DateTime | undefined
      ? Date | undefined
      : T[K] extends DateTime
        ? Date
        : T[K];
};

/**
 * Transforms Date instances or Luxon DateTime on input (object, array...) to ISO date/datetime strings
 *
 * Recursively finds and transforms any Date or Luxon DateTime to UTC ISO string:
 * - Shifting to UTC for Date objects
 * - Without shifting to UTC for Luxon DateTime
 *
 * Changes are made in-place (on the passed reference).
 *
 * @param {*} input Any value sent to the API - nested objects, array ...
 * @return Reference to the input or tranformed value for pass-by-value (primitive) values
 */
export function transformDateTimeToStrings(input: Date | DateTime): string;
export function transformDateTimeToStrings<Input extends object>(
  input: Input[]
): ObjectDatesAsStrings<Input>[];
export function transformDateTimeToStrings<Input extends object>(
  input: Input
): ObjectDatesAsStrings<Input>;
export function transformDateTimeToStrings<Input extends object>(
  input: Date | DateTime | Input | Input[]
) {
  // Transform every supported value type separately and let the rest fall through

  if (input instanceof Date) {
    return input.toJSON();
  }

  if (input instanceof DateTime) {
    return input.setZone('UTC', { keepLocalTime: true }).toJSON();
  }

  if (Array.isArray(input)) {
    input.forEach((one) => transformDateTimeToStrings(one));
  }

  if (typeof input === 'object' && input !== null) {
    Object.entries(input).forEach(
      // @ts-ignore We are sure about the type here as we just got key from the object
      ([key, value]) => (input[key] = transformDateTimeToStrings(value))
    );
  }

  return input;
}

/**
 * Transforms Luxon DateTime on input (object, array...) to Date objects
 *
 * Recursively finds and transforms any Luxon DateTime to Date object wihout shifting the time.
 * Example: For input DateTime eq. 2020-10-10T10:10:10+04:00 output is Date 2020-10-10T10:10:10 local time.
 * Useful for input mapping on forms.
 *
 * Changes are made on a copy - it is immutable
 *
 * @param {*} input Any value coming from the API - nested objects, array ...
 * @return Copy of input with transformed value(s)
 */
export function transformDateTimeToDate(input: DateTime): Date;
export function transformDateTimeToDate<Input extends object>(
  input: Input[]
): ObjectDateTimesAsDates<Input>[];
export function transformDateTimeToDate<Input extends object>(
  input: Input
): ObjectDateTimesAsDates<Input>;
export function transformDateTimeToDate<Input extends object>(input: DateTime | Input | Input[]) {
  // Transform every supported value type separately and let the rest fall through
  if (input instanceof DateTime) {
    return input.setZone(new SystemZone(), { keepLocalTime: true }).toJSDate();
  }

  if (input instanceof Date) {
    return input;
  }

  if (Array.isArray(input)) {
    return input.map((one) => transformDateTimeToDate(one));
  }

  if (typeof input === 'object' && input !== null) {
    return Object.fromEntries(
      Object.entries(input).map(([key, value]) => [key, transformDateTimeToDate(value)])
    );
  }

  return input;
}

/**
 * Transforms Date instance to local string formatted for API
 *
 * Example: For input Date eq. 2020-10-10T10:10:10 output is string 2020-10-10T10:10:10Z.
 * Useful for output mapping on forms where all of the Date objects are either date only (without timezone) or date time and should be saved in the local time.
 *
 * @param {Date|DateTime} input Any value sent to the API
 * @param {'DATETIME' | 'DATE' | 'TIME'} format Optional format, defaults to DATETIME
 * @return Formatted string fit for use in request to API
 */
export function transformDateToString(
  input: Date | DateTime,
  format: 'DATETIME' | 'DATE' | 'TIME' = 'DATETIME'
): string {
  const date = input instanceof DateTime ? input : DateTime.fromJSDate(input);
  return date.toFormat(
    format === 'DATETIME'
      ? `yyyy-LL-dd'T'HH:mm:ss'Z'`
      : format === 'DATE'
        ? 'yyyy-LL-dd'
        : 'HH:mm:ss'
  );
}
