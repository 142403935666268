import { useCallback } from 'react';

import { AccreditationsRole } from 'app/models/Accreditation';
import User from 'app/models/User';
import { Option } from 'core/components/DropDown';
import { useBoolClientOption } from 'core/hooks/useClientOption';
import { useHumanReadableAvailability } from 'personnel/hooks/useHumanReadableAvailability';
import AvailabilityCategory from 'personnel/models/AvailabilityCategory';

const useOfficersReducer = (
  availabilityCategories?: AvailabilityCategory[],
  options?: {
    requiredRoles?: AccreditationsRole[];
    beforeReducer?: (list: User[], chosen: number[] | null) => User[];
    afterReducer?: (list: Option[], chosen: number[] | null) => Option[];
  }
) => {
  const getHumanReadableAvailability = useHumanReadableAvailability(availabilityCategories);
  const tagsEnabled = useBoolClientOption('enableTags');
  const availabilitiesEnabled = useBoolClientOption('enableAvailabilities');

  const reducer = useCallback(
    (list: User[], chosen: number[] | null) => {
      const chosenSet = new Set(chosen);

      const requiredRoles = options?.requiredRoles;
      const beforeReducer = options?.beforeReducer;
      const afterReducer = options?.afterReducer;

      const beforeReduced = beforeReducer ? beforeReducer(list, chosen) : list;

      const reduced = beforeReduced
        .filter((officer) => {
          // Selected officer is always included
          if (chosenSet.has(officer.id)) return true;

          const accreditedFor = new Set(
            officer.accreditations?.map((accreditation) => accreditation.role)
          );

          return (
            officer.active &&
            (!requiredRoles || requiredRoles.some((required) => accreditedFor.has(required)))
          );
        })
        .map((item) => {
          const secondary = [];
          if (availabilitiesEnabled) {
            secondary.push(getHumanReadableAvailability(item.availabilities));
          }
          if (tagsEnabled) {
            if (Array.isArray(item.tags) && item.tags.length > 0) {
              secondary.push(item.tags.join(', '));
            } else if (!availabilitiesEnabled) {
              // Secondary row has to be always available if at least one can have tags and won't have availability.
              // Otherwise, the dropdown would break.
              secondary.push('-');
            }
          }

          return {
            id: item.id,
            name: item.fullName || item.email,
            secondary: secondary.length > 0 ? secondary.join(', ') : undefined,
            extra: item,
          };
        });

      // Align secondary - either all or none have it - this otherwise breaks list of options
      const anyHasSecondary = reduced.some((item) => !!item.secondary);
      const items = anyHasSecondary
        ? reduced.map((item) => ({ ...item, secondary: item.secondary || '-' }))
        : reduced.map((item) => {
            const withoutSecondary = { ...item };
            delete withoutSecondary.secondary;
            return withoutSecondary;
          });

      return afterReducer ? afterReducer(items, chosen) : items;
    },
    [
      options?.requiredRoles,
      options?.afterReducer,
      tagsEnabled,
      options?.beforeReducer,
      availabilitiesEnabled,
      getHumanReadableAvailability,
    ]
  );

  return reducer;
};

export default useOfficersReducer;
