import { push } from 'connected-react-router';
import { Location, UnregisterCallback } from 'history';
import { FC, useState, useRef, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch } from 'react-redux';
import { useHistory } from 'react-router-dom';

import Button from 'core/components/Button';
import Modal from 'core/components/Modal';
import { TestFormData } from 'planning/containers/TestDetailPage/hooks/useTestSchema';

interface Props {
  trackUrl: string;
  /**
   * Reference to unsaved data that, when not empty, trigger prompt
   */
  reference: { [testsId: number]: TestFormData };
}

const PageLeavePrompt: FC<Props> = ({ trackUrl, reference }) => {
  const { t } = useTranslation();
  const { block } = useHistory();
  const [blockedUrl, setBlockedUrl] = useState<string>();
  const unblock = useRef<UnregisterCallback>();
  const dispatch = useDispatch();

  useEffect(() => {
    unblock.current = block((location: Location): string | false | void => {
      const hasUnsavedChanges = Object.keys(reference).length > 0;
      if (hasUnsavedChanges && !location.pathname.startsWith(trackUrl)) {
        setBlockedUrl(location.pathname + (location.search || ''));
        return false;
      }
    });

    return () => {
      unblock.current && unblock.current();
      unblock.current = undefined;
    };
  }, [trackUrl, block, reference]);

  const confirmNavigation = () => {
    unblock.current && unblock.current();
    unblock.current = undefined;

    dispatch(push(blockedUrl as string));
  };

  return (
    <Modal
      ariaLabel={t('Unsaved changes confirmation dialog')}
      title={t('Confirm leaving form')}
      confirmButton={
        <Button text={t('Discard changes')} onClick={confirmNavigation} type="button" />
      }
      cancelButton={
        <Button onClick={() => setBlockedUrl(undefined)} text={t(`Don't leave`)} type="button" />
      }
      onClose={() => setBlockedUrl(undefined)}
      open={blockedUrl !== undefined}
    >
      {t('You have unsaved changes on current form. Are you sure you want to leave this page?')}
    </Modal>
  );
};

export default PageLeavePrompt;
