import { Input } from '@material/react-text-field';
import classNames from 'classnames';
import random from 'lodash/random';
import times from 'lodash/times';
import { ReactElement, SyntheticEvent, forwardRef, useCallback, useMemo } from 'react';

import MaterialIcon from 'core/components/MaterialIcon';

import { StyledTextfield } from './styled';

interface OwnProps {
  onChange?: (
    value: string | null,
    e: SyntheticEvent<HTMLInputElement | HTMLTextAreaElement>
  ) => void;
  value?: string | number | null;
  trailingIcon?: ReactElement;
  textarea?: boolean;
  error?: boolean;
  dense?: boolean;
  icon?: string;
  preventAutoComplete?: boolean;
  label: string;
  placeholder?: string;
}

type InputProps = Omit<
  Input<HTMLInputElement | HTMLTextAreaElement>['props'],
  'value' | 'onChange'
>;
export type Props = InputProps & OwnProps;

const Textfield = forwardRef<Input, Props>(
  (
    {
      type = 'text',
      trailingIcon,
      onChange,
      textarea,

      label,
      value,
      icon,
      error,
      dense,
      placeholder,
      preventAutoComplete,
      ...props
    },
    ref
  ) => {
    const handleChange = useCallback(
      (e: SyntheticEvent<HTMLInputElement | HTMLTextAreaElement>) => {
        const target = e.target as HTMLInputElement | HTMLTextAreaElement;
        if (onChange) {
          onChange(target.value === '' ? null : target.value, e);
        }
      },
      [onChange]
    );

    // Random 16-char identifier used if we should bust the autocomplete
    // autocomplete=off doesn't work in the current browsers
    const autoCompleteBuster = useMemo(() => times(16, () => random(35).toString(36)).join(''), []);
    const autoComplete =
      props.autoComplete || (preventAutoComplete ? autoCompleteBuster : undefined);

    const input = (
      <Input<HTMLInputElement | HTMLTextAreaElement>
        onChange={handleChange}
        value={value === null || value === undefined ? '' : value}
        type={type}
        placeholder={placeholder}
        autoComplete={autoComplete}
        {...props}
        // @ts-ignore String ref doesn't seem typed properly
        ref={ref}
      />
    );

    return (
      <StyledTextfield
        leadingIcon={icon ? <MaterialIcon icon={icon} /> : undefined}
        className={classNames({ 'mdc-text-field--invalid': error })}
        trailingIcon={trailingIcon || undefined}
        textarea={textarea}
        label={label}
        dense={dense}
        outlined
      >
        {input}
      </StyledTextfield>
    );
  }
);

export default Textfield;
