import { useCallback } from 'react';

import { mapMember } from '../TeamTab/useTeamOutputMapping';

import { OfficersFormData } from './useOfficersInputMapping';

/**
 * Turns invitedMembers/assignedMembers into:
 * - teamMembers
 */

const useOfficersOutputMapping = (disableTeamMembers: boolean = false) => {
  return useCallback(
    (data: OfficersFormData) => {
      // Join assigned members with invited members, group them together

      return {
        invitedMembers: undefined,
        assignedMembers: undefined,
        ...(disableTeamMembers
          ? {
              // For situations like bulk edit, we must send these fields for proper handling
              teamMembers: undefined,
              dcos: data.dcos || [],
              bcos: data.bcos || [],
              chaperones: data.chaperones || [],
              leadDcosId: data.leadDcosId || null,
            }
          : {
              dcos: undefined,
              bcos: undefined,
              chaperones: undefined,
              leadDcosId: undefined,
              teamMembers: [
                ...data.invitedMembers.map((it) => ({ ...mapMember(it) })),
                ...data.assignedMembers.map((it) => ({
                  ...mapMember(it),
                })),
              ],
            }),
      };
    },
    [disableTeamMembers]
  );
};

export default useOfficersOutputMapping;
