import styled from 'styled-components/macro';

import theme from 'app/theme';

export const PopoverContent = styled.div`
  padding: 0.6rem;
  margin: 0.5rem 0;
  &:empty {
    display: none;
  }
`;

export const PopoverTitle = styled.div<{ $color?: string; $fontSize?: string }>`
  color: ${(p) => p.$color || theme.color.baseFontColor};
  font-size: ${(p) => p.$fontSize || '0.8rem'};
`;

export const PopoverDescriptionItem = styled.div<{ $color?: string }>`
  color: ${(p) => p.$color || theme.color.textSecondary};
  font-size: 0.75rem;
  display: flex;
  align-items: end;
  span:first-child {
    margin-right: 0.25rem;
  }
`;

export const PopoverIcon = styled.div`
  display: flex;
  justify-content: end;
  max-height: 20px;

  span {
    font-size: 1rem;
  }

  & > a {
    margin-left: 1rem;
  }
`;

export const PopoverTarget = styled.span`
  display: flex;
  align-items: baseline;
  justify-content: start;
  width: fit-content;
  max-width: 100%;

  &:first-child {
    margin-right: 0.5rem;
  }
  & > .material-icons {
    flex-shrink: 0;
  }
`;
