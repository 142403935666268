import { useMemo } from 'react';
import { useTranslation } from 'react-i18next';

import useMultiDropdownOptionsFilter from 'core/hooks/useMultiDropdownOptionsFilter';
import { TestSituation } from 'planning/models/Test';

export const useTestSituationOptions = () => {
  const { t } = useTranslation();

  const testSituationOptions = useMemo(() => {
    return [
      {
        id: 'any',
        name: t('Any'),
      },
      {
        id: TestSituation.IC,
        name: t('IC'),
      },
      {
        id: TestSituation.OC,
        name: t('OC'),
      },
      {
        id: TestSituation.TC,
        name: t('TC'),
      },
    ];
  }, [t]);

  return testSituationOptions;
};

export const useTestSituationsFilter = () => {
  const { t } = useTranslation();
  const testSituationOptions = useTestSituationOptions();

  return useMultiDropdownOptionsFilter(
    t('Test Situation'),
    'testSituationsId',
    testSituationOptions
  );
};
