import { toast } from 'react-toastify';
import { put, take, race } from 'redux-saga/effects';

import {
  DeleteDialogEntityAction,
  TYPE_DELETE_DIALOG_ENTITY,
  SaveDialogEntityAction,
  TYPE_SAVE_DIALOG_ENTITY,
} from 'core/actions';
import { apiCall } from 'core/actions';
import { t } from 'core/i18n';

export function* saveDialogEntitySaga(action: SaveDialogEntityAction) {
  const { endpoint, formData, prevFormData, successCallback, successMessage } = action.payload;

  if (prevFormData) {
    // Update
    yield put(
      apiCall(
        TYPE_SAVE_DIALOG_ENTITY,
        'PATCH',
        endpoint,
        { ...formData },
        {},
        true,
        prevFormData,
        []
      )
    );
  } else {
    // Create
    yield put(
      apiCall(TYPE_SAVE_DIALOG_ENTITY, 'POST', endpoint, formData, {
        headers: {
          Accept: 'application/json',
          'Content-Type': 'application/json',
        },
      })
    );
  }

  const { success } = yield race({
    success: take(`${TYPE_SAVE_DIALOG_ENTITY}_SUCCESS`),
    error: take(`${TYPE_SAVE_DIALOG_ENTITY}_ERROR`),
  });

  if (success && successMessage !== null) {
    successCallback && successCallback();

    toast.success(
      successMessage || (prevFormData ? t('Successfully edited') : t('Successfully added'))
    );
  }
}

export function* deleteDialogEntitySaga(action: DeleteDialogEntityAction) {
  const { endpoint, successCallback } = action.payload;

  yield put(apiCall(TYPE_DELETE_DIALOG_ENTITY, 'DELETE', endpoint, {}));

  const { success } = yield race({
    success: take(`${TYPE_DELETE_DIALOG_ENTITY}_SUCCESS`),
    error: take(`${TYPE_DELETE_DIALOG_ENTITY}_ERROR`),
  });

  if (success) {
    successCallback && successCallback();
    toast.success(t('Successfully deleted'));
  }
}
