import { ColDef, RowNode } from 'ag-grid-community';
import { ReactElement, ReactNode, useMemo, useState } from 'react';

import useUrlMultiState from 'core/hooks/useUrlMultiState';

import { BulkAction, Filter, FilterValue } from '..';
import { COLUMNS_DEFAULT_VALUE, UrlColumnConfiguration } from '../hooks/useColumnConfiguration';
import useDatagridUserViews from '../hooks/useDatagridUserViews';
import { SelectedRows } from '../props';

import BulkActions from './BulkActions';
import Counts from './Counts';
import CustomizeViews from './CustomizeViews';
import { VIEW_URL_KEYS } from './CustomizeViews/Header';
import { CustomColumnConfiguration } from './CustomizeViews/tabs/Columns';
import { ViewTabs } from './CustomizeViews/useViewTabs';
import FavoriteViews from './FavoriteViews';
import ListViews from './ListViews';
import { Container } from './styled';
import ViewChanges from './ViewChanges';

interface Props {
  columnDefs: ColDef<any>[];
  originalColumnDefs: ColDef<any>[];
  columnConfiguration: CustomColumnConfiguration;
  filters?: { [id: string]: Filter };
  filterValues: { [key: string]: FilterValue };
  defaultFilterValues: { [key: string]: FilterValue };
  selectedRows: SelectedRows;
  bulkActions?: BulkAction[];
  counts?: { filtered: number; total: number | null };
  disableTotalCount?: boolean;
  endpoint: string;
  highlightDuplicateRowsBy?: string;
  disableSettings?: boolean;
  customToolbarElement?: ReactNode;
  getSelectedNodes: () => RowNode[];
  onBulkSuccess: () => void;
  onFiltersAndColumnsChange: (
    filters: { [key: string]: FilterValue } | undefined,
    columnConfiguration: UrlColumnConfiguration
  ) => void;
  customSettings?: ReactNode;
}

export const COUNTS_PORTAL_ID = 'datagridCounts';

enum DIALOG_OPEN {
  NONE,
  CUSTOMIZE_VIEWS,
  LIST_VIEWS,
}
function Toolbar({
  counts,
  filters,
  endpoint,
  columnDefs,
  bulkActions,
  selectedRows,
  filterValues,
  onBulkSuccess,
  defaultFilterValues,
  customSettings,
  onFiltersAndColumnsChange,
  disableSettings,
  getSelectedNodes,
  disableTotalCount,
  columnConfiguration,
  customToolbarElement,
  highlightDuplicateRowsBy,
  originalColumnDefs,
}: Props): ReactElement {
  const [dialogOpen, setDialogOpen] = useState<DIALOG_OPEN>(DIALOG_OPEN.NONE);
  const [defaultCustomizeTab, setDefaultCustomizeTab] = useState<ViewTabs>(ViewTabs.FILTERS);
  const configuredFilters = useMemo(() => new Set(Object.keys(filters || {})), [filters]);
  const [userViews, reloadUserViews] = useDatagridUserViews(endpoint, configuredFilters);

  const { urlStatesValues: viewConfiguration }: { urlStatesValues: UrlColumnConfiguration } =
    useUrlMultiState(VIEW_URL_KEYS, COLUMNS_DEFAULT_VALUE);

  return (
    <>
      <Container activated={selectedRows === 'ALL' || selectedRows.length > 0}>
        {selectedRows === 'ALL' || selectedRows.length > 0 ? (
          <BulkActions
            selectedRows={selectedRows}
            bulkActions={bulkActions}
            onBulkSuccess={onBulkSuccess}
            getSelectedNodes={getSelectedNodes}
          />
        ) : (
          <>
            <ViewChanges
              onApplyView={onFiltersAndColumnsChange}
              reloadUserViews={reloadUserViews}
              userViews={userViews}
              onOpen={(tab) => {
                setDefaultCustomizeTab(tab || ViewTabs.FILTERS);
                setDialogOpen(DIALOG_OPEN.CUSTOMIZE_VIEWS);
              }}
              filters={filters}
              defaultFilterValues={defaultFilterValues}
              entity={endpoint}
              originalColumnDefs={originalColumnDefs}
            />

            <FavoriteViews userViews={userViews} onApplyView={onFiltersAndColumnsChange} />

            <ListViews
              onApplyView={onFiltersAndColumnsChange}
              reloadUserViews={reloadUserViews}
              userViews={userViews}
              onOpenEditView={() => {
                setDefaultCustomizeTab(ViewTabs.DETAILS);
                setDialogOpen(DIALOG_OPEN.CUSTOMIZE_VIEWS);
              }}
              open={dialogOpen === DIALOG_OPEN.LIST_VIEWS}
              setOpen={(v) => setDialogOpen(v ? DIALOG_OPEN.LIST_VIEWS : DIALOG_OPEN.NONE)}
              viewConfiguration={viewConfiguration}
            />

            {customToolbarElement ? (
              customToolbarElement
            ) : (
              <Counts
                total={counts?.total}
                filtered={counts?.filtered}
                disableTotalCount={disableTotalCount}
                portalId={COUNTS_PORTAL_ID}
              />
            )}
          </>
        )}
      </Container>

      {filters && (
        <CustomizeViews
          open={dialogOpen === DIALOG_OPEN.CUSTOMIZE_VIEWS}
          filters={filters}
          defaultFilterValues={defaultFilterValues}
          filterValues={filterValues}
          endpoint={endpoint}
          onFiltersAndColumnsChange={onFiltersAndColumnsChange}
          onClose={() => {
            setDefaultCustomizeTab(ViewTabs.FILTERS);
            setDialogOpen(DIALOG_OPEN.NONE);
          }}
          columnDefs={columnDefs}
          highlightDuplicateRowsBy={highlightDuplicateRowsBy}
          columnConfiguration={columnConfiguration}
          customSettings={customSettings}
          disableSettings={!!disableSettings}
          defaultTab={defaultCustomizeTab}
          onOpenAllViews={() => {
            setDialogOpen(DIALOG_OPEN.LIST_VIEWS);
            setDefaultCustomizeTab(ViewTabs.FILTERS);
          }}
          viewConfiguration={viewConfiguration}
        />
      )}
    </>
  );
}

export default Toolbar;
