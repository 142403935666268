import { useCallback } from 'react';

import { Settings } from 'planning/models/Mission';

import useSettingsSchema from './useSettingsSchema';

const useSettingsInputMapping = () => {
  const settingsSchema = useSettingsSchema('settings');

  return useCallback(
    (fd: Settings) => {
      return settingsSchema.cast(
        {
          ...fd,
          fileTemplatesId: fd.fileTemplatesId || 'default',
          useCustomFileTemplate: !!fd.fileTemplatesId,
          useAdamsAthleteLevel: !!fd.adamsAthleteLevelsId,
        },
        { stripUnknown: true }
      );
    },
    [settingsSchema]
  );
};

export default useSettingsInputMapping;
