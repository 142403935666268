import { FC } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch } from 'react-redux';
import * as Yup from 'yup';

import Button from 'core/components/Button';
import Form from 'core/components/Form';
import Modal from 'core/components/Modal';
import { useDetail } from 'core/containers/FormDetailPage/DetailContext';
import { useSetModal } from 'core/hooks/useModal';
import { TestBulkCreateOptions, testBulkCreate } from 'planning/actions';
import useMissionTests from 'planning/hooks/useMissionTests';
import Mission from 'planning/models/Mission';

import { MissionModal, useMissionModal } from '../hooks/useMissionModal';
import CreateTestsFieldset from '../tabs/Detail/CreateTestsFieldset';

const CreateTestByGenderModal: FC = () => {
  const dispatch = useDispatch();
  const isOpen = useMissionModal(MissionModal.CREATE_TEST_BY_GENDER);
  const setModal = useSetModal();
  const { t } = useTranslation();
  const { id: missionsId } = useDetail<Mission>();
  const { dispatch: refetchTests } = useMissionTests(missionsId, false);

  const bulkCreateTests = (testBulkCreateOptions: TestBulkCreateOptions) => {
    dispatch(
      testBulkCreate(missionsId!, testBulkCreateOptions, () => {
        refetchTests();
      })
    );
  };

  const schema = Yup.object().shape({
    maleCount: Yup.number().nullable().default(0),
    femaleCount: Yup.number().nullable().default(0),
    otherCount: Yup.number().nullable().default(0),
  });

  return (
    <Modal
      ariaLabel={t('Create Tests by Gender')}
      title={t('Create Tests by Gender')}
      open={isOpen}
      onClose={() => {
        setModal(undefined);
      }}
      confirmButton={
        <Button
          form="create-tests-by-gender"
          type="submit"
          text={t('Confirm')}
          onClick={() => {
            setModal(undefined);
          }}
        />
      }
      clearButton={<Button form="create-tests-by-gender" type="reset" text={t('Clear')} />}
    >
      <Form
        initialValues={{
          maleCount: undefined,
          femaleCount: undefined,
          otherCount: undefined,
        }}
        validationSchema={schema}
        id="create-tests-by-gender"
        onSubmit={(values) => {
          bulkCreateTests &&
            bulkCreateTests({
              male: Number(values.maleCount) || 0,
              female: Number(values.femaleCount) || 0,
              other: Number(values.otherCount) || 0,
            });
        }}
      >
        <CreateTestsFieldset />
      </Form>
    </Modal>
  );
};

export default CreateTestByGenderModal;
