import { PersistedState } from 'redux-persist';

import { CoreState } from 'core/reducer';

type PersistedCoreState = PersistedState & {
  isLoggedIn: CoreState['isLoggedIn'];
  language: CoreState['language'];
  locale: CoreState['locale'];
  dataGridColumnConfiguration: CoreState['dataGridColumnConfiguration'];
};

/**
 * Reducer that gets persisted state and should return new migrated state that will be applied
 */
const persistMigrations: {
  [version: number]: (state?: PersistedCoreState) => PersistedCoreState | undefined;
} = {
  0: (state) => state,
  /**
   * - Dropped old Datagrid column configuration of enabled columns for object of properties
   */
  2: (state) =>
    state
      ? {
          ...state,
          dataGridColumnConfiguration: undefined,
        }
      : undefined,
  /**
   * - Removed compression transform, so the whole store needs to be dropped as it is gibberish now
   */
  3: (state) =>
    state
      ? {
          ...state,
          isLoggedIn: undefined,
          language: undefined,
          dataGridColumnConfiguration: undefined,
          user: undefined,
        }
      : undefined,
};

export default persistMigrations;
