import { useCallback, useMemo } from 'react';
import { useDispatch } from 'react-redux';

import { loadProfile, saveView } from 'core/actions';
import useAppSelector from 'core/hooks/useAppSelector';
import useUrlMultiState from 'core/hooks/useUrlMultiState';
import { UserView } from 'core/models/UserView';

import { COLUMNS_DEFAULT_VALUE, UrlColumnConfiguration } from '../../hooks/useColumnConfiguration';
import { getUrlConfiguration } from '../../hooks/useDefaultViewConfiguration';
import { FilterValue } from '../../props';

import { VIEW_URL_KEYS } from './Header';
import useSchema, { FormData } from './useSchema';

const useSaveView = (
  entity: string,
  onApplyView: (
    filters: { [key: string]: FilterValue } | undefined,
    columnConfiguration: UrlColumnConfiguration
  ) => void,
  userViews: UserView[],
  reloadUserViews: () => void,
  successCallback?: () => void
) => {
  const usersId = useAppSelector((s) => s.core.user!.id);

  const dispatch = useDispatch();
  const schema = useSchema();
  const { urlStatesValues: viewConfiguration } = useUrlMultiState(
    VIEW_URL_KEYS,
    COLUMNS_DEFAULT_VALUE
  );

  const activeView = useMemo(() => {
    if (!viewConfiguration?.activeView) {
      return null;
    }

    return (userViews || []).find((it) => it.id === Number(viewConfiguration.activeView));
  }, [viewConfiguration, userViews]);

  return useCallback(
    (
      values: FormData,
      hideColumns: { [key: string]: boolean },
      filterValues: { [key: string]: FilterValue }
    ) => {
      const newViewConfiguration = {
        ...(viewConfiguration?.duplicity ? { duplicity: viewConfiguration.duplicity } : {}),
        ...(viewConfiguration?.pinned ? { pinned: viewConfiguration.pinned } : {}),
        ...(viewConfiguration?.width ? { width: viewConfiguration.width } : {}),
        ...(viewConfiguration?.position ? { position: viewConfiguration.position } : {}),
        ...(viewConfiguration?.sort ? { sort: viewConfiguration.sort } : {}),
        filters: filterValues,
        hide: hideColumns,
      };

      const previousData = activeView
        ? { ...schema.cast(activeView, { stripUnknown: true }), entity, data: null }
        : undefined;

      dispatch(
        saveView(
          {
            ...values,
            entity,
            // hide is also part of the viewConfiguration, but we should also save new changes
            data: !activeView || usersId === activeView?.createdBy ? newViewConfiguration : null,
            ...(!activeView ? { favorite: true } : {}),
          },
          activeView?.id || undefined,
          previousData,
          (userView) => {
            reloadUserViews();
            // We have to reload profile with defaults to reflect it within this session
            dispatch(loadProfile());

            if (userView) {
              const { filters, ...rest } = userView.data;
              onApplyView(filters, getUrlConfiguration(rest, userView?.id));
            }

            successCallback && successCallback();
          }
        )
      );
    },
    [
      dispatch,
      reloadUserViews,
      entity,
      activeView,
      viewConfiguration,
      onApplyView,
      schema,
      usersId,
      successCallback,
    ]
  );
};

export default useSaveView;
