import { DateTime } from 'luxon';
import { FC, ReactNode, SyntheticEvent } from 'react';
import { useTranslation } from 'react-i18next';
import { useHistory } from 'react-router-dom';

import Tooltip from 'core/components/Tooltip';
import { useFormatRelativeDateTime } from 'core/i18n/useFormatDateTime';

import {
  ItemButton,
  ItemButtons,
  ItemContent,
  ItemFooter,
  ItemIcon,
  ItemLoadingIcon,
  ItemPrimary,
  ItemSpace,
  ItemText,
  ItemWrapper,
  ReadButton,
} from './styled';

export enum NotificationTypeEnum {
  INFO = 'info',
  SUCCESS = 'success',
  ERROR = 'error',
  WARNING = 'warning',
}
type NotificationTypeKey = keyof typeof NotificationTypeEnum;
type NofiticationTypeValue = (typeof NotificationTypeEnum)[NotificationTypeKey];

export interface Notification {
  /**
   * Notification actions visible on expansion
   *
   * Acts as a link when action is a string.
   */
  actions?: Array<{ key: string; text: string; action: string | ((e: SyntheticEvent) => void) }>;
  type: NofiticationTypeValue;
  updatedAt: string | Date;
  secondary: ReactNode;
  inProgress: boolean;
  primary: string;
  read: boolean;
  icon: string;
  id: number;
}

const RenderNotification: FC<{
  onMarkAsRead?: (id?: number) => void;
  onExpand: (id: number) => void;
  onClose: (id: number) => void;
  expanded: boolean;
  item: Notification;
  style: object;
}> = ({ onMarkAsRead, onExpand, expanded, style, item }) => {
  const { secondary, inProgress, ...data } = item;
  const { t } = useTranslation();
  const history = useHistory();
  const formatRelativeDateTime = useFormatRelativeDateTime();

  const Icon = inProgress ? ItemLoadingIcon : ItemIcon;

  const notificationTime = formatRelativeDateTime(
    typeof item.updatedAt === 'string' ? DateTime.fromISO(item.updatedAt) : item.updatedAt
  );

  return (
    <ItemSpace style={style} expanded={expanded}>
      <ItemWrapper
        id={`notification-${item.id}`}
        expanded={expanded}
        aria-expanded={expanded ? 'true' : 'false'}
        onClick={() => onExpand(item.id)}
      >
        <Icon color={data.type === 'info' ? 'gray200' : data.type} icon={data.icon} />

        <ItemContent>
          <ItemPrimary>{item.primary}</ItemPrimary>
          <ItemText expanded={expanded}>{secondary}</ItemText>

          <ItemFooter>
            <time dateTime={notificationTime}>{notificationTime}</time>

            {expanded && (
              <ItemButtons>
                {data.actions?.map((action) => (
                  <ItemButton
                    key={action.key}
                    text={action.text}
                    onClick={(e: SyntheticEvent) => {
                      typeof action.action === 'string' && history.push(action.action);
                      typeof action.action === 'function' && action.action(e);
                    }}
                    dense
                  />
                ))}
              </ItemButtons>
            )}
          </ItemFooter>
        </ItemContent>

        {!data.read && (
          <Tooltip content={t('Mark as Read')}>
            <ReadButton
              onClick={(e) => {
                e.preventDefault();
                e.stopPropagation();
                onMarkAsRead && onMarkAsRead(item.id);
              }}
            />
          </Tooltip>
        )}
      </ItemWrapper>
    </ItemSpace>
  );
};

export default RenderNotification;
