import { FC } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch } from 'react-redux';

import { ConfirmationMessage } from 'core/components/FormStyles';
import Modal from 'core/components/Modal';
import useModal, { useSetModal } from 'core/hooks/useModal';
import { undoMemberStatement } from 'planning/actions';
import { TeamMemberEntities } from 'planning/enums';

export interface Props {
  entity: TeamMemberEntities;
  id: number;
}

export const ConfirmUndoStatementModalId = 'CONFIRM_UNDO_STATEMENT_MODAL';

const ConfirmUndoStatementModal: FC<Props> = ({ entity, id }) => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const setModal = useSetModal();
  const currentlyOpenModal = useModal<string>();
  const handleConfirm = () => {
    dispatch(undoMemberStatement(entity, id));
  };

  return (
    <Modal
      ariaLabel={t('Undo statement dialog')}
      title={t(`Undo statement`)}
      onClose={setModal}
      onConfirm={handleConfirm}
      open={currentlyOpenModal === ConfirmUndoStatementModalId}
    >
      <ConfirmationMessage>
        {t(`Are you sure you want to undo your statement?`)}
      </ConfirmationMessage>
    </Modal>
  );
};

export default ConfirmUndoStatementModal;
