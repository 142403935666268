import { CoreEventType } from 'core/models/CoreEvent';

enum EventType {
  SEND_TO_ADAMS = 0,
  ATHLETE_IMPORT = 1,
  CREATE_CLIENT = CoreEventType.CREATE_CLIENT,

  CREATE_REPORT = 5,

  ORDERS_IMPORT = 6,
  ORDERS_RESULT = 7,

  TEST_UA_REPORT_REVIEW = 9,

  BULK_DOWNLOAD = CoreEventType.BULK_DOWNLOAD,

  ORDER_ITEMS_IMPORT = 10,

  GENERATE_TDP_FILE = 11,

  DCO_INVOICE = 12,

  MISSION_TEAM_INVITE_UPDATE = 13,
  TEST_TEAM_INVITE_UPDATE = 14,

  MISSION_TEAM_MEMBER_INVITED = 15,
  TEST_TEAM_MEMBER_INVITED = 16,

  WATCHER_ADDED = CoreEventType.WATCHER_ADDED,
  WATCHER_REMOVED = CoreEventType.WATCHER_REMOVED,
}

export default EventType;
