import { DateTime } from 'luxon';
import { useTranslation } from 'react-i18next';

import { entities } from 'app/entity';
import Event, { EventStatus } from 'app/models/Event';
import {
  Notification,
  NotificationTypeEnum,
} from 'core/components/Notifications/RenderNotification';
import getEnv from 'core/functions/getEnv';
import { useFormatDateTimeRange } from 'core/i18n/useFormatDateTime';

export default function useCostItemsImport() {
  const { t } = useTranslation();
  const formatDateTimeRange = useFormatDateTimeRange();

  return (e: Event): Notification => {
    const createdAt =
      e.createdAt &&
      formatDateTimeRange(
        typeof e.createdAt === 'string' ? DateTime.fromISO(e.createdAt) : e.createdAt
      );

    const map = {
      [EventStatus.IN_PROGRESS]: {
        primary: t('Cost Items import submitted'),
        secondary: t('Import from {{createdDateTime}} is being processed.', {
          createdDateTime: createdAt,
        }),

        updatedAt: e.modifiedAt || e.createdAt,
        icon: 'cloud_upload',
        inProgress: true,
        read: e.isRead,
        type: NotificationTypeEnum.INFO,
        id: e.id,
      },

      [EventStatus.FINISHED]: {
        primary: t('Cost Items import completed'),
        secondary: t('Cost Items from {{createdDateTime}} were successfully imported.', {
          createdDateTime: createdAt,
        }),

        updatedAt: e.modifiedAt || e.createdAt,
        icon: 'cloud_upload',
        inProgress: false,
        read: e.isRead,
        type: NotificationTypeEnum.SUCCESS,
        id: e.id,
      },

      [EventStatus.WARNING]: {
        primary: t('Cost Items import partially completed'),
        secondary: t(
          'Cost Items were partially ({{imported}}/{{total}}) imported. Please check the file with skipped errored rows.',
          {
            imported: e.payload.total - e.payload.failed,
            total: e.payload.total,
          }
        ),

        updatedAt: e.modifiedAt || e.createdAt,
        actions: [
          {
            key: 'download',
            text: t('Download file'),
            action: () =>
              window.location.replace(`${getEnv('API_URL')}${entities.cost.api().import(e.id)}`),
          },
        ],
        icon: 'cloud_upload',
        inProgress: false,
        read: e.isRead,
        type: NotificationTypeEnum.WARNING,
        id: e.id,
      },

      [EventStatus.FAILED]: {
        primary: t('Cost Items import failed'),
        secondary: t('Import from {{ createdDateTime }} failed ({{ errorMessage }}).', {
          createdDateTime: createdAt,
          errorMessage: e.payload.message,
        }),

        updatedAt: e.modifiedAt || e.createdAt,
        icon: 'cloud_upload',
        inProgress: false,
        read: e.isRead,
        type: NotificationTypeEnum.ERROR,
        id: e.id,
      },

      [EventStatus.CREATED]: null,
    };

    return map[e.status] || map[EventStatus.IN_PROGRESS];
  };
}
