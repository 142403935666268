import { DateTime } from 'luxon';

import User from 'app/models/User';
import ArchivableModel from 'core/models/Archivable';
import { Gender } from 'core/models/CoreUser';
import Country from 'core/models/Country';
import { DatagridDCOInvoice } from 'finance/models/DCOInvoice';
import Analysis from 'lists/models/Analysis';
import Pool from 'lists/models/Pool';
import Sport from 'lists/models/Sport';
import Athlete from 'personnel/models/Athlete';
import Mission, { MissionStatus, Settings } from 'planning/models/Mission';
import TeamMember from 'planning/models/TeamMember';
import { NotificationArrivalDelayReasons } from 'planning/models/TestResult';

import UAReport from './UAReport';

export enum TestStatus {
  ASSIGNED = 1,
  COMPLETED = 2,
  UNSUCCESSFUL = 3,
  IN_PROGRESS = 4,
  CANCELLED = 5,
  UNASSIGNED = 6,
}

export const orderedTestStatuses: TestStatus[] = [
  TestStatus.UNASSIGNED,
  TestStatus.ASSIGNED,
  TestStatus.IN_PROGRESS,
  TestStatus.COMPLETED,
  TestStatus.UNSUCCESSFUL,
  TestStatus.CANCELLED,
];

export enum TestSituation {
  OC = 1,
  TC = 2,
  IC = 3,
}

export enum AdamsRunStatus {
  IN_PROGRESS = 0,
  FAILED = 1,
  COMPLETED = 2,
}

export const getTestSituationName = (situation: TestSituation) => {
  switch (situation) {
    case TestSituation.OC:
      return 'OC';
    case TestSituation.TC:
      return 'TC';
    case TestSituation.IC:
      return 'IC';
    default:
      return 'Unknown';
  }
};

export const getUserTeamMemberStatus = (test?: Test, user?: User) => {
  if (!test || !user) {
    return undefined;
  }
  const teamMember = test.teamMembers?.find((member) => member.usersId === user.id);
  return teamMember?.status;
};

export interface TestDatagrid extends ArchivableModel {
  language: string | null;
  missionsId: number;
  timeZonesId: number;
  missionCode: string;
  missionExternalId: string | null;
  missionInternalComments: string | null;
  missionDateFrom: Date | null;
  missionDateTo: Date | null;
  missionStatus: MissionStatus;
  eventsId: number | null;
  ordersId: number | null;
  costsId: number | null;
  testAaReportsId: string | null;
  testDcoReportsIds: string | null;
  testReportsIds: string | null;
  testUaReportsIds: string | null;
  testReturnsIds: string | null;
  testReturnsStatus: number | null;
  cancelReason: number | null;
  cancelComment: string | null;
  arrivalDelayReason: NotificationArrivalDelayReasons | null;
  arrivalDelayComment: string | null;
  testSituationsId: number;
  athleteName: string | null;
  athleteGender: Gender;
  athletesId: number | null;
  athletesAdamsId: string | null;
  athletesExternalId: string | null;
  athletesNationalityId: number | null;
  athletesNationality: string | null;
  athleteDateOfBirth: Date | null;
  athletesMastersId: number | null;
  athletesDisabilitiesId: number | null;
  athletesDisabilityComment: string | null;
  /**
   * A semicolon-separated list of comma-separated steps.
   *
   * Example: g,n;g,n,u,b means there are two different sets of steps
   * Example: g,n,a,u,b,e,co,c means there is only one set of steps
   */
  resultSteps: string | null;
  resultAthleteAdamsId: string | null;
  resultAdamsRunStatus: AdamsRunStatus | null;
  resultBloodSamplesTotal: number;
  resultBloodSamplesSent: number;
  resultUrineSamplesTotal: number;
  resultUrineSamplesSent: number;
  resultSamplesTotal: number;
  resultSamplesSent: number;
  shipmentStatus: 'done' | 'open' | 'none';
  race: string | null;
  place: string | null;
  dcos: string | null;
  dcosId: number[] | null;
  bcos: string | null;
  bcosId: number[] | null;
  dateFrom?: Date | null;
  dateTo?: Date | null;
  dcoDateFrom: Date | null;
  dcoDateTo: Date | null;
  resultPerformedAt: DateTime | null;
  resultCreatedAt: Date | null;
  uaStatus: UAReport['status'] | null;
  uaDoneDate: Date | null;
  doneDate: Date | null;
  notifiedAt: Date | null;
  dateFromBuffer: number | null;
  dateToBuffer: number | null;
  plannedAt: DateTime | null;
  countriesId: number | null;
  countriesName: string | null;
  street: string | null;
  address: string | null;
  city: string | null;
  state: string | null;
  zip: string | null;
  generalComments: string | null;
  federationsId: number | null;
  federationsName: string | null;
  testingAuthoritiesId: number;
  testingAuthoritiesName: string | null;
  initiatorAuthoritiesId: number | null;
  initiatorAuthoritiesName: string | null;
  externalId: string | null;
  sportsId: number | null;
  sportsName: string | null;
  sportDisciplinesId: number | null;
  sportDisciplinesName: string | null;
  avatarStorageKey: string | null;
  assigneesId: number | null;
  assigneesName: string | null;
  tags?: null | string[];
  analysesNames: string | null;
  bloodAnalysesCount: number | null;
  urineAnalysesCount: number | null;
  status: TestStatus;
  sentToAdams: boolean;
  finalTestResultsId: number | null;
  dcoInvoicesCount: number;
  invoicingCodesId: number | null;
  invoicingCodeCode: string | null;
  debtorsId: number | null;
  debtorsName: string | null;
  customersId: number | null;
  customersName: string | null;
}

export default interface Test extends Settings, ArchivableModel {
  timeZonesId: number;
  paperOnly: boolean;
  assigneesId: number | null;
  assignee: User;
  bloodComments: string | null;
  bloodLabsId: number | null;
  bloodExpedited: boolean;
  bloodLts: boolean;
  city: string | null;
  closedAt: string | null;
  code: string;
  countriesId: number | null;
  dateFrom: Date | null;
  dateTo: Date | null;
  timeFrom: string | null;
  timeTo: string | null;
  plannedAt: DateTime | null;
  publishAt: DateTime | null;
  notifyOfTeamUpdates: boolean;
  showAthleteToDco: boolean;
  showInternalCommentToDco: boolean;
  eventName: string | null;
  federation?: {
    fullName: string | null;
    shortName: string | null;
  };
  federationsId: number | null;
  federationsName: string | null;
  generalComments: string | null;
  internalComments: string | null;
  openedAt: string | null;
  place: string | null;
  race: string | null;
  status: TestStatus;
  testCount: number | null;
  testSituationsId: number;
  urineComments: string | null;
  urineLabsId: number | null;
  urineExpedited: boolean;
  urineLts: boolean;
  sentToAdams: boolean;
  club: string | null;
  team: string | null;
  backNumber: string | null;
  dcos: User[];
  bcos: User[];
  teamMembers?: TeamMember[];
  urineAnalyses: Analysis[];
  bloodAnalyses: Analysis[];
  missionsId: number;
  mission: Mission;
  athletesId: number | null;
  athlete?: Athlete;
  poolsId: number | null;
  sportDisciplinesId: number | null;
  leadDcosId: number | null;
  leadDco: User | null;
  street: string | null;
  address: string | null;
  zip: string | null;
  state: string | null;
  testAuthority: number;
  resultAuthority: number;
  sampleAuthoritiesId: number | null;
  initiatorAuthoritiesId: number | null;
  externalId: string | null;
  oneHourSlot: 'in' | 'out' | null;
  finalTestResultsId: number | null;
  testResult?: { [key: string]: any };
  sportsId: null | number;
  createLocation: boolean;
  locationName: string | null;
  locationsId: number | null;
  pool: null | Pool;
  testUaReportsId: null | number;
  testUaReport: UAReport | null;
  ordersId: number | null;
  costsId: number | null;
  dcfStorageKey: string | null;
  dcoInvoices?: DatagridDCOInvoice[];
  use3genApp: null | boolean;
  allowIcPoolCollection: boolean | null;
  testReturnsStatus: number | null;
  debtorsId: number | null;
  debtorsName: string | null;
  customersId: number | null;
  customersName: string | null;
  sport?: Sport | null;
  sportDiscipline?: Sport | null;
  country?: Country | null;
  billableByDco: boolean;
  billableToCustomer: boolean;
}

export interface DcoReportTest {
  id: number;
  missionsId: number;
  archived: boolean;
  eventName: string | null;
  place: string | null;
  testSituationsId: number;
  finalTestResultsId: number | null;

  teamMembers: TeamMember[];

  mission: {
    id: number;
    code: string;
  };
  athlete?: {
    id: number;
    fullName: string;
  };
  assignee?: {
    id: number;
    fullName: string;
  };
  testsAuthority?: {
    id: number;
    shortName: string;
  };
  testResult?: {
    dcosId: number;
    language: string;
  };
  resultsAuthority?: {
    id: number;
    shortName: string;
  };
  federation?: {
    id: number;
    shortName: string;
  };
}

export interface UaReportTest {
  id: number;
  missionsId: number;
  archived: boolean;
  status: TestStatus;
  eventName: string | null;
  athletesId: number | null;
  poolsId: number | null;

  teamMembers: TeamMember[];

  mission: {
    code: string;
  };
  athlete?: {
    id: number;
    fullName: string;
  };
  assignee?: {
    id: number;
    fullName: string;
  };
  testsAuthority?: {
    id: number;
    shortName: string;
  };
  federation?: {
    id: number;
    shortName: string;
  };
  pool?: null | Pool;
  testResult?: {
    dcosId: number;
    language: string;
  };
  billableByDco: boolean;
  billableToCustomer: boolean;
}
