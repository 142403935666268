import { DateTime } from 'luxon';
import { FC, useCallback, useState } from 'react';
import { useTranslation } from 'react-i18next';

import { useLocale } from 'core/i18n/useLocale';

import MonthNavigation from '../MonthNavigation';
import YearSelector from '../YearSelector';

import { StyledDay, StyledWrapper, StyledYear } from './styled';

interface Props {
  onChange: (value: Date) => void;
  selection?: Date | Date[];
  fromMonth?: Date;
  toMonth?: Date;
  date: Date;
  range?: boolean;
}

const CalendarHeader: FC<Props> = ({ selection, fromMonth, onChange, toMonth, date, range }) => {
  const [yearsOverlay, setYearsOverlay] = useState(false);
  const { t } = useTranslation();

  const locale = useLocale();
  const getFormattedDay = useCallback(
    (d: Date) =>
      DateTime.fromJSDate(d).setLocale(locale).toLocaleString({ month: 'long', day: 'numeric' }),
    [locale]
  );

  fromMonth = fromMonth || new Date('1900-01-01');
  toMonth = toMonth || new Date('2100-01-01');

  const years = [];
  for (let i = fromMonth.getFullYear(); i <= toMonth.getFullYear(); i += 1) {
    years.push(i);
  }

  let selectionHr;
  if (selection === undefined) {
    selectionHr = t('Date Selection');
  } else {
    selectionHr = Array.isArray(selection)
      ? `${getFormattedDay(selection[0])} - ${getFormattedDay(selection[1])}`
      : getFormattedDay(selection);
  }

  const handleChangeYear = useCallback(
    (date: Date) => {
      setYearsOverlay(false);
      onChange(date);
    },
    [setYearsOverlay, onChange]
  );

  return (
    <>
      <StyledWrapper>
        <StyledYear
          onClick={() => setYearsOverlay(true)}
          className="DayPicker-Year"
          chosen={yearsOverlay}
          tabIndex={0}
        >
          {date.getFullYear()}
        </StyledYear>
        <StyledDay
          onClick={() => setYearsOverlay(false)}
          className="DayPicker-Result"
          chosen={!yearsOverlay}
          pointer={yearsOverlay}
          tabIndex={0}
        >
          {selectionHr}
        </StyledDay>
        <MonthNavigation
          className="DayPicker-MonthNavigation"
          date={date}
          onChange={onChange}
          range={range}
        />
      </StyledWrapper>
      {yearsOverlay && <YearSelector years={years} date={date} onChange={handleChangeYear} />}
    </>
  );
};

export default CalendarHeader;
