import Active from './Active';

export default interface CoreEvent<EventType = CoreEventType, P = any>
  extends Omit<Active, 'createdAt' | 'modifiedAt'> {
  usersId: number;
  isRead: boolean;
  status: EventStatus;
  type: EventType;
  payload: P;
  createdAt: string | Date;
  modifiedAt: string | Date;
}

export enum CoreEventType {
  CREATE_CLIENT = 2,
  BULK_DOWNLOAD = 8,
  WATCHER_ADDED = 17,
  WATCHER_REMOVED = 18,
}

export enum EventStatus {
  CREATED = 0,
  IN_PROGRESS = 1,
  FINISHED = 2,
  FAILED = 3,
  WARNING = 4,
}
