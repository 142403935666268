import { useCallback, useMemo } from 'react';
import { useTranslation } from 'react-i18next';

import usePermission, { useGetPermission } from 'core/hooks/usePermission';
import { coreEntities, EntityMeta } from 'core/module';
import { financeEntities } from 'finance/module';
import { listsEntities } from 'lists/module';
import { personnelEntities } from 'personnel/module';
import { planningEntities } from 'planning/module';
import { settingsEntities } from 'settings/module';
import { translationEntities } from 'translations/module';

export const entities = {
  ...coreEntities,
  ...financeEntities,
  ...listsEntities,
  ...personnelEntities,
  ...planningEntities,
  ...settingsEntities,
  ...translationEntities,
} as const;

// REQUIRED !!!
// eslint-disable-next-line @typescript-eslint/no-unused-vars
const verifyEntities: Record<keyof typeof personnelEntities, EntityMeta> = personnelEntities;

export type EntityKey = keyof typeof entities;

export type EntityMap = typeof entities;

type FilterConditionally<Source, Condition> = Pick<
  Source,
  { [K in keyof Source]: Source[K] extends Condition ? K : never }[keyof Source]
>;

export type NoContextEntityKey = keyof FilterConditionally<typeof entities, { urls: () => any }>;

export const useEntityName = (entity: EntityKey) => {
  const { t } = useTranslation();
  return useMemo(() => entities[entity].name(t), [t, entity]);
};

export const getEntityPermission = (entity: EntityKey): string => entities[entity].permission;

export const useEntityPermission = (entity: EntityKey, action: string): boolean => {
  return usePermission(`${getEntityPermission(entity)}:${action}`);
};

export const useGetEntityPermission = () => {
  const get = useGetPermission();

  return useCallback(
    (entity: EntityKey, action: string) => get(`${getEntityPermission(entity)}:${action}`),
    [get]
  );
};
