import styled from 'styled-components/macro';

import theme from 'app/theme';
import {
  activeClassName,
  activeItemBackground,
} from 'core/containers/Sidebar/Navigation/NavGroup/styled';

export const NavbarWrap = styled.div`
  flex-direction: column;
  overflow-y: auto;
  overflow-x: hidden;
  display: flex;
  flex-grow: 1;
  width: auto;

  .${activeClassName} {
    background: ${activeItemBackground};
  }

  a {
    color: ${theme.color.white};
  }
`;
