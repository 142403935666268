import * as Sentry from '@sentry/browser';
import { Component, ReactNode } from 'react';
import { withTranslation, WithTranslation } from 'react-i18next';

import Button from '../Button';

import { ErrorWrapper } from './styled';

interface OwnProps {
  fullScreen?: boolean;
  includeReload?: boolean;
  children?: ReactNode;
}

type Props = OwnProps & WithTranslation;

class ErrorBoundary extends Component<Props> {
  state = { hasError: false, errorMessage: '' };

  static getDerivedStateFromError(error: Error) {
    // Update state so the next render will show the fallback UI.
    return { hasError: true, errorMessage: error.message };
  }

  componentDidCatch(error: Error) {
    Sentry.captureException(error);
  }

  render() {
    const { t, fullScreen, includeReload } = this.props;

    const onReload = () => window.location.reload();

    if (this.state.hasError) {
      // You can render any custom fallback UI
      return (
        <ErrorWrapper fullScreen={fullScreen}>
          <h1>{t('Something went wrong')}</h1>
          <p>
            {t(`We are sorry, but there was an error in the application we can't
            recover from. We've already been notified about the problem.`)}
          </p>
          <p>{t('Error: "{{message}}".', { message: this.state.errorMessage })}</p>

          {includeReload && <Button text={t('Reload')} onClick={onReload} />}
        </ErrorWrapper>
      );
    }

    return this.props.children;
  }
}

export default withTranslation()(ErrorBoundary);
