import { replace } from 'connected-react-router';
import { toast } from 'react-toastify';
import { delay, put, race, select, take } from 'redux-saga/effects';

import User from 'app/models/User';
import { AppState } from 'app/store';
import { urls } from 'app/urls';
import { t } from 'core/i18n';

import {
  apiCall,
  bootstrapped,
  logout,
  socketStart,
  TYPE_I18N_INITIALIZED,
  TYPE_LOAD_PROFILE,
} from '../actions';

function* bootstrap(initial: boolean) {
  const i18nInitialized: boolean = yield select((state: AppState) => state.core.i18nInitialized);
  if (!i18nInitialized) {
    yield race({
      i18n: take(TYPE_I18N_INITIALIZED),
      timeout: delay(500),
    });
  }

  yield put(apiCall(TYPE_LOAD_PROFILE, 'GET', '/profile', undefined, {}, false));

  const { success, error } = yield race({
    success: take(`${TYPE_LOAD_PROFILE}_SUCCESS`),
    error: take(`${TYPE_LOAD_PROFILE}_ERROR`),
  });

  if (error) {
    const e = error.payload.error;

    if (e.response && e.response.status === 401 && initial) {
      yield put(logout());
      return;
    }

    const message =
      e.response && e.response.data && e.response.data.message
        ? e.response.data.message
        : e.message;
    toast.error(t('Initialization failed: {{message}}.', { message }));
    return;
  }

  if (success) {
    const response = success.payload.response as {
      data: User & { permissions: Array<{ controller: string; action: string }> };
    };

    yield put(bootstrapped(response.data));

    // Redirect to previous screen if this is bootstrap after login
    const prevInternalPathname: string | undefined = yield select(
      (state: AppState) => state.core.prevPathname
    );
    const prevExternalPath = new URLSearchParams(window.location.search).get('prevExternal');
    const currentPathname: string = yield select((s: AppState) => s.router.location.pathname);
    const canSeeDashboard = response.data.permissions.some(
      (p) => p.controller === 'dashboard' && p.action === 'view'
    );
    if (prevExternalPath) {
      window.location.pathname = prevExternalPath;
      return;
    } else if (!initial) {
      yield put(replace(prevInternalPathname || '/'));
    } else if (initial && currentPathname === urls.login) {
      yield put(replace(canSeeDashboard ? urls.dashboard : urls.account));
    }

    yield put(socketStart());
  }
}

export default bootstrap;
