import { useMemo } from 'react';

import getResourcesUrl from 'core/functions/getResourcesUrl';

const useCommentsUrls = (baseUrl: string) => {
  return useMemo(
    () => ({
      entityList: (raw?: boolean) => (raw ? baseUrl : getResourcesUrl(baseUrl)),
      commentsList: (entityId: number, newestFirst?: boolean) =>
        getResourcesUrl(
          `${baseUrl}/${entityId}/comments`,
          newestFirst !== undefined
            ? {
                order: 'createdAt',
                sort: newestFirst ? 'desc' : 'asc',
              }
            : undefined
        ),
      commentsDetail: (entityId: number, commentId: number) =>
        getResourcesUrl(`${baseUrl}/${entityId}/comments/${commentId}`),
    }),
    [baseUrl]
  );
};

export type CommentsUrls = ReturnType<typeof useCommentsUrls>;

export default useCommentsUrls;
