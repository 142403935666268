import { useTranslation } from 'react-i18next';

import { entities } from 'app/entity';
import Event, { EventStatus } from 'app/models/Event';
import {
  Notification,
  NotificationTypeEnum,
} from 'core/components/Notifications/RenderNotification';

export default function useMissionTeamMemberInvitated() {
  const { t } = useTranslation();

  return (e: Event): Notification => {
    const map = {
      [EventStatus.CREATED]: {
        primary: t('You have been invited to a Mission'),
        secondary: t('Mission {{missionCode}} has been offered to you.', {
          missionCode: e.payload.missionsCode,
        }),

        updatedAt: e.modifiedAt || e.createdAt,
        actions: [
          {
            key: 'open',
            text: t('Open Mission'),
            action: entities.mission.urls().detail(e.payload.missionsId),
          },
        ],
        icon: 'groups',
        inProgress: false,
        read: e.isRead,
        type: NotificationTypeEnum.SUCCESS,
        id: e.id,
      },
    };

    return map[EventStatus.CREATED];
  };
}
