import { useTranslation } from 'react-i18next';

import { FlexRow } from 'core/components/FlexUtils';

import Checkbox from '../BulkActions/Checkbox';
import { BulkActionsLogic } from '../BulkActions/useBulkActions';
import DataCardProps, { DataCardAction, DataCardItem } from '../props';
import RowEndAction from '../RowEndAction';
import useRowEndAction from '../RowEndAction/useRowEndAction';

import { ActionFlexCell, Content } from './styled';

interface Props<Item extends DataCardItem> extends DataCardProps<Item> {
  bulkActions?: DataCardAction<Item>[];
  bulkLogic: BulkActionsLogic<Item>;
  selection: Set<number | string>;
  offset?: number;
}

const FieldContent = <Item extends DataCardItem>({
  header = null,
  items,
  children,
  actions = [],
  onRemove,
  isActionable,
  bulkActions,
  bulkLogic,
  selection,
  offset = 0,
}: Props<Item>) => {
  const { t } = useTranslation();

  const rowEndAction = useRowEndAction(actions, onRemove);

  return (
    <Content marginTop={!!bulkActions}>
      {header && (
        <FlexRow verticalAlign="flex-start">
          {bulkActions && (
            <ActionFlexCell block>
              <Checkbox logic={bulkLogic} />
            </ActionFlexCell>
          )}

          {header}
        </FlexRow>
      )}

      {items.map((item, index) => (
        <FlexRow key={item.id} role="group" verticalAlign="flex-start">
          {bulkActions && (!isActionable || isActionable(item)) && (
            <ActionFlexCell block>
              <Checkbox id={item.id.toString()} logic={bulkLogic} />
            </ActionFlexCell>
          )}

          {children(item, index + offset, { selected: selection.has(item.id) })}

          {rowEndAction && (!isActionable || isActionable(item)) && (
            <ActionFlexCell block>
              <RowEndAction action={rowEndAction} item={item} />
            </ActionFlexCell>
          )}
        </FlexRow>
      ))}

      {items.length === 0 && <em>{t('There are no items.')}</em>}
    </Content>
  );
};

export default FieldContent;
