import mapValues from 'lodash/mapValues';
import { darken, lighten } from 'polished';

const breakpoints = {
  mobile: 25,
  tablet: 48,
  desktop: 75,
};

const primary = '#12BC96';
const secondary = '#2a2a2a';
const success = '#28a745';
const success50 = lighten(0.4, success);

const error = '#dc3545';
const error50 = lighten(0.35, error);

const warning = '#ffc107';
const info = '#17a2b8';
const primary700 = darken(0.1, primary);
const primary50 = lighten(0.5, primary);
const primary5 = 'rgba(18, 188, 150, 0.05)';
const secondary800 = lighten(0.05, secondary);
const secondary700 = lighten(0.05, secondary800);
const gray = '#f5f5f5';
const gray100 = '#e8e8e8';
const gray200 = darken(0.05, gray100);
const gray300 = '#9e9e9e';
const gray400 = 'rgba(0, 0, 0, 0.6)';
const white = '#fff';
const black = '#000000';
const burgundy = '#750800';
const darkGray = '#949494';
const white100 = 'rgba(255, 255, 255, 0.1)';

// TODO: Refactor this mess
const theme = {
  color: {
    white,
    white100,
    darkGray,
    black,
    textSecondary: 'rgba(0, 0, 0, 0.54)',
    primary,
    primary700,
    primary50,
    primary5,
    secondary,
    secondary800,
    secondary700,
    secondaryLighter: 'rgba(0, 128, 240, 0.3)',
    gray,
    gray100,
    gray200,
    gray300,
    gray400,
    success,
    success50,
    successBackground: lighten(0.55, success),
    warning,
    warningBackground: lighten(0.45, warning),
    error,
    error50,
    errorBackground: lighten(0.43, error),
    info,
    infoBackground: lighten(0.4, info),
    baseFontColor: '#333',
    burgundy,
    status: {
      white,
      blue: '#0080f0',
      green: '#4caf50',
      orange: '#ff9800',
      red: '#f44336',
      purple: '#8831e0',
      cyan: '#00bcd4',
      gray: '#9e9e9e',
      black: '#1a1b1c',
    },
    statusIcons: {
      blue: 'person_circle',
      gray: 'empty_circle',
      green: 'checkmark_circle',
      orange: 'edit_circle',
      red: 'cancel_circle',
      purple: 'vertical_line_circle',
      cyan: 'arrow_up_circle',
      black: 'paid',
      white: '',
    } as const,
  },
  breakpoints,
  mq: mapValues(breakpoints, (x) => `@media (min-width: ${x}rem)`),
  drawerWidth: '15.625rem',
  drawerColapseWidth: '3.5rem',
  popoverShadow: `0px 5px 5px -3px rgba(0, 0, 0, 0.2), 0px 8px 10px 1px rgba(0, 0, 0, 0.14),
  0px 3px 14px 2px rgba(0, 0, 0, 0.12)`,
  sidebarLogoHeight: null,
  sidebarActiveBackground: '',
};

export type ThemeColor = keyof (typeof theme)['color'];
export type StatusIcon = keyof (typeof theme)['color']['statusIcons'];
export type StatusIconValue = (typeof theme)['color']['statusIcons'][StatusIcon];
export type StatusColor = keyof (typeof theme)['color']['status'];

export default theme;
