import { useMemo } from 'react';
import * as Yup from 'yup';

import { getTabMeta } from 'core/components/Form/TabErrorCollector';
import { MAX_ZIP_LENGTH } from 'core/utils/constant';

export enum CountryRequired {
  ALWAYS = 'always',
  WHEN_OTHER_FIELDS_ARE_FILLED = 'when_other_fields_are_filled',
  NEVER = 'never',
}

type AddressSchemaOptions = {
  countryRequired: CountryRequired;
};

const defaultOptions: AddressSchemaOptions = {
  countryRequired: CountryRequired.WHEN_OTHER_FIELDS_ARE_FILLED,
};
type TabMetaAddressType = 'address' | 'profile' | 'detail';
const tabMeta = getTabMeta<TabMetaAddressType>();

function getCountriesIdSchema(
  countryRequired: CountryRequired,
  sections: TabMetaAddressType[] = ['address']
) {
  const metaTab = sections ? tabMeta(...sections) : {};

  if (countryRequired === CountryRequired.ALWAYS) {
    return Yup.number().nullable().required().default(null).meta(metaTab);
  }

  if (countryRequired === CountryRequired.WHEN_OTHER_FIELDS_ARE_FILLED) {
    return Yup.number()
      .nullable()
      .default(null)
      .when(['street', 'city', 'zip', 'state', 'address'], {
        is: function () {
          const values = Array.prototype.slice.call(arguments, 0, 5).filter(Boolean);
          return values.length > 0;
        },
        then: Yup.number().nullable().required().default(null).meta(metaTab),
      })
      .default(null)
      .meta(metaTab);
  }

  return Yup.number().nullable().default(null).meta(metaTab);
}

/**
 * Address schema
 *
 * @param options
 * - countryRequired: This determines when country is required: always, when other fields are filled or never.
 */
export const useAddressSchema = (
  options: AddressSchemaOptions = defaultOptions,
  sections: TabMetaAddressType[] = ['address']
) => {
  const metaTab = useMemo(() => (sections ? tabMeta(...sections) : {}), [sections]);
  const countriesId = getCountriesIdSchema(options.countryRequired, sections);

  return useMemo(
    () =>
      Yup.object({
        id: Yup.number().nullable().default(null).meta(metaTab),
        type: Yup.string().required().default('main').meta(metaTab),
        street: Yup.string().nullable().default(null).meta(metaTab),
        address: Yup.string().nullable().default(null).meta(metaTab),
        city: Yup.string().nullable().default(null).meta(metaTab),
        zip: Yup.string().nullable().default(null).max(MAX_ZIP_LENGTH).meta(metaTab),
        state: Yup.string().nullable().default(null).meta(metaTab),
        countriesId,
      }).meta(metaTab),
    [countriesId, metaTab]
  );
};

export type UserAddressInput = Yup.TypeOf<ReturnType<typeof useAddressSchema>>;

const useAddressesFieldsSchema = () => {
  const userAddressInput = useAddressSchema();
  return useMemo(() => Yup.array(userAddressInput).ensure().default([]), [userAddressInput]);
};

export default useAddressesFieldsSchema;
