import { createContext, FC, PropsWithChildren, useContext, useState } from 'react';

const ModalContext = createContext<undefined | number | string>(undefined);
const SetModalContext = createContext((_s?: undefined | number | string) => {});

export const ModalProvider: FC<PropsWithChildren> = ({ children }) => {
  const [state, setState] = useState<string | number>();

  return (
    <SetModalContext.Provider value={setState}>
      <ModalContext.Provider value={state}>{children}</ModalContext.Provider>
    </SetModalContext.Provider>
  );
};

export default function useModal<E = number | string>() {
  const open = useContext(ModalContext);
  return open as E | undefined;
}

export const useSetModal = () => {
  return useContext(SetModalContext);
};
