import { useCallback, useMemo } from 'react';
import { useHistory, useLocation } from 'react-router-dom';

import { parse, stringify } from 'core/functions/qs';

type QueryParamValue = string | string[] | { [key: string]: QueryParamValue };

/**
 * Get url query params
 */
export function useQueryParams() {
  const { search } = useLocation();

  return useMemo(
    () => (parse(search.replace(/^\?/, '')) as Record<string, QueryParamValue>) || {},
    [search]
  );
}

/**
 * Get url query param
 */
export function useQueryParam(name: string): QueryParamValue | undefined {
  const obj = useQueryParams();

  return obj[name];
}

export function useSetQueryParam() {
  const params = useQueryParams();
  const history = useHistory();
  const { pathname } = useLocation();

  return useCallback(
    (name: string, value: any) => {
      history.push(pathname + `?` + stringify({ ...params, [name]: value }));
    },
    [history, params, pathname]
  );
}
