import { SavedDataGridParams } from 'core/actions';
import useAppSelector from 'core/hooks/useAppSelector';

const usePreviousDatagridParams = (endpoint: string) => {
  const datagridParams: SavedDataGridParams = useAppSelector(
    (state) => state.core.dataGridEntityParams
  );

  if (datagridParams && datagridParams.endpoint === endpoint) {
    return `${datagridParams.endpoint}${datagridParams.params}`;
  }
  return undefined;
};

export default usePreviousDatagridParams;
