import * as Sentry from '@sentry/browser';
import { Action, Dispatch, MiddlewareAPI } from 'redux';

const identity = <T>(x: T) => x;
const getUndefined = () => undefined;
const getType = <A extends Action>(action: A) => action.type;
const filter = () => true;

export interface Options<S> {
  breadcrumbMessageFromAction?: <A extends Action>(action: A) => string;
  breadcrumbDataFromAction?: <A extends Action>(action: A) => Sentry.Breadcrumb['data'];
  actionTransformer?: <A extends Action>(action: A) => any;
  stateTransformer?: (state: S) => S;
  breadcrumbCategory?: string;
  filterBreadcrumbActions?: <A extends Action>(action: A) => boolean;
  getUserContext?: (state: S) => Sentry.User;
  getTags?: (state: S) => Sentry.Event['tags'];
}

// Inspiration from https://github.com/captbaritone/raven-for-redux by Jordan Eldredge under MIT
function createSentryMiddleware<S, D extends Dispatch>(
  sentry: typeof Sentry,
  options: Options<S> = {}
) {
  const {
    breadcrumbDataFromAction = getUndefined,
    breadcrumbMessageFromAction = getType,
    actionTransformer = identity,
    stateTransformer = identity,
    breadcrumbCategory = 'redux-action',
    filterBreadcrumbActions = filter,
    getUserContext,
    getTags,
  } = options;

  return (store: MiddlewareAPI<D, S>) => {
    let lastAction: Action;
    sentry.addGlobalEventProcessor((event) => {
      const state = store.getState();
      const reduxExtra = {
        lastAction: actionTransformer(lastAction),
        state: stateTransformer(state),
      };
      event.extra = Object.assign(reduxExtra, event.extra || {});
      if (getUserContext) {
        event.user = getUserContext(state);
      }
      if (getTags) {
        event.tags = getTags(state);
      }
      return event;
    });

    return (next: Dispatch) => (action: Action) => {
      if (filterBreadcrumbActions(action)) {
        sentry.addBreadcrumb({
          category: breadcrumbCategory,
          message: breadcrumbMessageFromAction(action),
          data: breadcrumbDataFromAction(action),
        });
      }
      lastAction = action;
      return next(action);
    };
  };
}

export default createSentryMiddleware;
