import { useMemo, FC } from 'react';
import { useTranslation } from 'react-i18next';

import { AccreditationsRole } from 'app/models/Accreditation';
import EmailsDialog, { EmailType, RECIPIENTS } from 'core/containers/EmailsDialog';
import { useDetail } from 'core/containers/FormDetailPage/DetailContext';
import getResourcesUrl from 'core/functions/getResourcesUrl';
import { useSetModal } from 'core/hooks/useModal';
import useAssignedMembers from 'planning/components/TeamTab/useAssignedMembers';
import Mission from 'planning/models/Mission';

import { MissionModal, useMissionModal } from '../hooks/useMissionModal';
import { MissionFormData } from '../hooks/useMissionSchema';

const EMAIL_ROLES = [AccreditationsRole.DCO];

const AppliedEmailsDialog: FC = () => {
  const { entityData } = useDetail<Mission>();

  const open = useMissionModal(MissionModal.EMAILS);
  const setModal = useSetModal();

  const { entityData: missionData } = useDetail<Mission, MissionFormData>();
  const { t } = useTranslation();
  const assignedMembers = useAssignedMembers(missionData?.teamMembers || [], EMAIL_ROLES);

  const recipientParams = useMemo(() => {
    const recipientParams = new Map();
    if (entityData && assignedMembers.assignedMembers?.length) {
      recipientParams.set(RECIPIENTS.DCO, {
        usersId: (assignedMembers.assignedMembers || []).map((it) => it.usersId),
      });
    }
    return recipientParams;
  }, [assignedMembers.assignedMembers, entityData]);

  const emailTypes: EmailType[] = useMemo(
    () => [
      {
        code: 'missionDetails',
        title: t('Mission Details Changed'),
        customRecipients: [RECIPIENTS.DCO],
        withAdditionalMessage: true,
        attachmentsEndpoint: getResourcesUrl(`missions/${entityData?.id}/files`),
      },
    ],
    [t, entityData?.id]
  );

  if (!assignedMembers.assignedMembers?.length) {
    return null;
  }

  return (
    <EmailsDialog
      open={open}
      onClose={setModal}
      recipientParams={recipientParams}
      endpoint={`/missions/${entityData?.id}/sendEmail`}
      emailTypes={emailTypes}
    />
  );
};

export default AppliedEmailsDialog;
