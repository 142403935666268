import { useMemo } from 'react';
import { Redirect, Route, Switch } from 'react-router-dom';

import { ChildrenProps } from 'core/containers/FormDetailPage';
import { useDetail } from 'core/containers/FormDetailPage/DetailContext';
import Base from 'core/models/Base';

import { RouteTabOption } from '.';

interface Props<E> {
  tabs: RouteTabOption[];
  getDefaultUrl?: (props: ChildrenProps<E>) => undefined | string;
}

function RenderTabs<E extends Base = Base>({ tabs, getDefaultUrl }: Props<E>) {
  const props = useDetail<E>();
  const defaultUrl = useMemo(() => getDefaultUrl && getDefaultUrl(props), [getDefaultUrl, props]);

  return (
    <Switch>
      {tabs
        .filter(({ hidden }) => !hidden)
        .map((tab) => (
          <Route key={tab.path} path={tab.path} component={tab.component} />
        ))}

      <Redirect to={defaultUrl || tabs[0].url} />
    </Switch>
  );
}

export default RenderTabs;
