import { DateTime } from 'luxon';
import { useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import * as Yup from 'yup';

type DateRange = {
  from: Date;
  to: Date;
} | null;

const useDateBufferSchema = () => {
  const { t } = useTranslation();

  // this works with only the dateToBuffer, ignores the dateFromBuffer
  const dateFromBufferSchemaOnly = useMemo(
    () =>
      Yup.number()
        .nullable()
        .integer()
        .min(0)
        .max(255)
        .test(
          'outside-range',
          t('Buffers cannot be larger than the date range'),
          function (value, testContext) {
            if (!value) {
              return true;
            }

            const range: DateRange = testContext.parent.dateRange;

            if (!range) {
              return true;
            }

            const dateToBuffer = value;

            const daysInRange = DateTime.fromJSDate(range.to).diff(
              DateTime.fromJSDate(range.from),
              'days'
            );

            return daysInRange.days > dateToBuffer;
          }
        )
        .default(0),
    [t]
  );

  // this considers both dateFromBuffer and dateToBuffer
  const dateFromBufferSchema = useMemo(
    () =>
      Yup.number()
        .nullable()
        .integer()
        .min(0)
        .max(255)
        .test(
          'outside-range',
          t('Buffers cannot be larger than the date range'),
          function (value, testContext) {
            if (!value) {
              return true;
            }

            const range: DateRange = testContext.parent.dateRange;

            if (!range) {
              return true;
            }

            const dateFromBuffer = value;
            const dateToBuffer = testContext.parent.dateToBuffer;

            const daysInRange = DateTime.fromJSDate(range.to).diff(
              DateTime.fromJSDate(range.from),
              'days'
            );

            return daysInRange.days > dateFromBuffer + dateToBuffer;
          }
        )
        .default(0),
    [t]
  );

  // this considers both dateFromBuffer and dateToBuffer
  const dateToBufferSchema = useMemo(
    () =>
      Yup.number()
        .nullable()
        .integer()
        .min(0)
        .max(255)
        .test(
          'outside-range',
          t('Buffers cannot be larger than the date range'),
          function (value, testContext) {
            if (!value) {
              return true;
            }

            const range: DateRange = testContext.parent.dateRange;

            if (!range) {
              return true;
            }

            const dateFromBuffer = testContext.parent.dateFromBuffer;
            const dateToBuffer = value;

            const daysInRange = DateTime.fromJSDate(range.to).diff(
              DateTime.fromJSDate(range.from),
              'days'
            );

            return daysInRange.days > dateFromBuffer + dateToBuffer;
          }
        )
        .default(0),
    [t]
  );

  return { dateToBufferSchema, dateFromBufferSchema, dateFromBufferSchemaOnly };
};

export default useDateBufferSchema;
