import { ReactElement, useCallback, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';

import { Row } from 'core/components/Grid';
import { FullRowCell } from 'core/components/GridCell';
import { CustomFilter, Filter, FilterValue } from 'core/containers/DataGrid';

import { UrlColumnConfiguration } from '../../hooks/useColumnConfiguration';
import { Title } from '../../Toolbar/CustomizeViews/Header/styled';
import { NoPaddingGrid, StyledModal } from '../../Toolbar/CustomizeViews/styled';
import FilterElements from '../../Toolbar/CustomizeViews/tabs/Filters';

interface Props {
  open: boolean;
  filters: { [id: string]: Filter };
  filterValues: { [key: string]: FilterValue };
  defaultFilterValues: { [key: string]: FilterValue };
  onClose: () => void;
  onFiltersAndColumnsChange: (
    filters: { [key: string]: FilterValue } | undefined,
    columnConfiguration: UrlColumnConfiguration
  ) => void;
}
function Filters({
  open,
  filters,
  filterValues: committedFilterValues,
  defaultFilterValues,
  onClose,
  onFiltersAndColumnsChange,
}: Props): ReactElement {
  const { t } = useTranslation();
  const [filterValues, setFilterValues] = useState(committedFilterValues);

  useEffect(() => {
    // Make sure changes from parent are also reflected in filters
    setFilterValues(committedFilterValues);
  }, [committedFilterValues]);

  const handleFilterValueChange = useCallback(
    (id: string, val: FilterValue, autoCommit = false) => {
      if (val === undefined) throw new Error('Filter value cannot be undefined');
      const newValues = { ...filterValues, [id]: val };
      setFilterValues(newValues);
      autoCommit && onFiltersAndColumnsChange(newValues, {});
    },
    [onFiltersAndColumnsChange, filterValues]
  );

  const onConfirm = useCallback(
    () =>
      onFiltersAndColumnsChange(
        Object.keys(filterValues).length === 0 ? undefined : filterValues,
        {}
      ),
    [filterValues, onFiltersAndColumnsChange]
  );

  const handleClose = useCallback(() => {
    onClose();
  }, [onClose]);

  const customFilters = Object.entries(filters).filter(
    ([, filter]) => filter.type === 'custom'
  ) as Array<[string, CustomFilter]>;

  const customFilterElements = customFilters.map(
    ([id, filter]) =>
      'component' in filter &&
      filter.component(
        (value: FilterValue) => handleFilterValueChange(id, value, true),
        filterValues[id] !== undefined ? filterValues[id] : defaultFilterValues[id]
      )
  );

  return (
    <>
      <StyledModal
        ariaLabel={t('Filters dialog')}
        open={open}
        onClose={handleClose}
        onClear={() => {
          setFilterValues({});
        }}
        onCancel={() => {
          setFilterValues(committedFilterValues);
        }}
        onConfirm={onConfirm}
        autoResponsiveWidth={false}
        mountOnEnter={false}
        unmountOnExit={false}
      >
        <NoPaddingGrid>
          <Row>
            <FullRowCell>
              <Title>{t('Filters')}</Title>
            </FullRowCell>

            <FilterElements
              defaultFilterValues={defaultFilterValues}
              filterValues={filterValues}
              filters={filters}
              onFilterValueChange={handleFilterValueChange}
              isModalOpen={open}
              onConfirm={onConfirm}
            />
          </Row>
        </NoPaddingGrid>
      </StyledModal>

      {customFilterElements}
    </>
  );
}

export default Filters;
