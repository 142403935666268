import * as Sentry from '@sentry/browser';
// defaults to localStorage for web and AsyncStorage for react-native
import { connectRouter, RouterState } from 'connected-react-router';
import { History } from 'history';
import { Action, combineReducers, DeepPartial } from 'redux';
import { persistStore, persistReducer, createMigrate } from 'redux-persist';
import storage from 'redux-persist/lib/storage';

import appSaga from 'app/effects';
import coreSaga from 'core/effects';
import getEnv from 'core/functions/getEnv';
import coreReducer, { CoreState } from 'core/reducer';
import persistMigrations from 'core/store/persistMigrations';
import safeSagaSpawn from 'core/store/safeSagaSpawn';
import listsReducer, { ListsState } from 'lists/reducer';
import personnelSaga from 'personnel/effects';
import personnelReducer, { PersonnelState } from 'personnel/reducer';
import planningSaga from 'planning/effects';
import planningReducer, { PlanningState } from 'planning/reducer';
import settingsSaga from 'settings/effects';
import settingsReducer, { SettingsState } from 'settings/reducer';

import prepareStoreDev from './prepareStore.dev';
import prepareStoreProd from './prepareStore.prod';

export interface State<T extends Action = Action> {
  core: CoreState;
  planning: PlanningState;
  personnel: PersonnelState;
  lists: ListsState;
  settings: SettingsState;
  router: RouterState;
  lastAction: T;
}

const host = window.location.hostname
  .split('.')
  .reverse()
  .filter((i) => i !== 'www'); // ignore www
// url must be %client% [ . %env% ] . domain.com = if there is no env info -> its prod
const environment = host.length === 3 ? 'production' : host[2];

if (process.env.NODE_ENV === 'production') {
  if (getEnv('SENTRY_DSN')) {
    Sentry.init({
      release: getEnv('VERSION'),
      dsn: getEnv('SENTRY_DSN'),
      environment,
      ignoreErrors: ['ResizeObserver loop limit exceeded'],
    });
  }
}

const prepareStore = (history: History, preloadedState?: DeepPartial<State>) => {
  const persistConfig = {
    version: 3,
    key: 'core',
    storage,
    // @ts-ignore Weak 3rd party typings that I can't find quick workaround for
    migrate: createMigrate(persistMigrations, { debug: false }),
    whitelist: [
      'isLoggedIn',
      'language',
      'locale',
      'dataGridColumnConfiguration',
      'dataGridEntityParams',
    ],
  };

  const rootReducer = combineReducers({
    core: persistReducer(persistConfig, coreReducer),
    planning: planningReducer,
    personnel: personnelReducer,
    lists: listsReducer,
    settings: settingsReducer,
    router: connectRouter(history),
  });

  function* rootSaga() {
    yield safeSagaSpawn(coreSaga, planningSaga, personnelSaga, settingsSaga, appSaga);
  }

  let store;
  if (process.env.NODE_ENV === 'production') {
    store = prepareStoreProd(rootReducer, rootSaga, history, preloadedState);
  } else {
    store = prepareStoreDev(rootReducer, rootSaga, history, preloadedState);
  }

  const persistor = persistStore(store);

  return { store, persistor };
};

export default prepareStore;
