import Tippy, { TippyProps, useSingleton } from '@tippyjs/react';
import { createContext, FC, memo, ReactNode, useContext, useMemo, useRef, useState } from 'react';

import { Wrapper } from './styled';

type Props = TippyProps & { wrapInSpan?: boolean };
export type TooltipSingleton = TippyProps['singleton'];

interface ContextProps {
  mountedTippyRef: HTMLElement | null;
  setMountedTippyRef?: (element: HTMLElement | null) => void;
  singletonSource?: TooltipSingleton;
  singletonTarget?: TooltipSingleton;
  /**
   * Will only mount the tooltip content if that is the currently shown singleton content.
   *
   * A potentially unwanted side effect of setting this to `true` is that every `VirtualTooltip`
   * will be re-rendered in the react dom on each `onTrigger` (i.e. mouse hover-over) event.
   *
   * Inspiration: https://gist.github.com/atomiks/520f4b0c7b537202a23a3059d4eec908
   */
  lazy?: boolean;
}

export const TooltipContext = createContext<ContextProps>({ mountedTippyRef: null });

export const useTooltipSingleton = useSingleton;

export const useTooltipContext = () => {
  return useContext(TooltipContext);
};

export const TooltipProvider: FC<{ children: ReactNode; lazy?: boolean }> = ({
  children,
  lazy,
}) => {
  const [singletonSource, singletonTarget] = useTooltipSingleton();
  const [mountedTippyRef, setMountedTippyRef] = useState<HTMLElement | null>(null);

  return (
    <TooltipContext.Provider
      value={{ mountedTippyRef, setMountedTippyRef, singletonSource, singletonTarget, lazy }}
    >
      {children}
    </TooltipContext.Provider>
  );
};

export const defaultTippyOptions = {
  arrow: false,
  placement: 'bottom',
  animation: 'scale-subtle',
  duration: 50,
  theme: 'material',
} as const;

const Tooltip: FC<Props> = ({ wrapInSpan, children, ...props }) => (
  <Tippy
    {...defaultTippyOptions}
    {...props}
    children={wrapInSpan ? <span>{children}</span> : children}
  />
);

/**
 * Singleton version of the `Tooltip` component.
 *
 * Meant to be wrapped with the `TooltipProvider`.
 *
 * More on the singleton pattern: https://atomiks.github.io/tippyjs/v5/addons/#singleton
 */
export const SingletonTooltip: FC<Props> = (props) => {
  const { singletonSource, setMountedTippyRef, lazy } = useTooltipContext();

  return (
    <Tooltip
      onTrigger={
        lazy
          ? (_, event) => {
              setMountedTippyRef && setMountedTippyRef(event.currentTarget as HTMLDivElement);
            }
          : undefined
      }
      onHidden={lazy ? () => setMountedTippyRef && setMountedTippyRef(null) : undefined}
      singleton={singletonSource}
      {...props}
    />
  );
};

interface VirtualTooltipProps {
  lazy?: boolean;
}

/**
 * Tooltip that will re-use a singleton tippy element.
 *
 * Note that tippy's singleton does not allow additional events like `onTrigger` in the virtual
 * instances. Therefore all logic bound to event listeners should be handled in the singleton
 * instance.
 */
export const VirtualTooltip: FC<Props & VirtualTooltipProps> = (props) => {
  const { singletonTarget, mountedTippyRef, lazy } = useTooltipContext();
  const ref = useRef(null);

  const isCurrentRef = useMemo(() => ref.current === mountedTippyRef, [mountedTippyRef]);

  return (
    <Tippy
      content={lazy ? isCurrentRef && props.content : props.content}
      singleton={singletonTarget}
    >
      <Wrapper ref={ref}>{props.children}</Wrapper>
    </Tippy>
  );
};

export default memo(Tooltip);
