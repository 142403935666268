import { AxiosRequestHeaders } from 'axios';
import { toast } from 'react-toastify';
import { put, race, select, take } from 'redux-saga/effects';

import { EditProfileAction } from 'app/actions';
import User from 'app/models/User';
import {
  apiCall,
  editEntity,
  loadEntitySuccess,
  TYPE_EDIT_ENTITY,
  TYPE_LOAD_PROFILE,
} from 'core/actions';
import createDiff from 'core/effects/apiCall/createDiff';
import { Result } from 'core/effects/apiQuery';
import { t } from 'core/i18n';
import { CoreState } from 'core/reducer';

// TODO: inspect return type problem
export function* editProfileSaga(action: EditProfileAction): any {
  const { currentPassword, newPassword, ...data } = action.payload.req.data;

  if (newPassword) {
    const ACTION_PREFIX = 'SET_PASSWORD';
    yield put(
      apiCall(
        ACTION_PREFIX,
        'POST',
        '/profile/password',
        { currentPassword, newPassword },
        {},
        false
      )
    );

    const { error, success }: Result = yield race({
      success: take(`${ACTION_PREFIX}_SUCCESS`),
      error: take(`${ACTION_PREFIX}_ERROR`),
    });

    if (success) {
      toast.success(t('User password updated successfully.'));

      const [data, url] = yield select(({ core }) => [core.entityData, core.entityDataSource]);

      // Reload entity to let form know if saving its done and Password could be cleared
      // trying to match Core reducer - case 'LOAD_ENTITY_SUCCESS':
      yield put(
        loadEntitySuccess(
          {
            data: { ...data },
            status: 200,
            statusText: 'OK',
            headers: {},
            config: { headers: {} as AxiosRequestHeaders },
          },
          url
        )
      );
    } else if (error) {
      toast.error(
        // @ts-ignore
        t('User password was not changed: {{error}}', {
          error: error.payload?.response?.data?.message || '',
        })
      );
    }
  }

  const prev = { ...action.payload.prevEntity } as Partial<typeof action.payload.prevEntity>;
  delete prev.currentPassword;
  delete prev.newPassword;
  const diff = createDiff(data, prev, []);

  // Skip no changes on profile
  if (newPassword && (!diff || diff.length === 0)) {
    return;
  }

  yield put(editEntity({ ...action.payload.req, data }, prev));

  const { success } = yield race({
    success: take(`${TYPE_EDIT_ENTITY}_SUCCESS`),
    error: take(`${TYPE_EDIT_ENTITY}_ERROR`),
  });

  if (success) {
    const user: User = yield select(({ core }: { core: CoreState }) => core.user);
    const payload = {
      response: { data: { ...user, ...success.payload.response.data } },
    };

    yield put({
      type: TYPE_LOAD_PROFILE + '_SUCCESS',
      payload,
    });
  }
}
