import styled from 'styled-components/macro';

import theme from 'app/theme';

export const TestHeader = styled.div`
  position: relative;
  z-index: 1;
  width: 100%;
  padding-top: 1rem;
  background: ${theme.color.gray};
  /* This element should be on a separete layer to improve performance during scrolling */
  will-change: transform;

  h2 {
    position: relative;
    margin: 0;
    padding: 0.5rem 1rem;

    text-transform: uppercase;
    font-size: 1rem;
    font-weight: bold;

    border: 1px solid ${theme.color.gray100};
    border-bottom: none;
    border-radius: 4px;
    border-bottom-left-radius: 0;
    border-bottom-right-radius: 0;
  }
`;

export const TestContent = styled.div`
  border: 1px solid ${theme.color.gray100};
  border-top: none;
  border-radius: 4px;
  border-top-left-radius: 0;
  border-top-right-radius: 0;
  padding: 0.5rem;
`;

export const Container = styled.div`
  position: relative;
  display: flex;
  flex-direction: row;
  overflow-x: scroll;
  overflow-y: scroll;
  background: ${theme.color.gray};

  > * {
    flex: 0 0 32em;
    flex-grow: 0;
    height: fit-content;

    margin: 0 0 1rem 1rem;
    &:last-child {
      margin-right: 1rem;
    }
  }
`;
