import {
  createContext,
  FC,
  ReactNode,
  useCallback,
  useContext,
  useLayoutEffect,
  useRef,
} from 'react';
import { useDispatch } from 'react-redux';

import { clearApiQuery } from 'core/actions';

const Context = createContext<undefined | ((id: string) => void)>(undefined);

interface Props {
  disable?: boolean;
  children?: ReactNode;
}

/**
 * "Api Cache" wrapper for improving local app state
 * by aggregating "api cleanup" actions
 *
 * by wrapping any useApiCall hook
 * - useResources
 * - useResourceWithCounts
 * - useDropdownOptions
 * you will stop "unmount" cleanup action and this action will be triggered once
 * once wrapper is unmounted
 *
 * Example:
 *   <Route path='/tab1' component={Dropdown1} />
 *   <Route path='/tab2' component={Dropdown1} />
 *
 * by navigating between routes Dropdown1 & Dropdown2 resource is re-fetched every time you change tab
 *
 * <ApiCache>
 *   <Route path='/tab1' component={Dropdown1} />
 *   <Route path='/tab2' component={Dropdown1} />
 * </ApiCache>
 *   ...
 *
 * api calls will be fired only on first render, and cleared by leaving whole group of sub-urls (tabs)
 *
 */
const ApiCache: FC<Props> = ({ children, disable }) => {
  const ids = useRef<Set<string>>(new Set());
  const isMounted = useRef<boolean>(true);
  const reduxDispatch = useDispatch();

  const preventClear = useCallback(
    (id: string) => {
      // Parent components are unmounted before children are
      // We have to clear it right away if we already unmounted
      if (isMounted.current) {
        ids.current.add(id);
      } else if (!ids.current.has(id)) {
        // ... unless it already was during unmount
        reduxDispatch(clearApiQuery(id));
      }
    },
    [reduxDispatch]
  );

  useLayoutEffect(() => {
    isMounted.current = true;

    return () => {
      isMounted.current = false;

      // We are not depending on a React element, but on strings which are fine
      // eslint-disable-next-line react-hooks/exhaustive-deps
      Array.from(ids.current).forEach((id) => {
        reduxDispatch(clearApiQuery(id));
      });
    };
  }, [reduxDispatch]);

  return <Context.Provider value={disable ? undefined : preventClear}>{children}</Context.Provider>;
};

export default ApiCache;

export const useClearApiCache = () => {
  const reduxDispatch = useDispatch();
  const clearCache = useContext(Context);

  return useCallback(
    (id: string) => {
      if (clearCache) {
        clearCache(id);
      } else {
        reduxDispatch(clearApiQuery(id));
      }
    },
    [clearCache, reduxDispatch]
  );
};
