export const HOURS = Array.from(Array(24).keys());
export const MINUTES = Array.from(Array(60).keys());
export const MIN_TIME = new Date(0, 0, 0, 0, 0, 0);
export const MAX_TIME = new Date(0, 0, 0, 23, 59, 59);

export const isHoursDisabled = (
  hour: number,
  min: Date = new Date(0, 0, 0, 0, 0, 0),
  max: Date = new Date(0, 0, 0, 23, 59, 59)
) => {
  let maxHour = max;
  let minHour = min;
  if (maxHour === null) maxHour = MAX_TIME;
  if (minHour === null) minHour = MIN_TIME;

  return hour < minHour.getHours() || hour > maxHour.getHours();
};

export const isMinutesDisabled = (
  minute: number,
  min: Date = new Date(0, 0, 0, 0, 0, 0),
  max: Date = new Date(0, 0, 0, 23, 59, 59),
  activeHour?: number
) => {
  if (max === null) max = MAX_TIME;
  if (min === null) min = MIN_TIME;

  return (
    (max.getHours() - min.getHours() === 0 &&
      (minute < min.getMinutes() || minute > max.getMinutes())) ||
    (activeHour !== undefined && activeHour === max.getHours() && minute > max.getMinutes()) ||
    (activeHour !== undefined && activeHour === min.getHours() && minute < min.getMinutes())
  );
};
