import 'rc-slider/assets/index.css';
import { SliderProps } from 'rc-slider/lib/Slider';
import { FC } from 'react';

import MaterialIcon from 'core/components/MaterialIcon';

import {
  StyledLeftIconWrapper,
  StyledRightIconWrapper,
  StyledSlider,
  StyledSliderWrapper,
} from './styled';

interface OwnProps {
  leftIcon?: string;
  rightIcon?: string;
}

type Props = OwnProps & SliderProps<number>;

// Default values for the rc-slider - https://www.npmjs.com/package/rc-slider
const RC_SLIDER_DEFAULT_MAX = 100;
const RC_SLIDER_DEFAULT_MIN = 0;
const RC_SLIDER_DEFAULT_STEP = 1;
const RC_SLIDER_DEFAULT_VALUE = 0;

const Slider: FC<Props> = ({ leftIcon, rightIcon, ...rest }) => {
  const value = rest.value || rest.defaultValue || RC_SLIDER_DEFAULT_VALUE;
  const step = rest.step || RC_SLIDER_DEFAULT_STEP;
  const minDisabled = rest.value === rest.min;
  const maxDisabled = rest.value === rest.max;

  return (
    <StyledSliderWrapper>
      {leftIcon && (
        <StyledLeftIconWrapper disabled={minDisabled}>
          <MaterialIcon
            icon={leftIcon}
            disabled={minDisabled}
            onClick={() =>
              rest.onChange &&
              rest.onChange(Math.max(value - step, rest.min || RC_SLIDER_DEFAULT_MIN))
            }
          />
        </StyledLeftIconWrapper>
      )}
      <StyledSlider {...rest} />
      {rightIcon && (
        <StyledRightIconWrapper disabled={maxDisabled}>
          <MaterialIcon
            icon={rightIcon}
            disabled={maxDisabled}
            onClick={() =>
              rest.onChange &&
              rest.onChange(Math.min(value + step, rest.max || RC_SLIDER_DEFAULT_MAX))
            }
          />
        </StyledRightIconWrapper>
      )}
    </StyledSliderWrapper>
  );
};

export default Slider;
