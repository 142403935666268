import { NotificationType } from 'planning/components/TeamTab/enums';
import { TeamMemberNotification } from 'planning/models/TeamMember';

export const NotificationTypeMap = {
  [NotificationType.EMAIL]: 'Email',
  [NotificationType.SMS]: 'SMS',
};

type SentNotificationsMap = Map<string, { date: Date; means: string[] }>;

export const parseNotificationData = (
  notifications: TeamMemberNotification[],
  dateFormatter: (date: Date) => string
): { plannedMeans: string[]; sent: { formattedDate: string; means: string[] }[] } => {
  const sent: SentNotificationsMap = new Map();
  const plannedMeans: string[] = [];

  for (const n of notifications) {
    if (!n.createdAt) {
      plannedMeans.push(NotificationTypeMap[n.type]);
      continue;
    }

    const formattedDate = dateFormatter(n.createdAt);

    const existing = sent.get(formattedDate) || { date: n.createdAt, means: [] };
    sent.set(formattedDate, {
      date: n.createdAt,
      means: [...existing.means, NotificationTypeMap[n.type]],
    });
  }

  const orderedSent = Array.from(sent.entries())
    .sort((a, b) => b[1].date.getTime() - a[1].date.getTime())
    .map(([formattedDate, value]) => ({ formattedDate, means: value.means }));

  return {
    plannedMeans,
    sent: orderedSent,
  };
};
