import { useCallback } from 'react';
import { useTranslation } from 'react-i18next';

import Analysis from 'lists/models/Analysis';

const useAnalysesOptionsReducer = () => {
  const { t } = useTranslation();

  return useCallback(
    (list: Analysis[], selectedIds?: (number | 'other')[] | null) => {
      return list
        .filter((analysis) => analysis.active || (selectedIds || []).includes(analysis.id))
        .map((analysis) => {
          const analysesTypes: string[] = [
            ...(analysis.analyseTypesId === 'uri' ? [t('Urine')] : []),
            ...(analysis.oneBloodEdtaTube ? [t('1xEDTA')] : []),
            ...(analysis.twoBloodEdtaTubes ? [t('2xEDTA')] : []),
            ...(analysis.oneBloodGelTube ? [t('1xGEL')] : []),
            ...(analysis.twoBloodGelTubes ? [t('2xGEL')] : []),
            ...(analysis.oneBloodEdtaTwoGelTubes ? [t('1xEDTA+2xGEL')] : []),
            ...(analysis.driedBloodSpots ? [t('DBS')] : []),
          ];

          return {
            id: analysis.id,
            name: analysis.shortName,
            secondary: analysesTypes.length ? analysesTypes.join(', ') : '-',
          };
        });
    },
    [t]
  );
};

export default useAnalysesOptionsReducer;
