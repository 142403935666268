import { useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import * as Yup from 'yup';

import { getTabMeta } from 'core/components/Form/TabErrorCollector';
import { MAX_ZIP_LENGTH } from 'core/utils/constant';

const tabMeta = getTabMeta<'detail'>();

export const useAthleteSchemaFields = () => {
  const { t } = useTranslation();
  return {
    firstName: Yup.string().nullable().required().meta(tabMeta('detail')).default(null),
    middleName: Yup.string().nullable().meta(tabMeta('detail')).default(null),
    lastName: Yup.string().nullable().required().meta(tabMeta('detail')).default(null),
    gender: Yup.string().nullable().meta(tabMeta('detail')).default(null),
    dateOfBirth: Yup.date().nullable().meta(tabMeta('detail')).default(null),
    nationalityId: Yup.number().nullable().meta(tabMeta('detail')).default(null),
    poolsId: Yup.number().nullable().meta(tabMeta('detail')).default(null),
    // to make our life easier with includes
    sportDisciplines: Yup.array(
      Yup.object({
        id: Yup.number().meta(tabMeta('detail')).default(null),
        sportsId: Yup.number().nullable().meta(tabMeta('detail')).default(null),
        sportDisciplinesId: Yup.number()
          .nullable()
          .default(null)
          .when('sportsId', {
            is: (sportsId: null | number) => !!sportsId,
            then: (schema) =>
              schema
                .meta(tabMeta('detail'))
                .test('selectDiscipline', t('Please select sport discipline'), (v) => !!v),
          }),
      })
    )
      .nullable()
      .meta(tabMeta('detail'))
      .default(null),
    adamsId: Yup.string().nullable().meta(tabMeta('detail')).default(null),
    externalId: Yup.string().max(50).nullable().meta(tabMeta('detail')).default(null),
    street: Yup.string().nullable().meta(tabMeta('detail')).default(null),
    address: Yup.string().nullable().meta(tabMeta('detail')).default(null),
    zip: Yup.string().nullable().max(MAX_ZIP_LENGTH).meta(tabMeta('detail')).default(null),
    city: Yup.string().nullable().meta(tabMeta('detail')).default(null),
    state: Yup.string().nullable().meta(tabMeta('detail')).default(null),
    countriesId: Yup.number().nullable().meta(tabMeta('detail')).default(null),
    phone: Yup.string().phone().nullable().meta(tabMeta('detail')).default(null),
    email: Yup.string().nullable().email().meta(tabMeta('detail')).default(null),
    active: Yup.boolean().meta(tabMeta('detail')).default(true),
    internalComments: Yup.string().nullable().meta(tabMeta('detail')).default(null),
    disabilitiesId: Yup.number().nullable().meta(tabMeta('detail')).default(null),
    disabilityComment: Yup.string().nullable().meta(tabMeta('detail')).default(null),
  };
};

const useSchema = () => {
  const athleteSchemaFields = useAthleteSchemaFields();

  return useMemo(() => Yup.object().shape(athleteSchemaFields), [athleteSchemaFields]);
};

export default useSchema;
