import { useFormikContext } from 'formik';
import { FC, useContext, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';

import { entities } from 'app/entity';
import { SimpleOption } from 'core/components/DropDown';
import { FormContext } from 'core/components/Form';
import Field from 'core/components/Form/Field';
import sortOptionsHelper from 'core/components/FormDropDown/sortOptionsHelper';
import FormTextfield from 'core/components/FormTextfield';
import Modal from 'core/components/Modal';
import { useIncludeOptions } from 'core/components/ResourceFormDropdown/IncludeResourcesProvider';
import SelectOtherField, { OTHER_NUMBER } from 'core/components/SelectOtherField';
import { useDetail } from 'core/containers/FormDetailPage/DetailContext';
import { defaultDropdownReducer } from 'core/hooks/useDropdownResource';
import { useResources } from 'core/hooks/useResource';
import useEventInputMapping from 'planning/containers/EventDetailPage/hooks/useEventInputMapping';
import useIncludeOverride from 'planning/containers/TestDetailPage/hooks/useIncludeOverride';
import { includeResourceMapping } from 'planning/containers/TestDetailPage/TestForm/resourceMap';
import Event from 'planning/models/Event';

const EventField: FC = () => {
  const { t } = useTranslation();
  const [, setIncludesOverride] = useIncludeOverride();

  const { isFieldInViewMode } = useContext(FormContext);
  const viewMode = isFieldInViewMode('eventName');
  const { mode } = useDetail();
  const { values, setValues, touched, setTouched } = useFormikContext<{
    eventName: string | null;
    eventsId: number | null;
    externalId: string | null;
  }>();
  const inputMapping = useEventInputMapping('clone');
  const [event, setEvent] = useState(values);

  const include = useIncludeOptions('eventsId') as Event[];

  const { data: events, reload: loadEvents } = useResources<Event>('events', {
    autoload: !!values.eventsId && mode === 'create',
  });

  const [confirmEventChangeData, setConfirmEventChangeData] = useState<null | Event>(null);

  const updateIncludesUsingEvent = () => {
    if (!confirmEventChangeData) return;

    const newIncludes = {} as { [key: string]: unknown };
    Object.values(includeResourceMapping).forEach((key) => {
      // @ts-ignore This definition is unfortunately very loose
      if (confirmEventChangeData[key]) newIncludes[key] = confirmEventChangeData[key];
    });

    setIncludesOverride(newIncludes);
  };

  // Called when event change is confirmed
  const handleConfirmEventChange = () => {
    if (!confirmEventChangeData) return;

    updateIncludesUsingEvent();

    const inputData = inputMapping(confirmEventChangeData);

    setValues({
      ...event,
      ...inputData,
      externalId: values.externalId,
    });

    setTouched(
      {
        ...touched,
        ...Object.keys(inputData).reduce((p, k) => ({ ...p, [k]: false }), {}),
      },
      // Validating after we've just changed values would cause problems with stale values
      false
    );
  };

  const eventOptions: SimpleOption[] = useMemo(() => {
    const addInclude = include.length && !events?.some((f) => f.id === include[0].id);

    const completeEvents = addInclude ? [...include, ...(events || [])] : events || [];

    return sortOptionsHelper(
      defaultDropdownReducer(
        completeEvents.map((f) => ({
          ...f,
          secondary: f.externalId,
        })) || [],
        values.eventsId
      )
    ) as SimpleOption[];
  }, [include, events, values.eventsId]);

  const handleEventChange = (eventsId: number | null, selectedEvent: Event | null) => {
    setEvent({
      ...event,
      eventsId: eventsId as number | null,
      eventName: null,
    });

    setConfirmEventChangeData(selectedEvent);

    if (eventsId === null) {
      setValues({ ...values, eventsId: null, eventName: null });
      // eventName is handled by formik
      setTouched({ ...touched, eventsId: true });
      return;
    }
  };

  return (
    <>
      <SelectOtherField
        label={entities.event.name(t)}
        options={eventOptions}
        name="eventsId"
        viewMode={viewMode}
        otherComponent={(autoFocus) => (
          <Field
            name="eventName"
            label={entities.event.name(t)}
            component={FormTextfield}
            autoFocus={autoFocus}
            dummyValue={
              viewMode ? event.eventName || (include.length > 0 && include[0].name) : undefined
            }
          />
        )}
        defaultMode={event.eventName ? 'other' : 'dropdown'}
        onClickOther={() => {
          setValues({ ...values, eventsId: null, eventName: null });
          // eventName is handled by formik
          setTouched({ ...touched, eventsId: true });
        }}
        otherOption={{
          value: OTHER_NUMBER,
        }}
        onDropdownChange={(newValue, option) =>
          handleEventChange(newValue as number | null, option?.extra)
        }
        onOpen={() => {
          if (!events?.length) {
            loadEvents();
          }
        }}
      />
      <Modal
        ariaLabel={t('Unsaved data confirmation dialog')}
        onConfirm={() => handleConfirmEventChange()}
        onClose={() => setConfirmEventChangeData(null)}
        open={!!confirmEventChangeData}
      >
        {t(
          'You can overwrite filled data by changing the Event. Are you sure you want to continue?'
        )}
      </Modal>
    </>
  );
};

export default EventField;
