import { push } from 'connected-react-router';
import { toast } from 'react-toastify';
import { put, race, take } from 'redux-saga/effects';

import {
  apiCall,
  beginEditEntity,
  CreateEntityAction,
  DeleteEntityAction,
  EditEntityAction,
  finishEditEntity,
  LoadEntityAction,
  loadEntitySuccess,
  TYPE_CREATE_ENTITY,
  TYPE_DELETE_ENTITY,
  TYPE_EDIT_ENTITY,
  TYPE_LOAD_ENTITY,
} from 'core/actions';
import { t } from 'core/i18n';

export function* loadEntitySaga(action: LoadEntityAction) {
  const { endpoint } = action.payload;

  yield put(apiCall(TYPE_LOAD_ENTITY, 'GET', endpoint, {}));
}

export function* createEntitySaga(action: CreateEntityAction) {
  const files = action.payload.req.uploadables;

  if (action.payload.req.forceMultipart || (files && Object.keys(files).length > 0)) {
    const multipart = new FormData();
    if (files) {
      Object.keys(files).forEach((key) => {
        if (!files[key]) return;

        if (Array.isArray(files[key])) {
          (files[key] as File[]).forEach((f) => multipart.append(key + '[]', f));
        } else {
          // @ts-ignore
          multipart.append(key, files[key]);
        }
      });
    }

    multipart.append(
      'data',
      new Blob([JSON.stringify(action.payload.req.data, null, 2)], {
        type: 'application/json',
      })
    );

    yield put(
      apiCall(TYPE_CREATE_ENTITY, 'POST', action.payload.req.endpoint, multipart, {
        headers: {
          Accept: 'application/json',
          'Content-Type': `multipart/form-data`,
        },
      })
    );
  } else {
    yield put(
      apiCall(TYPE_CREATE_ENTITY, 'POST', action.payload.req.endpoint, action.payload.req.data, {
        headers: {
          Accept: 'application/json',
          'Content-Type': 'application/json',
        },
      })
    );
  }

  const { success } = yield race({
    success: take(`${TYPE_CREATE_ENTITY}_SUCCESS`),
    error: take(`${TYPE_CREATE_ENTITY}_ERROR`),
  });

  if (success) {
    const id = success.payload.response.data && success.payload.response.data.id;
    yield put(push(id ? action.payload.detailUrl(id) : action.payload.listUrl));
    toast.success(t('Successfully added'));
  }
}

export function* deleteEntitySaga(action: DeleteEntityAction) {
  const { endpoint, successCallback } = action.payload;

  yield put(apiCall(TYPE_DELETE_ENTITY, 'DELETE', endpoint, {}));

  const { success } = yield race({
    success: take(`${TYPE_DELETE_ENTITY}_SUCCESS`),
    error: take(`${TYPE_DELETE_ENTITY}_ERROR`),
  });

  if (success) {
    successCallback && successCallback();
    toast.success(t('Successfully deleted'));
  }
}

export function* editEntitySaga(action: EditEntityAction) {
  const {
    req: { endpoint, data, successCallback },
    prevEntity,
  } = action.payload;

  yield put(beginEditEntity());

  yield put(apiCall(TYPE_EDIT_ENTITY, 'PATCH', endpoint, { ...data }, {}, true, prevEntity, []));

  const { success } = yield race({
    success: take(`${TYPE_EDIT_ENTITY}_SUCCESS`),
    error: take(`${TYPE_EDIT_ENTITY}_ERROR`),
  });

  yield put(finishEditEntity());

  if (success) {
    successCallback && successCallback();

    toast.success(t('Successfully edited'));

    yield put(loadEntitySuccess(success.payload.response, endpoint));
  }
}
