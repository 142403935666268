import { FC, useState } from 'react';
import { useTranslation } from 'react-i18next';

import IconLoader from 'app/components/IconLoader';
import { entities, NoContextEntityKey } from 'app/entity';
import MaterialIcon from 'core/components/MaterialIcon';
import PaperContainer from 'core/components/PaperContainer';
import 'core/containers/DataGrid/styles.scss';
import { useResources } from 'core/hooks/useResource';
import CommentModel from 'core/models/Comment';

import { ToolbarButton } from '../DataGrid/Toolbar/styled';

import Comment from './Comment';
import useCommentsUrls, { CommentsUrls } from './useCommentsUrls';

export interface Props {
  entityId: number;
  commentsUrls?: CommentsUrls;
  entity: NoContextEntityKey;
  blacklistedRoles?: number[];
  canAddComment?: boolean;
}

const Comments: FC<Props> = ({
  commentsUrls,
  entityId,
  entity,
  blacklistedRoles,
  canAddComment = true,
}) => {
  const [writeOpen, setWriteOpen] = useState<number | undefined>(undefined);
  const [newestFirst, setNewestFirst] = useState(true);
  const { t } = useTranslation();

  const currentEntity = entities[entity];
  const defaultUrls = useCommentsUrls(currentEntity.api().list);
  const urls = commentsUrls || defaultUrls;

  const { data: comments, reload } = useResources<CommentModel>(
    urls.commentsList(entityId, newestFirst),
    { cache: false } // Because when sorting changes and new comment is added it is not reloaded
  );

  return (
    <PaperContainer
      customTitleAction={
        comments && comments?.length > 0 ? (
          <ToolbarButton
            type="button"
            icon={<MaterialIcon icon="sort" />}
            onClick={() => setNewestFirst(!newestFirst)}
            text={newestFirst ? t('Newest first') : t('Oldest first')}
            $pullRight
          />
        ) : null
      }
      badgePrimary={`${comments?.length || 0}`}
      title={t('Comments')}
    >
      <Comment
        toggleWrite={() => null}
        write
        reload={reload}
        linkedEntityId={entityId}
        linkedEntity={entity}
        urls={urls}
        blacklistedRoles={blacklistedRoles}
        canAddComment={canAddComment}
      />

      {comments ? (
        (comments || []).map((it) => (
          <Comment
            key={`comment-${it.id}`}
            toggleWrite={() => setWriteOpen(it.id === writeOpen ? undefined : it.id)}
            write={it.id === writeOpen}
            reload={reload}
            record={it}
            linkedEntityId={entityId}
            linkedEntity={entity}
            urls={urls}
            blacklistedRoles={blacklistedRoles}
            canAddComment={canAddComment}
          />
        ))
      ) : (
        <IconLoader />
      )}
    </PaperContainer>
  );
};

export default Comments;
