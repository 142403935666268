import { FC, memo, useMemo } from 'react';
import { useTranslation } from 'react-i18next';

import MaterialIcon from 'core/components/MaterialIcon';
import Tooltip from 'core/components/Tooltip';
import { useResources } from 'core/hooks/useResource';
import { Role } from 'core/models/Role';

import { InlineIconText } from '../../styled';
import { CommentsUrls } from '../../useCommentsUrls';

type Props = { restrictedRoleIds: number[]; urls: CommentsUrls };

const RestrictedRoleNames: FC<Props> = ({ restrictedRoleIds, urls }) => {
  const { t } = useTranslation();

  const { data: roles } = useResources<Role>('roles');
  const { data: unrestrictedRoleIds } = useResources<number>(
    `${urls.entityList(true)}/comments/unrestrictedRoles`
  );

  const restrictedRoleNames = useMemo(() => {
    // This is just a placeholder for the unrestricted role
    const unrestrictedRole: Role = {
      id: 0,
      name: t('You (unrestricted)'),
      active: true,
      deleted: false,
      createdBy: 0,
      modifiedBy: null,
      createdAt: new Date(),
      modifiedAt: null,
    };

    const isUnrestrictedUser = roles?.some((it) => (unrestrictedRoleIds || []).includes(it.id));

    const filteredRoles = (roles || [])
      .filter((it) => !(unrestrictedRoleIds || []).includes(it.id))
      .filter((it) => restrictedRoleIds.includes(it.id));
    const preparedRoles = isUnrestrictedUser ? [unrestrictedRole, ...filteredRoles] : filteredRoles;

    const roleNames = preparedRoles.map((it) => it.name);

    return restrictedRoleIds.length ? roleNames : [];
  }, [t, roles, restrictedRoleIds, unrestrictedRoleIds]);

  return (
    <>
      {restrictedRoleNames.length > 0 && (
        <Tooltip content={t('Only visible to chosen roles')}>
          <InlineIconText>
            <MaterialIcon icon="lock_outline" />
            {restrictedRoleNames.join(', ')}
          </InlineIconText>
        </Tooltip>
      )}
    </>
  );
};

export default memo(RestrictedRoleNames);
