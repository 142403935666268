import { useFormikContext } from 'formik';
import isEqual from 'lodash/isEqual';
import { FC, useEffect } from 'react';

import { TestFormData } from '../TestDetailPage/hooks/useTestSchema';

interface Props {
  /**
   * Reference that will be used to set unsaved changes - new form data
   */
  reference: { [testsId: number]: TestFormData };
  testsId: number;
}

/**
 * Collects changes made on the formik where it's used by setting reference[testId] = values
 */
const ChangesCollector: FC<Props> = ({ reference, testsId }) => {
  const { values, initialValues } = useFormikContext<TestFormData>();
  useEffect(() => {
    const nothingChanged = isEqual(values, initialValues);
    if (nothingChanged) {
      // This test shouldn't be considered for update if nothing changed
      delete reference[testsId];
    } else {
      reference[testsId] = values;
    }
  }, [values, initialValues, reference, testsId]);

  return null;
};

export default ChangesCollector;
