import { FC, useId } from 'react';

import theme from 'app/theme';
import Text from 'core/components/Text';

import { StyledRadio } from './styled';

type Props = {
  /** Label of the radio */
  label: string;
  /** Name of the radio */
  name: string;
  /** Function that will be called when the radio is clicked */
  onChange?: (event: any) => void;
  /** Description of the radio */
  description?: string | number | null;
  /** Value of the radio */
  value?: string;
  /** If the radio is checked */
  checked?: boolean;
  /** If the radio is disabled */
  disabled?: boolean;
  /** Id of the radio */
  id?: string;
};

const Radio: FC<Props> = ({ onChange, label, name, description, checked, disabled, id, value }) => {
  const htmlId = useId();

  return (
    <StyledRadio $checked={checked} $disabled={disabled} key={name}>
      <input
        disabled={disabled}
        type="radio"
        id={id || htmlId}
        name={name}
        checked={checked}
        onChange={(event) => {
          onChange && onChange(event);
        }}
        value={value}
      />
      <label htmlFor={id || htmlId}>
        <Text
          $fontSize="0.75rem"
          $color={disabled ? theme.color.darkGray : theme.color.black}
          $width="90%"
        >
          {label}
        </Text>
        <Text
          $fontSize="1rem"
          $color={disabled ? theme.color.darkGray : theme.color.black}
          $width="90%"
        >
          {description ? description : '-'}
        </Text>
      </label>
    </StyledRadio>
  );
};

export default Radio;
