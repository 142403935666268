import { useMemo } from 'react';
import { useTranslation } from 'react-i18next';

import { BLACKLISTS_TYPE } from '.';

const useBlacklistsTypeTitle = (type?: BLACKLISTS_TYPE) => {
  const { t } = useTranslation();

  return useMemo(() => {
    switch (type) {
      case BLACKLISTS_TYPE.ATHLETES:
        return t('Athletes');
      case BLACKLISTS_TYPE.SPORT_DISCIPLINES:
        return t('Sport Disciplines');
      default:
        throw Error('Not supported blacklists type');
    }
  }, [type, t]);
};

export default useBlacklistsTypeTitle;
