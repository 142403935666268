import { useFormikContext } from 'formik';
import { FC, useContext, useMemo } from 'react';
import { useTranslation } from 'react-i18next';

import { SimpleOption } from 'core/components/DropDown';
import { FormContext } from 'core/components/Form';
import Field from 'core/components/Form/Field';
import sortOptionsHelper from 'core/components/FormDropDown/sortOptionsHelper';
import FormTextfield from 'core/components/FormTextfield';
import { useIncludeOptions } from 'core/components/ResourceFormDropdown/IncludeResourcesProvider';
import SelectOtherField, { OTHER_NUMBER } from 'core/components/SelectOtherField';
import { useDetail } from 'core/containers/FormDetailPage/DetailContext';
import { defaultDropdownReducer } from 'core/hooks/useDropdownResource';
import { useResources } from 'core/hooks/useResource';
import { AuthorityGrid } from 'lists/models/Authority';

const CustomerField: FC = () => {
  const { t } = useTranslation();

  const { isFieldInViewMode } = useContext(FormContext);
  const viewMode = isFieldInViewMode('customersId');
  const { mode } = useDetail();
  const { values, setValues } = useFormikContext<{
    customersName: string | null;
    customersId: number | null;
  }>();

  const include = useIncludeOptions('customersId') as AuthorityGrid[];
  const { data: authorities, reload: loadAuthorities } = useResources<AuthorityGrid>(
    'authorities',
    {
      autoload: !!values.customersId && mode === 'create',
    }
  );

  const authorityOptions: SimpleOption[] = useMemo(() => {
    const addInclude = include.length && !authorities?.some((f) => f.id === include[0].id);

    const completeAuthorities = addInclude
      ? [...include, ...(authorities || [])]
      : authorities || [];

    return sortOptionsHelper(
      defaultDropdownReducer(
        completeAuthorities.map((f) => ({
          id: f.id,
          name: f.shortName,
          secondary: f.fullName,
          active: f.active,
        })) || [],
        values.customersId
      )
    ) as SimpleOption[];
  }, [include, authorities, values.customersId]);

  const handleAuthoritiesChange = (id: number | null) => {
    setValues({
      ...values,
      customersId: id as number | null,
      customersName: null,
    });
  };

  return (
    <SelectOtherField
      label={t('Customer')}
      options={authorityOptions}
      name="customersId"
      viewMode={viewMode}
      otherComponent={(autoFocus) => (
        <Field
          name="customersName"
          label={t('Customer')}
          component={FormTextfield}
          autoFocus={autoFocus}
          dummyValue={
            viewMode
              ? values.customersName || (include.length > 0 && include[0].fullName)
              : undefined
          }
        />
      )}
      otherOption={{
        value: OTHER_NUMBER,
      }}
      defaultMode={values.customersName ? 'other' : 'dropdown'}
      onClickOther={() => setValues({ ...values, customersId: null, customersName: null })}
      onDropdownChange={(newValue) => handleAuthoritiesChange(newValue as number | null)}
      onOpen={() => {
        if (!authorities?.length) {
          loadAuthorities();
        }
      }}
    />
  );
};

export default CustomerField;
