import styled from 'styled-components/macro';

import theme from 'app/theme';

export const ChipsInput = styled.div`
  &.mdc-text-field {
    padding: 0.5rem 0.25rem 0.25rem 0.25rem !important;
    background-color: ${theme.color.white};
    border-radius: 0.25rem !important;
    border: 0.0625rem solid ${theme.color.gray200};
    display: block !important;
    height: auto !important;
    cursor: pointer;

    &.mdc-text-field--error {
      border-color: var(--mdc-theme-error) !important;
    }

    &:hover:not(.mdc-text-field--error) {
      border-color: ${theme.color.baseFontColor} !important;
    }

    &:focus-within:not(.mdc-text-field--error) {
      border-color: var(--mdc-theme-primary) !important;
      border-width: 2px;
      margin: -1px;
    }
  }

  .mdc-chip {
    margin: 0.25rem;
    &.has-error {
      background-color: var(--mdc-theme-error);
      color: ${theme.color.white};
    }
  }

  .mdc-floating-label {
    background-color: ${theme.color.white};
    padding-right: 1rem;
    padding-left: 0.3rem;
    left: 0.7rem;

    &.mdc-floating-label--error {
      color: var(--mdc-theme-error);
    }
  }

  input {
    box-shadow: none !important;
    background: transparent;
    border: none !important;
    padding: 0.25rem;
    margin: 0.25rem;
    max-width: 90%;
    height: 2rem;
  }
`;

export const Input = styled.input<{ $textLen: number }>`
  width: ${({ $textLen }) => Math.max(10, $textLen) + 'ch'};
`;
