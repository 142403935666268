import { useMemo } from 'react';
import { useTranslation } from 'react-i18next';

import { DataCardAction, DataCardItem } from '../props';

const useRowEndAction = <Item extends DataCardItem>(
  actions: DataCardAction<Item>[],
  onRemove?: (item: Item | Item[]) => void
) => {
  const { t } = useTranslation();

  return useMemo(() => {
    const quick = actions.filter((action) => action.quickAction !== false);

    // Remove is moved to actions if available
    if (quick.length > 0 && onRemove) {
      quick.push({
        id: 'remove',
        title: t('Remove'),
        onClick: onRemove,
      });
    }

    if (quick.length > 0) {
      return quick;
    } else if (onRemove) {
      return onRemove;
    } else {
      return undefined;
    }
  }, [actions, onRemove, t]);
};

export default useRowEndAction;
