import { useFormikContext } from 'formik';
import { FC, useContext, useEffect } from 'react';

import { FormContext } from '..';

const FormikContextHelper: FC = () => {
  const context = useFormikContext();
  const formContext = useContext(FormContext);

  useEffect(() => {
    window.getFormikContext = () => context;
    window.getFormContext = () => formContext;

    return () => {
      delete window.getFormikContext;
      delete window.getFormContext;
    };
  }, [context, formContext]);

  return null;
};

export default FormikContextHelper;
