import styled, { css } from 'styled-components/macro';

export const Collapse = styled.div<{ isOpen: boolean }>`
  transition: max-height 0.4s ease-in-out;
  height: auto;
  ${(p) =>
    !p.isOpen &&
    css`
      overflow: hidden;
      max-height: 0;
    `}
`;

export default Collapse;
