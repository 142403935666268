import { useMemo } from 'react';
import { useTranslation } from 'react-i18next';

export enum ViewTabs {
  FILTERS = 0,
  GRID_SETTINGS = 1,
  DETAILS = 2,
}

const useViewTabs = (disableSettings: boolean) => {
  const { t } = useTranslation();

  return useMemo(
    () => [
      {
        title: t('Filters'),
        type: ViewTabs.FILTERS,
        key: 'filter',
        disabled: false,
      },
      {
        title: t('Columns'),
        type: ViewTabs.GRID_SETTINGS,
        key: 'gridSettings',
        disabled: disableSettings,
      },
      {
        title: t('Details'),
        type: ViewTabs.DETAILS,
        key: 'details',
      },
    ],
    [t, disableSettings]
  );
};

export default useViewTabs;
