import Sample from 'planning/models/Sample';

export const TYPE_LOAD_SAMPLE = 'LOAD_SAMPLE';
export const loadSample = (id: number) =>
  ({
    type: TYPE_LOAD_SAMPLE,
    payload: {
      id,
    },
  }) as const;
export type LoadSampleAction = ReturnType<typeof loadSample>;

export const TYPE_UNLOAD_SAMPLE = 'UNLOAD_SAMPLE';
export const unloadSample = () =>
  ({
    type: TYPE_UNLOAD_SAMPLE,
  }) as const;
export type UnloadSampleAction = ReturnType<typeof unloadSample>;

export const TYPE_EDIT_SAMPLE = 'EDIT_SAMPLE';
export const editSample = (formData: Sample) =>
  ({
    type: TYPE_EDIT_SAMPLE,
    payload: {
      formData,
    },
  }) as const;
export type EditSampleAction = ReturnType<typeof editSample>;

export type SampleActions = LoadSampleAction | UnloadSampleAction | EditSampleAction;
