import styled from 'styled-components/macro';

export const StyledWrapper = styled.div`
  display: table-caption;
  margin-bottom: 0.5em;
  padding: 0.5rem 1em 1em;
  text-align: left;
  border-radius: 0.25em 0.25em 0 0;
`;

export const StyledYear = styled.div.attrs(({ className, tabIndex, onClick, children }) => ({
  tabIndex,
  onClick,
  children,
  className,
}))<{ chosen: boolean }>`
  opacity: ${(p) => (p.chosen ? 1 : 0.7)};
  cursor: pointer;
`;

export const StyledDay = styled.div.attrs(({ className, tabIndex, onClick, children }) => ({
  tabIndex,
  onClick,
  children,
  className,
}))<{ chosen: boolean; pointer: boolean }>`
  pointer-events: ${(p) => (p.pointer ? 'all' : 'none')};
  opacity: ${(p) => (p.chosen ? 1 : 0.7)};
  font-size: 1.5em;
  cursor: pointer;
`;
