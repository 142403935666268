import { useCallback, useMemo } from 'react';
import { useTranslation } from 'react-i18next';

import { entities } from 'app/entity';
import Event, { EventStatus } from 'app/models/Event';
import {
  Notification,
  NotificationTypeEnum,
} from 'core/components/Notifications/RenderNotification';
import getEnv from 'core/functions/getEnv';

const ADAMS_DCF_URL = 'https://adams.wada-ama.org/ng/en/dcf/view';

export default function useSentToADAMS() {
  const { t } = useTranslation();
  const messages = useAdamsErrorMessages();

  return useCallback(
    (e: Event): Notification => {
      const codeError = e.payload.message?.split(' ').shift() as unknown as AdamsErrorCodes;
      const errorMessage =
        codeError in messages
          ? `${messages[codeError]} ${e.payload.message?.replace(`${codeError} `, '')}`
          : e.payload.message;

      const actions = [];
      if (e.payload.resultStorageKey) {
        actions.push({
          key: 'download',
          text: t('Download Result'),
          action: () => window.location.replace(`${getEnv('API_URL')}/tests/send-result/${e.id}`),
        });
      }
      if (e.payload.adamsDcfId) {
        actions.push({
          key: 'openAdams',
          text: t('Open ADAMS DCF'),
          action: () => window.open(`${ADAMS_DCF_URL}/${e.payload.adamsDcfId}`, '_blank'),
        });
      }
      if (actions.length < 2 && e.payload.testsId) {
        actions.push({
          key: 'open',
          text: t('Open Test'),
          action: entities.test.urls().detail(e.payload.testsId),
        });
      }

      const map = {
        [EventStatus.IN_PROGRESS]: {
          primary: t('Send test #{{ testsId }} to ADAMS submitted', { testsId: e.payload.testsId }),
          secondary: t('The test is being sent to ADAMS.'),

          updatedAt: e.modifiedAt || e.createdAt,
          actions,
          icon: 'send',
          inProgress: true,
          read: e.isRead,
          type: NotificationTypeEnum.INFO,
          id: e.id,
        },

        [EventStatus.FINISHED]: {
          primary: t(
            e.payload.message === 'NOT_COMPLETED' || e.payload.message === 'ALREADY'
              ? 'Send test #{{ testsId }} to ADAMS partially succeeded'
              : 'Send test #{{ testsId }} to ADAMS completed',
            { testsId: e.payload.testsId }
          ),
          secondary:
            e.payload.message === 'NOT_COMPLETED'
              ? t(
                  'The DCF was saved but is not completed or it cannot be confirmed. Please check the DCF in ADAMS.'
                )
              : e.payload.message === 'ALREADY'
                ? t(
                    'There is already a matching DCF in ADAMS. Please check the existing DCF in ADAMS.'
                  )
                : t('The test has been successfully sent to ADAMS.'),

          updatedAt: e.modifiedAt || e.createdAt,
          actions,
          icon: 'send',
          inProgress: false,
          read: e.isRead,
          type:
            e.payload.message === 'NOT_COMPLETED' || e.payload.message === 'ALREADY'
              ? NotificationTypeEnum.WARNING
              : NotificationTypeEnum.SUCCESS,
          id: e.id,
        },

        [EventStatus.FAILED]: {
          primary: t('Send test #{{ testsId }} to ADAMS failed', { testsId: e.payload.testsId }),
          secondary: errorMessage || messages.UNKNOWN,
          updatedAt: e.modifiedAt || e.createdAt,
          actions,
          icon: 'send',
          inProgress: false,
          read: e.isRead,
          type: NotificationTypeEnum.ERROR,
          id: e.id,
        },

        [EventStatus.CREATED]: null,
        [EventStatus.WARNING]: null,
      };

      return map[e.status] || map[EventStatus.IN_PROGRESS];
    },
    [messages, t]
  );
}

type AdamsErrorCodes = keyof ReturnType<typeof useAdamsErrorMessages>;

function useAdamsErrorMessages() {
  const { t } = useTranslation();
  return useMemo(
    () => ({
      UNKNOWN: t('Internal Error.'),
      MAINTENANCE: t('ADAMS is undergoing maintenance. Retry the action later.'),
      UNAUTHORIZED: t('Could not authorize. Verify ADAMS credentials.'),

      INVALID_USERNAME: t(
        'Authentication failed likely because of an invalid username. Verify it is configured correctly.'
      ),
      INVALID_PASSWORD: t(
        'Authentication failed likely because of an invalid password. Verify it is configured correctly.'
      ),
      INVALID_TOTP: t(
        'Authentication failed likely because of an invalid TOTP. Verify it is configured correctly.'
      ),
      CHANGE_PASSWORD_REQUIRED: t('Password is no longer valid. Change your password in ADAMS.'),
      MISSING_PASSWORD_INPUT: t('ADAMS authentication does not work. Retry the action later.'),
      MISSING_OTP_INPUT: t('ADAMS TOTP does not work. Retry the action later.'),
      MISSING_TOTP_CONFIG: t('TOTP is not configured. Configure TOTP in ADAMS.'),
      TERMS_VERIFICATION_REQUIRED: t(
        'Terms & Conditions are not accepted. Accept Terms and Conditions in ADAMS.'
      ),
      CANNOT_SUBMIT_AUTH: t('Cannot authenticate. Retry the action later.'),
      CANNOT_SET_CONTRACT: t('Cannot use contracts. Retry the action later.'),
      CANNOT_FIND_CONTRACT: t('Cannot find Contract. Verify Testing Authority is set correctly.'),

      UNABLE_TO_PREPARE: t('Cannot prepare DCF data. Retry the action later.'),
      CANNOT_FIND_ATHLETE: t('Cannot find Athlete. Verify ADAMS ID is set correctly.'),
      ATHLETE_SEARCH_BROKEN: t('Athlete search does not work. Retry the action later.'),
      ATHLETE_RETRIEVAL_BROKEN: t('Athlete data cannot be retrieved. Retry the action later.'),
      CANNOT_OPEN_DCF_FORM: t('Cannot open DCF form. Retry the action later.'),
      INVALID_DCF_DATA: t('Cannot use DCF data. Check error details and retry the action later.'),
      CANNOT_FETCH_ITEMS: t('Cannot fetch list items. Retry the action later.'),
      UNKNOWN_SAMPLE_TYPE: t('Sample type sent do ADAMS it unknown. Notify us about the problem.'),
      MISSING_DOCUMENT_TYPES: t(
        'ADAMS is missing “Other“ or “Third Party“ document type. Retry the action later.'
      ),
      MISSING_SAMPLE_TYPES: t(
        'ADAMS is missing type of the sample being sent. Retry the action later.'
      ),
      SAMPLE_VALIDATION_FAILED: t(
        'Sample validation failed. Check error details and verify sample data is correct.'
      ),
      CANNOT_FIND_ORDER: t(
        'Cannot find corresponding Testing Order. Verify it is set correctly and open in ADAMS.'
      ),
      CANNOT_FETCH_USER_INFO: t(
        'ADAMS user profile info cannot be retrieved. Retry the action later.'
      ),

      INVALID_TESTING_AUTHORITY: t(
        'Testing Authority could not be found. Verify it is set correctly.'
      ),
      INVALID_RESULT_AUTHORITY: t(
        'Result Management Authority could not be found. Verify it is set correctly.'
      ),
      INVALID_COLLECTION_AUTHORITY: t(
        'Sample Collection Authority could not be found. Verify it is set correctly.'
      ),
      INVALID_COORDINATOR_AUTHORITY: t(
        'Doping Control Coordinator could not be found. Verify it is set correctly.'
      ),
      INVALID_COUNTRY: t('Country could not be found. Verify it is set correctly.'),
      INVALID_REGION: t('Region could not be found. Verify it is set correctly.'),
      INVALID_PARTICIPANT: t(
        'Invalid Participant name. Check details and verify it is set correctly.'
      ),
      INVALID_SPORT_DISCIPLINE: t(
        'Sport Discipline could not be found. Verify it is set correctly.'
      ),
      INVALID_SPORT_NATIONALITY: t(
        'Sport Nationality could not be found. Verify it is set correctly.'
      ),
      INVALID_LABORATORY: t('Laboratory could not be found. Verify it is set correctly.'),
      INVALID_SAMPLE_MANUFACTURER: t(
        'Sample Manufacturer could not be found. Verify it is set correctly.'
      ),
      MISSING_SAMPLES_TIME_ZONE: t(
        'Samples are missing a time zone information. Verify it is set correctly.'
      ),
      MISSING_ATHLETE_ADAMS_ID: t('Athlete is missing ADAMS ID. Verify it is set correctly.'),
      SENDING_DCF_FAILED: t('Saving DCF failed. Verify manually the DCF was saved.'), // mostly unknown err if SAMPLE_VALIDATION_FAILED does not appeared before
    }),
    [t]
  );
}
