import { FC, memo, useCallback, useState } from 'react';
import { Editor } from 'react-draft-wysiwyg';
import { useTranslation } from 'react-i18next';
import { useDispatch } from 'react-redux';

import { EntityKey } from 'app/entity';
import { saveDialogEntity } from 'core/actions';
import Button from 'core/components/Button';
import { EDITOR_CONFIG } from 'core/components/FormEditorField';
import { FieldGroup } from 'core/components/FormEditorField/styled';
import useAppSelector from 'core/hooks/useAppSelector';
import usePermission from 'core/hooks/usePermission';
import { useResources } from 'core/hooks/useResource';
import CommentModel from 'core/models/Comment';

import { ButtonWrapper, EditorWrapper } from '../../styled';
import { CommentsUrls } from '../../useCommentsUrls';
import useCanSeeRestrictedVisibility from '../useCanSeeRestrictedVisiblity';

import RestrictedVisibility from './RestrictedVisibility';
import useInputMapping from './useInputMapping';
import useOutputMapping from './useOutputMapping';

type Props = {
  toggleWrite: () => void;
  reload: () => void;
  record?: CommentModel;
  urls: CommentsUrls;
  linkedEntityId: number;
  linkedEntity: EntityKey;
  blacklistedRoles?: number[];
};

const CommentForm: FC<Props> = ({
  toggleWrite,
  reload,
  record,
  urls,
  linkedEntityId,
  linkedEntity,
  blacklistedRoles,
}) => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const isAdding = !record;
  const canSeeRestrictedVisibility = useCanSeeRestrictedVisibility(linkedEntity);
  const canSeeRoles = usePermission('roles:find');
  const saveInProgress = useAppSelector(({ core }) => core.dialogSaveInProgress);
  const { data: unrestrictedRoleIds } = useResources<number>(
    `${urls.entityList(true)}/comments/unrestrictedRoles`
  );

  const inputMapping = useInputMapping();
  const outputMapping = useOutputMapping();

  const [fieldValues, setFieldValues] = useState(inputMapping(record));

  const handleSave = useCallback(() => {
    dispatch(
      saveDialogEntity(
        record ? urls.commentsDetail(linkedEntityId, record.id) : urls.commentsList(linkedEntityId),
        outputMapping(fieldValues),
        record ? { ...outputMapping(inputMapping(record)) } : undefined,
        () => {
          setFieldValues(inputMapping(record));
          reload();
          toggleWrite();
        }
      )
    );
  }, [
    dispatch,
    reload,
    inputMapping,
    outputMapping,
    record,
    toggleWrite,
    linkedEntityId,
    urls,
    fieldValues,
  ]);

  return (
    <EditorWrapper noTopPadding={isAdding}>
      <FieldGroup hasError={false}>
        <Editor
          editorState={fieldValues.editorState}
          onEditorStateChange={(editorState) => setFieldValues({ ...fieldValues, editorState })}
          toolbar={EDITOR_CONFIG}
        />
      </FieldGroup>

      <ButtonWrapper>
        <div>
          <Button
            type="button"
            onClick={handleSave}
            text={isAdding ? t('Add a comment...') : t('Save')}
            disabled={
              !(fieldValues.editorState && fieldValues.editorState.getCurrentContent().hasText()) ||
              saveInProgress
            }
          />
          {!isAdding && <Button type="button" onClick={toggleWrite} text={t('Cancel')} />}
        </div>

        {canSeeRoles && canSeeRestrictedVisibility && (
          <RestrictedVisibility
            restrictToRoles={fieldValues.restrictToRoles as number[]}
            onChangeRoles={(newRoleIds) =>
              setFieldValues({ ...fieldValues, restrictToRoles: newRoleIds })
            }
            unrestrictedRoleIds={unrestrictedRoleIds || []}
            blacklistRoles={blacklistedRoles}
          />
        )}
      </ButtonWrapper>
    </EditorWrapper>
  );
};

export default memo(CommentForm);
