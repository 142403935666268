import styled from 'styled-components/macro';

import Modal from 'core/components/Modal';

export const ModalWithStrongOverlay = styled(Modal)`
  backdrop-filter: blur(10px);
`;

export const StyledLoginFieldsetWrapper = styled.div`
  padding-bottom: 1rem;
`;
