import { useMemo } from 'react';

import { Option } from 'core/components/DropDown';
import { FilterValue, DropDownFilter } from 'core/containers/DataGrid';

import { defaultApplyToParams } from './useMultiDropdownResourceFilter';

const DEFAULT_VALUE = ['any'];

const useMultiDropdownOptionsFilter = (
  label: string,
  property: string,
  options: Option[],
  defaultValue: string[] | number[] = DEFAULT_VALUE,
  applyToParams?: (value: FilterValue, property: string) => any
): DropDownFilter => {
  return useMemo(
    () => ({
      type: 'dropdown',
      label: label,
      defaultValue: defaultValue,
      applyToParams: (value) =>
        applyToParams ? applyToParams(value, property) : defaultApplyToParams(value, property),
      single: false,
      options: options,
      onChange: (
        newValue: FilterValue,
        oldValue: FilterValue,
        onFiltersChange: (newOnChangeVal: FilterValue) => void
      ) => {
        // Can't be empty - defaults to 'Any'
        if (!Array.isArray(newValue) || (Array.isArray(newValue) && newValue.length === 0))
          return onFiltersChange(['any']);

        if (newValue.includes('any') && (!Array.isArray(oldValue) || !oldValue.includes('any'))) {
          // When we didn't have 'any' previously and selected it - we select only that (and unselect others)
          return onFiltersChange(['any']);
        } else if (
          newValue.includes('any') &&
          newValue.length > 1 &&
          Array.isArray(oldValue) &&
          oldValue.includes('any')
        ) {
          // When we did have 'any' and we selected more, we unselected 'any'
          return onFiltersChange(newValue.filter((id) => id !== 'any'));
        } else {
          // We are just selecting values
          onFiltersChange(newValue);
        }
      },
    }),
    [applyToParams, defaultValue, label, options, property]
  );
};

export default useMultiDropdownOptionsFilter;
