import { ReactNode, useCallback, useEffect, useMemo, useRef } from 'react';
import { useDispatch } from 'react-redux';

import { ApiCallAction, apiQuery } from 'core/actions';
import { ApiQuery, ApiQueryStatus } from 'core/reducer';

import useAppSelector from '../useAppSelector';

import { useClearApiCache } from './ApiCache';

type UseApiResult<T> = ApiQuery<T> & {
  dispatch: (options?: ApiQueryOptions, callback?: (res: ApiQuery<T>) => void) => void;
};

type FinalApiQuery<T> = Omit<ApiQuery<T>, 'status'> & {
  status: ApiQueryStatus.ERROR | ApiQueryStatus.SUCCESS;
};

export interface BulkResponse<T = any> {
  data: T[];
  updated: number;
}

/**
 * Register for instances, looking for some resource
 * !!! Only those, which are waiting for response (or will be by config) = autoload: false -> will return cached or ""
 */
const watchMap: Record<string, Set<Symbol>> = {};

/**
 * Was this resource already loaded
 */
const loadedMap: Record<string, boolean> = {};

export type ApiQueryOptions = Partial<Omit<ApiCallAction['payload'], 'actionPrefix'>> & {
  /**
   * Load query immediately
   */
  autoload?: boolean;
  /**
   * use stale Data until next response is returned
   * by default its true
   */
  cache?: boolean;
  /**
   * Indicate using old data until new arrives
   * By default its true
   */
  stale?: boolean;
  /**
   * Loading indicator messages
   * - GET -> error only
   * - PATCH, POST, DELETE -> loading, success, error
   */
  loadingMessage?: ReactNode;
  successMessage?: ReactNode;
  errorMessage?: ReactNode;
  /**
   * Query params
   * - are computed to get Unique ID
   */
  params?: Record<string, unknown>;
};

export function useApiCall<T = unknown>(
  /**
   * Request url
   * if its falsy, we skip loading data
   * because of hooks cannot be called conditionally = params can cancel calls
   * every resource is re-evaluated once this param is changed = trigger request if not cached
   */
  urlOrId?: string,
  /**
   * Api call options
   * optional request config, by default we ask for autoLoaded GET based on urlOrId
   */
  options?: ApiQueryOptions,
  onStateChange?: (res: ApiQuery<T>) => void
): UseApiResult<T> {
  const reduxDispatch = useDispatch();
  const clearApi = useClearApiCache();

  const id = urlOrId || '';

  const state = useAppSelector((s) => s.core.apiQueries[id] as undefined | ApiQuery<T>);

  const callbacks = useRef<Array<(state: FinalApiQuery<T>) => void>>([]);
  const optionsRef = useRef(options);
  useEffect(() => {
    optionsRef.current = options;
  }, [options]);

  const dispatch = useCallback(
    (overrides?: ApiQueryOptions, cb?: (state: FinalApiQuery<T>) => void) => {
      const options = calcFinalOptions(id, {
        ...optionsRef.current,
        ...(overrides || {}),
      });

      cb && callbacks.current.push(cb);
      reduxDispatch(apiQuery(options.id, options));

      loaded(id);
    },
    [reduxDispatch, id]
  );

  const finalOptions = useMemo(() => calcFinalOptions(id, options), [id, options]);
  const { autoload } = finalOptions;

  // Autoload if autoload starts with true or id changes
  useEffect(() => {
    const unsubscribe = watch(id);

    autoload && dispatch();

    return () => {
      unsubscribe();

      if (!id || hasWatchers(id) || !wasLoaded(id)) return;

      clearApi(id);
      unloaded(id);
    };
    // This transitively has all the needed dependencies, we don't want autoload dependency
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [dispatch]);

  // Autoload if autoload changes from false to true
  useEffect(() => {
    if (wasLoaded(id) || !autoload) return;

    dispatch();
    // We only want to trigger the autoload if we didn't load yet
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [autoload]);

  const res = useMemo(
    () => ({
      status: state?.status || (autoload ? ApiQueryStatus.LOADING : ApiQueryStatus.IDLE),
      response: state?.response,
      options: finalOptions,
      error: state?.error,
      data: state?.data,
      dispatch,
    }),
    [autoload, dispatch, finalOptions, state?.data, state?.error, state?.response, state?.status]
  );

  const status = res?.status;

  // Trigger all "callbacks" waiting for resolution
  useEffect(() => {
    // trigger dispatch callbacks registered manually
    if (state && [ApiQueryStatus.ERROR, ApiQueryStatus.SUCCESS].includes(status)) {
      callbacks.current.forEach((cb) => cb(state as FinalApiQuery<T>));
      callbacks.current = [];
    }

    // trigger general watcher - status changes
    onStateChange && onStateChange(res);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [status]);

  return res;
}

/** register watcher */
function watch(path: string): () => void {
  if (!path) {
    return () => {};
  }

  watchMap[path] = watchMap[path] || new Set();
  const uid = Symbol(path);
  watchMap[path].add(uid);

  return () => {
    watchMap[path].delete(uid);
  };
}

/** check if no-one listening data */
function hasWatchers(path: string): boolean {
  return watchMap[path]?.size > 0;
}

/**
 * Mark resource as loaded
 */
function loaded(path: string) {
  if (!path) return;

  loadedMap[path] = true;
}

/**
 * Mark resource as no longer loaded
 */
function unloaded(path: string) {
  if (!path) return;

  delete loadedMap[path];
}

/**
 * Check if the resource was already loaded
 */
function wasLoaded(path: string) {
  return loadedMap[path] || false;
}

function calcFinalOptions(urlOrId: string, options?: ApiQueryOptions) {
  const conf = options || ({} as Partial<ApiQueryOptions>);
  const method = conf.method || 'GET';
  const autoload = 'autoload' in conf ? conf.autoload : method === 'GET';
  const cache = 'cache' in conf ? conf.cache : method === 'GET';
  const stale = 'stale' in conf ? conf.cache : true;
  const id = urlOrId;
  const url = conf.url || urlOrId;

  return {
    ...options,
    autoload,
    method,
    cache,
    stale,
    url,
    id,
  } as const;
}
