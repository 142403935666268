import { FC } from 'react';

import { Row } from 'core/components/Grid';
import { HalfRowCell } from 'core/components/GridCell';
import 'core/containers/DataGrid/styles.scss';

import CocsTable from './EntityTables/CocsTable';
import ReportsTable from './EntityTables/ReportsTable';
import SamplesTable from './EntityTables/SamplesTable';
import TestsTable from './EntityTables/TestsTable';

const ContentsTab: FC = () => {
  return (
    <Row $fullHeight>
      <HalfRowCell>
        <Row $fullHeight>
          <TestsTable />
          <CocsTable />
        </Row>
      </HalfRowCell>

      <HalfRowCell>
        <Row $fullHeight>
          <ReportsTable />
          <SamplesTable />
        </Row>
      </HalfRowCell>
    </Row>
  );
};

export default ContentsTab;
