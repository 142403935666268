import { useMemo } from 'react';
import { useTranslation } from 'react-i18next';

import { RECIPIENTS } from '.';

const useRecipientOptionHelper = () => {
  const { t } = useTranslation();

  return useMemo(() => {
    return (recipient: RECIPIENTS) => {
      switch (recipient) {
        case RECIPIENTS.LABORATORY:
          return { name: t('Laboratory'), id: RECIPIENTS.LABORATORY };
        case RECIPIENTS.AUTHORITY:
          return { name: t('Authority'), id: RECIPIENTS.AUTHORITY };
        case RECIPIENTS.DCO:
          return { name: t('DCO(s)'), id: RECIPIENTS.DCO };
        case RECIPIENTS.ATHLETE:
          return { name: t('Athlete'), id: RECIPIENTS.ATHLETE };
        default:
          return { name: t('Other'), id: RECIPIENTS.OTHER };
      }
    };
  }, [t]);
};

export default useRecipientOptionHelper;
