import { HTMLAttributeAnchorTarget, forwardRef } from 'react';

import MenuItem from './MenuItem';
import { StyledMenu } from './styled';

export type Item<K = string> = {
  onClick?: (item: Item<K>) => void;
  onMouseDown?: (item: Item<K>) => void;
  confirmation?: string;
  disabled?: boolean;
  active?: boolean;
  icon?: string;
  text: string;
  to?: string | { url: string; target: HTMLAttributeAnchorTarget };
  title?: string;
  key: K;
  id?: string;
  form?: string;
  type?: HTMLButtonElement['type'];
};

interface Props {
  onItemClick?: (item: Item) => void;
  onItemMouseDown?: (item: Item) => void;
  items: Array<Item | 'SEPARATOR'>;
  id?: string;
}

const Menu = forwardRef<HTMLDivElement, Props>(
  ({ id, items, onItemClick, onItemMouseDown }, ref) => (
    <StyledMenu ref={ref} id={id} role="listbox" aria-expanded="true">
      {items.map((item, index: number) => {
        if (item === 'SEPARATOR') {
          return item === 'SEPARATOR' ? <hr key={`SEPARATOR_${index}`} /> : null;
        }

        const { onClick, onMouseDown, ...passing } = item;
        const clickHandler = onClick || onItemClick || undefined;
        const mouseDownHandler = onMouseDown || onItemMouseDown || undefined;

        return (
          <MenuItem
            {...passing}
            key={item.key}
            id={id && passing.id ? `${id}-option-${passing.id}` : undefined}
            onClick={clickHandler ? () => clickHandler(item) : undefined}
            onMouseDown={mouseDownHandler ? () => mouseDownHandler(item) : undefined}
            title={item.title}
          />
        );
      })}
    </StyledMenu>
  )
);

export default Menu;
