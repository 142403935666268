import { ICellRendererParams, GridApi } from 'ag-grid-community';

import { t } from 'core/i18n';

import styles from './index.module.css';

export default class StatusDotCellRenderer {
  private element?: HTMLButtonElement;
  private state: 'ALL' | 'NONE' | 'SOME' = 'NONE';
  private eventListener?: () => void;

  public init({ api }: ICellRendererParams) {
    this.element = document.createElement('button');
    this.element.setAttribute('role', 'checkbox');
    this.element.setAttribute('type', 'button');
    this.element.setAttribute('aria-label', t('Toggle selection of all rows'));
    this.element.className = `${styles.checkbox} material-icons`;
    this.setIcon(api, this.element);

    this.eventListener = () => {
      if (this.state === 'NONE') {
        api.forEachNode((node) => node.setSelected(true));
        // We set the icon right away to prevent delay in the button status change
        this.setIcon(api, this.element!);
      } else {
        api.deselectAll();
        // We set the icon right away to prevent delay in the button status change
        this.setIcon(api, this.element!);
      }
    };
    this.element.addEventListener('click', this.eventListener);
  }

  public getGui() {
    // Ag-grid should call init before getGui, so we should be fairly sure
    return this.element!;
  }

  public refresh({ api }: ICellRendererParams) {
    // Tell grid the whole component needs to be rendered
    if (!this.element) return false;

    this.setIcon(api, this.element);

    // Tell grid we rerendered successfully
    return true;
  }

  public destroy() {
    if (this.element && this.eventListener) {
      this.element.removeEventListener('click', this.eventListener);
    }
  }

  private setIcon(api: GridApi, element: HTMLElement) {
    let rowCount = 0;
    api.forEachNode(() => rowCount++);

    let icon = 'check_box_outline_blank';
    if (api.getSelectedNodes().length === rowCount && rowCount !== 0) {
      icon = 'check_box';
      this.state = 'ALL';
    } else if (api.getSelectedNodes().length > 0) {
      icon = 'indeterminate_check_box';
      this.state = 'SOME';
    }

    element.setAttribute(
      'aria-checked',
      this.state === 'ALL' ? 'true' : this.state === 'SOME' ? 'mixed' : 'false'
    );
    element.classList.remove(
      'icon-check_box_outline_blank',
      'icon-indeterminate_check_box',
      'icon-checkbox'
    );
    element.style.overflow = 'visible';
    element.classList.add(`icon-${icon}`);
    element.innerHTML = `<span aria-hidden="true">${icon}</span>`;
  }
}
