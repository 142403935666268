import styled from 'styled-components/macro';

import Modal from 'core/components/Modal';

export const MaxScreenWidthModal = styled(Modal)`
  [role='dialog'] {
    max-width: 100vw;
  }
`;

export const MinWidthTable = styled.table`
  min-width: 75rem;
  overflow-y: auto;
`;
