function fromEntries(iterable: Array<[string, unknown]>) {
  return [...iterable].reduce(
    (obj, [key, val]) => {
      obj[key] = val;
      return obj;
    },
    {} as { [key: string]: unknown }
  );
}

const entries = () => {
  if (!Object.fromEntries) {
    Object.fromEntries = fromEntries;
  }
};

export default entries;
