import { Chip } from '@material/react-chips';
import classnames from 'classnames';
import {
  FC,
  FocusEvent,
  KeyboardEvent,
  MutableRefObject,
  useCallback,
  useMemo,
  useRef,
  useState,
} from 'react';

import MaterialIcon from 'core/components/MaterialIcon';

import { ChipsInput, Input } from './styled';

type Props = {
  onChange: (value: null | string[]) => void;
  onTextChange: (value: string) => void;
  chipErrors?: Array<string | null>;
  value: null | string[];
  textValue: string;
  disabled?: boolean;
  name: string;
  error?: boolean;
  label: string;
  /**
   * Optional confirmation behavior
   *
   * Can be:
   * - Key code - confirmation is triggered when pressing the selected key and on blur, see https://www.w3.org/TR/uievents-key/#named-key-attribute-values for available key codes
   * - false - disables confirmation using keyboard and on blur
   * Defaults to Enter if no value is provided.
   */
  confirmation?: string | false;
  inputRef?: MutableRefObject<HTMLInputElement | null>;
  onBlur?: (e: FocusEvent<HTMLInputElement>) => void;
  id?: string;
};

const ChipsField: FC<Props> = ({
  chipErrors,
  onChange,
  onTextChange,
  disabled,
  error,
  value,
  textValue,
  name,
  label,
  id,
  confirmation = 'Enter',
  inputRef: forwardedInputRef,
  onBlur,
}) => {
  const [focused, setFocus] = useState<boolean>(false);
  const chips = useMemo(() => value || [], [value]);

  const handleKeyDown = useCallback(
    (event: KeyboardEvent<HTMLInputElement>) => {
      if (disabled || !(event.target instanceof HTMLInputElement)) {
        return;
      }
      const targetValue = event.target.value;
      if (confirmation !== false && event.key === confirmation && targetValue) {
        event.preventDefault();
        event.stopPropagation();
        const res = [...chips, targetValue];
        onChange(res);
        onTextChange('');
      } else if (event.key === 'Backspace' && !targetValue && chips.length > 0) {
        const res = chips.slice(0, -1);
        onChange(res);
      }
    },
    [disabled, onChange, onTextChange, confirmation, chips]
  );

  const handleRemove = useCallback(
    (index: number) => () => {
      if (disabled) {
        return;
      }
      const value = [...chips];
      value.splice(index, 1);
      onChange(value);
    },
    [disabled, onChange, chips]
  );

  const inputRef = useRef<HTMLInputElement | null>();
  const htmlId = id || `chip-input-${name}`;

  const focusInput = useCallback(() => {
    inputRef.current?.focus();
  }, [inputRef]);

  const renderChips = useMemo(
    () =>
      chips.map((chip: string, key: number) => (
        <Chip
          className={classnames({ 'has-error': chipErrors && chipErrors[key] })}
          data-testid="chip"
          handleTrailingIconInteraction={handleRemove(key)}
          trailingIcon={<MaterialIcon icon="cancel" />}
          shouldRemoveOnTrailingIconClick={false}
          id={key.toString()}
          label={chip}
          key={key}
        />
      )),
    [chips, chipErrors, handleRemove]
  );

  return (
    <ChipsInput
      className={classnames('mdc-text-field mdc-text-field--outlined', {
        'mdc-text-field--error': error || (chipErrors && chipErrors.length > 0),
      })}
      onFocus={focusInput}
      onClick={focusInput}
      role="button"
      data-cy={`chips-field-${name}`}
    >
      {renderChips}

      <Input
        size={10}
        name={name}
        $textLen={(textValue || '').length}
        onChange={(e) => {
          const dif = (e.target.value || '').length - textValue.length;
          if (dif > 1) {
            // test ctrl + v and split on white space
            onChange([...chips, ...(e.target.value || '').split(' ').filter((i) => i)]);
            onTextChange('');
          } else {
            onTextChange(e.target.value || '');
          }
        }}
        onFocus={() => setFocus(true)}
        onBlur={(e) => {
          if (confirmation !== false && textValue) {
            onChange([...(value || []), textValue]);
            onTextChange('');
          }
          setFocus(false);
          onBlur && onBlur(e);
        }}
        onKeyDown={handleKeyDown}
        disabled={disabled}
        value={textValue}
        ref={(element) => {
          inputRef.current = element;
          if (forwardedInputRef) forwardedInputRef.current = element;
        }}
        type="text"
        id={htmlId}
      />
      {label && (
        <label
          className={classnames('mdc-floating-label', {
            'mdc-floating-label--float-above': focused || chips.length > 0 || textValue,
            'mdc-floating-label--error': error || (chipErrors && chipErrors.length > 0),
          })}
          htmlFor={htmlId}
          onFocus={focusInput}
          onClick={focusInput}
        >
          {label}
        </label>
      )}
    </ChipsInput>
  );
};

export default ChipsField;
