import { definition, getStandardApi, getStandardUrls } from 'core/module';

import { ProjectTranslationsList } from './models/Translation';

type T = (m: string) => string;

export const translationEntities = {
  translation: {
    ...definition<ProjectTranslationsList>('translations', {
      name: (t: T) => t('Translations'),
      listName: (t: T) => t('Translations'),
      permission: 'projectTranslations',
    }),
    api: (project: number | string) => ({
      ...getStandardApi(`translations/projects/${project}`),
      list: 'translations/projects',
    }),
    urls: (project: number | string) => ({
      ...getStandardUrls(`translations/${project}`),
      list: '/translations',
    }),
  },
} as const;

export type K = keyof typeof translationEntities;
