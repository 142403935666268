import { useCallback, useState, memo, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch } from 'react-redux';

import { urls } from 'app/urls';
import { logout } from 'core/actions';
import MaterialIcon from 'core/components/MaterialIcon';
import NotificationsIcon from 'core/components/NotificationsIcon';
import StyledIcon from 'core/components/StyledIcon';
import Tooltip from 'core/components/Tooltip';
import useAppSelector from 'core/hooks/useAppSelector';
import usePermission from 'core/hooks/usePermission';

import RenewLoginDialog from '../RenewLoginDialog';

import Account from './Account';
import Brand from './Brand';
import Navigation from './Navigation';
import { GroupHeader, Heading } from './Navigation/NavGroup/styled';
import Notifications from './Notifications';
import { SideWrap } from './styled';

const Sidebar = () => {
  const [openNotifications, setOpenNotifications] = useState(false);
  const canViewDashboard = usePermission('dashboard:view');
  const user = useAppSelector((s) => s.core.user!);
  const [collapse, setCollapse] = useState(localStorage.getItem('sidebarCollapse') === 'true');
  const { t } = useTranslation();
  const dispatch = useDispatch();

  const handleLogout = useCallback(() => dispatch(logout()), [dispatch]);
  const handleClose = useCallback(() => setOpenNotifications(false), []);
  const handleOpen = useCallback(() => setOpenNotifications(true), []);
  const handleToggleCollapse = useCallback(() => setCollapse((c) => !c), []);

  useEffect(() => {
    localStorage.setItem('sidebarCollapse', collapse.toString());
  }, [collapse]);

  return (
    <SideWrap collapse={collapse}>
      <Account
        user={user}
        collapse={collapse}
        onLogout={handleLogout}
        openNotifications={openNotifications}
        setOpenNotifications={setOpenNotifications}
      />

      <Navigation collapse={collapse} />

      {collapse && <NotificationsIcon expand onOpen={handleOpen} />}

      <Brand collapse={collapse} link={canViewDashboard ? '/' : urls.account} />

      <Tooltip content={collapse ? t('Expand Menu') : t('Collapse Menu')} placement="right">
        <GroupHeader onClick={handleToggleCollapse} collapse={collapse}>
          <StyledIcon>
            <MaterialIcon icon={collapse ? 'keyboard_arrow_right' : 'keyboard_arrow_left'} />
          </StyledIcon>
          <Heading>{t('Collapse')}</Heading>
        </GroupHeader>
      </Tooltip>

      <RenewLoginDialog />

      <Notifications open={openNotifications} onClose={handleClose} />
    </SideWrap>
  );
};

export default memo(Sidebar);
