import { ColDef, GridApi } from 'ag-grid-community';
import { AgGridReact } from 'ag-grid-react';
import { FC, useCallback, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useHistory } from 'react-router';

import { entities } from 'app/entity';
import AgGridPaperWrapper from 'core/components/AgGridPaperWrapper';
import { AvatarCellRenderer, StatusCellRenderer } from 'core/containers/DataGrid/FastCellRenderers';
import { onCopyableRowClicked } from 'core/containers/DataGrid/helpers';
import 'core/containers/DataGrid/styles.scss';
import useGetFormatAmount from 'core/hooks/useGetFormatAmount';
import { getCustomUserAvatarUrl } from 'core/models/CoreProfile';
import {
  useGetInvoiceStatusColor,
  useGetInvoiceStatusName,
} from 'finance/containers/DCOInvoicesPage/useInvoiceStatus';
import { DatagridDCOInvoice } from 'finance/models/DCOInvoice';

interface Props {
  invoices: DatagridDCOInvoice[];
}

const InvoicesTable: FC<Props> = ({ invoices }) => {
  const [gridApi, setGridApi] = useState<GridApi | undefined>(undefined);
  const getStatus = useGetInvoiceStatusColor();
  const getStatusName = useGetInvoiceStatusName();
  const formatAmount = useGetFormatAmount();
  const { t } = useTranslation();
  const history = useHistory();

  const columnDefs: ColDef[] = useMemo(
    () => [
      {
        width: 60,
        minWidth: 60,
        field: 'status',
        cellRenderer: 'statusCellRenderer',
        valueGetter: ({ data: { status } }) => getStatusName({ status }),
        cellRendererParams: {
          color: (data: DatagridDCOInvoice) => getStatus({ status: data.status }),
        },
        suppressSizeToFit: true,
      },
      {
        width: 55,
        minWidth: 55,
        resizable: true,
        headerName: t('User'),
        field: 'usersFullName',
        suppressSizeToFit: true,
        cellRenderer: 'avatarCellRenderer',
        cellRendererParams: {
          title: (invoice: DatagridDCOInvoice) => invoice.userFullName,
          containsImg: (invoice: DatagridDCOInvoice) => invoice.avatarStorageKey,
          url: (invoice: DatagridDCOInvoice) =>
            getCustomUserAvatarUrl(invoice.usersId, invoice.avatarStorageKey),
        },
      },
      {
        headerName: t('Invoice Number'),
        field: 'invoiceNumber',
        minWidth: 120,
        width: 150,
      },
      {
        headerName: t('Plan / Method'),
        width: 250,
        minWidth: 200,
        valueGetter: ({ data: { paymentPlanName, paymentMethodName } }) => {
          return `${paymentPlanName}/${paymentMethodName}`;
        },
      },
      {
        headerName: t('Amount'),
        field: 'totalGross',
        valueGetter: ({ data: { totalGross, currencyCode } }) => {
          return formatAmount(totalGross, currencyCode);
        },
        suppressSizeToFit: true,
        width: 100,
      },
    ],
    [formatAmount, getStatus, getStatusName, t]
  );

  const components = useMemo(
    () => ({
      avatarCellRenderer: AvatarCellRenderer,
      statusCellRenderer: StatusCellRenderer,
    }),
    []
  );

  const handleOpenInvoice = useCallback(
    ({ data: { id, usersId } }: { data: { id: number; usersId: number } }) => {
      history.push(entities.dcoInvoice.urls(usersId).detail(id));
    },
    [history]
  );

  if (!invoices || invoices.length === 0) {
    return <em>{t('No Linked Invoices')}</em>;
  }

  return (
    <AgGridPaperWrapper
      customHeight={Math.min(15, 3.5 + invoices.length * 3.16)} // 3.5rem header + N * ( 3rem row + 0.16rem border )
    >
      <AgGridReact
        suppressHorizontalScroll={false}
        onRowClicked={(e) => onCopyableRowClicked(e, handleOpenInvoice)}
        enableCellTextSelection
        ensureDomOrder
        components={components}
        columnDefs={columnDefs}
        rowData={invoices}
        onGridReady={({ api }) => {
          setGridApi(api);
        }}
        onFirstDataRendered={() => {
          gridApi?.sizeColumnsToFit();
        }}
      />
    </AgGridPaperWrapper>
  );
};

export default InvoicesTable;
