import { FC, memo, useCallback } from 'react';
import { useTranslation } from 'react-i18next';

import { Option } from 'core/components/DropDown';
import Field from 'core/components/Form/Field';
import useFormFieldValue from 'core/components/Form/useFormFieldValue';
import { Row } from 'core/components/Grid';
import { FullRowCell, HalfRowCell } from 'core/components/GridCell';
import ResourceFormDropdown from 'core/components/ResourceFormDropdown';
import { useResources } from 'core/hooks/useResource';
import SportDiscipline from 'lists/models/SportDiscipline';
import BlacklistSportDisciplines from 'personnel/models/BlacklistSportDisciplines';

type Props = {
  isEdit: boolean;
  usersId: number;
};

const SportDisciplines: FC<Props> = memo(({ isEdit, usersId }) => {
  const { t } = useTranslation();

  const { data: disciplinesBlacklist } = useResources<BlacklistSportDisciplines>(
    `blacklist/sport-disciplines/user/${usersId}`
  );

  const selectedSportsId = useFormFieldValue('sportsId');

  const disciplineOptionsReducer = useCallback(
    (list: SportDiscipline[], val: null | number) => {
      return list.reduce((acc: Option[], { id, active, name, sportsId }) => {
        if (sportsId === selectedSportsId && (active || id === val)) {
          if (isEdit) {
            // display all for editation
            acc.push({ id, name });
          } else {
            // For create only items which are not yet blacklisted
            const alreadyBlacklisted = (disciplinesBlacklist || []).find(
              (it) => it.sportDisciplinesId === id
            );

            if (!alreadyBlacklisted) {
              acc.push({ id, name });
            }
          }
        }
        return acc;
      }, []);
    },
    [selectedSportsId, disciplinesBlacklist, isEdit]
  );

  return (
    <FullRowCell>
      <Row>
        <HalfRowCell>
          <Field
            component={ResourceFormDropdown}
            resource="sports"
            label={t('Sport')}
            name="sportsId"
            fast={false}
            single
            viewMode={isEdit}
          />
        </HalfRowCell>

        <HalfRowCell>
          <Field
            resourceReducer={disciplineOptionsReducer}
            component={ResourceFormDropdown}
            label={t('Sport Discipline')}
            resource={'sports/disciplines'}
            name="sportDisciplinesId"
            single
            fast={false}
            viewMode={isEdit || !selectedSportsId}
          />
        </HalfRowCell>
      </Row>
    </FullRowCell>
  );
});

export default SportDisciplines;
