import { memo, useEffect, FC, createRef } from 'react';

import { Wrapper, StyledYearOption } from './styled';

interface Props {
  onChange: (value: Date) => void;
  years: number[];
  date: Date;
}

const YearSelector: FC<Props> = ({ years, date, onChange }) => {
  const wrapperRef = createRef<HTMLDivElement>();
  // Scroll so that chosen year is in the middle
  useEffect(() => {
    if (wrapperRef.current === null) {
      return;
    }
    const chosenElement = wrapperRef.current.querySelector<HTMLSpanElement>('span[data-chosen]');
    if (chosenElement) {
      const chosenPosition = chosenElement.offsetTop + chosenElement.clientHeight / 2;
      const scrollPosition = chosenPosition - wrapperRef.current.clientHeight / 2;
      wrapperRef.current &&
        wrapperRef.current.scrollTo &&
        wrapperRef.current.scrollTo(0, scrollPosition);
    }
  }, [wrapperRef]);

  return (
    <Wrapper ref={wrapperRef}>
      {years.map((y) => {
        const chosen = date.getFullYear() === y;
        return (
          <StyledYearOption
            tabIndex={chosen ? 0 : undefined}
            key={y}
            chosen={chosen}
            onClick={() => {
              const val = new Date(date);
              val.setUTCFullYear(y);
              onChange(val);
            }}
          >
            {y}
          </StyledYearOption>
        );
      })}
    </Wrapper>
  );
};

export default memo(YearSelector);
