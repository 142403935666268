import { FC, useMemo } from 'react';
import { useTranslation } from 'react-i18next';

import theme, { StatusColor } from 'app/theme';
import Badge from 'core/components/Badge';
import MaterialIcon, { MaterialIconProps } from 'core/components/MaterialIcon';
import Media from 'core/components/Media';
import Tooltip from 'core/components/Tooltip';

import { BadgeWrapper, MetaGroup, StatusWrap, StyledContent } from './styled';
import Tabs, { TabOption } from './Tabs';
import Time from './Time';

interface Props {
  statusText?: string;
  statusColor?: StatusColor;
  statusIcon?: string; // TODO: Improve types: https://cannypack.atlassian.net/browse/FEC-391
  options?: TabOption[];
  modified?: Date;
  created?: Date;
  /**
   * Used if neither modified or created is present
   */
  chosenTimeZone?: string;
  /**
   * If passed does not open the default details model but calls the callback
   */
  onOpenTimeDetails?: () => void;
  icons?: MaterialIconProps[];
  statusBadgeVariant?: string;
  statusBadgeText?: string;
  archived?: boolean;
}

const DetailSubheader: FC<Props> = ({
  statusText,
  statusColor,
  statusIcon,
  statusBadgeVariant,
  statusBadgeText,
  options,
  modified,
  created,
  archived,
  icons,
  chosenTimeZone,
  onOpenTimeDetails,
}) => {
  const { t } = useTranslation();
  const statusIcons = useMemo(
    () =>
      (archived === true
        ? [
            {
              tooltip: t('Archived'),
              icon: 'archive',
              color: theme.color.status.gray,
            } as MaterialIconProps,
          ]
        : []
      ).concat(icons || []),
    [archived, icons, t]
  );

  const statusEl = statusText && statusColor && (
    <StatusWrap data-cy="entity-status">
      <Tooltip content={statusText}>
        <MaterialIcon
          icon={statusIcon || theme.color.statusIcons[statusColor]}
          color={theme.color.status[statusColor]}
        />
      </Tooltip>
      {statusBadgeText && (
        <BadgeWrapper>
          <Badge variant={statusBadgeVariant}>{statusBadgeText}</Badge>
        </BadgeWrapper>
      )}
    </StatusWrap>
  );

  // There is no info to show and there is no onOpenDetails (custom dialog)
  const timeEl = (modified || created || chosenTimeZone || onOpenTimeDetails) && (
    <Time
      modified={modified}
      created={created}
      chosenTimeZone={chosenTimeZone}
      onOpenDetails={onOpenTimeDetails}
    />
  );

  return (
    <StyledContent>
      {options && options.length > 0 && <Tabs options={options} />}

      {(statusEl || timeEl || statusIcons.length > 0) && (
        <MetaGroup>
          <Media query="tabletDesktop">
            {statusEl || null}

            {statusIcons.length > 0 &&
              statusIcons.map((el) => (
                <StatusWrap>
                  <MaterialIcon size={1.2} color={theme.color.status.gray} {...el} key={el.icon} />
                </StatusWrap>
              ))}

            {timeEl || null}
          </Media>
        </MetaGroup>
      )}
    </StyledContent>
  );
};

export default DetailSubheader;
