import * as OTPAuth from 'otpauth';

/**
 * Get TOTP token for secret
 *
 * @param secret BASE32 secret
 * @param options Setup of the token, defaults to SHA1 with 6 digits, period of 30
 * @returns Token
 */
export const getTotpFromSecret = (
  secret: string,
  options: ConstructorParameters<typeof OTPAuth.TOTP>[0] = {
    algorithm: 'SHA1',
    digits: 6,
    period: 30,
  }
) => {
  const totp = new OTPAuth.TOTP({
    secret: OTPAuth.Secret.fromBase32(secret),
    ...options,
  });

  return totp.generate();
};

export const clearSecret = (secret?: string) => secret?.toUpperCase().replace(/\s|-/g, '') || '';

export const addSpaces = (word: string, indexForSpaceAndDashes = 4) => {
  const lettersArray = Array.from(word).reduce((acc: string[], it: string, index) => {
    if (index + 1 !== word.length && (index + 1) % indexForSpaceAndDashes === 0) {
      acc.push(it);
      acc.push(' ');
      return acc;
    }

    acc.push(it);
    return acc;
  }, []);

  return lettersArray.join('');
};

export const isSecretValid = (secret?: string) => {
  if (!secret) {
    return false;
  }

  try {
    const filtered = clearSecret(secret);
    getTotpFromSecret(filtered);

    // For cast to hex
    return filtered.length % 8 === 0;
  } catch {
    return false;
  }
};
