import React, { useMemo } from 'react';
import { useTranslation } from 'react-i18next';

import ContextMenu from 'core/components/ContextMenu';
import IconButton from 'core/components/IconButton';
import { Item } from 'core/components/Menu';
import useAppSelector from 'core/hooks/useAppSelector';
import { UserView } from 'core/models/UserView';

import { ViewTabs } from '../useViewTabs';

interface Props {
  activeView: UserView | null;
  activeTab: ViewTabs;
  setActiveTab: (newActiveTab: ViewTabs) => void;
  onClone: () => void;
}

const SaveButton: React.FC<Props> = ({ activeTab, setActiveTab, activeView, onClone }) => {
  const { t } = useTranslation();
  const isFormTabActive = activeTab === ViewTabs.DETAILS;
  const saveAsNewViewAvailable = activeView && !!activeView.default;

  const { savingInProgress } = useAppSelector(({ core }) => ({
    savingInProgress: core.viewSavingInProgress,
  }));

  const saveActions: Array<Omit<Item, 'onClick'> & { onClick: () => void }> = useMemo(() => {
    return [
      {
        key: 'saveChanges',
        text: t('Save changes'),
        onClick: () => {
          if (!isFormTabActive) {
            setActiveTab(ViewTabs.DETAILS);
          }
        },
        disabled: savingInProgress,
        type: isFormTabActive ? 'submit' : 'button',
        form: isFormTabActive ? 'viewForm' : undefined,
      },
      ...(saveAsNewViewAvailable
        ? [
            {
              key: 'createNew',
              text: t('Save as new View'),
              onClick: onClone,
            },
          ]
        : []),
    ];
  }, [t, saveAsNewViewAvailable, isFormTabActive, onClone, setActiveTab, savingInProgress]);

  return (
    <>
      {!saveAsNewViewAvailable && (
        <IconButton
          icon="save"
          tooltip={savingInProgress ? t('Saving...') : t('Save')}
          disabled={savingInProgress}
          onClick={isFormTabActive ? undefined : () => setActiveTab(ViewTabs.DETAILS)}
          type={isFormTabActive ? 'submit' : 'button'}
          form={isFormTabActive ? 'viewForm' : undefined}
        />
      )}

      {saveAsNewViewAvailable && (
        <ContextMenu menuItems={saveActions} menuId="saveViewOptions">
          <IconButton
            icon="save"
            tooltip={savingInProgress ? t('Saving...') : t('Save')}
            disabled={savingInProgress}
          />
        </ContextMenu>
      )}
    </>
  );
};

export default SaveButton;
