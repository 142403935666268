import { FC, ReactNode } from 'react';

import { Cell } from '../Grid';

export const FullRowCell: FC<{ children?: ReactNode; id?: string; maxHeight?: string }> = ({
  children,
  id,
  maxHeight,
}) => (
  <Cell desktopColumns={12} tabletColumns={8} phoneColumns={4} id={id} $maxHeight={maxHeight}>
    {children}
  </Cell>
);

export const HalfRowCell: FC<{ children?: ReactNode }> = ({ children }) => (
  <Cell desktopColumns={6} tabletColumns={8} phoneColumns={4}>
    {children}
  </Cell>
);

export const DividerRowCell: FC<{ children?: ReactNode }> = ({ children }) => (
  <Cell desktopColumns={3}>{children}</Cell>
);

export const CenterRowCell: FC<{ children?: ReactNode }> = ({ children }) => (
  <Cell desktopColumns={6} tabletColumns={8} phoneColumns={4}>
    {children}
  </Cell>
);

export const TwoThirdsRowCell: FC<{ children?: ReactNode }> = ({ children }) => (
  <Cell desktopColumns={8} tabletColumns={4} phoneColumns={4}>
    {children}
  </Cell>
);
