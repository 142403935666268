import { DateTime } from 'luxon';
import { useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import * as Yup from 'yup';

import { isHoursDisabled, isMinutesDisabled } from 'core/components/TimePickerInput/helpers';

type DateRange = {
  from: Date;
  to: Date;
} | null;

const usePlannedAtSchema = () => {
  const { t } = useTranslation();

  return useMemo(
    () =>
      Yup.date()
        .nullable()
        .test(
          'outside-range',
          t('Planned Collection cannot be outside of DCO Date Range'),
          function (value: Date | null | undefined, testContext) {
            if (!value) return true;

            const range: DateRange = testContext.parent.dateRange;

            if (!range) return true;

            const dateFromBuffer = testContext.parent.dateFromBuffer;
            const dateToBuffer = testContext.parent.dateToBuffer;

            const rangeFrom: Date = DateTime.fromJSDate(range.from)
              .plus({ days: dateFromBuffer || 0 })
              .startOf('day')
              .toJSDate();

            const rangeTo: Date = DateTime.fromJSDate(range.to)
              .minus({ days: dateToBuffer || 0 })
              .endOf('day')
              .toJSDate();

            const publishAt = testContext.parent.publishAt;

            if (!publishAt) return true;

            return (
              value >= rangeFrom &&
              value <= rangeTo &&
              !isHoursDisabled(value.getHours(), this.parent.timeFrom, this.parent.timeTo) &&
              !isMinutesDisabled(
                value.getMinutes(),
                this.parent.timeFrom,
                this.parent.timeTo,
                value.getHours()
              )
            );
          }
        )
        .default(null),
    [t]
  );
};

export default usePlannedAtSchema;
