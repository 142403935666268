import { useCallback, useMemo } from 'react';
import { useTranslation } from 'react-i18next';

import theme from 'app/theme';

const useGetSampleTypeBadgeTitle = () => {
  const { t } = useTranslation();

  return useCallback(
    (isBlood: boolean, isUrine: boolean) => {
      if (isBlood && isUrine) {
        return t('Both');
      }

      if (isBlood) {
        return t('Blood');
      }

      if (isUrine) {
        return t('Urine');
      }

      return '';
    },
    [t]
  );
};

const getSampleTypeBadgeColor = (isBlood: boolean, isUrine: boolean) => {
  if (isBlood && isUrine) {
    return theme.color.status.gray;
  }

  if (isBlood) {
    return theme.color.status.red;
  }

  if (isUrine) {
    return theme.color.status.orange;
  }

  return theme.color.status.white;
};

const useGetSampleTypeBadge = () => {
  const getSampleTypeBadgeTitle = useGetSampleTypeBadgeTitle();

  return useMemo(
    () => (isBlood: boolean, isUrine: boolean) => {
      return {
        title: getSampleTypeBadgeTitle(isBlood, isUrine),
        color: getSampleTypeBadgeColor(isBlood, isUrine),
      };
    },
    [getSampleTypeBadgeTitle]
  );
};

export default useGetSampleTypeBadge;
