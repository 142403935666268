import { useCallback } from 'react';

import { SimpleOption } from 'core/components/DropDown';
import { Client } from 'core/models/Client';

type OptionsType = {
  sort?: boolean;
  skipInactive?: boolean;
  name?: string;
  secondary?: string;
};

const defaultOptions = {
  sort: false,
  skipInactive: false,
  name: 'shortName',
  secondary: 'fullName',
};

const useOptionsReducer = () => {
  return useCallback((array: any[], options?: OptionsType): SimpleOption[] => {
    let tempOptions = { ...defaultOptions, ...options };

    let tempArray = [...array];

    if (tempOptions.sort) {
      tempArray = tempArray.sort((a: any, b: any) =>
        (a.shortName || a.fullName || '').localeCompare(b.shortName || b.fullName || '')
      );
    }

    if (tempOptions.skipInactive) {
      tempArray = tempArray.filter((it: Client): boolean => it.active);
    }

    return tempArray.map((item: any) => ({
      id: item.id,
      name: item[tempOptions.name],
      secondary: item[tempOptions.secondary],
    }));
  }, []);
};

export default useOptionsReducer;
