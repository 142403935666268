import styled from 'styled-components/macro';

export const Icons = styled.div`
  display: flex;
  color: var(--mdc-theme-primary);

  > *:not(:last-child) {
    margin-right: 0.375rem;
  }
`;

export const SelectionText = styled.div`
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: space-between;
  font-size: 0.875rem;
  color: var(--mdc-theme-primary);
`;
