import { CellContextMenuEvent } from 'ag-grid-community';
import { push } from 'connected-react-router';
import { useCallback, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch } from 'react-redux';
import { useLocation } from 'react-router-dom';

import { entities } from 'app/entity';
import { Item as MenuItem } from 'core/components/Menu';
import { mergeUrl, parse } from 'core/functions/qs';
import { useGetPermission } from 'core/hooks/usePermission';

import { ColumnDef, HandleSortCallback, StatusColumn } from '../props';

import { STATUS_COL_ID } from './useColumnDefs';

const useContextMenu = <T>(
  colDefs: ColumnDef<T>[],
  handleSort: HandleSortCallback,
  statusCol?: StatusColumn,
  disableSorting?: boolean
): {
  items: MenuItem[];
  onCellContextMenu: (
    e: CellContextMenuEvent,
    getDetailUrl: (data: T) => string | undefined
  ) => void;
  visible: boolean;
  hide: () => void;
} => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const location = useLocation();
  const getPermission = useGetPermission();
  const [visible, setVisible] = useState<boolean>(false);
  const [items, setItems] = useState<MenuItem[]>([]);

  const hide = useCallback(() => {
    setVisible(false);
  }, []);

  const getColDef: (e: CellContextMenuEvent<T>) => ColumnDef<T> | undefined = useCallback(
    (e) => {
      if (e.colDef.colId === STATUS_COL_ID) return statusCol?.colDef;
      const colField = e.colDef.field;
      const colDef = colDefs.find((c) => colField && c.field === colField);
      return colDef;
    },
    [colDefs, statusCol?.colDef]
  );

  const getEntityDetailId: (data: T, colDef: ColumnDef) => any = useCallback((data, colDef) => {
    if (
      data &&
      colDef?.entity &&
      typeof data === 'object' &&
      colDef.entity.rowDataKey &&
      colDef.entity.rowDataKey in data
    ) {
      return (data as Record<string, unknown>)[colDef.entity.rowDataKey];
    }
    return undefined;
  }, []);

  const getFilterValue: (data: T, colDef: ColumnDef) => any = useCallback((data, colDef) => {
    if (data && colDef?.customFilter && typeof data === 'object') {
      if (colDef.customFilter.filterValueKey && colDef.customFilter.filterValueKey in data) {
        return (data as Record<string, unknown>)[colDef.customFilter.filterValueKey];
      }
      return colDef.customFilter.getFilterValue?.(data);
    }
    return undefined;
  }, []);

  const getItems: (
    e: CellContextMenuEvent<T> & { data: T },
    detailUrl: string | undefined
  ) => MenuItem[] = useCallback(
    (e, detailUrl) => {
      let items: MenuItem[] = [];

      if (detailUrl) {
        items.push({
          key: 'new-tab',
          text: t('Open in New Tab'),
          icon: 'launch',
          to: detailUrl ? { url: detailUrl, target: '_blank' } : undefined,
        });
      }

      const colDef = getColDef(e);

      if (!colDef) return items;

      const filterValue = getFilterValue(e.data, colDef);
      const entityDetailId = getEntityDetailId(e.data, colDef);

      if (colDef?.entity && (entityDetailId || ![undefined, null].includes(filterValue))) {
        items.push({
          key: 'entity-new-tab',
          text: t('Open Entity in New Tab'),
          icon: 'launch',
          to: colDef.entity
            ? {
                url:
                  'urls' in colDef.entity
                    ? colDef.entity.urls.detail(entityDetailId || filterValue)
                    : entities[colDef.entity.key].urls().detail(entityDetailId || filterValue),
                target: '_blank',
              }
            : undefined,
          disabled: !getPermission(`${entities[colDef.entity.key].permission}:get`),
        });
      }

      if (![undefined, null].includes(filterValue)) {
        items.push({
          key: 'filter-by',
          text: t('Filter by {{value}}', {
            value: colDef.customFilter?.getFilterByName?.(e.data) || e.value,
          }),
          icon: 'filter_list',
          onClick: () => {
            if (colDef?.customFilter) {
              const configuration = parse(location.search);

              const filters = {
                ...(configuration?.filters || {}),
                [colDef.customFilter.filterKey]:
                  colDef.customFilter.single || Array.isArray(filterValue)
                    ? filterValue
                    : [filterValue],
              };

              const url = mergeUrl(
                `${location.pathname}`,
                { ...configuration, filters },
                { shallowMerge: true }
              );
              dispatch(push(url));
            }
          },
        });
      }

      const sort = e.column.getSort();
      if (colDef.sortable && !disableSorting) {
        items.push({
          key: 'sort',
          text: !sort || sort === 'asc' ? t('Sort') : t('Reset Sort'),
          icon: !sort ? 'arrow_upward' : sort === 'asc' ? 'arrow_downward' : 'remove',
          onClick: () => handleSort(e.column, colDef),
        });
      }

      return items;
    },
    [
      dispatch,
      getColDef,
      getEntityDetailId,
      getFilterValue,
      getPermission,
      handleSort,
      location.pathname,
      location.search,
      t,
      disableSorting,
    ]
  );

  const onCellContextMenu = useCallback(
    (e: CellContextMenuEvent<T>, getDetailUrl: (data: T) => string | undefined) => {
      if (!e.data) return;
      setVisible(false);

      const event = { ...e, data: e.data };

      const detailUrl = getDetailUrl(event.data);
      const items = getItems(event, detailUrl);
      setItems(items);

      items.length > 0 &&
        setTimeout(() => {
          setVisible(true);
        }, 50);
    },
    [getItems]
  );

  return { items, onCellContextMenu, visible, hide };
};

export default useContextMenu;
