import { useMemo } from 'react';

import { SEARCH_PORTAL_REF_ID } from 'core/components/PageHeader';
import SearchInputWrapper from 'core/components/SearchInput/SearchInputWrapper';
import { CustomFilter, FilterValue } from 'core/containers/DataGrid';

// TODO: move location inside (its router context hook anyways)
function useSearchDatagridFilter(): CustomFilter {
  return useMemo(
    () =>
      ({
        type: 'custom',
        applyToParams: (search: FilterValue) => (search ? { search } : {}),
        disablesSorting: true,
        autoCommit: true,
        defaultValue: null,
        component: (onChange: (val: FilterValue | undefined) => void, value: FilterValue) => (
          <SearchInputWrapper
            value={value as string | null | undefined}
            portalId={SEARCH_PORTAL_REF_ID}
            key={'search-input'}
            onSubmit={onChange}
            expanded
          />
        ),
      }) as CustomFilter,
    []
  );
}

export default useSearchDatagridFilter;
