import { DateTime } from 'luxon';
import { useTranslation } from 'react-i18next';

import CoreEvent, { EventStatus } from 'app/models/Event';
import {
  Notification,
  NotificationTypeEnum,
} from 'core/components/Notifications/RenderNotification';
import getEnv from 'core/functions/getEnv';
import { useFormatDateTimeRange } from 'core/i18n/useFormatDateTime';

export default function useAthleteImport() {
  const { t } = useTranslation();
  const formatDateTimeRange = useFormatDateTimeRange();

  return (e: CoreEvent): Notification => {
    const createdAt =
      e.createdAt &&
      formatDateTimeRange(
        typeof e.createdAt === 'string' ? DateTime.fromISO(e.createdAt) : e.createdAt
      );

    const map = {
      [EventStatus.IN_PROGRESS]: {
        primary: t('Athlete import submitted'),
        secondary: t('Import from {{createdDateTime}} is being processed', {
          createdDateTime: createdAt,
        }),

        updatedAt: e.modifiedAt || e.createdAt,
        icon: 'cloud_upload',
        inProgress: true,
        read: e.isRead,
        type: NotificationTypeEnum.INFO,
        id: e.id,
      },

      [EventStatus.FINISHED]: {
        primary: t('Athlete import completed'),
        secondary: t('Athlete import completed successfully.'),

        updatedAt: e.modifiedAt || e.createdAt,
        icon: 'cloud_upload',
        inProgress: false,
        read: e.isRead,
        type: NotificationTypeEnum.SUCCESS,
        id: e.id,
      },

      [EventStatus.FAILED]: {
        primary: t('Athlete import failed'),
        secondary: e.payload.storageKey
          ? t('{{ errorMessage }}. Please check the file for potential errors.', {
              errorMessage: e.payload.message,
            })
          : t('{{ errorMessage }}.', {
              errorMessage: e.payload.message,
            }),

        updatedAt: e.modifiedAt || e.createdAt,
        actions: e.payload.storageKey
          ? [
              {
                key: 'download',
                text: t('Download file'),
                action: () =>
                  window.location.replace(`${getEnv('API_URL')}/athletes/import/${e.id}`),
              },
            ]
          : undefined,
        icon: 'cloud_upload',
        inProgress: false,
        read: e.isRead,
        type: NotificationTypeEnum.ERROR,
        id: e.id,
      },

      [EventStatus.CREATED]: null,
      [EventStatus.WARNING]: {
        primary: t('Athlete import partially completed'),
        secondary: e.payload.storageKey
          ? t('{{ errorMessage }}. Please check the file for potential errors.', {
              errorMessage: e.payload.message,
            })
          : t('{{ errorMessage }}.', {
              errorMessage: e.payload.message,
            }),

        updatedAt: e.modifiedAt || e.createdAt,
        actions: e.payload.storageKey
          ? [
              {
                key: 'download',
                text: t('Download file'),
                action: () =>
                  window.location.replace(`${getEnv('API_URL')}/athletes/import/${e.id}`),
              },
            ]
          : undefined,
        icon: 'cloud_upload',
        inProgress: false,
        read: e.isRead,
        type: NotificationTypeEnum.WARNING,
        id: e.id,
      },
    };

    return map[e.status] || map[EventStatus.IN_PROGRESS];
  };
}
