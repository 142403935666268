import { useCallback, useRef } from 'react';

import { useResources } from 'core/hooks/useResource';
import Pool from 'lists/models/Pool';

interface Props {
  autoload?: boolean;
}

// Max number of reloads to avoid infinite loop if the API is not working
const RELOAD_MAX = 5;

export const useGetOneHourSlotActive = ({ autoload = false }: Props) => {
  const { data, reload, isLoading } = useResources<Pool>('pools', { autoload });

  const reloadCount = useRef(0);

  return useCallback(
    (currentPoolsId: number | null | undefined, currentPool?: Pool | null) => {
      if (!currentPoolsId) {
        return undefined;
      }

      let pool = currentPool;

      const hasChanged = currentPool && currentPool.id !== currentPoolsId;

      if (isLoading) {
        return false;
      }

      if (hasChanged || !pool) {
        if (!data && reloadCount.current < RELOAD_MAX) {
          reload();
          reloadCount.current++;
        }
        pool = data?.find((pool) => pool.id === currentPoolsId);
      }

      return pool?.oneHourSlot || false;
    },
    [data, reload, isLoading]
  );
};
