import {
  resources,
  SupportedLocale,
  DEFAULT_LANGUAGE,
  DEFAULT_LOCALE,
} from 'app/assets/i18n/resources';

const getResources = () =>
  Object.fromEntries(
    Object.entries(resources).map(([locale, content]) => [locale, { translation: content }])
  );

export default getResources;

export type { SupportedLocale };

export { DEFAULT_LANGUAGE, DEFAULT_LOCALE };
