import { push } from 'connected-react-router';
import { toast } from 'react-toastify';
import { put, race, take } from 'redux-saga/effects';

import { urls } from 'app/urls';
import { unblockRedirect } from 'core/history';
import { t } from 'core/i18n';

import {
  TYPE_LOGOUT,
  TYPE_LOGOUT_ERROR,
  TYPE_LOGOUT_SUCCESS,
  apiCall,
  logoutSuccess,
} from '../actions';

export const INITIAL_URL_KEY = 'initialUrl';

function* logout() {
  unblockRedirect();

  yield put(apiCall(TYPE_LOGOUT, 'POST', '/auth/logout', undefined, {}, false));

  const { success, error } = yield race({
    success: take(TYPE_LOGOUT_SUCCESS),
    error: take(TYPE_LOGOUT_ERROR),
  });

  if (error) {
    const e = error.payload.error;

    if (e.response && (e.response.status === 401 || e.response.status === 403)) {
      yield put(logoutSuccess());
      yield put(push(urls.login));
    } else {
      const message =
        e.response && e.response.data && e.response.data.message
          ? e.response.data.message
          : e.message;
      toast.error(t('Logout failed: {{message}}', { message }));
      return;
    }
  }

  if (success) {
    // check src/index.tsx
    const initialUrl = localStorage.getItem(INITIAL_URL_KEY) || '';

    if (initialUrl.indexOf('redirect_uri') > -1) {
      window.location.href = initialUrl;
      return;
    }

    toast.success(t('Logout successful'));
    yield put(push(urls.login));
  }
}

export default logout;
