import i18n, { TFunction } from 'i18next';
import LngDetector from 'i18next-browser-languagedetector';
import { initReactI18next } from 'react-i18next';
import { Store } from 'redux';
import { setLocale as setYupLocale } from 'yup';

import { State } from 'app/store/prepareStore';
import { changeLanguage as changeLanguageAction, i18nInitialized } from 'core/actions';

import getResources, { DEFAULT_LANGUAGE, DEFAULT_LOCALE, SupportedLocale } from './languages';
import schemaMessages from './schemaMessages';

let store: Store<State>;

function init(storeInstance: Store) {
  store = storeInstance;

  // Is saving cache (saving to Redux) in progress
  let isSavingCache = false;

  const ownDetector = {
    name: 'own',

    lookup() {
      const state = store.getState();

      if (state.core.user?.language) {
        return state.core.user.language;
      }

      const stored = state.core.language;
      if (stored) {
        return stored;
      }

      // Modern browsers support navigator.languages that is similar to Accept-Language
      // Older browsers have only single .language property or IE .userLanguage
      const found = [
        ...(navigator.languages || [
          // @ts-ignore We support browser specific (not standard) userLanguage
          navigator.language || navigator.userLanguage,
        ]),
      ]
        // In case both .language and .userlanguage won't be set
        .filter((l) => typeof l === 'string')
        // We only support ISO 3166 codes as language code on backend
        // Also, we treat 'no' and 'nn 'as 'nb'
        .map((lang) => lang.replace(/-.*/, '').replace('nb', 'no').replace('nn', 'no'));

      return found.length > 0 ? found[0] : undefined;
    },

    cacheUserLanguage(language: SupportedLocale) {
      // Set language on the store as well
      isSavingCache = true;
      store.dispatch(changeLanguageAction(language || DEFAULT_LANGUAGE));
    },
  };

  const lngDetector = new LngDetector();
  lngDetector.addDetector(ownDetector);

  i18n
    .use(initReactI18next)
    .use(lngDetector)
    .init({
      resources: getResources(),
      detection: {
        order: ['own'],
        caches: ['own'],
      },
      react: {
        useSuspense: false,
      },

      // Allow keys to be phrases having `:`, `.`. `_` and turn off fallback for key fallback
      nsSeparator: false,
      keySeparator: false,
      pluralSeparator: undefined,
      fallbackLng: false,
      returnEmptyString: false,

      interpolation: {
        // Already done by React itself
        escapeValue: false,
      },
    })
    .then(() => store.dispatch(i18nInitialized()));

  setYupLocale(schemaMessages);

  store.subscribe(() => {
    // Ignore saving of cache as the language is already set
    if (isSavingCache) {
      isSavingCache = false;
      return;
    }

    const core = store.getState().core;

    if (i18n.language !== core.language) {
      // If it's an empty string, i18next will call lngDetector.detect()
      i18n.changeLanguage(core.language || '');
      tFn = undefined;
    }
  });

  return i18n;
}

/**
 * Get current locale
 *
 * Prefer using useLocale hook whenever possible!
 */
export const getCurrentLocale = () => {
  return store.getState().core.locale || navigator.language || DEFAULT_LOCALE;
};

let tFn: TFunction | undefined;
export const t = (key: string, opts?: Record<string, unknown>) => {
  if (!tFn) tFn = i18n.getFixedT(i18n.language);
  return tFn(key, opts);
};

export default init;
