import { ICellRendererParams } from 'ag-grid-community';
import tippy, { Instance } from 'tippy.js';

import theme from 'app/theme';
import { defaultTippyOptions } from 'core/components/Tooltip';

import styles from './index.module.css';

type Params = ICellRendererParams & {
  title: (data: any) => string;
  containsImg: (data: any) => boolean;
  url: (data: any) => string;
};

export default class AvatarCellRenderer {
  private placeholderElement?: HTMLDivElement;
  private imageElement?: HTMLImageElement;
  private tippyInstance?: Instance;

  public init({ title, containsImg, url, data }: Params) {
    const tooltip = title(data);

    if (!containsImg(data)) {
      this.placeholderElement = document.createElement('div');
      this.placeholderElement.className = styles['avatar-placeholder'];
      this.placeholderElement.style.backgroundColor = theme.color.gray400;
      this.setPlaceholder(this.placeholderElement, tooltip);
    } else {
      this.imageElement = document.createElement('img');
      this.imageElement.className = styles.avatar;
      this.setImg(this.imageElement, url(data), tooltip);
    }

    this.tippyInstance = tippy(this.placeholderElement || this.imageElement!, {
      ...defaultTippyOptions,
      content: tooltip,
    });
  }

  public getGui() {
    // Ag-grid should call init before getGui, so we should be fairly sure
    return this.placeholderElement || this.imageElement!;
  }

  public refresh({ title, containsImg, url, data }: Params) {
    const hasImg = containsImg(data);

    // Tell grid the whole component needs to be rendered
    if (!hasImg && !this.placeholderElement) {
      return false;
    }

    if (hasImg && !this.imageElement) {
      return false;
    }

    const tooltip = title(data);

    if (hasImg) {
      this.setImg(this.imageElement!, url(data), tooltip);
    } else {
      this.setPlaceholder(this.placeholderElement!, tooltip);
    }

    if (this.tippyInstance) this.tippyInstance.setContent(tooltip);

    // Tell grid we rerendered successfully
    return true;
  }

  public destroy() {
    if (this.tippyInstance) {
      this.tippyInstance.destroy();
      this.tippyInstance = undefined;
    }
  }

  private setPlaceholder(placeholderElement: HTMLDivElement, title: string) {
    if (!title) {
      placeholderElement.textContent = '-';
      return;
    }
    const words = title.split(' ').map((i) => i.charAt(0)); // expecting full-name - first last middle
    const initials = words[0] + (words[1] || '');
    placeholderElement.textContent = initials;
  }

  private setImg(imageElement: HTMLImageElement, src: string, alt: string) {
    imageElement.src = src;
    imageElement.alt = alt;
  }
}
