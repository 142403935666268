import { DateTime } from 'luxon';
import { FC, useContext } from 'react';
import { useTranslation } from 'react-i18next';

import { FlexCell, FlexRow } from 'core/components/FlexUtils';
import { FormContext } from 'core/components/Form';
import Field from 'core/components/Form/Field';
import { StyledHelperText } from 'core/components/Form/Field/styled';
import FormTextfield, { SetFieldValueOnChange } from 'core/components/FormTextfield';
import IconButton from 'core/components/IconButton';

interface Props {
  name: string;
  dateFrom?: Date;
  dateTo?: Date;
  label?: string;
  helperText?: string;
  showFillButton?: boolean;
  onCalculated: (days: number | null) => void;
  onChange?: (value: string) => void;
}

const getCalculatedBufferForRange = (from?: Date, to?: Date) => {
  if (!from || !to) return null;

  const diffInDays = DateTime.fromJSDate(to).diff(DateTime.fromJSDate(from), 'days').days;

  if (diffInDays > 28) {
    return 7;
  } else if (diffInDays > 21) {
    return 5;
  } else if (diffInDays > 14) {
    return 3;
  } else if (diffInDays > 7) {
    return 2;
  } else {
    return null;
  }
};

const DateRangeBuffer: FC<Props> = ({
  name,
  dateFrom,
  dateTo,
  label,
  helperText,
  showFillButton = true,
  onCalculated,
  onChange,
}) => {
  const { t } = useTranslation();
  const { viewMode: formViewMode, defaultViewMode } = useContext(FormContext);

  const defaultHelperText = t(
    'Number of days by which date range will be shortened for the DCO and will create a buffer.'
  );

  const viewMode =
    formViewMode && formViewMode.hasOwnProperty(name)
      ? formViewMode[name]
      : formViewMode && defaultViewMode;

  return (
    <>
      <FlexRow>
        <FlexCell flex={1} block>
          <Field
            component={FormTextfield}
            label={label || t('Date Range Buffer')}
            name={name}
            type="number"
            fast={false}
            onChange={(value: string, setFieldValue: SetFieldValueOnChange) => {
              onChange && onChange(value);
              setFieldValue(value);
            }}
          />
        </FlexCell>
        {!viewMode && showFillButton && (
          <FlexCell>
            <IconButton
              icon="calculate"
              tooltip={t('Fill with buffer calculated from the number of days in the date range.')}
              onClick={() => {
                const calculatedBuffer = getCalculatedBufferForRange(dateFrom, dateTo);
                onCalculated(calculatedBuffer);
              }}
            />
          </FlexCell>
        )}
      </FlexRow>
      <StyledHelperText>{helperText || defaultHelperText}</StyledHelperText>
    </>
  );
};

export default DateRangeBuffer;
