import { DateTime } from 'luxon';
import { FC } from 'react';

import MaterialIcon from 'core/components/MaterialIcon';
import { useLocale } from 'core/i18n/useLocale';

import { StyledArrow, StyledMonth, StyledWrapper } from './styled';

interface Props {
  onChange: (value: Date) => void;
  className?: string;
  date: Date;
  range?: boolean;
}

const MonthNavigation: FC<Props> = ({ date, className, onChange, range }) => {
  const locale = useLocale();

  const handleChange = (action: string) => {
    const newMonth =
      action === 'prev'
        ? DateTime.fromJSDate(date).minus({ month: 1 })
        : // Next month arrow is already in the next calendar month
          range
          ? DateTime.fromJSDate(date)
          : DateTime.fromJSDate(date).plus({ month: 1 });
    onChange(newMonth.toJSDate());
  };

  return (
    <StyledWrapper className={className}>
      <StyledArrow
        onClick={() => handleChange('prev')}
        className="DayPicker-MonthArrow"
        aria-label="Previous Month"
        role="button"
        tabIndex={0}
      >
        <MaterialIcon icon="chevron_left" />
      </StyledArrow>
      <StyledMonth>{DateTime.fromJSDate(date).setLocale(locale).toFormat('MMMM')}</StyledMonth>
      <StyledArrow
        onClick={() => handleChange('next')}
        className="DayPicker-MonthArrow"
        aria-label="Next Month"
        role="button"
        tabIndex={0}
      >
        <MaterialIcon icon="chevron_right" />
      </StyledArrow>
    </StyledWrapper>
  );
};

export default MonthNavigation;
