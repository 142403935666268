import camelCase from 'lodash/camelCase';

import User from 'app/models/User';
import { t } from 'core/i18n';
import Base from 'core/models/Base';

import AuditLog from './models/AuditLog';

/**
 * Translation fn
 */
export type T = typeof t;

export interface StandardUrls {
  /**
   * Browse entity List Screen (datagrid)
   * /users
   */
  list: string;
  /**
   * Create New Entity Screen
   * /users/create
   */
  create: string;
  /**
   * Display Entity details Screen
   * /users/1
   */
  detail: (id: number | string) => string;
  /**
   * Clone existing entity Screen
   * /users/1/clone
   */
  clone: (id: number | string) => string;
}

export interface StandardApi {
  /**
   * GET
   * - list response (resources for dropdowns)
   * - ?withCounts=true - datagrid response
   * POST
   * - create entity
   * DELETE
   * - remove entity
   */
  list: string;
  /**
   * GET
   * - detail response
   * PATCH
   * - update one entity
   * DELETE
   * - remove one entity
   */
  detail: (id: number | string) => string;
  /**
   * PATCH
   * - bulk patch multiple entities (apply diff)
   * DELETE
   * - remove multiple entities
   */
  bulk: string;
}

/**
 * All metadata collected for single entity in one place
 *
 * FE urls
 * API endpoints
 * permission name
 * common response Types (Grid, Detail, Request)
 * translated name in Singular or Plural form
 */

export interface EntityMeta {
  /**
   * DB Model Name (e.g. attachments requires entity model name)
   */
  readonly model: string;
  /**
   * entity permission name - plural lowercase
   */
  readonly permission: string;
  /**
   * Human Readable singular name
   * used by e.g. Detail screens
   */
  readonly name: (t: T) => string;
  /**
   * Human Readable plural name
   * used by e.g. Datagrid screens
   */
  readonly listName: (t: T) => string;
  /**
   * Standard Entity urls
   * used by generic components with strong patterns
   * like List, Detail, Clone, Create
   */
  readonly urls: () => StandardUrls;
  /**
   * Standard entity urls
   * used by generic components with strong patterns
   * so we can provide CRUD actions over this entity
   */
  readonly api: () => StandardApi;
  /**
   * List Response Typescript definition
   * used by generic components like List page
   * so we are sure all params matches
   */
  readonly gridModel: Base;
  /**
   * Detail Response Typescript definition (GET, POST, PATCH)
   * used by generic components like EntityDetail page
   * so Response -> Form transformation is type safe
   */
  readonly detailModel: Base;
  /**
   * Detail Request Typescript definition (POST, PATCH)
   * used by generic components like EntityDetail page
   * so Form -> Request transformation is type safe
   */
  readonly requestModel: Object;
}

/**
 * Generate standard entity urls
 * - list /
 * - detail /:id/edit
 * - create /create
 * - clone /clone/:id
 * @param {string} baseUrl
 * @returns
 */
export const getStandardUrls = (baseUrl: string) => {
  return {
    create: `/${baseUrl}/create`,
    clone: (id: number | string) => `/${baseUrl}/clone/${id}`,
    list: `/${baseUrl}`,
    detail: (id: number | string) => `/${baseUrl}/${id}/edit`,
  } as const;
};

/**
 * Generate standard API endpoints
 * - list GET / (create = POST /, remove = DELETE /)
 * - detail GET /:id
 * - bulk GET /bulk
 * @param {string} baseUrl
 * @returns
 */
export const getStandardApi = (baseUrl: string) =>
  ({
    list: `/${baseUrl}`,
    detail: (id: number | string) => `/${baseUrl}/${id}`,
    bulk: `/${baseUrl}/bulk`,
  }) as const;

/**
 * Shortcut to generate Module Entity definition
 * a "standard" way
 * - for overrides you can use second param with custom values
 * - or use spread operator
 */
export const definition = <G, D = G, R = D, C = unknown>(
  plural: string,
  meta: Partial<Omit<EntityMeta, 'gridModel' | 'detailModel' | 'requestModel'>> &
    Pick<EntityMeta, 'name' | 'listName'>
) => ({
  permission: meta.permission || plural,
  model: meta.model || camelCase(plural),
  urls: meta.urls || ((_c?: C) => getStandardUrls(plural)),
  api: meta.api || ((_c?: C) => getStandardApi(plural)),
  gridModel: {} as G,
  detailModel: {} as D,
  requestModel: {} as R,
  name: meta.name,
  listName: meta.listName,
});

export const coreEntities = {
  file: definition<User>('files', { name: (t: T) => t('File'), listName: (t: T) => t('Files') }),
  auditLog: definition<AuditLog>('audit-logs', {
    name: (t: T) => t('Audit Log'),
    listName: (t: T) => t('Audit Logs'),
  }),
} as const;

// this guy its here only for verify check if all enums are properly setup
// bcs if we use Record<EntityKey, EntityMeta> in this file TS wil skip CONST and values are not enums but types
/* eslint-disable  @typescript-eslint/no-unused-vars */
const verifyEntities: Record<keyof typeof coreEntities, EntityMeta> = coreEntities;

export type K = keyof typeof coreEntities;
