import { useTranslation } from 'react-i18next';

import { entities } from 'app/entity';
import Event, { EventStatus } from 'app/models/Event';
import {
  Notification,
  NotificationTypeEnum,
} from 'core/components/Notifications/RenderNotification';

export default function useDcoInvoiceReview() {
  const { t } = useTranslation();

  return (e: Event): Notification => {
    const map = {
      [EventStatus.FINISHED]: {
        primary: t('DCO Invoice accepted'),
        secondary: `#${e.payload?.invoiceNumber || e.payload.id}`,
        updatedAt: e.modifiedAt || e.createdAt,
        actions: [
          {
            key: 'open',
            text: t('Open Invoice'),
            action: entities.dcoInvoice.urls(e.usersId).detail(e.payload.id),
          },
        ],
        icon: 'request_quote',
        inProgress: false,
        read: e.isRead,
        type: NotificationTypeEnum.SUCCESS,
        id: e.id,
      },

      [EventStatus.FAILED]: {
        primary: t('DCO Invoice declined'),
        secondary: `#${e.payload?.invoiceNumber || e.payload.id}`,
        updatedAt: e.modifiedAt || e.createdAt,
        actions: [
          {
            key: 'open',
            text: t('Open Invoice'),
            action: entities.dcoInvoice.urls(e.usersId).detail(e.payload.id),
          },
        ],
        icon: 'request_quote',
        inProgress: false,
        read: e.isRead,
        type: NotificationTypeEnum.ERROR,
        id: e.id,
      },

      [EventStatus.CREATED]: null,
      [EventStatus.IN_PROGRESS]: null,
      [EventStatus.WARNING]: null,
    };

    return map[e.status] || map[EventStatus.FINISHED];
  };
}
