import { useFormikContext } from 'formik';
import { FC, useMemo } from 'react';
import { useTranslation } from 'react-i18next';

import { Row } from 'core/components/Grid';
import PaperContainer from 'core/components/PaperContainer';

import { FormData, SampleCodeType } from '../../useSchema';

import SampleCode from './SampleCode';

export const SAMPLE_CODE_TYPES = [
  'urineSampleCodeValidation',
  'partialUrineSampleCodeValidation',
  'bloodSampleCodeValidation',
  'dbsSampleCodeValidation',
];

type SampleCodeValidation = {
  label: string;
  value: SampleCodeType;
  fieldName:
    | 'urineSampleCodeValidation'
    | 'partialUrineSampleCodeValidation'
    | 'bloodSampleCodeValidation'
    | 'dbsSampleCodeValidation';
};

const SampleCodeValidationPaper: FC = () => {
  const { values } = useFormikContext<FormData>();

  const { t } = useTranslation();

  const {
    urineSampleCodeValidation,
    partialUrineSampleCodeValidation,
    bloodSampleCodeValidation,
    dbsSampleCodeValidation,
  } = values;

  const sampleCodes: SampleCodeValidation[] = useMemo(
    () => [
      {
        label: t('Urine Samples'),
        value: urineSampleCodeValidation,
        fieldName: 'urineSampleCodeValidation',
      },
      {
        label: t('Partial Urine Samples'),
        value: partialUrineSampleCodeValidation,
        fieldName: 'partialUrineSampleCodeValidation',
      },
      {
        label: t('Blood Samples'),
        value: bloodSampleCodeValidation,
        fieldName: 'bloodSampleCodeValidation',
      },
      {
        label: t('DBS Samples'),
        value: dbsSampleCodeValidation,
        fieldName: 'dbsSampleCodeValidation',
      },
    ],
    [
      t,
      bloodSampleCodeValidation,
      urineSampleCodeValidation,
      partialUrineSampleCodeValidation,
      dbsSampleCodeValidation,
    ]
  );

  return (
    <>
      <PaperContainer title={t('Sample Codes Validation')}>
        <Row>
          {sampleCodes.map((it) => (
            <SampleCode
              fieldName={it.fieldName}
              label={it.label}
              value={it.value}
              key={it.fieldName}
            />
          ))}
        </Row>
      </PaperContainer>
    </>
  );
};

export default SampleCodeValidationPaper;
