import { useTranslation } from 'react-i18next';

import theme from 'app/theme';
import Text from 'core/components/Text';

export type StatusType = 'normal' | 'warning';
export const STATUS_TYPE_WARNING: StatusType = 'warning';
export const STATUS_TYPE_NORMAL: StatusType = 'normal';

type Prop = {
  /** Label of the status message, it is display before ':' */
  label?: string;
  /** Label of the status message, it is display after ':' */
  value?: number | string | null;
  /** Type of the status message */
  type?: StatusType;
};

export const StatusMessage = ({ label, value, type }: Prop) => {
  const { t } = useTranslation();

  return (
    <Text
      $fontSize="0.8rem"
      $marginTop=".25rem"
      $paddingLeft="1rem"
      $color={type === STATUS_TYPE_WARNING ? theme.color.warning : theme.color.darkGray}
      $width="100%"
    >
      {label ? label : t('Currently')}:{' '}
      <Text
        $fontWeight="600"
        $color={type === STATUS_TYPE_WARNING ? theme.color.warning : theme.color.darkGray}
        $display="inline"
        $marginLeft=".15rem"
      >
        {value ? value : '-'}
      </Text>
    </Text>
  );
};
