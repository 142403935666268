import { ComponentType, FC, memo } from 'react';
import { withRouter, RouteComponentProps } from 'react-router';

import StyledTabBar from 'core/components/StyledTabBar';
import { StyledTabIcon } from 'core/components/StyledTabIcon';
import useAppSelector from 'core/hooks/useAppSelector';

import { StyledTab } from './styled';

/**
 * Detail-Subheader Tab definition
 */
export interface TabOption {
  /**
   * Displays red warning icon
   */
  hasError?: boolean;
  /**
   * disabled Button & unavailable Route
   */
  disabled?: boolean;
  /**
   * Hidden Tab label & unavailable Route
   */
  hidden?: boolean;
  /**
   *  tab button description
   */
  title?: string;
  /**
   * button name
   */
  name: string;
  /**
   *  action url
   */
  url: string;
  /**
   * unique list id
   */
  id: string;
}

/**
 * Route Tab - definition of Subheader Tab extended by Route render props
 * Standardized interface for rendering Detail Tabs
 */
export interface RouteTabOption extends TabOption {
  /**
   * Route path
   */
  path: string;
  /**
   * route render Component
   * usage: <Route component={tab.component} path={tab.path} />
   * or use <RenderTabs tabs={RouteTabOption[]} />
   * if you need formDetail context, please use useDetail() hook instead of prop drilling
   */
  component: ComponentType<RouteComponentProps>;
}

interface Props extends RouteComponentProps {
  options: TabOption[];
}

export const Tabs: FC<Props> = ({ options, location, history }) => {
  const errors = useAppSelector((state) => state.core.tabErrors);

  return (
    <StyledTabBar activeIndex={-1}>
      {options
        .filter((t) => !t.hidden)
        .map((option) => {
          // check if current tab has error or nested fields starting with same prefix
          // tab errors processing walking trough fields, and merging errors & meta info =
          // detail.email or email.metaTab('detail') should display same notification
          const hasError =
            option.hasError || errors.some((k) => k === option.id || k.startsWith(`${option.id}.`));

          return (
            <StyledTab
              onClick={() => !option.disabled && history.push(option.url)}
              active={option.url.replace(/\?.*/, '') === location.pathname}
              disabled={option.disabled}
              hasError={!!hasError}
              key={option.id}
              type="button"
            >
              {option.title && <StyledTabIcon icon="info" tooltip={option.title}></StyledTabIcon>}

              <span>{option.name}</span>

              {hasError && <StyledTabIcon icon="warning" color="error"></StyledTabIcon>}
            </StyledTab>
          );
        })}
    </StyledTabBar>
  );
};

export default withRouter(memo(Tabs));
