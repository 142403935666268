import { useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import * as Yup from 'yup';

/**
 * Usable on yup schema object field
 *
 * Example: dateRange: dateRangeSchema.required().meta(tabMeta('detail'))
 */
const useDateRangeSchema = () => {
  const { t } = useTranslation();

  return useMemo(
    () =>
      Yup.object({ from: Yup.date(), to: Yup.date() })
        .nullable()
        .default(null)
        .test(
          'dateRange',
          t('You need to pick a valid date range'),
          (value: { from: Date | undefined; to: Date | undefined } | null) => {
            if (!value || !value.from || !value.to) return true;

            return value.from <= value.to;
          }
        ),
    [t]
  );
};

export default useDateRangeSchema;
