import { useMemo } from 'react';
import { useTranslation } from 'react-i18next';

const useRouteOfAdministrationOptions = () => {
  const { t } = useTranslation();

  return useMemo(
    () => [
      { id: 'Buccal', name: t('Buccal (between gums and cheek)') },
      { id: 'Dental', name: t('Dental (to teeth or gums)') },
      { id: 'Inhalation', name: t('Inhalation (through mouth into lungs)') },
      { id: 'Intra-articular injection', name: t('Intra-articular injection (joint)') },
      { id: 'Intravenous', name: t('Intravenous') },
      { id: 'IV injection/infusion', name: t('IV injection/infusion (into a vein)') },
      { id: 'Intramuscular injection', name: t('Intramuscular injection') },
      { id: 'IM', name: t('IM (into a muscle)') },
      { id: 'Intrathecal injection', name: t('Intrathecal injection (into spinal cord)') },
      { id: 'Nasal', name: t('Nasal (into the nose)') },
      { id: 'Nebulization', name: t('Nebulization (through mouth and nose into lungs)') },
      { id: 'Ocular', name: t('Ocular (into the eye)') },
      { id: 'Oral', name: t('Oral (taken by mouth)') },
      { id: 'Otic', name: t('Otic (into the ear)') },
      { id: 'Rectal', name: t('Rectal (rectum)') },
      { id: 'Subcutaneous', name: t('Subcutaneous') },
      { id: 'SQ injection', name: t('SQ injection (under the skin)') },
      { id: 'Sublingual', name: t('Sublingual (under tongue)') },
      { id: 'Topical', name: t('Topical (applied to skin)') },
      { id: 'Transdermal', name: t('Transdermal (patch on skin)') },
      { id: 'Transfusion', name: t('Transfusion') },
      { id: 'Vaginal', name: t('Vaginal (vagina)') },
      { id: 'Unknown Route', name: t('Unknown Route') },
      { id: 'Other', name: t('Other (not specified above)') },
    ],
    [t]
  );
};

export default useRouteOfAdministrationOptions;
