// eslint-disable-next-line no-restricted-imports
import { IStringifyOptions } from 'qs';

import { parse, stringify } from 'core/functions/qs';

/**
 * Default listing limit
 */
const RESOURCES_LIMIT = 50000;

export function getResourceUrl(
  base: string,
  params?: Record<string, any>,
  options?: IStringifyOptions
): string {
  const path = base.replace(/\?.*$/, '');
  const baseQuery = base.indexOf('?') > -1 ? base.replace(path + '?', '') : '';
  const baseParams = baseQuery ? parse(baseQuery) : {};
  const stringifiedParams = stringify({ ...baseParams, ...(params || {}) }, options);

  const url = `${path}${stringifiedParams ? `?${stringifiedParams}` : ''}`;

  return url;
}

export default function getResourcesUrl(
  base: string,
  params?: Record<string, any>,
  options?: IStringifyOptions
): string {
  const path = base.replace(/\?.*$/, '');
  const baseQuery = base.indexOf('?') > -1 ? base.replace(path + '?', '') : '';
  const baseParams = baseQuery ? parse(baseQuery) : {};

  return getResourceUrl(
    base,
    { limit: RESOURCES_LIMIT, offset: 0, ...(params || {}), ...baseParams }, // DO NOT OVERRIDE OFFSET - LIMIT
    options
  );
}
