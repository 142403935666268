import Tippy from '@tippyjs/react/headless';
import { FC, useCallback, useMemo, useRef, useState } from 'react';
import { ReactCropperProps } from 'react-cropper';
import { useTranslation } from 'react-i18next';
import { useDispatch } from 'react-redux';
import { Instance, Placement } from 'tippy.js';

import { deleteDialogEntity, uploadImage } from 'core/actions';
import MaterialIcon from 'core/components/MaterialIcon';
import Menu, { Item } from 'core/components/Menu';
import Modal from 'core/components/Modal';
import Tooltip from 'core/components/Tooltip';
import UploadImageModal from 'core/components/UploadImageModal';
import getEnv from 'core/functions/getEnv';

import { PopoverWrapper } from '../DataGrid/CellRenderers/RowActionsCellRenderer/styled';

import { AvatarButton, AvatarLetters, Circle } from './styled';

export interface Props {
  title?: string;
  storageKey?: string | null;
  size?: 'huge' | 'normal' | 'small' | 'big';
  onChange?: () => void;
  dense?: boolean;
  edit?: boolean;
  endpoint: string;
  cropper?: { props: ReactCropperProps; width: number; height: number };
  tooltipPlacement?: Placement;
  withTooltip?: boolean;
  noMargin?: boolean;
}

enum ModalVisibility {
  UPLOAD,
  DELETE,
  NONE,
}

const Avatar: FC<Props> = ({
  edit,
  size,
  onChange,
  endpoint,
  cropper,
  storageKey,
  title,
  dense = false,
  tooltipPlacement,
  withTooltip = true,
  noMargin = false,
}) => {
  const { t } = useTranslation();
  const [modalVisibility, setModalVisible] = useState<ModalVisibility>(ModalVisibility.NONE);
  const [imageMenuVisible, setImageMenuVisible] = useState(false);
  const tippyRef = useRef<Instance>();
  const dispatch = useDispatch();

  const letters = useMemo(() => {
    if (!title) return undefined;

    const words = title.includes(' ')
      ? title.split(' ').filter((word) => word && word !== ' ')
      : title;

    if (words.length > 1) {
      return `${words[0].charAt(0)}${words[1].charAt(0)}`;
    }

    return words[0].substring(0, 2);
  }, [title]);

  const dispatchUploadImage = useCallback(
    (file: Blob, sc: () => void) =>
      dispatch(
        uploadImage(file, endpoint, () => {
          sc && sc();
          onChange && onChange();
        })
      ),
    [dispatch, endpoint, onChange]
  );

  const dispatchDeleteFile = useCallback(
    () =>
      dispatch(
        deleteDialogEntity(endpoint, () => {
          onChange && onChange();
        })
      ),
    [endpoint, dispatch, onChange]
  );

  const actions: Item[] = [];

  if (edit) {
    actions.push({
      text: storageKey ? t('Change Image') : t('Add Image'),
      onClick: () => {
        setModalVisible(ModalVisibility.UPLOAD);
        setImageMenuVisible(false);
      },
      key: 'add',
    });

    storageKey &&
      actions.push({
        onClick: () => {
          setModalVisible(ModalVisibility.DELETE);
          setImageMenuVisible(false);
        },
        text: t('Remove'),
        key: 'remove',
      });
  }

  //TODO: Unify usage of entities in avatar component https://cannypack.atlassian.net/browse/FEC-192
  const imgUrlEndpoint = endpoint.startsWith('/') ? endpoint.substring(1) : endpoint;

  const imgUrl = `${getEnv('API_URL')}/${imgUrlEndpoint}?image=${storageKey}`;
  const imgHtml = <img src={imgUrl} alt={title || t('Avatar')} />;

  if (!edit) {
    return (
      <Circle hasImg={!!storageKey} dense={dense} size={size} noMargin={noMargin}>
        <div>
          {storageKey ? (
            imgHtml
          ) : withTooltip ? (
            <Tooltip content={title} placement={tooltipPlacement}>
              <AvatarLetters>{letters}</AvatarLetters>
            </Tooltip>
          ) : (
            <AvatarLetters>{letters}</AvatarLetters>
          )}
        </div>
      </Circle>
    );
  }

  return (
    <>
      <Tippy
        interactive
        onCreate={(instance) => {
          tippyRef.current = instance;
        }}
        placement="bottom-start"
        appendTo={document.body}
        visible={imageMenuVisible}
        onClickOutside={() => setImageMenuVisible(false)}
        render={(attrs) => {
          return (
            <PopoverWrapper {...attrs}>
              <Menu id="contextMenu-avatar" items={actions} />
            </PopoverWrapper>
          );
        }}
      >
        <Circle
          hasImg={!!storageKey}
          size={size}
          onClick={() => setImageMenuVisible(!imageMenuVisible)}
          dense={dense}
        >
          <div>
            {storageKey ? (
              imgHtml
            ) : withTooltip ? (
              <Tooltip content={title} placement={tooltipPlacement}>
                <AvatarLetters>{letters}</AvatarLetters>
              </Tooltip>
            ) : (
              <AvatarLetters>{letters}</AvatarLetters>
            )}
            <AvatarButton
              onClick={() => {
                setImageMenuVisible(!imageMenuVisible);
              }}
            >
              <MaterialIcon icon="camera_alt" />
            </AvatarButton>
          </div>
        </Circle>
      </Tippy>

      {edit && (
        <Modal
          onConfirm={dispatchDeleteFile}
          onCancel={() => setModalVisible(ModalVisibility.NONE)}
          onClose={() => setModalVisible(ModalVisibility.NONE)}
          ariaLabel={t('Image deletion confirmation dialog')}
          open={modalVisibility === ModalVisibility.DELETE}
          title={t('Delete current Image')}
        >
          {t(`Are you sure you want to permanently delete this image?`)}
        </Modal>
      )}

      {edit && (
        <UploadImageModal
          onClose={() => setModalVisible(ModalVisibility.NONE)}
          open={modalVisibility === ModalVisibility.UPLOAD}
          onUpload={dispatchUploadImage}
          cropperWidth={(cropper && cropper.width) || 128}
          cropperHeight={(cropper && cropper.height) || 128}
          cropperProps={
            (cropper && cropper.props) || {
              aspectRatio: 1,
              dragMode: 'move',
              minContainerHeight: 300,
            }
          }
        />
      )}
    </>
  );
};

export default Avatar;
