import { Formik, FormikConfig, FormikHelpers } from 'formik';
import { Location } from 'history';
import {
  createContext,
  ReactElement,
  ReactNode,
  useCallback,
  useContext,
  useEffect,
  useMemo,
  useState,
} from 'react';

import { CustomOperation } from 'core/actions';
import BaseModel from 'core/models/Base';

import FormLeavePrompt from '../FormLeavePrompt';
import { FormikForm } from '../FormStyles';

import BulkEditCustomActionDialog, { OperationType } from './BulkEditCustomActionDialog';
import FormikContextHelper from './FormikContextHelper';
import TabErrorCollector from './TabErrorCollector';

export interface FormContextType {
  /** map of required fields (to display asterisk automagically) */
  requiredMap: { [name: string]: boolean };
  /** enable vieMode logic */
  viewMode?: { [name: string]: boolean | string };
  /**
   * Tells whether the field should be in view mode
   */
  isFieldInViewMode(fieldName: string): boolean;
  /** default view mode (true)
   * true -> viewMode is blacklist where to NOT USE
   * false -> viewMode is white list */
  setResetViewModeField: (fieldName: string, isViewOnly: boolean) => void;
  openedOperation?: CustomOperation;
  setOpenedOperation: (op: CustomOperation | undefined) => void;
  customOperations: CustomOperation[];
  saveOperation: (op: CustomOperation) => void;
  withoutOptionsFields: string[] | boolean;

  defaultViewMode: boolean;
  /** turn on "field has been changed" helper text */
  diffMode?: boolean;
  /** dense (compact) input variant */
  dense?: boolean;

  /**
   * Browser autocomplete buster
   *
   * Triggers busting of autocomplete on all input fields
   */
  preventAutoComplete?: boolean;

  /**
   * Entity as received from API before any transformations and changes are applied
   */
  originalEntity?: BaseModel;

  children?: ReactNode;
}

export type SharedFormProps<V extends Record<string, any>, VV extends V = V> = {
  /** enable vieMode logic */
  viewMode?: boolean | { [name: string]: boolean | string };
  /** default view mode (true)
   * true -> viewMode is black-list where to NOT use DUMMY version
   * false -> viewMode is white-list where to use DUMMY version
   */
  defaultViewMode?: boolean;
  /** handle form submit */
  onSubmit?: (
    data: VV,
    actions: FormikHelpers<V>,
    customOperations: CustomOperation[],
    resetFormOnSuccess: () => void
  ) => any;
  /** initial form data */
  initialValues?: Partial<V>;
  /** should display leave prompt when leaving unsaved form   */
  shouldBlockNextLocation?: (location: Location) => boolean;
  /** turn on "field has been changed" helper text */
  diffMode?: boolean;
};

export type FormProps<V extends Record<string, any>, VV extends V = V> = SharedFormProps<V, VV> & {
  /** User dense input variant */
  dense?: boolean;
  /** html form ID prop (used when submit button is out of form tag) */
  id: string;
  /** Fields for bulk edit which should not have options picker for ADD|REPLACE|DELETE  */
  diffWithoutOptionsFields?: string[] | boolean;
  /** Custom operations for bulk edit fields with allowed extended operations like: ADD|REPLACE|DELETE  */
  diffOperationOptions?: OperationType[];
  /**
   * Autocomplete attribute for form element
   *
   * If set to "off", the form tries to bust autocomplete on individual inputs
   * as autocomplete="off" doesn't prevent autocomplete on form in modern browsers.
   *
   * Defaults to "off"
   */
  autoComplete?: string;
  /**
   * Entity as received from API before any transformations and changes are applied
   *
   * If passed, it is available via FormContext throughout the form
   */
  originalEntity?: BaseModel;
  /**
   * Form elements
   */
  children?: ReactNode;
};

const defaultContextValue: FormContextType = {
  defaultViewMode: true,
  viewMode: undefined,
  isFieldInViewMode: () => false,
  diffMode: false,
  requiredMap: {},
  dense: false,
  setResetViewModeField: () => null,
  setOpenedOperation: () => null,
  customOperations: [],
  saveOperation: () => null,
  withoutOptionsFields: true,
  preventAutoComplete: true,
};

type Props<V extends Record<string, any>, VV extends V = V> = Omit<FormikConfig<V>, 'onSubmit'> &
  FormProps<V, VV>;

const Context = createContext<FormContextType>(defaultContextValue);

const Form: <V extends Record<string, any>, VV extends V = V>(
  p: Props<V, VV>
) => ReactElement<Props<V, VV>> = <V extends Record<string, any>, VV extends V = V>({
  defaultViewMode = true,
  enableReinitialize = true,
  validationSchema,
  initialValues,
  dense = false,
  diffMode,
  viewMode,
  onSubmit,
  children,
  id,
  shouldBlockNextLocation,
  diffWithoutOptionsFields = true,
  diffOperationOptions,
  autoComplete = 'off',
  originalEntity,
  ...rest
}: Props<V, VV>) => {
  // This tells info about viewMode if it is blacklist or whitelist for editing
  const defaultViewModeValue =
    typeof defaultViewMode === 'undefined' ? defaultContextValue.defaultViewMode : defaultViewMode;
  const [openedOperation, setOpenedOperation] = useState<CustomOperation | undefined>(undefined);
  const [customOperations, setCustomOperations] = useState<CustomOperation[]>([]);

  const getInitialValueOfViewMode = (viewMode?: boolean | { [name: string]: boolean | string }) =>
    viewMode && viewMode === true ? {} : viewMode !== false ? viewMode : undefined;

  const [usedViewMode, setUsedViewMode] = useState<
    undefined | { [name: string]: boolean | string }
  >(getInitialValueOfViewMode(viewMode));

  useEffect(() => {
    // For cases when viewMode is changed in the parent
    setUsedViewMode(getInitialValueOfViewMode(viewMode));
  }, [viewMode]);

  const handleViewModeChanged = useCallback(
    (fieldName: string, isViewOnly: boolean) => {
      const newViewMode = {
        ...(usedViewMode || {}),
        [fieldName]: defaultViewMode ? !isViewOnly : isViewOnly,
      };

      Object.keys({ ...newViewMode }).forEach((key) => {
        if (defaultViewMode && newViewMode[key]) {
          // viewMode is whitelist, if field is true remove bcs it's redundant it is editable when it's false
          delete newViewMode[key];
        }
        if (!defaultViewMode && !newViewMode[key]) {
          // viewMode is blacklist, if field is false remove bcs it's redundant it is editable when it's true
          delete newViewMode[key];
        }
      });

      setUsedViewMode({ ...newViewMode });
    },
    [usedViewMode, defaultViewMode]
  );

  const handleSaveOperation = useCallback(
    (newOp: CustomOperation) => {
      const existingOps = customOperations.filter((it) => it.fieldName !== newOp.fieldName);
      if (newOp.operation !== 'replace') {
        existingOps.push(newOp);
      }
      setCustomOperations([...existingOps]);
    },
    [customOperations]
  );

  const handleResetOperations = useCallback(() => {
    setUsedViewMode(getInitialValueOfViewMode(viewMode));
    setCustomOperations([]);
  }, [setCustomOperations, setUsedViewMode, viewMode]);

  type Fields = keyof V;

  const requiredMap = useMemo(() => {
    if (!validationSchema) {
      return {};
    }

    return getRequired(validationSchema.describe()) as { [key in Fields]: boolean };
  }, [validationSchema]);

  const val = useMemo(
    () => ({
      ...defaultContextValue,
      ...{
        diffMode: !!diffMode,
        viewMode: usedViewMode,
        isFieldInViewMode: (fieldName: string) =>
          usedViewMode?.hasOwnProperty(fieldName)
            ? !!usedViewMode.fieldName
            : !!usedViewMode && !!defaultViewModeValue,
        setResetViewModeField: handleViewModeChanged,
        requiredMap,
        defaultViewMode: defaultViewModeValue,
        dense: !!dense,
        openedOperation,
        customOperations,
        setOpenedOperation: (op: CustomOperation | undefined) => setOpenedOperation(op),
        saveOperation: handleSaveOperation,
        withoutOptionsFields: diffWithoutOptionsFields,
        preventAutoComplete: autoComplete === 'off',
        originalEntity,
      },
    }),
    [
      requiredMap,
      diffMode,
      dense,
      handleViewModeChanged,
      usedViewMode,
      customOperations,
      diffWithoutOptionsFields,
      handleSaveOperation,
      openedOperation,
      defaultViewModeValue,
      autoComplete,
      originalEntity,
    ]
  );

  return (
    <Context.Provider value={val}>
      <Formik<V>
        validationSchema={validationSchema}
        initialValues={initialValues}
        enableReinitialize={enableReinitialize}
        onSubmit={(data, formik: FormikHelpers<V>) =>
          onSubmit &&
          onSubmit(data as VV, formik, val.customOperations, () => {
            formik.resetForm({ values: data });
            handleResetOperations();
          })
        }
        {...rest}
      >
        <>
          <FormikContextHelper />
          <TabErrorCollector validationSchema={validationSchema} />
          <FormikForm placeholder={undefined} id={id} autoComplete={autoComplete}>
            {children}
          </FormikForm>
          {shouldBlockNextLocation && (
            <FormLeavePrompt shouldBlockNextLocation={shouldBlockNextLocation} />
          )}
          {diffMode && <BulkEditCustomActionDialog operationOptions={diffOperationOptions} />}
        </>
      </Formik>
    </Context.Provider>
  );
};

export default Form;
export { Context as FormContext };

export function getRequired(schemaDesc: any) {
  type RequiredMap = { [key: string]: RequiredMap | boolean };

  // Heuristics to guess this is a date range
  const looksLikeDateRange = (it: any) =>
    it &&
    it.type === 'object' &&
    it.fields &&
    it.fields.from &&
    it.fields.to &&
    Object.keys(it.fields).length === 2 &&
    it.fields.from.type === 'date' &&
    it.fields.to.type === 'date';

  // We consider field required if it has required validation
  const hasRequiredValidation = (it: any) =>
    !!(it && it.tests.find((test: { name: string }) => test.name === 'required'));

  return Object.keys(schemaDesc.fields).reduce((acc, node) => {
    const fieldName = node;
    const it = schemaDesc.fields[fieldName];

    if (looksLikeDateRange(it)) {
      acc[fieldName] = hasRequiredValidation(it);
      return acc;
    } else if (it && it.type === 'object' && it.fields) {
      acc[fieldName] = getRequired(it);
      return acc;
    } else if (it && it.type === 'array' && it.innerType && it.innerType.type === 'object') {
      acc[fieldName + '$'] = getRequired(it.innerType);
    }

    acc[fieldName] = hasRequiredValidation(it);
    return acc;
  }, {} as RequiredMap);
}

export function useFormViewMode(field?: string) {
  const { defaultViewMode, viewMode } = useContext(Context);

  return field && viewMode && viewMode.hasOwnProperty(field)
    ? viewMode[field]
    : viewMode && defaultViewMode;
}
