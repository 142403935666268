import { useMemo } from 'react';

import useAppSelector from '../useAppSelector';

/**
 * Client option hook
 * - read client option
 *
 * @param {string} optionName
 * @param {string} type Optional type requirement.
 * @returns {boolean}
 */
const useClientOption = (optionName: string, type?: string): null | string => {
  const options = useAppSelector(({ core: { user } }) => user && user.clientOptions);

  return useMemo(() => {
    const option = options?.[optionName];

    if (!option || (type && option.type !== type)) return null;

    return options?.[optionName]?.value || null;
  }, [optionName, options, type]);
};

/**
 * Client options hook
 * - read all client options
 */
export const useClientOptions = () => {
  return useAppSelector(({ core: { user } }) => (user && user.clientOptions) || {});
};

/**
 * Hook that returns true if users client options contains at least one of desired client options
 * @param {string} optionName
 * @returns {boolean}
 */
export const useBoolClientOptions = (clientOptions?: string[]): boolean => {
  const options = useAppSelector(({ core: { user } }) => user && user.clientOptions);

  return useMemo(() => {
    if (!clientOptions || clientOptions.length === 0) {
      return true;
    }

    let optionEnabled = false;
    clientOptions.forEach((simpleOption) => {
      if (options?.[simpleOption]?.value === '1') {
        optionEnabled = true;
        return;
      }
    });
    return optionEnabled;
  }, [clientOptions, options]);
};

/**
 * Boolean Client option hook
 *
 * @param {string} optionName
 * @param {string} type
 * @param {string} type Optional type requirement.
 * @returns {boolean}
 */
export const useBoolClientOption = (optionName: string, type?: string): boolean => {
  return useClientOption(optionName, type) === '1';
};

export default useClientOption;
