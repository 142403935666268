import { FormikContextType, FormikProvider, useFormikContext } from 'formik';
import { ReactNode, useEffect, useMemo, useRef } from 'react';

type OwnProps<T> = {
  /**
   * onChange event "reducer"
   * event receives:
   * - name
   * - newValue
   * - formValues
   *
   * - if name === undefined -> setValues was called instead of setFieldValue
   *
   * and MUST return new formValues
   */
  onChange?: (name: undefined | keyof T, value: undefined | T[keyof T], values: T) => T;
  children: ReactNode;
};

interface Props<T> extends OwnProps<T> {
  formik?: FormikContextType<T>;
}

/**
 * Override formik context
 *
 * - known issue: you have to use TYPE instead of INTERFACE as generic
 * see  https://github.com/microsoft/TypeScript/issues/15300
 *
 */
function FormFieldset<T>({ onChange, children }: Props<T>) {
  const { values, setValues, setFieldValue, ...form } = useFormikContext<T>();
  const valuesRef = useRef(values);

  useEffect(() => {
    valuesRef.current = values;
  }, [values]);

  const context = useMemo(
    () => ({
      ...form,
      values,
      setFieldValue: (name: keyof T, value: T[keyof T]) => {
        if (onChange) {
          // setTouched should be handled by onBlur
          setValues(onChange(name, value, valuesRef.current));
          return;
        }
        setFieldValue(name as string, value);
      },
      setValues: ((newValues: T) => {
        if (onChange) {
          setValues(onChange(undefined, undefined, newValues));
        }
        return setValues(newValues);
      }) as typeof setValues,
    }),
    [form, onChange, setFieldValue, setValues, values]
  );

  return <FormikProvider value={context as unknown as any}>{children}</FormikProvider>;
}

export default FormFieldset;
