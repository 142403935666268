export enum AdamsAction {
  SEND = 1,
  MARK_SENT = 2,
  MARK_UNSENT = 3,
}

export const TYPE_ADAMS_CHANGE = 'ADAMS_CHANGE';
export const adamsChange = (
  selection: number[] | number,
  action: AdamsAction,
  successCallback?: () => void
) =>
  ({
    type: TYPE_ADAMS_CHANGE,
    payload: {
      selection,
      action,
      successCallback,
    },
  }) as const;
export type AdamsChangeAction = ReturnType<typeof adamsChange>;

export type AdamsActions = AdamsChangeAction;
