import { DateTime } from 'luxon';
import { useCallback } from 'react';

import useClientOption from 'core/hooks/useClientOption';

const usePublishAtOffsetValue = () => {
  const testPublishOffset = useClientOption('testPublishOffset');
  const offsetOption = testPublishOffset ? Number(testPublishOffset) : 1;

  return useCallback(
    (currentPublishAt: Date | null | undefined, testDateFrom: Date | null | undefined) => {
      return currentPublishAt
        ? currentPublishAt
        : DateTime.fromJSDate(testDateFrom ? testDateFrom : new Date())
            .minus({ days: offsetOption })
            .toJSDate();
    },
    [offsetOption]
  );
};

export default usePublishAtOffsetValue;
