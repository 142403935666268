import { put, race, select, take } from '@redux-saga/core/effects';

import { AppState } from 'app/store';
import {
  apiCall,
  fireApiQuery,
  ApiQueryAction,
  apiQueryResult,
  ApiCallErrorAction,
  ApiCallSuccessAction,
  ReloadApiQueryAction,
  FireApiQueryAction,
} from 'core/actions';
import { ApiQueryOptions } from 'core/hooks/useApiCall';
import { ApiQuery, ApiQueryStatus } from 'core/reducer';

const ACTION_PREFIX = 'API_QUERY_';

export type Result = { success?: ApiCallSuccessAction; error?: ApiCallErrorAction };

/**
 * Query Api with Entity and maintain shared Local State
 */
export function* apiQuerySaga(action: ApiQueryAction) {
  const { urlOrId, options } = action.payload;

  // Because hooks cannot be called conditionally, we can skip requests until we know final url or resource name
  if (!urlOrId) {
    return;
  }

  const prev: ApiQuery<any> | undefined = yield select((s: AppState) => s.core.apiQueries[urlOrId]);

  // Skip firing request if it was already solved, and we wont force fetch (use cached/stale data by default)
  if (prev?.isFired || (prev?.status === ApiQueryStatus.SUCCESS && options.cache !== false)) {
    return;
  }

  // mark query as Fetching - prevent redundant requests
  yield put(fireApiQuery(urlOrId, options.stale === false));
}

/**
 * Trigger Api Call Request
 */
export function* fireApiQuerySaga(action: FireApiQueryAction) {
  const id = action.id;
  const query: ApiQuery<any> | undefined = yield select((s: AppState) => s.core.apiQueries[id]);

  if (!query) {
    return;
  }

  // create old "apiCall"
  const apiAction = createApiCallAction(id, query?.options);

  yield put(apiAction);

  // wait for Response, with prefix = we can filter ApiQuery requests from "oldSchool" effects
  const { success, error }: Result = yield race({
    success: take(`${ACTION_PREFIX}${id}_SUCCESS`),
    error: take(`${ACTION_PREFIX}${id}_ERROR`),
  });

  const result: ApiQuery = {
    options: query.options,
    isFired: false,
    status: error ? ApiQueryStatus.ERROR : ApiQueryStatus.SUCCESS,
    data: success?.payload.response.data,
    error: error?.payload.error,
    response: success?.payload.response || error?.payload.response,
  };

  yield put(apiQueryResult(id, result));
}

/**
 * Reload Api Queries
 */
export function* reloadApiQuerySaga(action: ReloadApiQueryAction) {
  const ids = Array.isArray(action.idOrIds) ? action.idOrIds : [action.idOrIds];

  for (const id of ids) {
    const prev: ApiQuery<any> | undefined = yield select((s: AppState) => s.core.apiQueries[id]);

    if (!prev) {
      return;
    }
    yield put(fireApiQuery(id, action.clear));
  }
}

/**
 * Transform new Options based call to apiCall Action
 * @param urlOrId
 * @param options
 * @returns
 */
const createApiCallAction = (urlOrId: string, options: ApiQueryOptions) =>
  apiCall(
    `${ACTION_PREFIX}${urlOrId}`,
    options.method || 'GET',
    options.url || urlOrId,
    options.data,
    { ...(options.config || {}), params: options.params || {} },
    false, // apiCall hook requests are handled by ApiCallToasts component
    options.previousData,
    options.ignoredPreviousParameters,
    options.diffPatch,
    options.customOperations
  );
