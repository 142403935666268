import { useFormikContext } from 'formik';
import { FC, useMemo } from 'react';
import { useTranslation } from 'react-i18next';

import { SimpleOption } from 'core/components/DropDown';
import Field from 'core/components/Form/Field';
import FormDropDown from 'core/components/FormDropDown';
import { ExplanationWrapper } from 'core/components/FormStyles';
import FormSwitch from 'core/components/FormSwitch';
import { Row } from 'core/components/Grid';
import { FullRowCell, HalfRowCell } from 'core/components/GridCell';
import PaperContainer from 'core/components/PaperContainer';
import ResourceFormDropdown from 'core/components/ResourceFormDropdown';
import { getResourceUrl } from 'core/functions/getResourcesUrl';
import { useResources } from 'core/hooks/useResource';
import FileTemplate, { FileTemplateType } from 'lists/models/FileTemplate';

import { SettingsFormData } from './useSettingsSchema';
import useSwitchSettings, { SwitchSettingName } from './useSwitchSettings';

interface Props {
  displayMode?: 'oneColumn' | 'twoColumns' | 'list';
  getFieldName?: (fieldName: string) => string;
  exclude?: Set<SwitchSettingName>;
}

const displayedOnOtherTabs = new Set(['showAthleteToDco', 'notifyOfTeamUpdates'] as const);
export const DetailSettingsTab = () => <SettingsTab exclude={displayedOnOtherTabs} />;

const SettingsTab: FC<Props> = ({ getFieldName, displayMode = 'twoColumns', exclude }) => {
  const { t } = useTranslation();
  const switchSettings = useSwitchSettings({ exclude });

  const { data: fileTemplates } = useResources<FileTemplate>(
    getResourceUrl('fileTemplates', { type: FileTemplateType.DCF })
  );
  const { values, setValues } = useFormikContext<SettingsFormData>();

  const useCustomFileTemplate = getFieldName
    ? values[getFieldName('useCustomFileTemplate') as keyof SettingsFormData]
    : values.useCustomFileTemplate;

  const useAdamsAthleteLevel = getFieldName
    ? values[getFieldName('useAdamsAthleteLevel') as keyof SettingsFormData]
    : values.useAdamsAthleteLevel;

  const templateOptions = useMemo(() => {
    if (fileTemplates) {
      return ([{ name: t('Default'), id: 'default' }] as SimpleOption[]).concat(
        fileTemplates.map((t) => ({
          id: t.id.toString(),
          name: t.name,
        }))
      );
    }
    return [];
  }, [fileTemplates, t]);

  const customFileTemplateDisabled = templateOptions.length === 0;

  const booleanControlSettings = switchSettings.control.map((it) => (
    <FullRowCell key={it.name}>
      <Field
        name={getFieldName ? getFieldName(it.name) : it.name}
        label={it.title}
        component={FormSwitch}
        inline
        viewMode={it.disabled}
      />
      <ExplanationWrapper>{it.description}</ExplanationWrapper>
    </FullRowCell>
  ));

  const valueControlSettings = (
    <>
      <FullRowCell>
        <Field
          label={t('ADAMS Athlete Level')}
          name={getFieldName ? getFieldName('useAdamsAthleteLevel') : 'useAdamsAthleteLevel'}
          fast={false}
          inline
          onChange={(newValue: boolean) => {
            if (getFieldName) {
              setValues({
                ...values,
                [getFieldName('useAdamsAthleteLevel')]: newValue,
                [getFieldName('adamsAthleteLevelsId')]: !newValue
                  ? null
                  : values[getFieldName('adamsAthleteLevelsId') as keyof SettingsFormData],
              });
            } else {
              setValues({
                ...values,
                useAdamsAthleteLevel: newValue,
                adamsAthleteLevelsId: !newValue ? null : values.adamsAthleteLevelsId,
              });
            }
          }}
          component={FormSwitch}
        />
        <ExplanationWrapper>
          {t('Athlete level used in ADAMS irrespective of athlete data.')}
        </ExplanationWrapper>
      </FullRowCell>

      {useAdamsAthleteLevel && (
        <FullRowCell>
          <Field
            label={t('ADAMS Athlete Level')}
            component={ResourceFormDropdown}
            resource="adams-athlete-levels"
            name={getFieldName ? getFieldName('adamsAthleteLevelsId') : 'adamsAthleteLevelsId'}
            fast={false}
            single
          />
        </FullRowCell>
      )}
    </>
  );

  const paperControlSettings = (
    <PaperContainer title={t('Control')}>
      <Row>
        {booleanControlSettings}

        {valueControlSettings}
      </Row>
    </PaperContainer>
  );

  const dcfSettings = (
    <>
      {switchSettings.dcf.map((it) => {
        return (
          <FullRowCell key={it.name}>
            <Field
              name={getFieldName ? getFieldName(it.name) : it.name}
              label={it.title}
              component={FormSwitch}
              inline
            />
            <ExplanationWrapper>{it.description}</ExplanationWrapper>
          </FullRowCell>
        );
      })}

      <FullRowCell>
        <Field
          disabled={customFileTemplateDisabled}
          label={t('Custom File Template')}
          name={getFieldName ? getFieldName('useCustomFileTemplate') : 'useCustomFileTemplate'}
          fast={false}
          inline
          tooltip={customFileTemplateDisabled ? t('There are no file templates yet') : null}
          onChange={(newValue: boolean) => {
            // todo

            const newValues = { ...values };
            const getName = (n: string) =>
              (getFieldName ? getFieldName(n) : n) as keyof typeof newValues;

            // @ts-ignore TODO: ...
            newValues[getName('useCustomFileTemplate')] = newValue;
            if (!newValue) {
              // @ts-ignore TODO: ...
              newValues[getName('fileTemplatesId')] = 'default';
            }

            setValues(newValues);
          }}
          component={FormSwitch}
        />
        <ExplanationWrapper>
          {t('The selected template will be used to generate the DCF document.')}
        </ExplanationWrapper>
      </FullRowCell>

      {useCustomFileTemplate && (
        <FullRowCell>
          <Field
            label={t('DCF Template')}
            options={templateOptions}
            component={FormDropDown}
            name={getFieldName ? getFieldName('fileTemplatesId') : 'fileTemplatesId'}
            fast={false}
            single
          />
        </FullRowCell>
      )}
    </>
  );

  const paperDcfSettings = (
    <PaperContainer title={t('DCF')}>
      <Row>{dcfSettings} </Row>
    </PaperContainer>
  );

  return (
    <Row>
      {displayMode === 'oneColumn' && (
        <>
          <FullRowCell>{paperControlSettings}</FullRowCell>
          <FullRowCell>{paperDcfSettings}</FullRowCell>
        </>
      )}

      {displayMode === 'twoColumns' && (
        <>
          <HalfRowCell>{paperControlSettings}</HalfRowCell>
          <HalfRowCell>{paperDcfSettings}</HalfRowCell>
        </>
      )}

      {displayMode === 'list' && (
        <>
          <FullRowCell>
            <Row>{booleanControlSettings}</Row>
          </FullRowCell>
          <FullRowCell>
            <Row>{dcfSettings}</Row>
          </FullRowCell>
          <FullRowCell>
            <Row>{valueControlSettings}</Row>
          </FullRowCell>
        </>
      )}
    </Row>
  );
};

export default SettingsTab;
