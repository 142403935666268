import { useCallback, useMemo } from 'react';
import { useDispatch } from 'react-redux';

import { deleteView, loadProfile } from 'core/actions';
import useAppSelector from 'core/hooks/useAppSelector';
import useUrlMultiState from 'core/hooks/useUrlMultiState';
import { UserView } from 'core/models/UserView';

import { COLUMNS_DEFAULT_VALUE, UrlColumnConfiguration } from '../../hooks/useColumnConfiguration';
import { getUrlConfiguration } from '../../hooks/useDefaultViewConfiguration';
import { FilterValue } from '../../props';

import { VIEW_URL_KEYS } from './Header';

const useDeleteView = (
  onApplyView: (
    filters: { [key: string]: FilterValue } | undefined,
    columnConfiguration: UrlColumnConfiguration
  ) => void,
  userViews: UserView[],
  reloadUserViews: () => void
) => {
  const usersId = useAppSelector((s) => s.core.user!.id);
  const dispatch = useDispatch();
  const { urlStatesValues: viewConfiguration } = useUrlMultiState(
    VIEW_URL_KEYS,
    COLUMNS_DEFAULT_VALUE
  );

  const activeView = useMemo(() => {
    if (!viewConfiguration?.activeView) {
      return null;
    }

    return (userViews || []).find((it) => it.id === viewConfiguration.activeView);
  }, [viewConfiguration, userViews]);

  return useCallback(
    (viewId: number) => {
      const currentView = userViews.find((it) => it.id === viewId);
      const defaultView = currentView?.default ? currentView : undefined;

      const successCallback = () => {
        reloadUserViews();

        if (defaultView) {
          // If default is removed we have to reload profile
          dispatch(loadProfile());
        }

        if (activeView && activeView?.id === viewId) {
          if (defaultView && defaultView.id !== viewId) {
            const { filters, ...rest } = defaultView.data;
            onApplyView(filters || {}, getUrlConfiguration(rest, defaultView?.id));
          } else {
            onApplyView({}, { hide: {}, activeView: undefined });
          }
        }
      };

      dispatch(
        deleteView({
          id: viewId,
          onlyRelation: currentView?.createdBy !== usersId,
          successCallback,
        })
      );
    },
    [dispatch, activeView, reloadUserViews, userViews, onApplyView, usersId]
  );
};

export default useDeleteView;
