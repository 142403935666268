import { useCallback } from 'react';
import { useTranslation } from 'react-i18next';

import { FileEntityName, useGetFileEntityLookupByName } from 'app/hooks/useFileEntityLookups';
import Event, { EventStatus } from 'app/models/Event';
import getEnv from 'core/functions/getEnv';

import { Notification, NotificationTypeEnum } from '../RenderNotification';

export default function useBulkDownload() {
  const { t } = useTranslation();

  const getFileEntityLookupByName = useGetFileEntityLookupByName();

  const getBulkDownloadUrl = useCallback(
    (eventId: number, fileEntityName: FileEntityName) => {
      const lookup = getFileEntityLookupByName(fileEntityName);

      return lookup?.urls.bulkDownload(eventId);
    },
    [getFileEntityLookupByName]
  );

  return (e: Event): Notification => {
    const map = {
      [EventStatus.IN_PROGRESS]: {
        primary: t('Bulk download submitted'),
        secondary: t('Bulk download of files is being processed.'),

        updatedAt: e.modifiedAt || e.createdAt,
        icon: 'cloud_download',
        inProgress: true,
        read: e.isRead,
        type: NotificationTypeEnum.INFO,
        id: e.id,
      },

      [EventStatus.FINISHED]: {
        primary: t('Bulk download prepared'),
        secondary: t('Bulk download is now prepared and you can download it.'),

        updatedAt: e.modifiedAt || e.createdAt,
        icon: 'cloud_download',
        inProgress: false,
        read: e.isRead,
        actions: [
          {
            key: 'download',
            text: t('Download'),
            action: () =>
              window.location.replace(
                `${getEnv('API_URL')}${getBulkDownloadUrl(e.id, e.payload?.entityName)}`
              ),
          },
        ],
        type: NotificationTypeEnum.SUCCESS,
        id: e.id,
      },

      [EventStatus.FAILED]: {
        primary: t('Bulk download failed'),
        secondary: t('An error occurred during bulk download files. Please try again later.'),

        updatedAt: e.modifiedAt || e.createdAt,
        icon: 'cloud_download',
        inProgress: false,
        read: e.isRead,
        type: NotificationTypeEnum.ERROR,
        id: e.id,
      },

      [EventStatus.CREATED]: null,
      [EventStatus.WARNING]: null,
    };

    return map[e.status] || map[EventStatus.IN_PROGRESS];
  };
}
