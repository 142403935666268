import { useMemo } from 'react';
import * as Yup from 'yup';

const useLoginSchema = (twoFactor: boolean) => {
  return useMemo(
    () =>
      Yup.object({
        email: Yup.string().nullable().email().required().default(null),
        password: Yup.string().nullable().required().default(null),
        passcode: twoFactor ? Yup.string().nullable().required() : Yup.string().nullable(),
      }),
    [twoFactor]
  );
};

export type LoginFormData = Yup.TypeOf<ReturnType<typeof useLoginSchema>>;

export default useLoginSchema;
