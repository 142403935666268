import { useTranslation } from 'react-i18next';

import { entities } from 'app/entity';
import Event, { EventStatus } from 'app/models/Event';
import {
  Notification,
  NotificationTypeEnum,
} from 'core/components/Notifications/RenderNotification';

export default function useTestUAReportReview() {
  const { t } = useTranslation();

  return (e: Event): Notification => {
    const map = {
      [EventStatus.FINISHED]: {
        primary: t('UA Report #{{ testUaReportsId }} accepted', {
          testUaReportsId: e.payload.testUaReportsId,
        }),
        secondary: t('UA Report #{{ testUaReportsId }} was accepted.', {
          testUaReportsId: e.payload.testUaReportsId,
        }),

        updatedAt: e.modifiedAt || e.createdAt,
        actions: [
          {
            key: 'open',
            text: t('Open Report'),
            action: entities.testUaReport.urls(e.payload.testsId).detail(e.payload.testUaReportsId),
          },
        ],
        icon: 'event_busy',
        inProgress: false,
        read: e.isRead,
        type: NotificationTypeEnum.SUCCESS,
        id: e.id,
      },

      [EventStatus.FAILED]: {
        primary: t('UA Report  #{{ testUaReportsId }} declined', {
          testUaReportsId: e.payload.testUaReportsId,
        }),
        secondary: t('UA Report #{{ testUaReportsId }} was declined.', {
          testUaReportsId: e.payload.testUaReportsId,
        }),

        updatedAt: e.modifiedAt || e.createdAt,
        actions: [
          {
            key: 'open',
            text: t('Open Report'),
            action: entities.testUaReport.urls(e.payload.testsId).detail(e.payload.testUaReportsId),
          },
        ],
        icon: 'event_busy',
        inProgress: false,
        read: e.isRead,
        type: NotificationTypeEnum.ERROR,
        id: e.id,
      },

      [EventStatus.CREATED]: null,
      [EventStatus.IN_PROGRESS]: null,
      [EventStatus.WARNING]: null,
    };

    return map[e.status] || map[EventStatus.FINISHED];
  };
}
