import { FC, ReactNode } from 'react';
import { Helmet } from 'react-helmet-async';

import Logo from 'app/assets/icons/Logo';
import { ButtonProps } from 'core/components/Button';
import Media from 'core/components/Media';
import Spacer from 'core/components/Spacer';
import getEnv from 'core/functions/getEnv';
import { useLocale } from 'core/i18n/useLocale';

import Link, { LinkProps } from './Link';
import {
  Actions,
  ContentContainer,
  ContentGrid,
  IllustrationWrapper,
  LogoWrap,
  PageWrap,
  PrimaryButton,
  StyledHeader,
  StyledSubtitle,
  StyledText,
} from './styled';

interface Props {
  subtitle?: string;
  title?: string;
  children?: ReactNode;
  rightIllustrations?: ReactNode;
  leftIllustrations?: ReactNode;
}

type SecondaryPageComponent = FC<Props> & {
  Button: FC<ButtonProps>;
  Link: FC<LinkProps>;
  Actions: FC<{ children?: ReactNode }>;
  Title: FC<{ children?: ReactNode }>;
  Text: FC<{ children?: ReactNode }>;
  Grid: FC<{ children?: ReactNode }>;
};

const SecondaryPage: SecondaryPageComponent = ({
  children,
  subtitle,
  title,
  rightIllustrations,
  leftIllustrations,
}) => {
  const locale = useLocale();

  return (
    <PageWrap>
      <Helmet htmlAttributes={{ lang: locale }} title={`${title} | ${getEnv('NAME')}`} />
      <Media query="tabletDesktop">
        <IllustrationWrapper>{leftIllustrations}</IllustrationWrapper>
      </Media>

      <ContentContainer>
        <LogoWrap dark>
          <Logo />
        </LogoWrap>
        {title && <StyledHeader>{title}</StyledHeader>}
        {subtitle && <StyledSubtitle>{subtitle}</StyledSubtitle>}
        <Spacer $marginTop="2rem">{children}</Spacer>
      </ContentContainer>

      <Media query="tabletDesktop">
        <IllustrationWrapper>{rightIllustrations}</IllustrationWrapper>
      </Media>
    </PageWrap>
  );
};

SecondaryPage.Button = PrimaryButton;
SecondaryPage.Title = StyledHeader;
SecondaryPage.Actions = Actions;
SecondaryPage.Grid = ContentGrid;
SecondaryPage.Text = StyledText;
SecondaryPage.Link = Link;

export default SecondaryPage;
