import { FC, useMemo } from 'react';

import { AccreditationsRole } from 'app/models/Accreditation';
import { useDetail } from 'core/containers/FormDetailPage/DetailContext';
import { useSetModal } from 'core/hooks/useModal';
import InvoiceUserDialog from 'finance/components/InvoiceUserDialog';
import useAssignedMembers from 'planning/components/TeamTab/useAssignedMembers';
import Mission from 'planning/models/Mission';

import { MissionModal, useMissionModal } from '../hooks/useMissionModal';
import { MissionFormData } from '../hooks/useMissionSchema';

const DCO_ROLE = [AccreditationsRole.DCO];
const BCO_ROLE = [AccreditationsRole.BCO];

const CreateInvoiceDialog: FC = () => {
  const { entityData } = useDetail<Mission>();

  const open = useMissionModal(MissionModal.CREATE_INVOICE);
  const setModal = useSetModal();

  const { entityData: missionData } = useDetail<Mission, MissionFormData>();
  const dcoMembers = useAssignedMembers(missionData?.teamMembers || [], DCO_ROLE);
  const bcoMembers = useAssignedMembers(missionData?.teamMembers || [], BCO_ROLE);

  const invoiceUserIds = useMemo(() => {
    return [
      ...(dcoMembers.assignedMembers || []).map((it) => it.usersId),
      ...(bcoMembers.assignedMembers || []).map((it) => it.usersId),
    ];
  }, [bcoMembers.assignedMembers, dcoMembers.assignedMembers]);

  if (!entityData) {
    return null;
  }

  return (
    <InvoiceUserDialog
      missionsId={entityData.id}
      open={open}
      onClose={() => setModal(undefined)}
      allowedUserIds={invoiceUserIds}
    />
  );
};

export default CreateInvoiceDialog;
