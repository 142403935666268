import { FC, useMemo } from 'react';
import { useTranslation } from 'react-i18next';

import { FeedbackMessage } from 'core/components/FeedbackMessage';
import useAppSelector from 'core/hooks/useAppSelector';
import BlacklistAthletes from 'personnel/models/BlacklistAthletes';
import BlacklistSportDisciplines from 'personnel/models/BlacklistSportDisciplines';

export interface Props {
  blacklists: undefined | BlacklistAthletes[] | BlacklistSportDisciplines[];
  entityName: string;
  /**
   *  A custom translated message this will be filled out with two params {{entityName}} and
   *  {{userNames}}
   */
  message?: (entityName: string, userNames: string) => string;
}

const EntityBlacklistedMessage: FC<Props> = ({ blacklists, entityName, message }) => {
  const { t } = useTranslation();

  const user = useAppSelector((state) => state.core.user);

  const msg = useMemo(() => {
    if (!blacklists || blacklists.length === 0) return '';
    const filteredBlacklists = (blacklists as { usersId: number; usersFullName: string }[])
      .filter((it) => it.usersId !== user?.id)
      .sort((a, b) => a.usersId - b.usersId)
      .filter((it, i, arr) => !i || arr[i - 1].usersId !== it.usersId);
    if (filteredBlacklists.length === 0) return '';
    const userNames =
      filteredBlacklists.length > 1
        ? filteredBlacklists.map((it) => it.usersFullName).join(', ')
        : filteredBlacklists[0].usersFullName;
    return (
      message?.(entityName, userNames) ||
      t('This {{entityName}} is blacklisted for {{userNames}}.', {
        entityName,
        userNames,
      })
    );
  }, [blacklists, entityName, message, t, user?.id]);

  if (!msg) {
    return null;
  }

  return <FeedbackMessage $type="warning">{msg}</FeedbackMessage>;
};

export default EntityBlacklistedMessage;
