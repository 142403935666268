import styled, { css, keyframes } from 'styled-components/macro';

import theme from 'app/theme';

export const StyledName = styled.div.attrs(({ className, children }) => ({ className, children }))<{
  collapse: boolean;
}>`
  width: 100%;
  padding: 0 0.625rem;
  overflow: hidden;
  text-overflow: ellipsis;
  font-size: 0.875rem;
  opacity: 1;

  ${({ collapse }) =>
    collapse &&
    css`
      opacity: 0;
      animation-name: ${opacityAnimation};
      animation-duration: 0.25s;
      animation-iteration-count: linear;
    `};

  span {
    display: block;
    font-size: 0.65rem;
    color: ${theme.color.gray200};
  }
`;

const opacityAnimation = keyframes`
  0% {  opacity: 1;  }
  100% {  opacity: 0;  }
`;
