import { DateTime } from 'luxon';
import { useCallback, useMemo } from 'react';
import { useTranslation } from 'react-i18next';

import User from 'app/models/User';
import { transformDateToString } from 'core/effects/apiCall/dateTimeTransformations';
import useMultiDropdownDataFilter from 'core/hooks/useMultiDropdownResourceFilter';
import useRangeDatagridFilter from 'core/hooks/useRangeDatagridFilter';
import useSearchDatagridFilter from 'core/hooks/useSearchDatagridFilter';
import useSingleDropdownDataFilter from 'core/hooks/useSingleDropdownDataFilter';
import AuditLogType from 'core/models/AuditLogType';

import useEntitiesFilter from './useEntitiesFilter';

const useFilters = (
  settings: {
    withEntity: boolean;
    withSearch: boolean;
  } = {
    withEntity: true,
    withSearch: true,
  }
) => {
  const { t } = useTranslation();

  const rangeFilter = useRangeDatagridFilter(
    (from: DateTime, to: DateTime) => ({
      createdAt: {
        $gte: transformDateToString(from.startOf('day')),
        $lte: transformDateToString(to.endOf('day')),
      },
    }),
    undefined,
    'createdAt'
  );

  const usersFilter = useSingleDropdownDataFilter(
    t('User'),
    'users',
    'usersId',
    false,
    'any',
    useCallback((items: User[]) => items.map((it) => ({ name: it.fullName || '-', id: it.id })), [])
  );

  const typesFilter = useMultiDropdownDataFilter(
    t('Type'),
    'audit-logs/types',
    'typesId',
    false,
    useCallback((list: AuditLogType[]) => {
      return list.map((it) => ({
        id: it.id,
        name: it.name,
        secondary: it.key,
      }));
    }, [])
  );
  const entitiesFilter = useEntitiesFilter();
  const searchFilter = useSearchDatagridFilter();
  const filters = useMemo(
    () => ({
      ...(settings.withSearch
        ? {
            search: searchFilter,
          }
        : {}),
      user: usersFilter,
      type: typesFilter,
      ...(settings.withEntity
        ? {
            entity: entitiesFilter,
          }
        : {}),
      createdAt: rangeFilter,
    }),
    [
      rangeFilter,
      searchFilter,
      typesFilter,
      usersFilter,
      entitiesFilter,
      settings.withSearch,
      settings.withEntity,
    ]
  );

  return filters;
};

export default useFilters;
