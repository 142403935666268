import { push } from 'connected-react-router';
import { useCallback, useEffect } from 'react';
import { useDispatch } from 'react-redux';

import { entities } from 'app/entity';
import useAppSelector from 'core/hooks/useAppSelector';
import usePermission from 'core/hooks/usePermission';

/**
 * This hook handles redirecting the user to the Invoice Create page.
 * If the user is not allowed create invoices on behalf of others,
 * the redirection happens automatically.
 * If they can, callback is returned for manual redirection.
 */
const useRedirectUser = (enabled: boolean, onBeforeRedirect: () => void, missionsId?: number) => {
  const dispatch = useDispatch();
  const currentUsersId = useAppSelector(({ core }) => core.user?.id) as number;
  const canOnBehalf = usePermission('dcoInvoices:create[scope:all]');

  const redirectToInvoiceCreate = useCallback(
    (usersId: number) => {
      onBeforeRedirect();
      const createInvoiceUrl = `${entities.dcoInvoice.urls(usersId).create}/detail${missionsId ? `?missionsId=${missionsId}` : ''}`;
      dispatch(push(createInvoiceUrl));
    },
    [dispatch, missionsId, onBeforeRedirect]
  );

  useEffect(() => {
    if (enabled && !canOnBehalf) {
      redirectToInvoiceCreate(currentUsersId);
    }
  }, [enabled, canOnBehalf, currentUsersId, redirectToInvoiceCreate]);

  return canOnBehalf ? redirectToInvoiceCreate : null;
};

export default useRedirectUser;
