import { FC, memo, useMemo } from 'react';

import DropDown, { Option } from 'core/components/DropDown';
import { useResources } from 'core/hooks/useResource';
import { AthleteDatagrid } from 'personnel/models/Athlete';

import { ControlLocationValues } from '..';

type Props = {
  setLocationFields: (locationValues: Partial<ControlLocationValues>) => void;
  url: string;
};

const FALSY = ['-', ''];

const AthleteAddresses: FC<Props> = ({ setLocationFields, url }) => {
  const { data: athletes } = useResources<AthleteDatagrid>(url);

  const options = useMemo(() => {
    const uniqueAddresses = (athletes || []).reduce(
      (acc: { [key: string]: Option }, it: AthleteDatagrid) => {
        if (
          (it.street && !FALSY.includes(it.street)) ||
          (it.zip && !FALSY.includes(it.zip)) ||
          (it.city && !FALSY.includes(it.city)) ||
          (it.translatedCountry && !FALSY.includes(it.translatedCountry)) ||
          (it.state && !FALSY.includes(it.state))
        ) {
          const countryString = [it.street, it.zip, it.city, it.state, it.translatedCountry]
            .filter(Boolean)
            .join(', ');
          if (!(countryString in acc)) {
            acc[countryString] = { id: it.id, name: countryString, extra: { ...it } };
          }
        }
        return acc;
      },
      {}
    );

    return Object.values(uniqueAddresses);
  }, [athletes]);

  return (
    <DropDown
      onChange={(id, item) => {
        setLocationFields(item.extra);
      }}
      options={options}
      value={null}
      mode="inline"
      loading={!athletes}
      single
    />
  );
};

export default memo(AthleteAddresses);
