import styled from 'styled-components/macro';

import theme from 'app/theme';

export const Avatar = styled.img`
  height: 3.5rem;
  width: 3.5rem;
  border-radius: 50%;
`;

export const AvatarLetters = styled.div`
  height: 3.5rem;
  width: 3.5rem;
  border-radius: 50%;
  display: flex;
  align-items: center;
  justify-content: center;
  overflow: hidden;
  font-size: 1.125rem;
  font-weight: 500;
  letter-spacing: 0.125ch;
  background-color: ${theme.color.gray400};
  color: ${theme.color.white};
`;
