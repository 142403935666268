import { useMemo } from 'react';

import { AccreditationsRole } from 'app/models/Accreditation';
import TeamMember, { TeamMemberStatus } from 'planning/models/TeamMember';

/**
 * Filter members by status Invited and Assigned, optionally filter by specific roles defined in params
 * @param members TeamMember[]
 * @param filterRoles optional param, specify if the members should be filtered by an array of specific roles
 * @returns
 */
const useAssignedMembers = (members: TeamMember[] | null, filterRoles?: AccreditationsRole[]) =>
  useMemo(() => {
    const invitedMembers = (members || []).filter((it) => it.status !== TeamMemberStatus.CONFIRMED);
    const assignedMembers = (members || []).filter(
      (it) => it.status === TeamMemberStatus.CONFIRMED
    );

    const filterMembersByRequiredRoles = (members: TeamMember[]) =>
      filterRoles
        ? members.filter((im) => filterRoles.every((r) => (im.roles || []).includes(r)))
        : members;

    return {
      invitedMembers: filterMembersByRequiredRoles(invitedMembers),
      assignedMembers: filterMembersByRequiredRoles(assignedMembers),
    };
  }, [members, filterRoles]);

export default useAssignedMembers;
