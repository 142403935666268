import { put, race, take } from 'redux-saga/effects';

import { apiCall, TYPE_INSTALL_APPLICATION, InstallApplicationAction } from 'core/actions';

export default function* installApplication(action: InstallApplicationAction) {
  const { appType, platform, version } = action.payload;

  yield put(
    apiCall(
      TYPE_INSTALL_APPLICATION,
      'GET',
      `/app/${appType ? `${appType}/` : ''}${platform}/${'' + version}`,
      {},
      {}
    )
  );

  const { success } = yield race({
    success: take(`${TYPE_INSTALL_APPLICATION}_SUCCESS`),
  });

  if (success) {
    const { url } = success.payload.response.data;
    if (url) {
      // If it returns url it should be redirected there for ios installation
      window.location.assign(url);
    }
  }
}
