import { FC, useCallback, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';

import { StatusColor } from 'app/theme';
import DetailSubheader from 'core/components/DetailSubheader';
import { RouteTabOption } from 'core/components/DetailSubheader/Tabs';
import DetailTimeZoneModal from 'core/components/DetailTimeZoneModal';
import { useDetail } from 'core/containers/FormDetailPage/DetailContext';
import { useSetModal } from 'core/hooks/useModal';
import Mission, { MissionStatus } from 'planning/models/Mission';

import { MissionModal, useMissionModal } from '../hooks/useMissionModal';

interface Props {
  tabs: RouteTabOption[];
}

const MissionDetailSubheader: FC<Props> = ({ tabs }) => {
  const [chosenTimeZone, setChosenTimeZone] = useState<string>();
  const { entityData: missionData, mode } = useDetail<Mission>();
  const isOpenTimezone = useMissionModal(MissionModal.TIME_DETAIL);
  const setModal = useSetModal();
  const { t } = useTranslation();
  const isEdit = mode === 'edit';

  const getStatus = useCallback(
    (isEdit: boolean, missionData?: Mission): StatusColor | undefined => {
      if (!isEdit) return;
      if (!missionData) return;
      return missionData.status === MissionStatus.OPEN ? 'orange' : 'green';
    },
    []
  );

  const statusText = useMemo(
    () => (isEdit && missionData?.status === MissionStatus.OPEN ? t('Open') : t('Closed')),
    [isEdit, missionData?.status, t]
  );

  const createdAt = useMemo(
    () => (isEdit && missionData?.createdAt) || undefined,
    [isEdit, missionData?.createdAt]
  );

  const modifiedAt = useMemo(
    () => (isEdit && missionData?.modifiedAt) || undefined,
    [isEdit, missionData?.modifiedAt]
  );

  return (
    <>
      <DetailSubheader
        onOpenTimeDetails={() => setModal(MissionModal.TIME_DETAIL)}
        statusColor={getStatus(isEdit, missionData)}
        chosenTimeZone={chosenTimeZone}
        statusText={statusText}
        modified={modifiedAt}
        created={createdAt}
        options={tabs}
      />

      <DetailTimeZoneModal
        afterTimeZoneChange={setChosenTimeZone}
        modified={missionData?.modifiedAt}
        created={missionData?.createdAt}
        open={isOpenTimezone}
        onClose={setModal}
      />
    </>
  );
};

export default MissionDetailSubheader;
