import { useFormikContext } from 'formik';
import { FC, Ref, useCallback, useEffect, useMemo, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch } from 'react-redux';

import { entities } from 'app/entity';
import { Option, SimpleOption } from 'core/components/DropDown';
import { useIncludeOptions } from 'core/components/ResourceFormDropdown/IncludeResourcesProvider';
import SelectOtherField, { OTHER_NUMBER } from 'core/components/SelectOtherField';
import useAppSelector from 'core/hooks/useAppSelector';
import { useResources } from 'core/hooks/useResource';
import { loadMission } from 'planning/actions';
import MissionCodeField from 'planning/containers/MissionDetailPage/tabs/Detail/MissionCodeField';
import useTestInputMapping from 'planning/containers/TestDetailPage/hooks/useTestInputMapping';
import { TestFormData } from 'planning/containers/TestDetailPage/hooks/useTestSchema';
import usePublishAtOffsetValue from 'planning/hooks/usePublishAtOffsetValue';
import Mission from 'planning/models/Mission';
import Test from 'planning/models/Test';

interface Props {
  didChange: (values: Partial<TestFormData>) => void;
  selectOtherFieldRef?: Ref<{ isOther: boolean }>;
}

const MissionsIdField: FC<Props> = ({ didChange, selectOtherFieldRef }) => {
  const { t } = useTranslation();
  const { values, setValues, submitCount } = useFormikContext<TestFormData>();
  const defaultMode = useRef<'dropdown' | 'other'>(
    values.missionsId ? 'dropdown' : values.code ? 'other' : 'dropdown'
  );
  const [mode, setMode] = useState(defaultMode.current);

  useEffect(() => {
    setMode(values.missionsId ? 'dropdown' : values.code ? 'other' : 'dropdown');
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [submitCount]);

  // read includes from form context
  const includes = useIncludeOptions('missionsId') as Mission[];
  const { data: missions, reload: loadMissions } = useResources<Mission>('missions', {
    autoload: false,
  });

  const listOptions = useMemo(
    () =>
      (missions || []).map((it) => ({
        id: it.id,
        name: it.code,
      })),
    [missions]
  ) as Option[];
  const mission = useAppSelector((state) => state.planning.missionDetail) || null;
  const withPublishAtOffset = usePublishAtOffsetValue();

  // merge list items + includes (remove duplicates)
  const options: Option[] = useMemo(() => {
    const included = (includes || []).map(({ id, code: name }) => ({
      id,
      name,
    })) as Option[];

    return listOptions.concat(
      included.filter(
        (i) =>
          listOptions.length === 0 || // has no loaded options
          !listOptions.find((j) => (j as SimpleOption).id === (i as SimpleOption).id) // not already included in list
      )
    );
  }, [includes, listOptions]);

  const waitAsyncLoad = useRef<number | null | false | 'Other'>(false);
  const testInputMapping = useTestInputMapping('clone');
  const dispatch = useDispatch();

  const generatePatch = useCallback(
    (mission: Mission): Partial<TestFormData> => {
      const { id: missionsId, ...missionsData } = mission;
      const newDefaults = {
        ...values,
        ...missionsData,
        dcos: values.dcos || missionsData.dcos,
        poolsId: values.poolsId || missionsData.poolsId,
      }; // there should be tests values plus data of mission

      // Hack to use test input mapping with mission
      const missionData = testInputMapping(newDefaults as unknown as Test);

      return {
        ...missionData,
        missionsId,
        code: null,
        externalId: null,
        generateCode: false,
        publishAt: withPublishAtOffset(undefined, missionData.dateRange?.from),
      };
    },
    [testInputMapping, values, withPublishAtOffset]
  );

  const onChangeCallback = (value: number | null) => {
    if (value) {
      if (mission && mission.id === value) {
        didChange(generatePatch(mission));
      } else {
        waitAsyncLoad.current = value;
        dispatch(loadMission(value));
      }
    } else {
      setValues({ ...values, missionsId: value });
    }
  };

  useEffect(() => {
    if (mission && waitAsyncLoad.current !== false && waitAsyncLoad.current === mission.id) {
      waitAsyncLoad.current = false;
      didChange(generatePatch(mission));
    }
  }, [mission, didChange, generatePatch]);

  return (
    <SelectOtherField
      options={options}
      name="missionsId"
      label={entities.mission.name(t) + ' *'}
      onOpen={() => !missions && loadMissions()}
      onDropdownChange={(newValue) => onChangeCallback(newValue as number | null)}
      loading={!missions}
      otherOption={{
        label: t('Create Mission'),
        value: OTHER_NUMBER,
      }}
      onClickOther={() => {
        didChange({
          ...values,
          missionsId: null,
          code: null,
          generateCode: false,
          missionsExternalId: null,
        });
      }}
      customRef={selectOtherFieldRef}
      defaultMode={mode}
      otherComponent={(autoFocus) => <MissionCodeField autoFocus={autoFocus} canAutogenerate />}
    />
  );
};

export default MissionsIdField;
