import { useTranslation } from 'react-i18next';

import { Option } from 'core/components/DropDown';
import { useBoolClientOption } from 'core/hooks/useClientOption';
import { useGetPermission } from 'core/hooks/usePermission';

/**
 *
 * @param id = split reports to groups
 * 'clientStatistics' - single report for SuperAdmins only
 * 'reports' - planning reports
 * 'all' - consumed by some generic helpers like Notifications generator (requires Option - Name mapping)
 * @returns list of
 */
function useReportOptions(id: 'clientStatistics' | 'reports' | 'all') {
  const enableDcr = useBoolClientOption('enableDcr');
  const getPermission = useGetPermission();
  const { t } = useTranslation();

  const canFindTestReports = getPermission('testReports:find');
  const canFindClients = getPermission('clients:find');
  const canFindTests = getPermission('tests:find');
  const canFindCocs = getPermission('cocs:find');

  let list = [];

  const clientStatistics = [
    {
      id: 'clients',
      name: t('Client statistics'),
      allowed: canFindClients,
    },
  ];

  const reports = [
    { id: 'tests', name: t('Tests'), allowed: canFindTests },
    { id: 'cocs', name: t('Chain of Custody'), allowed: canFindCocs },
    {
      id: 'samples',
      name: t('Samples'),
      // TODO: enable once API supports
      allowed: false, // permissions && permissions['samples:find'],
    },
    'SEPARATOR' as const,
    {
      id: 'dcrs',
      name: t('DCR'),
      allowed: enableDcr && canFindTestReports,
    },
    {
      id: 'dcrIntel',
      name: t('DCR Intels'),
      allowed: enableDcr && canFindTestReports,
    },
  ];

  switch (id) {
    case 'clientStatistics':
      list = clientStatistics;
      break;
    case 'reports':
      list = reports;
      break;
    default:
      list = [...reports, ...clientStatistics];
  }

  return list
    .filter((i) => i === 'SEPARATOR' || i.allowed) // process options & permissions
    .filter(
      // key > 0 = do not start with SEPARATOR
      // key < a.length -1 = do not end with SEPARATOR
      // key + 1 !== 'SEPARATOR' = eliminate double separator
      (i, k, a) => i !== 'SEPARATOR' || (k > 0 && k < a.length - 1 && a[k + 1] !== i)
    ) as Option[];
}

export default useReportOptions;
