import { Button } from '@material/react-button';
import { FC } from 'react';
import { Helmet } from 'react-helmet-async';
import { useTranslation } from 'react-i18next';

import { FlexRow } from 'core/components/FlexUtils';
import MaterialIcon from 'core/components/MaterialIcon';
import { PageBody, PageContent } from 'core/components/Page/styled';
import Header from 'core/components/PageHeader';
import getEnv from 'core/functions/getEnv';
import { useLocale } from 'core/i18n/useLocale';
import { NotFound } from 'core/illustrations/NotFound';

import { EmptyContent, EmptyContentText, Img } from './styled';

const EntityNotFoundPage: FC<{
  backControl?: { to: string; text: string };
  entityName: string;
  id: any;
}> = ({ backControl, entityName, id }) => {
  const { t } = useTranslation();
  const locale = useLocale();

  return (
    <>
      <Helmet
        title={t("Can't find {{title}} | {{appName}}", {
          title: entityName,
          appName: getEnv('NAME'),
        })}
        htmlAttributes={{ lang: locale }}
      />
      <PageContent>
        <Header
          backControl={backControl}
          title={t("Can't find record '{{title}}' #{{id}}", {
            title: entityName,
            id,
          })}
        />
        <PageBody>
          <FlexRow fullWidth horizontalAlign="center">
            <EmptyContent>
              <Img>
                <NotFound />
              </Img>
              <div>
                <EmptyContentText>
                  {t("The '{{title}}' #{{id}} you are looking for wasn’t found or doesn’t exist.", {
                    title: entityName,
                    id,
                  })}
                </EmptyContentText>
                <Button icon={<MaterialIcon icon="arrow_back" />} href={backControl?.to}>
                  {backControl?.text}
                </Button>
              </div>
            </EmptyContent>
          </FlexRow>
        </PageBody>
      </PageContent>
    </>
  );
};

export default EntityNotFoundPage;
