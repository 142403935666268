import { Operation } from 'fast-json-patch';
import { FC, PropsWithChildren, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';

import Form from 'core/components/Form';
import { useDetail } from 'core/containers/FormDetailPage/DetailContext';
import useAppSelector from 'core/hooks/useAppSelector';
import { useSetModal } from 'core/hooks/useModal';
import usePermission from 'core/hooks/usePermission';
import Mission, { MissionStatus } from 'planning/models/Mission';

import { MissionModal } from './hooks/useMissionModal';
import useMissionOutputMapping, { MissionRequestModel } from './hooks/useMissionOutputMapping';
import useMissionSchema, { MissionFormData, ValidMissionFormData } from './hooks/useMissionSchema';
import ApplyTestChangesModal from './modals/ApplyTestChangesModal';

import { DISALLOWED_TEST_CHANGE_FIELDS } from '.';

interface Props extends PropsWithChildren {
  onEdit: (
    id: number,
    data: MissionRequestModel,
    prevData: MissionRequestModel,
    sc?: (diff: Operation[] | undefined) => void
  ) => void;
}

const MissionForm: FC<Props> = ({ children, onEdit }) => {
  const {
    shouldBlockNextLocation,
    entityData: missionData,
    formData: initials,
    id: missionId,
    onCreate,
    mode,
  } = useDetail<Mission, MissionFormData>();
  const canEditDetails = usePermission('missions:patch[actions:details]');
  const canEditStatement = usePermission('missions:patch[actions:statement]');
  const myId = useAppSelector((s) => s.core.user!.id);
  const outputMapping = useMissionOutputMapping();
  const isCreate = mode === 'create';
  const schema = useMissionSchema();
  const { t } = useTranslation();
  const setModal = useSetModal();
  const isEdit = mode === 'edit';
  const isClone = mode === 'clone';

  const [patchTests, setPatchTests] = useState<
    undefined | { patch: Operation[]; newData: any; prevData: any }
  >(undefined);

  const testCount = missionData?.testCount || 0;

  const situationDisclaimer = t(
    'You cannot change the Test Situation because the current Mission has {{count}} Tests already assigned.',
    { count: testCount }
  );

  const viewMode = useMemo(() => {
    if (canEditDetails)
      return {
        testSituationsId: Boolean(isEdit && testCount) ? situationDisclaimer : false,
        plannedAt: false,
      } as { [name: string]: boolean };
    return canEditStatement ? { plannedAt: missionData?.status === MissionStatus.CLOSED } : {};
  }, [
    canEditDetails,
    canEditStatement,
    isEdit,
    missionData?.status,
    situationDisclaimer,
    testCount,
  ]);

  if (isCreate || isClone) {
    initials.assigneesId = myId || null;
  }

  const askPatchTests = (patch: Operation[] | undefined, newData: any, prevData: any) => {
    if (!patch || patch.length === 0) {
      return;
    }
    const patchFields = patch.filter(
      (i) => DISALLOWED_TEST_CHANGE_FIELDS.indexOf(i.path.replace('/', '')) === -1
    );

    if (patchFields.length > 0) {
      setPatchTests({ patch, newData, prevData });
      setModal(MissionModal.PATCH_TESTS);
    }
  };

  const handleEdit = (data: MissionFormData, initials: MissionFormData, updateTests?: boolean) => {
    const formData = outputMapping(data);
    const prevData = outputMapping(initials);

    missionId &&
      onEdit(
        missionId,
        formData,
        prevData,
        (diff: any) => updateTests && askPatchTests(diff, formData, prevData)
      );
  };

  return (
    <>
      <Form<MissionFormData, ValidMissionFormData>
        onSubmit={(data) => {
          !isEdit ? onCreate(data) : handleEdit(data, initials, Boolean(testCount));
        }}
        validationSchema={schema}
        initialValues={initials}
        defaultViewMode={!canEditDetails}
        id="detailForm"
        shouldBlockNextLocation={shouldBlockNextLocation}
        viewMode={viewMode}
      >
        {children}
      </Form>

      {missionId && isEdit && <ApplyTestChangesModal patchTests={patchTests} />}
    </>
  );
};

export default MissionForm;
