import styled from 'styled-components/macro';

import { DisplayType } from '.';

export const Spacer = styled.div<{
  $marginTop?: string;
  $marginBottom?: string;
  $marginRight?: string;
  $marginLeft?: string;
  $display?: DisplayType;
}>`
  margin-bottom: ${(props) => (props.$marginBottom ? props.$marginBottom : '0rem')};
  margin-right: ${(props) => (props.$marginRight ? props.$marginRight : '0rem')};
  margin-left: ${(props) => (props.$marginLeft ? props.$marginLeft : '0rem')};
  margin-top: ${(props) => (props.$marginTop ? props.$marginTop : '0rem')};
  display: ${(props) => (props.$display ? props.$display : 'block')};
`;
