import { useSelector, TypedUseSelectorHook, shallowEqual } from 'react-redux';

import { AppState } from 'app/store';

/**
 * Typed alternative to useSelector that allows retrieving of multiple values as an object
 *
 * Should be always used instead of useSelector
 */
const useAppSelector: TypedUseSelectorHook<AppState> = (selector, equalityFn) => {
  return useSelector(selector, (equalityFn as any) || shallowEqual);
};

export default useAppSelector;
