import { RowNode } from 'ag-grid-community';
import { FC, useCallback, useEffect, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';

import ContextMenu from 'core/components/ContextMenu';
import IconButton from 'core/components/IconButton';
import Modal from 'core/components/Modal';
import { BulkAction } from 'core/containers/DataGrid';

import { SelectedRows } from '../../props';

import { Icons, SelectionText } from './styled';

interface Props {
  bulkActions?: BulkAction[];
  selectedRows: SelectedRows;
  onBulkSuccess: () => void;
  getSelectedNodes: () => RowNode[];
}

const BulkActions: FC<Props> = ({ bulkActions, selectedRows, getSelectedNodes, onBulkSuccess }) => {
  const { t } = useTranslation();
  const [bulkConfirmation, setBulkConfirmation] = useState<BulkAction>();

  // Reflect changes in the selected bulk action
  useEffect(() => {
    setBulkConfirmation((current) => {
      if (!bulkActions) return undefined;

      const updated = bulkActions.find((a) => a.key === current?.key);
      return updated || current;
    });
  }, [bulkActions]);

  const ids = useMemo(
    () => (selectedRows === 'ALL' ? getSelectedNodes().map((n) => Number(n.id)) : selectedRows),
    [getSelectedNodes, selectedRows]
  );

  const { primaryActions, secondaryActions } = useMemo(
    () =>
      (bulkActions || [])?.reduce(
        (acc, b) => {
          if (b.secondary) acc.secondaryActions.push(b);
          else acc.primaryActions.push(b);
          return acc;
        },
        {
          primaryActions: [] as BulkAction[],
          secondaryActions: [] as BulkAction[],
        }
      ),
    [bulkActions]
  );

  const handleOnClick = useCallback(
    (action: BulkAction) => {
      if (action.confirmation) {
        setBulkConfirmation(action);
      } else {
        if (selectedRows === 'ALL') {
          action.onClick(
            getSelectedNodes().map((n) => +n.id!),
            onBulkSuccess
          );
        } else {
          action.onClick(selectedRows, onBulkSuccess);
        }
      }
    },
    [selectedRows, getSelectedNodes, onBulkSuccess]
  );

  const handleBulkActionConfirmation = () => {
    if (!bulkActions) {
      return;
    }

    const action = bulkConfirmation;
    if (action) {
      action.onClick(ids, onBulkSuccess);
    }

    setBulkConfirmation(undefined);
  };

  return (
    <>
      <SelectionText>
        {t('Selected items: {{number}}', {
          number: selectedRows === 'ALL' ? t('All visible') : selectedRows.length,
        })}
      </SelectionText>

      <Icons aria-label={t('Bulk Actions')}>
        {primaryActions.length > 0 &&
          primaryActions.map((action) => (
            <IconButton
              key={action.key}
              data-cy={`bulk-action-${action.key}`}
              icon={action.icon}
              tooltip={action.text}
              disabled={action.disabled}
              onClick={() => {
                handleOnClick(action);
              }}
            />
          ))}
        {secondaryActions.length > 0 && (
          <ContextMenu
            menuItems={secondaryActions.map((action) => {
              const onClick = () => handleOnClick(action);
              return {
                key: action.key,
                text: action.text,
                icon: action.icon,
                disabled: action.disabled,
                onClick,
              };
            })}
            menuId="secondaryControls"
          >
            <IconButton icon="more_horiz" tooltip={t('More')} />
          </ContextMenu>
        )}
      </Icons>

      <Modal
        ariaLabel={t('Bulk action confirmation dialog')}
        onCancel={() => setBulkConfirmation(undefined)}
        onClose={() => setBulkConfirmation(undefined)}
        onConfirm={handleBulkActionConfirmation}
        title={t(`Confirm bulk action`)}
        open={!!bulkConfirmation}
      >
        {typeof bulkConfirmation?.confirmation === 'function'
          ? bulkConfirmation.confirmation({ selection: ids })
          : bulkConfirmation?.confirmation}
      </Modal>
    </>
  );
};

export default BulkActions;
