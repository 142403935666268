import { FC } from 'react';
import { useTranslation } from 'react-i18next';

import Field from 'core/components/Form/Field';
import FormTextfield from 'core/components/FormTextfield';
import { Cell, Row } from 'core/components/Grid';
import { FullRowCell } from 'core/components/GridCell';

const CreateTestsFieldset: FC = () => {
  const { t } = useTranslation();

  return (
    <FullRowCell>
      <Row>
        <Cell desktopColumns={4} phoneColumns={4}>
          <Field
            label={t('Number of Male Tests')}
            component={FormTextfield}
            name="maleCount"
            fast={false}
            type="number"
            min={0}
          />
        </Cell>

        <Cell desktopColumns={4} phoneColumns={4}>
          <Field
            label={t('Number of Female Tests')}
            component={FormTextfield}
            name="femaleCount"
            fast={false}
            type="number"
            min={0}
          />
        </Cell>
        <Cell desktopColumns={4} phoneColumns={4}>
          <Field
            label={t('Number of Others Tests')}
            component={FormTextfield}
            name="otherCount"
            fast={false}
            type="number"
            min={0}
          />
        </Cell>
      </Row>
    </FullRowCell>
  );
};

export default CreateTestsFieldset;
