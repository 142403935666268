import { useCallback } from 'react';
import { useTranslation } from 'react-i18next';

import useClientOption from 'core/hooks/useClientOption';
import { useFormatDateTimeRange } from 'core/i18n/useFormatDateTime';
import AvailabilityCategory from 'personnel/models/AvailabilityCategory';
import { ProfileAvailability } from 'personnel/models/ProfileAvailability';

export const useHumanReadableAvailability = (availabilityCategories?: AvailabilityCategory[]) => {
  const { t } = useTranslation();
  const formatDateTimeRange = useFormatDateTimeRange();
  const availabilitySetting = useClientOption('defaultAvailabilityCategoriesId');

  const defaultAvailabilityId = availabilitySetting ? +availabilitySetting : null;
  const defaultAvailability = availabilityCategories?.find(
    (c) => c.id === defaultAvailabilityId
  )?.fullName;

  return useCallback(
    (availabilities?: ProfileAvailability[]) => {
      if (availabilities && availabilities.length > 0) {
        return availabilities
          .map((it) => {
            const dateRange = formatDateTimeRange(it.dateFrom, it.dateTo);

            return `${it.category && it.category.fullName} ${dateRange}`;
          })
          .join(', ');
      }

      // We display ellipsis while loading default
      return defaultAvailabilityId ? defaultAvailability || '...' : t('availability unknown');
    },
    [defaultAvailabilityId, defaultAvailability, t, formatDateTimeRange]
  );
};
