import { useCallback } from 'react';

import { formatDateTime, formatDateTimeRange, formatDateTimeToRelative } from './formatDateTime';
import { useLocale } from './useLocale';

export type FormatParameters = Parameters<typeof formatDateTime>;
type FormatRangeParameters = Parameters<typeof formatDateTimeRange>;
type FormatRelativeParameters = Parameters<typeof formatDateTimeToRelative>;

/**
 * Format date or time in local format using (current by default or specific) locale
 */
export const useFormatDateTime = () => {
  const locale = useLocale();

  return useCallback(
    (p0: FormatParameters[0], p1: FormatParameters[1]) => formatDateTime(p0, p1, locale),
    [locale]
  );
};

/**
 * Format date or time range in local format using (current by default or specific) locale
 *
 * If both start and end are passed, it returns 'formattedStart - formattedEnd'
 * If only one is passed, it returns 'from formattedStart' or 'to formattedEnd'
 * If neither start nor end is passed, it returns an empty string
 *
 * @param start Start of the range
 * @param end End of the range
 * @param format Optional format, defaults to DATE_SHORT
 */
export const useFormatDateTimeRange = () => {
  const locale = useLocale();

  return useCallback(
    (p0?: FormatRangeParameters[0], p1?: FormatRangeParameters[1], p2?: FormatRangeParameters[2]) =>
      formatDateTimeRange(p0, p1, p2, locale),
    [locale]
  );
};

export const useFormatRelativeDateTime = () => {
  const locale = useLocale();

  return useCallback(
    (p0: FormatRelativeParameters[0], p1?: FormatRelativeParameters[1]) =>
      formatDateTimeToRelative(p0, p1, locale),
    [locale]
  );
};
