import { FC, memo } from 'react';

import { RolesId } from 'app/models/Role';
import Comments from 'core/containers/Comments';
import { useDetail } from 'core/containers/FormDetailPage/DetailContext';
import usePermission from 'core/hooks/usePermission';

const MissionCommentsTab: FC = () => {
  const { id } = useDetail();
  const canAddComments = usePermission('missions:comments[actions:create]');

  if (!id) {
    return null;
  }
  return (
    <Comments
      entity="mission"
      entityId={id}
      blacklistedRoles={[RolesId.DCC]}
      canAddComment={canAddComments}
    />
  );
};

export default memo(MissionCommentsTab);
