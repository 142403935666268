import { ListItem } from '@material/react-list';
import { DateTime } from 'luxon';
import { FC, useCallback } from 'react';

import { HOURS, MINUTES, isHoursDisabled, isMinutesDisabled } from '../helpers';

import { Divider, Slider, Wrapper } from './styled';

export interface Props {
  onChange: (date: Date) => void;
  value?: Date;
  min?: Date;
  max?: Date;
}

const Sliders: FC<Props> = ({ onChange, value, min, max }) => {
  const selectedValue = value ? DateTime.fromJSDate(value) : DateTime.fromISO('2000-01-01T00:00');

  const selectedHour = selectedValue.hour;
  const selectedMinute = selectedValue.minute;

  const activedHours = useCallback(
    () => HOURS.filter((hour) => !isHoursDisabled(hour, min, max)),
    [min, max]
  );

  const activedMinutes = useCallback(
    () => MINUTES.filter((hour) => !isMinutesDisabled(hour, min, max, selectedHour)),
    [min, max, selectedHour]
  );

  const activedTime = {
    hour: activedHours(),
    minute: activedMinutes(),
  };

  const handleSelection = (unit: 'hour' | 'minute') => (val: number) => {
    onChange(selectedValue.set({ [unit]: activedTime[unit][val] }).toJSDate());
  };

  return (
    <Wrapper>
      <Slider
        dense={true}
        singleSelection
        selectedIndex={activedHours().indexOf(selectedHour) || 0}
        handleSelect={handleSelection('hour')}
      >
        {HOURS.map((h) => (
          <ListItem key={h} disabled={isHoursDisabled(h, min, max)}>
            {h.toString().padStart(2, '0')}
          </ListItem>
        ))}
      </Slider>
      <Divider>:</Divider>
      <Slider
        dense={true}
        singleSelection
        selectedIndex={activedMinutes().indexOf(selectedMinute) || 0}
        handleSelect={handleSelection('minute')}
      >
        {MINUTES.map((m) => (
          <ListItem
            id={m.toString()}
            disabled={isMinutesDisabled(m, min, max, selectedHour)}
            key={m}
          >
            {m.toString().padStart(2, '0')}
          </ListItem>
        ))}
      </Slider>
    </Wrapper>
  );
};

export default Sliders;
