import { ColDef, IHeaderParams, RowNode } from 'ag-grid-community';
import { ReactNode, useCallback, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';

import Modal from 'core/components/Modal';
import { useGetPermission } from 'core/hooks/usePermission';

import RowActionsCellRenderer from '../CellRenderers/RowActionsCellRenderer';
import ColumnHeaderComponent from '../ColumnHeaderComponent';
import { HeaderCheckboxCellRenderer, StatusCellRenderer } from '../FastCellRenderers';
import {
  BulkAction,
  ColumnDef,
  HandleSortCallback,
  RowAction,
  SetConfirmationRowAction,
  StatusColumn,
} from '../props';
import {
  CustomColumnConfiguration,
  customizeUsingConfiguration,
} from '../Toolbar/CustomizeViews/tabs/Columns';

export const STATUS_COL_ID = 'prefix-status';

function useColumnDefs<T>(
  columnDefs: ColumnDef<T>[],
  columnConfiguration: CustomColumnConfiguration,
  reloadData: () => void,
  handleSort: HandleSortCallback,
  rowActions?: RowAction<T>[],
  statusColumn?: StatusColumn,
  bulkActions?: BulkAction[],
  disableSorting?: boolean,
  sortState?: [string, 'asc' | 'desc'] | null,
  printMode?: boolean
): { preparedColumnDefs: ColDef<T>[]; confirmationDialog: ReactNode } {
  const evalPermission = useGetPermission();
  const [confirmationDialog, setConfirmationDialog] = useState<SetConfirmationRowAction>();
  const { t } = useTranslation();

  const handleConfirmActionDialog = useCallback(() => {
    if (confirmationDialog && 'onClick' in confirmationDialog && confirmationDialog.onClick) {
      confirmationDialog.onClick();
    }

    confirmationDialog && setConfirmationDialog(undefined);
  }, [confirmationDialog]);

  const availableRowActions = useMemo(() => {
    if (!rowActions) {
      return undefined;
    }

    const permittedRowActions = rowActions.filter((it) => {
      if (!it.permission) {
        return true;
      }

      if (!evalPermission) {
        return false;
      }

      if (typeof it.permission === 'string') {
        return evalPermission(it.permission, it.underOriginUser);
      }

      return it.permission.every((permission) => evalPermission(permission, it.underOriginUser));
    });

    return permittedRowActions.length > 0 ? permittedRowActions : undefined;
  }, [evalPermission, rowActions]);

  // Minimize changes in dependencies
  const hasBulkActions = !!bulkActions?.length;
  // eslint-disable-next-line react-hooks/exhaustive-deps
  const getStatus = useMemo(() => statusColumn?.getColor, []);

  // Column defs shouldn't change by default as ag-grid won't handle names properly, adding _1 to names
  const preparedColumnDefs = useMemo(() => {
    const prefixColumns = [] as ColDef<T>[];
    const suffixColumns = [] as ColDef<T>[];

    if (getStatus) {
      prefixColumns.push({
        cellRenderer: StatusCellRenderer,
        cellRendererParams: { color: getStatus, icon: statusColumn?.getIcon },
        colId: STATUS_COL_ID,
        minWidth: 42,
        maxWidth: 42,
        lockPosition: true,
        resizable: false,
        pinned: 'left',
        headerComponent: statusColumn?.colDef
          ? (p: IHeaderParams<T>) => (
              <ColumnHeaderComponent
                headerParams={p}
                columnDef={statusColumn.colDef!}
                handleSort={handleSort}
                sortState={sortState}
              />
            )
          : undefined,
      });
    }

    if (!printMode && hasBulkActions) {
      prefixColumns.push({
        headerComponent: HeaderCheckboxCellRenderer,
        checkboxSelection: true,
        colId: 'prefix-check',
        minWidth: 44,
        maxWidth: 44,
        lockPosition: true,
        resizable: false,
        pinned: 'left',
      });
    }

    if (!printMode && availableRowActions) {
      suffixColumns.push({
        cellRenderer: (row: RowNode) =>
          availableRowActions && (
            <RowActionsCellRenderer
              rowId={row.id || row.rowIndex || 'row'}
              data={row.data}
              rowActions={availableRowActions}
              setConfirmationModal={setConfirmationDialog}
              reloadData={reloadData}
            />
          ),
        colId: 'suffix-actions',
        minWidth: 64,
        maxWidth: 64,
        lockPosition: true,
        resizable: false,
        pinned: 'right',
      });
    }

    return customizeUsingConfiguration<T>(
      [
        ...prefixColumns,
        ...columnDefs.map((column): ColumnDef<T> => {
          // eslint-disable-next-line @typescript-eslint/no-unused-vars
          const { customFilter, entity, ...columnProps } = column;
          return {
            ...columnProps,
            colId: (column.field as string) || undefined,
            headerComponent:
              column.headerComponent ||
              ((p: IHeaderParams<T>) => (
                <ColumnHeaderComponent
                  headerParams={p}
                  columnDef={column}
                  handleSort={handleSort}
                  sortable={!disableSorting && column.sortable}
                  sortState={sortState}
                />
              )),
            // Turn off filtering that we are handling ourselves
            filter: false,
            sortable: disableSorting ? false : column.sortable,
          };
        }),
        ...suffixColumns,
      ],
      columnConfiguration
    );
  }, [
    getStatus,
    printMode,
    hasBulkActions,
    availableRowActions,
    columnDefs,
    columnConfiguration,
    statusColumn,
    handleSort,
    reloadData,
    disableSorting,
    sortState,
  ]);

  const confirmationModal = useMemo(
    () => (
      <Modal
        ariaLabel={t('Confirmation Dialog')}
        onClose={() => setConfirmationDialog(undefined)}
        onCancel={() => setConfirmationDialog(undefined)}
        onConfirm={handleConfirmActionDialog}
        title={t(`Confirm Action`)}
        open={!!confirmationDialog}
      >
        {confirmationDialog && confirmationDialog.confirmation}
      </Modal>
    ),
    [confirmationDialog, handleConfirmActionDialog, t]
  );

  return { preparedColumnDefs, confirmationDialog: confirmationModal };
}

export default useColumnDefs;
