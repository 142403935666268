import { useCallback } from 'react';

import { useLocale } from 'core/i18n/useLocale';

function useGetFormatAmount(): (amount: number | string, currency: undefined | string) => string {
  const locale = useLocale();

  const formatAmount = useCallback(
    (amount: number | string, currency: undefined | string) => {
      if (!currency) {
        return new Intl.NumberFormat(locale).format(Number(amount) || 0);
      }

      return new Intl.NumberFormat(locale, {
        style: 'currency',
        currency: currency,
      }).format(Number(amount) || 0);
    },
    [locale]
  );

  return formatAmount;
}

export default useGetFormatAmount;
