import { ListItemGraphic } from '@material/react-list';
import classNames from 'classnames';
import { ListChildComponentProps } from 'react-window';

import { Option, SimpleOption } from '..';
import { StyledList } from '../styled';

import LoadingItem from './LoadingItem';
import { Checkbox, Separator } from './styled';

interface Props extends ListChildComponentProps {
  handleSelect: (id: string | number, group?: string) => void;
  selected: Set<string | number>;
  single?: boolean;
}

export function getItemSize(option: Option | 'LOADING', fontSize: number) {
  if (option === 'SEPARATOR') {
    // Height + margin
    return 1 + 0.25 * 2 * fontSize;
  }

  if (typeof option === 'object') {
    if ('options' in option) {
      // Header size is 1.75rem line height + 1.5rem margin
      // Options size is options number * 40px
      return 1.75 * fontSize + 1.5 * fontSize + option.options.length * 40;
    }

    if ('secondary' in option) {
      return 60;
    }
  }

  return 40;
}

const ItemRenderer = ({ handleSelect, selected, single, style, index, data }: Props) => {
  const o = data[index] as Option | 'LOADING';
  if (o === 'SEPARATOR')
    // Don't apply height on separator as it'll stretch it
    return <Separator role="separator" style={{ ...style, height: undefined }} />;

  if (o === 'LOADING') return <LoadingItem style={style} single={single} />;

  const render = (o: SimpleOption, g?: string) => {
    const isSelected = selected.has(o.id);

    return (
      <div
        role="checkbox"
        aria-checked={isSelected ? 'true' : 'false'}
        aria-disabled={o.disabled ? 'true' : 'false'}
        tabIndex={0}
        className={classNames('mdc-list-item', {
          'mdc-list-item--selected': isSelected,
          'mdc-list-item--disabled': o.disabled,
        })}
        style={style}
        data-id={o.id}
        data-numberid={typeof o.id === 'number'}
        key={o.id}
        onClick={() => !o.disabled && handleSelect(o.id, g)}
      >
        {o.graphic && <ListItemGraphic graphic={o.graphic} />}
        <span
          className={`mdc-list-item__text ${o.secondary ? '' : 'mdc-list-item--without-two-lines'}`}
        >
          {o.secondary ? (
            <>
              <span className="mdc-list-item__primary-text">
                {o.icons}
                {o.name}
              </span>
              <span className="mdc-list-item__secondary-text">{o.secondary}</span>
            </>
          ) : (
            o.name
          )}
        </span>
        {o.meta && <div className="mdc-list-item__meta">{o.meta}</div>}
        {!single && (
          <div className="mdc-list-item__meta">
            <Checkbox
              icon={isSelected ? 'check_box' : 'check_box_outline_blank'}
              disabled={o.disabled}
            />
          </div>
        )}
      </div>
    );
  };

  if (o && 'options' in o) {
    return (
      <div className="mdc-list-item" style={style}>
        <h3 className="mdc-list-group__subheader">{o.name}</h3>
        <StyledList>{o.options.map((option) => render(option, o.id))}</StyledList>
      </div>
    );
  }

  return render(o);
};

export default ItemRenderer;
