import Tab from '@material/react-tab';
import { useFormikContext } from 'formik';
import { FC, useCallback, useEffect, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';

import {
  FileEntityKey,
  FileEntityName,
  getFileEntityName,
  useFileEntityLookupByName,
  useGetFileEntityLookupByName,
} from 'app/hooks/useFileEntityLookups';
import { Option } from 'core/components/DropDown';
import Field from 'core/components/Form/Field';
import { ExplanationWrapper } from 'core/components/FormStyles';
import { FullRowCell } from 'core/components/GridCell';
import ResourceFormDropdown from 'core/components/ResourceFormDropdown';
import StyledTabBar from 'core/components/StyledTabBar';
import { FormData as FileAttachmentFormData } from 'core/containers/AddFilesDialog/useSchema';
import Base from 'core/models/Base';
import FileType from 'core/models/FileType';

interface Props {
  onEntityChange: () => void;
  fileEntity?: FileEntityKey;
  fileType?: FileType;
}

const EntityFields: FC<Props> = ({ fileType, fileEntity, onEntityChange }) => {
  const { t } = useTranslation();
  const getFileEntityLookupByName = useGetFileEntityLookupByName();
  const { values, setValues } = useFormikContext<FileAttachmentFormData>();
  const [activeEntityIndex, setActiveEntity] = useState(0);

  useEffect(() => {
    setActiveEntity(0);
  }, [fileType]);

  const getEntitiesArrayFromString = useCallback((fileType: FileType) => {
    const entitiesString = fileType.entities;
    const entities: FileEntityName[] = [];
    entitiesString.split(`;`).forEach((item: string) => {
      entities.push(item.replace(/'/g, '') as FileEntityName);
    });
    return entities;
  }, []);

  const fileEntityName = fileEntity && getFileEntityName(fileEntity);

  const actualEntities: FileEntityName[] = useMemo(
    () =>
      (fileEntityName && [fileEntityName]) ||
      (fileType && getEntitiesArrayFromString(fileType)) ||
      [],
    [fileEntityName, fileType, getEntitiesArrayFromString]
  );

  const actualLookup = useFileEntityLookupByName(actualEntities[activeEntityIndex]);

  const assignmentOptionsReducer: (list: Base[]) => Option[] = useCallback(
    (fileAssignment: Base[]) => {
      return fileAssignment.map((x) => ({
        id: x.id,
        name: x.id.toString() || '',
      }));
    },
    []
  );

  const assignmentDisabled = (fileEntity && true) || false;

  return (
    <>
      {!assignmentDisabled && actualEntities.length > 1 && (
        <FullRowCell>
          <StyledTabBar>
            {actualEntities.map((entityName: FileEntityName, index: number) => {
              const entityLookup = getFileEntityLookupByName(entityName);

              return (
                <Tab
                  active={index === activeEntityIndex}
                  onClick={() => {
                    setActiveEntity(index);
                    onEntityChange();
                  }}
                  key={entityName}
                  type="button"
                >
                  {entityLookup && entityLookup.label}
                </Tab>
              );
            })}
          </StyledTabBar>
        </FullRowCell>
      )}

      {actualLookup && (
        <FullRowCell>
          <Field
            onChange={(val: number | null) => {
              setValues({
                ...values,
                entityId: val,
                entityName: actualEntities[activeEntityIndex],
              });
            }}
            resourceReducer={assignmentOptionsReducer}
            component={ResourceFormDropdown}
            disabled={assignmentDisabled}
            resource={actualLookup.urls.entities}
            label={actualLookup.label}
            name="entityId"
            fast={false}
            single
          />
          <ExplanationWrapper disableBorder={true}>
            {t('This File will be assigned to selected {{entityName}}', {
              entityName: actualLookup.label,
            })}
          </ExplanationWrapper>
        </FullRowCell>
      )}
    </>
  );
};

export default EntityFields;
