import IconButton from '@material/react-icon-button';
import styled, { keyframes } from 'styled-components/macro';

import theme from 'app/theme';

export const Notifications = styled(IconButton)`
  background: transparent;
  position: relative;
  overflow: visible;
  margin: ${({ $expand }: { $expand?: boolean }) => ($expand ? 'auto' : '0 -10px 0 0')};
`;

export const NotificationsBadge = styled.span`
  background: var(--mdc-theme-error);
  color: ${theme.color.white};
  white-space: nowrap;
  position: absolute;
  border-radius: 8px;
  line-height: 12px;
  padding: 2px 3px;
  font-size: 12px;
  left: 50%;
  top: 4px;
  min-width: 1rem;
`;

const ring = keyframes`
  0% { transform: rotateZ(0); }
  4% { transform: rotateZ(30deg); }
  8% { transform: rotateZ(-28deg); }
  12% { transform: rotateZ(34deg); }
  16% { transform: rotateZ(-32deg); }
  20% { transform: rotateZ(30deg); }
  24% { transform: rotateZ(-28deg); }
  28% { transform: rotateZ(26deg); }
  32% { transform: rotateZ(-24deg); }
  36% { transform: rotateZ(22deg); }
  40% { transform: rotateZ(-20deg); }
  44% { transform: rotateZ(18deg); }
  48% { transform: rotateZ(-16deg); }
  52% { transform: rotateZ(14deg); }
  56% { transform: rotateZ(-12deg); }
  60% { transform: rotateZ(10deg); }
  64% { transform: rotateZ(-8deg); }
  68% { transform: rotateZ(6deg); }
  72% { transform: rotateZ(-4deg); }
  76% { transform: rotateZ(2deg); }
  80% { transform: rotateZ(-1deg); }
  84% { transform: rotateZ(1deg); }
  88% { transform: rotateZ(0); }
  100% { transform: rotateZ(0); }
`;

export const bellAnimationDuration = 2500;
export const Bell = styled.span`
  position: relative;
  display: flex;

  &.ring {
    animation: ${ring} ${bellAnimationDuration}ms ease-in-out forwards;
  }
`;
