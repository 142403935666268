import { useFormikContext } from 'formik';
import { FC, useMemo } from 'react';
import { useTranslation } from 'react-i18next';

import { SimpleOption } from 'core/components/DropDown';
import DummyField from 'core/components/DummyField';
import Field from 'core/components/Form/Field';
import FormDropDown from 'core/components/FormDropDown';
import { ExplanationWrapper } from 'core/components/FormStyles/styled';
import FormSwitch from 'core/components/FormSwitch';
import FormTextField from 'core/components/FormTextfield';
import { Cell, Grid, Row } from 'core/components/Grid';
import { FullRowCell, HalfRowCell } from 'core/components/GridCell';
import PaperContainer from 'core/components/PaperContainer';
import Slider from 'core/components/Slider';
import useAppSelector from 'core/hooks/useAppSelector';
import { useBoolClientOption } from 'core/hooks/useClientOption';
import {
  AthleteDocumentNumber,
  CovidSrfStatus,
  DEFAULT_NOTIFICATION_ARRIVAL_DELAY,
} from 'settings/models/Setting';

import { FormData } from '../useSchema';

import AutomaticApproval from './AutomaticApproval';
import { StyledSliderWrapper } from './styled';
import useAutoArchiveItems from './useAutoArchiveItems';

export const SESSION_LIFETIME_DEFAULT = 1;

const Settings: FC = () => {
  const { t } = useTranslation();
  const { setFieldValue, values, setValues } = useFormikContext<FormData>();
  const sessionLifetime = values.value_sessionLifetime || SESSION_LIFETIME_DEFAULT;
  const invoicesEnabled = useBoolClientOption('enableDcoInvoices');

  const athleteDocumentNumberOptions: SimpleOption[] = useMemo(() => {
    return [
      {
        id: AthleteDocumentNumber.MANDATORY,
        name: t('Show as mandatory'),
        secondary: t(
          'Field is shown as mandatory in the app and will be visible on the DCF and in the BO results.'
        ),
      },
      {
        id: AthleteDocumentNumber.OPTIONAL,
        name: t('Show as optional'),
        secondary: t(
          'Field is shown as optional in the app and will be visible on the DCF and in the BO results.'
        ),
      },
      {
        id: AthleteDocumentNumber.HIDDEN,
        name: t('Don’t show'),
        secondary: t(
          'Field is not shown in the app and not visible on the DCF and in the BO results.'
        ),
      },
    ];
  }, [t]);

  const covidSrfOptions: SimpleOption[] = useMemo(() => {
    return [
      { id: CovidSrfStatus.DISABLED, name: t('Disabled') },
      { id: CovidSrfStatus.ENABLED, name: t('Enabled') },
      { id: CovidSrfStatus.ENABLED_OPENED, name: t('Enabled and Opened') },
      { id: CovidSrfStatus.ENABLED_CLOSED, name: t('Enabled and Closed') },
    ];
  }, [t]);

  const autoArchiveEnabled = useBoolClientOption('enableAutoArchive');
  const clientOptions = useAppSelector(({ core: { user } }) => user?.clientOptions || {});
  const autoArchiveOptions = useAutoArchiveItems();
  const tdpsEnabled = useBoolClientOption('enableTdp');
  const enable3genApp = useBoolClientOption('enable3genApp');

  const setAndDefault = (name: string) => (value: boolean) =>
    setValues({
      ...values,
      [`enabled_${name}`]: value,
      [`value_${name}`]: !value ? null : 7,
    });

  const controlModules = (
    <PaperContainer title={t('Control Modules')}>
      <Row>
        <FullRowCell>
          <Field
            name="enabled_enableCancelControl"
            label={t('Control Cancellation')}
            component={FormSwitch}
            inline
          />

          <ExplanationWrapper>
            {t(
              'The "Cancel Control" button will appear in the app. Only an ongoing control can be canceled.'
            )}
          </ExplanationWrapper>
        </FullRowCell>

        <FullRowCell>
          <Field
            label={t('Notification Address Copying')}
            name="enabled_enableAddressCopying"
            component={FormSwitch}
            inline
          />

          <ExplanationWrapper>
            {t(
              'The "Copy control address" button will appear in the "Athlete Information" step. Clicking the button copies the control location to the contact information.'
            )}
          </ExplanationWrapper>
        </FullRowCell>

        <FullRowCell>
          <Field
            label={t('Delay Between Notification Time and Arrival Time')}
            name="enabled_arrivalDelayCheckEnabled"
            component={FormSwitch}
            fast={false}
            inline
            onChange={(value: boolean) =>
              setValues({
                ...values,
                enabled_arrivalDelayCheckEnabled: value,
                value_arrivalDelayMax: DEFAULT_NOTIFICATION_ARRIVAL_DELAY,
              })
            }
          />

          <ExplanationWrapper>
            {t(
              'This setting will force DCOs to specify the reason why the Athlete arrived late at the Doping Control Station. If this setting is enabled, it is possible to define the maximum allowed delay between Notification time and Arrival time before the reason must be explained.'
            )}
          </ExplanationWrapper>
        </FullRowCell>

        {values['enabled_arrivalDelayCheckEnabled'] && (
          <FullRowCell>
            <Field
              label={t('Trigger Time Difference (Minutes)')}
              name="value_arrivalDelayMax"
              component={FormTextField}
              fast={false}
              type="number"
            />
          </FullRowCell>
        )}

        <FullRowCell>
          <Field
            name="enabled_enableCoachDoctor"
            label={t('Coach & Doctor')}
            component={FormSwitch}
            inline
          />

          <ExplanationWrapper>
            {t('The "Coach Name" and "Doctor Name" fields will appear in the Athlete Information.')}
          </ExplanationWrapper>
        </FullRowCell>

        <FullRowCell>
          <Field
            name="enabled_medicationNameMandatoryOnly"
            label={t('Only Medication Name mandatory')}
            component={FormSwitch}
            inline
          />

          <ExplanationWrapper>
            {t(
              'Choose whether only the Medication Name, or all of the Medication fields in the Test Result Declaration are mandatory.'
            )}
          </ExplanationWrapper>
        </FullRowCell>

        <FullRowCell>
          <Field
            name="enabled_enableAthleteAutoSetAsMaster"
            label={t('Automatically Set as Master')}
            component={FormSwitch}
            inline
          />

          <ExplanationWrapper>
            {t('The latest created revision will be considered a master record.')}
          </ExplanationWrapper>
        </FullRowCell>

        {tdpsEnabled && (
          <FullRowCell>
            <Field
              name="enabled_tdpFederationMandatory"
              label={t('TDP Federation Mandatory')}
              component={FormSwitch}
              inline
            />

            <ExplanationWrapper>
              {t('Federation field in TDPs module will be mandatory.')}
            </ExplanationWrapper>
          </FullRowCell>
        )}

        <FullRowCell>
          <Field
            name="value_covidSrf"
            label={t('Covid SRF')}
            component={FormDropDown}
            options={covidSrfOptions}
            single
            fast
          />

          <ExplanationWrapper>
            {t('This setting allows to choose behavior of Covid SRF in the Mobile Application.')}
          </ExplanationWrapper>
        </FullRowCell>
      </Row>
    </PaperContainer>
  );

  const securityAndPolicy = (
    <PaperContainer title={t('Privacy & Security')}>
      <Row>
        <FullRowCell>
          <Field
            name="enabled_filterAthletePersonalData"
            label={t('Filtering Private Data')}
            component={FormSwitch}
            inline
          />

          <ExplanationWrapper>
            {t(
              `Athlete private information (address, phone, and email) will not be transferred to the app.`
            )}
          </ExplanationWrapper>
        </FullRowCell>

        <FullRowCell>
          <Field
            label={t('Allow DCO detail editing')}
            name="enabled_allowDcoDetailEdit"
            component={FormSwitch}
            inline
          />

          <ExplanationWrapper>
            {t('The DCO will be able to edit their personal data.')}
          </ExplanationWrapper>
        </FullRowCell>

        <FullRowCell>
          <Field
            label={t('Allow DCOs to see archived records')}
            name="enabled_showArchivedToDcos"
            component={FormSwitch}
            inline
          />

          <ExplanationWrapper>
            {t('The DCOs will be able to see records which are archived.')}
          </ExplanationWrapper>
        </FullRowCell>

        <FullRowCell>
          <Field
            label={t('Allow assigned DCOs to see test analyses')}
            name="enabled_showAnalysesToAssignedDco"
            component={FormSwitch}
            inline
          />

          <ExplanationWrapper>
            {t('The assigned DCOs will be able to see test analyses.')}
          </ExplanationWrapper>
        </FullRowCell>

        <FullRowCell>
          <Field
            name="value_athleteDocumentNumber"
            label={t('Athlete Document Number')}
            component={FormDropDown}
            options={athleteDocumentNumberOptions}
            single
            fast
          />

          <ExplanationWrapper>
            {t(
              'Control visibility of the athlete’s document number in DCF and the 3rd Gen App. When empty, the "Hide the Athlete’s Document Number" setting is used instead.'
            )}
          </ExplanationWrapper>
        </FullRowCell>

        <FullRowCell>
          <Field
            label={t('Hide the Athlete’s Document Number')}
            name="enabled_hideAthleteDocumentNumber"
            component={FormSwitch}
            inline
          />

          <ExplanationWrapper>
            {t(
              'Hides the athlete’s document number in DCF and makes it optional in the application.'
            )}
          </ExplanationWrapper>
        </FullRowCell>

        <FullRowCell>
          <Field
            label={t('Application Locking')}
            name="enabled_requireLocking"
            component={FormSwitch}
            inline
          />

          <ExplanationWrapper>
            {t(
              'After installing the app and signing in, the user will need to choose an additional locking method. If "No" is selected, this setting can be skipped.'
            )}
          </ExplanationWrapper>
        </FullRowCell>

        <FullRowCell>
          <Field
            label={t('Application Lock Timer')}
            name="enabled_enableLockTimer"
            component={FormSwitch}
            inline
            onChange={(value: boolean) =>
              setValues({
                ...values,
                enabled_enableLockTimer: value,
                value_lockTimerTimeout: null,
              })
            }
            fast={false}
          />

          <ExplanationWrapper>
            {t(
              'Defines whether the application locks automatically. If the option is enabled, it is possible to define the timeout period for the application locking.'
            )}
          </ExplanationWrapper>
        </FullRowCell>

        {values['enabled_enableLockTimer'] && (
          <FullRowCell>
            <Field
              label={t('Timer Timeout (in minutes)')}
              name="value_lockTimerTimeout"
              component={FormTextField}
              fast={false}
              type="number"
            />
          </FullRowCell>
        )}

        <FullRowCell>
          <Row>
            <Cell desktopColumns={3} tabletColumns={3} phoneColumns={4}>
              <DummyField
                label={t('Session Lifetime')}
                value={`${t('Hours: {{count}}', { count: sessionLifetime })}`}
              />
            </Cell>

            <Cell desktopColumns={9} tabletColumns={5} phoneColumns={4}>
              <StyledSliderWrapper>
                <Slider
                  step={1}
                  min={1}
                  max={48}
                  onChange={(newValue: number) => setFieldValue('value_sessionLifetime', newValue)}
                  leftIcon="history"
                  rightIcon="update"
                  value={sessionLifetime}
                />
              </StyledSliderWrapper>
            </Cell>
          </Row>

          <ExplanationWrapper>
            {t('The session for inactive users will expire after selected time.')}
          </ExplanationWrapper>
        </FullRowCell>

        {enable3genApp && (
          <FullRowCell>
            <Field
              label={t('Enable Previous Declarations')}
              name="enabled_enablePreviousDeclarations"
              component={FormSwitch}
              inline
            />

            <ExplanationWrapper>
              {t('Pre-fill medications in Declarations step with previous athlete data.')}
            </ExplanationWrapper>
          </FullRowCell>
        )}
      </Row>
    </PaperContainer>
  );

  const autoArchive = (
    <PaperContainer title={t('Automatic Archiving')}>
      {autoArchiveOptions
        .filter((option) =>
          option.clientOption ? clientOptions[option.clientOption]?.value === '1' : true
        )
        .map((option) => (
          <Row key={option.key}>
            <FullRowCell>
              <Field
                name={`enabled_${option.key}`}
                label={option.entity}
                inline
                component={FormSwitch}
                onChange={
                  option.type === 'AGE'
                    ? setAndDefault(option.key)
                    : (value: boolean) => setFieldValue(`enabled_${option.key}`, value)
                }
                fast={false}
              />
              {(!values[`enabled_${option.key}` as keyof FormData] ||
                option.type === 'BOOLEAN') && (
                <ExplanationWrapper>{option.description}</ExplanationWrapper>
              )}
            </FullRowCell>
            {values[`enabled_${option.key}` as keyof FormData] && option.type === 'AGE' && (
              <FullRowCell>
                <Row>
                  <Cell desktopColumns={3} tabletColumns={3} phoneColumns={4}>
                    <DummyField
                      label={option.source}
                      value={`${t('Days: {{count}}', {
                        count: Number(values[`value_${option.key}` as keyof FormData]),
                      })}`}
                    />
                  </Cell>

                  <Cell desktopColumns={9} tabletColumns={5} phoneColumns={4}>
                    <StyledSliderWrapper>
                      <Slider
                        step={1}
                        min={1}
                        max={60}
                        onChange={(newValue: number) =>
                          setFieldValue(`value_${option.key}`, newValue)
                        }
                        leftIcon="history"
                        rightIcon="update"
                        value={Number(values[`value_${option.key}` as keyof FormData])}
                      />
                    </StyledSliderWrapper>
                  </Cell>
                </Row>

                {values[`enabled_${option.key}` as keyof FormData] && (
                  <ExplanationWrapper>{option.description}</ExplanationWrapper>
                )}
              </FullRowCell>
            )}
          </Row>
        ))}
    </PaperContainer>
  );

  return (
    <Grid>
      <Row>
        <HalfRowCell>
          <Row>
            <FullRowCell>{securityAndPolicy}</FullRowCell>
            {invoicesEnabled && <AutomaticApproval />}
          </Row>
        </HalfRowCell>
        <HalfRowCell>
          <Row>
            <FullRowCell>{controlModules}</FullRowCell>

            {autoArchiveEnabled && <FullRowCell>{autoArchive}</FullRowCell>}
          </Row>
        </HalfRowCell>
      </Row>
    </Grid>
  );
};

export default Settings;
