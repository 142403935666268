import { saveAs } from 'file-saver';
import { put, race, take } from 'redux-saga/effects';

import { apiCall, ExportDataAction, PREFIX_EXPORT_DATA_ENDPOINT } from 'core/actions';
import { stringify } from 'core/functions/qs';

function* exportData(action: ExportDataAction) {
  const { endpoint, options } = action.payload;
  const { type, paramsFromFilters, format, selectedRows, exportAll } = options;

  const selectedRowIds = {
    ...(selectedRows && selectedRows !== 'ALL' && selectedRows.length > 0 && { id: selectedRows }),
  };

  const payload = {
    exportType: type || undefined,
    offset: 0,
    limit: 10000,
    ...(!exportAll && { ...paramsFromFilters, ...selectedRowIds }),
  };

  const headers = { Accept: `text/${format}` };

  yield put(
    apiCall(PREFIX_EXPORT_DATA_ENDPOINT, 'GET', endpoint, payload, {
      headers,
      paramsSerializer: (params) =>
        stringify(params, {
          encodeValuesOnly: true,
        }),
    })
  );

  const { success, error } = yield race({
    success: take(`${PREFIX_EXPORT_DATA_ENDPOINT}_SUCCESS`),
    error: take(`${PREFIX_EXPORT_DATA_ENDPOINT}_ERROR`),
  });

  if (error) {
    return;
  }

  const blob = new Blob([success.payload.response.data], {
    type: `text/${action.payload.options.format};charset=utf-8`,
  });

  const fileName =
    action.payload.options.fileName ||
    `export-${endpoint.replace('/', '')}-${Date.now()}.${format}`;

  saveAs(blob, fileName);

  action.payload.successCallback && action.payload.successCallback();
}

export default exportData;
