import { FC } from 'react';

import { Option } from 'core/components/DropDown';
import DummyField, { DummyFieldProps, getLink } from 'core/components/DummyField';

interface Props extends DummyFieldProps {
  options: Array<Option>;
  loading?: boolean;
  value?: any;
  dummyValue?: string;
}

const DummyDropdownField: FC<Props> = ({
  endAdornment,
  disabledLink,
  tooltip,
  options,
  dense,
  label,
  value,
  field,
  link,
  valueStyle,
  dummyValue,
}) => {
  const val = value || (field && field.value);
  let inputVal: Array<string | number> =
    typeof val === 'number' || typeof val === 'string' ? [val] : Array.isArray(val) ? val : [];

  if (inputVal && !Array.isArray(inputVal)) {
    inputVal = [inputVal];
  }

  const selected: string[] =
    (options &&
      Array.isArray(options) &&
      inputVal &&
      options.reduce((acc: string[], option) => {
        if (typeof option === 'object' && inputVal.indexOf(option.id) > -1) {
          acc.push(option.name);
        }
        return acc;
      }, [])) ||
    [];

  return (
    <DummyField
      link={(link && inputVal.length === 1 && getLink(link, inputVal[0])) || ''}
      value={selected || dummyValue}
      disabledLink={disabledLink}
      endAdornment={endAdornment}
      tooltip={tooltip}
      label={label}
      dense={dense}
      valueStyle={valueStyle}
    />
  );
};

export default DummyDropdownField;
