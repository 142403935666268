import { FieldProps, ErrorMessage, getIn } from 'formik';
import { FC, useCallback, useMemo } from 'react';

import DropzonePicker from 'core/components/DropzonePicker/';
import { FeedbackMessage } from 'core/components/FeedbackMessage';
import FieldGroup from 'core/components/FieldGroup';

interface OwnProps {
  onChange?: (newValue: File | File[]) => void;
  multiple?: boolean;
  error?: boolean;
  disabled?: boolean;
}

export type Props = FieldProps<File> & OwnProps;

const FormFileInput: FC<Props> = ({
  form: { touched, errors, setFieldValue },
  field: { name, value },
  onChange,
  error,
  disabled,
  multiple = false,
}) => {
  const onChangeCallback = useCallback(
    (files: File[]) => {
      if (onChange) {
        onChange(multiple ? files : files[0]);
      } else {
        setFieldValue(name, multiple ? files : files[0]);
      }
    },
    [setFieldValue, name, onChange, multiple]
  );

  // dropzone needs File[]
  const decoratedValue = useMemo(
    () => (value ? (Array.isArray(value) ? value : [value]) : []),
    [value]
  );

  const onRemoveFileCallback = useCallback(
    (removeIndex: number) => {
      if (disabled) return;

      const filteredFiles = decoratedValue.filter(
        (f: File, index: number) => removeIndex !== index
      );
      if (multiple) {
        setFieldValue(name, filteredFiles);
      } else {
        const newValue = filteredFiles.length > 0 ? filteredFiles : null;
        setFieldValue(name, newValue);
      }
    },
    [setFieldValue, name, decoratedValue, multiple, disabled]
  );

  const hasError = error !== undefined ? error : !!getIn(touched, name) && !!getIn(errors, name);

  return (
    <FieldGroup>
      <DropzonePicker
        onFileChoose={onChangeCallback}
        choosedFiles={decoratedValue}
        removeFile={onRemoveFileCallback}
        multiple={multiple}
        disabled={disabled}
        hasError={hasError}
      />
      {hasError && <ErrorMessage component={FeedbackMessage} name={name} />}
    </FieldGroup>
  );
};

export default FormFileInput;
