import { useCallback } from 'react';
import { useTranslation } from 'react-i18next';

import { TeamMemberStatus } from 'planning/models/TeamMember';

/**
 * Human readable team member status
 */
const useGetStatusName = () => {
  const { t } = useTranslation();

  return useCallback(
    (status?: TeamMemberStatus) => {
      switch (status) {
        case TeamMemberStatus.ACCEPTED:
          return t('Accepted') as string;

        case TeamMemberStatus.AWAITING:
          return t('Awaiting') as string;

        case TeamMemberStatus.CONFIRMED:
          return t('Confirmed') as string;

        case TeamMemberStatus.DECLINED:
          return t('Declined') as string;

        case TeamMemberStatus.SELECTED:
          return t('Selected') as string;

        default:
          return t('Unknown') as string;
      }
    },
    [t]
  );
};

export default useGetStatusName;
