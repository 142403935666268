import React from 'react';

import { StyledDivider } from './styled';

interface DividerProps {
  orientation?: 'horizontal' | 'vertical';
  color?: string;
  marginVertical?: string;
}

const Divider: React.FC<DividerProps> = ({
  orientation = 'horizontal',
  color,
  marginVertical,
}: DividerProps) => {
  return (
    <StyledDivider
      $orientation={orientation}
      $backgroundColor={color}
      $marginVertical={marginVertical}
    />
  );
};

export default Divider;
