import { useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import * as Yup from 'yup';

import { RECIPIENTS } from '.';

export const MAX_EMAILS_VALIDATION = 20;

const useSchema = () => {
  const { t } = useTranslation();

  return useMemo(
    () =>
      Yup.object({
        emailType: Yup.string().nullable().required().default(null),
        withRecipients: Yup.boolean().default(true),
        recipients: Yup.array(Yup.number())
          .nullable()
          .when('withRecipients', {
            is: true,
            then: Yup.array().required().min(1),
          })
          .default([]),
        customText: Yup.string().nullable().default(null),
        emails: Yup.array(Yup.string().email())
          .unique(t('Duplicate Email'))
          .nullable()
          .default([])
          .when('recipients', {
            is: (recipients: number[] | null | undefined) =>
              (recipients || []).includes(RECIPIENTS.OTHER),
            then: Yup.array().required().min(1),
          })
          .max(MAX_EMAILS_VALIDATION)
          .ensure()
          .default([]),
        filesId: Yup.array().nullable().ensure().default(null),
      }),
    [t]
  );
};

export type FormData = Yup.TypeOf<ReturnType<typeof useSchema>>;

export default useSchema;
