import { push } from 'connected-react-router';
import { toast } from 'react-toastify';
import { put, race, select, take } from 'redux-saga/effects';

import { entities } from 'app/entity';
import { State } from 'app/store/prepareStore';
import {
  TYPE_EDIT_ENTITY,
  TYPE_LOAD_ENTITY_SUCCESS,
  apiCall,
  apiQueryResult,
  editEntity,
  loadEntity,
  loadEntitySuccess,
  reloadApiQuery,
} from 'core/actions';
import createDiff from 'core/effects/apiCall/createDiff';
import getResourcesUrl from 'core/functions/getResourcesUrl';
import { stringify } from 'core/functions/qs';
import showUpdatedRecordsMessage from 'core/functions/showUpdatedRecordsMessage';
import { t } from 'core/i18n';
import { ApiQuery, CoreState } from 'core/reducer';
import {
  AbortTestAction,
  BulkEditTestAction,
  EditTestAction,
  IndividualBulkEditTestAction,
  SaveTestAssignmentDiffAction,
  TYPE_ABORT_TEST,
  TYPE_BULK_EDIT_TEST,
  TYPE_INDIVIDUAL_BULK_EDIT_TEST,
  TYPE_SAVE_TEST_ASSIGNMENT_DIFF,
  TYPE_TEST_BULK_CREATE,
  TestBulkCreateAction,
  testAssignmentChange,
  testAssignmentDiffResolved,
} from 'planning/actions';
import { MAX_INDIVIDUAL_BULK_EDITED } from 'planning/containers/TestIndividualBulkEditPage';
import TeamMember, { TeamMemberStatus } from 'planning/models/TeamMember';
import Test from 'planning/models/Test';
import { TestAssignmentDiff } from 'planning/reducer';

import { clearMemberOutbound } from './missions';

export function* bulkEditTestSaga(action: BulkEditTestAction) {
  const {
    selection: params,
    outputMapping,
    formData,
    initials,
    customOperations,
    successCallback,
  } = action.payload;

  const skipOperations = (customOperations || []).filter((it) => it.operation === 'skip');

  if (skipOperations?.length) {
    params.patchOpSkip = skipOperations.map((it) => it.fieldName);
  }

  yield put(
    apiCall(
      TYPE_BULK_EDIT_TEST,
      'PATCH',
      `/tests/bulk`,
      outputMapping(formData),
      {
        params,
        paramsSerializer: (params) =>
          stringify(params, {
            encodeValuesOnly: true,
          }),
      },
      true,
      outputMapping({
        ...initials,
        // TODO: handle special touched fields when there isn't changed value but we want to send PATCH anyway
        // @ts-ignore
        dcos:
          formData.dcos && formData.dcos.length === 0 && initials.dcos !== formData.dcos
            ? [0]
            : initials.dcos,
        bcos:
          formData.bcos && formData.bcos.length === 0 && initials.bcos !== formData.bcos
            ? [0]
            : initials.bcos,
        chaperones:
          formData.chaperones &&
          formData.chaperones.length === 0 &&
          initials.chaperones !== formData.chaperones
            ? [0]
            : initials.chaperones,
      }),
      [],
      true,
      customOperations
    )
  );
  const { success } = yield race({
    success: take(`${TYPE_BULK_EDIT_TEST}_SUCCESS`),
    error: take(`${TYPE_BULK_EDIT_TEST}_ERROR`),
  });

  if (success) {
    showUpdatedRecordsMessage(success.payload.response);

    yield put({
      type: `${TYPE_BULK_EDIT_TEST}_SUCCESS`,
      payload: success.payload,
    });
    successCallback && successCallback();
  }
}

export function* individualBulkEditTestSaga(action: IndividualBulkEditTestAction) {
  // eslint-disable-next-line no-unused-vars
  const { prevData, newData, selection } = action.payload;

  // Manually create diff for each test
  const diffs = Object.fromEntries(
    Object.entries(newData).map(([id, test]) => [
      id,
      // @ts-ignore TODO: Currently, TS won't correctly assume implicit index signature on analyses
      createDiff(test, prevData[+id], []),
    ])
  );

  yield put(
    apiCall(
      TYPE_INDIVIDUAL_BULK_EDIT_TEST,
      'PATCH',
      `/tests/bulk`,
      diffs,
      undefined,
      true,
      undefined,
      [],
      false
    )
  );
  const { success } = yield race({
    success: take(`${TYPE_INDIVIDUAL_BULK_EDIT_TEST}_SUCCESS`),
    error: take(`${TYPE_INDIVIDUAL_BULK_EDIT_TEST}_ERROR`),
  });

  if (success) {
    showUpdatedRecordsMessage(success.payload.response);

    // Update changed tests in resources
    const resourceKey = getResourcesUrl(
      'tests',
      { ...selection, withAllFields: true, limit: MAX_INDIVIDUAL_BULK_EDITED, withCounts: true },
      { encodeValuesOnly: true }
    );

    const existingApiQueryState: ApiQuery<any> = yield select(
      (state: { core: CoreState }) => state.core.apiQueries[resourceKey]
    );
    const existingTests: { data: Test[]; filtered: number; total: number | null } =
      existingApiQueryState.data;

    const newTests = success.payload.response.data.data.reduce(
      (acc: { [id: number]: Test }, test: Test) => {
        acc[test.id] = test;
        return acc;
      },
      {}
    );

    yield put(
      apiQueryResult(resourceKey, {
        ...existingApiQueryState,
        data: {
          data: existingTests.data.map((existingTest) => newTests[existingTest.id] || existingTest),
          filtered: existingTests.filtered,
          total: existingTests.total,
        },
      })
    );
  }
}

export function* testBulkCreateSaga(action: TestBulkCreateAction) {
  const { bulkCreateOptions, missionsId, successCallback } = action.payload;
  const { male, female, other, athletesId } = bulkCreateOptions;

  let testCount = 0;
  if (athletesId && athletesId.length > 0) {
    testCount += athletesId.length;
  }
  if (male) {
    testCount += male;
  }
  if (female) {
    testCount += female;
  }
  if (other) {
    testCount += other;
  }

  if (testCount) {
    yield put(
      apiCall(action.type, 'POST', `/tests/mission/${missionsId}/tests`, {
        maleCount: Number(male) || undefined,
        femaleCount: Number(female) || undefined,
        othersCount: Number(other) || undefined,
        athletesId,
      })
    );
  } else {
    toast.error(t('No athletes or their genders were picked - no tests will be created.'));
  }

  const { success } = yield race({
    success: take(`${TYPE_TEST_BULK_CREATE}_SUCCESS`),
    error: take(`${TYPE_TEST_BULK_CREATE}_ERROR`),
  });

  if (success) {
    toast.success(
      t('{{count}} new tests created', {
        count: testCount,
      })
    );

    yield put(loadEntity(`/missions/${missionsId}`));

    yield race({
      success: take(TYPE_LOAD_ENTITY_SUCCESS),
      error: take(`${TYPE_TEST_BULK_CREATE}_ERROR`),
    });

    successCallback && successCallback();
  }
}

export function* editTestSaga(action: EditTestAction) {
  const { prevData, formData, id } = action.payload;

  yield put(editEntity({ id, data: formData, endpoint: `/tests/${id}` }, prevData));

  const { success } = yield race({
    success: take(`${TYPE_EDIT_ENTITY}_SUCCESS`),
    error: take(`${TYPE_EDIT_ENTITY}_ERROR`),
  });

  if (success) {
    // Offer sync of advanced assignment changes to mission
    const hasAdvancedAssignment: boolean | undefined = yield select(
      ({ core }: State) => core.user?.clientOptions['enableAdvancedDcoAssignment']?.value === '1'
    );

    if (hasAdvancedAssignment) {
      const newlyAssignedMembers = (formData.teamMembers || []).filter(
        (newTeamMember: TeamMember) => {
          // We need only assigned/confirmed
          if (newTeamMember.status !== TeamMemberStatus.CONFIRMED) {
            return false;
          }

          const alreadyWasInPreviousData = (prevData.teamMembers || []).find(
            (it) => it.usersId === newTeamMember.usersId && it.status === TeamMemberStatus.CONFIRMED
          );

          return !alreadyWasInPreviousData;
        }
      );

      if (newlyAssignedMembers.length && formData.missionsId) {
        yield put(testAssignmentChange({ teamMembers: newlyAssignedMembers }));
      }
    }

    if (formData.assigneesId !== prevData.assigneesId) {
      // Refresh watchers
      yield put(reloadApiQuery(getResourcesUrl(`/tests/${id}/watchers`)));
    }
  }
}

export function* saveTestAssignmentDiffSaga(action: SaveTestAssignmentDiffAction) {
  const { missionsId } = action.payload;

  const testAssignmentDiff: TestAssignmentDiff = yield select(
    ({ planning }: State) => planning.testAssignmentDiff
  );

  if ('teamMembers' in testAssignmentDiff) {
    testAssignmentDiff.teamMembers = clearMemberOutbound(testAssignmentDiff.teamMembers);
  }

  yield put(
    apiCall(
      TYPE_SAVE_TEST_ASSIGNMENT_DIFF,
      'PUT',
      `missions/${missionsId}/additional-assignees`,
      testAssignmentDiff
    )
  );

  const { success } = yield race({
    success: take(`${TYPE_SAVE_TEST_ASSIGNMENT_DIFF}_SUCCESS`),
    error: take(`${TYPE_SAVE_TEST_ASSIGNMENT_DIFF}_ERROR`),
  });

  if (success) {
    yield put(testAssignmentDiffResolved());

    toast.success(t('New test assignments were successfully reflected on mission'));
  }
}

/**
 * Used to cancel or mark a test as unsuccessful
 */
export function* abortTestSaga(action: AbortTestAction) {
  const { testId, data, successCallback } = action.payload;
  const { canSeeTestResults, ...apiData } = data;

  const cancelTest = !!apiData.cancelReason;

  yield put(
    apiCall(
      TYPE_ABORT_TEST,
      'POST',
      `/tests/${testId}/${cancelTest ? 'cancel' : 'unsuccess'}`,
      apiData
    )
  );

  const { success } = yield race({
    success: take(`${TYPE_ABORT_TEST}_SUCCESS`),
    error: take(`${TYPE_ABORT_TEST}_ERROR`),
  });

  if (success) {
    yield put(loadEntitySuccess(success.payload.response, entities.test.api().detail(testId)));
    if (canSeeTestResults) {
      yield put(push(entities.test.urls().results(testId)));
    }
    toast.success(
      cancelTest
        ? t('The test has been successfully canceled.')
        : t("The test's status has been changed to Unsuccessful.")
    );
    successCallback && successCallback();
  }
}
