import { toast } from 'react-toastify';
import { put, race, take } from 'redux-saga/effects';

import { apiCall, TYPE_GENERATE_PDF_MANUALLY, GeneratePdfManuallyAction } from 'core/actions';
import { t } from 'core/i18n';

export default function* generatePdfManually(action: GeneratePdfManuallyAction) {
  const { endpoint } = action.payload;

  yield put(apiCall(TYPE_GENERATE_PDF_MANUALLY, 'POST', `${endpoint}`, {}, {}));

  const { success } = yield race({
    success: take(`${TYPE_GENERATE_PDF_MANUALLY}_SUCCESS`),
  });

  if (success) {
    toast.success(t('The PDF document(s) is being regenerated'));
  }
}
