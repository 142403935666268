import { ColDef } from 'ag-grid-community';
import { useMemo } from 'react';
import { useTranslation } from 'react-i18next';

import { getRangeFilterValues } from 'core/hooks/useRangeDatagridFilter';
import { useFormatDateTime } from 'core/i18n/useFormatDateTime';
import AuditLog, { AuditLogDatagrid } from 'core/models/AuditLog';
import { getCustomUserAvatarUrl } from 'core/models/CoreProfile';

const useColumnDefs: () => ColDef[] = () => {
  const { t } = useTranslation();
  const formatDateTime = useFormatDateTime();

  return useMemo(
    () => [
      {
        headerName: t('Log ID'),
        sortable: true,
        sort: 'desc',
        field: 'id',
        minWidth: 60,
        width: 80,
      },
      {
        headerName: t('Type'),
        field: 'typesName',
        sortable: true,
        width: 150,
        minWidth: 100,
        customFilter: {
          filterValueKey: 'type',
          filterKey: 'type',
          single: true,
        },
      },
      {
        headerName: t('Triggered'),
        field: 'createdAt',
        sortable: true,
        width: 150,
        minWidth: 100,
        valueGetter: ({ data }) =>
          data?.createdAt
            ? formatDateTime(data?.createdAt, 'DATETIME_SHORT_WITH_SECONDS')
            : (t('N/A') as string),
        customFilter: {
          filterKey: 'createdAt',
          single: true,
          getFilterValue: (data: AuditLog) => {
            return getRangeFilterValues(data.createdAt, data.createdAt);
          },
        },
      },
      {
        headerName: t('User'),
        headerValueGetter: () => '',
        field: 'usersId',
        filter: true,
        width: 55,
        minWidth: 55,
        resizable: true,
        suppressSizeToFit: true,
        cellRenderer: 'avatarCellRenderer',
        cellRendererParams: {
          title: (auditLog: AuditLogDatagrid) => auditLog.usersName,
          containsImg: (auditLog: AuditLogDatagrid) => !!auditLog.usersStorageKey,
          url: (auditLog: AuditLogDatagrid) =>
            getCustomUserAvatarUrl(auditLog.usersId!, auditLog.usersStorageKey),
        },
        customFilter: {
          filterKey: 'user',
          getFilterValue: (auditLog: AuditLogDatagrid) => auditLog.usersId,
          getFilterByName: (auditLog: AuditLogDatagrid) => auditLog.usersName,
        },
      },
    ],
    [t, formatDateTime]
  );
};

export default useColumnDefs;
