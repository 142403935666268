import { FC, ReactNode, useMemo } from 'react';
import { useTranslation } from 'react-i18next';

import { SimpleOption } from 'core/components/DropDown';
import { FeedbackMessage } from 'core/components/FeedbackMessage';
import Field from 'core/components/Form/Field';
import useFormFieldValue from 'core/components/Form/useFormFieldValue';
import FormDropDown from 'core/components/FormDropDown';
import FormTextfield from 'core/components/FormTextfield';
import { Cell, Row } from 'core/components/Grid';
import { FullRowCell, HalfRowCell } from 'core/components/GridCell';
import ResourceFormDropdown from 'core/components/ResourceFormDropdown';
import { useIncludeOptions } from 'core/components/ResourceFormDropdown/IncludeResourcesProvider';
import useDropdownResource from 'core/hooks/useDropdownResource';
import useOptionsReducer from 'core/hooks/useOptionsReducer';
import Country from 'core/models/Country';

interface Props {
  fieldset?: string;
  fieldAsterisks?: Record<string, boolean>;
  viewMode?: boolean;
  children?: ReactNode;
}

const AddressFields: FC<Props> = ({ fieldset, fieldAsterisks, children, viewMode }) => {
  const { t } = useTranslation();

  const countriesId = useFormFieldValue(fieldset ? `${fieldset}.countriesId` : 'countriesId');
  const [countries] = useDropdownResource<Country>('countries', { autoload: false });
  const countryInclude = useIncludeOptions('countriesId') as Country[];
  const country = useMemo(() => {
    if (!countriesId) return;

    if (countries) {
      const option = countries.find(
        (c) => typeof c !== 'string' && c.id === countriesId
      ) as SimpleOption;
      return option?.extra as Country;
    }

    return countryInclude?.[0];
  }, [countriesId, countries, countryInclude]);

  const state = useFormFieldValue(fieldset ? `${fieldset}.state` : 'state') as string;
  const states = useMemo(() => country?.states?.map((s) => ({ ...s, id: s.name })), [country]);
  const isStateDropDown = useMemo(() => {
    if (!states) return false;
    if (state && !states.some((s) => s.name === state)) return false;

    return true;
  }, [states, state]);

  const zip = useFormFieldValue(fieldset ? `${fieldset}.zip` : 'zip') as string;
  const stateDefinition = useMemo(() => states?.find((s) => s.id === state), [states, state]);
  const isZipValid = useMemo(() => {
    if (!stateDefinition || !zip) return true;

    if (stateDefinition.minLength && zip.length < stateDefinition.minLength) return false;
    if (stateDefinition.maxLength && zip.length > stateDefinition.maxLength) return false;

    if (stateDefinition.minZip && zip < stateDefinition.minZip) return false;
    if (stateDefinition.maxZip && zip > stateDefinition.maxZip) return false;

    return true;
  }, [stateDefinition, zip]);

  const countriesOptions = useOptionsReducer();

  return (
    <Row>
      <FullRowCell>
        <Field
          name={fieldset ? `${fieldset}.countriesId` : 'countriesId'}
          component={ResourceFormDropdown}
          resource="countries"
          resourceReducer={(list: Country[]) =>
            countriesOptions(list, { secondary: 'code', name: 'name' })
          }
          label={t('Country')}
          viewMode={viewMode}
          requiredAsterisk={fieldAsterisks?.country}
          fast={false}
          single
        />
      </FullRowCell>

      <HalfRowCell>
        <Field
          name={fieldset ? `${fieldset}.street` : 'street'}
          component={FormTextfield}
          label={t('Street and Number')}
          viewMode={viewMode}
          requiredAsterisk={fieldAsterisks?.street}
          fast={false}
        />
      </HalfRowCell>
      <HalfRowCell>
        <Field
          name={fieldset ? `${fieldset}.address` : 'address'}
          component={FormTextfield}
          label={t('Additional Address Information')}
          viewMode={viewMode}
          requiredAsterisk={fieldAsterisks?.address}
          fast={false}
        />
      </HalfRowCell>

      <Cell desktopColumns={5} tabletColumns={3} phoneColumns={4}>
        <Field
          name={fieldset ? `${fieldset}.zip` : 'zip'}
          component={FormTextfield}
          label={t('ZIP Code')}
          viewMode={viewMode}
          requiredAsterisk={fieldAsterisks?.zip}
          fast={false}
        />
        {!isZipValid && (
          <FeedbackMessage $type="warning">
            {t('ZIP for {{state}} should be from range {{min}} - {{max}}', {
              state,
              min: stateDefinition?.minZip,
              max: stateDefinition?.maxZip,
            })}
          </FeedbackMessage>
        )}
      </Cell>

      <Cell desktopColumns={7} tabletColumns={5} phoneColumns={4}>
        <Field
          name={fieldset ? `${fieldset}.city` : 'city'}
          component={FormTextfield}
          label={t('City')}
          viewMode={viewMode}
          requiredAsterisk={fieldAsterisks?.city}
          fast={false}
        />
      </Cell>

      <HalfRowCell>
        <Field
          name={fieldset ? `${fieldset}.state` : 'state'}
          component={isStateDropDown ? FormDropDown : FormTextfield}
          label={t('Region / State')}
          options={states}
          single={isStateDropDown ? true : undefined}
          viewMode={viewMode}
          requiredAsterisk={fieldAsterisks?.state}
          fast={false}
        />
      </HalfRowCell>

      {children}
    </Row>
  );
};

export default AddressFields;
