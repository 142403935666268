import { RowClickedEvent } from 'ag-grid-community';
import React from 'react';
import { useTranslation } from 'react-i18next';

import { entities } from 'app/entity';
import { FullRowCell } from 'core/components/GridCell';
import { useDetail } from 'core/containers/FormDetailPage/DetailContext';
import { getResourceUrl } from 'core/functions/getResourcesUrl';
import history from 'core/history';
import usePermission from 'core/hooks/usePermission';
import { useResources } from 'core/hooks/useResource';
import Coc from 'planning/models/Coc';

import PaperTable from '../PaperTable';

import useEntityOverviewUrl from './useEntityOverviewUrl';

const CocsTable: React.FC = () => {
  const { t } = useTranslation();
  const { id: missionsId, mode } = useDetail();
  const isEdit = mode === 'edit';
  const canFindCocs = usePermission('cocs:find');

  const { data: cocs, isLoading: cocsLoading } = useResources<Coc>('missionCocs', {
    url: getResourceUrl(`missions/${missionsId}/cocs`, {
      order: 'createdAt',
      sort: 'desc',
    }),
    autoload: canFindCocs,
  });

  const entityOverviewUrl = useEntityOverviewUrl('coc', {
    mission: missionsId?.toString(),
  });

  const iconActions = [
    {
      icon: 'link',
      to: entityOverviewUrl,
      disabled: !cocs?.length,
      title: t('Link to Cocs'),
    },
  ];

  if (!canFindCocs) {
    return null;
  }

  return (
    <FullRowCell>
      <PaperTable
        data={cocs || []}
        type="cocs"
        onSelect={(event: RowClickedEvent) => {
          if (isEdit) {
            const coc: Coc = event.data;
            history.push(entities.coc.urls().detail(coc.id));
          }
        }}
        loading={cocsLoading}
        icons={iconActions}
      />
    </FullRowCell>
  );
};

export default CocsTable;
