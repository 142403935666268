import * as Sentry from '@sentry/browser';
import { LocaleObject } from 'yup/lib/locale';

import { t } from 'core/i18n';

import { formatDateTime } from './formatDateTime';

import { getCurrentLocale } from '.';

export default {
  mixed: {
    default: () => t('This field is invalid'),
    required: () => t('This field is required'),
    oneOf: (params) => {
      // Prop "values" is usually a comma separated list, but is typed as any so to be safe...
      if (typeof params.values !== 'string') {
        Sentry.withScope((scope) => {
          scope.setExtra('params', params);
          Sentry.captureMessage(
            'Hard to understand yup message! Add a custom error message.',
            Sentry.Severity.Warning
          );
        });
      }

      t('This field must be one of the following values: {{values}}', {
        values: params.values,
      });
    },
    notOneOf: (params) => {
      // Prop "values" is usually a comma separated list, but is typed as any so to be safe...
      if (typeof params.values !== 'string') {
        Sentry.withScope((scope) => {
          scope.setExtra('params', params);
          Sentry.captureMessage(
            'Hard to understand yup message! Add a custom error message.',
            Sentry.Severity.Warning
          );
        });
      }

      return t('This field must not be one of the following values: {{values}}', {
        values: params.values,
      });
    },
    notType: (params) => {
      Sentry.withScope((scope) => {
        scope.setExtra('params', params);
        Sentry.captureMessage(
          'Hard to understand yup message! Verify if it can be nullable or add a custom error message.',
          Sentry.Severity.Warning
        );
      });

      const { type, originalValue } = params;

      // Typeof null === 'object' so it would be confusing
      const actualType = originalValue === null ? null : typeof originalValue;
      return t('This field must be a {{expectedType}}, but was {{actualType}}.', {
        expectedType: type,
        actualType,
      });
    },
  },

  string: {
    length: ({ length }) => t('This field must be exactly {{length}} characters long', { length }),
    min: ({ min }) => t('This field must be at least {{min}} characters long', { min }),
    max: ({ max }) => t('This field must be at most {{max}} characters long', { max }),
    matches: (params) => {
      Sentry.withScope((scope) => {
        scope.setExtra('params', params);
        Sentry.captureMessage(
          'Hard to understand yup message! Add a custom error message for regex validation.',
          Sentry.Severity.Warning
        );
      });

      return t('This field must match the following pattern: {{regex}}', {
        regex: params.regex,
      });
    },
    email: () => t('This field must be a valid email'),
    url: () => t('This field must be a valid URL'),
    trim: () => t('This field must be without whitespace at the start or end'),
    lowercase: () => t('This field must be in a lower case'),
    uppercase: () => t('This field must be in a upper case'),
  },

  number: {
    min: ({ min }) => t('This field must be greater than or equal to {{min}}', { min }),
    max: ({ max }) => t('This field must be less than or equal to {{max}}', { max }),
    lessThan: ({ less }) => t('This field must be less than {{less}}', { less }),
    moreThan: ({ more }) => t('This field must be greater than {{more}}', { more }),
    notEqual: ({ notEqual }: { notEqual: number }) =>
      t('This field must be not equal to number {{notEqual}}', { notEqual }),
    positive: () => t('This field must be a positive number'),
    negative: () => t('This field must be a negative number'),
    integer: () => t('This field must be an integer'),
  },

  date: {
    min: ({ min }) =>
      t('This field must be later than {{min}}', {
        min: formatDateTime(
          typeof min === 'string' ? new Date(min) : min,
          'DATETIME_SHORT',
          getCurrentLocale()
        ),
      }),
    max: ({ max }) =>
      t('This field must be earlier than {{max}}', {
        max: formatDateTime(
          typeof max === 'string' ? new Date(max) : max,
          'DATETIME_SHORT',
          getCurrentLocale()
        ),
      }),
  },

  object: {
    noUnknown: () => t('This field cannot have additional values'),
  },

  array: {
    min: ({ min }) => t('This field must have at least {{min}} items', { min }),
    max: ({ max }) => t('This field must have at most {{max}} items', { max }),
  },
} as LocaleObject;
