import { put, take, race } from 'redux-saga/effects';

import { fetchDataGridDataAction, apiCall, PREFIX_FETCH_DATAGRID_ENDPOINT } from 'core/actions';
import { stringify } from 'core/functions/qs';

function* fetchDataGridData(action: fetchDataGridDataAction) {
  const params = action.payload.agGetRowsParams;
  const sortModel = params.sortModel[0];
  yield put(
    apiCall(
      `${PREFIX_FETCH_DATAGRID_ENDPOINT}_${action.payload.requestId}`,
      'GET',
      action.payload.endpoint,
      {
        offset: params.startRow,
        limit: params.endRow - params.startRow,
        order: sortModel ? sortModel.colId : undefined,
        sort: sortModel ? sortModel.sort : undefined,
        withCounts: true,
        ...action.payload.additionalParams,
      },
      {
        paramsSerializer: (params) =>
          stringify(params, {
            encodeValuesOnly: true,
          }),
      }
    )
  );

  const { success, error } = yield race({
    success: take(`${PREFIX_FETCH_DATAGRID_ENDPOINT}_${action.payload.requestId}_SUCCESS`),
    error: take(`${PREFIX_FETCH_DATAGRID_ENDPOINT}_${action.payload.requestId}_ERROR`),
  });

  if (error) {
    // We have to do this with callbacks because of the ag-grid infinite scrolling architecture
    params.failCallback();
    return;
  }

  const { data: items, filtered, total } = success.payload.response.data;

  // We have to do this with callbacks because of the ag-grid infinite scrolling architecture
  params.successCallback(items, filtered, total);
}

export default fetchDataGridData;
