import React, { ChangeEvent, ReactNode, useMemo } from 'react';
import { useTranslation } from 'react-i18next';

import { AlertMessage } from 'core/components/Alert';
import Checkbox from 'core/components/Checkbox';
import { FlexColumn, FlexRow } from 'core/components/FlexUtils';
import Radio from 'core/components/Radio';
import {
  STATUS_TYPE_NORMAL,
  STATUS_TYPE_WARNING,
  StatusMessage,
} from 'core/components/StatusMessage';
import Text from 'core/components/Text';
import { BLACKLISTS_TYPE } from 'personnel/containers/UsersDetailPage/tabs/BlackListsTab/BlacklistsModal';
import useTeamBlacklists from 'personnel/hooks/useTeamBlacklists';
import Athlete from 'personnel/models/Athlete';
import BlacklistSportDisciplines from 'personnel/models/BlacklistSportDisciplines';
import EntityBlacklistedMessage from 'planning/components/EntityBlacklistedMessage';

export const ContentFullItem = ({
  title,
  onClick,
  disabled,
  options,
  checkedAll,
}: {
  title: string;
  onClick?: any;
  checkedAll?: boolean;
  disabled?: boolean;
  options: {
    label: string;
    description?: string | number | null;
    name: string;
    currentValue?: string | number | null;
    checked?: boolean;
    onChange?: (event: ChangeEvent<HTMLInputElement>) => void;
    disabled?: boolean;
    showStatus?: boolean;
  }[];
}) => {
  const { t } = useTranslation();
  const checkedAllEnabled = onClick && checkedAll !== undefined && !disabled;

  return (
    <FlexRow marginTop="1.5rem" fullWidth>
      <FlexColumn verticalAlign="right">
        {checkedAllEnabled ? (
          <Checkbox
            onlyLabelAndInput={true}
            label={
              <Text $uppercase $fontWeight="700" $fontSize="1rem">
                {title}
              </Text>
            }
            name={title}
            checked={checkedAll}
            onChange={(event) => onClick && onClick(event)}
          />
        ) : (
          <Text $uppercase $fontWeight="700" $fontSize="1rem" $marginBottom=".75rem">
            {title}
          </Text>
        )}
        {options &&
          options.map((option, index) => {
            return (
              <FlexRow key={index}>
                <FlexColumn spacing="0rem" verticalAlign="baseline">
                  <Checkbox
                    label={option.label}
                    name={option.name}
                    description={option.description}
                    checked={option.checked}
                    onChange={(event) => option.onChange && option.onChange(event)}
                    disabled={option.disabled}
                  />
                  {option.showStatus ? (
                    <StatusMessage
                      label={t('Currently')}
                      value={option.currentValue ? option.currentValue : ''}
                      type={option.checked ? STATUS_TYPE_WARNING : STATUS_TYPE_NORMAL}
                    />
                  ) : null}
                </FlexColumn>
              </FlexRow>
            );
          })}
      </FlexColumn>
    </FlexRow>
  );
};

export const ContentHalfItems = ({
  title,
  options,
  athleteDetail,
  children,
}: {
  title: string;
  options: {
    label: string;
    description?: string | number | null;
    name: string;
    currentValue?: string | number | null;
    value?: string;
    checked?: boolean;
    onChange?: (event: ChangeEvent<HTMLInputElement>) => void;
    id?: number;
    disabled?: boolean;
  }[];
  athleteDetail: Athlete;
  children?: ReactNode;
}) => {
  const { t } = useTranslation();

  const arr = [];
  const alertMessage: AlertMessage = useMemo(
    () => ({
      value: t("You have blacklisted the Athlete's Sport Discipline."),
    }),
    [t]
  );

  const sportDisciplinesBlacklists: BlacklistSportDisciplines[] | undefined = useTeamBlacklists(
    BLACKLISTS_TYPE.SPORT_DISCIPLINES,
    athleteDetail.sportDisciplines?.map((sportrDiscipline) => sportrDiscipline.id),
    {
      id: 'sportDiscipline',
      message: alertMessage,
    }
  ) as BlacklistSportDisciplines[];

  for (let i = 0; i < options.length; i += 2) {
    arr.push(
      <React.Fragment key={i}>
        <FlexRow key={i} fullWidth spacing="0" marginTop=".4rem">
          <FlexColumn spacing="0rem" marginRight="0.5rem" width="0" verticalAlign="baseline">
            <Radio
              label={options[i].label}
              name={options[i].name}
              description={options[i].description}
              onChange={(event) => options[i].onChange && options[i]?.onChange?.(event)}
              disabled={true}
            />
          </FlexColumn>
          <FlexColumn spacing="0rem" width="0" verticalAlign="baseline">
            <Radio
              label={options[i + 1].label}
              description={options[i + 1].description}
              name={options[i + 1].name}
              onChange={(event) => options[i + 1].onChange && options[i + 1]?.onChange?.(event)}
              value={options[i + 1].value}
              disabled={options[i + 1].disabled}
              {...(options[i + 1].checked ? { checked: true } : { checked: false })}
            />
          </FlexColumn>
        </FlexRow>
        {sportDisciplinesBlacklists?.find(
          (item) => item.sportDisciplinesId === options[i + 1]?.id
        ) ? (
          <EntityBlacklistedMessage
            entityName={t('Sport Discipline')}
            blacklists={sportDisciplinesBlacklists}
          />
        ) : null}
      </React.Fragment>
    );
  }

  return (
    <FlexRow marginTop="1.5rem" fullWidth>
      <FlexColumn verticalAlign="right">
        <Text $uppercase $fontWeight="700" $fontSize="1rem" $marginBottom=".25rem">
          {title}
        </Text>
        {options && arr}
        {children}
      </FlexColumn>
    </FlexRow>
  );
};
