import {
  CSSProperties,
  FC,
  RefObject,
  useCallback,
  useEffect,
  useMemo,
  useRef,
  useState,
} from 'react';
import { useTranslation } from 'react-i18next';
import AutoSizer from 'react-virtualized-auto-sizer';
import { VariableSizeList } from 'react-window';

import AppIcon from 'app/assets/icons/AppIcon';
import IconLoader from 'app/components/IconLoader';
import useNotificationsReducer from 'app/hooks/useNotificationsReducer';
import Event from 'app/models/Event';

import RenderGroupTitle, { ListGroupTitleItem } from './RenderGroupTitle';
import RenderLoadingItem from './RenderLoadingItem';
import RenderNoData from './RenderNoData';
import RenderNotification, { Notification } from './RenderNotification';
import { CloseButton, CloseIcon, Content, Header, Logo, Title, Wrapper } from './styled';

interface Props {
  handleMarkAsRead?: (id?: number) => void;
  onRequestClose?: () => void;
  data?: Event[];
}

export type NotificationItem = 'NO_DATA' | 'LOADING' | Notification;

const Notifications: FC<Props> = ({ handleMarkAsRead, onRequestClose, data }) => {
  const notificationsReducer = useNotificationsReducer();
  const { t } = useTranslation();

  const virtualListItems = useMemo(() => {
    const [inProgress, other] = (data || []).reduce(notificationsReducer, [[], []]) as [
      Notification[],
      Notification[],
    ];
    let items: Array<ListGroupTitleItem | NotificationItem> = [];

    if (inProgress.length) {
      items.push({
        id: -1,
        onMarkAsRead: inProgress.concat(other).some((it) => !it.read)
          ? handleMarkAsRead
          : undefined,
        title: t('In Progress'),
      });
      items = items.concat(inProgress);
    }

    if (other.length) {
      items.push({
        id: -2,
        onMarkAsRead: inProgress.length > 0 ? undefined : handleMarkAsRead,
        title: t('Past'),
      });
      items = items.concat(other);
    }

    if (items.length === 0 && data) {
      items.push('NO_DATA' as const);
    }

    return items;
  }, [data, handleMarkAsRead, notificationsReducer, t]);

  const [expandedItemId, setExpandedItemId] = useState<number>();
  const [expandedItemHeight, setExpandedItemHeight] = useState<number>();
  const onExpand = useCallback((id: number) => {
    setExpandedItemId(id);
  }, []);
  useEffect(() => {
    const expandedElement =
      expandedItemId && document.getElementById(`notification-${expandedItemId}`);
    if (expandedItemId && expandedElement) {
      // Content height + padding
      setExpandedItemHeight(expandedElement.clientHeight + 16);
    } else {
      setExpandedItemHeight(undefined);
    }
  }, [expandedItemId]);
  const onClose = useCallback(() => {
    setExpandedItemId(undefined);
    setExpandedItemHeight(undefined);
  }, []);

  const RenderRow = ({ index, style }: { index: number; style: CSSProperties }) => {
    const it = virtualListItems[index] || 'LOADING';

    if (it === 'NO_DATA') {
      return <RenderNoData />;
    }
    if (it === 'LOADING') {
      return <RenderLoadingItem style={style} />;
    }
    if ('title' in it) {
      return <RenderGroupTitle style={style} item={it} />;
    }

    return (
      <RenderNotification
        item={it}
        style={style}
        expanded={expandedItemId === it.id}
        onMarkAsRead={handleMarkAsRead}
        onExpand={onExpand}
        onClose={onClose}
      />
    );
  };

  const calcItemSize = useCallback(
    (i: number) => {
      const it = virtualListItems[i];

      if (it === 'NO_DATA') {
        return 300;
      }

      if (typeof it === 'object' && 'title' in it) {
        return 48;
      }

      if (typeof it === 'object' && it.id === expandedItemId) {
        return expandedItemHeight || 81;
      }

      return 81;
    },
    [virtualListItems, expandedItemId, expandedItemHeight]
  );

  const listRef = useRef<VariableSizeList>() as RefObject<VariableSizeList>;

  useEffect(() => {
    const ref = listRef.current;
    virtualListItems && ref && ref.resetAfterIndex(0, true);
  }, [virtualListItems, listRef]);

  return (
    <Wrapper>
      <Header>
        <Logo>
          <AppIcon />
        </Logo>
        <Title>{t('Notifications')}</Title>
        <CloseButton onClick={onRequestClose}>
          <CloseIcon icon="close" />
        </CloseButton>
      </Header>
      <Content>
        {data === undefined && <IconLoader />}
        <AutoSizer>
          {({ height, width }: any) => (
            <VariableSizeList
              itemCount={virtualListItems.length}
              itemData={virtualListItems}
              itemSize={calcItemSize}
              itemKey={(i, data) => (data[i] === 'NO_DATA' ? 0 : data[i]?.id)}
              height={height || 0}
              width={width || 0}
              ref={listRef}
            >
              {RenderRow}
            </VariableSizeList>
          )}
        </AutoSizer>
      </Content>
    </Wrapper>
  );
};

export default Notifications;
