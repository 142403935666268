import styled from 'styled-components/macro';

import theme from 'app/theme';

const StyledIcon = styled.div`
  .mdc-list--dense &.mdc-list-item__graphic {
    width: unset;
    height: auto;
    color: ${theme.color.baseFontColor};
  }
`;

export default StyledIcon;
