import { FC, memo, useMemo } from 'react';

import { Option } from 'core/components/DropDown';
import Field from 'core/components/Form/Field';
import useFormFieldValue from 'core/components/Form/useFormFieldValue';
import Analysis from 'lists/models/Analysis';

import AddOtherButton from './AddOtherButton';
import OtherRows from './OtherRows';

export interface OtherAnalyseInput {
  remarks: string | null;
  id: null | number;
}

interface Props {
  analysesFieldName: string;
  analyses: Analysis[];
  enableDBS?: boolean;
  viewMode?: boolean;
  name: string;
}

const OtherBloodAnalyses: FC<Props> = ({
  /** selected blood analyses field name */
  analysesFieldName,
  /** enable list Dried-BloodSpots in dropdown options */
  enableDBS,
  /** list of available OTHER BLOOD analyses */
  analyses,
  /** other blood analyses field name */
  name,
}) => {
  const value: OtherAnalyseInput[] = useFormFieldValue(name);

  const otherOptions = useMemo(
    () =>
      analyses
        .filter(
          (a) =>
            // Include DBS only if it's enabled or chosen
            (value || []).find((i) => a.id === i.id) || !a.driedBloodSpots || enableDBS
        )
        .map(
          (a: Analysis) =>
            ({
              name: a.shortName.replace('Additional for ', ''),
              id: a.id,
            }) as Option
        ),
    [analyses, value, enableDBS]
  );

  if (!value || value.length <= 0) {
    return null;
  }

  return (
    <>
      <OtherRows name={name} selectedValues={value} options={otherOptions} />

      <Field
        dummyPlaceholder={() => null}
        component={AddOtherButton}
        name={analysesFieldName}
        options={otherOptions}
        diffMode={false}
        listName={name}
        fast={false}
      />
    </>
  );
};

export default memo(OtherBloodAnalyses);
