import { PropsWithChildren } from 'react';

import { Badge } from './styled';

export interface BadgeCellParams {
  color?: string;
  title?: string;
}

const BadgeCellRenderer = ({ title, color }: PropsWithChildren<BadgeCellParams>) => {
  return title ? <Badge $color={color}>{title}</Badge> : null;
};

export default BadgeCellRenderer;
