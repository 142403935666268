import { toast } from 'react-toastify';
import { put, take, race, select } from 'redux-saga/effects';

import { entities } from 'app/entity';
import { State } from 'app/store/prepareStore';
import { apiCall } from 'core/actions';
import { t } from 'core/i18n';
import {
  LoadSampleAction,
  TYPE_LOAD_SAMPLE,
  EditSampleAction,
  TYPE_EDIT_SAMPLE,
} from 'planning/actions';
import Sample from 'planning/models/Sample';

export function* loadSampleSaga(action: LoadSampleAction) {
  yield put(
    apiCall(TYPE_LOAD_SAMPLE, 'GET', `${entities.sample.urls().list}/${action.payload.id}`, {})
  );
}

export function* editSampleSaga(action: EditSampleAction) {
  const fd = action.payload.formData;
  const prevSample: Sample | undefined = yield select(
    (state: State) => state.planning.sampleDetail
  );

  yield put(
    apiCall(
      TYPE_EDIT_SAMPLE,
      'PATCH',
      `${entities.sample.urls().list}/${fd.id}`,
      {
        receivedByLab: fd.receivedByLab,
        resultReported: fd.resultReported,
      },

      {},
      true,
      {
        receivedByLab: prevSample?.receivedByLab,
        resultReported: prevSample?.resultReported,
      },
      ['language']
    )
  );

  const { success } = yield race({
    success: take(`${TYPE_EDIT_SAMPLE}_SUCCESS`),
    error: take(`${TYPE_EDIT_SAMPLE}_ERROR`),
  });

  if (success) {
    toast.success(t('Sample successfully edited'));

    yield put({
      type: `${TYPE_LOAD_SAMPLE}_SUCCESS`,
      payload: success.payload,
    });
  }
}
