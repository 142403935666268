import { useCallback } from 'react';

import { Permission } from 'core/models/CoreProfile';

import useAppSelector from './useAppSelector';

const usePermission = (query: string, underOriginUser: boolean = false) => {
  const get = useGetPermission();

  return get(query, underOriginUser);
};

export const queryPermissionAllowed = (
  permissions: { [key: string]: Permission },
  query: string
) => {
  // new format is controller:action[arrayParameter:value]
  // so:
  // - split on [
  // - remove all ]
  // - take only first 2 findings
  const [permission, params] = (query + '[]').split('[').map((i) => i.trim().replace(']', ''));

  if (!permission) {
    return true;
  }

  const val = permissions[permission];

  // do we have extra [xxx:xxx] param ?
  if (val && params) {
    if (!val.params) return false;

    // split to key:value
    const [key, value] = params.split(':');
    const arr = (val.params && val.params[key as keyof typeof val.params]) || [];

    // search for val
    return (
      !!arr &&
      // @ts-ignore
      // eslint-disable-next-line eqeqeq
      (Array.isArray(arr) ? arr!.some((it) => it == value) || arr.includes('all') : arr === value)
    );
  }

  return !!val;
};

export const useGetPermission = (): ((query: string, underOriginUser?: boolean) => boolean) => {
  const permissions = useAppSelector(({ core: { user } }) => (user && user.permissions) || {});
  const originUserPermissions = useAppSelector(
    ({ core: { user } }) => (user && user.originUser?.permissions) || {}
  );

  return useCallback(
    (query: string, underOriginUser: boolean = false): boolean =>
      queryPermissionAllowed(permissions, query) ||
      (underOriginUser && queryPermissionAllowed(originUserPermissions, query)),
    [permissions, originUserPermissions]
  );
};

export default usePermission;
