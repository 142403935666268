import { useFormikContext } from 'formik';
import { Location } from 'history';
import { FC, useCallback, useContext, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useHistory } from 'react-router';

import Button from 'core/components/Button';
import Modal from 'core/components/Modal';
import { blockRedirect, unblockRedirect } from 'core/history';

import { FormContext } from '../Form';

type Props = {
  continueText?: string;
  cancelText?: string;
  message?: string;
  shouldBlockNextLocation: (location: Location) => boolean;
};

const FormLeavePrompt: FC<Props> = ({
  continueText,
  cancelText,
  message,
  shouldBlockNextLocation,
}) => {
  const [blockedUrl, setBlockedUrl] = useState<string | null>(null);
  const { customOperations } = useContext(FormContext);
  const formik = useFormikContext();
  const { t } = useTranslation();
  const history = useHistory();

  const { dirty, isValidating } = formik;

  // force continue
  const continueAction: () => void = useCallback(() => {
    unblockRedirect();

    blockedUrl && history.push(blockedUrl);

    setBlockedUrl(null);
  }, [blockedUrl, history]);

  // stop redirect
  useEffect(() => {
    unblockRedirect();

    blockRedirect((location: Location): string | false | void => {
      if ((dirty || customOperations.length > 0) && shouldBlockNextLocation(location)) {
        setBlockedUrl(location.pathname + (location.search || ''));
        return false;
      }
    });

    // cleanup
    return () => {
      unblockRedirect();
    };
  }, [history, dirty, shouldBlockNextLocation, customOperations]);

  return (
    <Modal
      ariaLabel={t('Unsaved Changes Confirmation Dialog')}
      title={t('Confirm Leaving Form')}
      confirmButton={
        <Button
          text={continueText || t('Discard Changes')}
          onClick={continueAction}
          type="button"
        />
      }
      cancelButton={
        <Button
          onClick={() => setBlockedUrl(null)}
          text={cancelText || t(`Don't leave`)}
          type="button"
        />
      }
      onClose={() => setBlockedUrl(null)}
      open={!isValidating && !!blockedUrl}
    >
      {message ||
        t('You have unsaved changes on current form. Are you sure you want to leave this page?')}
    </Modal>
  );
};

export default FormLeavePrompt;
