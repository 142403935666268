import { FC } from 'react';
import { useTranslation } from 'react-i18next';

import LoginForm from 'core/components/LoginForm';
import { StyledSubtitle, StyledHeader } from 'core/components/SecondaryPage/styled';
import useAppSelector from 'core/hooks/useAppSelector';

import { ModalWithStrongOverlay, StyledLoginFieldsetWrapper } from './styled';

const RenewLoginDialog: FC = () => {
  const { t } = useTranslation();
  const sessionRenewInProgress = useAppSelector((state) => state.core.sessionRenewInProgress);

  return (
    <ModalWithStrongOverlay
      ariaLabel={t('Renew login form')}
      open={sessionRenewInProgress}
      onClose={() => null}
      cancelButton={null}
      confirmButton={null}
    >
      <StyledLoginFieldsetWrapper>
        <StyledHeader>{t('Renew your login')}</StyledHeader>

        <StyledSubtitle>
          {t('Your previous login expired, please fill your password to renew your login')}
        </StyledSubtitle>

        <LoginForm loginRenewal />
      </StyledLoginFieldsetWrapper>
    </ModalWithStrongOverlay>
  );
};

export default RenewLoginDialog;
