import { useCallback } from 'react';

import { SettingsFormData } from './useSettingsSchema';
import { switchSettings } from './useSwitchSettings';

const useSettingsOutputMapping = () => {
  return useCallback((fd: SettingsFormData) => {
    return Object.fromEntries([
      ...switchSettings.control.map((key) => [key, fd[key as keyof SettingsFormData]]),
      ...switchSettings.dcf.map((key) => [key, fd[key as keyof SettingsFormData]]),
      ['fileTemplatesId', fd.fileTemplatesId === 'default' ? null : Number(fd.fileTemplatesId)],
      ['adamsAthleteLevelsId', fd.useAdamsAthleteLevel ? fd.adamsAthleteLevelsId : null],
    ]);
  }, []);
};

export default useSettingsOutputMapping;
