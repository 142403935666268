import { useTranslation } from 'react-i18next';

import ContextMenu from 'core/components/ContextMenu';
import IconButton from 'core/components/IconButton';

import { DataCardAction, DataCardItem } from '../props';

interface Props<Item extends DataCardItem> {
  action: ((selection: Item) => void) | DataCardAction<Item>[];
  item: Item;
}

/**
 * Action at the end of the row
 *
 * Either quick actions of available or remove button if not
 */
const RowEndAction = <Item extends DataCardItem>({ action, item }: Props<Item>) => {
  const { t } = useTranslation();
  if (typeof action === 'function') {
    return <IconButton icon="close" onClick={() => action(item)} tooltip={t('Remove')} />;
  }

  return (
    <ContextMenu
      menuId={`card-item-${item.id}`}
      menuItems={action.map((action) => {
        const disabled = Boolean(
          typeof action.disabled === 'function' ? action.disabled(item) : action.disabled
        );

        return {
          key: action.id,
          text: action.title,
          disabled,
          onClick: () => action.onClick(item),
        };
      })}
      placement="bottom-end"
    />
  );
};

export default RowEndAction;
