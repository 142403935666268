import styled, { keyframes } from 'styled-components/macro';

import theme from 'app/theme';

export const HamburgerWrap = styled.div`
  position: relative;
`;

export const Backdrop = styled.div`
  position: fixed;
  height: 100%;
  left: 0;
  top: 0;
  z-index: 99;
  width: 100%;
  background: rgba(0, 0, 0, 0.2);
`;

const appear = keyframes`
  0% {
    transform: translateX(-100%);
  }
  5% {
    transform: translateX(-95%);
  }
  100% {
    transform: translateX(.0 rem);
  }
`;

export const HamburgerMenu = styled.div`
  position: fixed;
  top: 0;
  z-index: 100;
  background: ${theme.color.gray100};
  animation: ${appear} 0.5s;
  left: 0;
`;
