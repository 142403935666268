import styled from 'styled-components/macro';

import theme from 'app/theme';

export const Wrapper = styled.div.attrs<{ collapse: boolean }>(
  ({ collapse: _c, ...props }) => props
)<{
  collapse: boolean;
}>`
  flex-shrink: 0;
  height: 3.5rem;
  width: 100%;
  min-width: ${theme.drawerWidth};
  margin: 0;
  padding-left: 0.625rem;
  padding-right: ${({ collapse }) => (collapse ? '0.625rem' : '0.65rem')};
  display: flex;
  align-items: center;
  justify-content: space-between;
  box-sizing: border-box;
  border-bottom: 1px solid ${theme.color.secondary800};

  a {
    display: flex;
    text-decoration: none !important;
    color: ${theme.color.white};
    align-items: center;
    flex: 1;
    text-overflow: ellipsis;
    overflow: hidden;
  }
`;
