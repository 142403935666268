import az from './az.json';
import da from './da.json';
import de from './de.json';
import en from './en.json';
import es from './es.json';
import fr from './fr.json';
import hu from './hu.json';
import ja from './ja.json';
import ko from './ko.json';
import lt from './lt.json';
import nl from './nl.json';
import no from './no.json';
import pt from './pt.json';
import zh from './zh.json';

export const DEFAULT_LANGUAGE = 'en';
export const DEFAULT_LOCALE = 'en-GB';

// TODO: think about dynamic import
export const resources = {
  az,
  da,
  de,
  en,
  es,
  fr,
  hu,
  ja,
  ko,
  lt,
  nl,
  no,
  pt,
  zh,
} as const;

export type SupportedLocale = keyof typeof resources;
