import { FC } from 'react';
import { useTranslation } from 'react-i18next';

import Field from 'core/components/Form/Field';
import FormTextfield from 'core/components/FormTextfield';
import { Row } from 'core/components/Grid';
import { FullRowCell } from 'core/components/GridCell';
import PaperContainer from 'core/components/PaperContainer';

interface Props {
  canSeeSensitiveComments?: boolean;
}

const Comments: FC<Props> = ({ canSeeSensitiveComments = false }) => {
  const { t } = useTranslation();

  return (
    <PaperContainer title={t('Comments')}>
      <Row>
        <FullRowCell>
          <Field
            label={t('General Comments')}
            component={FormTextfield}
            name="generalComments"
            textarea
          />
        </FullRowCell>

        {canSeeSensitiveComments && (
          <FullRowCell>
            <Field
              label={t('Internal Comments')}
              component={FormTextfield}
              name="internalComments"
              textarea
            />
          </FullRowCell>
        )}

        <FullRowCell>
          <Field
            label={t('Draw Comments')}
            component={FormTextfield}
            name="drawComments"
            fast={false}
            textarea
          />
        </FullRowCell>

        {canSeeSensitiveComments && (
          <FullRowCell>
            <Field
              label={t('Invoice Comments')}
              component={FormTextfield}
              name="invoiceComments"
              fast={false}
              textarea
            />
          </FullRowCell>
        )}

        <FullRowCell>
          <Field
            label={t('Accommodation Comments')}
            component={FormTextfield}
            name="accommodationComments"
            fast={false}
            textarea
          />
        </FullRowCell>
      </Row>
    </PaperContainer>
  );
};

export default Comments;
