import { FC } from 'react';

import DummyField from 'core/components/DummyField';
import { Props as CalendarInputProps } from 'core/components/FormCalendarInput';
import { useFormatDateTime, useFormatDateTimeRange } from 'core/i18n/useFormatDateTime';

type Props = Partial<CalendarInputProps>;

const DummyTimeField: FC<Props> = ({ label, format = 'TIME_SHORT', field, value }) => {
  const formatDateTime = useFormatDateTime();
  const formatDateTimeRange = useFormatDateTimeRange();

  let val = value ?? field?.value;

  if (Array.isArray(val)) {
    val = formatDateTimeRange(val[0], val[1], format);
  } else if (val) {
    val = formatDateTime(val, format);
  } else {
    val = '';
  }

  return <DummyField value={val} label={label || ''} />;
};

export default DummyTimeField;
