import { getCSRFToken } from 'core/effects/apiCall';
import { mergeUrl } from 'core/functions/qs';

const apiRedirect = (
  method: 'POST' | 'PUT' | 'DELETE',
  url: string,
  params: Record<string, unknown>
) => {
  const form = document.createElement('form');
  form.action = mergeUrl(url, {
    _csrf: getCSRFToken(),
  });
  form.method = method;

  Object.entries(params)
    .filter(([, value]) => !!value)
    .map(([key, value]) => {
      const input = document.createElement('input');
      input.name = key;
      input.type = 'hidden';
      input.value = value as string;

      return input;
    })
    .forEach((param) => form.appendChild(param));

  document.body.appendChild(form);

  form.submit();
};

export default apiRedirect;
