import { FC } from 'react';

import { PopoverContent } from 'core/containers/DataGrid/CellRenderers/PopoverCellRenderer/styled';
import { TeamMemberType } from 'planning/components/TeamTab/enums';
import { TeamMemberData } from 'planning/components/TeamTab/useTeamInputMapping';

import AssignmentNotificationsContent from './AssignmentNotificationsContent';
import InvitationNotificationsContent from './InvitationNotificationsContent';

interface Props {
  type: TeamMemberType;
  user: TeamMemberData & { id: number };
}

const NotificationsPopover: FC<Props> = ({ user, type }) => {
  return (
    <PopoverContent>
      {type === TeamMemberType.INVITED ? (
        <InvitationNotificationsContent user={user} />
      ) : (
        <AssignmentNotificationsContent user={user} />
      )}
    </PopoverContent>
  );
};

export default NotificationsPopover;
