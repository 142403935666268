import { push } from 'connected-react-router';
import { FC, useMemo, useCallback } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch } from 'react-redux';

import DropDown, { Option } from 'core/components/DropDown';
import DropDownBadge from 'core/components/DropDownBadge';
import MaterialIcon from 'core/components/MaterialIcon';
import Modal from 'core/components/Modal';
import { useResources } from 'core/hooks/useResource';
import Cost from 'finance/models/Cost';

interface Props {
  open: boolean;
  setOpen: (open: boolean) => void;
  onPickedCost: (id: number | null) => void;
  createNewCostLink?: string;
  costsId?: number | null;
  showMissionLink?: boolean;
}

const NEW_COSTS_ID = 'NEW_COSTS_ID';

const LinkCostsModal: FC<Props> = ({
  open,
  setOpen,
  createNewCostLink,
  onPickedCost,
  costsId,
  showMissionLink = false,
}) => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const { data: costs } = useResources<Cost>('costs', { autoload: !!open });
  const costOptions = useMemo<Option[]>(() => {
    if (!costs) return [];

    const costsOptions = costs.map((cost) =>
      showMissionLink
        ? {
            id: cost.id,
            name: t('Costs #{{id}}', { id: cost.id }),
            graphic: cost.missionsId ? (
              <DropDownBadge variant="warning">
                <MaterialIcon icon="link" />
              </DropDownBadge>
            ) : (
              <DropDownBadge variant="success">
                <MaterialIcon icon="link_off" />
              </DropDownBadge>
            ),
            secondary: cost.missionsId
              ? t('Linked to Mission #{{id}}', { id: cost.missionsId })
              : t('Not Linked'),
          }
        : {
            id: cost.id,
            name: t('Costs #{{id}}', { id: cost.id }),
          }
    );

    return createNewCostLink
      ? [
          showMissionLink
            ? {
                id: NEW_COSTS_ID,
                name: t('Create new Costs'),
                secondary: t('Opens create Costs screen with links preselected'),
              }
            : {
                id: NEW_COSTS_ID,
                name: t('Create new Costs'),
              },
          'SEPARATOR',
          ...costsOptions,
        ]
      : costsOptions;
  }, [costs, t, createNewCostLink, showMissionLink]);

  const onCostsChange = useCallback(
    (value: unknown) => {
      const costsId = value as number | null | typeof NEW_COSTS_ID;
      if (costsId === NEW_COSTS_ID) {
        createNewCostLink && dispatch(push(createNewCostLink));
      } else {
        onPickedCost(costsId);
      }
    },
    [dispatch, onPickedCost, createNewCostLink]
  );

  return (
    <Modal
      ariaLabel={t('Linked Costs editing dialog')}
      open={open}
      onClear={() => {
        onPickedCost(null);
        setOpen(false);
      }}
      onClose={() => setOpen(false)}
    >
      <DropDown
        options={costOptions}
        value={costsId || null}
        onChange={onCostsChange}
        loading={!costs}
        single
        mode="inline"
      />
    </Modal>
  );
};

export default LinkCostsModal;
