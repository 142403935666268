import { useMemo } from 'react';

import { BLACKLISTS_TYPE } from './BlacklistsModal';

export const useBlacklistsTypeEndpoint = (type: BLACKLISTS_TYPE) => {
  return useMemo(() => {
    switch (type) {
      case BLACKLISTS_TYPE.ATHLETES:
        return 'blacklist/athletes';
      case BLACKLISTS_TYPE.SPORT_DISCIPLINES:
        return 'blacklist/sport-disciplines';
      default:
        throw Error('Not supported blacklists type');
    }
  }, [type]);
};
