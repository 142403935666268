import { useTranslation } from 'react-i18next';

import Checkbox from '../BulkActions/Checkbox';
import { BulkActionsLogic } from '../BulkActions/useBulkActions';
import DataCardProps, { DataCardAction, DataCardItem } from '../props';
import RowEndAction from '../RowEndAction';
import useRowEndAction from '../RowEndAction/useRowEndAction';

import {
  NoItemsPlaceholder,
  StickyActionCell,
  StickyHeaderActionCell,
  TableRow,
  TableWrapper,
} from './styled';

interface Props<Item extends DataCardItem> extends DataCardProps<Item> {
  bulkActions?: DataCardAction<Item>[];
  bulkLogic: BulkActionsLogic<Item>;
  selection: Set<number | string>;
  offset?: number;
}

const TableContent = <Item extends DataCardItem>({
  header = null,
  items,
  children,
  actions = [],
  onRemove,
  isActionable,
  bulkActions,
  bulkLogic,
  selection,
  offset = 0,
}: Props<Item>) => {
  const { t } = useTranslation();
  const rowEndAction = useRowEndAction(actions, onRemove);

  return (
    <TableWrapper>
      {items.length > 0 && (
        <table>
          {header && (
            <thead>
              <TableRow>
                {bulkActions && (
                  <StickyHeaderActionCell>
                    <Checkbox logic={bulkLogic} />
                  </StickyHeaderActionCell>
                )}

                {header}

                {rowEndAction && <StickyHeaderActionCell />}
              </TableRow>
            </thead>
          )}

          <tbody>
            {items.map((item, index) => (
              <TableRow key={item.id}>
                {bulkActions && (!isActionable || isActionable(item)) && (
                  <StickyActionCell>
                    <Checkbox id={item.id.toString()} logic={bulkLogic} />
                  </StickyActionCell>
                )}

                {children(item, index + offset, { selected: selection.has(item.id) })}

                {rowEndAction && (!isActionable || isActionable(item)) && (
                  <StickyActionCell>
                    <RowEndAction action={rowEndAction} item={item} />
                  </StickyActionCell>
                )}
              </TableRow>
            ))}
          </tbody>
        </table>
      )}

      {items.length === 0 && <NoItemsPlaceholder>{t('There are no items.')}</NoItemsPlaceholder>}
    </TableWrapper>
  );
};

export default TableContent;
