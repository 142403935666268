import { LocationChangeAction } from 'connected-react-router';
import { put, select } from 'redux-saga/effects';

import { entities, NoContextEntityKey } from 'app/entity';
import { AppState } from 'app/store';
import { publicPathNames } from 'app/urls';

import { dataGridSaveEntityParams, savePrevPathname } from '../actions';

function* locationChange(action: LocationChangeAction) {
  const {
    prevPathname,
    updateAvailable,
    dataGridEntityParams,
  }: {
    prevPathname: AppState['core']['prevPathname'];
    updateAvailable: AppState['core']['updateAvailable'];
    dataGridEntityParams: AppState['core']['dataGridEntityParams'];
  } = yield select((state: AppState) => ({
    prevPathname: state.core.prevPathname,
    updateAvailable: state.core.updateAvailable,
    dataGridEntityParams: state.core.dataGridEntityParams,
  }));
  const pathname = action.payload.location.pathname;

  if (pathname === '/' || !publicPathNames.find((p) => pathname.startsWith(p))) {
    yield put(savePrevPathname(pathname));
  }

  if (dataGridEntityParams) {
    if (!pathname.includes(dataGridEntityParams.endpoint)) {
      // If new pathname is different from saved filter, reset them if they exists
      yield put(dataGridSaveEntityParams(undefined));
    }

    const isDatagridPage = (Object.keys(entities) as NoContextEntityKey[]).find((urlKey) => {
      const urlGroup = entities[urlKey].urls();
      if (urlGroup && urlGroup.hasOwnProperty('list')) {
        return urlGroup.list === pathname;
      }
      return false;
    });
    if (isDatagridPage) {
      const pathOrParamsNotTheSame =
        dataGridEntityParams.endpoint !== pathname ||
        dataGridEntityParams.params !== action.payload.location.search;

      if (pathOrParamsNotTheSame) {
        // If it's different datagrid page or params not the same
        yield put(
          dataGridSaveEntityParams({
            endpoint: pathname,
            params: action.payload.location.search,
          })
        );
      }
    }
  }

  if (updateAvailable && prevPathname && prevPathname.split('/')[1] !== pathname.split('/')[1]) {
    // If this is transition between modules, reload if we have pending updates
    window.location.reload();
  }
}

export default locationChange;
