import { put, take, race } from 'redux-saga/effects';

import {
  apiCall,
  DatagridBulkPatchAction,
  PREFIX_DATAGRID_BULK_PATCH_ENDPOINT,
} from 'core/actions';
import { stringify } from 'core/functions/qs';
import showUpdatedRecordsMessage from 'core/functions/showUpdatedRecordsMessage';

function* datagridBulkPatch(action: DatagridBulkPatchAction) {
  yield put(
    apiCall(
      PREFIX_DATAGRID_BULK_PATCH_ENDPOINT,
      'PATCH',
      `${action.payload.endpoint}/bulk`,
      action.payload.data,
      {
        params: Array.isArray(action.payload.selection)
          ? {
              id: action.payload.selection,
            }
          : action.payload.selection,
        paramsSerializer: (params) =>
          stringify(params, {
            encodeValuesOnly: true,
          }),
      },
      true,
      // So that diff calculates replace for all
      // Negation seems like a good idea since
      // !'' === true, !'smt' === false, !true === false, !1 === false etc.
      Object.keys(action.payload.data).reduce(
        (data, key) => {
          data[key] = !action.payload.data[key];
          return data;
        },
        {} as { [key: string]: boolean }
      )
    )
  );

  const { error, success } = yield race({
    success: take(`${PREFIX_DATAGRID_BULK_PATCH_ENDPOINT}_SUCCESS`),
    error: take(`${PREFIX_DATAGRID_BULK_PATCH_ENDPOINT}_ERROR`),
  });

  if (error) {
    return;
  }

  if (success) {
    showUpdatedRecordsMessage(success.payload.response);
  }

  // We have to do this because of the ag-grid infinite scrolling architecture
  action.payload.successCallback();
}

export default datagridBulkPatch;
