import { RECIPIENTS } from '.';

export const getRecipientRequiredParamName = (recipient: RECIPIENTS) => {
  switch (recipient) {
    case RECIPIENTS.LABORATORY:
      return 'laboratoriesId';
    case RECIPIENTS.AUTHORITY:
      return 'authoritiesId';
    case RECIPIENTS.DCO:
      return 'usersId';
    case RECIPIENTS.ATHLETE:
      return 'athletesId';
    default:
      return undefined;
  }
};
