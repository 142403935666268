import { useFormikContext } from 'formik';
import { useCallback, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';

import { DataCardAction } from 'core/components/DataCard/props';
import { TeamMemberStatus } from 'planning/models/TeamMember';

import { TeamMemberType } from '../../enums';
import { TeamFormData, TeamMemberData } from '../../useTeamInputMapping';
import { getCommonRoles } from '../getCommonRoles';

import AddModal from './AddModal';
import CommentsModal from './CommentsModal';
import InvitationsModal from './InvitationsModal';
import RolesModal from './RolesModal';

const useActions = (type: TeamMemberType, notRemovableUsersId?: number[]) => {
  const { t } = useTranslation();
  const formik = useFormikContext<TeamFormData>();
  const [adding, setAdding] = useState(false);
  const [editingComments, setEditingComments] = useState<TeamMemberData>();
  const [changingRole, setChangingRole] = useState<TeamMemberData[]>();
  const [togglingInvitation, setTogglingInvitation] = useState<TeamMemberData[]>();

  const dataKey = type === TeamMemberType.INVITED ? 'invitedMembers' : 'assignedMembers';
  const oppositeDataKey = type === TeamMemberType.INVITED ? 'assignedMembers' : 'invitedMembers';
  const data = formik.values[dataKey];

  const toggleAssignment = useCallback(
    (selection: TeamMemberData | TeamMemberData[]) => {
      const current = formik.values[dataKey];
      const removed = new Set(
        Array.isArray(selection) ? selection.map((member) => member.usersId) : [selection.usersId]
      );

      formik.setValues({
        ...formik.values,
        [dataKey]: current.filter((member) => !removed.has(member.usersId)),
        [oppositeDataKey]: [
          ...formik.values[oppositeDataKey],
          ...(Array.isArray(selection) ? selection : [selection]).map((member) => ({
            ...member,
            status:
              type === TeamMemberType.ASSIGNED
                ? TeamMemberStatus.SELECTED
                : TeamMemberStatus.CONFIRMED,
          })),
        ],
      });
    },
    [dataKey, formik, oppositeDataKey, type]
  );

  const onRemove = useCallback(
    (selection: TeamMemberData | TeamMemberData[]) => {
      const current = formik.values[dataKey];
      const deleted = new Set(
        Array.isArray(selection) ? selection.map((member) => member.usersId) : [selection.usersId]
      );

      formik.setValues({
        ...formik.values,
        [dataKey]: current.filter((member) => !deleted.has(member.usersId)),
      });
    },
    [formik, dataKey]
  );

  const actions = useMemo(() => {
    return [
      {
        id: 'assignment',
        title: type === TeamMemberType.INVITED ? t('Assign') : t('Unassign'),
        onClick: toggleAssignment,
        bulkIcon: 'person_add',
      },
      ...(type === TeamMemberType.INVITED
        ? [
            {
              id: 'invitation',
              title: t('Plan Invitation'),
              onClick: (selection) =>
                setTogglingInvitation(Array.isArray(selection) ? selection : [selection]),
              bulkIcon: 'send',
            } as DataCardAction<TeamMemberData & { id: number }>,
          ]
        : []),
      {
        id: 'comment',
        title: t('Edit Internal Comments'),
        onClick: (selection) => setEditingComments(selection as TeamMemberData),
      },
      {
        id: 'role',
        title: t('Change Role'),
        onClick: (selection) => setChangingRole(Array.isArray(selection) ? selection : [selection]),
        bulkIcon: 'how_to_reg',
        disabled: (selection: TeamMemberData | TeamMemberData[]) => {
          const selectionArray = Array.isArray(selection) ? selection : [selection];
          return !getCommonRoles(selectionArray)?.length
            ? t('Selected items have not the common role')
            : false;
        },
      },
    ] as DataCardAction<TeamMemberData & { id: number }>[];
  }, [t, toggleAssignment, type]);

  const triggers = useMemo(() => {
    return {
      onRemove,
      onAdd: () => setAdding(true),
      onEditComments: (member: TeamMemberData) => setEditingComments(member),
      onToggleInvitations: (member: TeamMemberData) => setTogglingInvitation([member]),
    };
  }, [onRemove]);

  const modals = (
    <>
      <AddModal
        notRemovableUsersId={notRemovableUsersId}
        type={type}
        open={adding}
        onClose={() => setAdding(false)}
      />
      <CommentsModal
        type={type}
        member={editingComments}
        index={data.findIndex((m) => m.usersId === editingComments?.usersId)}
        onClose={() => setEditingComments(undefined)}
      />
      <RolesModal type={type} members={changingRole} onClose={() => setChangingRole(undefined)} />
      <InvitationsModal
        type={type}
        members={togglingInvitation}
        onClose={() => setTogglingInvitation(undefined)}
      />
    </>
  );

  return [actions, triggers, modals] as const;
};

export default useActions;
