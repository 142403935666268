import { useCallback } from 'react';
import { useTranslation } from 'react-i18next';
import { useHistory, useRouteMatch } from 'react-router-dom';

import { entities } from 'app/entity';
import { SecondaryControl } from 'core/components/PageHeader/Navigation';
import useAddFileMenuItem from 'core/containers/FilesTable/useAddFileMenuItem';
import { ChildrenProps, DetailModeEnum } from 'core/containers/FormDetailPage';
import { useBoolClientOption } from 'core/hooks/useClientOption';
import { useSetModal } from 'core/hooks/useModal';
import { useGetPermission } from 'core/hooks/usePermission';
import useGetWADAMissionUrl, { isWADAMission } from 'planning/hooks/useGetWADAMissionUrl';
import Mission from 'planning/models/Mission';

import { MissionModal } from './useMissionModal';
import { MissionRequestModel } from './useMissionOutputMapping';
import { MissionFormData } from './useMissionSchema';

const useGetMissionSecondaryControls = (mode: DetailModeEnum) => {
  const getPermission = useGetPermission();
  const wadaMissionUrl = useGetWADAMissionUrl();
  const isEdit = mode === 'edit';
  const { t } = useTranslation();
  const canDeleteMission = getPermission('missions:delete');
  const { url, params } = useRouteMatch<{ id?: string; usersId: string }>();
  const setModal = useSetModal();
  const history = useHistory();
  const canEditDetails = getPermission('missions:patch[actions:details]');
  const canCreateMission = getPermission('missions:create');
  const hasDCOInvoicesEnabled = useBoolClientOption('enableDcoInvoices');

  const addFileAction = useAddFileMenuItem('missions', () => {
    setModal(MissionModal.ADD_FILE);
    history.push(url + '/attachments');
  });

  return useCallback(
    ({
      onDelete,
      entityData,
    }: ChildrenProps<Mission, MissionFormData, MissionRequestModel>): SecondaryControl[] => {
      return [
        {
          to: entities.mission.urls().clone(Number(params.id)),
          icon: 'file_copy',
          text: t('Clone'),
          key: 'clone',
          hidden: !canCreateMission || !isEdit,
        },
        {
          key: 'createInvoice',
          text: t('Create Invoice'),
          icon: 'add',
          permission: 'dcoInvoices:create',
          onClick: () => {
            entityData && setModal(MissionModal.CREATE_INVOICE);
          },
          hidden: !hasDCOInvoicesEnabled || !isEdit,
        },
        { ...addFileAction, hidden: addFileAction.hidden || !canEditDetails || !isEdit },
        {
          text: t('Open in ADAMS'),
          icon: 'wada',
          key: 'linkToWada',
          hidden: !isWADAMission(entityData?.code),
          to: { url: wadaMissionUrl(entityData?.code || ''), target: '_blank' },
        },
        {
          confirmation: t('Are you sure you want to permanently delete this Mission?'),
          onClick: () => onDelete(),
          text: t('Delete'),
          icon: 'delete',
          key: 'delete',
          hidden: !canDeleteMission || !isEdit,
        },
      ];
    },
    [
      addFileAction,
      canCreateMission,
      canDeleteMission,
      canEditDetails,
      hasDCOInvoicesEnabled,
      isEdit,
      params.id,
      t,
      wadaMissionUrl,
      setModal,
    ]
  );
};

export default useGetMissionSecondaryControls;
