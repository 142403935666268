import B, { ButtonProps as UIButtonProps } from '@material/react-button';
import { FC } from 'react';

type ButtonTypes = HTMLAnchorElement | HTMLButtonElement;
type MaterialButtonProps = Pick<
  UIButtonProps<ButtonTypes>,
  Exclude<keyof UIButtonProps<ButtonTypes>, 'initRipple'>
>;

export interface ButtonProps extends Omit<MaterialButtonProps, 'children'> {
  text: string;
}

const Button: FC<ButtonProps> = (p) => (
  <B placeholder={undefined} {...p}>
    {p.text}
  </B>
);

export default Button;
