import { ReactNode } from 'react';
import { useTranslation } from 'react-i18next';

import { FlexCell, FlexRow } from 'core/components/FlexUtils';
import IconButton from 'core/components/IconButton';

import { DataCardAction, DataCardItem } from '../props';

import Checkbox from './Checkbox';
import { Header, Icons, SelectionHeader, Title } from './styled';
import { BulkActionsLogic } from './useBulkActions';

interface Props<Item extends DataCardItem> {
  title: ReactNode;
  actions?: DataCardAction<Item>[];
  selection: Set<string | number>;
  logic: BulkActionsLogic<Item>;
  displayCheckbox: boolean;
}

/**
 * Heading with the bulk actions
 */
const BulkActions = <Item extends DataCardItem>({
  title,
  actions,
  selection,
  logic,
  displayCheckbox,
}: Props<Item>) => {
  const { t } = useTranslation();

  if (selection.size === 0 || !actions) {
    return (
      <Header verticalPadding={!displayCheckbox || !actions}>
        {actions && displayCheckbox && <Checkbox logic={logic} />}
        {typeof title === 'string' ? <Title>{title}</Title> : title}
      </Header>
    );
  }

  return (
    <SelectionHeader>
      <FlexRow verticalAlign="center" spacing="0">
        {displayCheckbox && (
          <FlexCell>
            <Checkbox logic={logic} />
          </FlexCell>
        )}

        <FlexCell>{t('Selected items: {{number}}', { number: selection.size })}</FlexCell>
      </FlexRow>

      <Icons aria-label={t('Bulk Actions')}>
        {actions.map((action) => {
          const disabled =
            typeof action.disabled === 'function'
              ? action.disabled(logic.getSelectedItems())
              : action.disabled;

          return (
            <IconButton
              key={action.id}
              icon={action.bulkIcon as string}
              tooltip={typeof disabled === 'string' ? disabled : action.title}
              disabled={!!disabled}
              onClick={() => action.onClick(logic.getSelectedItems())}
            />
          );
        })}
      </Icons>
    </SelectionHeader>
  );
};

export default BulkActions;
