import { FC, ReactElement, ReactNode } from 'react';
import { Link as ReactRouterLink } from 'react-router-dom';

import theme from 'app/theme';
import MaterialIcon from 'core/components/MaterialIcon';
import Text from 'core/components/Text';

import Tooltip from '../Tooltip';

import { LinkWrapper } from './styled';

/**
 * Props for the Link component.
 *
 * @interface LinkProps
 * @property {string} [to] - The URL to navigate to.
 * @property {ReactNode} [text] - The text content of the link.
 * @property {ReactNode} [textPosition] - The position of text of the link.
 * @property {ReactNode} [icon] - The icon element to display alongside the text.
 * @property {'_blank' | '_self' | '_parent' | '_top'} [target] - The target attribute for the link.
 * @property {string} [className] - The CSS class name for the link.
 * @property {boolean} [$fullWidth] - Whether the link should take up the full width of its container.
 */

export enum TextPosition {
  LEFT = 'left',
  RIGHT = 'right',
}

interface LinkProps {
  to: string;
  text?: ReactNode;
  textPosition?: TextPosition;
  icon?: ReactNode;
  target?: '_blank' | '_self' | '_parent' | '_top';
  className?: string;
  fullWidth?: boolean;
  tooltip?: string;
}

const Link: FC<LinkProps> = ({
  to,
  text,
  textPosition = TextPosition.LEFT,
  className,
  icon = 'launch',
  target = '_blank',
  fullWidth = false,
  tooltip,
  ...rest
}) => {
  let el =
    typeof icon === 'string' ? <MaterialIcon icon={icon} color={theme.color.gray400} /> : icon;

  const textElement = typeof text === 'string' ? <Text>{text}</Text> : text;

  if (tooltip && el) {
    el = <Tooltip content={tooltip}>{el as ReactElement}</Tooltip>;
  }

  return (
    <LinkWrapper className={className} $fullWidth={fullWidth}>
      {textPosition === TextPosition.LEFT && textElement}
      <ReactRouterLink to={to} target={target} rel="noreferrer" {...rest}>
        {el}
      </ReactRouterLink>
      {textPosition === TextPosition.RIGHT && textElement}
    </LinkWrapper>
  );
};

export default Link;
