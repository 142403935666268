import { Input } from '@material/react-text-field';
import { createPopper } from '@popperjs/core';
import debounce from 'lodash/debounce';
import {
  ComponentProps,
  FocusEvent,
  RefObject,
  useCallback,
  useEffect,
  useRef,
  useState,
} from 'react';
import { useTranslation } from 'react-i18next';

import Menu from 'core/components/Menu';

import { ApiQueryOptions, useApiCall } from '../useApiCall';

import { PopoverWrapper } from './styled';

/**
 * Adds autocomplete to input-like fields
 *
 * Requires using returned additional props and popover element.
 * For example implementation, see AutocompleteTextField.
 */
const useFieldAutocomplete = (
  id: string,
  inputRef: RefObject<Input | HTMLInputElement>,
  endpoint: string,
  onSelection: (value: string) => void,
  onBlur?: (event: FocusEvent<HTMLInputElement>) => void,
  value?: string | number | null,
  /**
   * Should the current value, if it doesn't match any suggestions, become an option
   */
  newValueAsOption = false
) => {
  const { t } = useTranslation();
  const popoverRef = useRef<HTMLDivElement>(null);
  const getInputEl = useCallback(
    () =>
      inputRef.current instanceof HTMLInputElement
        ? inputRef.current
        : inputRef.current?.inputElement,
    [inputRef]
  );

  const [activeItem, setActiveItem] = useState<number>(0);
  const [items, setItems] = useState<ComponentProps<typeof Menu>['items']>([]);
  const hasItems = Array.isArray(items) && items.length > 0;

  const encodedPrefix = typeof value === 'string' && value.length > 0 && encodeURIComponent(value);
  const { dispatch: reloadSuggestions, data: loadedItems } = useApiCall<
    Array<{ id: number; name: string }>
  >(`autocompleteSuggestions-${id}`, {
    autoload: false,
    stale: true,
  });
  const debouncedReloadSuggestions = useRef(
    debounce((options: ApiQueryOptions) => reloadSuggestions(options), 100, { maxWait: 1000 })
  );

  useEffect(() => {
    const hasFocus = getInputEl() === document.activeElement;
    if (loadedItems && hasFocus) {
      const options = loadedItems.map((item, index) => {
        if (typeof item === 'string') {
          return { key: item, id: index.toString(), text: item, active: activeItem === index };
        }
        return {
          key: item.id.toString(),
          id: index.toString(),
          text: item.name,
          active: activeItem === index,
        };
      });
      if (newValueAsOption && value && !options.some((o) => o.text === value))
        options.push({
          key: value.toString(),
          id: options.length.toString(),
          text: value.toString(),
          active: activeItem === options.length,
        });
      setItems(options);
    } else {
      setItems([]);
    }
  }, [loadedItems, activeItem, getInputEl, newValueAsOption, value, t]);

  useEffect(() => {
    // Load only if input has focus, because it can be set programmatically
    const hasFocus = getInputEl() === document.activeElement;
    if (encodedPrefix && hasFocus) {
      debouncedReloadSuggestions.current({ url: `${endpoint}/${encodedPrefix}` });
      setActiveItem(0);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [encodedPrefix, endpoint, getInputEl]);

  // Create popper
  useEffect(() => {
    if (!popoverRef.current || !getInputEl()) return;
    const popper = createPopper(getInputEl()!, popoverRef.current, {
      placement: 'bottom-start',
    });

    return () => {
      popper.destroy();
    };
  }, [getInputEl]);

  const selectItem = useCallback(
    (text: string) => {
      // In timeout to make sure other changes are committed by this point
      setTimeout(() => {
        onSelection(text);
        setItems([]);
        setActiveItem(0);
      });
    },
    [onSelection, setItems]
  );

  // Support keyboard navigation - up and down keys to navigate list, Enter to confirm
  useEffect(() => {
    const inputEl = getInputEl();
    if (!inputEl) return;
    const eventListener = (e: KeyboardEvent) => {
      if (e.key === 'ArrowUp') {
        setActiveItem((i) => Math.max((i || 0) - 1, 0));
        e.preventDefault();
      }
      if (e.key === 'ArrowDown') {
        setActiveItem((i) => Math.min((i || 0) + 1, items.length - 1));
        e.preventDefault();
      }
      if (e.key === 'Enter') {
        const item = items.find((i) => typeof i !== 'string' && i.id === activeItem?.toString());
        if (typeof item === 'object') {
          inputEl.blur();
          selectItem(item.text);
        }
        e.preventDefault();
      }
    };
    inputEl.addEventListener('keydown', eventListener);

    return () => {
      inputEl.removeEventListener('keydown', eventListener);
    };
  }, [items, activeItem, selectItem, getInputEl]);

  // Clear options, set activeItem to 0 onBlur if not already the case
  const handleOnBlur = useCallback(
    (e: FocusEvent<HTMLInputElement>) => {
      onBlur && onBlur(e);
      // Because of blur - run timeout to make sure everything is committed
      setTimeout(() => {
        setItems((items) => (items.length === 0 ? items : []));
        setActiveItem(0);
      }, 100);
    },
    [onBlur]
  );

  return {
    handleOnBlur,
    props: {
      id: `${id}-edit`,
      autoComplete: 'off',
      role: 'combobox',
      'aria-owns': `${id}-list`,
      'aria-autocomplete': 'list',
      'aria-activedescendant': hasItems ? `${id}-opt${activeItem}` : undefined,
    } as const,
    popover: (
      <PopoverWrapper ref={popoverRef} visible={hasItems}>
        {hasItems && (
          <Menu id={`${id}-list`} items={items} onItemMouseDown={(item) => selectItem(item.text)} />
        )}
      </PopoverWrapper>
    ),
  };
};

export default useFieldAutocomplete;
