import { ClientOptions } from 'app/models/Profile';
import { OTHER_NUMBER } from 'core/components/SelectOtherField';
import { transformDateTimeToDate } from 'core/effects/apiCall/dateTimeTransformations';
import { useClientOptions } from 'core/hooks/useClientOption';
import useSettingsInputMapping from 'planning/components/SettingsTab/useSettingsInputMapping';
import Event from 'planning/models/Event';

import useEventSchema, { EventFormData } from './useEventSchema';

const useEventInputMapping = (mode: 'edit' | 'clone' | 'create') => {
  const settingsInputMapping = useSettingsInputMapping();
  const schema = useEventSchema();
  const clientOptions = useClientOptions();

  return (eventData?: Event): EventFormData => {
    const { dateFrom, dateTo } = transformDateTimeToDate(eventData || ({} as Event));

    return {
      ...schema.cast(
        eventData
          ? {
              ...eventData,
              dateRange: dateFrom && dateTo ? { from: dateFrom, to: dateTo } : null,
              externalId: mode === 'create' ? null : eventData.externalId,
              federationsId: eventData.federationsId || OTHER_NUMBER,
              ...settingsInputMapping(eventData),
            }
          : {
              resultAuthority: getFromClientOption(clientOptions, 'defaultResultAuthority'),
              testAuthority: getFromClientOption(clientOptions, 'defaultTestAuthority'),
              federationsId: getFromClientOption(clientOptions, 'defaultFederationsId'),
              sampleAuthoritiesId: getFromClientOption(
                clientOptions,
                'defaultSampleCollectionAuthority'
              ),
              initiatorAuthoritiesId: getFromClientOption(clientOptions, 'defaultForDcc'),
              notifyOfTeamUpdates: getFromClientOption(
                clientOptions,
                'defaultForNotifyOfTeamUpdates'
              ),
              showAthleteToDco: getFromClientOption(clientOptions, 'defaultForShowAthleteToDco'),
              use3genApp: getFromClientOption(clientOptions, 'defaultForUse3genApp'),
              allowIcPoolCollection: getFromClientOption(
                clientOptions,
                'defaultForAllowIcPoolCollection'
              ),
            },
        { stripUnknown: true }
      ),
    };
  };
};

const getFromClientOption = (
  clientOptions: undefined | ClientOptions,
  clientOptionName: Extract<keyof ClientOptions, string>,
  type: 'number' | 'string' = 'number'
): null | string | number => {
  const value = clientOptions?.[clientOptionName]?.value;
  if (!value) return null;
  return type === 'number' ? Number(value) : value;
};

export default useEventInputMapping;
