import { FC, memo } from 'react';
import { useTranslation } from 'react-i18next';

import { Option } from 'core/components/DropDown';
import Field from 'core/components/Form/Field';
import FormDropDown from 'core/components/FormDropDown';
import FormTextfield from 'core/components/FormTextfield';

import { Row, Cell } from '../styled';

import RemoveButton from './RemoveButton';

interface Props {
  options: Option[];
  name: string;
}

const OtherRow: FC<Props> = ({ name, options }) => {
  const { t } = useTranslation();

  return (
    <Row>
      <Cell>
        <Field
          label={t('Analyse Type')}
          component={FormDropDown}
          name={`${name}.id`}
          options={options}
          fast={false}
          required
          single
        />
      </Cell>
      <Cell>
        <Field
          name={`${name}.remarks`}
          component={FormTextfield}
          label={t('Remarks')}
          fast={false}
        />
      </Cell>
      <Field
        dummyPlaceholder={() => null} // don't render remove button in view mode
        component={RemoveButton}
        diffMode={false}
        fast={false}
        name={name}
      />
    </Row>
  );
};

export default memo(OtherRow);
