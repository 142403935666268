import styled from 'styled-components/macro';

// Values are in pixels bc ag-grid uses pixels
export const Container = styled.div`
  height: 46px;
  width: 100%;
  display: flex;
  justify-content: flex-start;
  align-items: center;

  > * {
    margin-left: 64px;
    width: 240px;
    height: 16px;
  }
`;
