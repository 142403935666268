import { ColDef } from 'ag-grid-community';
import { useCallback, useEffect, useMemo, useRef } from 'react';
import { useLocation } from 'react-router-dom';

import { Filter, FilterValue } from '../props';

import { UrlColumnConfiguration } from './useColumnConfiguration';
import useDatagridUserViews from './useDatagridUserViews';
import useDefaultGridConfiguration, { useDefaultFilterValues } from './useDefaultGridConfiguration';

export const useSetDefaultViewConfiguration = (
  entity: string,
  onFiltersAndColumnsChange: (
    filters: { [key: string]: FilterValue } | undefined,
    columnConfiguration: UrlColumnConfiguration
  ) => void,
  originalColumnDefs: ColDef<any>[],
  filterValues?: Record<string, FilterValue>,
  filters?: Record<string, Filter>,
  disableViews?: boolean
) => {
  const configuredFilters = useMemo(() => new Set(Object.keys(filters || {})), [filters]);
  const [userViews] = useDatagridUserViews(entity, configuredFilters, disableViews);
  const defaultView = userViews.find((it) => it.default);
  const defaultFilterValues = useDefaultFilterValues(filters);
  const defaultGridConfiguration = useDefaultGridConfiguration(originalColumnDefs);

  return useCallback(() => {
    if (defaultView) {
      const { filters, ...rest } = defaultView.data;
      onFiltersAndColumnsChange(
        { ...filters, ...(filterValues || {}) },
        { ...rest, hide: rest?.hide || defaultGridConfiguration.hide, activeView: defaultView.id }
      );
    } else {
      onFiltersAndColumnsChange(
        { ...defaultFilterValues, ...(filterValues || {}) },
        {
          ...defaultGridConfiguration,
          activeView: undefined,
        }
      );
    }
  }, [
    defaultFilterValues,
    onFiltersAndColumnsChange,
    defaultGridConfiguration,
    defaultView,
    filterValues,
  ]);
};

export const getUrlConfiguration: (
  configuration: UrlColumnConfiguration,
  activeViewId?: number
) => UrlColumnConfiguration = (c, activeViewId) => ({
  activeView: activeViewId,
  pinned: c?.pinned || undefined,
  width: c?.width || undefined,
  duplicity: c?.duplicity || false,
  hide: c?.hide || undefined,
  position: c?.position || undefined,
  sort: c?.sort || undefined,
});

const useDefaultViewConfiguration = (
  disableViews: boolean,
  entity: string,
  originalColumnDefs: ColDef<any>[],
  onFiltersAndColumnsChange: (
    filters: { [key: string]: FilterValue } | undefined,
    columnConfiguration: UrlColumnConfiguration
  ) => void,
  filterValues?: Record<string, FilterValue>,
  filters?: Record<string, Filter>
) => {
  // To avoid infinity setDefaultView calling
  const alreadySet = useRef<boolean>(false);
  const location = useLocation();
  const configuredFilters = useMemo(() => new Set(Object.keys(filters || {})), [filters]);
  const [, , isLoading] = useDatagridUserViews(entity, configuredFilters, disableViews);
  const prefillDisabled = !!Object.keys(filterValues || {}).length; // If we have filters in URL, we don't want to prefill defaults, so back button works correctly

  const setDefaultView = useSetDefaultViewConfiguration(
    entity,
    onFiltersAndColumnsChange,
    originalColumnDefs,
    filterValues,
    filters,
    disableViews
  );

  useEffect(() => {
    alreadySet.current = false;
  }, [location.search]);

  useEffect(() => {
    // We rely on always having all hide properties
    if (
      !disableViews &&
      !prefillDisabled &&
      !location.search.includes('hide') &&
      !isLoading &&
      !alreadySet.current
    ) {
      alreadySet.current = true;
      setDefaultView();
    }
  }, [setDefaultView, disableViews, location.search, isLoading, prefillDisabled]);
};

export default useDefaultViewConfiguration;
