import { useFormikContext } from 'formik';
import { useEffect, useContext } from 'react';

import { AccreditationsRole } from 'app/models/Accreditation';
import { FormContext } from 'core/components/Form';
import { AnalysesFieldsData } from 'planning/components/AnalysesFields';
import { TeamMemberData } from 'planning/components/TeamTab/useTeamInputMapping';

const useBcosClearingWhenNoBloodAnalyses = () => {
  const { values, setValues } = useFormikContext<
    AnalysesFieldsData & {
      dcos: number[];
      bcos: number[];
      leadDcosId: number | null;
      invitedMembers?: TeamMemberData[];
      assignedMembers?: TeamMemberData[];
    }
  >();
  const { bloodAnalyses, otherBloodAnalyses, invitedMembers, assignedMembers } = values;
  const bloodAnalysesUsed = bloodAnalyses?.length > 0 || otherBloodAnalyses?.length > 0;
  const hasBcoMembers =
    invitedMembers?.some((member) => member.roles.includes(AccreditationsRole.BCO)) ||
    assignedMembers?.some((member) => member.roles.includes(AccreditationsRole.BCO));
  const removeLeadDco = !!assignedMembers?.find(
    (member) =>
      (member.roles || []).includes(AccreditationsRole.LEAD_DCO) &&
      (member.roles || []).includes(AccreditationsRole.BCO) &&
      !(member.roles || []).includes(AccreditationsRole.DCO)
  );

  const { viewMode } = useContext(FormContext);

  useEffect(() => {
    if (!viewMode && !bloodAnalysesUsed && hasBcoMembers) {
      setValues((currentValues) => {
        const newInvited = currentValues.invitedMembers?.map((member) => ({
          ...member,
          roles: (member.roles || []).filter((it) => it !== AccreditationsRole.BCO),
        }));
        const newAssigned = currentValues.assignedMembers?.map((member) => ({
          ...member,
          roles: (member.roles || []).filter((it) => it !== AccreditationsRole.BCO),
        }));

        return {
          ...currentValues,
          // In this level we don't know which assignment is used
          ...('bcos' in currentValues
            ? {
                bcos: [],
              }
            : {}),
          ...('leadDcosId' in currentValues && removeLeadDco
            ? {
                leadDcosId: null,
              }
            : {}),
          invitedMembers: (newInvited || []).filter((member) => (member.roles || []).length),
          assignedMembers: (newAssigned || []).filter((member) => (member.roles || []).length),
        };
      });
    }
  }, [bloodAnalysesUsed, hasBcoMembers, setValues, viewMode, removeLeadDco]);
};

export default useBcosClearingWhenNoBloodAnalyses;
