import { toast } from 'react-toastify';
import { put, take, race } from 'redux-saga/effects';

import { apiCall, loadEntitySuccess } from 'core/actions';
import { stringify } from 'core/functions/qs';
import { t } from 'core/i18n';
import {
  LoadAthleteRevisionAction,
  SetAthleteMasterAction,
  TYPE_SET_ATHLETE_MASTER,
  loadAthleteRevisions,
  LoadAthleteMastersAction,
} from 'personnel/actions';
import { ATHLETES_RESOURCE_PARAMS } from 'personnel/models/Athlete';

export function* loadAthleteRevisionsSaga(action: LoadAthleteRevisionAction) {
  yield put(
    apiCall(
      action.type,
      'GET',
      '/athletes',
      {},
      {
        params: {
          $or: { mastersId: action.payload.mastersId, id: action.payload.mastersId },
          // TODO: Sort
        },
        paramsSerializer: (params) => stringify(params),
      }
    )
  );
}

export function* setAthleteMasterSaga(action: SetAthleteMasterAction) {
  yield put(
    apiCall(
      action.type,
      'PATCH',
      `/athletes/${action.payload.athleteId}`,
      { mastersId: action.payload.mastersId },
      {},
      true,
      { mastersId: action.payload.mastersId === null ? 1 : null }
    )
  );

  const { success } = yield race({
    success: take(`${TYPE_SET_ATHLETE_MASTER}_SUCCESS`),
    error: take(`${TYPE_SET_ATHLETE_MASTER}_ERROR`),
  });

  if (success) {
    toast.success(t('Master successfully updated'));
    const athlete = success.payload.response.data;

    if (
      (athlete && action.payload.athleteId === athlete.id) ||
      action.payload.mastersId === athlete.mastersId ||
      action.payload.mastersId === null // todo: send prev mastersID and if its matches with loaded
    ) {
      yield put(
        loadEntitySuccess(success.payload.response, `/athletes/${action.payload.athleteId}`)
      );

      yield put(loadAthleteRevisions(action.payload.mastersId || action.payload.athleteId));
    }
  }
}

export function* loadAthleteMastersSaga(action: LoadAthleteMastersAction) {
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const { sort, order, ...defaultParams } = ATHLETES_RESOURCE_PARAMS;
  yield put(
    apiCall(
      action.type,
      'GET',
      '/athletes',
      {},
      {
        params: {
          ...defaultParams,
          offset: 0,
        },
        paramsSerializer: (params) => stringify(params),
      }
    )
  );
}
