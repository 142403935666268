import { FC } from 'react';
import { useTranslation } from 'react-i18next';

import Field from 'core/components/Form/Field';
import useFormFieldValue from 'core/components/Form/useFormFieldValue';
import FormDropDown from 'core/components/FormDropDown';
import { Cell } from 'core/components/Grid';
import ResourceFormDropdown from 'core/components/ResourceFormDropdown';
import { useDetail } from 'core/containers/FormDetailPage/DetailContext';
import Mission from 'planning/models/Mission';
import Test from 'planning/models/Test';

import { useGetOneHourSlotActive } from './hooks';

interface Props {
  withOneHourSlotField?: boolean;
  entityData?: Test;
}

const PoolsField: FC<Props> = ({ withOneHourSlotField = true, entityData: testData }) => {
  const { t } = useTranslation();
  const formDetails = useDetail<Test | Mission>();
  const entityData = formDetails?.entityData || testData;
  const poolsId = useFormFieldValue('poolsId');

  const pool = entityData?.pool;

  const oneHourSlotOptions = [
    { name: t('Undefined'), id: null },
    { name: t('Inside'), id: 'in' },
    { name: t('Outside'), id: 'out' },
  ];

  const getOneHourSlotActive = useGetOneHourSlotActive({});

  return (
    <>
      <Cell desktopColumns={4} tabletColumns={8} phoneColumns={4}>
        <Field
          component={ResourceFormDropdown}
          label={t('Pool')}
          resource="pools"
          name="poolsId"
          single
          fast
        />
      </Cell>

      {withOneHourSlotField && getOneHourSlotActive(poolsId, pool) && (
        <Cell desktopColumns={4} tabletColumns={8} phoneColumns={4}>
          <Field
            label={t('1h-slot Requirement')}
            options={oneHourSlotOptions}
            component={FormDropDown}
            name="oneHourSlot"
            single
            fast
          />
        </Cell>
      )}
    </>
  );
};

export default PoolsField;
