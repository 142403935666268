import { ColDef, ICellRendererParams } from 'ag-grid-community';
import { useCallback, useMemo } from 'react';
import { useTranslation } from 'react-i18next';

import { AccreditationsRole } from 'app/models/Accreditation';
import { useAthleteColumn } from 'common/columns/useAthleteColumn';
import PopoverCellRenderer from 'core/containers/DataGrid/CellRenderers/PopoverCellRenderer';
import {
  PopoverContent,
  PopoverDescriptionItem,
  PopoverTitle,
} from 'core/containers/DataGrid/CellRenderers/PopoverCellRenderer/styled';
import TooltipCellRenderer from 'core/containers/DataGrid/CellRenderers/TooltipCellRenderer';
import BadgeCellRenderer from 'core/containers/DataGrid/FastCellRenderers/BadgeCellRenderer';
import { useDetail } from 'core/containers/FormDetailPage/DetailContext';
import useAppSelector from 'core/hooks/useAppSelector';
import { useBoolClientOption } from 'core/hooks/useClientOption';
import { useGetPermission } from 'core/hooks/usePermission';
import { useFormatDateTime } from 'core/i18n/useFormatDateTime';
import Analysis from 'lists/models/Analysis';
import useGetSampleTypeBadge from 'planning/hooks/useGetSampleTypeBadge';
import Coc from 'planning/models/Coc';
import { MissionDatagrid, MissionsContentsTestsDatagrid } from 'planning/models/Mission';
import Sample, { SampleType } from 'planning/models/Sample';
import TeamMember from 'planning/models/TeamMember';

const useTableDefinitions = () => {
  const { t } = useTranslation();
  const formatDateTime = useFormatDateTime();
  const sampleBadgeType = useGetSampleTypeBadge();
  const getAthleteColumn = useAthleteColumn();
  const hasPermission = useGetPermission();
  const canSeeTestDetails = hasPermission('tests:get[actions:details]');
  const detail = useDetail();

  const samplesMap = useMemo(
    () => ({
      oneUrineTube: t('Urine'),
      oneBloodEdtaTube: t('1xEDTA'),
      twoBloodEdtaTubes: t('2xEDTA'),
      twoBloodGelTubes: t('2xGEL'),
      oneBloodEdtaTwoGelTubes: t('1xEDTA+2xGEL'),
      driedBloodSpots: t('DBS'),
    }),
    [t]
  );

  const getSamplesTooltip = useCallback(
    (samples: Partial<Record<keyof typeof samplesMap, boolean>>) => {
      const filteredSamples = Object.keys(samples).filter(
        (s) => samples[s as keyof typeof samples]
      );

      const tooltip = filteredSamples
        .map((s) => samplesMap[s as keyof typeof samplesMap])
        .join(', ');

      return tooltip || t('None');
    },
    [t, samplesMap]
  );

  const getTestAnalysesTooltip = useCallback(
    (data?: MissionsContentsTestsDatagrid) => {
      const analyses = [...(data?.urineAnalyses || []), ...(data?.bloodAnalyses || [])];
      const analysesTemp = Object.entries(samplesMap).map(([key, value]) => {
        const shortNames = analyses
          .map(
            (item) =>
              item[key as keyof Analysis] && (
                <PopoverDescriptionItem>{item.shortName}</PopoverDescriptionItem>
              )
          )
          .filter(Boolean);

        if (!shortNames.length) return undefined;

        return (
          <>
            <PopoverTitle>{value}</PopoverTitle>
            {shortNames}
          </>
        );
      });

      return (
        <PopoverContent>
          <>{analysesTemp && analysesTemp}</>
        </PopoverContent>
      );
    },
    [samplesMap]
  );

  const getTestDCOAnalysesTooltip = useCallback(
    (data?: MissionsContentsTestsDatagrid) => {
      if (!data) return t('None');
      const sampleTypes = Object.keys(data).filter(
        (s) => s in samplesMap && data[s as keyof MissionsContentsTestsDatagrid]
      );

      const tooltip = sampleTypes.map((s) => samplesMap[s as keyof typeof samplesMap]).join(', ');
      return <PopoverContent>{tooltip || t('None')}</PopoverContent>;
    },
    [samplesMap, t]
  );

  const hasAnalyses = useCallback((data?: MissionsContentsTestsDatagrid) => {
    if (!data) return false;
    return data.urineAnalyses || data.bloodAnalyses;
  }, []);

  const getTestSamplesTooltip = useCallback(
    (data?: MissionsContentsTestsDatagrid) => {
      const hasAnalysesPermissions = hasAnalyses(data);

      if (hasAnalysesPermissions) return getTestAnalysesTooltip(data);
      return getTestDCOAnalysesTooltip(data);
    },
    [getTestAnalysesTooltip, getTestDCOAnalysesTooltip, hasAnalyses]
  );

  const hasBlood = useCallback(
    (data?: MissionsContentsTestsDatagrid) => {
      const hasAnalysesPermissions = hasAnalyses(data);
      if (!data) return false;
      if (!hasAnalysesPermissions) {
        const sampleTypes = Object.keys(data).filter(
          (s) =>
            s in samplesMap &&
            data[s as keyof MissionsContentsTestsDatagrid] &&
            s !== 'oneUrineTube'
        );
        return !!sampleTypes.length;
      }
      return (data.bloodAnalyses || []).length > 0;
    },
    [hasAnalyses, samplesMap]
  );

  const hasUrine = useCallback(
    (data?: MissionsContentsTestsDatagrid) => {
      const hasAnalysesPermissions = hasAnalyses(data);
      if (!data) return false;
      if (!hasAnalysesPermissions) {
        const sampleTypes = Object.keys(data).filter(
          (s) =>
            s in samplesMap &&
            data[s as keyof MissionsContentsTestsDatagrid] &&
            s === 'oneUrineTube'
        );
        return !!sampleTypes.length;
      }
      return (data.urineAnalyses || []).length > 0;
    },
    [hasAnalyses, samplesMap]
  );
  const user = useAppSelector(({ core }) => core.user);

  const showAnalysesToAssignedDco =
    useBoolClientOption('showAnalysesToAssignedDco') &&
    !!(detail.formData?.assignedMembers || []).find((it: TeamMember) => {
      return it.usersId === user?.id && (it.roles || []).includes(AccreditationsRole.DCO);
    });

  return useMemo(
    (): Record<string, () => { title: string; columns: ColDef[] }> =>
      ({
        tests: () => ({
          title: t('Tests'),
          columns: [
            {
              headerName: t('Test ID'),
              sortable: true,
              sort: 'desc',
              field: 'id',
              width: 80,
            },
            getAthleteColumn(),
            {
              headerName: t('Sample Type'),
              field: 'sampleType',
              valueGetter: (params: { data: any }) => {
                const isBlood = hasBlood(params.data);
                const isUrine = hasUrine(params.data);
                const badgeType = sampleBadgeType(isBlood, isUrine);
                return badgeType.title;
              },
              cellRenderer: (params: ICellRendererParams) => {
                const isBlood = hasBlood(params.data);
                const isUrine = hasUrine(params.data);

                const badgeType = sampleBadgeType(isBlood, isUrine);
                return showAnalysesToAssignedDco || canSeeTestDetails ? (
                  <PopoverCellRenderer<MissionsContentsTestsDatagrid, string>
                    content={() => getTestSamplesTooltip(params.data)}
                  >
                    <BadgeCellRenderer color={badgeType.color} title={badgeType.title} />
                  </PopoverCellRenderer>
                ) : (
                  <BadgeCellRenderer color={badgeType.color} title={badgeType.title} />
                );
              },
            },
            {
              headerName: t('Done Date'),
              valueGetter: ({ data }: any) =>
                data.resultPerformedAt
                  ? formatDateTime(data.resultPerformedAt, 'DATE_SHORT')
                  : data.resultCreatedAt
                    ? formatDateTime(data.resultCreatedAt, 'DATE_SHORT')
                    : t('Open'),
            },
          ],
        }),

        reports: () => ({
          title: t('Control Reports'),
          columns: [
            {
              headerName: t('Test ID'),
              field: 'testsId',
              sortable: true,
              sort: 'desc',
              width: 80,
            },
            {
              headerName: t('DCO'),
              field: 'dcoName',
            },
            getAthleteColumn(),
            {
              headerName: t('Created'),
              valueGetter: (params: any) => formatDateTime(params.data.createdAt, 'DATE_SHORT'),
              field: 'createdAt',
            },
          ],
        }),

        uaReports: () => ({
          title: t('UA Reports'),
          columns: [
            {
              headerName: t('Test ID'),
              field: 'testsId',
              sortable: true,
              sort: 'desc',
              width: 80,
            },
            {
              headerName: t('DCO'),
              field: 'dcoName',
            },
            getAthleteColumn(),
            {
              headerName: t('Created'),
              valueGetter: (params: any) => formatDateTime(params.data.createdAt, 'DATE_SHORT'),
              field: 'createdAt',
            },
          ],
        }),

        cocs: () => ({
          title: t('CoCs'),
          columns: [
            {
              headerName: t('No.'),
              sortable: true,
              sort: 'desc',
              field: 'id',
              width: 80,
            },
            {
              valueGetter: (params: any) => params.data.lab && params.data.lab.shortName,
              headerName: t('Lab'),
              field: 'lab',
            },
            {
              valueGetter: (params: any) =>
                params.data.personalDelivery ? t('Personal') : t('Courier'),
              headerName: t('Delivery'),
              field: 'deliveryCustom',
            },
            {
              valueGetter: (params: { data: Coc }) =>
                params.data.shippedAt
                  ? formatDateTime(params.data.shippedAt, 'DATE_SHORT')
                  : t('N/A'),
              headerName: t('Date'),
              field: 'shippedAt',
              width: 200,
            },
            {
              headerName: t('Waybill'),
              field: 'waybill',
            },
          ],
        }),

        samples: () => ({
          title: t('Samples'),
          columns: [
            {
              headerName: t('Sample ID'),
              sortable: true,
              sort: 'desc',
              field: 'id',
              width: 120,
            },
            {
              headerName: t('Code'),
              field: 'code',
            },
            {
              headerName: t('Sample Type'),
              field: 'sampleType',
              valueGetter: (params: { data: any }) => {
                const badgeType = sampleBadgeType(
                  params.data.type !== SampleType.URINE,
                  params.data.type === SampleType.URINE
                );

                return badgeType.title;
              },
              cellRenderer: (params: ICellRendererParams) => {
                const samples = {
                  oneUrineTube: params.data.type === SampleType.URINE,
                  oneBloodEdtaTube: params.data.type === SampleType.ONE_EDTA,
                  twoBloodEdtaTubes: params.data.type === SampleType.TWO_EDTA,
                  twoBloodGelTubes: params.data.type === SampleType.TWO_GEL,
                  oneBloodEdtaTwoGelTubes: params.data.type === SampleType.ONE_EDTA_TWO_GEL,
                  driedBloodSpots: params.data.type === SampleType.DBS,
                };

                const badgeType = sampleBadgeType(
                  params.data.type !== SampleType.URINE,
                  params.data.type === SampleType.URINE
                );

                return (
                  <TooltipCellRenderer<MissionDatagrid, string>
                    content={() => getSamplesTooltip(samples)}
                    params={params}
                  >
                    <BadgeCellRenderer color={badgeType.color} title={badgeType.title} />
                  </TooltipCellRenderer>
                );
              },
            },
            {
              headerName: t('Stamp'),
              field: 'performedAt',
              valueGetter: (params: { data: Sample }) =>
                params.data.performedAt
                  ? formatDateTime(params.data.performedAt, 'DATE_SHORT')
                  : t('N/A'),
              width: 200,
            },
          ],
        }),
      }) as const,
    [
      t,
      hasBlood,
      hasUrine,
      sampleBadgeType,
      getTestSamplesTooltip,
      formatDateTime,
      getSamplesTooltip,
      getAthleteColumn,
      showAnalysesToAssignedDco,
      canSeeTestDetails,
    ]
  );
};

export default useTableDefinitions;
