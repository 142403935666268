import { takeLatest, takeLeading } from 'redux-saga/effects';

import {
  TYPE_RESTORE_USER_PASSWORD,
  TYPE_INVITE_USERS,
  TYPE_LOAD_ATHLETE_REVISIONS,
  TYPE_SET_ATHLETE_MASTER,
  TYPE_LOAD_ATHLETE_MASTERS,
  TYPE_EDIT_CLIENT,
  TYPE_RESEND_WELCOME_EMAIL,
  TYPE_SAVE_BLACKLIST_RECORD,
  TYPE_FETCH_AVAILABILITIES,
  TYPE_DELETE_AVAILABILITY,
  TYPE_SAVE_AVAILABILITY,
} from '../actions';

import * as athletesSaga from './athlete';
import * as clientSaga from './client';
import * as availabilitiesSaga from './profileAvailabilities';
import * as usersSaga from './users';

export default function* root() {
  yield takeLatest(TYPE_LOAD_ATHLETE_REVISIONS, athletesSaga.loadAthleteRevisionsSaga);
  yield takeLatest(TYPE_RESTORE_USER_PASSWORD, usersSaga.restoreUserPasswordSaga);
  yield takeLeading(TYPE_INVITE_USERS, usersSaga.inviteUsersSaga);
  yield takeLatest(TYPE_RESEND_WELCOME_EMAIL, usersSaga.resendWelcomeEmailSaga);
  yield takeLatest(TYPE_SAVE_BLACKLIST_RECORD, usersSaga.saveBlacklistRecordSaga);

  yield takeLatest(TYPE_LOAD_ATHLETE_MASTERS, athletesSaga.loadAthleteMastersSaga);
  yield takeLatest(TYPE_SET_ATHLETE_MASTER, athletesSaga.setAthleteMasterSaga);

  yield takeLatest(TYPE_EDIT_CLIENT, clientSaga.editClientSaga);

  yield takeLatest(TYPE_FETCH_AVAILABILITIES, availabilitiesSaga.fetchAvailabilities);
  yield takeLatest(TYPE_SAVE_AVAILABILITY, availabilitiesSaga.saveAvailability);
  yield takeLatest(TYPE_DELETE_AVAILABILITY, availabilitiesSaga.deleteAvailability);
}
