import { useCallback, useEffect, useState } from 'react';
import { useHistory, useLocation } from 'react-router-dom';

import { mergeUrl, parse } from 'core/functions/qs';

import { PossibleUrlItemType, coercePossibleNumbers } from '../useUrlState';

/**
 * This method serves for managing complex state in URL {[item1]: any, ...}
 * that consists of different properties and their values
 * @param stateKeys All keys that should be parsed from location.search
 * @param defaultValues Default values, for keys in case the URL doesn't contains it
 * @returns
 */
export const useUrlMultiState = (
  stateKeys: string[],
  defaultValues: {
    [firstLevel: string]: { [secondLevel: string]: PossibleUrlItemType } | PossibleUrlItemType;
  }
) => {
  const location = useLocation();
  const history = useHistory();
  const [multiStateValues, setMultiStateValues] = useState<any>({});

  const urlStateObject = Object.entries(parse(location.search.substring(1)) || {}).reduce(
    (acc, [id, value]) => {
      if (stateKeys.includes(id)) {
        acc[id] = value;
      }

      return acc;
    },
    {} as { [key: string]: any }
  );
  const urlStates = JSON.stringify(urlStateObject);
  const possibleValues = JSON.stringify(Object.keys(defaultValues));

  useEffect(() => {
    const persisted = JSON.parse(urlStates) as {
      [firstLevel: string]:
        | {
            [secondLevel: string]: string | string[] | null;
          }
        | string
        | string[]
        | null;
    };

    const newValues = Object.entries(persisted || {}).reduce(
      (accFirstLevel, [firstLevelId, firstLevelValue]) => {
        if (!stateKeys.includes(firstLevelId)) {
          // We care only about specified keys
          return accFirstLevel;
        }

        // If is object then do it again, if not just use the value
        if (
          typeof firstLevelValue === 'object' &&
          firstLevelValue !== null &&
          !Array.isArray(firstLevelValue)
        ) {
          accFirstLevel[firstLevelId] = Object.entries(firstLevelValue).reduce(
            (accSecondLevel, [secondLevelId, secondLevelValue]) => {
              const persistedValue = coercePossibleNumbers(secondLevelValue);
              if (persistedValue === 'true' || persistedValue === 'false') {
                accSecondLevel[secondLevelId] = persistedValue === 'true';
              } else if (persistedValue || persistedValue === 0) {
                // 0 is falsy but a valid value
                accSecondLevel[secondLevelId] = persistedValue;
              }
              return accSecondLevel;
            },
            {} as {
              [secondLevel: string]: PossibleUrlItemType;
            }
          );
          return accFirstLevel;
        }

        const persistedValue = coercePossibleNumbers(firstLevelValue);
        if (persistedValue === 'true' || persistedValue === 'false') {
          accFirstLevel[firstLevelId] = persistedValue === 'true';
        } else if (persistedValue || persistedValue === 0) {
          // 0 is falsy but a valid value
          accFirstLevel[firstLevelId] = persistedValue;
        }

        return accFirstLevel;
      },
      {} as {
        [firstLevel: string]: { [secondLevel: string]: PossibleUrlItemType } | PossibleUrlItemType;
      }
    );

    setMultiStateValues(newValues);
    // Indirect dependency
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [possibleValues, urlStates]);

  const setUrlStatesValues = useCallback(
    (newStatesValues: {
      [firstLevel: string]: { [secondLevel: string]: PossibleUrlItemType } | PossibleUrlItemType;
    }) => {
      const filtered = Object.entries(newStatesValues).reduce(
        (acc, [id, value]) => {
          if (stateKeys.includes(id)) {
            acc[id] = value;
          }
          return acc;
        },
        {} as {
          [firstLevel: string]:
            | { [secondLevel: string]: PossibleUrlItemType }
            | PossibleUrlItemType;
        }
      );

      // Persist changed settings to the URL
      const urlWithParams = mergeUrl(`${location.pathname}${location.search}`, filtered, {
        shallowMerge: true,
      });

      setMultiStateValues(newStatesValues);

      history.push(urlWithParams);
    },
    [history, location.pathname, location.search, stateKeys]
  );

  return { urlStatesValues: multiStateValues, setUrlStatesValues };
};

export default useUrlMultiState;
