import { EditorState } from 'draft-js';
import * as Yup from 'yup';

const useSchema = () => {
  return Yup.object({
    editorState: Yup.mixed().nullable().default(EditorState.createEmpty()),
    restrictToRoles: Yup.array(Yup.number()).ensure().default([]),
  });
};

export default useSchema;

export type FormData = Yup.TypeOf<ReturnType<typeof useSchema>>;
