import { FC } from 'react';
import { Helmet } from 'react-helmet-async';
import { useTranslation } from 'react-i18next';

import IconLoader from 'app/components/IconLoader';
import theme from 'app/theme';
import Header from 'core/components/PageHeader';
import getEnv from 'core/functions/getEnv';
import { useLocale } from 'core/i18n/useLocale';

import { PageContent, PageBody } from '../Page/styled';

import { TitleContentLoader } from './styled';

interface Props {
  displayHeader?: boolean;
}

const LoadingPage: FC<Props> = ({ displayHeader = true }) => {
  const { t } = useTranslation();
  const locale = useLocale();

  return (
    <>
      <Helmet
        title={t('Loading... | {{app}}', { app: getEnv('NAME') })}
        htmlAttributes={{ lang: locale }}
      />
      <PageContent>
        {displayHeader && (
          <Header
            title={
              <TitleContentLoader
                height={18}
                width={240}
                backgroundColor={theme.color.gray100}
                foregroundColor={theme.color.gray200}
                title={t('Loading...')}
              >
                <rect x="0" y="0" rx="5" ry="5" width={240} height={18} />
              </TitleContentLoader>
            }
          />
        )}
        <PageBody>
          <IconLoader />
        </PageBody>
      </PageContent>
    </>
  );
};

export default LoadingPage;
