import { FC, ReactNode, useEffect, useRef } from 'react';
import ReactDOM from 'react-dom';

import { Wrapper, Content } from './styled';

interface Props {
  onRequestClose: () => void;
  opacity?: number;
  open?: boolean;
  children?: ReactNode;
}

const Drawer: FC<Props> = ({ children, opacity = 15, open, onRequestClose }) => {
  const root = document.getElementById('root');
  const wrapperRef = useRef<HTMLElement>();
  const contentRef = useRef<HTMLElement>();

  useEffect(() => {
    const handler = (e: KeyboardEvent) => {
      e.code === 'Escape' && onRequestClose();
    };
    document.addEventListener('keydown', handler);
    return () => {
      document.removeEventListener('keydown', handler);
    };
  }, [onRequestClose]);

  useEffect(() => {
    if (open) {
      wrapperRef.current &&
        (wrapperRef.current.classList.contains('close')
          ? wrapperRef.current.classList.replace('close', 'open')
          : wrapperRef.current.classList.add('open'));
      contentRef.current &&
        (contentRef.current.classList.contains('close')
          ? contentRef.current.classList.replace('close', 'open')
          : contentRef.current.classList.add('open'));
    } else {
      if (wrapperRef.current && wrapperRef.current.classList.contains('open')) {
        wrapperRef.current.classList.replace('open', 'close');
      }
      if (contentRef.current && contentRef.current.classList.contains('open')) {
        contentRef.current.classList.replace('open', 'close');
      }
    }
  }, [open]);

  return (
    root &&
    ReactDOM.createPortal(
      <>
        <Wrapper onClick={onRequestClose} opacity={opacity} ref={wrapperRef}></Wrapper>
        <Content
          ref={contentRef}
          onClick={(e) => {
            e.preventDefault();
            e.stopPropagation();
          }}
        >
          {children}
        </Content>
      </>,
      root
    )
  );
};

export default Drawer;
