import { ICellRendererParams } from 'ag-grid-community';

import theme from 'app/theme';

import styles from './index.module.css';

type Props = ICellRendererParams & {
  title: (data: any) => string;
  rightTitle?: (data: any) => string;
};

export default class CircleLettersCellRenderer {
  private wrapper?: HTMLDivElement;
  private circleElement?: HTMLDivElement;
  private textCircleElement?: HTMLSpanElement;
  private rightTextElement?: HTMLSpanElement;

  public init({ title, rightTitle, data }: Props) {
    this.circleElement = document.createElement('div');
    this.circleElement.className = styles['circle-letters'];
    this.circleElement.style.border = `1px solid ${theme.color.gray300}`;
    this.circleElement.style.color = theme.color.gray400;

    this.textCircleElement = document.createElement('span');
    this.textCircleElement.textContent = title(data);
    this.circleElement.appendChild(this.textCircleElement);

    if (rightTitle) {
      this.wrapper = document.createElement('div');
      this.wrapper.className = styles['circle-letters-wrapper'];
      this.wrapper.appendChild(this.circleElement);
      this.rightTextElement = document.createElement('span');
      this.rightTextElement.textContent = rightTitle(data);
      this.rightTextElement.className = styles['circle-letters-right-title'];
      this.wrapper.appendChild(this.rightTextElement);
    }
  }

  public getGui() {
    // Ag-grid should call init before getGui, so we should be fairly sure
    return this.wrapper ? this.wrapper : this.circleElement!;
  }

  public refresh({ title, rightTitle, data }: Props) {
    // Tell grid the whole component needs to be rendered
    if (!this.textCircleElement) return false;

    this.textCircleElement.textContent = title(data);

    if (this.rightTextElement && rightTitle) {
      this.rightTextElement.textContent = rightTitle(data);
    }

    // Tell grid we rerendered successfully
    return true;
  }
}
