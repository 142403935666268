import { useMemo } from 'react';

import getResourcesUrl from 'core/functions/getResourcesUrl';
import { ApiQueryStatus } from 'core/reducer';

import { ApiQueryOptions, useApiCall } from '../useApiCall';

interface UseResource<T = unknown> {
  data: undefined | T;
  isLoading: boolean;
  isError: boolean;
  status: ApiQueryStatus;
  error?: string | Error;
  reload: (force?: boolean) => void;
}

/**
 * Load Resource from API
 */
function useResource<T = unknown>(
  resource: undefined | string,
  options?: ApiQueryOptions
): UseResource<T> {
  const { data, error, status, dispatch } = useApiCall<T>(resource, {
    autoload: true,
    cache: true,
    ...(options || {}),
  });

  return useMemo(
    () => ({
      reload: (force: boolean = true) => dispatch({ cache: !force }),
      isError: !!error,
      isLoading: status === ApiQueryStatus.LOADING,
      data,
      status,
      error,
    }),
    [data, dispatch, error, status]
  );
}

export default useResource;

export interface ResourceWithCounts<T> {
  data: T[];
  filtered: number;
  total: number | null;
}

export function useResourceWithCounts<T = Record<string, unknown>>(
  resource?: string,
  options?: ApiQueryOptions
): UseResource<ResourceWithCounts<T>> {
  const url = resource
    ? resource + `${resource.includes('?') ? '&' : '?'}withCounts=true`
    : undefined;

  return useResource<ResourceWithCounts<T>>(url, { autoload: true, ...(options || {}) });
}

/**
 * Use Api Resource List with default limit/offset (in future we can add pagination)
 */
export function useResources<T = unknown>(
  resource: undefined | string,
  options?: ApiQueryOptions
): UseResource<T[]> {
  const { data, error, status, dispatch } = useApiCall<T[]>(resource, {
    url: resource && getResourcesUrl(resource, options?.params),
    cache: true,
    autoload: true,
    ...(options || {}),
  });

  return useMemo(
    () => ({
      reload: (force: boolean = true) => dispatch({ cache: !force }),
      isError: !!error,
      isLoading: status === ApiQueryStatus.LOADING,
      data,
      status,
    }),
    [data, dispatch, error, status]
  );
}
