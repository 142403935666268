import { FC, ReactNode } from 'react';
import { Link as RouterLink } from 'react-router-dom';

import { StyledLink } from './styled';

export interface LinkProps {
  onClick?: () => void;
  href?: string;
  to?: string;
  children?: ReactNode;
}

const Link: FC<LinkProps> = ({ children, onClick, to, ...rest }) => {
  if (!to) {
    return (
      <StyledLink {...rest} onClick={onClick} href={rest.href || '#'}>
        {children}
      </StyledLink>
    );
  }
  return (
    <StyledLink as={RouterLink} to={to} {...rest}>
      {children}
    </StyledLink>
  );
};

export default Link;
