import { useCallback } from 'react';

import Analysis from 'lists/models/Analysis';

import { OtherAnalyseInput } from './BloodFields/OtherBloodAnalyses';
import useAnalysesFieldsSchema, { AnalysesFieldsData } from './useAnalysesFieldsSchema';

interface DataWithAnalyses {
  urineAnalyses: Analysis[];
  bloodAnalyses: Analysis[];
}

const useAnalysesInputMapping = (): ((data: DataWithAnalyses) => AnalysesFieldsData) => {
  const schema = useAnalysesFieldsSchema('');

  return useCallback(
    (data) => {
      const otherUrineAnalyses: OtherAnalyseInput[] = ((data && data.urineAnalyses) || [])
        .filter((it: Analysis) => it.code.startsWith('ADT'))
        .map((it) => ({ id: it.id, remarks: it.otherAnalysisRemarks || null }));

      const otherBloodAnalyses: OtherAnalyseInput[] = ((data && data.bloodAnalyses) || [])
        .filter((it) => it.code.startsWith('ADT'))
        .map((it) => ({ id: it.id, remarks: it.otherAnalysisRemarks || null }));

      const inputData = {
        ...data,
        urineAnalyses: ((data && data.urineAnalyses) || [])
          .filter((it) => !it.code.startsWith('ADT'))
          .map((it: Analysis) => it.id) as Array<number | 'other'>,
        bloodAnalyses: ((data && data.bloodAnalyses) || [])
          .filter((it) => !it.code.startsWith('ADT'))
          .map((it: Analysis) => it.id) as Array<number | 'other'>,
        otherBloodAnalyses,
        otherUrineAnalyses,
      };

      if (inputData.otherUrineAnalyses.length > 0) {
        inputData.urineAnalyses.push('other');
      }

      if (inputData.otherBloodAnalyses.length > 0) {
        inputData.bloodAnalyses.push('other');
      }

      const formData = schema.cast(inputData, { stripUnknown: true });

      return formData as AnalysesFieldsData;
    },
    [schema]
  );
};

export default useAnalysesInputMapping;
