import { ComponentProps, useMemo } from 'react';

import DropDown, { SimpleOption } from 'core/components/DropDown';

type DropDownProps = ComponentProps<typeof DropDown>;

/**
 * Groups selected options - by default at the top
 *
 * Change in the value is reflected on options that are groupped.
 * DOES NOT WORK with GROUPS (suboptions)
 *
 * @param options             Options that would be passed to DropDown options
 * @param value               Value that would be passed to DropDown value
 * @param config.getPosition  Optional callback that gets options and can determine where is selection inserted
 * @param config.getIgnored   Optional callback that gets options and can determine which indices won't be moved
 * @return Options with grouped selection that are usable on DropDown component
 */
export function groupSelection(
  options: DropDownProps['options'],
  value: DropDownProps['value'],
  config?: {
    getPosition?: (options: DropDownProps['options']) => number;
    getIgnored?: (options: DropDownProps['options']) => number[];
  }
) {
  const currentValue = Array.isArray(value) ? new Set(value) : value;
  if (currentValue === null) return options;

  const multipleChoices = Array.isArray(value);
  const selected: SimpleOption[] = [];
  const ignored = config?.getIgnored ? new Set(config.getIgnored(options)) : undefined;
  const processedOptions = options.filter((option, index) => {
    if (option === 'SEPARATOR' || ignored?.has(index)) return true;

    const isSelected = multipleChoices
      ? (currentValue as Set<unknown>).has(option.id)
      : currentValue === option.id;
    if (isSelected) {
      selected.push(option);
      return false;
    } else {
      return true;
    }
  });

  if (selected.length > 0) {
    if (config?.getPosition) {
      processedOptions.splice(config?.getPosition(processedOptions), 0, ...selected, 'SEPARATOR');
    } else {
      processedOptions.unshift(...selected, 'SEPARATOR');
    }
  }

  return processedOptions;
}

/**
 * Groups selected options - by default at the top
 *
 * Change in the value is reflected on options that are groupped.
 * Make sure to memoize arguments so the options are correctly memoized as well.
 * DOES NOT WORK with GROUPS (suboptions)
 *
 * @param options             Options that would be passed to DropDown options
 * @param value               Value that would be passed to DropDown value
 * @param config.getPosition  Optional callback that gets options and can determine where is selection inserted
 * @param config.getIgnored   Optional callback that gets options and can determine which indices won't be moved
 * @return Options with grouped selection that are usable on DropDown component
 */
const useDropDownSelectionGrouping = (
  options: DropDownProps['options'],
  value: DropDownProps['value'],
  config?: {
    getPosition?: (options: DropDownProps['options']) => number;
    getIgnored?: (options: DropDownProps['options']) => number[];
  }
) => {
  const { getPosition, getIgnored } = config || {};
  return useMemo(
    () => groupSelection(options, value, { getPosition, getIgnored }),
    [options, value, getPosition, getIgnored]
  );
};

export default useDropDownSelectionGrouping;
