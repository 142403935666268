import { toast } from 'react-toastify';

import { CopyToClipboardAction } from 'core/actions';
import { t } from 'core/i18n';

export default function* copyToClipboard(action: CopyToClipboardAction) {
  const { clipText } = action.payload;

  try {
    yield navigator.clipboard.writeText(clipText);

    toast.success(t('Copied to clipboard'));
  } catch (error) {
    toast.error(t('Unable to copy to clipboard'));
  }
}
