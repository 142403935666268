import { FC, FocusEvent, useRef, useState } from 'react';

import ChipsField from 'core/components/ChipsField';
import useFieldAutocomplete from 'core/hooks/useFieldAutocomplete';

export interface Props {
  onChange: (value: null | string[]) => void;
  value: null | string[];
  chipErrors?: Array<string | null>;
  error?: boolean;
  disabled?: boolean;
  label: string;
  id: string;
  /**
   * Endpoint without trailing or leading slash
   */
  endpoint: string;
  name: string;
  onBlur?: (event: FocusEvent<HTMLInputElement>) => void;
}

/**
 * ChipsField with autocomplete functionality
 *
 * Provides only a subset of functionality of ChipsField
 */
const AutocompleteChipsField: FC<Props> = ({
  id,
  endpoint,
  onChange,
  onBlur,
  value,
  label,
  chipErrors,
  error,
  disabled,
  name,
}) => {
  const [text, setText] = useState('');
  const inputRef = useRef<HTMLInputElement>(null);
  const { props, popover, handleOnBlur } = useFieldAutocomplete(
    id,
    inputRef,
    endpoint,
    (selection) => {
      onChange([...(value || []), selection]);
      setText('');
    },
    onBlur,
    text,
    true
  );

  return (
    <>
      <ChipsField
        inputRef={inputRef}
        onBlur={handleOnBlur}
        label={label}
        name={name}
        onChange={onChange}
        onTextChange={setText}
        value={value}
        textValue={text}
        chipErrors={chipErrors}
        error={error}
        disabled={disabled}
        confirmation={false}
        {...props}
      />

      {popover}
    </>
  );
};

export default AutocompleteChipsField;
