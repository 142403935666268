import { useTranslation } from 'react-i18next';

import { entities } from 'app/entity';
import { getFileEntityKey } from 'app/hooks/useFileEntityLookups';
import Event, { EventStatus } from 'app/models/Event';
import {
  Notification,
  NotificationTypeEnum,
} from 'core/components/Notifications/RenderNotification';

export default function useWatcherRemoved() {
  const { t } = useTranslation();

  return (e: Event): Notification => {
    const entityKey = getFileEntityKey(e.payload.entityName); // Missions => mission
    const entity = entities[entityKey];
    const entityName = entity.name(t);
    const entityIdentifier =
      (e.payload.identifier && `${e.payload.identifier}`.trim()) || e.payload.entityId;

    const map = {
      [EventStatus.CREATED]: {
        primary: t('{{entityName}} {{entityIdentifier}} is no longer watched', {
          entityName,
          entityIdentifier,
        }),
        secondary: t('You no longer watch {{entityName}} {{entityIdentifier}}', {
          entityIdentifier,
          entityName,
        }),

        updatedAt: e.modifiedAt || e.createdAt,
        actions: [
          {
            key: 'open',
            text: t('Open {{entityName}}', {
              entityName,
            }),
            action: entity.urls('').detail(e.payload.entityId),
          },
        ],
        icon: 'visibility_off',
        inProgress: false,
        read: e.isRead,
        type: NotificationTypeEnum.SUCCESS,
        id: e.id,
      },
    };

    return map[EventStatus.CREATED];
  };
}
