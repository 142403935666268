function formatNumberAbbreviated(num: number) {
  const abbreviations = ['', 'k', 'm', 'b', 't'];
  let absNum = Math.abs(num);

  let index = 0;
  while (absNum >= 1000 && index < abbreviations.length - 1) {
    absNum /= 1000;
    index++;
  }

  const formattedNum = num >= 0 ? absNum : -absNum;
  const roundedNum = Math.round(formattedNum * 10) / 10; // Round to one decimal place

  return `${roundedNum}${abbreviations[index]}`;
}

export default formatNumberAbbreviated;
