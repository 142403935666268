import { FC, useCallback, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch } from 'react-redux';

import Button from 'core/components/Button';
import Modal from 'core/components/Modal';
import { useDetail } from 'core/containers/FormDetailPage/DetailContext';
import { useSetModal } from 'core/hooks/useModal';
import { applyMissionToTests } from 'planning/actions';

import { MissionModal, useMissionModal } from '../hooks/useMissionModal';

interface Props {
  patchTests?: any;
}

const ApplyTestChangesModal: FC<Props> = ({ patchTests }) => {
  const { entitySaveInProgress, id: missionId } = useDetail();
  const openModal = useMissionModal(MissionModal.PATCH_TESTS);
  const [isLoading, setLoading] = useState(false);
  const setModal = useSetModal();
  const { t } = useTranslation();
  const dispatch = useDispatch();

  const closeModal = () => {
    !entitySaveInProgress && !isLoading && setModal();
  };

  const dispatchPatchTests = useCallback(
    (missionId: number, newData: any, prevData: any, successCallback: () => void) =>
      dispatch(applyMissionToTests([missionId], newData, prevData, [], successCallback)),
    [dispatch]
  );

  return (
    <Modal
      ariaLabel={t('Changes application confirmation dialog')}
      onClose={() => closeModal()}
      onCancel={closeModal}
      open={openModal}
      confirmButton={
        <Button
          disabled={isLoading || entitySaveInProgress}
          onClick={() => {
            if (missionId && patchTests) {
              setLoading(true);
              dispatchPatchTests(missionId, patchTests.newData, patchTests.prevData, () => {
                setLoading(false);
                setModal();
              });
            }
          }}
          text={t('Confirm')}
        />
      }
    >
      {t('Do you want to apply the same changes to assigned Tests?')}
    </Modal>
  );
};

export default ApplyTestChangesModal;
