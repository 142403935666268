import User from 'app/models/User';
import { ApiCallSuccessAction, ApiCallErrorAction } from 'core/actions';
import { Role } from 'core/models/Role';
import { AthleteDatagrid } from 'personnel/models/Athlete';
import { ProfileAvailability } from 'personnel/models/ProfileAvailability';

import { PersonnelActions } from './actions';

export interface PersonnelState {
  athleteRevisions?: {
    revisions?: AthleteDatagrid[];
    mastersId: number;
    loading: boolean;
  };
  userDetail?: { loading: boolean; data: null | User };
  editingUserInProgress?: boolean;
  deleteUserInProgress?: boolean;
  userRoles?: Role[];
  setAthleteMasterInProgress?: boolean;
  athleteMasters?: {
    loading: boolean;
    list: null | AthleteDatagrid[];
  };
  inviteUsersPending?: boolean;
  activeAthletes?: {
    loading: boolean;
    list?: AthleteDatagrid[];
  };
  dialogSaveInProgress?: boolean;
  profileAvailabilities?: ProfileAvailability[];
}

const initialState: PersonnelState = {};

export default function reducer(
  state = initialState,
  action:
    | PersonnelActions
    | ApiCallSuccessAction<'LOAD_ROLES_SUCCESS'>
    | ApiCallErrorAction<'LOAD_ATHLETE_REVISIONS_ERROR'>
    | ApiCallSuccessAction<'LOAD_ATHLETE_REVISIONS_SUCCESS'>
    | ApiCallSuccessAction<'SET_ATHLETE_MASTER_SUCCESS'>
    | ApiCallErrorAction<'SET_ATHLETE_MASTER_ERROR'>
    | ApiCallSuccessAction<'LOAD_ATHLETE_MASTERS_SUCCESS'>
    | ApiCallErrorAction<'LOAD_ATHLETE_MASTERS_ERROR'>
    | ApiCallSuccessAction<'INVITE_USERS_SUCCESS'>
    | ApiCallErrorAction<'INVITE_USERS_ERROR'>
    | ApiCallSuccessAction<'SAVE_BLACKLIST_RECORD_SUCCESS'>
    | ApiCallErrorAction<'SAVE_BLACKLIST_RECORD_ERROR'>
    | ApiCallSuccessAction<'FETCH_AVAILABILITIES_SUCCESS'>
    | ApiCallErrorAction<'FETCH_AVAILABILITIES_SUCCESS'>
    | ApiCallSuccessAction<'FETCH_AVAILABILITIES_ERROR'>
    | ApiCallSuccessAction<'SAVE_AVAILABILITY_SUCCESS'>
    | ApiCallErrorAction<'SAVE_AVAILABILITY_ERROR'>
): PersonnelState {
  switch (action.type) {
    case 'INVITE_USERS':
      return {
        ...state,
        inviteUsersPending: true,
      };

    case 'INVITE_USERS_SUCCESS':
      return {
        ...state,
        inviteUsersPending: false,
      };

    case 'INVITE_USERS_ERROR':
      return {
        ...state,
        inviteUsersPending: false,
      };

    case 'LOAD_ATHLETE_REVISIONS':
      return {
        ...state,
        athleteRevisions: {
          mastersId: action.payload.mastersId,
          loading: true,
        },
      };
    case 'LOAD_ATHLETE_REVISIONS_SUCCESS':
      return {
        ...state,
        athleteRevisions: {
          mastersId: (state.athleteRevisions && state.athleteRevisions.mastersId) || 0,
          revisions: action.payload.response.data,
          loading: false,
        },
      };

    case 'SET_ATHLETE_MASTER':
      return {
        ...state,
        setAthleteMasterInProgress: true,
      };

    case 'SET_ATHLETE_MASTER_ERROR':
    case 'SET_ATHLETE_MASTER_SUCCESS':
      return {
        ...state,
        setAthleteMasterInProgress: false,
      };

    case 'LOAD_ATHLETE_MASTERS':
      return {
        ...state,
        athleteMasters: { loading: true, list: null },
      };

    case 'LOAD_ATHLETE_MASTERS_SUCCESS':
      return {
        ...state,
        athleteMasters: {
          loading: false,
          list: action.payload.response.data.filter((i: AthleteDatagrid) => i.mastersId === null),
        },
      };

    case 'LOAD_ATHLETE_MASTERS_ERROR':
      return {
        ...state,
        athleteMasters: { loading: false, list: null },
      };

    case 'SAVE_BLACKLIST_RECORD':
      return {
        ...state,
        dialogSaveInProgress: true,
      };

    case 'SAVE_BLACKLIST_RECORD_SUCCESS':
    case 'SAVE_BLACKLIST_RECORD_ERROR':
      return {
        ...state,
        dialogSaveInProgress: false,
      };

    case 'FETCH_AVAILABILITIES':
    case 'UNLOAD_AVAILABILITIES':
      return { ...state, profileAvailabilities: undefined };

    case 'FETCH_AVAILABILITIES_SUCCESS':
      return {
        ...state,
        profileAvailabilities: action.payload.response.data,
      };

    default:
      return state;
  }
}
