import { GridApi, IDatasource, IGetRowsParams } from 'ag-grid-community';
import merge from 'lodash/merge';
import { MutableRefObject, useCallback, useRef, useState } from 'react';
import { useDispatch } from 'react-redux';
import { v1 as uuid } from 'uuid';

import { fetchDataGridData } from 'core/actions';

import { FilterValue, Props, SelectedRows } from '../props';

export const getParamsFromFilters = <T>(
  defaultFilters: Props<T>['defaultFilters'],
  filters: Props<T>['filters'],
  values: { [key: string]: FilterValue },
  defaultValues: { [key: string]: FilterValue }
) =>
  ({
    ...(defaultFilters || {}),
    ...Object.entries(filters || {}).reduce((acc, [id]) => {
      const filter = filters?.[id];
      if (!filter || filter.type === 'delimiter') return acc;
      return merge(
        acc,
        filter.applyToParams(values[id] !== undefined ? values[id] : defaultValues[id])
      );
    }, {}),
  }) as { [key: string]: any };

const useDataSource = <T>(
  endpoint: string,
  selectedRows: SelectedRows,
  updateDuplicateRows: (
    data: {
      [key: string]: unknown;
    }[]
  ) => void,
  agGridApiRef: MutableRefObject<GridApi | undefined>,
  defaultValues: { [key: string]: FilterValue },
  defaultFilters?: { [key: string]: FilterValue },
  rowsReducer?: <U>(rows: U[]) => T[],
  onDataReceived?: (data: T[]) => void
) => {
  const dispatch = useDispatch();

  const [loading, setLoading] = useState(true);
  const [counts, setCounts] = useState<{
    filtered: number;
    total: number | null;
  }>();

  // We keep count of started requests that doesn't influence rendering
  const runningRequests = useRef(0);

  const getDataSource = useCallback<
    (
      filters: Props<unknown>['filters'],
      values: { [key: string]: FilterValue },
      sortState: [string, 'asc' | 'desc'] | null
    ) => IDatasource
  >(
    (filters, values, sortState) => {
      const additionalParams = getParamsFromFilters(defaultFilters, filters, values, defaultValues);

      if (sortState) {
        // We must override sort and order based on our state
        additionalParams.order = sortState[0];
        additionalParams.sort = sortState[1];
      }

      const increaseRequests = () => runningRequests.current++;
      const decreaseRequests = () => runningRequests.current--;
      const noMoreRequests = () => runningRequests.current === 0;
      const refreshHeader = () => agGridApiRef.current?.refreshHeader();
      const selectAllNew = () => {
        const selectAll = selectedRows === 'ALL';
        selectAll &&
          // .selectAll() is not available in infinite mode
          // We can't select nodes that are not yet loaded, that's why we check for node.id
          agGridApiRef.current?.forEachNode((node) => node.id && node.setSelected(true));
        return selectAll;
      };

      // We have to do the initial loading indication ourselves
      // because ag-grid won't do it - IconCellRenderer is not for infinite mode
      // https://www.ag-grid.com/javascript-grid-loading-cell-renderer/
      return {
        getRows(params: IGetRowsParams) {
          const callback = params.successCallback;
          const failCallback = params.failCallback;
          params.failCallback = () => {
            setCounts(undefined);

            agGridApiRef.current && failCallback && failCallback();

            decreaseRequests();

            if (noMoreRequests()) {
              setLoading(false);
            }
          };

          // @ts-ignore We have to override this but still pass it to ag-grid, so typing is tricky and not worth it
          params.successCallback = (
            data: Array<{ [key: string]: unknown }>,
            filtered: number,
            total: number | null
          ) => {
            const rows = rowsReducer ? rowsReducer(data) : data;

            onDataReceived && onDataReceived(data as T[]);

            setCounts({ filtered, total });

            // Update duplicate rows info before rendering
            updateDuplicateRows(rows as Array<{ [key: string]: unknown }>);

            agGridApiRef.current && callback && callback(rows, filtered);

            decreaseRequests();

            if (params.startRow === 0 && noMoreRequests()) {
              setLoading(false);
            }

            // Select all new if currently all should be selected and refresh header
            if (selectAllNew()) {
              refreshHeader();
            }
          };

          // Trigger loading overlay only during first request (infinite loading handled differently)
          if (params.startRow === 0) {
            setLoading(true);
          }
          increaseRequests();
          dispatch(fetchDataGridData(endpoint, params, additionalParams, uuid()));
        },
      };
    },
    [
      agGridApiRef,
      defaultFilters,
      defaultValues,
      dispatch,
      endpoint,
      rowsReducer,
      selectedRows,
      updateDuplicateRows,
      onDataReceived,
    ]
  );

  return {
    loading,
    counts,
    getDataSource,
  };
};

export default useDataSource;
