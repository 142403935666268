import { FC } from 'react';
import { useTranslation } from 'react-i18next';

import { Option } from 'core/components/DropDown';
import Field from 'core/components/Form/Field';
import useFormFieldValue from 'core/components/Form/useFormFieldValue';
import FormDropDown from 'core/components/FormDropDown';
import FormTextfield from 'core/components/FormTextfield';
import { Cell as GridCell } from 'core/components/Grid';

import { Row, Cell } from '../styled';

const OtherUrineRow: FC<{ name: string; options: Option[] }> = ({ options, name }) => {
  const { t } = useTranslation();

  const value = useFormFieldValue('otherUrineAnalyses');

  if (!value || value.length <= 0) {
    return null;
  }

  return (
    <GridCell desktopColumns={12} tabletColumns={8} phoneColumns={4}>
      <Row key={'other-urine-analysis'}>
        <Cell>
          <Field
            viewMode={options.length === 1 || undefined}
            label={t('Analyse Type')}
            component={FormDropDown}
            name={`${name}[0].id`}
            options={options}
            fast={false}
            required
            single
          />
        </Cell>
        <Cell>
          <Field
            name={`${name}[0].remarks`}
            component={FormTextfield}
            label={t('Remarks')}
            fast={false}
          />
        </Cell>
      </Row>
    </GridCell>
  );
};

export default OtherUrineRow;
