import { FC, useEffect, useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch } from 'react-redux';
import { Link, useLocation } from 'react-router-dom';

import Profile from 'app/models/Profile';
import { urls } from 'app/urls';
import { getNotifications, loginAsUser } from 'core/actions';
import { Item } from 'core/components/Menu';
import NotificationsIcon from 'core/components/NotificationsIcon';
import Avatar from 'core/containers/Avatar';
import history from 'core/history';

import Name from './Name';
import { Wrapper } from './styled';

interface Props {
  user?: Profile;
  onLogout: () => void;
  collapse: boolean;
  openNotifications: boolean;
  setOpenNotifications: (open: boolean) => void;
}

const Account: FC<Props> = ({
  onLogout,
  user,
  collapse,
  openNotifications,
  setOpenNotifications,
}) => {
  const originalUser = user?.originUser;
  const location = useLocation();
  const { t } = useTranslation();
  const dispatch = useDispatch();

  const items: Array<Item | 'SEPARATOR'> = [
    {
      key: 'account',
      text: t('Account'),
      icon: 'account_circle',
      onClick: () => history.push(urls.account),
    },
    { key: 'logout', text: t('Log Out'), icon: 'logout', onClick: onLogout },
  ];

  if (originalUser) {
    items.push('SEPARATOR');

    items.push({
      text: t('Switch Back to {{name}}', { name: originalUser.fullName }),
      onClick: () => dispatch(loginAsUser(originalUser.id)),
      key: 'switch_login_as_back',
      icon: 'supervisor_account',
    });
  }

  useEffect(() => {
    if (openNotifications) {
      dispatch(getNotifications());
    }
  }, [openNotifications, dispatch]);

  // Close sidebar when route is changed = action from notification was provided
  // its because it is pretty hard to use prop-drilling to send closeDrawer() ref to each notification type renderer
  // and it could be pretty messy / bug prone, to use this closeDrawer() callback in each action
  // in case of any future problem I suggest to use context maybe
  useEffect(() => {
    setOpenNotifications(false);
  }, [location, setOpenNotifications]);

  const link = useMemo(
    () => (
      <Link to={urls.account}>
        <Avatar
          endpoint="profile/image"
          storageKey={user?.avatarStorageKey}
          title={user?.fullName || undefined}
          tooltipPlacement={collapse ? 'right' : 'bottom'}
        />
        <Name collapse={collapse} name={user?.firstName || user?.email} role={user?.role?.name} />
      </Link>
    ),
    [
      collapse,
      user?.avatarStorageKey,
      user?.email,
      user?.firstName,
      user?.fullName,
      user?.role?.name,
    ]
  );

  return (
    <>
      <Wrapper data-cy="account-menu-item" collapse={collapse}>
        {link}
        {!collapse && (
          <NotificationsIcon expand={false} onOpen={() => setOpenNotifications(true)} />
        )}
      </Wrapper>
    </>
  );
};

export default Account;
