import { useCallback } from 'react';

import { FilterValue } from 'core/containers/DataGrid';
import { AuthorityGrid } from 'lists/models/Authority';

const useAuthoritiesOptionsReducer = () => {
  return useCallback((list: AuthorityGrid[], selectedAuthoritiesId?: FilterValue) => {
    return list
      .filter((authority) => authority.active || selectedAuthoritiesId === authority.id)
      .map((authority) => ({
        id: authority.id,
        name: authority.shortName,
        secondary: authority.fullName,
      }));
  }, []);
};

export default useAuthoritiesOptionsReducer;
