import * as Sentry from '@sentry/browser';
import { useCallback, useMemo } from 'react';

import useAthleteImport from 'app/components/notificationTemplates/useAthleteImport';
import useCostItemsImport from 'app/components/notificationTemplates/useCostItemsImport';
import useCreateReport from 'app/components/notificationTemplates/useCreateReport';
import useCreateTdpFiles from 'app/components/notificationTemplates/useCreateTdpFiles';
import useDcoInvoiceReview from 'app/components/notificationTemplates/useDcoInvoiceReview';
import useMissionTeamMemberInvitated from 'app/components/notificationTemplates/useMissionTeamMemberInvitated';
import useMissionTeamMemberInvitationUpdate from 'app/components/notificationTemplates/useMissionTeamMemberInvitationUpdate';
import useOrderImport from 'app/components/notificationTemplates/useOrderImport';
import useOrderResult from 'app/components/notificationTemplates/useOrderResult';
import useSendToADAMS from 'app/components/notificationTemplates/useSendToADAMS';
import useTestTeamMemberInvitated from 'app/components/notificationTemplates/useTestTeamMemberInvitated';
import useTestTeamMemberInvitationUpdate from 'app/components/notificationTemplates/useTestTeamMemberInvitationUpdate';
import useTestUAReportReview from 'app/components/notificationTemplates/useTestUAReportReview';
import Event, { EventType } from 'app/models/Event';
import { Notification } from 'core/components/Notifications/RenderNotification';
import useBulkDownload from 'core/components/Notifications/templates/useBulkDownload';
import useCreateClient from 'core/components/Notifications/templates/useCreateClient';
import useWatcherAdded from 'core/components/Notifications/templates/useWatcherAdded';
import useWatcherRemoved from 'core/components/Notifications/templates/useWatcherRemoved';

/**
 * Decorate list of events for rendering
 */
const useNotificationsReducer = () => {
  const getAthleteImportNotification = useAthleteImport();
  const getCreateClientNotification = useCreateClient();
  const getWatcherAddedNotification = useWatcherAdded();
  const getWatcherRemovedNotification = useWatcherRemoved();

  const getCreateReportNotification = useCreateReport();
  const getCreateTdpFilesNotification = useCreateTdpFiles();
  const getSendToADAMSNotification = useSendToADAMS();
  const getOrderImportNotification = useOrderImport();
  const getOrderResultNotification = useOrderResult();
  const getUAReportReview = useTestUAReportReview();
  const getBulkDownloadNotification = useBulkDownload();
  const getCostItemsImportNotification = useCostItemsImport();
  const getDcoInvoiceReview = useDcoInvoiceReview();
  const getMissionTeamMemberInvitationUpdate = useMissionTeamMemberInvitationUpdate();
  const getTestTeamMemberInvitationUpdate = useTestTeamMemberInvitationUpdate();
  const getMissionTeamMemberInvitated = useMissionTeamMemberInvitated();
  const getTestTeamMemberInvitated = useTestTeamMemberInvitated();

  const map = useMemo<{ [key in EventType]: (e: Event) => Notification }>(
    () => ({
      [EventType.SEND_TO_ADAMS]: getSendToADAMSNotification,
      [EventType.ATHLETE_IMPORT]: getAthleteImportNotification,
      [EventType.CREATE_CLIENT]: getCreateClientNotification,

      [EventType.CREATE_REPORT]: getCreateReportNotification,
      [EventType.GENERATE_TDP_FILE]: getCreateTdpFilesNotification,

      [EventType.ORDERS_RESULT]: getOrderResultNotification,
      [EventType.ORDERS_IMPORT]: getOrderImportNotification,

      [EventType.TEST_UA_REPORT_REVIEW]: getUAReportReview,

      [EventType.BULK_DOWNLOAD]: getBulkDownloadNotification,

      [EventType.ORDER_ITEMS_IMPORT]: getCostItemsImportNotification,

      [EventType.DCO_INVOICE]: getDcoInvoiceReview,

      [EventType.MISSION_TEAM_INVITE_UPDATE]: getMissionTeamMemberInvitationUpdate,
      [EventType.TEST_TEAM_INVITE_UPDATE]: getTestTeamMemberInvitationUpdate,

      [EventType.MISSION_TEAM_MEMBER_INVITED]: getMissionTeamMemberInvitated,
      [EventType.TEST_TEAM_MEMBER_INVITED]: getTestTeamMemberInvitated,

      [EventType.WATCHER_ADDED]: getWatcherAddedNotification,
      [EventType.WATCHER_REMOVED]: getWatcherRemovedNotification,
    }),
    [
      getSendToADAMSNotification,
      getAthleteImportNotification,
      getCreateClientNotification,
      getCreateReportNotification,
      getCreateTdpFilesNotification,
      getOrderResultNotification,
      getOrderImportNotification,
      getUAReportReview,
      getBulkDownloadNotification,
      getCostItemsImportNotification,
      getDcoInvoiceReview,
      getMissionTeamMemberInvitationUpdate,
      getTestTeamMemberInvitationUpdate,
      getMissionTeamMemberInvitated,
      getTestTeamMemberInvitated,
      getWatcherAddedNotification,
      getWatcherRemovedNotification,
    ]
  );

  return useCallback(
    ([inP, oE]: Notification[][], e: Event): Notification[][] => {
      if (e.type in map) {
        const n = map[e.type](e);
        (n.inProgress ? inP : oE).push(n);
      } else {
        // eslint-disable-next-line no-console
        console.error(`Unknown event type found ${e.type} (created at ${e.createdAt})`);
        Sentry.captureException(
          new Error(`Unknown event type found ${e.type} (created at ${e.createdAt})`)
        );
      }

      return [inP, oE];
    },
    [map]
  );
};

export default useNotificationsReducer;
