import BaseModel from 'core/models/Base';

export enum DisabilitiesId {
  AMPUTEE = 1,
  CEREBRAL_PALSY = 2,
  INTELLECTUAL_DISABILITY = 3,
  SPINAL_INJURY = 4,
  VISUAL_IMPAIRMENT = 5,
  OTHER = 6,
}

export default interface Disability extends BaseModel {
  name: string;
}
