import { useMemo } from 'react';

import getResourcesUrl from 'core/functions/getResourcesUrl';

const useUsersUrl = () => {
  return useMemo(
    () => ({
      list: (options?: { clientsId: number }) => getResourcesUrl('users', options),
    }),
    []
  );
};

export default useUsersUrl;
