const Modoc = () => (
  <svg x="0px" y="0px" width="1050.139px" height="262.628px" viewBox="0 0 1050.139 262.628">
    <g>
      <g transform="matrix(1 0 0 1 312.9802 203.4409)">
        <path
          fill="var(--logo-color)"
          d="M83.679-57.577c1.088,2.048,2.079,4.19,2.975,6.429c0.96,2.176,1.888,4.383,2.783,6.622 c0.896-2.304,1.823-4.574,2.782-6.813c0.96-2.238,1.983-4.383,3.071-6.43l41.071-76.865c0.512-0.959,1.024-1.727,1.536-2.303 c0.575-0.576,1.184-0.991,1.823-1.247c0.703-0.256,1.471-0.416,2.303-0.48s1.823-0.096,2.975-0.096h19.48V0h-22.743v-89.628 c0-1.664,0.032-3.487,0.097-5.47c0.128-1.983,0.287-3.999,0.479-6.046l-41.936,78.689c-0.96,1.791-2.207,3.199-3.742,4.223 c-1.536,0.959-3.327,1.439-5.374,1.439h-3.551c-2.047,0-3.838-0.48-5.374-1.439c-1.535-1.023-2.782-2.432-3.742-4.223 l-42.512-78.977c0.257,2.111,0.416,4.19,0.48,6.238c0.128,1.983,0.191,3.838,0.191,5.566V0H14.01v-138.761h19.48 c1.152,0,2.144,0.032,2.975,0.096c0.832,0.064,1.568,0.224,2.208,0.48c0.703,0.256,1.343,0.671,1.919,1.247 c0.576,0.576,1.119,1.344,1.631,2.303L83.679-57.577L83.679-57.577z"
        />
      </g>
      <g transform="matrix(1 0 0 1 487.6292 203.4409)">
        <path
          fill="var(--logo-color)"
          d="M147.11-69.381c0,10.172-1.695,19.608-5.086,28.309 c-3.392,8.637-8.157,16.122-14.299,22.455c-6.142,6.334-13.53,11.292-22.167,14.875c-8.637,3.518-18.201,5.277-28.692,5.277 c-10.492,0-20.089-1.76-28.789-5.277c-8.637-3.583-16.058-8.541-22.263-14.875c-6.142-6.333-10.908-13.818-14.299-22.455 c-3.391-8.7-5.086-18.137-5.086-28.309c0-10.171,1.695-19.576,5.086-28.212c3.391-8.701,8.157-16.218,14.299-22.551 c6.205-6.333,13.626-11.259,22.263-14.778c8.7-3.583,18.297-5.374,28.789-5.374c10.491,0,20.056,1.791,28.692,5.374 c8.637,3.583,16.025,8.541,22.167,14.874c6.142,6.27,10.907,13.754,14.299,22.455C145.415-88.957,147.11-79.552,147.11-69.381z M120.625-69.381c0-7.613-1.024-14.426-3.071-20.439c-1.983-6.078-4.862-11.228-8.637-15.45 c-3.774-4.223-8.381-7.453-13.818-9.692c-5.374-2.239-11.451-3.359-18.232-3.359c-6.782,0-12.892,1.12-18.329,3.359 s-10.076,5.47-13.914,9.692c-3.775,4.222-6.686,9.372-8.733,15.45c-2.047,6.014-3.07,12.827-3.07,20.439 c0,7.613,1.023,14.459,3.07,20.536c2.048,6.014,4.958,11.132,8.733,15.354c3.838,4.158,8.477,7.356,13.914,9.596 s11.547,3.359,18.329,3.359c6.781,0,12.858-1.12,18.232-3.359c5.438-2.239,10.044-5.438,13.818-9.596 c3.774-4.223,6.653-9.341,8.637-15.354C119.6-54.922,120.625-61.768,120.625-69.381z"
        />
      </g>
      <g transform="matrix(1 0 0 1 633.3962 203.4409)">
        <path
          fill="var(--logo-color)"
          d="M137.226-69.381c0,10.172-1.695,19.513-5.086,28.021 c-3.391,8.508-8.157,15.834-14.299,21.975c-6.142,6.143-13.53,10.908-22.167,14.299S77.473,0,66.982,0H14.01v-138.761h52.972 c10.491,0,20.056,1.728,28.692,5.182c8.637,3.391,16.025,8.157,22.167,14.298c6.142,6.078,10.908,13.371,14.299,21.879 C135.531-88.893,137.226-79.552,137.226-69.381L137.226-69.381z M110.836-69.381c0-7.613-1.023-14.426-3.07-20.439 c-2.048-6.078-4.959-11.196-8.733-15.354c-3.774-4.223-8.38-7.453-13.818-9.692c-5.374-2.239-11.451-3.358-18.232-3.358H40.016 v97.69h26.966c6.781,0,12.858-1.12,18.232-3.359c5.438-2.239,10.044-5.438,13.818-9.596c3.774-4.223,6.686-9.341,8.733-15.354 C109.813-54.922,110.836-61.768,110.836-69.381z"
        />
        <path
          fill="var(--logo-color)"
          d="M288.678-69.381c0,10.172-1.695,19.608-5.086,28.309 c-3.391,8.637-8.157,16.122-14.299,22.455c-6.142,6.334-13.53,11.292-22.167,14.875c-8.637,3.518-18.2,5.277-28.692,5.277 s-20.088-1.76-28.789-5.277c-8.637-3.583-16.058-8.541-22.263-14.875c-6.142-6.333-10.908-13.818-14.299-22.455 c-3.391-8.7-5.086-18.137-5.086-28.309c0-10.171,1.695-19.576,5.086-28.212c3.391-8.701,8.157-16.218,14.299-22.551 c6.205-6.333,13.626-11.259,22.263-14.778c8.701-3.583,18.297-5.374,28.789-5.374s20.056,1.791,28.692,5.374 c8.637,3.583,16.025,8.541,22.167,14.874c6.142,6.27,10.908,13.754,14.299,22.455C286.983-88.957,288.678-79.552,288.678-69.381z  M262.193-69.381c0-7.613-1.023-14.426-3.071-20.439c-1.982-6.078-4.861-11.228-8.637-15.45c-3.774-4.223-8.38-7.453-13.818-9.692 c-5.374-2.239-11.451-3.359-18.232-3.359s-12.891,1.12-18.329,3.359c-5.438,2.239-10.076,5.47-13.914,9.692 c-3.774,4.222-6.686,9.372-8.732,15.45c-2.048,6.014-3.071,12.827-3.071,20.439c0,7.613,1.023,14.459,3.071,20.536 c2.047,6.014,4.958,11.132,8.732,15.354c3.838,4.158,8.477,7.356,13.914,9.596c5.438,2.239,11.548,3.359,18.329,3.359 s12.858-1.12,18.232-3.359c5.438-2.239,10.044-5.438,13.818-9.596c3.775-4.223,6.653-9.341,8.637-15.354 C261.168-54.922,262.193-61.768,262.193-69.381z"
        />
        <path
          fill="var(--logo-color)"
          d="M402.28-32.723c1.407,0,2.623,0.543,3.646,1.631l10.172,11.035 c-5.63,6.975-12.571,12.316-20.824,16.026c-8.188,3.71-18.041,5.565-29.557,5.565c-10.3,0-19.576-1.76-27.828-5.277 c-8.189-3.52-15.194-8.413-21.016-14.683c-5.822-6.27-10.301-13.755-13.436-22.455c-3.07-8.7-4.605-18.2-4.605-28.501 c0-10.427,1.727-19.959,5.182-28.596c3.455-8.701,8.316-16.186,14.586-22.455s13.755-11.132,22.455-14.586 c8.765-3.519,18.425-5.278,28.98-5.278c10.301,0,19.289,1.663,26.966,4.99c7.741,3.263,14.362,7.613,19.864,13.051l-8.637,11.995 c-0.512,0.768-1.184,1.439-2.016,2.015c-0.768,0.576-1.854,0.864-3.262,0.864c-1.472,0-2.976-0.576-4.511-1.728 s-3.486-2.399-5.854-3.742c-2.367-1.344-5.374-2.591-9.021-3.743c-3.583-1.152-8.157-1.728-13.723-1.728 c-6.525,0-12.539,1.152-18.041,3.455c-5.438,2.239-10.14,5.47-14.106,9.692c-3.902,4.222-6.973,9.372-9.212,15.45 c-2.176,6.014-3.263,12.795-3.263,20.344c0,7.805,1.087,14.746,3.263,20.824c2.239,6.078,5.246,11.195,9.021,15.354 s8.221,7.357,13.339,9.596c5.117,2.176,10.619,3.264,16.505,3.264c3.519,0,6.686-0.191,9.5-0.576 c2.879-0.384,5.502-0.992,7.869-1.823c2.431-0.831,4.702-1.888,6.813-3.167c2.175-1.344,4.318-2.975,6.43-4.894 c0.64-0.575,1.312-1.024,2.015-1.343C400.68-32.531,401.448-32.723,402.28-32.723z"
        />
      </g>
      <g>
        <path
          fill="var(--logo-color)"
          d="M125.972,109.095l-62.986,36.364L0,109.095v-72.73L62.986,0l62.986,36.364V109.095z"
        />
        <path
          fill="var(--logo-color)"
          d="M263.819,109.095l-62.986,36.364l-62.985-36.364v-72.73L200.833,0l62.986,36.364V109.095z"
        />
        <path
          fill="var(--logo-color)"
          d="M194.896,226.265l-62.986,36.363l-62.986-36.363v-72.73l62.986-36.364l62.986,36.364 V226.265z"
        />
      </g>
    </g>
  </svg>
);

export default Modoc;
