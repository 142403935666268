import { useFormikContext } from 'formik';
import { DateTime } from 'luxon';
import { useCallback, useState } from 'react';
import { useTranslation } from 'react-i18next';

import DummyField from 'core/components/DummyField';
import { FlexCell, FlexRow } from 'core/components/FlexUtils';
import Field from 'core/components/Form/Field';
import FormCalendarRangeInput from 'core/components/FormCalendarRangeInput';
import FormDateTimeField from 'core/components/FormDateTimeField';
import { ExplanationWrapper } from 'core/components/FormStyles';
import FormTimeInput from 'core/components/FormTimeInput';
import { Cell } from 'core/components/Grid';
import { FullRowCell, HalfRowCell } from 'core/components/GridCell';
import IconButton from 'core/components/IconButton';
import { useFormatDateTimeRange } from 'core/i18n/useFormatDateTime';
import DateRangeBuffer from 'planning/components/DateRangeBuffer';
import usePublishAtOffsetValue from 'planning/hooks/usePublishAtOffsetValue';

interface RequiredFormData {
  dateRange: {
    from: Date | null;
    to: Date | null;
  } | null;
  dateFromBuffer: number | null;
  dateToBuffer: number | null;
  plannedAt: Date | null;
  publishAt?: Date | null;
}

interface Props {
  originEntity: string;
  originRange?: [Date, Date];
  disablePublishAt?: boolean;
  disableExpanding?: boolean;
}

const ScheduleDateTimeFields = <TFormData extends RequiredFormData>({
  disablePublishAt = false,
  disableExpanding = false,
  originEntity,
  originRange,
}: Props) => {
  const { t } = useTranslation();
  const formatDateTimeRange = useFormatDateTimeRange();
  const [showDcoFields, setShowDcoFields] = useState(false);
  const { values, setValues, setFieldValue } = useFormikContext<TFormData>();
  // @ts-ignore
  const { publishAt, dateRange, plannedAt, timeTo, timeFrom } = values;
  const withPublishAtOffset = usePublishAtOffsetValue();

  const rangeFrom = dateRange?.from || undefined;
  const rangeTo = dateRange?.to || undefined;

  const getDcoRangeFrom = useCallback(
    (dateFromBuffer: number | null) => {
      const dcoRangeFrom = rangeFrom
        ? DateTime.fromJSDate(rangeFrom)
            .plus({ days: dateFromBuffer || 0 })
            .endOf('day')
        : rangeFrom;
      return dcoRangeFrom;
    },
    [rangeFrom]
  );

  const getDcoRangeTo = useCallback(
    (dateToBuffer: number | null) => {
      const dcoRangeTo = rangeTo
        ? DateTime.fromJSDate(rangeTo)
            .minus({ days: dateToBuffer || 0 })
            .endOf('day')
        : rangeTo;
      return dcoRangeTo;
    },
    [rangeTo]
  );

  const dcoRangeDate = formatDateTimeRange(
    rangeFrom ? getDcoRangeFrom(values.dateFromBuffer) : undefined,
    rangeTo ? getDcoRangeTo(values.dateToBuffer) : undefined,
    'DATE_SHORT'
  );

  const dcoRangeTime = formatDateTimeRange(timeFrom, timeTo, 'TIME_SHORT');

  const handleChangeRange = useCallback(
    (range: Date[] | null) => {
      if (!range) {
        setValues(
          disablePublishAt
            ? {
                ...values,
                dateRange: null,
              }
            : {
                ...values,
                dateRange: null,
                publishAt: withPublishAtOffset(publishAt, null),
              }
        );
        return;
      }

      // Default to startOf day and endOf day
      const fromWithTime = DateTime.fromJSDate(range[0])
        .set({
          hour: rangeFrom?.getHours() || 0,
          minute: rangeFrom?.getMinutes() || 0,
        })
        .toJSDate();
      const toWithTime = DateTime.fromJSDate(range[1])
        .set({
          hour: rangeTo?.getHours() || 23,
          minute: rangeTo?.getMinutes() || 59,
        })
        .toJSDate();

      const value = range
        ? {
            from: fromWithTime,
            to: toWithTime,
          }
        : null;

      const startDate: Date | null = value && value.from;
      // We need to adjust publish at if it's now in the disabled range
      if (
        startDate &&
        publishAt &&
        DateTime.fromJSDate(startDate) < DateTime.fromJSDate(publishAt)
      ) {
        setValues({
          ...values,
          dateRange: value,
          publishAt: withPublishAtOffset(undefined, startDate),
        });
      } else {
        setValues(
          disablePublishAt
            ? {
                ...values,
                dateRange: value,
              }
            : {
                ...values,
                dateRange: value,
                publishAt: withPublishAtOffset(publishAt, startDate),
              }
        );
      }
    },
    [disablePublishAt, publishAt, rangeFrom, rangeTo, setValues, values, withPublishAtOffset]
  );

  const getValueDCOField = useCallback(() => {
    if (dcoRangeDate && dcoRangeTime) return dcoRangeDate + ', ' + dcoRangeTime;
    if (dcoRangeDate) return dcoRangeDate;
    if (dcoRangeTime) return dcoRangeTime;

    return '';
  }, [dcoRangeDate, dcoRangeTime]);

  return (
    <>
      {!disableExpanding && (
        <FullRowCell>
          <Field
            component={FormCalendarRangeInput}
            label={t('Date Range')}
            name="dateRange"
            fast={false}
            onChange={handleChangeRange}
          />
          {dateRange && originRange && (
            <ExplanationWrapper>
              {t('{{entity}} Date Range is: {{range}}', {
                entity: originEntity,
                range: formatDateTimeRange(...originRange),
              })}
            </ExplanationWrapper>
          )}
        </FullRowCell>
      )}

      <Cell desktopColumns={6} tabletColumns={4}>
        <Field
          component={FormTimeInput}
          label={t('From Time')}
          name="timeFrom"
          max={timeTo}
          fast={false}
        />
      </Cell>

      <Cell desktopColumns={6} tabletColumns={4}>
        <Field
          component={FormTimeInput}
          label={t('To Time')}
          name="timeTo"
          min={timeFrom}
          fast={false}
        />
      </Cell>

      <FullRowCell>
        <Field
          fromDay={getDcoRangeFrom(values.dateFromBuffer)?.toJSDate()}
          toDay={getDcoRangeTo(values.dateToBuffer)?.toJSDate()}
          min={timeFrom}
          max={timeTo}
          component={FormDateTimeField}
          label={t('Planned Collection')}
          name="plannedAt"
          format="DATETIME_SHORT"
          fast={false}
        />
      </FullRowCell>

      <FullRowCell>
        <FlexRow>
          <FlexCell flex={1} block>
            <DummyField
              label={disableExpanding ? t('Date and Time Range') : t('Date and Time Range for DCO')}
              value={getValueDCOField()}
            />
          </FlexCell>

          {!disableExpanding && (
            <FlexCell>
              <IconButton
                icon={showDcoFields ? 'visibility_off' : 'visibility'}
                tooltip={
                  showDcoFields
                    ? disablePublishAt
                      ? t('Close Range Buffer')
                      : t('Close Publish At and Range Buffer')
                    : disablePublishAt
                      ? t('Open Range Buffer')
                      : t('Open Publish At and Range Buffer')
                }
                onClick={() => {
                  setShowDcoFields((shown) => !shown);
                }}
              />
            </FlexCell>
          )}
        </FlexRow>
      </FullRowCell>

      {showDcoFields && (
        <>
          {disablePublishAt ? null : (
            <FullRowCell>
              <Field
                toDay={rangeFrom}
                onChange={(value: Date | null) => {
                  setFieldValue('publishAt', withPublishAtOffset(value, rangeFrom));
                  if (value && plannedAt && plannedAt < value) {
                    setFieldValue('plannedAt', null);
                  }
                }}
                component={FormDateTimeField}
                label={t('Publish At')}
                name="publishAt"
                format="DATETIME_SHORT"
                fast={false}
              />
            </FullRowCell>
          )}
          <HalfRowCell>
            <DateRangeBuffer
              name="dateFromBuffer"
              dateFrom={rangeFrom}
              dateTo={rangeTo}
              label={t('DCO Start Buffer')}
              helperText={t('Offset for DCO Time Range start. (Days)')}
              showFillButton={false}
              onCalculated={(days) => setFieldValue('dateFromBuffer', days)}
              onChange={(value) => {
                const dcoRangeFrom = getDcoRangeFrom(Number(value));
                if (dcoRangeFrom && plannedAt && plannedAt < dcoRangeFrom.toJSDate()) {
                  setFieldValue('plannedAt', null);
                }
              }}
            />
          </HalfRowCell>
          <HalfRowCell>
            <DateRangeBuffer
              name="dateToBuffer"
              dateFrom={rangeFrom}
              dateTo={rangeTo}
              label={t('DCO End Buffer')}
              helperText={t('Offset for DCO Time Range end. (Days)')}
              onCalculated={(days) => setFieldValue('dateToBuffer', days)}
              onChange={(value) => {
                const dcoRangeTo = getDcoRangeTo(Number(value));
                if (dcoRangeTo && plannedAt && plannedAt > dcoRangeTo.toJSDate()) {
                  setFieldValue('plannedAt', null);
                }
              }}
            />
          </HalfRowCell>
        </>
      )}
    </>
  );
};

export default ScheduleDateTimeFields;
