import { FC } from 'react';

import FormCalendarInput, { Props } from '../FormCalendarInput';

const FormCalendarRangeInput: FC<Props> = ({ form, field, ...props }) => {
  const value = field.value ? [field.value.from, field.value.to] : null;

  const setFieldValue = (name: string, value: Date[]) => {
    const objectValue =
      value?.[0] && value?.[1]
        ? {
            from: value?.[0],
            to: value?.[1],
          }
        : null;
    form.setFieldValue(name, objectValue);
  };

  return (
    <FormCalendarInput
      field={{ ...field, value }}
      form={{ ...form, setFieldValue }}
      range
      {...props}
    />
  );
};

export default FormCalendarRangeInput;
