import { useMemo } from 'react';
import * as Yup from 'yup';

const useSchema = () => {
  return useMemo(
    () =>
      Yup.object({
        name: Yup.string().nullable().required().default(null),
        shared: Yup.boolean().nullable().default(false),
        default: Yup.boolean().nullable().default(false),
      }),
    []
  );
};

export type FormData = Yup.TypeOf<ReturnType<typeof useSchema>>;

export default useSchema;
