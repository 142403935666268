import { useMemo } from 'react';

import { useBoolClientOption } from 'core/hooks/useClientOption';
import { useResources } from 'core/hooks/useResource';
import { useHumanReadableAvailability } from 'personnel/hooks/useHumanReadableAvailability';
import AvailabilityCategory from 'personnel/models/AvailabilityCategory';
import TeamMember from 'planning/models/TeamMember';

const useUserAvailabilities = (
  users: TeamMember[],
  dateFrom?: Date | null,
  dateTo?: Date | null
) => {
  const availabilitiesEnabled = useBoolClientOption('enableAvailabilities');

  const { data: availabilityCategories } = useResources<AvailabilityCategory>(
    'profile/availabilities/categories',
    {
      autoload: availabilitiesEnabled,
    }
  );

  const getHumanReadableAvailability = useHumanReadableAvailability(availabilityCategories);
  const availabilities = useMemo(() => {
    return (
      users?.reduce(
        (acc, user) => {
          let filteredAvailabilities = user.availabilities;
          if (dateFrom && dateTo) {
            filteredAvailabilities = user.availabilities?.filter(
              (item) => item.dateFrom >= dateFrom && item.dateTo <= dateTo
            );
          }

          acc[user.usersId] = getHumanReadableAvailability(filteredAvailabilities);

          return acc;
        },
        {} as { [usersId: number]: string }
      ) || {}
    );
  }, [getHumanReadableAvailability, users, dateFrom, dateTo]);

  return availabilities;
};

export default useUserAvailabilities;
