import { FilterValue } from 'core/containers/DataGrid';
import getEnv from 'core/functions/getEnv';
import User from 'core/models/CoreUser';
import Currency from 'core/models/Currency';

import BaseModel from './Base';
import { Client } from './Client';
import CoreUser from './CoreUser';

export interface Permission {
  controller: string;
  action: string;
  params?: {
    scope?: string[];
    actions?: string[];
    roles?: number[];
  };
}

export const getCustomUserAvatarUrl = (userId: number, storageKey: string | undefined | null) =>
  storageKey
    ? `${getEnv('API_URL')}/users/${userId}/image?${storageKey}`
    : `${getEnv('API_URL')}/users/${userId}/image`;

export default interface CoreProfile extends CoreUser {
  permissions: { [key: string]: Permission };
  clientOptions: { [key: string]: ClientOption };
  filterDefaults: { [entity: string]: { [key: string]: FilterValue } };
  client: Client & { currency: Currency };
  originUser?: User;
}

export interface ClientOption extends BaseModel {
  key: string;
  value: string | null;
  type: string;
}

/**
 * Works only for settings with value '1'/'0'/null
 *
 * @param {ClientOption} option
 * @return {boolean} Whether client option is enabled
 */
export function clientOptionEnabled(option: ClientOption) {
  return option && option.value === '1';
}
