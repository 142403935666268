import { useFormikContext } from 'formik';
import { FC, useCallback, useState } from 'react';
import { useTranslation } from 'react-i18next';

import { AccreditationsRole } from 'app/models/Accreditation';
import DropDown from 'core/components/DropDown';
import Modal from 'core/components/Modal';

import { TeamMemberType } from '../../enums';
import { TeamFormData, TeamMemberData } from '../../useTeamInputMapping';
import { getCommonRoles } from '../getCommonRoles';
import useAccreditationRolesOptions from '../useAccreditationRolesOptions';

interface Props {
  type: TeamMemberType;
  members?: TeamMemberData[];
  onClose: () => void;
}

const RolesModal: FC<Props> = ({ type, members, onClose }) => {
  const { t } = useTranslation();
  const [selected, setSelected] = useState<AccreditationsRole[]>([]);
  const roleOptions = useAccreditationRolesOptions(!!members);
  const commonRoles = !!members ? getCommonRoles(members) : [];

  const dataKey = type === TeamMemberType.INVITED ? 'invitedMembers' : 'assignedMembers';
  const formik = useFormikContext<TeamFormData>();
  const change = useCallback(() => {
    const memberIds = new Set(members?.map((m) => m.usersId) || []);
    formik.setValues({
      ...formik.values,
      [dataKey]: formik.values[dataKey].map((member) => ({
        ...member,
        roles: memberIds.has(member.usersId) ? selected : member.roles,
      })),
    });
  }, [dataKey, formik, members, selected]);

  return (
    <Modal
      ariaLabel={t('Team Member role editing dialog')}
      open={!!members}
      onClose={() => {
        setSelected([]);
        onClose();
      }}
      onConfirm={() => change()}
      onClear={() => setSelected([])}
    >
      <DropDown
        mode="inline"
        value={selected}
        onChange={(selection) => {
          const selectionRoles = selection as AccreditationsRole[];

          setSelected(
            // Lead DCO also has to be a DCO
            selectionRoles.includes(AccreditationsRole.LEAD_DCO) &&
              !selectionRoles.includes(AccreditationsRole.DCO)
              ? [...selectionRoles, AccreditationsRole.DCO]
              : selectionRoles
          );
        }}
        options={
          roleOptions?.filter((role) => {
            if (role === 'SEPARATOR') return 'SEPARATOR';
            return commonRoles?.includes(role.id as AccreditationsRole);
          }) || []
        }
        loading={!roleOptions}
      />
    </Modal>
  );
};

export default RolesModal;
