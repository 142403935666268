import Cropper from 'react-cropper';
import styled from 'styled-components/macro';

export const StyledCropper = styled(Cropper)`
  .cropper-view-box {
    box-shadow: 0 0 0 1px #39f;
    outline: inherit !important;
  }
  .cropper-face {
    background-color: inherit !important;
  }
`;

export const StyledSliderWrapper = styled.div`
  padding: 1.5rem;
`;
