import { useTranslation } from 'react-i18next';

import { entities } from 'app/entity';
import Event, { EventStatus } from 'app/models/Event';
import {
  Notification,
  NotificationTypeEnum,
} from 'core/components/Notifications/RenderNotification';
import useGetStatusName from 'planning/components/TeamTab/MembersCard/MembersCardItem/useGetStatusName';
import { TeamMemberStatus } from 'planning/models/TeamMember';

export default function useMissionTeamMemberInvitationUpdate() {
  const { t } = useTranslation();
  const getStatusName = useGetStatusName();

  return (e: Event): Notification => {
    const response = getStatusName(e.payload.status);

    const map = {
      [EventStatus.CREATED]: {
        primary: t('User {{response}} invitation for {{missionCode}}', {
          response,
          missionCode: e.payload.missionsCode,
        }),
        secondary: t(
          'User {{name}} {{response}} invitation to be a team member for {{missionCode}}.',
          {
            name: e.payload.usersName,
            response,
            missionCode: e.payload.missionsCode,
          }
        ),

        updatedAt: e.modifiedAt || e.createdAt,
        actions: [
          {
            key: 'open',
            text: t('Open Mission'),
            action: entities.mission.urls().detail(e.payload.missionsId),
          },
        ],
        icon: 'groups',
        inProgress: false,
        read: e.isRead,
        type:
          e.payload.status === TeamMemberStatus.ACCEPTED
            ? NotificationTypeEnum.SUCCESS
            : NotificationTypeEnum.ERROR,
        id: e.id,
      },
    };

    return map[EventStatus.CREATED];
  };
}
