import styled from 'styled-components/macro';

import theme from 'app/theme';

export const InvitationTogglesWrapper = styled.div`
  display: flex;
  flex-direction: column;

  /* Switch animation causes overflow in both directions */
  overflow: hidden;

  color: ${theme.color.baseFontColor};

  padding-bottom: 0.5rem;
`;
