import { useMemo } from 'react';

import getResourcesUrl from 'core/functions/getResourcesUrl';

const useWatchersUrls = (baseUrl: string) => {
  return useMemo(
    () => ({
      watchersList: (entityId: number) => getResourcesUrl(`${baseUrl}/${entityId}/watchers`),
      availableWatchersList: (entityId: number) =>
        getResourcesUrl(`${baseUrl}/${entityId}/watchers/available`),
      watchersBulkAdd: getResourcesUrl(`${baseUrl}/watchers/bulk`),
    }),
    [baseUrl]
  );
};

export type WatchersUrls = ReturnType<typeof useWatchersUrls>;

export default useWatchersUrls;
