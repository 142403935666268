import BaseModel from 'core/models/Base';

export enum AccreditationsId {
  DCO = 1,
  BCO = 2,
  PHLEBOTOMIST = 3,
  DCA = 4,
  CHAPERONE = 5,
  LEAD_DCO = 6,
}

export const dcoAccreditations = [
  AccreditationsId.DCO,
  AccreditationsId.LEAD_DCO,
  AccreditationsId.CHAPERONE,
];
export const bcoAccreditations = [AccreditationsId.BCO, AccreditationsId.PHLEBOTOMIST];

export const chaperoneAccreditations = [AccreditationsId.CHAPERONE];

export enum AccreditationsRole {
  DCO = 'dco',
  BCO = 'bco',
  CHAPERONE = 'chaperone',
  LEAD_DCO = 'leadDco',
}
export default interface Accreditation extends BaseModel {
  name: string;
  role: AccreditationsRole;
  isSelectable: boolean;
}
