import styled, { keyframes } from 'styled-components/macro';

import theme from 'app/theme';

type WrapperProps = { opacity?: number; className?: string; ref?: any };

const slideIn = keyframes`
  from {
    transform: translateX(-100%);
  }

  to {
    transform: translateX(0);
  }
`;

const fadeIn = keyframes`
  from {
    opacity: 0
  }

  to {
    opacity: 1;
  }
`;

const slideOut = keyframes`
  from {
    transform: translateX(0);
  }

  to {
    transform: translateX(-100%);
  }
`;

type ContentProps = {
  className?: string;
  ref?: any;
};

export const Content = styled.div<ContentProps>`
  background: ${theme.color.white};
  width: 100%;
  max-width: 500px;
  transform: translateX(-100%);
  position: fixed;
  height: 100vh;
  height: calc(var(--vh, 1vh) * 100);
  z-index: 999;
  display: flex;
  left: 0;
  top: 0;

  &.open {
    animation: 0.3s ${slideIn} 0s normal forwards;
  }

  &.close {
    animation: 0.3s ${slideOut} 0s normal forwards;
  }
`;

export const Wrapper = styled.div<WrapperProps>`
  position: fixed;
  display: flex;
  z-index: 999;
  opacity: 0;
  bottom: 0;
  left: 0%;
  width: 0;
  top: 0;

  background: rgba(0, 0, 0, ${(props) => ((100 - (props.opacity || 0)) / 100).toFixed(2)});

  &.open {
    width: 100%;
    animation: 0.6s ${fadeIn} 0.15s normal forwards;
  }
`;
