import { ICellRendererParams } from 'ag-grid-community';

import styles from './index.module.css';

export default class EllipsisCellRenderer {
  private node?: Node;

  public init({ value, valueFormatted, eGridCell, eParentOfValue }: ICellRendererParams) {
    const text: string | null = valueFormatted ? valueFormatted : value;

    // Parent is not the grid cell e.g. when enableCellTextSelection is enabled
    if (eGridCell !== eParentOfValue) {
      eParentOfValue.classList.add(styles.ellipsis);

      this.node = document.createTextNode(text ?? '');
    } else {
      this.node = document.createElement('div');
      (this.node as HTMLDivElement).className = styles.ellipsis;

      this.node.textContent = text ?? '';
    }
  }

  public getGui() {
    // Ag-grid should call init before getGui, so we should be fairly sure
    return this.node!;
  }

  public refresh({ value, valueFormatted }: ICellRendererParams) {
    // Tell grid the whole component needs to be rendered
    if (!this.node) return false;

    const text = valueFormatted ? valueFormatted : value;

    this.node.textContent = text ?? '';
    // Tell grid we rerendered successfully
    return true;
  }
}
