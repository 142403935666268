import { FC, useCallback } from 'react';
import { useDropzone } from 'react-dropzone';
import { useTranslation } from 'react-i18next';

import IconButton from 'core/components/IconButton';
import MaterialIcon from 'core/components/MaterialIcon';

import Tooltip from '../Tooltip';

import {
  FileInsideWrapper,
  FileName,
  FilePickerContainer,
  FilePickerWrapper,
  FilesDetailWrapper,
  StyledHeadline,
  StyledMaterialIcon,
  StyledSecondaryText,
  StyledTextWrapper,
} from './styled';

interface Props {
  onFileChoose: (droppedFiles: File[]) => void;
  accept?: Record<string, string[]>;
  multiple?: boolean;
  choosedFiles?: File[];
  removeFile?: (fileIndex: number) => void;
  disabled?: boolean;
  hasError?: boolean;
}

const DropzonePicker: FC<Props> = ({
  onFileChoose,
  choosedFiles,
  accept,
  removeFile,
  multiple = false,
  disabled,
  hasError,
}) => {
  const { t } = useTranslation();

  const onDrop = useCallback(
    (acceptedFiles: File[]) => {
      if (acceptedFiles.length > 0) {
        if (multiple) {
          !disabled && onFileChoose(acceptedFiles);
        } else {
          !disabled && onFileChoose([acceptedFiles[0]]);
        }
      }
    },
    [onFileChoose, multiple, disabled]
  );

  const { getRootProps, getInputProps, isDragActive } = useDropzone({
    onDrop,
    multiple: multiple,
    accept,
    useFsAccessApi: false,
  });

  return (
    <>
      <FilePickerContainer active={isDragActive} hasError={hasError}>
        <FilePickerWrapper {...getRootProps()} disabled={disabled}>
          <input {...getInputProps()} disabled={disabled} />
          <StyledMaterialIcon icon="cloud_upload" />
          <StyledTextWrapper>
            <StyledHeadline>{t('Drag & Drop')}</StyledHeadline>
            <StyledSecondaryText>
              {t('your file(s) here, or click on this area')}
            </StyledSecondaryText>
          </StyledTextWrapper>
        </FilePickerWrapper>
      </FilePickerContainer>

      {choosedFiles && choosedFiles.length > 0 && (
        <FilesDetailWrapper>
          {choosedFiles.map((f, index) => {
            return (
              <FileInsideWrapper key={`${f.name}-${index}`}>
                <FileName>
                  <Tooltip content={f.name}>
                    <MaterialIcon icon="insert_drive_file" />
                  </Tooltip>
                  <p>{f.name}</p>
                </FileName>
                {removeFile && (
                  <IconButton
                    icon="close"
                    onClick={() => removeFile(index)}
                    tooltip={t('Remove file')}
                  />
                )}
              </FileInsideWrapper>
            );
          })}
        </FilesDetailWrapper>
      )}
    </>
  );
};

export default DropzonePicker;
