import { ColDef } from 'ag-grid-community';
import { AgGridReact } from 'ag-grid-react';
import camelCase from 'lodash/camelCase';
import { FC, useCallback, useEffect, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch } from 'react-redux';

import { EntityMap } from 'app/entity';
import { FileEntityKey, useFileEntityLookup } from 'app/hooks/useFileEntityLookups';
import theme from 'app/theme';
import { downloadData } from 'core/actions';
import AgGridPaperWrapper from 'core/components/AgGridPaperWrapper';
import PaperContainer from 'core/components/PaperContainer';
import AddFilesDialog from 'core/containers/AddFilesDialog';
import { IconCellRenderer } from 'core/containers/DataGrid/FastCellRenderers';
import 'core/containers/DataGrid/styles.scss';
import EditFilesDialog from 'core/containers/EditFilesDialog';
import { useQueryParam } from 'core/hooks/useQueryParams';
import { useResources } from 'core/hooks/useResource';
import { useFormatDateTime } from 'core/i18n/useFormatDateTime';
import FileAttachment from 'core/models/FileAttachment';

import EmptyOverlay from '../DataGrid/EmptyOverlay';

import { RightButton } from './styled';

export interface Props {
  closeFileDialog?: () => void;
  addFileOpen?: boolean;
  readonly?: boolean;
  entityId: number;
  entityKey: FileEntityKey;
  entityContext?: Parameters<EntityMap[FileEntityKey]['api']>[0];
}

const FilesTable: FC<Props> = ({
  addFileOpen,
  closeFileDialog,
  entityId,
  readonly,
  entityKey,
  entityContext,
}) => {
  const dispatch = useDispatch();
  const { t } = useTranslation();
  const formatDateTime = useFormatDateTime();

  const [fileDetailId, setFileDetailId] = useState<number | null>(null);
  const modalQueryParam = useQueryParam('modal');
  const [isFileAddingOpen, setIsFileAddingOpen] = useState(!!addFileOpen);
  const [initalFileAddOpen, setInitialFileAddOpen] = useState<boolean>(
    modalQueryParam === 'add-attachment'
  );
  const actualLookup = useFileEntityLookup(entityKey, entityContext);
  const lookupUrls = actualLookup.urls;
  const { data: unrestrictedRoleIds } = useResources<number>(lookupUrls.unrestrictedRoles);

  const permissionController = camelCase(actualLookup.entityName);

  const downloadFile = useCallback(
    (fileId: number, fileName: string) =>
      actualLookup &&
      dispatch(downloadData(lookupUrls.download(entityId, fileId), '*/*', fileName)),
    [dispatch, actualLookup, entityId, lookupUrls]
  );

  useEffect(() => {
    if (!initalFileAddOpen) setIsFileAddingOpen(addFileOpen || false);
  }, [addFileOpen, initalFileAddOpen]);

  const { data: files, reload: reloadFiles } = useResources<FileAttachment>(
    lookupUrls.files(entityId)
  );

  const columnDefs: ColDef[] = useMemo(
    () => [
      {
        headerName: t('Name'),
        field: 'metadata.fileName',
        suppressSizeToFit: true,
        cellRenderer: 'iconCellRenderer',
        cellStyle: { cursor: 'pointer' },
        onCellClicked: (params) => {
          const currentFile: FileAttachment = params.data;
          downloadFile(currentFile.id, currentFile.metadata.fileName);
        },
        width: 350,
        cellRendererParams: {
          tooltip: t('Download File'),
          alignRight: true,
          titleLeft: (file: FileAttachment) => file && file.metadata.fileName,
          icons: [
            {
              name: 'save_alt',
              color: theme.color.gray300,
              visible: () => true,
            },
          ],
        },
      },
      {
        headerName: t('Type'),
        field: 'fileTypeName',
        suppressSizeToFit: true,
        width: 300,
        onCellClicked: (params) => {
          const currentFile: FileAttachment = params.data;
          !readonly && setFileDetailId(currentFile.id);
        },
      },
      {
        headerName: t('Date'),
        field: 'createdAt',
        valueGetter: (params) => formatDateTime(params.data.createdAt, 'DATETIME_SHORT'),
        width: 200,
        suppressSizeToFit: true,
        onCellClicked: (params) => {
          const currentFile: FileAttachment = params.data;
          !readonly && setFileDetailId(currentFile.id);
        },
      },
      {
        headerName: t('Entity'),
        field: 'entityName',
        minWidth: 200,
        onCellClicked: (params) => {
          const currentFile: FileAttachment = params.data;
          !readonly && setFileDetailId(currentFile.id);
        },
        valueGetter: (params) => {
          const currentFile: FileAttachment = params.data;
          return currentFile.master ? currentFile.master.entityName : currentFile.entityName;
        },
      },
      {
        headerName: t('Description'),
        field: 'description',
        minWidth: 200,
        onCellClicked: (params) => {
          const currentFile: FileAttachment = params.data;
          !readonly && setFileDetailId(currentFile.id);
        },
      },
    ],
    [downloadFile, formatDateTime, readonly, t]
  );

  if (!actualLookup) return null;

  return (
    <PaperContainer
      customTitleAction={
        !readonly && (
          <RightButton
            type="button"
            onClick={() => setIsFileAddingOpen(true)}
            text={t('Add Attachment')}
          />
        )
      }
      title={t('File Attachments')}
    >
      <AgGridPaperWrapper fullHeight>
        <AgGridReact
          domLayout="autoHeight"
          defaultColDef={{ sortable: true }}
          columnDefs={columnDefs}
          suppressHorizontalScroll
          suppressNoRowsOverlay
          rowData={files || []}
          onGridReady={({ api }) => {
            api.sizeColumnsToFit();
          }}
          components={{
            iconCellRenderer: IconCellRenderer,
          }}
          enableCellTextSelection
          ensureDomOrder
        />

        {files && !files.length && (
          <EmptyOverlay
            subTitle={t('Click the button in the upper right corner for adding new items')}
            padding="0 3rem 3rem 3rem"
          />
        )}
      </AgGridPaperWrapper>

      <AddFilesDialog
        onClose={() => {
          setInitialFileAddOpen(false);
          closeFileDialog && closeFileDialog();
          setIsFileAddingOpen(false);
        }}
        open={isFileAddingOpen || initalFileAddOpen}
        entityKey={entityKey}
        entityId={entityId}
        onSuccessSaved={() => {
          setIsFileAddingOpen(false);
          reloadFiles();
        }}
        createUrl={lookupUrls.files(entityId)}
        unrestrictedRoleIds={unrestrictedRoleIds || []}
      />

      {fileDetailId && (
        <EditFilesDialog
          fileId={fileDetailId}
          onClose={() => setFileDetailId(null)}
          open={!!fileDetailId}
          onSuccessDelete={() => reloadFiles()}
          onSuccessSave={() => {
            reloadFiles();
            setFileDetailId(null);
          }}
          hideAssignTo
          listUrl={lookupUrls.files(entityId)}
          detailUrl={(fileId: number) => lookupUrls.file(entityId, fileId)}
          downloadUrl={(fileId: number) => lookupUrls.download(entityId, fileId)}
          permissionController={permissionController}
          unrestrictedRoleIds={unrestrictedRoleIds || []}
        />
      )}
    </PaperContainer>
  );
};

export default FilesTable;
