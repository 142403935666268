const AppIcon = () => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="240"
    height="240"
    viewBox="0 0 240 240"
    fill="none"
    strokeWidth="2"
    strokeLinecap="round"
    strokeLinejoin="round"
    className="feather feather-calendar"
  >
    <path
      d="m117.32588,109.99306l-28.36748,16.37753l-28.36748,-16.37753l0,-32.75595l28.36748,-16.37798l28.36748,16.37753l0,32.7564z"
      fill="#2a2a2a"
    />
    <path
      d="m179.40906,109.99306l-28.36748,16.37753l-28.36703,-16.37753l0,-32.75595l28.36703,-16.37798l28.36748,16.37753l0,32.7564z"
      fill="#2a2a2a"
    />
    <path
      d="m148.3677,162.76379l-28.36748,16.37708l-28.36748,-16.37708l0,-32.75595l28.36748,-16.37753l28.36748,16.37753l0,32.75595z"
      fill="#2a2a2a"
    />
  </svg>
);

export default AppIcon;
