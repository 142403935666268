import * as Yup from 'yup';

import { entityModel } from 'app/hooks/useFileEntityLookups';

import { DetailModeEnum } from '../FormDetailPage';

const useSchema = (mode: DetailModeEnum = DetailModeEnum.CREATE) => {
  return Yup.object({
    fileTypesId:
      DetailModeEnum.EDIT === mode
        ? Yup.number().nullable().default(null)
        : Yup.number().nullable().required().default(null),
    entityId: Yup.number().required().nullable().default(null),
    files:
      DetailModeEnum.EDIT === mode
        ? Yup.array().nullable().ensure().default([])
        : Yup.array().nullable().min(1).required().default([]),
    description: Yup.string().nullable().default(null),
    entityName: Yup.mixed().oneOf(Object.values(entityModel)).required(),
    restrictToRoles: Yup.array(Yup.number()).ensure().default([]),
  });
};

export default useSchema;

export type FormData = Yup.TypeOf<ReturnType<typeof useSchema>>;
