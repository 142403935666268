import Tab from '@material/react-tab';
import { FormikConsumer, setIn } from 'formik';
import { FC, memo, useCallback, useEffect, useMemo, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';

import AddressFields from 'core/components/AddressesFields/AddressFields';
import DropDown from 'core/components/DropDown';
import Field from 'core/components/Form/Field';
import FormFieldset from 'core/components/Form/FormFieldset';
import useFormFieldValue from 'core/components/Form/useFormFieldValue';
import { ExplanationWrapper } from 'core/components/FormStyles';
import FormSwitch from 'core/components/FormSwitch';
import FormTextfield from 'core/components/FormTextfield';
import { Row } from 'core/components/Grid';
import { FullRowCell, HalfRowCell } from 'core/components/GridCell';
import Modal from 'core/components/Modal';
import PaperContainer from 'core/components/PaperContainer';
import { useIncludeOptions } from 'core/components/ResourceFormDropdown/IncludeResourcesProvider';
import { StyledTabIcon } from 'core/components/StyledTabIcon';
import getResourcesUrl from 'core/functions/getResourcesUrl';
import usePermission from 'core/hooks/usePermission';
import { useResources } from 'core/hooks/useResource';
import Location from 'lists/models/Location';
import { ATHLETES_RESOURCE_PARAMS } from 'personnel/models/Athlete';
import { testResultLocations } from 'planning/resources/testResult';

import AthleteAddresses from './AthleteAddresses';
import SelectLocationButton from './SelectLocationButton';
import { TabBar } from './styled';

export type ControlLocationValues = {
  locationName: string | null;
  countriesId: number | null;
  locationsId: number | null;
  createLocation: boolean;
  street: string | null;
  address: string | null;
  state: string | null;
  city: string | null;
  zip: string | null;
};

type Props = {
  locationPickerEnabled?: boolean;
  /**
   * If it's undefined, the athlete tab is not available for picking athlete locations
   * If it's null the athlete locations tab is disabled and if it's provided athlete location can be selected
   */
  athletesId?: number | null;
  name?: string; // name prefix
  countryWithStar?: boolean;
  withoutLazyLoading?: boolean;
};

enum TABS {
  LOCATION = 0,
  ATHLETE_ADDRESSES = 1,
  RECENT_CONTROLS = 2,
}

const ControlLocationFields: FC<Props> = ({
  name,
  athletesId,
  locationPickerEnabled = true,
  countryWithStar = true,
  withoutLazyLoading = false,
}) => {
  const [activeTab, setActiveTab] = useState<number>(TABS.LOCATION);
  const [locationChoosingOpen, setLocationOpen] = useState(false);
  const getName = useCallback((n: string) => (name ? `${name}${n ? '.' + n : n}` : n), [name]);
  const { t } = useTranslation();
  const fetchLocationsRefId = useRef(undefined);

  const canSeeLocations = usePermission('locations:find');

  const {
    data: locations,
    reload: loadLocations,
    isLoading,
  } = useResources<Location>(testResultLocations, {
    autoload: withoutLazyLoading && canSeeLocations,
  });

  const includes = useIncludeOptions('locationsId') as Location[];
  const locationsId = useFormFieldValue(getName('locationsId'));

  const locationOptions = useMemo(() => {
    const possibleLocations = ([...(locations || []), ...(includes || [])] as Location[]).reduce(
      (acc: { [key: number]: Location }, it: Location) => {
        if (!(it.id in acc) && (it.active || it.id === locationsId) && !it.deleted) {
          acc[it.id] = it;
        }
        return acc;
      },
      {}
    );

    return Object.keys(possibleLocations).map((key) => ({
      id: possibleLocations[Number(key)].id,
      name: possibleLocations[Number(key)].name,
    }));
  }, [locations, includes, locationsId]);

  useEffect(() => {
    if (
      !isLoading &&
      locationsId &&
      !locationOptions?.find((it) => it.id === locationsId) &&
      locationsId !== fetchLocationsRefId.current
    ) {
      // For protection before infinity reloading when include doesn't contain location and chosen one is deleted
      fetchLocationsRefId.current = locationsId;
      loadLocations(true);
    }
  }, [loadLocations, locationOptions, locationsId, isLoading]);

  const createLocation = useFormFieldValue(getName('createLocation'));
  let actualLocation = useMemo(
    () =>
      [...(locations || []), ...(includes || [])].find((location) => location.id === locationsId),
    [locationsId, locations, includes]
  );

  const closeLocationDialog = useCallback(() => {
    setLocationOpen(false);
    setActiveTab(TABS.LOCATION);
  }, []);

  const handleFieldsetChange = useCallback(
    (
      name: keyof ControlLocationValues | undefined,
      value: string | number | boolean | null | undefined,
      values: ControlLocationValues
    ) => {
      if (!name) {
        closeLocationDialog();
        return values;
      }

      if (name !== getName('locationsId')) {
        const v = setIn(values, name, value);
        return setIn(v, getName('locationsId'), null);
      }

      closeLocationDialog();

      const loc = (locations || []).find((i) => i.id === value) || ({} as Location);

      const v: ControlLocationValues = {
        countriesId: loc.countriesId || null,
        locationName: loc.name || null,
        locationsId: loc.id || null,
        street: loc.street || null,
        address: loc.address || null,
        state: loc.state || null,
        city: loc.city || null,
        zip: loc.zip || null,
        createLocation: false,
      };

      return getName('') ? setIn(values, getName(''), v) : { ...values, ...v };
    },
    [getName, locations, closeLocationDialog]
  );

  const fieldAsterisks = useMemo(
    () => ({ city: createLocation, country: createLocation && countryWithStar }),
    [countryWithStar, createLocation]
  );

  const tabs = useMemo(
    () => [
      { key: TABS.LOCATION, title: t('Saved Locations') },
      ...(athletesId !== undefined
        ? [
            {
              key: TABS.ATHLETE_ADDRESSES,
              title: t('Athlete Addresses'),
              disabled: !athletesId ? t('Athlete must be chosen') : undefined,
            },
            {
              key: TABS.RECENT_CONTROLS,
              title: t('Recent Controls'),
              disabled: !athletesId ? t('Athlete must be chosen') : undefined,
            },
          ]
        : []),
    ],
    [t, athletesId]
  );

  const handleAthleteAddressChosen = useCallback(
    (address: Partial<ControlLocationValues>, setValues: (currentValues: any) => any) => {
      setValues((currentValues: any) => {
        const newValues = {
          ...currentValues,
          countriesId: address.countriesId || null,
          street: address.street || null,
          address: address.address || null,
          state: address.state || null,
          city: address.city || null,
          zip: address.zip || null,
          createLocation: false,
          locationName: null,
          locationsId: null,
        };

        return getName('')
          ? setIn(currentValues, getName(''), newValues)
          : { ...currentValues, ...newValues };
      });
    },
    [getName]
  );

  return (
    <PaperContainer
      title={t('Control Location')}
      customTitleAction={
        <SelectLocationButton
          onClick={() => {
            // Lazyly load locations the first time modal is opened
            !locations && loadLocations(true);
            setLocationOpen(true);
          }}
          location={actualLocation}
          disabled={!locationPickerEnabled}
        />
      }
    >
      <FormFieldset<ControlLocationValues> onChange={handleFieldsetChange}>
        <FormikConsumer>
          {({ setFieldValue, setValues }) => (
            <>
              <AddressFields fieldset={name} fieldAsterisks={fieldAsterisks}>
                {!actualLocation && locationPickerEnabled && (
                  <FullRowCell>
                    <Row>
                      <HalfRowCell>
                        <Field
                          label={t('Create Location')}
                          component={FormSwitch}
                          name={getName('createLocation')}
                          fast={false}
                          onChange={(newValue: boolean) => {
                            setValues((v: any) => ({
                              ...v,
                              createLocation: newValue,
                              locationName: null,
                            }));
                          }}
                        />
                        <ExplanationWrapper>
                          {t(
                            'This location will be saved in the list of locations for future use.'
                          )}
                        </ExplanationWrapper>
                      </HalfRowCell>
                      {createLocation && (
                        <HalfRowCell>
                          <Field
                            label={t('Location Name (alias)') + ((createLocation && ' *') || '')}
                            name={getName('locationName')}
                            component={FormTextfield}
                            fast={false}
                          />
                        </HalfRowCell>
                      )}
                    </Row>
                  </FullRowCell>
                )}
              </AddressFields>

              {locationPickerEnabled && (
                <Modal
                  onClear={() => setFieldValue(getName('locationsId'), null)}
                  onClose={() => {
                    closeLocationDialog();
                  }}
                  ariaLabel={t('Select Location')}
                  open={locationChoosingOpen}
                  confirmButton={null}
                  cancelButton={null}
                  title={t('Select Location')}
                >
                  {tabs.length > 1 && (
                    <TabBar>
                      {tabs.map((it, index) => (
                        <Tab
                          active={activeTab === index}
                          onClick={() => {
                            setActiveTab(index);
                          }}
                          key={it.key}
                          type="button"
                          disabled={!!it.disabled}
                        >
                          {it.disabled && <StyledTabIcon icon="info" tooltip={it.disabled} />}

                          <span>{it.title}</span>
                        </Tab>
                      ))}
                    </TabBar>
                  )}

                  {activeTab === TABS.LOCATION && (
                    <DropDown
                      onChange={(id) => setFieldValue(getName('locationsId'), id)}
                      options={locationOptions || []}
                      name={getName('locationsId')}
                      value={null}
                      mode="inline"
                      loading={!locations}
                      single
                    />
                  )}

                  {activeTab === TABS.ATHLETE_ADDRESSES && athletesId && (
                    <AthleteAddresses
                      url={getResourcesUrl('athletes', {
                        ...ATHLETES_RESOURCE_PARAMS,
                        $or: {
                          mastersId: athletesId,
                          id: athletesId,
                        },
                      })}
                      setLocationFields={(address) =>
                        handleAthleteAddressChosen(address, setValues)
                      }
                    />
                  )}

                  {activeTab === TABS.RECENT_CONTROLS && athletesId && (
                    <AthleteAddresses
                      url={getResourcesUrl(`athletes/${athletesId}/results/addresses`, {
                        sort: 'DESC',
                        order: 'performedAt',
                      })}
                      setLocationFields={(address) =>
                        handleAthleteAddressChosen(address, setValues)
                      }
                    />
                  )}
                </Modal>
              )}
            </>
          )}
        </FormikConsumer>
      </FormFieldset>
    </PaperContainer>
  );
};

export default memo(ControlLocationFields);
