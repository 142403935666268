import { useCallback } from 'react';

import { editorStateToMarkdown } from 'core/components/FormEditorField';

import { FormData } from './useSchema';

const useOutputMapping = () => {
  return useCallback((formData: FormData) => {
    return {
      text: editorStateToMarkdown(formData.editorState),
      restrictToRoles: formData.restrictToRoles,
    } as const;
  }, []);
};

export default useOutputMapping;
