import { SettingsFormData } from 'settings/reducer';

export const TYPE_LOAD_CONTROL_SETTINGS = 'LOAD_CONTROL_SETTINGS';
export const loadControlSettings = () =>
  ({
    type: TYPE_LOAD_CONTROL_SETTINGS,
    payload: {},
  }) as const;
export type LoadControlSettingsAction = ReturnType<typeof loadControlSettings>;

export const TYPE_UNLOAD_CONTROL_SETTINGS = 'UNLOAD_CONTROL_SETTINGS';
export const unloadControlSettings = () =>
  ({
    type: TYPE_UNLOAD_CONTROL_SETTINGS,
    payload: {},
  }) as const;
export type UnloadControlSettingsAction = ReturnType<typeof unloadControlSettings>;

export const TYPE_EDIT_CONTROL_SETTINGS = 'EDIT_CONTROL_SETTINGS';
export const editControlSettings = (fd: SettingsFormData, successCallback?: () => void) =>
  ({
    type: TYPE_EDIT_CONTROL_SETTINGS,
    payload: { fd, successCallback },
  }) as const;
export type EditControlSettingsAction = ReturnType<typeof editControlSettings>;

export type ControlSettingsActions =
  | LoadControlSettingsAction
  | UnloadControlSettingsAction
  | EditControlSettingsAction;
