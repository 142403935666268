import getNavGroups from './getNavGroups';
import type { NavGroup, NavSubItem } from './getNavGroups';

export { getNavGroups };
export type { NavGroup, NavSubItem };

export const defaultSearchUrl = '/users';

export type FormPageURLs = {
  readonly create?: string | ((id: number) => string);
  readonly clone?: (id: number) => string;
  readonly detail: (id: number) => string;
  readonly list: string;
};

export const urls = {
  account: '/account',
  adamsSettings: '/adams-settings',
  agenda: '/agenda',
  applicationContents: '/application-contents',
  communicationSettings: '/outbound-communication',
  controlSettings: '/control-settings',

  dashboard: '/',
  download: '/download',
  downloadApp: '/download/application',
  downloadDocs: '/download/documentation',
  fileTypes: '/files/types',
  login: '/login',
  logout: '/logout',
  clientStatistics: '/client-statistics',

  attachment: '/attachments',
  permissions: '/permissions',
};

export const publicPathNames = [
  '/login',
  '/forgot-password',
  '/reset-password',
  '/activate-account',
  '/unlock-account',
];
