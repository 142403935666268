import { push } from 'connected-react-router';
import { useFormikContext } from 'formik';
import { useContext, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch } from 'react-redux';

import { entities } from 'app/entity';
import DummyField from 'core/components/DummyField';
import { FormContext } from 'core/components/Form';
import Field from 'core/components/Form/Field';
import FormSwitch from 'core/components/FormSwitch';
import { Row } from 'core/components/Grid';
import { FullRowCell } from 'core/components/GridCell';
import IconButton from 'core/components/IconButton';
import PaperContainer, { Icon } from 'core/components/PaperContainer';
import { useBoolClientOption } from 'core/hooks/useClientOption';
import usePermission from 'core/hooks/usePermission';
import { DatagridDCOInvoice } from 'finance/models/DCOInvoice';

import LinkCostsModal from '../LinkCostsModal';

import InvoicesTable from './InvoicesTable';
import InvoicingCodes from './InvoicingCodes';

interface RequiredFormData {
  costsId: number | null;
}

interface Props {
  createNewCostLink?: string;
  viewMode?: boolean;
  showMissionLink?: boolean;
  dcoInvoices?: DatagridDCOInvoice[];
  icons?: Icon[];
  showInvoicingCode?: boolean;
  showBillableOptions?: boolean;
}

/**
 * Reusable paper for Mission, Test or other detail screens that are linked to finance modules like Costs
 *
 * Needs to be used as child of <Form> with field costsId.
 */
const LinkedFinancesPaper = <FormData extends RequiredFormData>({
  createNewCostLink,
  viewMode,
  showInvoicingCode,
  showMissionLink,
  showBillableOptions,
  dcoInvoices,
  icons,
}: Props) => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const [costAssigningOpen, setCostAssigningOpen] = useState(false);

  const costsEnabled = useBoolClientOption('enableCosts');
  const invoicesEnabled = useBoolClientOption('enableDcoInvoices');

  const canSeeInvoices = usePermission('dcoInvoices:find');
  const canSeeCosts = usePermission('costs:find');

  const costsAccessible = costsEnabled && canSeeCosts;
  const invoicesAccessible = invoicesEnabled && canSeeInvoices;

  const anyAccessible = costsAccessible || invoicesAccessible;

  const { setFieldValue, values } = useFormikContext<FormData>();

  const { isFieldInViewMode, diffMode: isBulkEdit } = useContext(FormContext);
  const costsIdViewMode = viewMode !== undefined ? viewMode : isFieldInViewMode('costsId');

  if (!anyAccessible) return null;

  return (
    <FullRowCell>
      <PaperContainer icons={icons} title={t('Finance')}>
        <Row>
          {costsAccessible && (
            <FullRowCell>
              <DummyField
                label={t('Costs')}
                value={
                  values.costsId ? t('Costs #{{id}}', { id: values.costsId }) : t('Not Linked')
                }
                endAdornment={
                  <>
                    {values.costsId && (
                      <IconButton
                        icon="link"
                        tooltip={t('Open Linked Costs')}
                        onClick={() => dispatch(push(entities.cost.urls().detail(values.costsId!)))}
                      />
                    )}
                    {!costsIdViewMode && (
                      <IconButton
                        icon="edit"
                        tooltip={t('Edit Linked Costs')}
                        onClick={() => {
                          setCostAssigningOpen(true);
                        }}
                      />
                    )}
                  </>
                }
              />
            </FullRowCell>
          )}

          {!isBulkEdit && invoicesAccessible && (
            <FullRowCell>
              <InvoicesTable invoices={dcoInvoices || []} />
            </FullRowCell>
          )}

          {showInvoicingCode && invoicesAccessible && (
            <FullRowCell>
              <InvoicingCodes />
            </FullRowCell>
          )}

          {showBillableOptions && (
            <>
              <FullRowCell>
                <Field
                  component={FormSwitch}
                  label={t('Billable by DCO')}
                  name="billableByDco"
                  fast
                  viewMode={false}
                />
              </FullRowCell>
              <FullRowCell>
                <Field
                  component={FormSwitch}
                  label={t('Billable to Customer')}
                  name="billableToCustomer"
                  fast
                  viewMode={false}
                />
              </FullRowCell>
            </>
          )}
        </Row>
      </PaperContainer>

      {costsAccessible && (
        <LinkCostsModal
          open={costAssigningOpen}
          setOpen={setCostAssigningOpen}
          createNewCostLink={createNewCostLink}
          onPickedCost={(id) => setFieldValue('costsId', id)}
          costsId={values.costsId}
          showMissionLink={showMissionLink}
        />
      )}
    </FullRowCell>
  );
};

export default LinkedFinancesPaper;
