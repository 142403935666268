import { toast } from 'react-toastify';
import { put, take, race } from 'redux-saga/effects';

import { apiCall } from 'core/actions';
import { stringify } from 'core/functions/qs';
import { t } from 'core/i18n';

import {
  FetchAvailabilitiesAction,
  TYPE_FETCH_AVAILABILITIES,
  SaveAvailabilityAction,
  TYPE_SAVE_AVAILABILITY,
  DeleteAvailabilityAction,
  TYPE_DELETE_AVAILABILITY,
} from '../actions';

export function* fetchAvailabilities(action: FetchAvailabilitiesAction) {
  const { dateFrom, dateTo, endpoint } = action.payload;

  const whereCond = {
    dateFrom: {
      $lt: dateTo,
    },
    dateTo: {
      $gt: dateFrom,
    },
    limit: 50000,
  };

  yield put(
    apiCall(
      TYPE_FETCH_AVAILABILITIES,
      'GET',
      `${endpoint}`,
      {},
      {
        params: whereCond,
        paramsSerializer: (params) =>
          stringify(params, {
            encodeValuesOnly: true,
          }),
      }
    )
  );
}

export function* saveAvailability(action: SaveAvailabilityAction) {
  const { formData, prevAvailability, id, successCallback } = action.payload;
  const isUpdate = !!prevAvailability;

  if (isUpdate) {
    yield put(
      apiCall(
        TYPE_SAVE_AVAILABILITY,
        'PATCH',
        `/profile/availabilities/${id}`,
        formData,
        {},
        true,
        prevAvailability,
        []
      )
    );
  } else {
    yield put(apiCall(TYPE_SAVE_AVAILABILITY, 'POST', '/profile/availabilities', formData));
  }

  const { success } = yield race({
    success: take(`${TYPE_SAVE_AVAILABILITY}_SUCCESS`),
    error: take(`${TYPE_SAVE_AVAILABILITY}_ERROR`),
  });

  if (success) {
    toast.success(t('Successfully saved'));
    successCallback && successCallback();
  }
}

export function* deleteAvailability(action: DeleteAvailabilityAction) {
  const { successCallback, availabilityId } = action.payload;

  yield put(
    apiCall(TYPE_DELETE_AVAILABILITY, 'DELETE', `/profile/availabilities/${availabilityId}`, {}, {})
  );
  const { success } = yield race({
    success: take(`${TYPE_DELETE_AVAILABILITY}_SUCCESS`),
    error: take(`${TYPE_DELETE_AVAILABILITY}_ERROR`),
  });

  if (success) {
    toast.success(t('Successfully deleted'));
    successCallback && successCallback();
  }
}
