import { Cell as UiCell, Grid as UiGrid, Row as UiRow } from '@material/react-layout-grid';
import styled, { css } from 'styled-components/macro';

export const Grid = styled(UiGrid)<{ $fullHeight?: boolean }>`
  min-width: 100%;
  ${({ $fullHeight }) =>
    $fullHeight &&
    css`
      height: 100%;
    `};
`;

export default Grid;

export const Row = styled(UiRow)<{ $fullHeight?: boolean }>`
  min-width: 100%;

  ${({ $fullHeight }) =>
    $fullHeight &&
    css`
      height: 100%;
    `}
`;

export const Cell = styled(UiCell)<{ $fullHeight?: boolean; $maxHeight?: string }>`
  ${({ $fullHeight }) =>
    $fullHeight &&
    css`
      height: 100%;
    `}

  ${({ $maxHeight }) =>
    $maxHeight &&
    css`
      max-height: ${$maxHeight};
      height: ${$maxHeight};
    `}
`;
