import { useMemo } from 'react';
import * as Yup from 'yup';

import { BLACKLISTS_TYPE } from '.';

const useSchema = (type?: BLACKLISTS_TYPE) => {
  const athletesBlacklistsSchema = useAthletesBlacklistsSchema();
  const sportDisciplinesBlacklistsSchema = useSportDisciplinesBlacklistsSchema();

  return useMemo(() => {
    switch (type) {
      case BLACKLISTS_TYPE.ATHLETES:
        return athletesBlacklistsSchema;
      case BLACKLISTS_TYPE.SPORT_DISCIPLINES:
        return sportDisciplinesBlacklistsSchema;
      default:
        throw Error('Undefined blacklists type');
    }
  }, [athletesBlacklistsSchema, sportDisciplinesBlacklistsSchema, type]);
};

const commonFields = {
  usersId: Yup.number().nullable().default(null),
  comments: Yup.string().nullable().default(null),
};

const useAthletesBlacklistsSchema = () => {
  return Yup.object({
    ...commonFields,
    athletesId: Yup.number().nullable().required().default(null),
  });
};

const useSportDisciplinesBlacklistsSchema = () => {
  return Yup.object({
    ...commonFields,
    sportDisciplinesId: Yup.number().nullable().required().default(null),
    sportsId: Yup.number().nullable().required().default(null),
  });
};

export default useSchema;

export type AthletesBlacklistsFormData = Yup.TypeOf<ReturnType<typeof useAthletesBlacklistsSchema>>;
export type SportDisciplinesBlacklistsFormData = Yup.TypeOf<
  ReturnType<typeof useSportDisciplinesBlacklistsSchema>
>;
