/**
 * Generate Fake ID for lists
 * by convention its negative number
 * @returns number
 */
function getFakeId(): number {
  if (process.env.NODE_ENV === 'test') {
    return -1;
  }
  return (Math.random() * -10000) | 0;
}

export default getFakeId;
