import { ReactElement } from 'react';
import { useTranslation } from 'react-i18next';

import { entities, EntityKey, EntityMap } from 'app/entity';

import FormDetailPage from '.';
import type { Props as FormDetailPageProps } from './';

interface Props<K extends EntityKey, FormData, ValidFormData extends FormData = FormData>
  extends Omit<
    FormDetailPageProps<
      EntityMap[K]['detailModel'],
      FormData,
      EntityMap[K]['requestModel'],
      ValidFormData
    >,
    'api' | 'urls' | 'entityName' | 'permission'
  > {
  entity: EntityKey;
  apiContext: Parameters<EntityMap[K]['api']>[0];
  urlsContext: Parameters<EntityMap[K]['urls']>[0];
}

function EntityDetailPage<K extends EntityKey, D, VD extends D = D>({
  entity,
  apiContext,
  urlsContext,
  ...props
}: Props<K, D, VD>): ReactElement {
  const { t } = useTranslation();
  const meta = entities[entity];
  type Model = typeof meta.gridModel;
  type Request = typeof meta.requestModel;

  return (
    // this ignore it must be here, bcs form data are unknown, maybe once we will have Schema Map ? - https://cannypack.atlassian.net/browse/MODFE-2854
    // @ts-ignore
    <FormDetailPage<Model, D, Request, VD>
      permission={meta.permission}
      entityName={meta.name(t)}
      urls={meta.urls(urlsContext as any)}
      api={meta.api(apiContext as any)}
      {...props}
    />
  );
}

export default EntityDetailPage;
