import { useFormikContext } from 'formik';
import { FC, useContext } from 'react';
import { useTranslation } from 'react-i18next';

import { FormContext } from 'core/components/Form';
import Field from 'core/components/Form/Field';
import {
  TwoFieldsInputWrapper,
  TwoFieldsSwitchWrapper,
  TwoFieldsWrapper,
} from 'core/components/FormStyles';
import FormSwitch from 'core/components/FormSwitch';
import FormTextfield from 'core/components/FormTextfield';
import { FullRowCell } from 'core/components/GridCell';

import { MissionFormData } from '../../../hooks/useMissionSchema';

type Props = {
  canAutogenerate: boolean;
  autoFocus?: boolean;
};
const MissionCodeField: FC<Props> = ({ canAutogenerate, autoFocus }) => {
  const { t } = useTranslation();
  const { values, setValues } = useFormikContext<MissionFormData>();
  const { isFieldInViewMode } = useContext(FormContext);

  const { generateCode } = values;
  const codeLabel = `${t('Code')} ${(canAutogenerate && !generateCode && '*') || ''}`;

  const codeField = (
    <Field
      viewMode={generateCode || isFieldInViewMode('code')}
      label={codeLabel}
      name="code"
      component={FormTextfield}
      autoFocus={autoFocus}
      fast={false}
    />
  );

  const handleGenerateCodeChange = (newValue: boolean) => {
    setValues({
      ...values,
      generateCode: newValue,
      code: newValue ? t('Autogenerated') : null,
    });
  };

  return (
    <FullRowCell>
      <TwoFieldsWrapper>
        <TwoFieldsInputWrapper>{codeField}</TwoFieldsInputWrapper>
        {canAutogenerate ? (
          <TwoFieldsSwitchWrapper>
            <Field
              onChange={(newValue: boolean) => handleGenerateCodeChange(newValue)}
              name="generateCode"
              tooltip={t('Generate code')}
              component={FormSwitch}
              fast={false}
            />
          </TwoFieldsSwitchWrapper>
        ) : null}
      </TwoFieldsWrapper>
    </FullRowCell>
  );
};

export default MissionCodeField;
