import { FC, useCallback, memo } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch } from 'react-redux';
import { TypeOf } from 'yup';

import { sendEmail } from 'core/actions';
import Form from 'core/components/Form';
import { DialogFormWrapper } from 'core/components/FormStyles';
import Modal from 'core/components/Modal';
import useAppSelector from 'core/hooks/useAppSelector';

import Fieldset from './fieldset';
import { getRecipientRequiredParamName } from './helpers';
import useSchema from './useSchema';

export interface EmailType {
  code: string;
  title: string;
  withAdditionalMessage?: boolean;
  attachmentsEndpoint?: string;
  customRecipients?: RECIPIENTS[];
  otherEmailsDisabled?: boolean;
}
export interface Props {
  open: boolean;
  onClose: () => void;
  recipientParams?: RecipientParams;
  endpoint: string;
  emailTypes: EmailType[];
}

// TODO: Extract From Core to specific project: https://cannypack.atlassian.net/browse/MODFE-1780
export enum RECIPIENTS {
  OTHER = 0,
  LABORATORY = 1,
  AUTHORITY = 2,
  DCO = 3,
  ATHLETE = 4,
}

export type RecipientParams = Map<RECIPIENTS, { [key: string]: number }>;

const EmailsDialog: FC<Props> = ({ onClose, open, recipientParams, endpoint, emailTypes }) => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const saveInProgress = useAppSelector(({ core }) => core.dialogSaveInProgress);
  const handleCloseIfCan = useCallback(() => {
    if (!saveInProgress) {
      onClose();
    }
  }, [saveInProgress, onClose]);

  const schema = useSchema();
  const initialValues = schema.cast({
    emailType: emailTypes[0].code,
    withRecipients: Boolean(
      emailTypes[0].customRecipients?.length || !emailTypes[0].otherEmailsDisabled
    ),
  });

  const dispatchSendEmail = useCallback(
    (values: any, endpoint: string, successCallback: () => void) => {
      dispatch(sendEmail(values, endpoint, successCallback));
    },
    [dispatch]
  );

  const outputMapping = useCallback(
    (values: TypeOf<typeof schema>) => {
      const formData: any = {
        emails: values.emails,
        customText: values.customText ? values.customText : undefined,
        filesId: values.filesId ? values.filesId : undefined,
        emailType: values.emailType,
      };
      values.recipients &&
        values.recipients.forEach((recipient) => {
          const requiredParamsObject =
            recipientParams && recipientParams.get(recipient as RECIPIENTS);
          const requiredParamName = getRecipientRequiredParamName(recipient as RECIPIENTS);
          if (requiredParamName && requiredParamsObject) {
            formData[requiredParamName] = requiredParamsObject[requiredParamName];
          }
        });
      return formData;
    },
    [recipientParams]
  );

  return (
    <Modal
      ariaLabel={t('Outbound Communication dialog')}
      title={t('Send e-mail')}
      open={open}
      confirmButton={null}
      cancelButton={null}
      onClose={handleCloseIfCan}
    >
      <Form
        initialValues={initialValues}
        validationSchema={schema}
        id="emailsDialog"
        onSubmit={(values, formik) => {
          const formData = outputMapping(values);

          dispatchSendEmail(formData, endpoint, () => {
            formik.resetForm();
            onClose();
          });
        }}
      >
        <DialogFormWrapper>
          <Fieldset
            saveInProgress={saveInProgress}
            onClose={handleCloseIfCan}
            emailTypes={emailTypes}
            recipientParams={recipientParams}
          />
        </DialogFormWrapper>
      </Form>
    </Modal>
  );
};

export default memo(EmailsDialog);
