import { createBrowserHistory, Location } from 'history';

const history = createBrowserHistory();
let unblockCallback: (() => void) | undefined;
let visited = 0;

// This is hack for getting if it's possible to redirect back in Error page
history.listen((location, method) => {
  if (method === 'POP') {
    visited -= 1;
  }
  if (method === 'PUSH') {
    visited += 1;
  }
});

export const canGoBack = () => {
  return visited > 0;
};

export const initialHistoryLength = history.length;

export default history;

export function blockRedirect(cb: (location: Location) => string | false | void) {
  unblockCallback = history.block(cb);
}

export function unblockRedirect(): void {
  unblockCallback && unblockCallback();
  unblockCallback = undefined;
}
