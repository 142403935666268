import { toast } from 'react-toastify';
import { put, race, take } from 'redux-saga/effects';

import { apiCall, CustomOperation, SaveWatchersAction, TYPE_SAVE_WATCHERS } from 'core/actions';
import { stringify } from 'core/functions/qs';
import { t } from 'core/i18n';

export function* saveWatchersSaga(action: SaveWatchersAction) {
  const { watcherIds, endpoint, successCallback, showSuccess, entityIds } = action.payload;

  const operations: CustomOperation[] =
    entityIds && entityIds.length
      ? [
          {
            fieldName: 'watchers',
            operation: 'add',
            type: 'EXTENDED',
          },
        ]
      : [];

  yield put(
    apiCall(
      TYPE_SAVE_WATCHERS,
      'PATCH',
      endpoint,
      {
        watchers: watcherIds,
      },
      entityIds && entityIds.length
        ? {
            params: {
              id: entityIds,
            },
            paramsSerializer: (params) =>
              stringify(params, {
                encodeValuesOnly: true,
              }),
          }
        : {},
      true,
      {
        watchers: null,
      },
      [],
      true,
      operations
    )
  );

  const { success } = yield race({
    success: take(`${TYPE_SAVE_WATCHERS}_SUCCESS`),
    error: take(`${TYPE_SAVE_WATCHERS}_ERROR`),
  });

  if (success) {
    successCallback && successCallback();

    if (showSuccess) {
      if (success.payload.response?.data?.updated !== undefined) {
        toast.success(
          t('Affected records: {{count}}', {
            count: success.payload.response?.data?.updated,
          })
        );
      } else {
        toast.success(t('Successfully edited'));
      }
    }
  }
}
