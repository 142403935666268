import { t } from 'core/i18n';
import { DisabilitiesId } from 'core/models/Disability';

export const getDisabilityName = (disabilitiesId?: DisabilitiesId | null): string => {
  const disabilityNames = {
    [DisabilitiesId.AMPUTEE]: t('Amputee'),
    [DisabilitiesId.CEREBRAL_PALSY]: t('Cerebral Palsy'),
    [DisabilitiesId.INTELLECTUAL_DISABILITY]: t('Intellectual Disability'),
    [DisabilitiesId.SPINAL_INJURY]: t('Spinal Injury'),
    [DisabilitiesId.VISUAL_IMPAIRMENT]: t('Visual Impairment/Blind'),
    [DisabilitiesId.OTHER]: t('Other'),
  };

  if (!disabilitiesId) return '';
  return disabilityNames[disabilitiesId];
};
