import { ColDef } from 'ag-grid-community';
import { PropsWithChildren } from 'react';

import { StyledIconButton } from './styled';

type Props<T> = {
  data: T;
  colDef: ColDef<T>;
};

const IconButtonCellRenderer = <T extends {}>({
  data,
  colDef,
  ...props
}: PropsWithChildren<Props<T>>) => {
  const icon = colDef?.cellRendererParams?.icon
    ? colDef?.cellRendererParams?.icon(data)
    : undefined;
  const disabled =
    'disabled' in colDef?.cellRendererParams ? colDef?.cellRendererParams.disabled(data) : false;
  return (
    <StyledIconButton
      {...props}
      onClick={
        colDef?.cellRendererParams?.onClick
          ? () => {
              if (colDef?.cellRendererParams?.onClick) {
                colDef?.cellRendererParams?.onClick(data);
              }
            }
          : undefined
      }
      dense
      icon={icon}
      disabled={disabled}
    />
  );
};

export default IconButtonCellRenderer;
