import styled from 'styled-components/macro';

import theme from 'app/theme';

export const ListWrapper = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  overflow-x: auto;

  > *:not(:last-child) {
    border-radius: 50%;
    box-sizing: content-box;
    border: 0.2rem solid ${theme.color.white};

    &:not(:first-child) {
      margin-left: -0.35rem;
    }
  }

  > *:last-child:not(:first-child) {
    margin-left: 1rem;
  }

  a {
    text-decoration: none;
  }
`;
