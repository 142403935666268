import { FC } from 'react';
import { useTranslation } from 'react-i18next';

import { GroupTitle } from './styled';

export interface ListGroupTitleItem {
  onMarkAsRead?: () => void;
  title: string;
}

const RenderGroupTitle: FC<{ item: undefined | ListGroupTitleItem; style: object }> = ({
  item = {} as ListGroupTitleItem,
  style,
}) => {
  const { title, onMarkAsRead } = item;
  const { t } = useTranslation();
  return (
    <GroupTitle style={style}>
      <span>{title}</span>
      {onMarkAsRead && (
        <a
          title={t('Mark all notifications as read')}
          onClick={() => onMarkAsRead()}
          href="#markAllAsRead"
        >
          {t('Mark all as Read')}
        </a>
      )}
    </GroupTitle>
  );
};

export default RenderGroupTitle;
