import styled, { css } from 'styled-components/macro';

import theme from 'app/theme';
import MaterialIcon from 'core/components/MaterialIcon';

export const FilePickerContainer = styled.div<{ active: boolean; hasError?: boolean }>`
  display: flex;
  justify-content: center;
  border-radius: 0.25rem;
  overflow: hidden;
  position: relative;
  background: ${(props) => (props.active ? theme.color.primary50 : 'inherit')};
  border: ${(props) => (props.hasError ? `1px solid var(--mdc-theme-error)` : 'none')};
`;

export const StyledMaterialIcon = styled(MaterialIcon)`
  display: flex;
  justify-content: center;
  font-size: 3rem;
  opacity: 0.25;
  width: 100%;
  height: 3rem;
  align-items: center;
  cursor: pointer;
`;

export const FilePickerWrapper = styled.div<{ disabled?: boolean }>`
  width: 100%;
  padding: 2rem;
  height: 18.75rem;
  background: ${theme.color.gray};
  display: flex;
  flex-wrap: wrap;
  opacity: 0.6;
  justify-content: center;
  align-items: center;
  flex-direction: column;

  &:hover {
    ${(p) =>
      !p.disabled &&
      css`
        cursor: pointer;
        opacity: 0.9;
      `}
  }
`;

export const StyledTextWrapper = styled.div`
  width: 100%;
  text-align: center;
`;

export const StyledHeadline = styled.div`
  font-size: 1.5rem;
  display: block;
`;

export const StyledSecondaryText = styled.div`
  font-size: 0.875rem;
  display: block;
`;

export const FilesDetailWrapper = styled.div`
  width: 100%;
  padding: 0.25rem;
`;

export const FileInsideWrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  min-height: 2rem;
`;

export const FileName = styled.div`
  display: flex;
  flex: 1;
  min-width: 0;
  align-items: center;
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;

  .material-icons {
    margin-right: 0.5rem;
  }

  p {
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
    margin: 0;
  }
`;
