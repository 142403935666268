import { useMemo } from 'react';
import { useTranslation } from 'react-i18next';

import getNavGroups, { NavGroup } from 'app/getNavGroups';

import useAppSelector from './useAppSelector';
import { useGetPermission } from './usePermission';

const useNavGroups = () => {
  const getPermission = useGetPermission();
  const { t } = useTranslation();
  const rolesId = useAppSelector(({ core }) => core.user?.rolesId);
  const options = useAppSelector(({ core }) => core.user?.clientOptions);

  return useMemo(() => {
    const groupItems = getNavGroups(t);

    // Filter groupItems
    const filteredByItems: NavGroup[] = groupItems.map((g) => ({
      ...g,
      items: g.items?.filter((i) => {
        if (i.restrictedToRoles && !i.restrictedToRoles.includes(rolesId!)) {
          return false;
        }

        let clientOptionEnabled = i.clientOption ? false : true;
        if (i.clientOption) {
          i.clientOption.forEach((o) => {
            if (options?.[o] && options[o].value === '1') {
              clientOptionEnabled = true;
            }
          });
        }

        const permission = (i.permission || i.key).replace(':find', '');

        const hasPermission =
          getPermission(`${permission}:find`) ||
          getPermission(`${g.permission || g.key}[scope:${permission}]`);

        return clientOptionEnabled && hasPermission;
      }),
    }));

    // Filter groups by permissions, client options and children count
    return filteredByItems.filter((g) => {
      if (g.restrictedToRoles && !g.restrictedToRoles.includes(rolesId!)) {
        return false;
      }

      let clientOptionEnabled = g.clientOption ? false : true;
      if (g.clientOption) {
        g.clientOption.forEach((o) => {
          if (options?.[o] && options[o].value === '1') {
            clientOptionEnabled = true;
          }
        });
      }
      const hasSubItems = g.items && g.items.length > 0;

      const hasPermission = getPermission(g.permission || `${g.key}:find`);

      return hasSubItems ? true : clientOptionEnabled && hasPermission;
    });
  }, [t, rolesId, getPermission, options]);
};

export default useNavGroups;
