import { useCallback } from 'react';

import { AccreditationsRole } from 'app/models/Accreditation';
import TeamMember, { TeamMemberStatus } from 'planning/models/TeamMember';

import { TeamMemberData, mapMember } from '../TeamTab/useTeamInputMapping';

export type OfficerEntityData = {
  teamMembers?: TeamMember[];
};

export type OfficersFormData = ReturnType<ReturnType<typeof useOfficersInputMapping>>;

/**
 * Splits assignment teamMembers to:
 * - invitedMembers
 * - assignedMembers
 * - dcos
 * - bcos
 * - leadDco
 * Because of backward compatibility with the original assignment process
 */
const useOfficersInputMapping = (mode: 'edit' | 'clone' | 'create') => {
  return useCallback(
    (data: OfficerEntityData) => {
      const assigned: TeamMemberData[] = (data.teamMembers || [])
        .filter((it) => it.status === TeamMemberStatus.CONFIRMED)
        .map((it) => mapMember(it, mode));

      const invited: TeamMemberData[] = (data.teamMembers || [])
        .filter((member) => member.status !== TeamMemberStatus.CONFIRMED)
        .map((it) => mapMember(it, mode));

      return {
        invitedMembers: invited,
        assignedMembers: assigned,
        dcos: assigned
          .filter((it) => (it.roles || []).includes(AccreditationsRole.DCO))
          .map((it) => it.usersId),
        bcos: assigned
          .filter((it) => (it.roles || []).includes(AccreditationsRole.BCO))
          .map((it) => it.usersId),
        chaperones: assigned
          .filter((it) => (it.roles || []).includes(AccreditationsRole.CHAPERONE))
          .map((it) => it.usersId),
        leadDcosId:
          assigned.find((it) => (it.roles || []).includes(AccreditationsRole.LEAD_DCO))?.usersId ||
          null,
      };
    },
    [mode]
  );
};

export default useOfficersInputMapping;
