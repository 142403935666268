import { useMemo } from 'react';
import { useTranslation } from 'react-i18next';

import useMultiDropdownOptionsFilter from 'core/hooks/useMultiDropdownOptionsFilter';

import useAuditedEntities from './useAuditedEntities';

const useEntitiesFilter = () => {
  const { t } = useTranslation();

  const auditedEntities = useAuditedEntities();

  const options = useMemo(
    () => [
      {
        id: 'any',
        name: t('Any'),
      },
      ...auditedEntities.map((it) => ({
        id: it.key,
        name: it.name,
      })),
    ],
    [auditedEntities, t]
  );

  return useMultiDropdownOptionsFilter(t('Entity'), 'object', options, ['any']);
};

export default useEntitiesFilter;
