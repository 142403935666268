import { useField, useFormikContext } from 'formik';
import { FC, useCallback, useContext, useMemo } from 'react';
import { useTranslation } from 'react-i18next';

import Button from 'core/components/Button';
import DataCard from 'core/components/DataCard';
import { DataCardItem } from 'core/components/DataCard/props';
import { FlexCell } from 'core/components/FlexUtils';
import { FormContext } from 'core/components/Form';
import Field from 'core/components/Form/Field';
import FormCalendarInput from 'core/components/FormCalendarInput';
import FormTextfield from 'core/components/FormTextfield';
import MaterialIcon from 'core/components/MaterialIcon';
import SelectOtherField from 'core/components/SelectOtherField';
import getFakeId from 'core/functions/getFakeId';
import { useBoolClientOption } from 'core/hooks/useClientOption';
import { Substance } from 'planning/models/TestResult';

import useFrequencyOptions from './useFrequencyOptions';
import useMedicationUnitOptions from './useMedicationUnitOptions';
import useRouteOfAdministrationOptions from './useRouteOfAdministrationOptions';

interface Props {
  /**
   * Path to the field, e.g., medications.advancedMedications
   */
  fieldName?: string;

  /**
   * Override value
   */
  value?: Substance[];

  /**
   * Override viewMode
   */
  viewMode?: boolean;
}

const AdvancedMedicationsCard: FC<Props> = ({ fieldName = '', value, viewMode }) => {
  const [{ value: formValue }, , { setValue }] = useField<Substance[] | null>(fieldName);
  const { setFieldValue } = useFormikContext();
  const items = useMemo(() => value || formValue || [], [formValue, value]);

  const { t } = useTranslation();
  const frequencyOptions = useFrequencyOptions();
  const medicationUnitOptions = useMedicationUnitOptions();
  const routeOptions = useRouteOfAdministrationOptions();
  const medicationNameMandatoryOnlyEnabled = useBoolClientOption('medicationNameMandatoryOnly');

  const { viewMode: formViewMode, defaultViewMode } = useContext(FormContext);
  const isInViewMode =
    viewMode !== undefined
      ? viewMode
      : formViewMode && formViewMode.hasOwnProperty(fieldName)
        ? formViewMode[fieldName]
        : formViewMode && defaultViewMode;

  const onRemove = useCallback(
    (selection: DataCardItem | DataCardItem[]) => {
      const deletedIds = new Set(
        Array.isArray(selection) ? selection.map(({ id }) => id) : [selection.id]
      );
      setValue(items.filter(({ id }) => !deletedIds.has(id)));
    },
    [items, setValue]
  );

  return (
    <DataCard
      title={t('Items')}
      items={items}
      onRemove={!isInViewMode && items.length > 1 ? onRemove : undefined}
      footer={
        !isInViewMode && (
          <Button
            onClick={() =>
              setValue([
                ...items,
                {
                  id: getFakeId().toString(),
                  name: '',
                  dose: '',
                  frequency: '',
                  lastTakenAt: medicationNameMandatoryOnlyEnabled ? null : new Date(),
                  route: medicationNameMandatoryOnlyEnabled ? '' : 'Oral',
                  unit: '',
                },
              ])
            }
            text={t('Add new item')}
            icon={<MaterialIcon icon="add" />}
            type="button"
          />
        )
      }
    >
      {(item, index) => (
        <>
          <FlexCell minWidth="17rem" flex={2} block>
            <Field
              component={FormTextfield}
              viewMode={!!isInViewMode}
              label={t('Name of Medication/Supplement/Method')}
              type="text"
              name={`${fieldName}[${index}].name`}
              dummyValue={item.name}
            />
          </FlexCell>

          <FlexCell minWidth="7rem" flex={1} block>
            <Field
              component={FormTextfield}
              viewMode={!!isInViewMode}
              label={t('Dose')}
              type="text"
              name={`${fieldName}[${index}].dose`}
              dummyValue={item.dose}
            />
          </FlexCell>

          <FlexCell minWidth="7rem" flex={1} block>
            <SelectOtherField
              label={t('Unit')}
              options={medicationUnitOptions}
              name={`${fieldName}[${index}].unit`}
              viewMode={!!isInViewMode}
              dummyValue={isInViewMode ? item.unit : undefined}
              onDropdownChange={(value) => {
                setFieldValue(`${fieldName}[${index}].unit`, value);
              }}
              onClickOther={() => setFieldValue(`${fieldName}[${index}].unit`, null)}
              otherOption={{
                value: 'Other',
                prependOptions: false,
              }}
            />
          </FlexCell>

          <FlexCell minWidth="10rem" flex={1} block>
            <Field
              component={FormCalendarInput}
              label={t('Date Last Taken')}
              name={`${fieldName}[${index}].lastTakenAt`}
              dummyValue={item.lastTakenAt}
              viewMode={!!isInViewMode}
            />
          </FlexCell>

          <FlexCell minWidth="10rem" flex={1} block>
            <SelectOtherField
              label={t('Frequency')}
              options={frequencyOptions}
              name={`${fieldName}[${index}].frequency`}
              viewMode={!!isInViewMode}
              dummyValue={isInViewMode ? item.frequency : undefined}
              onDropdownChange={(value) => {
                setFieldValue(`${fieldName}[${index}].frequency`, value);
              }}
              onClickOther={() => setFieldValue(`${fieldName}[${index}].frequency`, null)}
              otherOption={{
                value: 'Other',
                prependOptions: false,
              }}
            />
          </FlexCell>

          <FlexCell minWidth="17rem" flex={2} block>
            <SelectOtherField
              label={t('Route of Administration')}
              options={routeOptions}
              name={`${fieldName}[${index}].route`}
              viewMode={!!isInViewMode}
              dummyValue={isInViewMode ? item.route : undefined}
              onDropdownChange={(value) => {
                setFieldValue(`${fieldName}[${index}].route`, value);
              }}
              onClickOther={() => setFieldValue(`${fieldName}[${index}].route`, null)}
              otherOption={{
                value: 'Other',
                prependOptions: false,
              }}
            />
          </FlexCell>
        </>
      )}
    </DataCard>
  );
};

export default AdvancedMedicationsCard;
