import { toast } from 'react-toastify';
import { put, take, race } from 'redux-saga/effects';

import { apiCall, beginEditEntity, finishEditEntity, loadProfile } from 'core/actions';
import { t } from 'core/i18n';
import {
  TYPE_LOAD_ADAMS_SETTINGS,
  TYPE_EDIT_ADAMS_SETTINGS,
  EditAdamsSettingsAction,
} from 'settings/actions/index';
import { PASSWORD_DEFAULT } from 'settings/containers/AdamsSettingsPage/useSchema';
import { protectedAdamsSettings } from 'settings/reducer';

export function* loadAdamsSettingsSaga() {
  yield put(apiCall(TYPE_LOAD_ADAMS_SETTINGS, 'GET', `/client/adams`, {}));
}

export function* editAdamsSettingsSaga(action: EditAdamsSettingsAction) {
  yield put(beginEditEntity());

  const { fd } = action.payload;

  const enabled = fd['enable'];

  const adamsSettings = Object.entries(fd)
    .map(([key, value]) => {
      if (key === 'enable') {
        value = value ? '1' : '0';
      } else if (protectedAdamsSettings.has(key) && value === PASSWORD_DEFAULT) {
        return false;
      } else if (key === 'adamsCustomPoolMapping' && Array.isArray(value)) {
        value = JSON.stringify(
          value.reduce(
            (acc, item) => {
              // These are both required
              acc[item.modoc as string] = item.adams as string;
              return acc;
            },
            {} as Record<string, string>
          )
        );
      } else {
        value = enabled ? value : null;
      }

      return { key, value };
    })
    .filter((i) => i);

  yield put(apiCall(TYPE_EDIT_ADAMS_SETTINGS, 'POST', '/client/adams', adamsSettings));

  const { success } = yield race({
    success: take(`${TYPE_EDIT_ADAMS_SETTINGS}_SUCCESS`),
    error: take(`${TYPE_EDIT_ADAMS_SETTINGS}_ERROR`),
  });

  yield put(finishEditEntity());

  if (success) {
    yield put(loadProfile());
    toast.success(t('Settings successfully updated'));
  }
}
