import styled, { css } from 'styled-components/macro';

export const hidePrint = css`
  @media print {
    display: none;
  }
`;

export const onlyPrint = css`
  @media screen {
    display: none;
  }
`;

export const invisiblePrint = css`
  @media print {
    opacity: 0;
    visibility: hidden;
    pointer-events: none;
  }
`;

export const PrintContainer = styled.div`
  ${onlyPrint}
`;
