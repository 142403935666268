import { FC } from 'react';

import DummyField, { DummyFieldProps } from 'core/components/DummyField';
import { Props as CalendarInputProps } from 'core/components/FormCalendarInput';
import { useFormatDateTime, useFormatDateTimeRange } from 'core/i18n/useFormatDateTime';

type Props = Partial<Omit<DummyFieldProps, 'value'> & CalendarInputProps & { timezone?: string }>;

const DummyCalendarField: FC<Props> = ({
  format = 'DATE_SHORT',
  label,
  field,
  value,
  ...passingProps
}) => {
  const formatDateTime = useFormatDateTime();
  const formatDateTimeRange = useFormatDateTimeRange();

  let val = value ?? field?.value;

  if (val && typeof val === 'object' && val.hasOwnProperty('from')) {
    val = formatDateTimeRange(val.from, val.to, format);
  } else if (Array.isArray(val)) {
    val = formatDateTimeRange(val[0], val[1], format);
  } else {
    val = val ? formatDateTime(val, format) : '';
  }

  return <DummyField value={val} label={label || ''} {...passingProps} />;
};

export default DummyCalendarField;
