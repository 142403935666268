import { HelperText } from '@material/react-text-field';
import styled from 'styled-components/macro';

export const StyledHelperText = styled(HelperText)`
  font-size: 0.75rem;
  margin-left: 0.375rem;
  padding-top: 0.2rem;
  margin-top: 1px;
  opacity: 1;
`;
