import { FieldProps, getIn } from 'formik';
import { FC, useCallback, memo, useMemo } from 'react';
import { useTranslation } from 'react-i18next';

import Button from 'core/components/Button';
import { Cell as GridCell } from 'core/components/Grid';
import MaterialIcon from 'core/components/MaterialIcon';

import { AnalysesFieldsData } from '../useAnalysesFieldsSchema';

const AddOtherButton: FC<FieldProps<any[]> & { listName: string; options: any[] }> = ({
  field: { value },
  listName,
  options,
  form,
}) => {
  const { t } = useTranslation();
  const list = useMemo(
    () => (getIn(form.values, listName) || []) as AnalysesFieldsData['otherBloodAnalyses'],
    [form.values, listName]
  );

  const availableItems = useMemo(
    () => (options || []).filter((i) => !(list || []).find((j) => j.id === i.id)),
    [options, list]
  );

  const handleAddClick = useCallback(() => {
    if (!availableItems[0]) {
      return;
    }
    // @ts-ignore
    form.setFieldValue(listName, [...(list || []), { id: availableItems[0].id, remarks: null }]);
  }, [listName, form, availableItems, list]);

  if (
    !availableItems ||
    !availableItems.length ||
    !value ||
    value.indexOf('other') === -1 ||
    options.length === 0
  ) {
    return null;
  }

  return (
    <GridCell desktopColumns={12} tabletColumns={8} phoneColumns={4}>
      <Button
        text={t('Add Additional Analysis')}
        icon={<MaterialIcon icon="add" />}
        onClick={handleAddClick}
        type="button"
      />
    </GridCell>
  );
};

export default memo(AddOtherButton);
