import { TFunction } from 'i18next';
import { useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import * as Yup from 'yup';

import { getTabMeta } from 'core/components/Form/TabErrorCollector';
import useAppSelector from 'core/hooks/useAppSelector';
import { useBoolClientOption } from 'core/hooks/useClientOption';
import { CovidSrfStatus, DEFAULT_NOTIFICATION_ARRIVAL_DELAY } from 'settings/models/Setting';

import { SESSION_LIFETIME_DEFAULT } from './Features';
import {
  ENABLED_INVOICE_AUTO_APPROVAL_DURATION_DAYS,
  VALUE_INVOICE_AUTO_APPROVAL_DURATION_DAYS,
} from './Features/AutomaticApproval';
import { autoArchiveOptions } from './Features/useAutoArchiveItems';

const tabMeta = getTabMeta<'features' | 'defaults' | 'additional-values'>();

export const DEFAULT_TEST_PUBLISH_OFFSET = 1;

// Creating it this way, so I can use this schema in reducer, where I can't use hooks
export const getSampleCodeSchema = (t: TFunction) =>
  Yup.object({
    enabled: Yup.boolean().nullable().default(false),
    alphanumeric: Yup.boolean().default(true),
    min: Yup.number()
      .min(0)
      .test(
        'minMax',
        t('The minimum value must be less than or equal to the maximum value'),
        (value, context) => {
          // @ts-ignore
          const max = Number(context.from[0].value?.max || 0);
          const min = Number(value || 0);
          return min <= max;
        }
      )
      .nullable()
      .default(0),
    max: Yup.number().min(0).nullable().default(0),
  }).nullable();

export const useSampleCodeSchema = () => {
  const { t } = useTranslation();
  return getSampleCodeSchema(t);
};

export type SampleCodeType = Yup.TypeOf<ReturnType<typeof useSampleCodeSchema>>;

const useSchema = () => {
  const sampleCodeSchema = useSampleCodeSchema();
  const commonSetting = useMemo(() => Yup.string().nullable().default(null), []);
  const requiredNumber = useMemo(
    () => ({
      is: true,
      then: Yup.number().nullable().required().default(null),
    }),
    []
  );
  const requiredString = useMemo(
    () => ({
      is: true,
      then: Yup.string().nullable().required().default(null),
    }),
    []
  );

  const commonEnabled = useMemo(() => Yup.boolean().nullable().default(false), []);

  const clientOptions = useAppSelector(({ core: { user } }) => user?.clientOptions || {});
  const tdpEnabled = useBoolClientOption('enableTdp');
  const autoArchiveEnabled = useBoolClientOption('enableAutoArchive');

  const autoArchiveFields = useMemo(
    () =>
      autoArchiveOptions.reduce(
        (acc, option) => {
          if ('clientOption' in option && clientOptions[option.clientOption]?.value !== '1')
            return acc;

          acc[`enabled_${option.key}`] = commonEnabled;

          if (option.type === 'AGE') {
            acc[`value_${option.key}`] = Yup.number()
              .nullable()
              .default(null)
              .meta(tabMeta('features'))
              .when(`enabled_${option.key}`, requiredNumber);
          }

          return acc;
        },
        {} as Record<string, Yup.AnySchema>
      ),
    [clientOptions, commonEnabled, requiredNumber]
  );

  return Yup.object({
    enabled_cocDriverMandatory: commonEnabled,
    value_cocDriverMandatory: commonSetting,

    enabled_enableAddressCopying: commonEnabled,
    value_enableAddressCopying: commonSetting,

    enabled_arrivalDelayCheckEnabled: commonEnabled,

    value_arrivalDelayMax: Yup.number()
      .nullable()
      .meta(tabMeta('features'))
      .when('enabled_arrivalDelayCheckEnabled', {
        is: true,
        then: (schema) => schema.required(),
      })
      .default(DEFAULT_NOTIFICATION_ARRIVAL_DELAY),

    enabled_enableCancelControl: commonEnabled,
    value_enableCancelControl: commonSetting,

    [ENABLED_INVOICE_AUTO_APPROVAL_DURATION_DAYS]: commonEnabled,
    [VALUE_INVOICE_AUTO_APPROVAL_DURATION_DAYS]: commonSetting,

    enabled_enableDriedBloodSpots: commonEnabled,
    value_enableDriedBloodSpots: commonSetting,

    enabled_enableSocialSecurityNumber: commonEnabled,
    value_enableSocialSecurityNumber: commonSetting,

    enabled_enableTeamAndBackNumber: commonEnabled,
    value_enableTeamAndBackNumber: commonSetting,

    enabled_enableTue: commonEnabled,
    value_enableTue: commonSetting,

    enabled_enableVenipuncture: commonEnabled,
    value_enableVenipuncture: commonSetting,

    enabled_enableBloodDcoSignature: commonEnabled,
    value_enableBloodDcoSignature: commonSetting,

    enabled_filterAthletePersonalData: commonEnabled,
    value_filterAthletePersonalData: commonSetting,

    enabled_enableLockTimer: commonEnabled,
    value_enableLockTimer: commonSetting,

    enabled_lockTimerTimeout: commonEnabled,
    value_lockTimerTimeout: Yup.number()
      .nullable()
      .default(null)
      .meta(tabMeta('features'))
      .min(2)
      .when('enabled_enableLockTimer', requiredNumber),

    enabled_requireLocking: commonEnabled,
    value_requireLocking: commonSetting,

    value_athleteDocumentNumber: Yup.string().nullable().meta(tabMeta('defaults')).default(null),

    enabled_hideAthleteDocumentNumber: commonEnabled,

    enabled_showCardAddressEndDate: commonEnabled,
    value_showCardAddressEndDate: commonSetting,

    enabled_defaultFederationsId: commonEnabled,
    value_defaultFederationsId: Yup.number()
      .nullable()
      .default(null)
      .meta(tabMeta('defaults'))
      .when('enabled_defaultFederationsId', requiredNumber),

    value_tdpClassPreference: Yup.number()
      .nullable()
      .default(null)
      .meta(tabMeta('defaults'))
      .when('enabled_defaultFederationsId', {
        is: () => tdpEnabled,
        then: (schema) => schema.required(),
      }),

    enabled_defaultDebtorsId: commonEnabled,
    value_defaultDebtorsId: Yup.number()
      .nullable()
      .default(null)
      .meta(tabMeta('defaults'))
      .when('enabled_defaultDebtorsId', requiredNumber),

    enabled_defaultCustomersId: commonEnabled,
    value_defaultCustomersId: Yup.number()
      .nullable()
      .default(null)
      .meta(tabMeta('defaults'))
      .when('enabled_defaultCustomersId', requiredNumber),

    enabled_defaultResultAuthority: commonEnabled,
    value_defaultResultAuthority: Yup.number()
      .nullable()
      .default(null)
      .meta(tabMeta('defaults'))
      .when('enabled_defaultResultAuthority', requiredNumber),

    enabled_defaultNotificationType: commonEnabled,
    value_defaultNotificationType: Yup.string()
      .nullable()
      .default(null)
      .meta(tabMeta('defaults'))
      .when('enabled_defaultNotificationType', requiredString),

    value_defaultUrineManufacturer: Yup.number().nullable().default(null).meta(tabMeta('defaults')),
    value_defaultBloodManufacturer: Yup.number().nullable().default(null).meta(tabMeta('defaults')),

    enabled_defaultUrineLaboratory: commonEnabled,
    value_defaultUrineLaboratory: Yup.number()
      .nullable()
      .default(null)
      .meta(tabMeta('defaults'))
      .when('enabled_defaultUrineLaboratory', requiredNumber),

    enabled_defaultBloodLaboratory: commonEnabled,
    value_defaultBloodLaboratory: Yup.number()
      .nullable()
      .default(null)
      .meta(tabMeta('defaults'))
      .when('enabled_defaultBloodLaboratory', requiredNumber),

    enabled_defaultTestAuthority: commonEnabled,
    value_defaultTestAuthority: Yup.number()
      .nullable()
      .default(null)
      .meta(tabMeta('defaults'))
      .when('enabled_defaultTestAuthority', requiredNumber),

    enabled_defaultSampleCollectionAuthority: commonEnabled,
    value_defaultSampleCollectionAuthority: Yup.number()
      .nullable()
      .default(null)
      .meta(tabMeta('defaults'))
      .when('enabled_defaultSampleCollectionAuthority', requiredNumber),

    enabled_defaultForDcc: commonEnabled,
    value_defaultForDcc: Yup.number()
      .nullable()
      .default(null)
      .meta(tabMeta('defaults'))
      .when('enabled_defaultForDcc', requiredNumber),

    enabled_defaultForEventName: commonEnabled,
    value_defaultForEventName: Yup.string()
      .nullable()
      .default(null)
      .meta(tabMeta('defaults'))
      .when('enabled_defaultForEventName', requiredString),

    enabled_covidDefaultAddress: commonEnabled,
    value_covidDefaultAddress: Yup.string()
      .nullable()
      .default(null)
      .meta(tabMeta('defaults'))
      .when('enabled_covidDefaultAddress', requiredString),

    value_availableDocumentLanguages: Yup.string()
      .nullable()
      .default(null)
      .meta(tabMeta('defaults')),
    value_alternativeLanguage: Yup.string().nullable().default(null).meta(tabMeta('defaults')),

    value_testPublishOffset: Yup.number()
      .min(DEFAULT_TEST_PUBLISH_OFFSET)
      .nullable()
      .default(null)
      .meta(tabMeta('defaults')),

    enabled_defaultAvailabilityCategoriesId: commonEnabled,
    value_defaultAvailabilityCategoriesId: Yup.number()
      .nullable()
      .default(null)
      .when('enabled_defaultAvailabilityCategoriesId', requiredNumber)
      .meta(tabMeta('defaults')),

    value_defaultCallingCode: Yup.string().nullable().default(null).meta(tabMeta('defaults')),

    enabled_defaultCocShippingType: commonEnabled,
    value_defaultCocShippingType: Yup.number()
      .nullable()
      .default(null)
      .meta(tabMeta('defaults'))
      .when('enabled_defaultCocShippingType', requiredNumber),

    enabled_defaultCocLaboratory: commonEnabled,
    value_defaultCocLaboratory: Yup.number()
      .nullable()
      .default(null)
      .meta(tabMeta('defaults'))
      .when('enabled_defaultCocLaboratory', requiredNumber),

    urineSampleCodeValidation: sampleCodeSchema.default(sampleCodeSchema.cast({})),
    partialUrineSampleCodeValidation: sampleCodeSchema.default(sampleCodeSchema.cast({})),
    bloodSampleCodeValidation: sampleCodeSchema.default(sampleCodeSchema.cast({})),
    dbsSampleCodeValidation: sampleCodeSchema.default(sampleCodeSchema.cast({})),

    enabled_enableCoachDoctor: commonEnabled,
    value_enableCoachDoctor: commonSetting,

    enabled_enableAthleteAutoSetAsMaster: commonEnabled,

    enabled_enableAthletesConsent: commonEnabled,
    value_enableAthletesConsent: commonSetting,

    enabled_enableAthleteAddressInTheApp: commonEnabled.default(true),

    enabled_enablePoolInTheApp: commonEnabled.default(true),

    enabled_athleteZipMandatory: commonEnabled,
    value_athleteZipMandatory: commonSetting,

    enabled_socialSecurityNumberMandatory: commonEnabled,
    value_socialSecurityNumberMandatory: commonSetting,

    enabled_allowDcoDetailEdit: commonEnabled,

    enabled_showArchivedToDcos: commonEnabled,
    enabled_showAnalysesToAssignedDco: commonEnabled,

    value_covidSrf: Yup.number()
      .nullable()
      .meta(tabMeta('defaults'))
      .default(CovidSrfStatus.ENABLED),
    value_sessionLifetime: Yup.number()
      .nullable()
      .min(1)
      .max(48)
      .required()
      .default(SESSION_LIFETIME_DEFAULT)
      .meta(tabMeta('defaults')),

    ...(autoArchiveEnabled ? autoArchiveFields : {}),
    enabled_enablePreviousDeclarations: commonEnabled,
    enabled_defaultForIsPersonallyKnownAllowed: commonEnabled,
    enabled_defaultForRequireNotificationSignature: commonEnabled,
    enabled_defaultForAllowDelayDeadline: commonEnabled,
    enabled_defaultForPrefillSportDiscipline: commonEnabled,
    enabled_defaultForSimplifiedSigning: commonEnabled,
    enabled_defaultForAdditionalAssignments: commonEnabled,
    enabled_defaultForUseAuthorityLogo: commonEnabled,
    enabled_defaultForUseAuthorityEmailTemplate: commonEnabled,
    enabled_defaultForUseAuthorityDcfTemplate: commonEnabled,
    enabled_defaultForUseCustomFileTemplate: commonEnabled,
    value_defaultForFileTemplatesId: commonSetting,
    enabled_defaultForUseAdamsAthleteLevel: commonEnabled,
    value_defaultForAdamsAthleteLevelsId: Yup.number()
      .nullable()
      .default(null)
      .meta(tabMeta('defaults'))
      .when('enabled_defaultForAdamsAthleteLevelsId', requiredNumber),
    enabled_defaultForNotifyOfTeamUpdates: commonEnabled,
    enabled_defaultForShowAthleteToDco: commonEnabled,
    enabled_defaultForShowInternalCommentToDco: commonEnabled,
    enabled_hideRestrictedAccessInUar: commonEnabled,
    enabled_enableAutoChangeTestStatusToUnsuccessful: commonEnabled,
    enabled_defaultForUse3genApp: commonEnabled,
    enabled_defaultForAllowIcPoolCollection: commonEnabled,

    enabled_tdpFederationMandatory: Yup.boolean().nullable().default(true),

    enabled_medicationNameMandatoryOnly: commonEnabled,
  });
};

export default useSchema;

export type FormData = Yup.TypeOf<ReturnType<typeof useSchema>>;
