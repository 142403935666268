import useAppSelector from 'core/hooks/useAppSelector';
import usePermission from 'core/hooks/usePermission';

const useUserCanModify = (permissionController: string, filesCreatorId?: number) => {
  const hasPermission = (filesPermission: boolean, controllerFilesPermission: boolean) => {
    if (permissionController === 'files') {
      return filesPermission;
    }
    return controllerFilesPermission;
  };

  const currentUsersId = useAppSelector(({ core }) => core.user?.id);
  const filesPatch = usePermission('files:patch');
  const controllerFilesPatch = usePermission(`${permissionController}:files[actions:patch]`);
  const filesDelete = usePermission('files:delete');
  const controllerFilesDelete = usePermission(`${permissionController}:files[actions:delete]`);
  const filesPatchScopeAll = usePermission(`files:patch[scope:all]`);
  const controllerFilesScopeAll = usePermission(`${permissionController}:files[scope:all]`);
  const filesDeleteScopeAll = usePermission(`files:delete[scope:all]`);

  const hasDeletePermission = hasPermission(filesDelete, controllerFilesDelete);
  const hasPatchPermission = hasPermission(filesPatch, controllerFilesPatch);
  const hasPatchScopeAll = hasPermission(filesPatchScopeAll, controllerFilesScopeAll);
  const hasDeleteScopeAll = hasPermission(filesDeleteScopeAll, controllerFilesScopeAll);
  const isUserFileOwner = currentUsersId === filesCreatorId;

  return {
    canEdit: hasPatchPermission && (hasPatchScopeAll || isUserFileOwner),
    canDelete: hasDeletePermission && (hasDeleteScopeAll || isUserFileOwner),
  };
};

export default useUserCanModify;
