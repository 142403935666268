import { compare, Operation } from 'fast-json-patch';
import differenceWith from 'lodash/differenceWith';
import isEqual from 'lodash/isEqual';
import omit from 'lodash/omit';

/**
 * Create patch from 2 objects
 */
export type AcceptedDataTypes = {
  [key: string]:
    | string
    | number
    | null
    | undefined
    | boolean
    | AcceptedDataTypes
    | Array<AcceptedDataTypes | string | number>;
};

export const basicMetaProperties = [
  'modifiedAt',
  'createdAt',
  'modifiedBy',
  'createdBy',
  'deleted',
] as const;

export default function createDiff(
  data: AcceptedDataTypes,
  prevData: AcceptedDataTypes,
  ignore: string[]
) {
  let diff: Operation[] = [];

  // We ignore meta parameters that are automatically updated on the server side
  const ignoredPrevParameters = [
    ...basicMetaProperties,
    ...ignore.filter((p) => !p.includes('[]')),
  ];
  const prevEntity = omit(prevData, ignoredPrevParameters) as AcceptedDataTypes;
  const newEntity = omit(data, ignoredPrevParameters) as AcceptedDataTypes;

  // We ignore arrays that are handled individually as replace
  const arrayProps = Object.keys(newEntity).filter((k) => Array.isArray(newEntity[k]));

  diff = compare(omit(prevEntity, arrayProps), omit(newEntity, arrayProps));

  // Add array replacements
  arrayProps.forEach((k) => {
    const newArray = data[k];
    // It should be array as we are iterating arrays only, but still (and TS)...
    if (!Array.isArray(newArray)) return;

    // Prev data does not even have to be an array (null or undefined)
    const prevArray = prevData[k];

    if (Array.isArray(prevArray)) {
      // Omit meta and chosen parameters and compare
      const ignoredArrayPrevParameters = [
        ...basicMetaProperties,
        ...ignore.filter((p) => p.includes(`${k}[]`)).map((p) => p.replace(`${k}[].`, '')),
      ];
      const cleanItems = (d: number | string | AcceptedDataTypes) =>
        typeof d === 'object' && d !== null ? omit(d, ignoredArrayPrevParameters) : d;
      const previousCleanArray = prevArray.map(cleanItems);
      const newCleanArray = newArray.map(cleanItems);

      const changed =
        previousCleanArray.length !== newArray.length ||
        differenceWith(previousCleanArray, newCleanArray, isEqual).length > 0;
      if (!changed) {
        return;
      }
    }

    diff.push({
      op: 'replace',
      path: `/${k}`,
      value: data[k],
    });
  });

  return diff.length > 0 ? diff : undefined;
}
