import { EntityRequest } from 'core/actions';
import { UserSaveRequestData } from 'personnel/containers/UsersDetailPage/useUserOutputMapping';

export const TYPE_EDIT_PROFILE = 'EDIT_PROFILE';
export const editProfile = (
  req: EntityRequest<UserSaveRequestData>,
  prevEntity: UserSaveRequestData
) =>
  ({
    type: TYPE_EDIT_PROFILE,
    payload: {
      req,
      prevEntity,
    },
  }) as const;
export type EditProfileAction = ReturnType<typeof editProfile>;
