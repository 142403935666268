import { FC } from 'react';

import Sidebar from 'core/containers/Sidebar';

import { HamburgerWrap, HamburgerMenu, Backdrop } from './styled';

interface Props {
  toggle: () => void;
  isOpen: boolean;
}

const Hamburger: FC<Props> = ({ toggle, isOpen }) => {
  return (
    <HamburgerWrap onClick={(e) => e.stopPropagation()}>
      {isOpen && <Backdrop onClick={() => toggle()} />}

      {isOpen && (
        <HamburgerMenu>
          <Sidebar />
        </HamburgerMenu>
      )}
    </HamburgerWrap>
  );
};

export default Hamburger;
