import { useMemo } from 'react';

import getResourcesUrl from 'core/functions/getResourcesUrl';
import { useBoolClientOption } from 'core/hooks/useClientOption';
import usePermission from 'core/hooks/usePermission';
import useResource from 'core/hooks/useResource';
import { BLACKLISTS_TYPE } from 'personnel/containers/UsersDetailPage/tabs/BlackListsTab/BlacklistsModal';
import { useBlacklistsTypeEndpoint } from 'personnel/containers/UsersDetailPage/tabs/BlackListsTab/useBlacklistsEndpoints';
import BlacklistAthletes from 'personnel/models/BlacklistAthletes';
import BlacklistSportDisciplines from 'personnel/models/BlacklistSportDisciplines';

const useBlacklists = (
  usersId: number[],
  type: BLACKLISTS_TYPE,
  id: number | number[] | null | undefined
) => {
  const typeEndpoint = useBlacklistsTypeEndpoint(type);
  const containsUsers = usersId.length > 0;
  const blacklistsEnabled = useBoolClientOption('enableBlacklists');
  const canSeeAthletesBlacklist = usePermission('blacklistAthletes:find');
  const canSeeSportDisciplinesBlacklist = usePermission('blacklistSportDisciplines:find');
  const permissions = useMemo<{ [type in BLACKLISTS_TYPE]: boolean }>(
    () => ({
      [BLACKLISTS_TYPE.ATHLETES]: canSeeAthletesBlacklist,
      [BLACKLISTS_TYPE.SPORT_DISCIPLINES]: canSeeSportDisciplinesBlacklist,
    }),
    [canSeeAthletesBlacklist, canSeeSportDisciplinesBlacklist]
  );

  const blacklistsUrl = getResourcesUrl(typeEndpoint, {
    usersId,
    ...(type === BLACKLISTS_TYPE.ATHLETES ? { athletesId: id } : {}),
    ...(type === BLACKLISTS_TYPE.SPORT_DISCIPLINES ? { sportDisciplinesId: id } : {}),
  });

  const { data: blacklists } = useResource<Array<BlacklistAthletes | BlacklistSportDisciplines>>(
    blacklistsUrl,
    {
      autoload:
        blacklistsEnabled &&
        containsUsers &&
        permissions[type] &&
        (Array.isArray(id) ? id.length > 0 : !!id),
    }
  );

  return blacklists;
};

export default useBlacklists;
