import { push } from 'connected-react-router';
import { useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch } from 'react-redux';
import { useRouteMatch } from 'react-router-dom';

import { useBoolClientOption } from 'core/hooks/useClientOption';
import usePermission from 'core/hooks/usePermission';

const useAddFileMenuItem = (
  permissionEntity: string,
  onClick?: () => void,
  redirect: boolean = true
) => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const filesEnabled = useBoolClientOption('enableAttachments');
  const canCreateFiles = usePermission(`${permissionEntity}:files[actions:create]`);
  const { url } = useRouteMatch();

  return useMemo(
    () => ({
      onClick: () => {
        onClick?.();
        redirect && dispatch(push(`${url}/attachments`));
      },
      text: t('Add Attachment'),
      key: 'attachment',
      icon: 'attachment',
      hidden: !filesEnabled || !canCreateFiles,
    }),
    [canCreateFiles, dispatch, filesEnabled, onClick, redirect, t, url]
  );
};

export default useAddFileMenuItem;
