import { AxiosRequestHeaders } from 'axios';
import { toast } from 'react-toastify';
import { put, race, select, take } from 'redux-saga/effects';

import Profile from 'app/models/Profile';
import { AppState } from 'app/store';
import {
  apiCall,
  clear2FaSecret,
  loadEntitySuccess,
  TYPE_VERIFY_2FA,
  Verify2FAAcion,
} from 'core/actions';
import { t } from 'core/i18n';

function* verify2FA(action: Verify2FAAcion) {
  yield put(
    apiCall(TYPE_VERIFY_2FA, 'POST', '/profile/mfa/verify', {
      passcode: action.payload.code,
    })
  );

  const { success } = yield race({
    success: take(`${TYPE_VERIFY_2FA}_SUCCESS`),
    error: take(`${TYPE_VERIFY_2FA}_ERROR`),
  });

  if (success) {
    const entityData: Profile | undefined = yield select((s: AppState) => s.core.entityData);

    yield put(clear2FaSecret());

    yield put(
      loadEntitySuccess(
        {
          data: { ...entityData, hasTwoFactor: true },
          status: 200,
          statusText: 'OK',
          headers: {},
          config: { headers: {} as AxiosRequestHeaders },
        },
        '/profile'
      )
    );

    toast.success(t('2-Factor Verification configured successfully.'));
    action.payload.successCallback && action.payload.successCallback();
  }
}

export default verify2FA;
