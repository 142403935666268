import { AvailabilityRequestModel } from 'personnel/components/AvailabilityModal/useOutputMapping';

export const TYPE_FETCH_AVAILABILITIES = 'FETCH_AVAILABILITIES';
export const fetchAvailabilities = (dateFrom: Date, dateTo: Date, endpoint: string) =>
  ({
    type: TYPE_FETCH_AVAILABILITIES,
    payload: { dateFrom, dateTo, endpoint },
  }) as const;
export type FetchAvailabilitiesAction = ReturnType<typeof fetchAvailabilities>;

export const TYPE_UNLOAD_AVAILABILITIES = 'UNLOAD_AVAILABILITIES';
export const unloadAvailabilities = () =>
  ({
    type: TYPE_UNLOAD_AVAILABILITIES,
    payload: {},
  }) as const;
export type UnloadAvailabilitiesAction = ReturnType<typeof unloadAvailabilities>;

export const TYPE_DELETE_AVAILABILITY = 'DELETE_AVAILABILITY';
export const deleteAvailability = (availabilityId: number, successCallback: () => void) =>
  ({
    type: TYPE_DELETE_AVAILABILITY,
    payload: { availabilityId, successCallback },
  }) as const;
export type DeleteAvailabilityAction = ReturnType<typeof deleteAvailability>;

export const TYPE_SAVE_AVAILABILITY = 'SAVE_AVAILABILITY';
export const saveAvailability = (
  formData: AvailabilityRequestModel,
  prevAvailability?: AvailabilityRequestModel,
  id?: number,
  successCallback?: () => void
) =>
  ({
    type: TYPE_SAVE_AVAILABILITY,
    payload: { formData, prevAvailability, id, successCallback },
  }) as const;
export type SaveAvailabilityAction = ReturnType<typeof saveAvailability>;

export type AvailabilityActions =
  | SaveAvailabilityAction
  | DeleteAvailabilityAction
  | FetchAvailabilitiesAction
  | UnloadAvailabilitiesAction;
