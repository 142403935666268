import { useTranslation } from 'react-i18next';

import theme from 'app/theme';

import { IconContentLoader } from './styled';

const IconLoader = () => {
  const { t } = useTranslation();

  return (
    <IconContentLoader
      width="240"
      height="240"
      viewBox="0, 0, 240,240"
      backgroundColor={theme.color.gray}
      foregroundColor={theme.color.gray200}
      title={t('Loading...')}
      data-cy="loading-placeholder"
    >
      <path d="m117.32588,109.99306l-28.36748,16.37753l-28.36748,-16.37753l0,-32.75595l28.36748,-16.37798l28.36748,16.37753l0,32.7564z" />
      <path d="m179.40906,109.99306l-28.36748,16.37753l-28.36703,-16.37753l0,-32.75595l28.36703,-16.37798l28.36748,16.37753l0,32.7564z" />
      <path d="m148.3677,162.76379l-28.36748,16.37708l-28.36748,-16.37708l0,-32.75595l28.36748,-16.37753l28.36748,16.37753l0,32.75595z" />
    </IconContentLoader>
  );
};

export default IconLoader;
