import React from 'react';

import { entities } from 'app/entity';
import { FullRowCell } from 'core/components/GridCell';
import { useDetail } from 'core/containers/FormDetailPage/DetailContext';
import history from 'core/history';
import { useBoolClientOption } from 'core/hooks/useClientOption';
import usePermission from 'core/hooks/usePermission';
import ControlReport from 'planning/models/ControlReport';
import UAReport from 'planning/models/UAReport';

import PaperTableTabs from '../PaperTableTabs';
import useReportsTabs from '../useReportsTabs';

const ReportsTable: React.FC = () => {
  const { id: missionsId, mode } = useDetail();
  const isEdit = mode === 'edit';
  const reportTabs = useReportsTabs({ missionsId });
  const enableReports = useBoolClientOption('enableDcr');
  const enableDcoReports = useBoolClientOption('enableDcoReport');
  const canGetTestReportDetail = usePermission('testReports:get');
  const canGetDcoReportDetail = usePermission('testDcoReports:get');
  const canGetUaReportDetail = usePermission('testUaReports:get');

  return (
    <FullRowCell>
      <PaperTableTabs
        tabs={reportTabs}
        onSelect={(event, type) => {
          if (!isEdit) return;
          if (
            type === 'reports' &&
            ((canGetTestReportDetail && enableReports) ||
              (canGetDcoReportDetail && enableDcoReports))
          ) {
            const report: ControlReport = event.data;
            history.push(entities.testDcoReport.urls(report.testsId).detail(report.id));
          } else if (canGetUaReportDetail) {
            const report: UAReport = event.data;
            history.push(entities.testUaReport.urls(report.testsId).detail(report.id));
          }
        }}
      />
    </FullRowCell>
  );
};

export default ReportsTable;
