import { useCallback } from 'react';

import { markdownToEditorState } from 'core/components/FormEditorField';
import Comment from 'core/models/Comment';

import useSchema from './useSchema';

const useInputMapping = () => {
  const schema = useSchema();

  return useCallback(
    (comment?: Comment) => {
      return schema.cast(
        {
          editorState: markdownToEditorState(comment?.text || ''),
          restrictToRoles: comment?.restrictToRoles || [],
        },
        { stripUnknown: true }
      );
    },
    [schema]
  );
};

export default useInputMapping;
