import { useCallback, useMemo } from 'react';
import { useTranslation } from 'react-i18next';

import { getNavGroups } from 'app/urls';

/**
 * Prepare map for finding active item
 * pathName:groupKey
 * sorted by pathName (reverse - longest paths first)
 */
function prepareNavs(t: (m: string) => string) {
  // read nav groups - translation fn required
  const navGroups = getNavGroups(t);
  // extract all urls with its group

  // create group:pathname map
  const linkMap = navGroups.reduce(
    (acc, g) => {
      if (g.to) {
        acc[g.to] = g.key;
      } else if (g.items) {
        g.items.forEach((it) => (acc[it.to] = g.key));
      }

      return acc;
    },
    {} as Record<string, string>
  );

  // sorted paths with group key
  return Object.keys(linkMap)
    .sort()
    .reverse()
    .reduce((acc, key) => ({ ...acc, [key]: linkMap[key] }), {});
}

/**
 * Find active idem from prepared resources
 * Logic of finding
 */
function findActiveItem(linkMap: Record<string, string>, location: string) {
  // find proper longest matching url
  let activePath = Object.keys(linkMap).find((item) => {
    return location.startsWith(item);
  });

  // prevent using Home as fallback by matching substring
  if (activePath === '/' && location !== '/') {
    activePath = undefined;
  }

  // return active group key + specific active path
  return activePath ? { key: linkMap[activePath], path: activePath } : undefined;
}

/**
 * helper version out of context
 * it was required by bootstrap.ts and keeping it for future
 */
export function getNavActiveNavGroup(location: string, t: (m: string) => string = (m) => m) {
  const resources = prepareNavs(t);
  return findActiveItem(resources, location);
}

/**
 * memoized version (depends only on translator)
 */
export function useGetActiveNavItem() {
  const { t } = useTranslation();

  // prepare only once - based on translator
  const resources = useMemo(() => prepareNavs(t), [t]);

  return useCallback(
    (location: string) => {
      return findActiveItem(resources, location);
    },
    [resources]
  );
}
