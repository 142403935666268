import { SimpleOption, Option, GroupOption } from '../DropDown';

export function defaultComparator(a: SimpleOption, b: SimpleOption) {
  // We are comparing without being sensitive to case but being sensitive to locale
  const aValue = a?.name.toLocaleUpperCase();
  const bValue = b?.name.toLocaleUpperCase();

  if (!aValue || !bValue) return 0;

  return aValue.localeCompare(bValue);
}

export default function sortOptionsHelper(
  options: Option[],
  customComparator?: typeof defaultComparator
): Option[] {
  const comparator = customComparator || defaultComparator;
  const arrays: Array<SimpleOption | GroupOption>[] = [[]];
  let index = 0;

  options.forEach((option: Option) => {
    if (option === 'SEPARATOR') {
      index++;
      arrays.push([]);
    } else {
      arrays[index].push(
        'options' in option ? { ...option, options: option.options.sort(comparator) } : option
      );
    }
  });

  return arrays.reduce((res: Option[], options: Array<SimpleOption | GroupOption>, i: number) => {
    i > 0 && res.push('SEPARATOR');
    return res.concat(options.sort(comparator));
  }, []);
}
