import { FC } from 'react';

import { StyledName } from './styled';

interface Props {
  name?: string;
  role?: string;
  collapse: boolean;
}

const Name: FC<Props> = ({ collapse, name, role }) => (
  <StyledName collapse={collapse}>
    {name}
    {role && <span>{role}</span>}
  </StyledName>
);

export default Name;
