import { useMemo } from 'react';
import { useTranslation } from 'react-i18next';

const useMedicationUnitOptions = () => {
  const { t } = useTranslation();

  return useMemo(
    () => [
      { id: 'Drop(s)', name: t('Drop(s)') },
      { id: 'Capsule(s)', name: t('Capsule(s)') },
      { id: 'Item(s)', name: t('Item(s)') },
      { id: 'Pill(s)', name: t('Pill(s)') },
      { id: 'Tablet(s)', name: t('Tablet(s)') },
      { id: 'Patch(s)', name: t('Patch(es)') },
      { id: 'Puff(s)', name: t('Puff(s)') },
      { id: 'Scoop(s)', name: t('Scoop(s)') },
      { id: '%', name: t('Percent (%)') },
      { id: 'IU', name: t('Intl Units (IU)') },
      { id: 'mL', name: t('milliliters (mL or cc)') },
      { id: 'oz', name: t('ounces (oz)') },
      { id: 'mg', name: t('milligrams (mg)') },
      { id: 'μg', name: t('micrograms (μg)') },
      { id: 'mg/L', name: t('milligrams/liter (mg/L or μg/mL)') },
      { id: 'Other', name: t('Other (not specified above)') },
    ],
    [t]
  );
};

export default useMedicationUnitOptions;
