import { useCallback, useMemo } from 'react';
import { useHistory, useLocation } from 'react-router-dom';

import { mergeUrl, parse } from 'core/functions/qs';

export const coercePossibleNumber = (value: string | null) =>
  typeof value === 'string' && /^\d+$/.test(value) ? parseInt(value) : value;
export const coercePossibleNumbers = (value: string | string[] | null) =>
  Array.isArray(value)
    ? (value.map(coercePossibleNumber) as Array<string | number>)
    : coercePossibleNumber(value);

export type PossibleUrlItemType = string | boolean | number | null | Array<string | number>;

const useUrlState = (stateKey: string, defaultValues: { [key: string]: string }) => {
  const location = useLocation();
  const history = useHistory();

  const urlState = JSON.stringify(parse(location.search.substring(1))[stateKey] || {});
  const possibleValues = JSON.stringify(Object.keys(defaultValues));

  const urlStateValues = useMemo(() => {
    const persisted = JSON.parse(urlState) as {
      [key: string]: string | string[] | null;
    };

    return Object.entries(persisted || {}).reduce(
      (acc, [id, value]) => {
        const persistedValue = coercePossibleNumbers(value);

        if (persistedValue === 'true' || persistedValue === 'false') {
          acc[id] = persistedValue === 'true';
        } else if (persistedValue || persistedValue === 0) {
          // 0 is falsy but a valid value
          acc[id] = persistedValue;
        }
        return acc;
      },
      {} as { [key: string]: string | number | boolean | Array<string | number> | null }
    );
    // Indirect dependency
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [possibleValues, urlState]);

  const urlStateChanged = useMemo(
    () =>
      Object.keys(urlStateValues).length &&
      JSON.stringify(defaultValues) !== JSON.stringify(urlStateValues),
    [urlStateValues, defaultValues]
  );

  const setUrlStateValues = useCallback(
    (newStateValues: { [key: string]: string | number | Array<string | number> | null }) => {
      // Persist changed settings to the URL
      const urlWithParams = mergeUrl(
        `${location.pathname}${location.search}`,
        {
          [stateKey]: newStateValues || null,
        },
        { shallowMerge: true }
      );

      history.push(urlWithParams);
    },
    [history, location.pathname, location.search, stateKey]
  );

  return { urlStateValues, urlStateChanged, setUrlStateValues };
};

export default useUrlState;
