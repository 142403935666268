import { DateTime } from 'luxon';

import User from 'app/models/User';
import BaseModel from 'core/models/Base';
import { DatagridDCOInvoice } from 'finance/models/DCOInvoice';
import Analysis from 'lists/models/Analysis';
import Pool from 'lists/models/Pool';
import TeamMember from 'planning/models/TeamMember';

import Event from './Event';

export const MAX_DCOS = 15;
export const MAX_BCOS = 15;

export enum MissionStatus {
  OPEN = 1,
  CLOSED = 2,
}

export interface MissionsContentsTestsDatagrid extends BaseModel {
  missionsId: number;
  timezonesId: number;
  athleteName: string | null;
  race: string | null;
  place: string | null;
  oneUrineTube: boolean;
  oneBloodEdtaTube: boolean;
  twoBloodEdtaTubes: boolean;
  twoBloodGelTubes: boolean;
  oneBloodEdtaTwoGelTubes: boolean;
  driedBloodSpots: boolean;
  resultPerformedAt: DateTime | null;
  resultCreatedAt: DateTime | null;
  athleteDateOfBirth: DateTime | null;
  athleteGender: string | null;
  athleteNationality: string | null;
  athletesExternalId: string | null;
  athletesAdamsId: string | null;
  urineAnalyses?: Analysis[];
  bloodAnalyses?: Analysis[];
}

export interface MissionDatagrid extends BaseModel {
  language: string | null;
  code: string;
  ordersId: number | null;
  generalComments: string | null;
  dateFrom: Date | null;
  dateTo: Date | null;
  timeFrom: string | null;
  timeTo: string | null;
  dateFromBuffer: number | null;
  dateToBuffer: number | null;
  plannedAt: DateTime | null;
  federationsId: number;
  federationsName: string | null;
  eventsId: number | null;
  eventName: string | null;
  testingAuthoritiesId: number;
  testingAuthoritiesName: string | null;
  countriesId: number | null;
  countriesName: string | null;
  city: string | null;
  zip: string | null;
  leadDcosName: string | null;
  testCount: number | null;
  avatarStorageKey: string | null;
  assigneesId: number;
  assigneesName: string | null;
  testSituationsId: number;
  bloodAnalysesCount: number | null;
  urineAnalysesCount: number | null;
  sportsId: number | null;
  costsId: number | null;
  status: MissionStatus;
  bcos: string | null;
  bcosId: number[] | null;
  dcos: string | null;
  dcosId: number[] | null;
  invoicingCodesId: number | null;
  invoicingCodeCode: string | null;
  urineAnalyses: Analysis[];
  bloodAnalyses: Analysis[];
  debtorsId: number | null;
  debtorsName: string | null;
  customersId: number | null;
  customersName: string | null;
}

export interface Settings {
  allowDelayDeadline: boolean;
  prefillSportDiscipline: boolean;
  simplifiedSigning: boolean;
  additionalAssignments: boolean;
  fileTemplatesId: number | null;
  adamsAthleteLevelsId: number | null;
  isPersonallyKnownAllowed: boolean;
  requireNotificationSignature: boolean;
  useAuthorityEmailTemplate: boolean;
  useAuthorityDcfTemplate: boolean;
  useAuthorityLogo: boolean;
  allowIcPoolCollection: boolean | null;
}
export default interface Mission extends Settings, BaseModel {
  timeZonesId: number;
  paperOnly: boolean;
  assigneesId: number | null;
  assignee: User;
  bloodComments: string | null;
  bloodLabsId: number | null;
  bloodExpedited: boolean;
  bloodLts: boolean;
  city: string | null;
  closedAt: string | null;
  code: string;
  countriesId: number | null;
  dateFrom: Date | null;
  dateTo: Date | null;
  timeFrom: string | null;
  timeTo: string | null;
  dateFromBuffer: number | null;
  dateToBuffer: number | null;
  plannedAt: DateTime | null;
  sportsId: number | null;
  sportDisciplinesId: number | null;
  notifyOfTeamUpdates: boolean;
  showAthleteToDco: boolean;
  showInternalCommentToDco: boolean;
  eventsId: number | null;
  eventName: string | null;
  federation: {
    fullName: string | null;
    shortName: string | null;
  };
  federationsId: number | null;
  federationsName: string | null;
  generalComments: string | null;
  internalComments: string | null;
  drawComments: string | null;
  invoiceComments: string | null;
  accommodationComments: string | null;
  openedAt: string | null;
  place: string | null;
  race: string | null;
  resultAuthority: number | null;
  sampleAuthoritiesId: number | null;
  initiatorAuthoritiesId: number | null;
  externalId: string | null;
  leadDcosId: number | null;
  state: string | null;
  status: MissionStatus;
  street: string | null;
  address: string | null;
  testAuthority: number | null;
  testCount: number | null;
  testSituationsId: number;
  poolsId: number | null;
  pool: null | Pool;
  urineComments: string | null;
  urineLabsId: number | null;
  urineExpedited: boolean;
  urineLts: boolean;
  zip: string | null;
  ordersId: number | null;
  costsId: number | null;
  use3genApp: null | boolean;
  event: Event | null;
  leadDco: User | null;
  dcos: User[];
  bcos: User[];
  teamMembers: TeamMember[];
  urineAnalyses: Analysis[];
  bloodAnalyses: Analysis[];
  dcoInvoices: DatagridDCOInvoice[];
  debtorsId: number | null;
  debtorsName: string | null;
  customersId: number | null;
  customersName: string | null;
  testingAuthoritiesId: number;
  testingAuthoritiesName: string | null;
}
