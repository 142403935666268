import { AxiosResponse } from 'axios';
import { saveAs } from 'file-saver';
import { toast } from 'react-toastify';
import { put, take, race } from 'redux-saga/effects';

import {
  apiCall,
  DownloadDataAction,
  TYPE_DOWNLOAD_DATA,
  BulkDownloadAction,
  TYPE_BULK_DOWNLOAD,
} from 'core/actions';
import { stringify } from 'core/functions/qs';
import { t } from 'core/i18n';

export function* downloadData(action: DownloadDataAction) {
  const { exportFileName } = action.payload;

  yield put(
    apiCall(
      TYPE_DOWNLOAD_DATA,
      'GET',
      action.payload.endpoint,
      {},
      {
        headers: {
          Accept: `application/${action.payload.format}`,
        },
        responseType: 'blob', //important for axios to download
      }
    )
  );

  const { success, error } = yield race({
    success: take(`${TYPE_DOWNLOAD_DATA}_SUCCESS`),
    error: take(`${TYPE_DOWNLOAD_DATA}_ERROR`),
  });

  if (error) {
    return;
  }
  const response: AxiosResponse = success.payload.response;

  const blob = new Blob([response.data], {
    type: response.headers['content-type'],
  });

  const responseContentType =
    response.headers['content-type'] &&
    response.headers['content-type'].replace('application/', '').split(';')[0];

  saveAs(
    blob,
    exportFileName ||
      `export-${action.payload.endpoint.replace('/', '')}-${Date.now()}.${
        responseContentType || action.payload.format
      }`
  );
}

export function* bulkDownload(action: BulkDownloadAction) {
  const { fileTypesIds, selection, endpoint, successCallback } = action.payload;

  yield put(
    apiCall(
      TYPE_BULK_DOWNLOAD,
      'POST',
      endpoint,
      { fileTypesIds },
      {
        params: Array.isArray(selection)
          ? {
              id: selection,
            }
          : selection,
        paramsSerializer: (params) =>
          stringify(params, {
            encodeValuesOnly: true,
          }),
      }
    )
  );

  const { success } = yield race({
    success: take(`${TYPE_BULK_DOWNLOAD}_SUCCESS`),
  });

  if (success) {
    toast.info(t('Request for download submitted successfully'));

    successCallback && successCallback();
  }
}
