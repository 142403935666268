import { RowClickedEvent } from 'ag-grid-community';
import React from 'react';
import { useTranslation } from 'react-i18next';

import { entities } from 'app/entity';
import { FullRowCell } from 'core/components/GridCell';
import { useDetail } from 'core/containers/FormDetailPage/DetailContext';
import history from 'core/history';
import usePermission from 'core/hooks/usePermission';
import { ApiQueryStatus } from 'core/reducer';
import useMissionTests from 'planning/hooks/useMissionTests';
import Test from 'planning/models/Test';

import PaperTable from '../PaperTable';

import useEntityOverviewUrl from './useEntityOverviewUrl';

const TestsTable: React.FC = () => {
  const { id: missionsId, mode } = useDetail();
  const isEdit = mode === 'edit';
  const canFindTests = usePermission('tests:find');
  const { t } = useTranslation();

  const { data: tests, status: testsStatus } = useMissionTests(missionsId);
  const testsLoading = testsStatus === ApiQueryStatus.LOADING;

  const entityOverviewUrl = useEntityOverviewUrl('test', {
    mission: missionsId?.toString(),
    archived: 'any',
  });

  const iconActions = [
    {
      icon: 'link',
      to: entityOverviewUrl,
      disabled: !tests?.length,
      title: t('Link to Tests'),
    },
  ];

  if (!canFindTests) {
    return null;
  }

  return (
    <FullRowCell>
      <PaperTable
        type="tests"
        onSelect={(event: RowClickedEvent) => {
          if (isEdit) {
            const test: Test = event.data;
            history.push(entities.test.urls().detail(test.id));
          }
        }}
        data={tests || []}
        loading={testsLoading}
        icons={iconActions}
      />
    </FullRowCell>
  );
};

export default TestsTable;
