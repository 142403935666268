import MDCCard from '@material/react-card';
import styled from 'styled-components/macro';

export const Card = styled(MDCCard)`
  display: flex;
`;

export const HeaderContainer = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
`;

export const ExtrasContainer = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-end;
  padding: 0.25rem 1rem;
`;

export const Footer = styled.div`
  padding: 0 1rem 1rem 1rem;
  display: flex;
  flex-direction: row;
`;

export const TopSpacing = styled.div`
  padding-top: 1rem;
`;
