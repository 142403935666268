import { EditorState, convertFromRaw, convertToRaw } from 'draft-js';
import { ErrorMessage, FieldProps, getIn } from 'formik';
import { draftToMarkdown, markdownToDraft } from 'markdown-draft-js';
import { FC, useCallback } from 'react';
import { Editor } from 'react-draft-wysiwyg';
import 'react-draft-wysiwyg/dist/react-draft-wysiwyg.css';

import { FeedbackMessage } from 'core/components/FeedbackMessage';

import { FieldGroup, StyledLabel } from './styled';

export const EDITOR_CONFIG = {
  options: ['inline', 'blockType', 'list'],
  inline: {
    options: ['bold', 'italic'],
  },
  list: {
    options: ['ordered', 'unordered'],
  },
  blockType: {
    options: ['H1', 'Normal'],
  },
};

interface OwnProps {
  label: string;
  onChange?: (newValue: EditorState) => void;
  error?: boolean;
}

export type Props = FieldProps & OwnProps;

const FormEditorField: FC<Props> = ({
  form: { touched, errors, setFieldValue },
  field: { name, value },
  onChange,
  label,
  error,
  ...props
}) => {
  const onChangeCallback = useCallback(
    (newEditorState: EditorState) => {
      if (onChange) {
        onChange(newEditorState);
      } else {
        setFieldValue(name, newEditorState);
      }
    },
    [setFieldValue, name, onChange]
  );
  const hasError = error !== undefined ? error : !!getIn(touched, name) && !!getIn(errors, name);

  return (
    <FieldGroup hasError={hasError}>
      {label && <StyledLabel>{label}</StyledLabel>}
      <Editor
        editorState={value}
        onEditorStateChange={onChangeCallback}
        toolbar={EDITOR_CONFIG}
        {...props}
      />
      {hasError && <ErrorMessage component={FeedbackMessage} name={name} />}
    </FieldGroup>
  );
};

export const markdownToEditorState = (markdownString: string) => {
  const rawData = markdownToDraft(markdownString);
  const contentState = convertFromRaw(rawData);
  return EditorState.createWithContent(contentState);
};

export const editorStateToMarkdown = (state: EditorState) => {
  const content = state.getCurrentContent();
  const rawObject = convertToRaw(content);
  return draftToMarkdown(rawObject);
};

export default FormEditorField;
