import { useCallback } from 'react';

const useNumbersOnlyFilter = () => {
  return useCallback(
    (code: string) =>
      Array.from(code)
        .filter((character) => /[0-9]/.test(character))
        .join(''),
    []
  );
};

export default useNumbersOnlyFilter;
