import { Operation } from 'fast-json-patch';

import { CustomOperation } from 'core/actions';
import { MissionRequestModel } from 'planning/containers/MissionDetailPage/hooks/useMissionOutputMapping';
import { BulkTestFormData } from 'planning/containers/TestBulkEditPage/useBulkTestSchema';
import Mission from 'planning/models/Mission';

export const TYPE_LOAD_MISSION = 'LOAD_MISSION';
export const loadMission = (id: number) =>
  ({
    type: TYPE_LOAD_MISSION,
    payload: {
      id,
    },
  }) as const;
export type LoadMissionAction = ReturnType<typeof loadMission>;

export const TYPE_UNLOAD_MISSION = 'UNLOAD_MISSION';
export const unloadMission = () =>
  ({
    type: TYPE_UNLOAD_MISSION,
    payload: {},
  }) as const;
export type UnloadMissionAction = ReturnType<typeof unloadMission>;

export const TYPE_EDIT_MISSION = 'EDIT_MISSION';
export const editMission = (
  id: number,
  formData: MissionRequestModel,
  prevData: MissionRequestModel,
  successCallback?: (diff: Operation[] | undefined) => void
) =>
  ({
    type: TYPE_EDIT_MISSION,
    payload: {
      successCallback,
      formData,
      prevData,
      id,
    },
  }) as const;
export type EditMissionAction = ReturnType<typeof editMission>;

export const TYPE_BULK_EDIT_MISSION = 'BULK_EDIT_MISSION';
export const bulkEditMission = (
  selection: { [key: string]: any },
  formData: Partial<BulkTestFormData>,
  initials: Partial<BulkTestFormData>,
  customOperations: CustomOperation[],
  outputMapping: (data: any) => any,
  successCallback?: () => void
) =>
  ({
    type: TYPE_BULK_EDIT_MISSION,
    payload: {
      outputMapping,
      selection,
      formData,
      initials,
      successCallback,
      customOperations,
    },
  }) as const;
export type BulkEditMissionAction = ReturnType<typeof bulkEditMission>;

export const TYPE_APPLY_MISSION_TO_TESTS = 'APPLY_MISSION_TO_TESTS';
export const applyMissionToTests = (
  missionsId: number[],
  newData: Mission,
  prevData: Mission,
  customOperations: CustomOperation[],
  onSettled?: () => void
) =>
  ({
    type: TYPE_APPLY_MISSION_TO_TESTS,
    payload: {
      missionsId,
      prevData,
      newData,
      onSettled,
      customOperations,
    },
  }) as const;
export type ApplyMissionToTestsAction = ReturnType<typeof applyMissionToTests>;

export type MissionActions =
  | LoadMissionAction
  | UnloadMissionAction
  | EditMissionAction
  | BulkEditMissionAction
  | ApplyMissionToTestsAction;
