import styled from 'styled-components/macro';

import theme from 'app/theme';
import { FlexCell } from 'core/components/FlexUtils';
import IconButton from 'core/components/IconButton';

export const NameCell = styled(FlexCell)`
  display: flex;
  align-items: center;
  justify-content: space-between;
  flex-direction: row;
  color: ${theme.color.gray400};
`;

export const PersonalInfo = styled.div`
  display: block;
  flex: 1;
  padding: 0 1rem;
  overflow-x: hidden;

  h3 {
    font-size: 1rem;
    font-weight: 500;
    color: ${theme.color.baseFontColor};
    margin-bottom: 0;
  }

  span {
    font-size: 0.875rem;
  }
`;

export const ColoredIconButton = styled(IconButton)`
  color: ${({ color }: { color: keyof typeof theme.color }) => theme.color[color]};
`;
