import { FC, useCallback } from 'react';
import { useTranslation } from 'react-i18next';

import { Option } from 'core/components/DropDown';
import Field from 'core/components/Form/Field';
import useFormFieldValue from 'core/components/Form/useFormFieldValue';
import ResourceFormDropdown from 'core/components/ResourceFormDropdown';
import InvoicingCode from 'lists/models/InvoicingCode';

const InvoicingCodes: FC = () => {
  const { t } = useTranslation();

  const selectedInvoicingCodesId = useFormFieldValue('invoicingCodesId');

  const invoicingCodeOptionsReducer: (list: InvoicingCode[]) => Option[] = useCallback(
    (list: InvoicingCode[]) => {
      return list.reduce((acc: Option[], it: InvoicingCode) => {
        if (it.active || it.id === selectedInvoicingCodesId) {
          acc.push({ id: it.id, name: it.name, secondary: it.code });
        }

        return acc;
      }, []);
    },
    [selectedInvoicingCodesId]
  );

  return (
    <Field
      component={ResourceFormDropdown}
      label={t('Invoicing Code')}
      name="invoicingCodesId"
      single
      fast={true}
      resource="invoicing-codes"
      resourceReducer={invoicingCodeOptionsReducer}
    />
  );
};

export default InvoicingCodes;
