import { DependencyList, useMemo, useRef } from 'react';

import { Option } from 'core/components/DropDown';
import getResourcesUrl from 'core/functions/getResourcesUrl';
import { ApiQueryStatus } from 'core/reducer';

import { useApiCall } from './useApiCall';

/**
 * minimal resource entity type
 */
export interface Entity {
  active?: boolean;
  id: number;
  [key: string]: any;
}

type DropdownResourceOptions<E, R> = {
  /**
   * use custom list reducer or filter Active or Selected values
   */
  reducer?: any | ((list: E[]) => R[]);
  /** use selected value to filter inactive options
   * - we wont hide selected option if its inactive / deleted etc
   * - useful especially when u dont have custom list reducer
   */
  selectedId?: any;
  /**
   * define custom dependencies = trigger refresh resources on change
   */
  dependencies?: DependencyList;
  /**
   * Load Api resource by options, if false = waiting for "dispatch" action
   */
  autoload?: boolean;
};

/**
 * custom hook to use form resources for specific url prepared for use in DropDown component
 *
 * @param url
 * @param {boolean} onInit = request immediately
 * @param {any | (EntityType[], defaultReducer) => ReturnType[] } reduce = selected entity ID or custom reducer
 * - default reducer ignores inactive items,
 * - if you provide active entity ID, it will be accepted by default reducer event if its inactive
 * - You are able to mutate list item type by using custom reducer
 *
 * @returns undefined {Array<ReturnEntity>}
 * - result could be modified by custom reducer so you are able to define return type,
 * by default its same as the input
 * - returns undefined if:
 *   - not loaded (Pending)
 *   - on Error
 *
 */
const useDropdownResource = <E extends Entity, R = Option>(
  resource: string,
  options?: DropdownResourceOptions<E, R>
): [undefined | R[], (force?: boolean) => void, boolean] => {
  const { autoload, reducer, selectedId } = options || ({} as DropdownResourceOptions<E, R>);
  const url = getResourcesUrl(resource);

  const {
    data: list,
    dispatch,
    status,
  } = useApiCall<E[]>(resource, {
    autoload,
    url,
  });

  const initialValue = useRef(selectedId);

  return useMemo(() => {
    return [
      list
        ? typeof reducer === 'function'
          ? reducer(list, initialValue.current)
          : defaultDropdownReducer<E, R>(list, initialValue.current)
        : undefined,
      (force: boolean = true) => dispatch({ cache: !force }),
      status === ApiQueryStatus.LOADING,
    ];
  }, [list, dispatch, reducer, status]);
};

/**
 * Reduce resources and crete list of Option (id, name, active)
 *
 * @param originalList
 * @param selectedVal
 */
export const defaultDropdownReducer = <E extends Entity, R = E>(
  originalList: E[],
  selectedVal: any
): R[] => {
  return (
    (Array.isArray(originalList) &&
      originalList.reduce((acc: R[], it: E) => {
        if (
          it.active === undefined ||
          it.active ||
          (Array.isArray(selectedVal) ? selectedVal.includes(it.id) : it.id === selectedVal)
        ) {
          // @ts-ignore
          acc.push({
            ...it,
            name: (it.hasOwnProperty('fullName') ? it.fullName : it.name) || it.email || '',
            extra: it,
          } as R);
        }
        return acc;
      }, [] as R[])) ||
    []
  );
};

export default useDropdownResource;
