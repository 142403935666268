import { FC, useCallback } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch } from 'react-redux';

import { FileEntityKey, getFileEntityName } from 'app/hooks/useFileEntityLookups';
import { saveFileDocument } from 'core/actions';
import Form from 'core/components/Form';
import Modal from 'core/components/Modal';
import useAppSelector from 'core/hooks/useAppSelector';

import Fieldset from './Fieldset';
import useSchema, { FormData as DocumentFormData } from './useSchema';

export interface Props {
  open: boolean;
  onClose: () => void;
  entityKey?: FileEntityKey;
  entityId?: number;
  onSuccessSaved?: () => void;
  createUrl?: string;
  unrestrictedRoleIds?: number[];
}

const AddFilesDialog: FC<Props> = ({
  onClose,
  open,
  entityId,
  createUrl,
  entityKey,
  onSuccessSaved,
  unrestrictedRoleIds,
}) => {
  const { t } = useTranslation();
  const schema = useSchema();
  const entitySaveInProgress = useAppSelector(({ core }) => core.entitySaveInProgress);

  const initialValues = schema.cast({
    entityId,
    entityName: entityKey && getFileEntityName(entityKey),
  });

  const dispatch = useDispatch();

  const dispatchSave = useCallback(
    (fd: DocumentFormData, sc?: () => void) =>
      dispatch(saveFileDocument(fd, undefined, undefined, sc, createUrl)),
    [dispatch, createUrl]
  );

  return (
    <Modal
      ariaLabel={t('File adding process dialog')}
      title={t('Add File Attachment')}
      open={open}
      cancelButton={null}
      confirmButton={null}
      onClose={() => !entitySaveInProgress && onClose()}
    >
      <Form
        initialValues={initialValues}
        validationSchema={schema}
        id="detailForm"
        onSubmit={(values, formik) => {
          dispatchSave(values, () => {
            onSuccessSaved && onSuccessSaved();
            formik.resetForm();
            onClose();
          });
        }}
      >
        <Fieldset
          onCancel={() => onClose()}
          fileEntity={entityKey}
          requestInProgress={entitySaveInProgress}
          unrestrictedRoleIds={unrestrictedRoleIds || []}
        />
      </Form>
    </Modal>
  );
};

export default AddFilesDialog;
