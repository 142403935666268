import { useTranslation } from 'react-i18next';

import { entities } from 'app/entity';
import Event, { EventStatus } from 'app/models/Event';

import { Notification, NotificationTypeEnum } from '../RenderNotification';

export default function useCreateClient() {
  const { t } = useTranslation();

  return (e: Event): Notification => {
    const map = {
      [EventStatus.IN_PROGRESS]: {
        primary: t('Client {{ name }} creation submitted', { name: e.payload.name }),
        secondary: t('The client is being created.'),

        updatedAt: e.modifiedAt || e.createdAt,
        actions: [
          {
            key: 'open',
            text: t('Open Client'),
            action: entities.client.urls().detail(e.payload.clientsId),
          },
        ],
        icon: 'info',
        inProgress: true,
        read: e.isRead,
        type: NotificationTypeEnum.INFO,
        id: e.id,
      },

      [EventStatus.FINISHED]: {
        primary: t('Client {{ name }} creation completed', { name: e.payload.name }),
        secondary: t('The client has been successfully created.'),

        updatedAt: e.modifiedAt || e.createdAt,
        actions: [
          {
            key: 'open',
            text: t('Open Client'),
            action: entities.client.urls().detail(e.payload.clientsId),
          },
        ],
        icon: 'info',
        inProgress: false,
        read: e.isRead,
        type: NotificationTypeEnum.SUCCESS,
        id: e.id,
      },

      [EventStatus.FAILED]: {
        primary: t('Client {{ name }} creation failed', { name: e.payload.name }),
        secondary: t('An error occurred while creating the client.'),

        updatedAt: e.modifiedAt || e.createdAt,
        actions: [
          {
            key: 'open',
            text: t('Open Client'),
            action: entities.client.urls().detail(e.payload.clientsId),
          },
        ],
        icon: 'info',
        inProgress: false,
        read: e.isRead,
        type: NotificationTypeEnum.ERROR,
        id: e.id,
      },

      [EventStatus.WARNING]: null,
      [EventStatus.CREATED]: null,
    };

    return map[e.status] || map[EventStatus.IN_PROGRESS];
  };
}
