import { EntityKey, getEntityPermission } from 'app/entity';
import usePermission from 'core/hooks/usePermission';

export const useCanRestrictVisibility = (entityKey: EntityKey, action: 'create' | 'patch') => {
  const entityPermission = getEntityPermission(entityKey);
  const canSeeRoles = usePermission('roles:find');
  const canRestrictVisibility = usePermission(
    entityKey === 'file'
      ? `files:${action}`
      : `${entityPermission}:files[actions:restrictVisibility]`
  );

  return canSeeRoles && canRestrictVisibility;
};

export const useHasUnrestrictedVisibility = (entityKey: EntityKey) => {
  const entityPermission = getEntityPermission(entityKey);
  const hasUnrestrictedVisibility = usePermission(
    entityKey === 'file'
      ? `files:find[scope:unrestrictedVisibility]`
      : `${entityPermission}:files[scope:unrestrictedVisibility]`
  );

  return !!hasUnrestrictedVisibility;
};
