import { useTranslation } from 'react-i18next';
import * as Yup from 'yup';

import { clearSecret, isSecretValid } from './AdamsForm/helpers';

export const PASSWORD_DEFAULT = '.....';

const useSchema = () => {
  const { t } = useTranslation();

  const commonSetting = Yup.string()
    .nullable()
    .when('enable', {
      is: true,
      then: (schema) => schema.required().default(null),
    });

  return Yup.object({
    enable: Yup.boolean().nullable().default(false),

    adamsUsername: commonSetting,

    adamsPassword: Yup.string()
      .nullable()
      .when('enable', {
        is: true,
        then: (schema) => schema.required(),
      })
      .default(PASSWORD_DEFAULT),

    adamsTotpKey: Yup.string()
      .nullable()
      .test('validTotpLength', t('TOTP key is invalid'), (value) => {
        const clearedValue = clearSecret(value || '');
        if (!value || value === '') {
          return true;
        }
        return isSecretValid(clearedValue);
      })
      .when('enable', {
        is: true,
        then: (schema) => schema.required(),
      }),

    securityQuestion1: commonSetting,
    securityQuestion2: commonSetting,
    securityQuestion3: commonSetting,

    answerToSecurityQuestion1: commonSetting.default(PASSWORD_DEFAULT),
    answerToSecurityQuestion2: commonSetting.default(PASSWORD_DEFAULT),
    answerToSecurityQuestion3: commonSetting.default(PASSWORD_DEFAULT),

    adamsCustomPoolMapping: Yup.array()
      .of(
        Yup.object({
          modoc: Yup.string().required(),
          adams: Yup.string().required().nullable().default(null),
        }).required()
      )
      .nullable()
      .ensure()
      .when('enable', {
        is: true,
        then: (schema) => schema.required(),
      }),
  });
};

export default useSchema;

export type FormData = Yup.TypeOf<ReturnType<typeof useSchema>>;
