import styled, { css } from 'styled-components/macro';

import theme from 'app/theme';

import IconButton from '../IconButton';
import Modal from '../Modal';

export const DropDownModalContainer = styled.div`
  padding-top: 1rem;
`;

export const StyledSelectedText = styled.div.attrs((className) => ({ className }))<{
  dense: boolean;
  minWidth?: number | string;
}>`
  &.mdc-select {
    width: 100%;
    flex: 1;

    ${(props) =>
      props.dense &&
      css`
        height: 48px;

        .mdc-select__selected-text {
          height: 48px;
          padding: 12px 12px 7px;
        }

        .mdc-floating-label {
          padding-right: 0.5rem;
        }

        .mdc-floating-label:not(.mdc-floating-label--float-above) {
          top: 14px;
          font-size: 0.813rem;
        }

        .mdc-select__dropdown-icon {
          bottom: 12px;
        }
      `}

    .mdc-select__selected-text {
      min-width: ${({ minWidth }) => minWidth || '3rem'};
      display: inline;
      text-overflow: ellipsis;
      white-space: nowrap;
      overflow: hidden;
    }
  }
`;

export const StyledList = styled.div`
  &.mdc-list {
    user-select: none;
  }

  &.mdc-list--dense .mdc-list-item__graphic {
    margin-right: 1rem;
    width: 0.75rem;
    height: 0.75rem;
    font-size: 0.75rem;
    line-height: 1;
  }

  &.mdc-list--dense.mdc-list--two-line .mdc-list-item--without-two-lines {
    align-self: center;
  }

  &.mdc-list--dense .mdc-list-item__primary-text .material-icons {
    display: inline-flex;
    &:last-child {
      margin-right: 0.3rem;
    }
  }

  width: 100%;
`;

export const SelectAllButton = styled(IconButton)`
  /* We have to mimic item checkbox styling so they are aligned */
  margin-right: 5px;
  > .material-icons {
    font-size: 24px;
    width: 24px;
    height: 24px;
  }

  color: ${theme.color.baseFontColor};
`;

export const StyledModal = styled(Modal)<{
  $height?: string;
  $width?: string;
}>`
  &[data-reach-dialog-overlay] > div[role='dialog'] {
    ${({ $height }) =>
      $height &&
      css`
        height: ${$height};
      `}

    ${({ $width }) =>
      $width &&
      css`
        width: ${$width};
      `}
  }
`;
