import { toast } from 'react-toastify';
import { put, race, take } from 'redux-saga/effects';

import { apiCall, TYPE_GENERATE_FILE, GenerateFileAction } from 'core/actions';
import { t } from 'core/i18n';

export default function* generateFile(action: GenerateFileAction) {
  const { endpoint, body, successCallback } = action.payload;

  yield put(apiCall(TYPE_GENERATE_FILE, 'POST', `${endpoint}`, body, {}));

  const { success } = yield race({
    success: take(`${TYPE_GENERATE_FILE}_SUCCESS`),
  });

  if (success) {
    toast.info(t('The document(s) is being created'));
    successCallback && successCallback();
  }
}
