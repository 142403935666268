import { ErrorMessage, FieldProps, getIn } from 'formik';
import { FC, useCallback } from 'react';

import { FeedbackMessage } from 'core/components/FeedbackMessage';
import { FieldGroup } from 'core/components/FormCalendarInput/styled';
import TimePicker from 'core/components/TimePickerInput';

export interface OwnProps {
  onChange: (date: Date | Date[] | null) => void;
  /**
   * ID is created from the field name
   */
  id?: string;
  value: string | Date | null;
  label: string;
  format?: string;
  required?: boolean;
  disabled?: boolean;
  error?: boolean;
}

export type Props = FieldProps & OwnProps;

const FormTimeInput: FC<Props> = ({
  label,
  field: { name, value, onBlur },
  form: { touched, errors, setFieldValue },
  onChange,
  error,
  id,
  ...props
}) => {
  const onChangeCallback = useCallback(
    (v: Date | null) => {
      if (onChange) {
        onChange(v);
      } else {
        setFieldValue(name, v, true);
      }
    },
    [setFieldValue, name, onChange]
  );
  const hasError = error !== undefined ? error : !!getIn(touched, name) && !!getIn(errors, name);

  return (
    <FieldGroup hasError={hasError}>
      <TimePicker
        onChange={onChangeCallback}
        id={id || `form-${name}`}
        error={hasError}
        onBlur={() => onBlur({ target: { name } })}
        label={label}
        {...props}
        value={value}
      />
      {hasError && <ErrorMessage component={FeedbackMessage} name={name} />}
    </FieldGroup>
  );
};

export default FormTimeInput;
