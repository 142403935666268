import { toast } from 'react-toastify';
import { put, race, take } from 'redux-saga/effects';

import { entities } from 'app/entity';
import {
  apiCall,
  beginEditEntity,
  BulkUploadFilesAction,
  DeleteFileAction,
  finishEditEntity,
  SaveFileDocumentAction,
  TYPE_BULK_UPLOAD_FILES,
  TYPE_DELETE_FILE,
  TYPE_SAVE_FILE_DOCUMENT,
} from 'core/actions';
import { t } from 'core/i18n';

export function* saveFileDocument(action: SaveFileDocumentAction) {
  const fileData = action.payload.formData;
  const { prevFileDetail, fileId, successCallback } = action.payload;
  const isUpdate = !!prevFileDetail;

  yield put(beginEditEntity());

  if (isUpdate) {
    yield put(
      apiCall(
        TYPE_SAVE_FILE_DOCUMENT,
        'PATCH',
        action.payload.updateUrl || `${entities.file.urls().list}/${fileId}`,
        fileData,
        {},
        true,
        prevFileDetail,
        // Files can't be changed, only metadata
        ['files']
      )
    );
  } else {
    const fd = new FormData();
    fd.append('fileTypesId', '' + fileData.fileTypesId);
    fd.append('entityId', '' + fileData.entityId);
    fd.append('entityName', fileData.entityName);
    fd.append('description', fileData.description || '');
    fd.append('restrictToRoles', JSON.stringify(fileData.restrictToRoles || []));

    const files = fileData.files as File[];
    if (files) {
      files.forEach((file) => {
        fd.append('files', file);
      });
    }

    yield put(
      apiCall(
        TYPE_SAVE_FILE_DOCUMENT,
        'POST',
        action.payload.createUrl || entities.file.urls().list,
        fd,
        {
          headers: {
            'content-type': 'multipart/form-data',
          },
        }
      )
    );
  }

  const { success } = yield race({
    success: take(`${TYPE_SAVE_FILE_DOCUMENT}_SUCCESS`),
    error: take(`${TYPE_SAVE_FILE_DOCUMENT}_ERROR`),
  });

  yield put(finishEditEntity());

  if (success) {
    toast.success(t('Attachment successfully saved'));
    successCallback && successCallback();
  }
}

export function* deleteFile(action: DeleteFileAction) {
  const { successCallback, url, fileId } = action.payload;

  const deleteUrl = url || `/files/${fileId}`;

  yield put(apiCall(TYPE_DELETE_FILE, 'DELETE', deleteUrl, {}, {}));

  const { success } = yield race({
    success: take(`${TYPE_DELETE_FILE}_SUCCESS`),
    error: take(`${TYPE_DELETE_FILE}_ERROR`),
  });

  if (success) {
    toast.success(t('File was successfully deleted'));
    successCallback && successCallback();
  }
}

export function* bulkUploadFiles(action: BulkUploadFilesAction) {
  const { formData, endpoint, entityName, successCallback } = action.payload;

  const fd = new FormData();
  fd.append('fileTypesId', '' + formData.fileTypesId);
  fd.append('entityIds', JSON.stringify(formData.entityIds));
  fd.append('entityName', entityName);
  fd.append('description', formData.description || '');

  for (const file of formData.files || []) {
    fd.append('files', file);
  }

  yield put(
    apiCall(TYPE_BULK_UPLOAD_FILES, 'POST', endpoint, fd, {
      headers: {
        'content-type': 'multipart/form-data',
      },
    })
  );

  const { success } = yield race({
    success: take(`${TYPE_BULK_UPLOAD_FILES}_SUCCESS`),
    error: take(`${TYPE_BULK_UPLOAD_FILES}_ERROR`),
  });

  if (success) {
    toast.success(t('Files were successfully uploaded'));
    successCallback && successCallback();
  }
}
