import { useMemo } from 'react';
import { useTranslation } from 'react-i18next';

export type AuditedEntity = {
  key: string;
  name: string;
};

const useAuditedEntities = () => {
  const { t } = useTranslation();

  const auditedEntities: AuditedEntity[] = useMemo(
    () => [
      {
        key: 'accreditationCards',
        name: t('Accreditation Cards'),
      },
      {
        key: 'testAaReports',
        name: t('After Action Reports'),
      },
      {
        key: 'blacklistAthletes',
        name: t('Athlete Blacklists'),
      },
      {
        key: 'athletes',
        name: t('Athletes'),
      },
      {
        key: 'cocs',
        name: t('Cocs'),
      },
      {
        key: 'testDcoReports',
        name: t('Control Reports'),
      },
      {
        key: 'costs',
        name: t('Costs'),
      },
      {
        key: 'dcoInvoices',
        name: t('Dco Invoices'),
      },
      {
        key: 'events',
        name: t('Events'),
      },
      {
        key: 'missions',
        name: t('Missions'),
      },
      {
        key: 'orderItems',
        name: t('Order Items'),
      },
      {
        key: 'orders',
        name: t('Orders'),
      },
      {
        key: 'testResultSamples',
        name: t('Samples'),
      },
      {
        key: 'blacklistSportDisciplines',
        name: t('Sport Discipline Blacklists'),
      },
      {
        key: 'tdpAthletes',
        name: t('Tdp Athletes'),
      },
      {
        key: 'tdps',
        name: t('Tdps'),
      },
      {
        key: 'testResults',
        name: t('Test Results'),
      },
      {
        key: 'testReturns',
        name: t('Test Returns'),
      },
      {
        key: 'tests',
        name: t('Tests'),
      },
      {
        key: 'testUaReports',
        name: t('UA Reports'),
      },
      {
        key: 'users',
        name: t('Users'),
      },
    ],
    [t]
  );

  return auditedEntities;
};

export default useAuditedEntities;
