import { ChangeEvent, FC } from 'react';

import { MaterialSelect, StyledWrapper } from './styled';

const emptyOption = { label: '', value: '' };

export interface Option {
  name: string;
  id: string;
}

interface Props {
  onChange: (value: string) => void;
  includeEmptyOption?: boolean;
  disableOptions?: boolean;
  value?: string | null;
  onCLick?: () => void;
  onBlur?: () => void;
  onFocus?: () => void;
  outlined?: boolean;
  readOnly?: boolean;
  options: Option[];
  error?: boolean;
  dense?: boolean;
  label: string;
  name?: string;
  id: string;
}

const Select: FC<Props> = ({
  includeEmptyOption = true,
  disableOptions, // used by time input -> don't display options
  onChange,
  outlined,
  onFocus,
  readOnly,
  onCLick,
  options,
  onBlur,
  dense,
  label,
  value,
  error,
  name,
  id,
}) => (
  <StyledWrapper
    enablePointer={!readOnly && disableOptions} // *1
    onClick={() => {
      disableOptions && onCLick && onCLick();
    }}
  >
    <MaterialSelect
      onKeyDown={disableOptions ? (e: any) => e.preventDefault() : undefined}
      className={(error && 'mdc-select--invalid') || undefined}
      disablePointer={disableOptions}
      value={value || ''}
      outlined={outlined}
      disabled={readOnly}
      onFocus={onFocus}
      onBlur={onBlur}
      dense={dense}
      label={label}
      name={name}
      id={id}
      onChange={(e: any) => onChange((e as ChangeEvent<HTMLSelectElement>).target.value)}
      options={(includeEmptyOption ? [emptyOption] : []).concat([
        ...options.map((o) => ({ value: o.id, label: o.name })),
      ])}
    />
  </StyledWrapper>
);

// *1 - special case when we want to display "select" but we wont trigger displaying options

export default Select;
