import { FC, memo, useRef } from 'react';

import { Circle, Content, Primary, Secondary, Wrapper } from './styled';

const RenderLoadingItem: FC<{ style: any }> = ({ style }) => {
  const width = useRef((40 + Math.random() * 40) | 0);

  return (
    <Wrapper style={style}>
      <Circle />
      <Content>
        <Primary />
        <Secondary width={width.current} />
      </Content>
    </Wrapper>
  );
};

export default memo(RenderLoadingItem);
