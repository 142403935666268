export interface ListsState {}

const initialState: ListsState = {};

export default function reducer(state = initialState, action: any): ListsState {
  switch (action.type) {
    default:
      return state;
  }
}
