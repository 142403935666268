import { FC, useCallback, useState } from 'react';
import { ReactCropperProps } from 'react-cropper';
import { useTranslation } from 'react-i18next';

import AvatarCropper from 'core/components/AvatarCropper';
import Button from 'core/components/Button';
import PicturePickerField from 'core/components/DropzonePicker';
import useAppSelector from 'core/hooks/useAppSelector';

import { StyledModal } from './styled';

interface Props {
  title?: string;
  open: boolean;
  onClose: () => void;
  onUpload: (file: Blob, successCallback: () => void) => void;
  cropperProps?: ReactCropperProps;
  cropperWidth: number;
  cropperHeight: number;
}

const UploadImageModal: FC<Props> = ({
  title,
  open,
  onClose,
  onUpload,
  cropperProps,
  cropperWidth,
  cropperHeight,
}) => {
  const [imgToBeCropped, setCroppingImg] = useState<File | undefined>(undefined);
  const [croppedImg, setCroppedImg] = useState<HTMLCanvasElement | undefined>(undefined);
  const { t } = useTranslation();
  const saveInProgress = useAppSelector(({ core }) => core.dialogSaveInProgress);

  const clear = useCallback(() => {
    setCroppedImg(undefined);
    setCroppingImg(undefined);
  }, []);

  const handleCloseIfCan = useCallback(() => {
    if (!saveInProgress) {
      clear();
      onClose();
    }
  }, [saveInProgress, onClose, clear]);

  return (
    <StyledModal
      ariaLabel={t('Photo Upload Dialog')}
      title={title || t('Add a Photo')}
      onClear={() => {
        if (imgToBeCropped && !saveInProgress) {
          clear();
        }
      }}
      autoResponsiveWidth={false}
      onClose={handleCloseIfCan}
      cancelButton={
        <Button
          disabled={saveInProgress}
          text={t('Cancel')}
          type="text"
          onClick={handleCloseIfCan}
        />
      }
      confirmButton={
        <Button
          disabled={!croppedImg || saveInProgress}
          text={saveInProgress ? t('Uploading') : t('Upload')}
          type="text"
          onClick={() => {
            croppedImg!.toBlob((blob) => {
              onUpload(blob!, () => {
                clear();
                onClose();
              });
            }, 'image/png');
          }}
        />
      }
      open={open}
    >
      {(imgToBeCropped && (
        <AvatarCropper
          onCropImg={(croppedImg: HTMLCanvasElement) => {
            setCroppedImg(croppedImg);
          }}
          imgFile={imgToBeCropped}
          cropperProps={cropperProps}
          width={cropperWidth}
          height={cropperHeight}
        />
      )) || (
        <PicturePickerField
          onFileChoose={(droppedImg) => setCroppingImg(droppedImg[0])}
          accept={{ 'image/*': ['.png', '.jpeg', '.jpg', '.gif', '.bmp'] }}
        />
      )}
    </StyledModal>
  );
};

export default UploadImageModal;
