import { entities } from 'app/entity';
import { getResourceUrl } from 'core/functions/getResourcesUrl';
import { useApiCall } from 'core/hooks/useApiCall';
import Test from 'planning/models/Test';

export default function useMissionTests(missionsId?: number, autoload = true) {
  return useApiCall<Test[]>('missionTests', {
    url: getResourceUrl(entities.mission.api().tests(missionsId || 0), {
      order: 'createdAt',
      sort: 'desc',
    }),
    autoload: !!missionsId && autoload,
  });
}
