import { ErrorMessage, FieldProps, getIn } from 'formik';
import { FC, memo, ReactNode } from 'react';

import AutocompleteChipsField, {
  Props as OriginProps,
} from 'core/components/AutocompleteChipsField';
import { FeedbackMessage } from 'core/components/FeedbackMessage';
import FieldGroup from 'core/components/FieldGroup';
import { StyledHelperText } from 'core/components/FormChipsField/styled';

type OwnProps = FieldProps & {
  /**
   * By default, id is created from the field name
   */
  id?: string;
  helperText: ReactNode;
};

type Props = OriginProps & OwnProps;

const FormAutocompleteChipsField: FC<Props> = ({
  form: { touched, errors, setFieldValue },
  onChange,
  field,
  label,
  id,
  helperText,
  ...rest
}) => {
  const hasError = getIn(touched, field.name) && getIn(errors, field.name);
  const chipErrors =
    hasError && Array.isArray(errors[field.name])
      ? (errors[field.name] as string[])
      : ([] as string[]);

  return (
    <FieldGroup hasError={hasError}>
      <AutocompleteChipsField
        {...rest}
        id={id || `form-${field.name}`}
        error={hasError}
        chipErrors={chipErrors}
        label={label}
        {...field}
        onChange={(v) => {
          if (onChange) {
            onChange(v);
          } else {
            setFieldValue(field.name, v);
          }
        }}
      />

      <ErrorMessage
        component={FeedbackMessage}
        name={field.name}
        render={(error) => {
          if (error && Array.isArray(error)) {
            return error
              .filter((it: string, key: number, arr: string[]) => arr.indexOf(it) === key)
              .map((error: string, key: number) => (
                <FeedbackMessage key={key}>{error}</FeedbackMessage>
              ));
          }
          return <FeedbackMessage>{error}</FeedbackMessage>;
        }}
      />

      {helperText && !hasError && <StyledHelperText isValid={true}>{helperText}</StyledHelperText>}
    </FieldGroup>
  );
};

export default memo(FormAutocompleteChipsField);
