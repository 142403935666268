import { toast } from 'react-toastify';
import { put, race, take } from 'redux-saga/effects';

import { t } from 'core/i18n';

import { ReportSendAction, TYPE_REPORT_SEND, apiCall } from '../actions';

function* reportSendSaga(action: ReportSendAction) {
  yield put(apiCall(TYPE_REPORT_SEND, 'POST', '/reports', action.payload));

  const { success } = yield race({
    success: take(TYPE_REPORT_SEND + '_SUCCESS'),
    error: take(TYPE_REPORT_SEND + '_ERROR'),
  });

  if (success) {
    toast.info(t('Report successfully queued'));
  }
}

export default reportSendSaga;
