import { CustomOperation } from 'core/actions';
import { BulkTestFormData } from 'planning/containers/TestBulkEditPage/useBulkTestSchema';
import { TestRequestData } from 'planning/containers/TestDetailPage/hooks/useTestOutputMapping';
import Test from 'planning/models/Test';
import { TestAssignmentDiff } from 'planning/reducer';

export const TYPE_LOAD_TEST_RESULT = 'LOAD_TEST_RESULT';

export type TestBulkCreateOptions = {
  athletesId?: number[];
  male?: number;
  female?: number;
  other?: number;
};
export const TYPE_TEST_BULK_CREATE = 'TEST_BULK_CREATE';
export const testBulkCreate = (
  missionsId: number,
  bulkCreateOptions: TestBulkCreateOptions,
  successCallback?: () => void
) =>
  ({
    type: TYPE_TEST_BULK_CREATE,
    payload: {
      successCallback,
      bulkCreateOptions,
      missionsId,
    },
  }) as const;
export type TestBulkCreateAction = ReturnType<typeof testBulkCreate>;

export const TYPE_BULK_EDIT_TEST = 'BULK_EDIT_TEST';
export const bulkEditTest = (
  selection: { [key: string]: any },
  formData: Partial<BulkTestFormData>,
  initials: Partial<BulkTestFormData>,
  customOperations: CustomOperation[],
  outputMapping: (data: Partial<BulkTestFormData>) => any,
  successCallback?: () => void
) =>
  ({
    type: TYPE_BULK_EDIT_TEST,
    payload: {
      outputMapping,
      selection,
      formData,
      initials,
      customOperations,
      successCallback,
    },
  }) as const;
export type BulkEditTestAction = ReturnType<typeof bulkEditTest>;

export const TYPE_INDIVIDUAL_BULK_EDIT_TEST = 'INDIVIDUAL_BULK_EDIT_TEST';
export const individualBulkEditTest = (
  prevData: { [testsId: number]: TestRequestData },
  newData: { [testsId: number]: TestRequestData },
  selection: object
) =>
  ({
    type: TYPE_INDIVIDUAL_BULK_EDIT_TEST,
    payload: {
      prevData,
      newData,
      selection,
    },
  }) as const;
export type IndividualBulkEditTestAction = ReturnType<typeof individualBulkEditTest>;

export const TYPE_EDIT_TEST = 'EDIT_TEST';
export const editTest = (id: number, formData: TestRequestData, prevData: Test) =>
  ({
    type: TYPE_EDIT_TEST,
    payload: {
      formData,
      prevData,
      id,
    },
  }) as const;
export type EditTestAction = ReturnType<typeof editTest>;

export const TYPE_TEST_ASSIGNMENT_CHANGE = 'TEST_ASSIGNMENT_CHANGE';
export const testAssignmentChange = (assignmentDiff: TestAssignmentDiff) =>
  ({
    type: TYPE_TEST_ASSIGNMENT_CHANGE,
    payload: {
      assignmentDiff,
    },
  }) as const;
export type TestAssignmentChangeAction = ReturnType<typeof testAssignmentChange>;

export const TYPE_TEST_ASSIGNMENT_DIFF_RESOLVED = 'TEST_ASSIGNMENT_DIFF_RESOLVED';
export const testAssignmentDiffResolved = () =>
  ({
    type: TYPE_TEST_ASSIGNMENT_DIFF_RESOLVED,
    payload: {},
  }) as const;
export type TestAssignmentDiffResolvedAction = ReturnType<typeof testAssignmentDiffResolved>;

export const TYPE_SAVE_TEST_ASSIGNMENT_DIFF = 'SAVE_TEST_ASSIGNMENT_DIFF';
export const saveTestAssignmentDiff = (missionsId: number) =>
  ({
    type: TYPE_SAVE_TEST_ASSIGNMENT_DIFF,
    payload: {
      missionsId,
    },
  }) as const;
export type SaveTestAssignmentDiffAction = ReturnType<typeof saveTestAssignmentDiff>;

export const TYPE_ABORT_TEST = 'ABORT_TEST';
export const abortTest = (
  testId: number,
  data: {
    cancelReason?: number | null;
    cancelComment?: string | null;
    canSeeTestResults: boolean;
    performedAt: string;
  },
  successCallback?: () => void
) =>
  ({
    type: TYPE_ABORT_TEST,
    payload: {
      testId,
      data,
      successCallback,
    },
  }) as const;
export type AbortTestAction = ReturnType<typeof abortTest>;

export type TestActions =
  | TestBulkCreateAction
  | BulkEditTestAction
  | IndividualBulkEditTestAction
  | EditTestAction
  | TestAssignmentChangeAction
  | TestAssignmentDiffResolvedAction
  | SaveTestAssignmentDiffAction
  | AbortTestAction;
