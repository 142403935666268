import { TeamMemberEntities } from 'planning/enums';
import { TeamMemberStatus } from 'planning/models/TeamMember';

export const TYPE_ADD_MEMBER_STATEMENT = 'ADD_MEMBER_STATEMENT';
export const addMemberStatement = (
  entity: TeamMemberEntities,
  id: number,
  data: { status: TeamMemberStatus; statement: null | string }
) =>
  ({
    type: TYPE_ADD_MEMBER_STATEMENT,
    payload: {
      entity,
      id,
      data,
    },
  }) as const;
export type AddMemberStatement = ReturnType<typeof addMemberStatement>;

export const TYPE_UNDO_MEMBER_STATEMENT = 'UNDO_MEMBER_STATEMENT';
export const undoMemberStatement = (entity: TeamMemberEntities, id: number) =>
  ({
    type: TYPE_UNDO_MEMBER_STATEMENT,
    payload: {
      entity,
      id,
    },
  }) as const;
export type UndoMemberStatement = ReturnType<typeof undoMemberStatement>;

export type TeamMembersActions = AddMemberStatement | UndoMemberStatement;
