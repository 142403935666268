import { useTranslation } from 'react-i18next';

import { entities } from 'app/entity';
import Event, { EventStatus } from 'app/models/Event';
import {
  Notification,
  NotificationTypeEnum,
} from 'core/components/Notifications/RenderNotification';
import getEnv from 'core/functions/getEnv';
import useReportOptions from 'planning/hooks/useReportOptions';

export default function useCreateReport() {
  const options = useReportOptions('all');
  const { t } = useTranslation();

  return (e: Event): Notification => {
    // @ts-ignore
    const reportType = options.find((i) => i.id === e.payload.type)?.name || t('Unknown type');

    const map = {
      [EventStatus.IN_PROGRESS]: {
        primary: t('Report {{ reportType }} creation submitted', {
          reportType,
        }),
        secondary: t('The report is being created.'),

        updatedAt: e.modifiedAt || e.createdAt,
        icon: 'cloud_download',
        inProgress: true,
        read: e.isRead,
        type: NotificationTypeEnum.INFO,
        id: e.id,
      },

      [EventStatus.FINISHED]: {
        primary: t('Report {{ reportType }} creation completed', {
          reportType,
        }),
        secondary: t('The report has been successfully created. You can download it now.'),

        updatedAt: e.modifiedAt || e.createdAt,
        actions: [
          {
            key: 'download',
            text: t('Download'),
            action: () =>
              window.open(`${getEnv('API_URL')}${entities.report.api().download(e.id)}`, '_blank'),
          },
        ],
        icon: 'cloud_download',
        inProgress: false,
        read: e.isRead,
        type: NotificationTypeEnum.SUCCESS,
        id: e.id,
      },

      [EventStatus.FAILED]: {
        primary: t('Report {{ reportType }} creation failed', {
          reportType,
        }),
        secondary: t('An error occurred while creating the report.'),

        updatedAt: e.modifiedAt || e.createdAt,
        icon: 'cloud_download',
        inProgress: false,
        read: e.isRead,
        type: NotificationTypeEnum.ERROR,
        id: e.id,
      },

      [EventStatus.CREATED]: null,
      [EventStatus.WARNING]: null,
    };

    return map[e.status] || map[EventStatus.IN_PROGRESS];
  };
}
