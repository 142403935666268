import { FC, useCallback, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch } from 'react-redux';

import Button from 'core/components/Button';
import DummyField from 'core/components/DummyField';
import { FlexCell, FlexRow } from 'core/components/FlexUtils';
import Modal from 'core/components/Modal';
import Textfield from 'core/components/Textfield';
import useAppSelector from 'core/hooks/useAppSelector';
import useModal, { useSetModal } from 'core/hooks/useModal';
import { addMemberStatement } from 'planning/actions';
import { TeamMemberEntities } from 'planning/enums';
import { TeamMemberStatus } from 'planning/models/TeamMember';

import useGetStatusName from '../TeamTab/MembersCard/MembersCardItem/useGetStatusName';

interface Props {
  entity: TeamMemberEntities;
  id: number;
}

export enum InvitationStatementModalType {
  ACCEPT = 'ACCEPT',
  DECLINE = 'DECLINE',
}

const InvitationStatementModal: FC<Props> = ({ entity, id }) => {
  const { t } = useTranslation();
  const getStatusName = useGetStatusName();
  const [statement, setStatement] = useState<string | null>(null);
  const modal = useModal<InvitationStatementModalType | undefined>();
  const setModal = useSetModal();

  const decisionMap = new Map([
    [InvitationStatementModalType.ACCEPT, TeamMemberStatus.ACCEPTED],
    [InvitationStatementModalType.DECLINE, TeamMemberStatus.DECLINED],
  ]);

  const decision = modal && decisionMap.get(modal);

  const dispatch = useDispatch();
  const { inProgress, entityData } = useAppSelector(({ planning, core }) => ({
    inProgress: planning.dialogSaveInProgress,
    entityData: core.entityData,
  }));

  const onClose = () => {
    setModal();
    setStatement(null);
  };

  useEffect(() => {
    if (!inProgress && !!decision) {
      onClose();
    }
    // We only want to react to changing entity data, not the rest
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [entityData]);

  const onConfirm = useCallback(() => {
    if (!decision) {
      return;
    }
    dispatch(addMemberStatement(entity, id, { status: decision, statement }));
  }, [decision, dispatch, entity, id, statement]);

  return (
    <Modal
      ariaLabel={t('Invitation statement dialog')}
      title={t('Invitation Response')}
      onClose={onClose}
      open={!!decision}
      confirmButton={
        <Button
          text={inProgress ? t('Saving...') : t('Save')}
          disabled={inProgress}
          onClick={onConfirm}
        />
      }
    >
      <FlexRow>
        <FlexCell block flex={1}>
          <DummyField label={t('Decision')} value={getStatusName(decision)} />
        </FlexCell>
      </FlexRow>

      <FlexRow>
        <FlexCell block flex={1}>
          <Textfield label={t('Statement')} value={statement} onChange={setStatement} textarea />
        </FlexCell>
      </FlexRow>
    </Modal>
  );
};

export default InvitationStatementModal;
