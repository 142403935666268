import cn from 'classnames';
import { FC } from 'react';

import { NavGroup as NavGroupType } from 'app/urls';
import Collapse from 'core/components/Collapse';
import ContextMenu from 'core/components/ContextMenu';
import MaterialIcon from 'core/components/MaterialIcon';
import StyledIcon from 'core/components/StyledIcon';
import Tooltip from 'core/components/Tooltip';

import {
  GroupWrap,
  GroupHeader,
  Heading,
  NavItemWrap,
  NavItem,
  StyledLink,
  activeClassName,
} from './styled';

interface Props extends NavGroupType {
  id: string;
  isOpen: boolean;
  to?: string;
  toggleOpen: (id: string) => void;
  isActive?: boolean;
  activePath?: string;
  collapse: boolean;
}

const NavGroup: FC<Props> = ({
  activePath,
  isActive,
  id,
  img,
  isOpen,
  to,
  items,
  title,
  collapse,
  toggleOpen,
}) => {
  const IconComponent = img;

  let header = (
    <GroupHeader
      className={cn({ [activeClassName]: isActive })}
      onClick={items && (() => toggleOpen(id))}
      data-cy="navbar-header"
    >
      <StyledIcon>
        <IconComponent />
      </StyledIcon>
      <Heading>{title}</Heading>
      {!to && items && (
        <StyledIcon right isOpen={isOpen}>
          <MaterialIcon icon="expand_more" />
        </StyledIcon>
      )}
    </GroupHeader>
  );

  header = collapse ? (
    <Tooltip content={title} placement="right">
      {header}
    </Tooltip>
  ) : (
    header
  );

  return to ? (
    <StyledLink isActive={() => activePath === to} to={to} data-cy="navbar-item">
      {header}
    </StyledLink>
  ) : (
    <GroupWrap isOpen={!!isOpen} data-cy="navbar-group">
      {collapse && items && items.length > 0 ? (
        <ContextMenu
          menuId={`navbar-group-${id}`}
          menuItems={items.map((x) => ({ ...x, active: activePath === x.to }))}
          placement="right-start"
          onClickOutside={() => toggleOpen(id)}
        >
          {header}
        </ContextMenu>
      ) : (
        header
      )}

      {!collapse && !to && items && items.length > 0 && (
        <Collapse isOpen={isOpen}>
          {items.map((x) => (
            <NavItemWrap
              key={x.key}
              to={x.to}
              isActive={() => activePath === x.to}
              data-cy="navbar-sub-item"
            >
              <NavItem>{x.text}</NavItem>
            </NavItemWrap>
          ))}
        </Collapse>
      )}
    </GroupWrap>
  );
};

export default NavGroup;
