import { ReactNode } from 'react';

import { Spacer as StyledSpacer } from './styled';
export type DisplayType = 'inline' | 'block' | 'inline-block';
type SpacerType = {
  children?: ReactNode;
  $marginTop?: string;
  $marginRight?: string;
  $marginLeft?: string;
  $marginBottom?: string;
  $display?: DisplayType;
};

const Spacer = ({ children, ...props }: SpacerType) => {
  return <StyledSpacer {...props}>{children}</StyledSpacer>;
};

export default Spacer;
