import { toast } from 'react-toastify';
import { put, race, take } from 'redux-saga/effects';

import { apiCall, editEntity, TYPE_EDIT_ENTITY } from 'core/actions';
import { stringify } from 'core/functions/qs';
import { t } from 'core/i18n';
import { AdamsAction, AdamsChangeAction, TYPE_ADAMS_CHANGE } from 'planning/actions';

export function* adamsChangeSaga(reduxAction: AdamsChangeAction) {
  const { selection, action, successCallback } = reduxAction.payload;

  const config = {
    params: {
      id: selection,
    },
    paramsSerializer: (params: Record<string, any>) =>
      stringify(params, {
        encodeValuesOnly: true,
      }),
  };
  if (action === AdamsAction.SEND) {
    yield put(apiCall(TYPE_ADAMS_CHANGE, 'POST', '/tests/adams', {}, config));
  } else {
    const sent = action === AdamsAction.MARK_SENT;
    if (Array.isArray(selection)) {
      yield put(
        apiCall(TYPE_ADAMS_CHANGE, 'PATCH', '/tests/bulk', { sentToAdams: sent }, config, true, {
          sentToAdams: !sent,
        })
      );
    } else {
      yield put(
        editEntity(
          { data: { sentToAdams: sent }, endpoint: `tests/${selection}` },
          { sentToAdams: !sent }
        )
      );
    }
  }

  const { bulkSuccess } = yield race({
    bulkSuccess: take(`${TYPE_ADAMS_CHANGE}_SUCCESS`),
    editSuccess: take(`${TYPE_EDIT_ENTITY}_SUCCESS`),
  });

  if (bulkSuccess) {
    if (action === AdamsAction.SEND) {
      toast.success(t('Sending to ADAMS started successfully'));
    } else {
      toast.success(t('ADAMS indication changed successfully'));
    }
  }

  successCallback && successCallback();
}
