import { useFormikContext } from 'formik';
import { FC, useCallback, useContext, useMemo } from 'react';
import { useTranslation } from 'react-i18next';

import { entities } from 'app/entity';
import { RolesId } from 'app/models/Role';
import { useTestSituationOptions } from 'common/filters/useTestSituationsFilter';
import Alert, { AlertContext, AlertMessage } from 'core/components/Alert';
import { Option } from 'core/components/DropDown';
import { FeedbackMessage } from 'core/components/FeedbackMessage';
import { FormContext } from 'core/components/Form';
import Field from 'core/components/Form/Field';
import FormFieldset from 'core/components/Form/FormFieldset';
import useFormFieldValue from 'core/components/Form/useFormFieldValue';
import FormDropDown from 'core/components/FormDropDown';
import FormTextfield from 'core/components/FormTextfield';
import { Cell, Row } from 'core/components/Grid';
import { FullRowCell, HalfRowCell } from 'core/components/GridCell';
import PaperContainer from 'core/components/PaperContainer';
import ResourceFormDropdown from 'core/components/ResourceFormDropdown';
import { useDetail } from 'core/containers/FormDetailPage/DetailContext';
import { getResourceUrl } from 'core/functions/getResourcesUrl';
import useAppSelector from 'core/hooks/useAppSelector';
import { useBoolClientOption } from 'core/hooks/useClientOption';
import { useSetModal } from 'core/hooks/useModal';
import { useGetPermission } from 'core/hooks/usePermission';
import LinkedFinancesPaper from 'finance/components/LinkedFinancesPaper';
import SportDiscipline from 'lists/models/SportDiscipline';
import { BLACKLISTS_TYPE } from 'personnel/containers/UsersDetailPage/tabs/BlackListsTab/BlacklistsModal';
import useAuthoritiesOptionsReducer from 'personnel/hooks/useAuthoritiesOptionsReducer';
import useTeamBlacklists from 'personnel/hooks/useTeamBlacklists';
import BlacklistSportDisciplines from 'personnel/models/BlacklistSportDisciplines';
import AnalysesFields from 'planning/components/AnalysesFields';
import AssignmentFieldset from 'planning/components/AssignmentFieldset';
import Comments from 'planning/components/Comments';
import ControlLocationFields from 'planning/components/ControlLocationFields';
import CustomerField from 'planning/components/CustomerField';
import DebtorField from 'planning/components/DebtorField';
import EntityBlacklistedMessage from 'planning/components/EntityBlacklistedMessage';
import FederationsField from 'planning/components/FederationsField';
import PoolsField from 'planning/components/PoolsField';
import ScheduleFieldset from 'planning/components/ScheduleFieldset';
import useSelectedEvent from 'planning/components/ScheduleFieldset/EventField/useSelectedEvent';
import useBcosClearingWhenNoBloodAnalyses from 'planning/hooks/useBcosClearingWhenNoBloodAnalyses';
import Mission from 'planning/models/Mission';

import { MissionModal } from '../../hooks/useMissionModal';
import { MissionFormData } from '../../hooks/useMissionSchema';

import MissionCodeField from './MissionCodeField';

const Detail: FC = () => {
  const setModal = useSetModal();
  const clientsId = useAppSelector((state) => state.core.user!.client!.id || null);
  const { entityData: mission, mode } = useDetail<Mission>();
  const { values, setValues } = useFormikContext<MissionFormData>();
  const { viewMode, defaultViewMode } = useContext(FormContext);
  const viewModeActive = Boolean(viewMode && defaultViewMode);
  const testSituationOptions = useTestSituationOptions();
  const hasPermission = useGetPermission();
  const { t } = useTranslation();
  const { sportDisciplinesId } = values;
  const isEdit = mode === 'edit';

  const resources = {
    assignee: getResourceUrl('users', {
      rolesId: [RolesId.SUPER_ADMINISTRATOR, RolesId.ADMINISTRATOR, RolesId.TEST_PLANNER],
      clientsId,
    }),
  };

  const hasEnabledDBS = useBoolClientOption('enableDriedBloodSpots');
  const invoicesEnabled = useBoolClientOption('enableDcoInvoices');
  const canSeeDetails = hasPermission('missions:get[actions:details]');
  const canEditDetails = hasPermission('missions:patch[actions:details]');
  const canSeeTeam = hasPermission('missions:get[actions:team]');

  const financeIcons = useMemo(() => {
    return [
      ...(invoicesEnabled && mission
        ? [
            {
              onClick: () => {
                setModal(MissionModal.CREATE_INVOICE);
              },
              title: t('Create Invoice'),
              type: 'button',
              icon: 'request_quote',
            },
          ]
        : []),
    ];
  }, [t, invoicesEnabled, mission, setModal]);

  // TODO: pls double-check this magic
  function handleDetailsChange<K extends keyof MissionFormData>(
    name: undefined | K,
    value: undefined | MissionFormData[K],
    values: MissionFormData
  ) {
    if (name === 'sportsId') {
      values.sportDisciplinesId = null;
    }
    if (typeof name === 'string') {
      return { ...values, [name]: value };
    }
    return values;
  }

  const selectedSportsId = useFormFieldValue('sportsId');
  const disciplineOptionsReducer = useCallback(
    (list: SportDiscipline[], val: null | number) => {
      return list.reduce((acc: Option[], { id, active, name, sportsId }) => {
        if (sportsId === selectedSportsId && (active || id === val)) {
          acc.push({ id, name });
        }
        return acc;
      }, []);
    },
    [selectedSportsId]
  );

  const prefillSportDiscipline = useFormFieldValue('prefillSportDiscipline');
  const authorityOptionsReducer = useAuthoritiesOptionsReducer();
  const hasBlood = values.bloodAnalyses.length > 0 || values.otherBloodAnalyses.length > 0;
  useBcosClearingWhenNoBloodAnalyses();

  const event = useSelectedEvent();

  const alertMessage: AlertMessage = useMemo(
    () => ({
      value: t('You have blacklisted the selected Sport Discipline.'),
    }),
    [t]
  );

  const sportDisciplinesBlacklists: BlacklistSportDisciplines[] | undefined = useTeamBlacklists(
    BLACKLISTS_TYPE.SPORT_DISCIPLINES,
    sportDisciplinesId,
    {
      id: 'sportDiscipline',
      message: alertMessage,
    }
  ) as BlacklistSportDisciplines[];

  const basicInformation = (
    // @ts-ignore TODO: inspect typing problem
    <FormFieldset onChange={handleDetailsChange}>
      <PaperContainer title={t('Basic Information')}>
        <Row>
          <FullRowCell>
            <Field
              component={ResourceFormDropdown}
              resource={resources.assignee}
              initialOption="assignee"
              label={t('Assignee')}
              name="assigneesId"
              fast={false}
              single
            />
          </FullRowCell>

          <MissionCodeField canAutogenerate={!isEdit} />

          <FullRowCell>
            <FederationsField />
          </FullRowCell>

          <Cell desktopColumns={4} tabletColumns={8} phoneColumns={4}>
            <Field
              options={testSituationOptions}
              label={t('Test Situation')}
              component={FormDropDown}
              name="testSituationsId"
              searching={false}
              fast={false}
              height={200}
              single
            />
          </Cell>

          <PoolsField withOneHourSlotField={false} />

          <FullRowCell>
            <Row>
              <Cell desktopColumns={6} tabletColumns={4} phoneColumns={4}>
                <Field
                  component={ResourceFormDropdown}
                  resource="sports"
                  label={t('Sport')}
                  name="sportsId"
                  fast
                  single
                />
              </Cell>
              {selectedSportsId && (
                <Cell desktopColumns={6} tabletColumns={4} phoneColumns={4}>
                  <Field
                    resourceReducer={disciplineOptionsReducer}
                    component={ResourceFormDropdown}
                    label={t('Sport Discipline')}
                    resource="sports/disciplines"
                    name="sportDisciplinesId"
                    single
                    fast={false}
                  />
                  <EntityBlacklistedMessage
                    entityName={t('Sport Discipline')}
                    blacklists={sportDisciplinesBlacklists}
                  />
                  {prefillSportDiscipline === false && (
                    <FeedbackMessage $type="warning">
                      {t('App pre-fill is disabled in the settings')}
                    </FeedbackMessage>
                  )}
                </Cell>
              )}
            </Row>
          </FullRowCell>

          <FullRowCell>
            <Field
              component={ResourceFormDropdown}
              label={t('Testing Authority')}
              resource="authorities"
              name="testAuthority"
              fast={false}
              onChange={(testAuthorityId: number | null) =>
                setValues({
                  ...values,
                  testAuthority: testAuthorityId,
                  resultAuthority:
                    !values.resultAuthority && testAuthorityId
                      ? testAuthorityId
                      : values.resultAuthority,
                  sampleAuthoritiesId:
                    !values.sampleAuthoritiesId && testAuthorityId
                      ? testAuthorityId
                      : values.sampleAuthoritiesId,
                  initiatorAuthoritiesId:
                    !values.initiatorAuthoritiesId && testAuthorityId
                      ? testAuthorityId
                      : values.initiatorAuthoritiesId,
                  debtorsId:
                    !values.debtorsId && !values.debtorsName && testAuthorityId
                      ? testAuthorityId
                      : values.debtorsId,
                  customersId:
                    !values.customersId && !values.customersName && testAuthorityId
                      ? testAuthorityId
                      : values.customersId,
                })
              }
              single
              resourceReducer={authorityOptionsReducer}
            />
          </FullRowCell>

          <FullRowCell>
            <Field
              label={t('Result Management Authority')}
              component={ResourceFormDropdown}
              name="resultAuthority"
              resource="authorities"
              fast
              single
              resourceReducer={authorityOptionsReducer}
            />
          </FullRowCell>

          <FullRowCell>
            <Field
              label={t('Sample Collection Authority')}
              component={ResourceFormDropdown}
              name="sampleAuthoritiesId"
              resource="authorities"
              fast
              single
              resourceReducer={authorityOptionsReducer}
            />
          </FullRowCell>

          <FullRowCell>
            <Field
              label={t('Doping Control Coordinator')}
              component={ResourceFormDropdown}
              name="initiatorAuthoritiesId"
              resource="authorities"
              fast
              single
              resourceReducer={authorityOptionsReducer}
            />
          </FullRowCell>

          <FullRowCell>
            <CustomerField />
          </FullRowCell>

          <FullRowCell>
            <DebtorField />
          </FullRowCell>

          <FullRowCell>
            <Field label={t('Agent')} component={FormTextfield} name="agent" />
          </FullRowCell>

          <FullRowCell>
            <Field label={t('External ID')} component={FormTextfield} name="externalId" />
          </FullRowCell>
        </Row>
      </PaperContainer>
    </FormFieldset>
  );

  return (
    <Row>
      <AlertContext.Consumer>
        {(alert) =>
          alert.message.value && (
            <FullRowCell>
              <Alert />
            </FullRowCell>
          )
        }
      </AlertContext.Consumer>

      <HalfRowCell>
        <Row>
          <FullRowCell>{basicInformation}</FullRowCell>
          {canSeeDetails && <AnalysesFields enableDBS={hasEnabledDBS} />}
        </Row>
      </HalfRowCell>

      <HalfRowCell>
        <Row>
          <FullRowCell>
            <ScheduleFieldset
              originEntity={entities.event.name(t)}
              originRange={event ? [event.dateFrom, event.dateTo] : undefined}
              disablePublishAt
              disableRangeExpanding={!canEditDetails}
            />
          </FullRowCell>

          {canSeeTeam && (
            <FullRowCell>
              <AssignmentFieldset hasBlood={hasBlood} />
            </FullRowCell>
          )}

          <FullRowCell>
            <ControlLocationFields locationPickerEnabled={!viewModeActive} />
          </FullRowCell>

          {canSeeDetails && (
            <LinkedFinancesPaper<MissionFormData>
              dcoInvoices={mission?.dcoInvoices}
              createNewCostLink={
                isEdit && mission ? entities.cost.urls().createFromMission(mission.id) : undefined
              }
              icons={financeIcons}
              showMissionLink
              showInvoicingCode={canEditDetails}
            />
          )}

          <FullRowCell>
            <Comments canSeeSensitiveComments={canSeeDetails} />
          </FullRowCell>
        </Row>
      </HalfRowCell>
    </Row>
  );
};

export default Detail;
