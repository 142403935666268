import { useFormikContext } from 'formik';
import { FC, useCallback, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';

import Modal from 'core/components/Modal';
import Switch from 'core/components/Switch';
import { TeamMemberNotification } from 'planning/models/TeamMember';

import { NotificationType, TeamMemberType } from '../../enums';
import { TeamFormData, TeamMemberData } from '../../useTeamInputMapping';

import { InvitationTogglesWrapper } from './styled';

interface Props {
  type: TeamMemberType;
  members?: TeamMemberData[];
  onClose: () => void;
}

const InvitationsModal: FC<Props> = ({ type, members, onClose }) => {
  const { t } = useTranslation();
  const [viaEmail, setViaEmail] = useState(false);
  const [viaSms, setViaSms] = useState(false);

  useEffect(() => {
    // We can pre-fill current values only if one member is being edited
    if (members?.length === 1) {
      setViaEmail(
        members[0].invitations.some((i) => i.type === NotificationType.EMAIL && !i.createdAt)
      );
      setViaSms(
        members[0].invitations.some((i) => i.type === NotificationType.SMS && !i.createdAt)
      );
    }
  }, [members]);

  const teamMemberTypeFormikKey =
    type === TeamMemberType.INVITED ? 'invitedMembers' : 'assignedMembers';
  const formik = useFormikContext<TeamFormData>();

  const handleConfirm = useCallback(() => {
    const memberIds = new Set(members?.map((m) => m.usersId) || []);

    const newNotifications: TeamMemberNotification[] = [];
    if (viaEmail) {
      newNotifications.push({ type: NotificationType.EMAIL });
    }
    if (viaSms) {
      newNotifications.push({ type: NotificationType.SMS });
    }

    formik.setValues({
      ...formik.values,
      [teamMemberTypeFormikKey]: formik.values[teamMemberTypeFormikKey].map((member) => {
        if (!memberIds.has(member.usersId)) {
          return member;
        }

        if (type === TeamMemberType.INVITED) {
          const invitations = member.invitations.filter((i) => !!i.createdAt);
          return {
            ...member,
            invitations: [...invitations, ...newNotifications],
          };
        }

        if (type === TeamMemberType.ASSIGNED) {
          const assignmentNotifications = member.assignmentNotifications.filter(
            (i) => !!i.createdAt
          );
          return {
            ...member,
            assignmentNotifications: [...assignmentNotifications, ...newNotifications],
          };
        }

        throw new Error('Invalid member type');
      }),
    });
  }, [type, teamMemberTypeFormikKey, formik, members, viaEmail, viaSms]);

  return (
    <Modal
      ariaLabel={
        type === TeamMemberType.INVITED
          ? t('Team Member invitation editing dialog')
          : t('Team Member assignment notification editing dialog')
      }
      title={
        type === TeamMemberType.INVITED ? t('Planned Invitations') : t('Planned Notifications')
      }
      open={!!members}
      onClose={() => {
        setViaEmail(false);
        setViaSms(false);
        onClose();
      }}
      onConfirm={handleConfirm}
      onClear={() => {
        setViaEmail(false);
        setViaSms(false);
      }}
    >
      <InvitationTogglesWrapper>
        <p>
          {type === TeamMemberType.INVITED
            ? t(
                'If the invitee does not have a phone number, an email is sent instead. Invitations are sent after saving.'
              )
            : t(
                'If the invitee does not have a phone number, an email is sent instead. Notifications are sent after saving.'
              )}
        </p>

        <Switch
          id="invitation-email"
          name="invitation-email"
          label={t('Email')}
          value={viaEmail}
          onChange={setViaEmail}
        />

        <Switch
          id="invitation-sms"
          name="invitation-sms"
          label={t('SMS')}
          value={viaSms}
          onChange={setViaSms}
        />
      </InvitationTogglesWrapper>
    </Modal>
  );
};

export default InvitationsModal;
