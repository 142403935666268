import ActiveModel from 'core/models/Active';

export enum FileTemplateType {
  DCF = 'dcf',
  COC = 'coc',
  INVOICE = 'inv',
}

export default interface FileTemplate extends ActiveModel {
  name: string;
  type: FileTemplateType;
}
