import { TeamMemberData } from '../../useTeamInputMapping';

export const getCommonRoles = (members?: TeamMemberData[]) => {
  const allRoles = members?.map((obj) => obj.allowedRoles);

  const commonRoles = allRoles?.reduce((common, roles) => {
    return common.filter((role) => roles.includes(role));
  }, allRoles[0]);

  return commonRoles;
};
