import { FC, memo } from 'react';
import { useTranslation } from 'react-i18next';

import Button from 'core/components/Button';
import { StyledButtonWrapper } from 'core/components/DetailSubheader/Tabs/styled';
import DummyField from 'core/components/DummyField';
import Field from 'core/components/Form/Field';
import FormTextfield from 'core/components/FormTextfield';
import { Row } from 'core/components/Grid';
import { FullRowCell } from 'core/components/GridCell';

import { BLACKLISTS_TYPE } from '..';

import AthletesFields from './Athletes';
import SportDisciplinesFields from './SportDisciplines';

interface Props {
  type: BLACKLISTS_TYPE;
  onCancel: () => void;
  isEdit: boolean;
  onDeleted: () => void;
  creatorsName?: string;
  usersId: number;
  saveInProgress: boolean;
}

const Fieldset: FC<Props> = memo(
  ({ type, onCancel, isEdit, onDeleted, creatorsName, usersId, saveInProgress }) => {
    const { t } = useTranslation();

    const customFieldsForType = (
      <>
        {type === BLACKLISTS_TYPE.ATHLETES && <AthletesFields usersId={usersId} isEdit={isEdit} />}
        {type === BLACKLISTS_TYPE.SPORT_DISCIPLINES && (
          <SportDisciplinesFields usersId={usersId} isEdit={isEdit} />
        )}
      </>
    );

    return (
      <Row>
        {creatorsName && (
          <FullRowCell>
            <DummyField value={creatorsName} label={t('Creator')} />
          </FullRowCell>
        )}

        {customFieldsForType}

        <FullRowCell>
          <Field
            component={FormTextfield}
            label={t('Comments')}
            name="comments"
            textarea
            rows={6}
          />
        </FullRowCell>

        <FullRowCell>
          <StyledButtonWrapper>
            <div>
              {isEdit && (
                <Button
                  onClick={() => {
                    onDeleted();
                  }}
                  text={t('Delete')}
                  type="button"
                  disabled={saveInProgress}
                />
              )}
            </div>

            <div>
              <Button
                onClick={() => {
                  onCancel();
                }}
                text={t('Cancel')}
                type="button"
                disabled={saveInProgress}
              />
              <Button disabled={saveInProgress} type="submit" text={t('Confirm')} />
            </div>
          </StyledButtonWrapper>
        </FullRowCell>
      </Row>
    );
  }
);

export default Fieldset;
