import { useMemo } from 'react';
import * as Yup from 'yup';

import { getTabMeta } from 'core/components/Form/TabErrorCollector';
import useClientOption, { useBoolClientOption } from 'core/hooks/useClientOption';

const tabMeta = getTabMeta();

export const useSettingsSchema = (section: string) => {
  const metaTab = useMemo(() => (section ? tabMeta(section) : {}), [section]);

  const defaultForIsPersonallyKnownAllowed = useBoolClientOption(
    'defaultForIsPersonallyKnownAllowed'
  );
  const defaultForRequireNotificationSignature = useBoolClientOption(
    'defaultForRequireNotificationSignature'
  );
  const defaultForAllowDelayDeadline = useBoolClientOption('defaultForAllowDelayDeadline');
  const defaultForPrefillSportDiscipline = useBoolClientOption('defaultForPrefillSportDiscipline');
  const defaultForSimplifiedSigning = useBoolClientOption('defaultForSimplifiedSigning');

  const defaultForAdditionalAssignments = useBoolClientOption('defaultForAdditionalAssignments');

  const defaultForUseAuthorityLogo = useBoolClientOption('defaultForUseAuthorityLogo');

  const defaultForUseAuthorityEmailTemplate = useBoolClientOption(
    'defaultForUseAuthorityEmailTemplate'
  );
  const defaultForUseAuthorityDcfTemplate = useBoolClientOption(
    'defaultForUseAuthorityDcfTemplate'
  );
  const defaultForFileTemplatesId = useClientOption('defaultForFileTemplatesId');

  const defaultForAdamsAthleteLevelsId = useClientOption('defaultForAdamsAthleteLevelsId');

  const defaultForNotifyOfTeamUpdates = useBoolClientOption('defaultForNotifyOfTeamUpdates');
  const defaultForShowAthleteToDco = useBoolClientOption('defaultForShowAthleteToDco');
  const defaultForShowInternalCommentToDco = useBoolClientOption(
    'defaultForShowInternalCommentToDco'
  );
  const defaultForUse3genApp = useBoolClientOption('defaultForUse3genApp');
  const defaultForAllowIcPoolCollection = useBoolClientOption('defaultForAllowIcPoolCollection');

  return useMemo(
    () =>
      Yup.object({
        isPersonallyKnownAllowed: Yup.boolean()
          .meta(metaTab)
          .default(defaultForIsPersonallyKnownAllowed),
        requireNotificationSignature: Yup.boolean()
          .meta(metaTab)
          .default(defaultForRequireNotificationSignature),
        allowDelayDeadline: Yup.boolean().meta(metaTab).default(defaultForAllowDelayDeadline),
        prefillSportDiscipline: Yup.boolean()
          .meta(metaTab)
          .default(defaultForPrefillSportDiscipline),
        simplifiedSigning: Yup.boolean().meta(metaTab).default(defaultForSimplifiedSigning),
        additionalAssignments: Yup.boolean().meta(metaTab).default(defaultForAdditionalAssignments),
        useAuthorityLogo: Yup.boolean().meta(metaTab).default(defaultForUseAuthorityLogo),
        useAuthorityEmailTemplate: Yup.boolean()
          .meta(metaTab)
          .default(defaultForUseAuthorityEmailTemplate),
        useAuthorityDcfTemplate: Yup.boolean()
          .meta(metaTab)
          .default(defaultForUseAuthorityDcfTemplate),
        useCustomFileTemplate: Yup.boolean().meta(metaTab).default(!!defaultForFileTemplatesId),
        fileTemplatesId: Yup.string()
          .meta(metaTab)
          .required()
          .nullable()
          .default(defaultForFileTemplatesId || 'default'),
        useAdamsAthleteLevel: Yup.boolean().meta(metaTab).default(!!defaultForAdamsAthleteLevelsId),
        adamsAthleteLevelsId: Yup.number()
          .meta(metaTab)
          .nullable()
          .default(defaultForAdamsAthleteLevelsId ? +defaultForAdamsAthleteLevelsId : null),
        notifyOfTeamUpdates: Yup.boolean().meta(metaTab).default(defaultForNotifyOfTeamUpdates),
        showAthleteToDco: Yup.boolean().meta(metaTab).default(defaultForShowAthleteToDco),
        showInternalCommentToDco: Yup.boolean()
          .meta(metaTab)
          .default(defaultForShowInternalCommentToDco),
        use3genApp: Yup.boolean().meta(metaTab).default(defaultForUse3genApp),
        allowIcPoolCollection: Yup.boolean().meta(metaTab).default(defaultForAllowIcPoolCollection),
      }),
    [
      metaTab,
      defaultForIsPersonallyKnownAllowed,
      defaultForRequireNotificationSignature,
      defaultForAllowDelayDeadline,
      defaultForPrefillSportDiscipline,
      defaultForSimplifiedSigning,
      defaultForAdditionalAssignments,
      defaultForUseAuthorityLogo,
      defaultForUseAuthorityEmailTemplate,
      defaultForUseAuthorityDcfTemplate,
      defaultForFileTemplatesId,
      defaultForAdamsAthleteLevelsId,
      defaultForNotifyOfTeamUpdates,
      defaultForShowInternalCommentToDco,
      defaultForAllowIcPoolCollection,
      defaultForUse3genApp,
      defaultForShowAthleteToDco,
    ]
  );
};

export type SettingsFormData = Yup.TypeOf<ReturnType<typeof useSettingsSchema>>;

export default useSettingsSchema;
