import { DateTime } from 'luxon';

import getResourcesUrl from 'core/functions/getResourcesUrl';
import ActiveModel from 'core/models/Active';
import Base from 'core/models/Base';
import { Gender } from 'core/models/CoreUser';
import Country from 'core/models/Country';
import Pool from 'lists/models/Pool';
import SportDiscipline from 'lists/models/SportDiscipline';
import { Substance, TueItem } from 'planning/models/TestResult';

// TODO: Remove once we implement pagination
export const ATHLETES_RESOURCE_LIMIT = 100_000;

export const ATHLETES_RESOURCE_PARAMS = {
  mastersId: '\\x00',
  sort: 'fullName',
  order: 'ASC',
  limit: ATHLETES_RESOURCE_LIMIT,
};

export const ATHLETES_RESOURCE = getResourcesUrl('athletes', ATHLETES_RESOURCE_PARAMS);

export default interface Athlete extends ActiveModel {
  mastersId: number | null;
  firstName: string;
  middleName: null | string;
  lastName: string;
  fullName?: string;
  gender: Gender;
  dateOfBirth: Date | null;
  nationalityId: number | null;
  address: null | string;
  street: null | string;
  city: null | string;
  zip: null | string;
  state: null | string;
  countriesId: number | null;
  email: null | string;
  phone: null | string;
  poolsId: number | null;
  adamsId: null | string;
  externalId: null | string;
  previousDeclarations?: AthletePreviousDeclaration | null;
  sportDisciplines?: SportDiscipline[] | null;
  internalComments?: string | null;
  pool?: Pool | null;
  country: Country;
  nationality?: Country;
  disabilitiesId?: number | null;
  disabilityComment?: string | null;
  commentsCount?: number;
}

export interface AthleteDatagrid
  extends Omit<Athlete, 'country' | 'nationality' | 'commentsCount'> {
  country: string | null;
  translatedCountry: string | null;
  nationality: string | null;
  pool: null;
  translatedNationality: string | null;
  sportsId: number[] | null;
  sportDisciplinesId: number[] | null;
}

export interface AthletePreviousDeclaration extends Base {
  athletesId: number;
  testsId: number;
  advancedMedications: Substance[] | null;
  advancedTue: TueItem[] | null;
  timeZonesId: number | null;
  performedAt: DateTime | null;
}
