import styled from 'styled-components/macro';

import theme from 'app/theme';

export const StyledWrapper = styled.div`
  display: flex;
  justify-content: space-between;
  position: relative;
  top: 1em;
  left: -2em;
  width: calc(100% + 4em);
  padding: 1em 1em 0 1em;
  background: ${theme.color.white};
  color: var(--mdc-theme-secondary);
  border-top: 1px solid ${theme.color.gray100};
`;

export const StyledArrow = styled.span`
  cursor: pointer;
  svg polyline {
    color: var(--mdc-theme-secondary);
  }

  &:first-child {
    margin-left: 0.5em;
  }

  &:last-child {
    margin-right: 0.5em;
  }
`;

export const StyledMonth = styled.span.attrs({ 'data-cy': 'StyledMonth' })`
  line-height: 1.5em;
`;
