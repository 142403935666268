import axios, { AxiosResponse } from 'axios';
import { toast } from 'react-toastify';
import { select } from 'redux-saga/effects';
import { syncScrypt } from 'scrypt-js';
// @ts-ignore They are missing typings and are not important
import * as buffer from 'scrypt-js/thirdparty/buffer';

import { AppState } from 'app/store';
import { t } from 'core/i18n';

import { GetWorkspaceAction } from '../actions';

/**
 * After submit email in the first step of login, this function will redirect to the second step of login with corresponding workspace.
 */
function* getWorkspace(action: GetWorkspaceAction) {
  const params = new URLSearchParams(window.location.search);
  params.delete('key');
  const search = params.toString();

  let metaUrl;
  if (process.env.PROXY_URL) {
    if (process.env.REACT_APP_METADATA_URL) {
      metaUrl = process.env.REACT_APP_METADATA_URL;
    }
  } else {
    const hostparts = window.location.hostname.split('.');
    if (hostparts[0] === 'login') {
      metaUrl = `https://meta.${hostparts.slice(1).join('.')}`;
      metaUrl = metaUrl?.replace('.dev.', '.develop.');
    }
  }

  let newDomain = window.location.origin;
  if (metaUrl) {
    const email = action.payload.email.normalize('NFKC').toLocaleLowerCase();
    const normalizedEmail = new buffer.SlowBuffer(email);
    const N = Math.pow(2, 14),
      r = 8,
      p = 1,
      len = 32;
    const hashedEmail = new buffer.SlowBuffer(
      syncScrypt(normalizedEmail, new buffer.SlowBuffer(''), N, r, p, len)
    ).toString('hex');

    const response: AxiosResponse<{ domain: string }> = yield axios.get(`/domain/${hashedEmail}`, {
      baseURL: metaUrl,
    });

    if (response.status !== 200) {
      toast.error(t('Login failed: Could not retrieve user domain'));
      return;
    }

    newDomain = `https://${response.data.domain}`;
  }

  const keyParam = window.btoa(
    `email=${encodeURIComponent(action.payload.email)}&workspace=true&backTo=${
      window.location.origin + window.location.pathname
    }`
  );

  const prevInternalPathname: string | undefined = yield select(
    (state: AppState) => state.core.prevPathname
  );

  // Save prevPathname to localStorage otherwise it will be lost after redirect
  localStorage.setItem('prevPathname', prevInternalPathname || '/');

  window.location.href = `${newDomain}/login?key=${keyParam}${search ? `&${search}` : ''}`;
}

export default getWorkspace;
