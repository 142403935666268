import Slider, { SliderProps } from 'rc-slider';
import { FC } from 'react';
import styled from 'styled-components/macro';

import theme from 'app/theme';

export const StyledSlider = styled(Slider)`
  .rc-slider-track {
    background-color: var(--mdc-theme-primary);
  }
  .rc-slider-handle {
    border: none;
    cursor: pointer;
    background-color: var(--mdc-theme-primary);

    &:active,
    &:hover,
    &:focus {
      cursor: pointer;
      box-shadow: none;
      width: 45px;
      height: 45px;
      top: -12px;
      background-color: ${theme.color.secondaryLighter};
      &:after {
        content: '';
        width: 14px;
        height: 14px;
        background: ${theme.color.primary700};
        display: flex;
        position: relative;
        top: 17px;
        left: 15px;
        border-radius: 50%;
      }
    }
  }
` as FC<SliderProps<number>>;

export const StyledSliderWrapper = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
`;

export const StyledLeftIconWrapper = styled.div<{ disabled?: boolean }>`
  padding-right: 1.5rem;
  cursor: ${(p) => (p.disabled ? 'not-allowed' : 'pointer')};
  i {
    font-size: 1.6rem;
  }
`;

export const StyledRightIconWrapper = styled.div<{ disabled?: boolean }>`
  padding-left: 1.5rem;
  cursor: ${(p) => (p.disabled ? 'not-allowed' : 'pointer')};
  i {
    font-size: 1.6rem;
  }
`;
