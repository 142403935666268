import cn from 'classnames';
import { ReactNode } from 'react';
import styled, { css } from 'styled-components/macro';

import theme from 'app/theme';

export const AvatarButton = styled.div`
  background: rgba(0, 0, 0, 0.5);
  justify-content: center;
  align-items: center;
  font-size: 0.75rem;
  position: absolute;
  padding: 0.5rem 0;
  text-indent: 0;
  display: flex;
  width: 100%;
  opacity: 0;
  bottom: 0;
`;

interface AvatarProps {
  dense?: boolean;
  onClick?: any;
  size?: 'huge' | 'normal' | 'small' | 'big';
  className?: string;
  children: ReactNode;
  ref?: any;
  hasImg: boolean;
  noMargin?: boolean;
}

export const Circle = styled.div.attrs(({ ...props }: AvatarProps) => ({
  ...props,
  className: cn(props.className, `size-${props.size}`),
}))`
  background-color: ${(p) => (p.hasImg ? 'transparent' : theme.color.gray400)};
  color: ${theme.color.white};
  justify-content: center;
  background-size: cover;
  align-items: center;
  border-radius: 100%;
  position: relative;
  overflow: hidden;
  flex-shrink: 0;
  display: flex;
  opacity: 1;
  cursor: ${(props) => (props.onClick ? 'pointer' : 'inherit')};

  margin: ${(p) => (p.noMargin ? '0' : '0 auto')};
  z-index: 2;

  width: 2rem;
  line-height: 2rem;
  font-size: 0.85rem;

  ${(props) =>
    props.dense &&
    css`
      margin-top: -2rem;
    `};

  &.size-huge {
    width: 8rem;
    line-height: 8rem;
    font-size: 4rem;
    max-width: 100%;
  }

  &.size-small {
    width: 1.5rem;
    line-height: 1.5rem;
    font-size: 0.75rem;
    max-width: 100%;
  }

  &.size-big {
    width: 6rem;
    line-height: 6rem;
    font-size: 3rem;
    max-width: 100%;
  }

  &:hover {
    ${AvatarButton} {
      opacity: 1;
    }
  }

  & > div {
    position: relative;
    padding-bottom: 100%;
    width: 100%;
  }

  & > div > img {
    position: absolute;
    left: 0;
    top: 0;
    bottom: 0;
    right: 0;
    margin: 0;
    padding: 0;
    width: 100%;
  }

  & > div > div {
    position: absolute;
    top: auto;
    bottom: 0;
    left: auto;
    width: 100%;
  }
`;

export const AvatarLetters = styled.div`
  align-items: center;
  height: 100%;
  width: 100%;
  overflow: hidden;
  font-weight: 500;
  letter-spacing: 0.125ch;
  color: ${theme.color.white};
  text-align: center;
  text-transform: uppercase;
`;
