import { useCallback } from 'react';

import { entities, EntityKey } from 'app/entity';
import { stringify } from 'core/functions/qs';
import { useResources } from 'core/hooks/useResource';
import { UserView } from 'core/models/UserView';

const buildParams = (
  defaultViewData: Record<string, any>,
  filters: Record<string, any>,
  sort?: string
) => {
  let params: any = {};

  if (defaultViewData && Object.keys(defaultViewData).length) {
    params = { ...defaultViewData };
  }

  if (sort) {
    params.sort = sort;
  }

  if (filters && Object.keys(filters).length) {
    params.filters = filters;
  }

  return params;
};

/**
 * Return callback that takes filters and sort, and returns a new URL for the entity's overview page.
 */
export const useGetEntityOverviewUrl = (entityKey: EntityKey) => {
  const entity = entities[entityKey];

  const { data: userViews } = useResources<UserView>(
    'users/views?' + stringify({ entity: entity.api('').list, default: true })
  );
  const defaultView = (userViews || []).find((view) => !!view.default);

  return useCallback(
    (filters: Record<string, any>, sort?: string) => {
      const params = defaultView
        ? buildParams(defaultView.data, filters, sort)
        : Object.keys(filters).length
          ? { filters }
          : {};

      return `${entity.urls('').list}?${stringify(params)}`;
    },
    [defaultView, entity]
  );
};

/**
 * Takes an entity key, retrieves the entity's views, and returns a new URL for the entity's overview page,
 * including the settings from the entity's default view.
 */
const useEntityOverviewUrl = (
  entityKey: EntityKey,
  filters: Record<string, any>,
  sort?: string
) => {
  const getEntityOverviewUrl = useGetEntityOverviewUrl(entityKey);
  return getEntityOverviewUrl(filters, sort);
};

export default useEntityOverviewUrl;
