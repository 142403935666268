import { useFormikContext } from 'formik';
import { useTranslation } from 'react-i18next';

import DummyField from 'core/components/DummyField';
import Field from 'core/components/Form/Field';
import { ExplanationWrapper } from 'core/components/FormStyles';
import FormSwitch from 'core/components/FormSwitch';
import { Cell, Row } from 'core/components/Grid';
import { FullRowCell } from 'core/components/GridCell';
import PaperContainer from 'core/components/PaperContainer';
import Slider from 'core/components/Slider';

import { StyledSliderWrapper } from './styled';

export const VALUE_INVOICE_AUTO_APPROVAL_DURATION_DAYS =
  'value_invoiceAutoApprovalDurationDays' as const;
export const ENABLED_INVOICE_AUTO_APPROVAL_DURATION_DAYS =
  'enabled_invoiceAutoApprovalDurationDays' as const;

interface FormValues {
  [VALUE_INVOICE_AUTO_APPROVAL_DURATION_DAYS]: number;
  [ENABLED_INVOICE_AUTO_APPROVAL_DURATION_DAYS]: boolean;
}

const AutomaticApproval = () => {
  const { t } = useTranslation();

  const { setFieldValue, values } = useFormikContext<FormValues>();

  const explanationWrapperElement = (
    <ExplanationWrapper>
      {t(
        'When an Invoice reaches the "Out for Approval" status, it will automatically move to "Approved" after the specified number of days if no manual action is taken.'
      )}
    </ExplanationWrapper>
  );

  return (
    <FullRowCell>
      <PaperContainer title={t('Automatic Accepting')}>
        <Row>
          <FullRowCell>
            <Field
              name={ENABLED_INVOICE_AUTO_APPROVAL_DURATION_DAYS}
              label={t('Invoices')}
              inline
              component={FormSwitch}
              fast={false}
              onChange={(value: number) => {
                setFieldValue(ENABLED_INVOICE_AUTO_APPROVAL_DURATION_DAYS, value);
                if (!value) {
                  setFieldValue(VALUE_INVOICE_AUTO_APPROVAL_DURATION_DAYS, null);
                } else {
                  setFieldValue(VALUE_INVOICE_AUTO_APPROVAL_DURATION_DAYS, 7);
                }
              }}
            />
            {!values[ENABLED_INVOICE_AUTO_APPROVAL_DURATION_DAYS] && explanationWrapperElement}
          </FullRowCell>
          {values[ENABLED_INVOICE_AUTO_APPROVAL_DURATION_DAYS] && (
            <FullRowCell>
              <Row>
                <Cell desktopColumns={3} tabletColumns={3} phoneColumns={4}>
                  <DummyField
                    label={t('Last Modification')}
                    value={t('Days: {{count}}', {
                      count: values[VALUE_INVOICE_AUTO_APPROVAL_DURATION_DAYS],
                    })}
                  />
                </Cell>

                <Cell desktopColumns={9} tabletColumns={5} phoneColumns={4}>
                  <StyledSliderWrapper>
                    <Slider
                      step={1}
                      min={1}
                      max={60}
                      onChange={(newValue) =>
                        setFieldValue(VALUE_INVOICE_AUTO_APPROVAL_DURATION_DAYS, newValue)
                      }
                      value={values[VALUE_INVOICE_AUTO_APPROVAL_DURATION_DAYS]}
                      leftIcon="history"
                      rightIcon="update"
                    />
                  </StyledSliderWrapper>
                </Cell>
              </Row>
              {values[ENABLED_INVOICE_AUTO_APPROVAL_DURATION_DAYS] && explanationWrapperElement}
            </FullRowCell>
          )}
        </Row>
      </PaperContainer>
    </FullRowCell>
  );
};

export default AutomaticApproval;
