import { FC, memo } from 'react';
import { Editor } from 'react-draft-wysiwyg';

import { markdownToEditorState } from 'core/components/FormEditorField';

import { StyledMessage } from '../../styled';

type Props = { text: string };

const Message: FC<Props> = ({ text }) => {
  const editorState = markdownToEditorState(text);
  return (
    <StyledMessage>
      <Editor toolbarHidden editorState={editorState} readOnly={true} />
    </StyledMessage>
  );
};

export default memo(Message);
