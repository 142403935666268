import { RowNode } from 'ag-grid-community';
import isEqual from 'lodash/isEqual';
import { FC, ReactNode, useCallback, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';

import ContextMenu from 'core/components/ContextMenu';
import IconButton from 'core/components/IconButton';
import MaterialIcon from 'core/components/MaterialIcon';
import { Item } from 'core/components/Menu';

import { BulkAction, Filter, FilterValue } from '..';
import { UrlColumnConfiguration } from '../hooks/useColumnConfiguration';
import { SelectedRows } from '../props';
import BulkActions from '../Toolbar/BulkActions';
import Counts from '../Toolbar/Counts';
import { ToolbarButton } from '../Toolbar/styled';
import { FilterButton } from '../Toolbar/ViewChanges/styled';

import Filters from './Filters';
import { StyledContainer } from './styled';

interface Props {
  filters?: { [id: string]: Filter };
  filterValues: { [key: string]: FilterValue };
  defaultFilterValues: { [key: string]: FilterValue };
  selectedRows: SelectedRows;
  bulkActions?: BulkAction[];
  counts?: { filtered: number; total: number | null };
  disableTotalCount?: boolean;
  customToolbarElement?: ReactNode;
  getSelectedNodes: () => RowNode[];
  onBulkSuccess: () => void;
  onFiltersAndColumnsChange: (
    filters: { [key: string]: FilterValue } | undefined,
    columnConfiguration: UrlColumnConfiguration
  ) => void;
}

const FiltersToolbar: FC<Props> = ({
  counts,
  filters,
  bulkActions,
  selectedRows,
  filterValues,
  onBulkSuccess,
  defaultFilterValues,
  getSelectedNodes,
  disableTotalCount,
  customToolbarElement,
  onFiltersAndColumnsChange,
}) => {
  const { t } = useTranslation();
  const [filtersOpen, setFiltersOpen] = useState(false);
  const onFiltersClose = useCallback(() => setFiltersOpen(false), [setFiltersOpen]);
  const filtersChanged = Object.keys(filterValues).some(
    (key) => !isEqual(filterValues[key], defaultFilterValues[key])
  );
  const filtersDisabled = useMemo(
    () => Object.keys(filters || {}).filter((it) => it !== 'search').length === 0,
    [filters]
  );
  const filtersMenuItems = useMemo(() => {
    let list: null | Array<Item | 'SEPARATOR'> = [];

    if (filtersChanged && defaultFilterValues && Object.keys(defaultFilterValues).length > 0) {
      list.push({
        text: t('Reset Filters'),
        title: t('Reset Filters to Default value'),
        key: 'clear',
        onClick: () => onFiltersAndColumnsChange({}, {}),
      });
    }

    return list.length > 0 ? list : null;
  }, [defaultFilterValues, filtersChanged, onFiltersAndColumnsChange, t]);

  return (
    <>
      <StyledContainer activated={selectedRows === 'ALL' || selectedRows.length > 0}>
        {selectedRows === 'ALL' || selectedRows.length > 0 ? (
          <BulkActions
            selectedRows={selectedRows}
            bulkActions={bulkActions}
            onBulkSuccess={onBulkSuccess}
            getSelectedNodes={getSelectedNodes}
          />
        ) : (
          <>
            <FilterButton>
              <ToolbarButton
                type="button"
                text={filtersChanged ? t('Results Filtered') : t('Filter Results')}
                icon={<MaterialIcon icon="filter_list" />}
                disabled={filtersDisabled}
                onClick={() => setFiltersOpen(true)}
                $highlighted={filtersChanged}
              />
              {filtersMenuItems && (
                <ContextMenu menuItems={filtersMenuItems} menuId="filters">
                  <IconButton icon="more_horiz" tooltip={t('More')} />
                </ContextMenu>
              )}
            </FilterButton>

            {customToolbarElement ? (
              customToolbarElement
            ) : (
              <>
                <Counts
                  total={counts?.total}
                  filtered={counts?.filtered}
                  disableTotalCount={disableTotalCount}
                />
                <div />
              </>
            )}
          </>
        )}
      </StyledContainer>

      {filters && (
        <Filters
          open={filtersOpen}
          filters={filters}
          defaultFilterValues={defaultFilterValues}
          filterValues={filterValues}
          onFiltersAndColumnsChange={onFiltersAndColumnsChange}
          onClose={onFiltersClose}
        />
      )}
    </>
  );
};

export default FiltersToolbar;
