import { DateTime } from 'luxon';

import { t } from 'core/i18n';

const DateTimeFormat = {
  DATE_SHORT: DateTime.DATE_SHORT,
  DATE_LONG: DateTime.DATE_FULL,
  TIME_SHORT: DateTime.TIME_SIMPLE,
  TIME_WITH_SECONDS: DateTime.TIME_WITH_SECONDS,
  TIME_WITH_OFFSET: DateTime.TIME_WITH_SHORT_OFFSET,
  /**
   * Date and time without offset
   */
  DATETIME_SHORT: DateTime.DATETIME_SHORT,
  /**
   * Date and time with offset
   */
  DATETIME_LONG: DateTime.DATETIME_FULL,
  /**
   * Date and time with seconds without offset
   */
  DATETIME_SHORT_WITH_SECONDS: DateTime.DATETIME_SHORT_WITH_SECONDS,
  /**
   * Date and time with seconds and offset
   */
  DATETIME_LONG_WITH_SECONDS: DateTime.DATETIME_FULL_WITH_SECONDS,
} as const;

export type DateTimeFormats = keyof typeof DateTimeFormat;

/**
 * Format date or time in local format using (current by default or specific) locale
 *
 * Prefer using useFormatDateTime whenever possible!
 */
export function formatDateTime(input: Date | DateTime, format: DateTimeFormats, locale: string) {
  const date = input instanceof DateTime ? input : DateTime.fromJSDate(input);

  return formatValue(date, format, locale);
}

/**
 * Format date or time range in local format using (current by default or specific) locale
 *
 * If both start and end are passed, it returns 'formattedStart - formattedEnd'
 * If only one is passed, it returns 'from formattedStart' or 'to formattedEnd'
 * If neither start nor end is passed, it returns an empty string
 *
 * Prefer using useFormatDateTime whenever possible!
 *
 * @param start Start of the range
 * @param end End of the range
 * @param format Optional format, defaults to DATE_SHORT
 * @param locale Locale date/time will be formatted
 */
export function formatDateTimeRange(
  start: Date | DateTime | undefined,
  end: Date | DateTime | undefined,
  format: DateTimeFormats = 'DATE_SHORT',
  locale: string
) {
  if (!start && !end) return '';

  const startDate = start
    ? start instanceof DateTime
      ? start
      : DateTime.fromJSDate(start)
    : undefined;

  const endDate = end ? (end instanceof DateTime ? end : DateTime.fromJSDate(end)) : undefined;

  const formattedStart = startDate && formatValue(startDate, format, locale);
  const formattedEnd = endDate && formatValue(endDate, format, locale);

  if (formattedStart && formattedEnd) {
    return `${formattedStart} - ${formattedEnd}`;
  } else if (formattedStart) {
    return t('from {{date}}', { date: formattedStart });
  } else {
    return t('to {{date}}', { date: formattedEnd });
  }
}

function formatValue(val: DateTime, format: keyof typeof DateTimeFormat, locale: string): string {
  return val.setLocale(locale).toLocaleString(DateTimeFormat[format] as any);
}

/**
 * Format date or time in local format using (current by default or specific) locale
 *
 * If the date is 7+ days ago, it will be displayed in DATETIM_SHORT format
 *
 * Prefer using useFormatDateTime whenever possible!
 *
 * @param {boolean} calendar Should it use calendar formatting (today, yesterday...), defaults to false
 * @param locale Locale date/time will be formatted
 */
export function formatDateTimeToRelative(input: Date | DateTime, calendar = false, locale: string) {
  const date = input instanceof DateTime ? input : DateTime.fromJSDate(input);

  if (Math.abs(date.diffNow('days').days) >= 7) {
    return formatDateTime(date, 'DATETIME_SHORT', locale);
  }

  const relative = calendar
    ? date.setLocale(locale).toRelativeCalendar()
    : date.setLocale(locale).toRelative();

  return relative || formatDateTime(date, 'DATETIME_SHORT', locale);
}
