import { Input } from '@material/react-text-field';
import parse, {
  CountryCode,
  isValidNumberForRegion,
  NationalNumber,
  PhoneNumber,
} from 'libphonenumber-js';
import { FocusEvent, forwardRef, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';

import Button from 'core/components/Button';
import DropDown from 'core/components/DropDown';
import { FeedbackMessage } from 'core/components/FeedbackMessage';
import { FlexCell, FlexRow } from 'core/components/FlexUtils';
import Modal from 'core/components/Modal';
import Textfield, { Props as TextfieldProps } from 'core/components/Textfield';
import formatPhone from 'core/functions/formatPhone';
import getCountryCallingCode from 'core/functions/getCountryCallingCode';
import useClientOption from 'core/hooks/useClientOption';
import { useResources } from 'core/hooks/useResource';
import Country from 'core/models/Country';

import { FlagButton } from './styled';

interface Props extends TextfieldProps {
  enablePickCountry?: boolean;
}

const Phonefield = forwardRef<Input, Props>(
  ({ value, onChange, enablePickCountry = false, onFocus, onBlur, ...props }, ref) => {
    const { t } = useTranslation();
    const [state, setState] = useState<{ open?: boolean; countryCode?: null | string }>({});

    const val: string = `${value || ''}`;

    const [editableValue, setEditableValue] = useState<string | undefined>();

    const countryPrefix = useClientOption('defaultCallingCode');

    let phoneNumber: undefined | PhoneNumber;
    let isInvalidCountry: boolean = false;

    try {
      isInvalidCountry =
        !!value &&
        !!state.countryCode &&
        !isValidNumberForRegion(val as NationalNumber, state.countryCode as CountryCode);
      phoneNumber = parse(val);
    } catch {}

    const { data: countries, reload } = useResources<Country>('countries', { autoload: false });

    const extraProps = useMemo(
      () =>
        enablePickCountry
          ? {
              tooltip: t('Choose Country'),
              onClick: () => {
                setState((s) => {
                  if (!s.open && !countries?.length) {
                    reload();
                  }
                  return { ...s, open: true };
                });
              },
            }
          : {},
      [countries?.length, enablePickCountry, reload, t]
    );

    return (
      <>
        <FlexRow>
          <FlexCell>
            <FlagButton {...extraProps}>
              {state.countryCode || (phoneNumber && phoneNumber.country)
                ? getFlagEmoji(state.countryCode || phoneNumber!.country || '')
                : '🌐'}
            </FlagButton>
          </FlexCell>
          <FlexCell flex={1} block>
            <Textfield
              {...props}
              ref={ref}
              value={editableValue || formatPhone(val)}
              placeholder={countryPrefix ? getCountryCallingCode(countryPrefix) : ''}
              onFocus={(e: FocusEvent<HTMLInputElement> & FocusEvent<HTMLTextAreaElement>) => {
                if (countryPrefix && !value) {
                  onChange && onChange(getCountryCallingCode(countryPrefix), e);
                }

                // Create copy of the value for editing
                setEditableValue(formatPhone(value?.toString() || '') || '');

                onFocus && onFocus(e);
              }}
              onBlur={(e: FocusEvent<HTMLInputElement> & FocusEvent<HTMLTextAreaElement>) => {
                // Release the copy of the value after editing stopped
                setEditableValue(undefined);

                onBlur && onBlur(e);
              }}
              onChange={(val: string | null, e) => {
                setEditableValue((current) => (current !== undefined ? val || '' : undefined));

                let phone;
                try {
                  phone = parse(val || '');
                } catch {}
                onChange && onChange(phone ? (phone.number as string) : val, e);
              }}
            />
          </FlexCell>
        </FlexRow>
        {!props.error && isInvalidCountry && (
          <FlexRow>
            <FlexCell flex={1} block>
              <FeedbackMessage $type="warning">
                {t('Seems to be invalid Number for Selected Country')}
              </FeedbackMessage>
            </FlexCell>
          </FlexRow>
        )}

        <Modal
          onClose={() => setState((s) => ({ ...s, open: false }))}
          clearButton={
            <Button
              onClick={() => setState((s) => ({ ...s, countryCode: undefined, open: false }))}
              text={t('Clear')}
            />
          }
          ariaLabel={t('Select Country')}
          open={!!state.open}
        >
          <DropDown
            onChange={(code) =>
              setState((s) => ({ ...s, open: false, countryCode: code ? '' + code : null }))
            }
            options={(countries || []).map(({ name, code }) => ({ id: code, name }))}
            value={state.countryCode || null}
            label={t('Select Country')}
            mode="inline"
            id="country"
            single
          />
        </Modal>
      </>
    );
  }
);

export function getFlagEmoji(countryCode: string): string {
  const code = countryCode === 'en' ? 'gb' : countryCode;
  const codePoints = code
    .toUpperCase()
    .split('')
    .map((char) => 127397 + char.charCodeAt(0));
  return String.fromCodePoint(...codePoints);
}

export default Phonefield;
