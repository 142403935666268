import { put, select } from 'redux-saga/effects';

import { AppState } from 'app/store';
import { apiCall, TYPE_LOAD_PROFILE } from 'core/actions';

function* loadProfileSaga() {
  const isLoggedIn: true | undefined = yield select(({ core }: AppState) => core.isLoggedIn);
  if (!isLoggedIn) {
    return;
  }
  yield put(apiCall(TYPE_LOAD_PROFILE, 'GET', '/profile', {}, {}));
}

export default loadProfileSaga;
