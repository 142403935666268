import { FC } from 'react';
import { useTranslation } from 'react-i18next';

import theme from 'app/theme';
import Divider from 'core/components/Divider';
import {
  PopoverBlock,
  PopoverHeader,
} from 'core/containers/DataGrid/CellRenderers/PopoverCellRenderer';
import { useFormatRelativeDateTime } from 'core/i18n/useFormatDateTime';
import { TeamMemberData } from 'planning/components/TeamTab/useTeamInputMapping';

import { parseNotificationData } from './utils';

const InvitationNotificationsContent: FC<{ user: TeamMemberData & { id: number } }> = ({
  user,
}) => {
  const { t } = useTranslation();
  const formatRelativeDateTime = useFormatRelativeDateTime();

  const { plannedMeans, sent } = parseNotificationData(user.invitations, formatRelativeDateTime);

  const hasPlanned = plannedMeans.length > 0;
  const hasSent = sent.length > 0;

  return (
    <>
      <PopoverHeader title={t('Invitations')} />

      {hasPlanned && (
        <PopoverBlock label={t('On save, will be sent via')} value={plannedMeans.join(', ')} />
      )}

      {hasSent && hasPlanned && <Divider color={theme.color.gray200} marginVertical="0.5rem" />}

      {hasSent &&
        sent.map(({ formattedDate, means }) => (
          <PopoverBlock
            key={formattedDate}
            label={t('Sent {{when}} via', { when: formattedDate })}
            value={means.join(', ')}
          />
        ))}
    </>
  );
};

export default InvitationNotificationsContent;
