import { FC } from 'react';
import { useTranslation } from 'react-i18next';

import NoData from 'app/assets/icons/NoData';

import { Container, StyledIcon, StyledTitle } from './styled';

interface Props {
  subTitle?: string;
  title?: string;
  padding?: string;
}

const EmptyOverlay: FC<Props> = ({ title, subTitle, padding }) => {
  const { t } = useTranslation();

  return (
    <Container padding={padding} aria-label={t('No data to display')}>
      <StyledIcon>
        <NoData />
      </StyledIcon>
      <StyledTitle>{title || t('There are no matching items')}</StyledTitle>
      {subTitle !== null && (
        <span>{subTitle || t('Please make sure the filters above are set correctly.')}</span>
      )}
    </Container>
  );
};

export default EmptyOverlay;
