import { AxiosResponse as Response } from 'axios';
import { toast } from 'react-toastify';

import { t } from 'core/i18n';

const showUpdatedRecordsMessage = (response: Response) => {
  if (response && response.data.updated > 0) {
    toast.success(t('Affected records: {{count}}', { count: response.data.updated }));
  } else {
    toast.warning('Nothing was changed');
  }
};

export default showUpdatedRecordsMessage;
