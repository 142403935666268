import { FC, ReactNode } from 'react';
import MediaQuery from 'react-responsive';

import theme from 'app/theme';

const queries = {
  mobile: `(max-width: ${theme.breakpoints.tablet}rem)`,
  mobileTablet: `(max-width: ${theme.breakpoints.desktop}rem)`,
  tabletDesktop: `(min-width: ${theme.breakpoints.tablet}rem)`,
  desktop: `(min-width: ${theme.breakpoints.desktop}rem)`,
};

interface Props {
  query: 'mobile' | 'mobileTablet' | 'tabletDesktop' | 'desktop';
  children?: ReactNode;
}

const Media: FC<Props> = ({ children, query }) => {
  if (!queries[query]) {
    throw new Error('Unknown media query');
  }

  return <MediaQuery query={queries[query]}>{children}</MediaQuery>;
};

export default Media;
