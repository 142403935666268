import User from 'app/models/User';
import { Client } from 'core/models/Client';
import { definition, EntityMeta, getStandardApi, getStandardUrls } from 'core/module';

import { UserAgenda } from './containers/AgendaPage/AvailabilityOverview/useColumnsLogic';

type T = (m: string) => string;

export const personnelEntities = {
  user: {
    ...definition<User>('users', { name: (t: T) => t('User'), listName: (t: T) => t('Users') }),
    api: () => ({
      ...getStandardApi('users'),
      avatar: (id: number | string) => `users/${id}/image`,
      accCardsList: '/users/accreditation-cards',
      accCardsUserList: (id: number | string) => `/users/accreditation-cards/user/${id}`,
      accCardRevoke: (id: number | string) => `/users/accreditation-cards/${id}/revoke`,
      accCardVerify: (uuid: string) => `/users/accreditation-cards/verify/${uuid}`,
      paymentPlansList: (id: number | string) => `/users/${id}/payment-plans`,
    }),
  },
  client: {
    ...definition<Client>('clients', {
      name: (t: T) => t('Client'),
      listName: (t: T) => t('Clients'),
    }),
    urls: () => ({ ...getStandardUrls('clients'), current: '/client' }),
    api: () => ({
      ...getStandardApi('clients'),
      image: (id?: number) => (id ? `/clients/${id}/image` : `/client/image`),
    }),
  },
  athlete: {
    ...definition<User>('athletes', {
      name: (t: T) => t('Athlete'),
      listName: (t: T) => t('Athletes'),
    }),
    api: () => ({
      ...getStandardApi('athletes'),
      previousDeclarations: (id: number) => `/athletes/${id}/previousDeclarations`,
    }),
  },
  agenda: definition<UserAgenda>('agenda', {
    name: (t: T) => t('Availability'),
    listName: (t: T) => t('Availabilities'),
    // TODO: check permissions
  }),
} as const;

// REQUIRED !!!
// eslint-disable-next-line @typescript-eslint/no-unused-vars
const verifyEntities: Record<keyof typeof personnelEntities, EntityMeta> = personnelEntities;

export type K = keyof typeof personnelEntities;
