import { DateTime, DurationObjectUnits } from 'luxon';
import { useCallback } from 'react';

import useClientOption, { useBoolClientOption } from 'core/hooks/useClientOption';

const diffBetweenDatesIsMoreThanMax = (
  max: number,
  unit: keyof DurationObjectUnits,
  d1: Date | null,
  d2: Date | null
) => {
  if (!d1 || !d2) return false;

  const d1Luxon = DateTime.fromJSDate(d1);
  const d2Luxon = DateTime.fromJSDate(d2);

  return Math.abs(d1Luxon.diff(d2Luxon, unit).toObject()[unit] || 0) > max;
};

const useArrivalDelayIsMoreThanMax = () => {
  const isArrivalDelayCheckEnabled = useBoolClientOption('arrivalDelayCheckEnabled');
  const arrivalDelayMax = Number(useClientOption('arrivalDelayMax') || 0);

  return useCallback(
    (notificationPerformedAt: Date | null, arrivalAt: Date | null) =>
      isArrivalDelayCheckEnabled
        ? diffBetweenDatesIsMoreThanMax(
            arrivalDelayMax,
            'minutes',
            notificationPerformedAt,
            arrivalAt
          )
        : false,
    [arrivalDelayMax, isArrivalDelayCheckEnabled]
  );
};

export default useArrivalDelayIsMoreThanMax;
