import { FC, memo, useMemo } from 'react';

import { StyledTabIcon } from 'core/components/StyledTabIcon';

import IconButton from '../IconButton';
import { Icon } from '../PaperContainer';
import { BadgePrimary, IconsContainer } from '../PaperContainer/styled';

import { Tab, StyledTabBar } from './styled';

export interface TabOption {
  title: string;
  badgePrimary?: string;
  hasError?: boolean;
  hidden?: boolean;
  icons?: Icon[];
  data?: unknown[];
}
export type TabsOptions = Array<TabOption>;

const Tabs: FC<{
  setActiveTab: (key: number) => void;
  activeTab: number;
  tabsName: string;
  tabs: TabsOptions;
}> = ({ activeTab, setActiveTab, tabs: tabsDefinition, tabsName }) => {
  const tabs = useMemo(() => tabsDefinition.filter((tab) => !tab.hidden), [tabsDefinition]);

  const icons = tabs[activeTab].icons;

  return (
    <StyledTabBar>
      <>
        {tabs.map((tab, key: number) => {
          const { title, badgePrimary, hasError, data } = tab;

          const badgeDefault = Array.isArray(data) && data.length.toString();

          return (
            <Tab
              onClick={() => setActiveTab(key)}
              active={activeTab === key}
              key={`${tabsName}_${key}`}
              type="button"
              hasError={!!hasError}
            >
              {title}
              {(badgeDefault || badgePrimary) && (
                <BadgePrimary>{badgePrimary || badgeDefault}</BadgePrimary>
              )}
              {hasError && <StyledTabIcon color="error" icon="warning" />}
            </Tab>
          );
        })}

        {icons && (
          <IconsContainer>
            {icons.map((icon) => (
              <IconButton
                key={icon.icon}
                onClick={icon.onClick}
                disabled={icon.disabled}
                type={icon.type || 'button'}
                icon={icon.icon}
                to={icon.to}
                tooltip={icon.title}
              />
            ))}
          </IconsContainer>
        )}
      </>
    </StyledTabBar>
  );
};

export default memo(Tabs);
