import { AxiosRequestHeaders } from 'axios';
import { toast } from 'react-toastify';
import { put, race, select, take } from 'redux-saga/effects';

import Profile from 'app/models/Profile';
import { AppState } from 'app/store';
import { t } from 'core/i18n';

import { apiCall, loadEntitySuccess, TYPE_UNSUBSCRIBE_2FA } from '../actions';

function* unsubscribe2FASaga() {
  yield put(apiCall(TYPE_UNSUBSCRIBE_2FA, 'DELETE', '/profile/mfa/unsubscribe'));

  const { success } = yield race({
    success: take(`${TYPE_UNSUBSCRIBE_2FA}_SUCCESS`),
    error: take(`${TYPE_UNSUBSCRIBE_2FA}_ERROR`),
  });

  if (success) {
    const entityData: Profile | undefined = yield select((s: AppState) => s.core.entityData);

    yield put(
      loadEntitySuccess(
        {
          data: { ...entityData, hasTwoFactor: false },
          status: 200,
          statusText: 'OK',
          headers: {},
          config: { headers: {} as AxiosRequestHeaders },
        },
        '/profile'
      )
    );

    toast.success(t('2-Factor Verification successfully deactivated.'));
  }
}

export default unsubscribe2FASaga;
