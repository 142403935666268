import { useMemo } from 'react';

import { Option } from 'core/components/DropDown';
import { ResourceDropDownFilter, FilterValue } from 'core/containers/DataGrid';
import Base from 'core/models/Base';

function useSingleDropdownDataFilter<E extends Base = Base>(
  label: string,
  resource: string,
  property: string,
  canBeEmpty = true,
  defaultValue: any = 'any',
  customReducer?: ((resource: E[], value: FilterValue) => Option[]) | undefined,
  customApplyToParams?: ResourceDropDownFilter['applyToParams']
): ResourceDropDownFilter {
  return useMemo(
    () => ({
      type: 'resourceDropdown',
      label,
      defaultValue,
      applyToParams: customApplyToParams || ((value) => defaultApplyToParams(value, property)),
      resource,
      single: true,
      canBeEmpty,
      reducer: customReducer,
    }),
    [canBeEmpty, customApplyToParams, customReducer, defaultValue, label, property, resource]
  );
}

export const defaultApplyToParams = (value: FilterValue, property: string) => {
  if (value === 'noValue') {
    return { [property]: '\\x00' };
  }

  return !value || value === 'any' ? {} : { [property]: value };
};

export default useSingleDropdownDataFilter;
