import { useFormikContext } from 'formik';
import { FC, useCallback, useContext, useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { toast } from 'react-toastify';

import { entities } from 'app/entity';
import {
  AccreditationsId,
  bcoAccreditations,
  chaperoneAccreditations,
  dcoAccreditations,
} from 'app/models/Accreditation';
import Button from 'core/components/Button';
import ContextMenu from 'core/components/ContextMenu';
import DataCard from 'core/components/DataCard';
import { FlexCell, FlexRow } from 'core/components/FlexUtils';
import { FormContext } from 'core/components/Form';
import MaterialIcon from 'core/components/MaterialIcon';
import { Item as MenuItem } from 'core/components/Menu';
import { stringify } from 'core/functions/qs';
import { useBoolClientOption } from 'core/hooks/useClientOption';

import { TeamMemberType } from '../enums';
import { TeamFormData, TeamMemberData } from '../useTeamInputMapping';

import Item from './MembersCardItem';
import useAccreditationRolesOptions from './useAccreditationRolesOptions';
import useActions from './useActions';

interface Props {
  type: TeamMemberType;
  data: TeamMemberData[];
  availabilities: { [usersId: number]: string };
  limitedAssignmentAllowed?: boolean;
  alreadySavedUsersId?: number[];
}

const MembersCard: FC<Props> = ({
  type,
  data,
  availabilities,
  limitedAssignmentAllowed = false,
  alreadySavedUsersId,
}) => {
  const { t } = useTranslation();
  const availabilitiesEnabled = useBoolClientOption('enableAvailabilities');
  const formik = useFormikContext<TeamFormData & { dateRange: { from: Date; to: Date } | null }>();
  const { dateRange, invitedMembers, assignedMembers } = formik.values;

  const isTypeInvited = type === TeamMemberType.INVITED;

  const dataKey = isTypeInvited ? 'invitedMembers' : 'assignedMembers';

  const { viewMode, defaultViewMode } = useContext(FormContext);
  const isViewMode = !!viewMode?.hasOwnProperty(dataKey)
    ? !!viewMode[dataKey]
    : !!viewMode && defaultViewMode;
  const accreditationRoles = useAccreditationRolesOptions(formik.values[dataKey]?.length > 0);

  const [sortedData, idIndexMap] = useMemo(() => {
    const teamMembersMap = new Map<number, number>();

    const sortedData = data
      .map((m, index) => {
        teamMembersMap.set(m.usersId, index);
        return { ...m, id: m.usersId };
      })
      .sort((b, a) =>
        b.fullName ? b.fullName.localeCompare(a.fullName, undefined, { sensitivity: 'variant' }) : 0
      );

    return [sortedData, teamMembersMap];
  }, [data]);

  const [actions, triggers, modals] = useActions(
    type,
    limitedAssignmentAllowed ? alreadySavedUsersId : undefined
  );

  const dateFrom = dateRange?.from;
  const dateTo = dateRange?.to;
  const getDCOAgendaConditions = useCallback(
    (accreditation?: number[], dcos?: TeamMemberData[]) =>
      stringify({
        filters: {
          ...(accreditation && { accreditation }),
          ...(dcos && { dcos: dcos.map((d) => d.usersId) }),
        },
        range:
          dateFrom && dateTo
            ? { from: dateFrom.toISOString(), to: dateTo.toISOString() }
            : undefined,
      }),
    [dateFrom, dateTo]
  );
  const actionsDisabled = isViewMode && !limitedAssignmentAllowed;

  const triggerRemoveIfCan = useCallback(
    (selection: TeamMemberData | TeamMemberData[]) => {
      if (limitedAssignmentAllowed && alreadySavedUsersId) {
        const selectedUsersId = Array.isArray(selection)
          ? selection.map((it) => it.usersId)
          : [selection.usersId];

        if (selectedUsersId.some((it) => alreadySavedUsersId.indexOf(it) >= 0)) {
          toast.error(t('Already saved team members can not be removed') as string);
        } else {
          triggers.onRemove(selection);
        }
      }

      return isViewMode ? undefined : triggers.onRemove(selection);
    },
    [alreadySavedUsersId, limitedAssignmentAllowed, triggers, t, isViewMode]
  );

  const agendaMenuItems: MenuItem[] = useMemo(() => {
    const items: MenuItem[] = [];
    if (
      (type === TeamMemberType.INVITED && invitedMembers.length > 0) ||
      (type === TeamMemberType.ASSIGNED && assignedMembers.length > 0)
    ) {
      items.push({
        key: `${type}-members`,
        text: t('{{type}} Members', {
          type: `${type.charAt(0).toUpperCase()}${type.slice(1)}`,
        }),
        icon: 'launch',
        onClick: () =>
          window.open(
            `${entities.agenda.urls().list}?${getDCOAgendaConditions(
              undefined,
              type === TeamMemberType.INVITED ? invitedMembers : assignedMembers
            )}`,
            '_blank'
          ),
      });
    }
    const getCommonItem = (key: string, text: string, accreditations: AccreditationsId[]) => ({
      key,
      text,
      icon: 'launch',
      onClick: () =>
        window.open(
          `${entities.agenda.urls().list}?${getDCOAgendaConditions(accreditations)}`,
          '_blank'
        ),
    });
    const commonItems: MenuItem[] = [
      getCommonItem('DCO', t('DCO'), dcoAccreditations),
      getCommonItem('BCO', t('BCO'), bcoAccreditations),
      getCommonItem('Chaperone', t('Chaperone'), chaperoneAccreditations),
    ];
    return [...items, ...commonItems];
  }, [assignedMembers, getDCOAgendaConditions, invitedMembers, t, type]);

  const footer = useMemo(
    () => (
      <>
        <FlexRow horizontalAlign="space-between">
          {actionsDisabled ? undefined : (
            <FlexCell>
              <Button
                type="button"
                text={t('Add')}
                icon={<MaterialIcon icon="add" />}
                onClick={triggers.onAdd}
                data-cy={isTypeInvited ? 'addInvitation' : 'addAssignment'}
              />
            </FlexCell>
          )}

          {!isViewMode && availabilitiesEnabled && (
            <FlexCell>
              <ContextMenu menuItems={agendaMenuItems} menuId={`${type}-agenda-menu`}>
                <Button
                  type="button"
                  text={t('Agenda')}
                  icon={<MaterialIcon icon="date_range" />}
                />
              </ContextMenu>
            </FlexCell>
          )}
        </FlexRow>
      </>
    ),
    [
      actionsDisabled,
      agendaMenuItems,
      availabilitiesEnabled,
      isTypeInvited,
      isViewMode,
      t,
      triggers.onAdd,
      type,
    ]
  );

  return (
    <>
      <DataCard
        title={isTypeInvited ? t('Invited') : t('Assigned')}
        items={sortedData}
        actions={isViewMode ? [] : actions}
        onRemove={isViewMode && !limitedAssignmentAllowed ? undefined : triggerRemoveIfCan}
        footer={footer}
      >
        {(user) => (
          <Item
            user={user}
            index={idIndexMap.get(user.id)!}
            roles={accreditationRoles}
            availabilities={availabilities}
            triggers={triggers}
            formik={formik}
            type={type}
            isViewMode={isViewMode}
            actionsDisabled={actionsDisabled}
            limitedAssignmentAllowed={limitedAssignmentAllowed}
          />
        )}
      </DataCard>

      {modals}
    </>
  );
};

export default MembersCard;
